import { PFButton, PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { QueryJoin } from '.';

const QueryJoins = ({ joins, setJoins, tableOrigin,
  originOptions, schemaOrigin, joinOptions, setJoinOptions }) => {
  const { docs: databases } = useSelector(
    (state) => state.current_function.associated_dbs);

  const addJoin = () => {
    setJoins([...joins, {
      type: 'INNER', schema_db: '', schema: '', table: '', on: [], columns: []
    }]);
  };

  const renderJoins = () => {
    return joins.map((join, i) => {
      const setJoin = (join) => {
        joins[i] = join;
        setJoins(joins);
      };
      const removeJoin = () => {
        joins.splice(i, 1);
        setJoins(joins);
      };

      return (
        <QueryJoin key={i}
          index={i}
          join={join}
          setJoin={setJoin}
          removeJoin={removeJoin}
          databases={databases}
          tableOrigin={tableOrigin}
          schemaOrigin={schemaOrigin}
          joinOptions={joinOptions}
          currentJoins={joins.map((joinElement) => joinElement.table
            + '_' + joinElement.schema_db)}
          setJoinOptions={setJoinOptions}
          joins={joins} originOptions={originOptions} />
      );
    });
  };

  return (
    <PFContainer margin="top-s" display="flex"
      flexDirection="column" gap="m">
      {renderJoins()}
      <PFButton type="support" onClick={addJoin}
        style={{ alignSelf: 'start' }}>
        + add join
      </PFButton>
    </PFContainer>
  );
};

QueryJoins.propTypes = {
  joins: PropTypes.array.isRequired,
  setJoins: PropTypes.func.isRequired,
  tableOrigin: PropTypes.string.isRequired,
  originOptions: PropTypes.array,
  schemaOrigin: PropTypes.string,
  joinOptions: PropTypes.func,
  setJoinOptions: PropTypes.func,
};

export default QueryJoins;
