export default [
  {
    name: 'Query data',
    type: 'select_query',
    description: 'Obtain DB data',
    icon: 'bx bx-search-alt-2',
    create: () => ({
      var_name: '',
      type: 'select_query',
      execute: {
        schema_db: '',
        schema: '',
        table: '',
        columns: [],
        filters: [],
        joins: [],
      }
    })
  },
  {
    name: 'Update record',
    type: 'update_sql',
    description: 'Update DB data',
    icon: 'bx bx-reset',
    create: () => ({
      var_name: '',
      type: 'update_sql',
      execute: {
        schema_db: '',
        schema: '',
        table: '',
        filters: [],
        new_values: {},
      }
    })
  },
  {
    name: 'Insert record',
    type: 'insert_sql',
    description: 'Insert DB data',
    icon: 'bx bx-add-to-queue',
    create: () => ({
      var_name: '',
      type: 'insert_sql',
      execute: {
        schema_db: '',
        schema: '',
        table: '',
        values: {}
      }
    })
  },
  {
    name: 'Assign value',
    type: 'assign',
    description: 'Assing value to a variable',
    icon: 'bx bx-code-curly',
    create: () => ({
      var_name: '',
      type: 'assign',
      execute: {
        value: '',
        format: ''
      }
    })
  },
  {
    name: 'Arithmetic',
    type: 'arithmetic',
    description: 'Perform an arithmetic operation',
    icon: 'bx bx-math',
    create: () => ({
      var_name: '',
      type: 'arithmetic',
      execute: {
        calculate: ''
      }
    })
  },
  {
    name: 'Javascript',
    type: 'custom_code',
    description: 'Execute Javascript code',
    icon: 'bx bxl-javascript',
    create: () => ({
      var_name: '',
      type: 'custom_code',
      execute: {
        script: '',
        language: 'Javascript',
        settings: {
          mode: 'sync',
          action: 'execute',
          target: '',
          args: {}
        }
      }
    })
  },
  {
    name: 'Conditional',
    type: 'conditional',
    description: 'Conditionally execute an instruction',
    icon: 'bx bx-git-repo-forked bx-rotate-90',
    create: () => ({
      var_name: '',
      type: 'conditional',
      execute: {
        operands_list: [],
        flow_list: [[]],
      }
    })
  },
  {
    name: 'API Call',
    type: 'api_call',
    description: 'Add an API call',
    icon: 'bx bx-world',
    create: () => ({
      var_name: '',
      type: 'api_call',
      execute: {
        api_declaration_id: '',
        domain: '',
        params: {}
      }
    })
  },
  {
    name: 'GenAI',
    type: 'ai_generated_code',
    description: 'Generate an AI instruction',
    icon: 'bx bx-bot',
    create: () => ({
      var_name: '',
      type: 'ai_generated_code',
      execute: {
        script: '',
        language: 'Javascript',
        settings: {
          mode: 'sync',
          action: 'execute',
          target: '',
          args: {}
        }
      }
    })
  },
  {
    name: 'Time Delta',
    type: 'time_delta',
    description: 'Compare two date time columns',
    icon: 'bx bx-time',
    create: () => ({
      var_name: '',
      type: 'time_delta',
      execute: {
        type: '',
        database: '',
        table_name: '',
        column1: '',
        column2: '',
        timestamp: '',
        threshold_in_minutes: 0,
        filters: []
      }
    })
  }
];
