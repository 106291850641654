import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const EnvironmentSelectionModal = ({
  active,
  onDismiss
}) => {
  const navigate = useNavigate();
  const currentFunction = useSelector((state) => state.current_function);
  const [envName, setEnvName] = useState('');
  const { function_id } = useParams();

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);

  const createFunctionEnv = () => {
    createAsyncCall(async () => {
      await builderV2.functionAddDbsEnv(function_id,
        envName, currentFunction.associated_dbs.docs);
      navigate(0);
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">Create new functions environment</PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display='flex' gap='s'
            background='var(--purple-25)' alignItems='center'
            padding='left-s' radius='s'>
            <PFContainer width='150px' style={{
              borderRight: '2px solid var(--purple-50)'
            }}>
              <PFText >Name</PFText>
            </PFContainer>
            <PFContainer flex={1}>
              <PFInput placeholder='Environment name'
                value={envName} onChange={(e) => setEnvName(e.target.value)} />
            </PFContainer>

          </PFContainer>
          <PFContainer margin="top-l" display="flex" justifyContent="end">
            <PFButton onClick={createFunctionEnv}
              loading={createLoading}
              disabled={!envName}
              padding="horizontal-xl">
              Create environment
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
    </>
  );
};

EnvironmentSelectionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default EnvironmentSelectionModal;
