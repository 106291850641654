/* eslint-disable */
import {
  PFButton,
  PFContainer,
  PFIcon,
  PFSelect
} from 'PFComponents/common';
import PropTypes from 'prop-types';


const QueryAggregation = ({ aggregation, setAggregation, removeAggregation, execute, db }) => {


  const setColumn = (column) => setAggregation({ ...aggregation, column });
  const setOperation = (operation, table_name) => setAggregation({ ...aggregation, operation, alias: `${operation}_${table_name}` });
  const setBoth = (column, operation, table_name) => setAggregation({ ...aggregation, operation, alias: `${operation}_${table_name}`, column });
  const getColumnOptions = () => {
    const targetTable = execute.table;
    const joinTables = execute.joins?.map((join) => {
      return join.table;
    });
    const tables = [targetTable, ...joinTables];
    const result = [];

    tables.forEach((tableName) => {
      const tableSchema = db.tables.find((schema) =>
        schema.table_name === tableName);

      if (tableSchema) {
        const columnOptions = tableSchema.fields.map((field) => {
          return {
            label: `${tableSchema.table_name} -> ${field.alias}`,
            value: `${tableSchema.table_name}.${field.column_name}`
          };
        });
        result.push(...columnOptions);
      }
    });
    return result;
  };

  const operationOptions = [
    {
      label: 'SUM', value: 'sum'
    },
    {
      label: 'AVG', value: 'avg'
    },
    {
      label: 'MIN', value: 'min'
    },
    {
      label: 'MAX', value: 'max'
    },
    {
      label: 'COUNT', value: 'count'
    },
    {
      label: 'COUNT_DISTINCT', value: 'count_distinct'
    },
    {
      label: 'STD_DEV', value: 'std_dev'
    },
    {
      label: 'VAR', value: 'var'
    }
  ]
  return (
    <PFContainer display="grid" gap="s"
      gridTemplateColumns="5fr 5fr 1fr">
      <PFSelect
        placeholder="Column"
        value={aggregation.column}
        onChange={(e) => {
          setColumn(e.target.value);
          if (aggregation.operation) {
            const firstWord = e.target.value.match(/^\w+/);
            setBoth(e.target.value, aggregation.operation, firstWord[0]);
          }
        }}
        options={getColumnOptions()} />
      <PFSelect
        placeholder="Operation"
        value={aggregation.operation}
        onChange={(e) => {
          const firstWord = aggregation.column.match(/^\w+/);
          setOperation(e.target.value, firstWord[0]);
        }}
        options={operationOptions}
        disabled={aggregation.column.length > 0 ? false : true} />
      <PFButton type="support" onClick={removeAggregation}>
        <PFIcon icon="bx bx-trash" size="l" />
      </PFButton>
    </PFContainer>
  );
};

QueryAggregation.propTypes = {
  aggregation: PropTypes.object.isRequired,
  setAggregation: PropTypes.func.isRequired,
  removeAggregation: PropTypes.func.isRequired,
};

export default QueryAggregation;
