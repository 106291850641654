export default [
  { label: '=', value: '=' },
  { label: '>=', value: '>=' },
  { label: '<=', value: '<=' },
  { label: '>', value: '>' },
  { label: '<', value: '<' },
  { label: 'STARTS WITH', value: 'starts_with' },
  { label: 'CONTAINS', value: 'contains' },
  { label: 'ENDS WITH', value: 'ends_with' },

];
