export default [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Currency',
    value: 'currency',
  },
  {
    label: 'Currency decimal',
    value: 'currencyDecimal',
  },
  {
    label: 'International date',
    value: 'dateIntl',
  },
  {
    label: 'US date',
    value: 'dateUS',
  },
  {
    label: 'International date & time',
    value: 'datetimeIntl',
  },
  {
    label: 'US date & time',
    value: 'datetimeUS',
  },
  {
    label: 'Masked name',
    value: 'maskName',
  },
  {
    label: 'Masked numbers',
    value: 'maskNumbers',
  },
  {
    label: 'Phone digists',
    value: 'formatPhoneToDigits',
  },
  {
    label: 'E164 Phone',
    value: 'formatPhoneToE164',
  },
];
