import { PFContainer, PFLine, PFLoader, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import NodeResult from './NodeResult';

const NodeResults = ({ loading, nodeResults }) => {
  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    }

    if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length === 0;
    }

    return value === null || value === undefined;
  }
  const requestDate = new Date(nodeResults.input?.timestamp);
  const responseDate = new Date(nodeResults.output?.timestamp);
  const nodeDate = new Date(nodeResults.start_time);
  return (
    <PFContainer width='70%'>
      <PFText color='var(--black)'>Node results</PFText>
      {loading ? (
        <PFContainer display='flex' justifyContent='center'>
          <PFLoader color='var(--purple-100)' />
        </PFContainer>
      ) : (
        <PFContainer
          margin='top-s'
          className='custom-scroll'
          style={{
            maxHeight: '600px',
            overflow: 'auto',
          }}
          display='flex'
          flexDirection='column'
          gap='l'
          padding='right-s'
        >
          {!isEmpty(nodeResults) ? <PFContainer>
            <PFContainer display='flex' gap='xs'>
              <PFText color='var(--purple-200)'>
                {nodeResults.input.apiDeclarationId}
              </PFText>
              <PFText color='var(--black)'>-</PFText>
              <PFText color='var(--purple-100)'>
                {nodeDate.toLocaleString()}
              </PFText>
            </PFContainer>

            <PFContainer
              background='var(--purple-25)'
              padding='m'
              radius='m'
              margin='top-s'
            >
              {!isEmpty(nodeResults.input.params) ? (
                <>
                  <NodeResult
                    type='request'
                    timestamp={requestDate.toLocaleString()}
                    body={nodeResults.input.params}
                  />
                  <PFLine margin='top-m bottom-m' />
                </>
              ) : (
                <></>
              )}

              <NodeResult
                type='response'
                timestamp={responseDate.toLocaleString()}
                body={nodeResults.output.response}
                message={nodeResults.output?.response?.message}
              />
            </PFContainer>
          </PFContainer> : (
            <PFContainer
              padding='s'
              background='var(--purple-25)'
              radius='s'
              display='flex'
              gap='xs'
              justifyContent='center'
            >
              <PFText
                color='var(--black)'
                style={{
                  display: 'flex',
                }}
              >
                Click
              </PFText>
              <PFText color='var(--purple-100)' weight={'600'}>
                Run
              </PFText>
              <PFText color='black'>to test this Process Flow</PFText>
            </PFContainer>
          )}
        </PFContainer>
      )}
    </PFContainer>
  );
};

NodeResults.propTypes = {
  loading: PropTypes.bool,
  nodeResults: PropTypes.array,
};

export default NodeResults;
