import { PFButton, PFContainer, PFLine, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';


const TABS = ['Design', 'Configure', 'Interact'];

const TabSelection = ({ tab, setTab }) => {
  const renderTabs = () => {
    return TABS.map((tabName) => {
      const selected = tab === tabName;
      const onClick = () => setTab(tabName);

      return (
        <PFButton key={tabName} type="none" onClick={onClick}>
          <PFContainer height={40} display="flex" alignItems="center">
            <PFText color={selected ? 'white' : 'var(--secondary)'}
              style={{
                fontSize: '14px'
              }}>
              {tabName}
            </PFText>
          </PFContainer>
          {selected && <PFLine width={2} color='var(--secondary)' />}
        </PFButton>
      );
    });
  };

  return (
    <PFContainer>
      {renderTabs()}
      <PFLine />
    </PFContainer>
  );
};

TabSelection.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default TabSelection;
