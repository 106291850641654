/* eslint-disable no-unused-vars */
import {
  PFButton,
  PFContainer,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import '../../DesignEditor.css';
import { EditorControl, LayoutGridTemplate } from '../../components';
import { validateSizeInput } from '../../utils';

const conditionalControls = {
  flex: ['align', 'justify', 'direction', 'wrap', 'gap'],
  grid: ['align', 'justify', 'gap']
};

function composeLayout({ composedValue, componentBreakpointStyles }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);

  // eslint-disable-next-line prefer-const
  let modifierComposedValue;

  /**
   * Modifiers
   *
   * Modifier rules from StyleRules.js constant where attribute
   * is represented by an *, to be removed or added to the current
   * breakpoint rule
   *
   */
  // ? Can be abstracted further as * represents modifier values,
  // ?  ('*-' prefix) and ('-*' suffix).
  if (styleValue.includes('*')) {
    // toggle rule: flex-direction : handle *-reverse
    if (componentBreakpointStyles[styleKey]) {
      const modifier = styleValue.split('*');

      modifierComposedValue
        = {
        [styleKey]: componentBreakpointStyles[styleKey]
          .includes(modifier[1])
          ? componentBreakpointStyles[styleKey]
            .replace(modifier[1], '')
          : componentBreakpointStyles[styleKey]
            .concat(modifier[1])
      };
    }
  }

  let configStyles = {
    'flex-direction': componentBreakpointStyles['flex-direction'] || '',
    'flex-wrap': componentBreakpointStyles['flex-wrap'] || '',
    'align-items': componentBreakpointStyles['align-items'] || '',
    'justify-content': componentBreakpointStyles['justify-content'] || '',
    'row-gap': componentBreakpointStyles['row-gap'] || '',
    'column-gap': componentBreakpointStyles['column-gap'] || '',
    'grid-auto-columns': componentBreakpointStyles['grid-auto-columns'] || '',
    'grid-template-rows': componentBreakpointStyles['grid-template-rows'] || '',
    'grid-template-columns': componentBreakpointStyles['grid-template-columns']
      || '',
    ...(!modifierComposedValue
      ? { ...composedValue }
      : { ...modifierComposedValue }),
  };

  /**
   *
   * Main layout rule - display
   *
   * Initial set of rule "display" [block, flex, grid, inline-block, inline,
   * none] that results in initial values of display value specific rules
   *
   * e.g. display: flex, has flex-wrap rules
   *
   */
  if (styleKey === 'display' && styleValue.includes('flex')) {
    // For flex rule
    configStyles = {
      ...configStyles,
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'flex-direction': 'row',
      'flex-wrap': 'nowrap',
      'grid-auto-columns': '',
      'grid-template-rows': '',
      'grid-template-columns': ''
    };
  } else if (styleKey === 'display' && styleValue.includes('grid')) {
    // For grid rule
    configStyles = {
      ...configStyles,
      'align-items': 'flex-start',
      'justify-content': 'flex-start',
      'grid-template-columns': 'minmax(75px, auto) minmax(75px, auto)',
      'grid-template-rows': 'minmax(75px, auto) minmax(75px, auto)',
      'row-gap': '8px',
      'column-gap': '8px'
    };
  } else if (styleKey === 'display'
    && !styleValue.includes('flex')
    && !styleValue.includes('grid')) {
    // For anything that isn't flex or grid
    configStyles = {
      'display': styleValue,
      'flex-direction': '',
      'flex-wrap': '',
      'align-items': '',
      'justify-content': '',
      'row-gap': '',
      'column-gap': '',
      'grid-auto-columns': '',
      'grid-template-rows': '',
      'grid-template-columns': ''
    };
  }

  return {
    configStyles,
  };
}

function getGridTemplates(gridTemplateType, componentBreakpointStyles) {
  if (!gridTemplateType) return [];


  const gridTemplate
    = componentBreakpointStyles[`grid-template-${gridTemplateType}`];
  // eslint-disable-next-line max-len
  const regex = /minmax\([^)]+\)|\b\d+(?:\.\d+)?(?:px|em|%|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc|ch|ex|fr|max-content|min-content)?\b|\bauto\b/g;
  const matches = gridTemplate.match(regex);

  if (matches) {
    return matches.map((match) => {
      const matchSet = match.trim();
      const regex = /minmax\(([^,]+),([^)]+)\)/;
      const matches = matchSet.match(regex);

      if (matches && matches.length === 3) {
        return [matches[1].trim(), matches[2].trim()];
      } else {
        return [matchSet];
      }
    });
  } else {
    return [];
  }
}

function updateGridTemplateAt({
  templateType = 'columns',
  componentBreakpointStyles,
  rulePositionAt,
  ruleValueAt = 0,
  newSize = 0,
  action = 'update'
}) {
  const gridTemplateRule = getGridTemplates(templateType,
    componentBreakpointStyles);

  switch (action) {
    case 'add':
      gridTemplateRule.push(['1fr']);
      break;
    case 'remove':
      gridTemplateRule.splice(rulePositionAt, 1);
      break;
    case 'replace':
      const newValue = gridTemplateRule[rulePositionAt].length === 2
        ? '1fr' : 'minmax(10px, auto)';
      gridTemplateRule[rulePositionAt] = [];
      gridTemplateRule[rulePositionAt].push(newValue);
      break;
    case 'update':
      gridTemplateRule[rulePositionAt][ruleValueAt] = newSize;
      break;
    default:
      return [];
  }

  const updatedArray = gridTemplateRule.map((ruleValue) => {
    if (ruleValue.length === 1) {
      return ruleValue[0];
    } else if (ruleValue.length === 2) {
      return `minmax(${ruleValue[0]}, ${ruleValue[1]})`;
    }
  });

  return updatedArray.join(' ');
}

function handleGridTemplateAction({
  templateType = 'columns',
  configStyles,
  rulePositionAt,
  ruleValueAt = 0,
  newSize = 0,
  action = 'update',
  composeStyles,
  componentBreakpointStyles
}) {
  const updatedGridTemplate
    = updateGridTemplateAt(
      {
        templateType,
        configStyles,
        rulePositionAt,
        ruleValueAt,
        newSize,
        action,
        componentBreakpointStyles
      });

  const composedValue = composeLayout({
    composedValue: {
      [`grid-template-${templateType}`]: updatedGridTemplate
    },
    componentBreakpointStyles
  });

  composeStyles({
    composedAttributes: composedValue,
  });
}


const Layout = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles,
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <PFButton onClick={handleActiveSection}
        type='support' padding='0' height='auto'>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'} />
          Layout
        </PFText>
      </PFButton>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up'
        : 'pf-design-editor__section--down'}
      >
        {
          ruleSet.map((layoutRule, index) => {
            const currentKey = componentBreakpointStyles
              && Object.hasOwn(componentBreakpointStyles, 'display')
              ? componentBreakpointStyles['display'] : undefined;

            const cleanCurrentKey
              = currentKey ? currentKey.split('!')[0].trim() : '';

            const ruleKey = Object.keys(layoutRule)[0];
            const ruleValueControls = layoutRule[ruleKey];

            if (ruleKey !== 'layout') {
              if (conditionalControls[cleanCurrentKey]) {
                if (!conditionalControls[cleanCurrentKey].includes(ruleKey)) {
                  return null;
                }
              } else {
                return null;
              }
            }
            return (
              <PFContainer
                key={
                  `design-editor-${ruleKey}-${index}`
                } className="pf-designer-editor__rule">
                <PFText className='pf-design-editor__rule-title'>
                  {ruleKey}
                </PFText>
                <PFContainer
                  gap={ruleKey === 'gap' ? 'xs' : ''}
                  className=
                  "pf-design-editor__control pf-design-editor__control--small">
                  {
                    ruleValueControls.map((rule, index) => {
                      return (
                        <EditorControl
                          key={`design-editor-rule-${rule.key}-${index}}`}
                          excludeFromRule='-reverse'
                          controlStateRule='-reverse'
                          componentBreakpointStyles={componentBreakpointStyles}
                          onClick={() => {
                            const composedValue = composeLayout({
                              composedValue:
                              {
                                [rule.key]: rule.value
                              }, componentBreakpointStyles
                            });

                            composeStyles({
                              composedAttributes: composedValue,

                            });
                          }}
                          onChange={(event) => {
                            const normalizedInputValue
                              = validateSizeInput(
                                componentBreakpointStyles[
                                rule.key], event.target.value);

                            const composedValue = composeLayout({
                              composedValue: {
                                [rule.key]: normalizedInputValue
                              },
                              componentBreakpointStyles
                            });

                            composeStyles({
                              composedAttributes: composedValue,
                            });
                          }}
                          styleConfig={{ key: rule.key, value: rule.value }}
                          type={typeof rule.type === 'undefined'
                            ? 'editor-control-button' : rule.type}
                        >
                          {rule.label}
                        </EditorControl>
                      );
                    })
                  }
                </PFContainer>
              </PFContainer>);
          })
        }
        {/* Grid column controls */}
        {
          componentBreakpointStyles
            && componentBreakpointStyles['display'] === 'grid'
            ? (
              <PFContainer className='pf-editor-grid-template'>
                <PFContainer className='pf-editor-grid-header'>
                  <PFText className='pf-design-editor__rule-title'>
                    Columns
                  </PFText>
                  <span className='material-symbols-outlined pf-editor-grid-add'
                    onClick={() => {
                      handleGridTemplateAction({
                        templateType: 'columns',
                        componentBreakpointStyles,
                        action: 'add',
                        composeStyles
                      });
                    }}
                  >
                    add
                  </span>
                </PFContainer>
                {
                  getGridTemplates('columns', componentBreakpointStyles)
                    .map((gridTemplateValues, index) => {
                      return (
                        <LayoutGridTemplate
                          ruleLength={getGridTemplates('columns',
                            componentBreakpointStyles).length}
                          fieldValue={gridTemplateValues}
                          handleRemoveRule={() => {
                            handleGridTemplateAction({
                              templateType: 'columns',
                              componentBreakpointStyles,
                              rulePositionAt: index,
                              action: 'remove',
                              composeStyles
                            });
                          }}
                          handleNotationChange={() => {
                            handleGridTemplateAction({
                              templateType: 'columns',
                              componentBreakpointStyles,
                              rulePositionAt: index,
                              action: 'replace',
                              composeStyles
                            });
                          }}
                          handleFieldChange={(
                            templateFieldValues,
                          ) => {
                            const { ruleValueIndex, value }
                              = templateFieldValues;

                            const normalizedInputValue = validateSizeInput(
                              gridTemplateValues[ruleValueIndex],
                              value);

                            handleGridTemplateAction({
                              templateType: 'columns',
                              componentBreakpointStyles,
                              rulePositionAt: index,
                              ruleValueAt: ruleValueIndex,
                              newSize: normalizedInputValue,
                              action: 'update',
                              composeStyles
                            });
                          }}
                          key={index}
                        />);
                    })
                }
              </PFContainer>
            )
            : null
        }
        {
          componentBreakpointStyles
            && componentBreakpointStyles['display'] === 'grid'
            ? (
              <PFContainer className='pf-editor-grid-template'>
                <PFContainer className='pf-editor-grid-header'>
                  <PFText className='pf-design-editor__rule-title'>
                    Rows
                  </PFText>
                  <span className='material-symbols-outlined pf-editor-grid-add'
                    onClick={() => {
                      handleGridTemplateAction({
                        templateType: 'rows',
                        componentBreakpointStyles,
                        action: 'add',
                        composeStyles
                      });
                    }}
                  >
                    add
                  </span>
                </PFContainer>
                {
                  getGridTemplates('rows', componentBreakpointStyles)
                    .map((gridTemplateValues, index) => {
                      return (
                        <LayoutGridTemplate
                          ruleLength={getGridTemplates('rows',
                            componentBreakpointStyles).length}
                          fieldValue={gridTemplateValues}
                          handleRemoveRule={() => {
                            handleGridTemplateAction({
                              templateType: 'rows',
                              componentBreakpointStyles,
                              rulePositionAt: index,
                              action: 'remove',
                              composeStyles
                            });
                          }}
                          handleNotationChange={() => {
                            handleGridTemplateAction({
                              templateType: 'rows',
                              componentBreakpointStyles,
                              rulePositionAt: index,
                              action: 'replace',
                              composeStyles
                            });
                          }}
                          handleFieldChange={(
                            templateFieldValues,
                          ) => {
                            const { ruleValueIndex, value }
                              = templateFieldValues;

                            const normalizedInputValue = validateSizeInput(
                              gridTemplateValues[ruleValueIndex],
                              value);

                            handleGridTemplateAction({
                              templateType: 'rows',
                              componentBreakpointStyles,
                              rulePositionAt: index,
                              ruleValueAt: ruleValueIndex,
                              newSize: normalizedInputValue,
                              action: 'update',
                              composeStyles
                            });
                          }}
                          key={index}
                        />);
                    })
                }
              </PFContainer>
            )
            : null
        }
      </PFContainer>
    </PFContainer >
  );
};

export default Layout;

Layout.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object,
  ruleLength: PropTypes.number
};
