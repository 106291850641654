import { twoapp } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFFunctionEditorHeader } from 'PFComponents/navigation';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AutomationEditorHeader = ({
  save,
  saveLoading,
  saved,
  automation,
  setAutomation,
  jobOptions
}) => {
  const navigate = useNavigate();
  const currentApp = useSelector((state) => state.current_app);
  const appId = currentApp._id;
  const setActivate = (is_active) => setAutomation({
    ...automation,
    is_active
  });

  const [activateAsyncCall, activateLoading,
    activateError, dismissActivateError]
    = useAsyncCall(false);

  const [deactivateAsyncCall, deactivateLoading,
    deactivateError, dismissDeactivateError]
    = useAsyncCall(false);

  const changeAutomation = (e) => {
    const id = e.target.value;
    navigate(`/editor/apps/${appId}/automations/${id}`);
  };

  const backToFunctions = () => {
    navigate(`/apps/${appId}/automations`);
  };

  const deactivateJob = () => {
    deactivateAsyncCall(async () => {
      await twoapp.deactivateJob(appId, automation.id);
      setActivate(false);
    });
  };

  const activateJob = () => {
    activateAsyncCall(async () => {
      await twoapp.activateJob(appId, automation.id);
      setActivate(true);
    });
  };

  return (
    <PFFunctionEditorHeader backAction={backToFunctions}>
      <PFContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <PFContainer display="flex" gap="m">
          <PFContainer width={300}>
            <PFSelect
              className="header"
              prefix="Automation:"
              value={automation?.id}
              onChange={changeAutomation}
              options={jobOptions} />
          </PFContainer>
        </PFContainer>

        <PFContainer display="flex" gap="m">
          {
            automation?.is_active
              ? <PFContainer display='flex' gap='s'
                alignItems='center'>

                <PFContainer width='10px' radius='l'
                  height='10px' background='var(--success)'>

                </PFContainer>
                <PFText size='s'>
                  Active
                </PFText>
              </PFContainer>
              : <PFContainer display='flex' gap='s'
                alignItems='center'>

                <PFContainer width='10px' radius='l'
                  height='10px' background='var(--failure)'>

                </PFContainer>
                <PFText size='s'>
                  Inactive
                </PFText>
              </PFContainer>
          }
          {!saveLoading && saved
            && <PFContainer
              display="flex" alignItems="center" gap="s">
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type="secondary" width={80}
            onClick={save} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
          {
            automation?.is_active
              ? <PFButton type='none' height='auto'
                padding='top-xs bottom-xs left-s right-s'
                width='150px'
                style={{
                  backgroundColor: 'var(--white)',
                  display: 'flex',
                  gap: 'var(--size-xs))',
                  borderRadius: 'var(--radius-l)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} onClick={deactivateJob}>
                {
                  deactivateLoading
                    ? <PFLoader color='var(--purple-200)' size='s' />
                    : <>
                      <PFIcon icon='bx bx-pause' color='var(--purple-100)'
                        size='l' />
                      <PFText color='var(--purple-100)'>Turn off</PFText>
                    </>
                }

              </PFButton>
              : <PFButton type='none' height='auto'
                padding='top-xs bottom-xs left-s right-s'
                width='150px'
                style={{
                  backgroundColor: 'var(--purple-200)',
                  display: 'flex',
                  gap: 'var(--size-xs))',
                  borderRadius: 'var(--radius-l)',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} onClick={activateJob}>
                {
                  activateLoading
                    ? <PFLoader color='var(--white)' size='s' />
                    : <>
                      <PFIcon icon='bx bx-play' color='var(--white)'
                        size='l' />
                      <PFText>Turn on</PFText>
                    </>
                }

              </PFButton>
          }

        </PFContainer>
      </PFContainer>
      <PFAlert
        message={activateError}
        open={!!activateError}
        onClose={dismissActivateError} />
      <PFAlert
        message={deactivateError}
        open={!!deactivateError}
        onClose={dismissDeactivateError} />
    </PFFunctionEditorHeader>
  );
};

AutomationEditorHeader.propTypes = {
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  automation: PropTypes.object,
  setAutomation: PropTypes.func,
  jobOptions: PropTypes.array,
};

export default AutomationEditorHeader;
