import {
  PFButton, PFCheckbox, PFContainer, PFIcon, PFInput, PFSelect, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ShortUniqueId from 'short-unique-id';
import {
  componentProperties, conditionalOperators,
  fieldTypes, validationTypes
} from './common';

const uid = new ShortUniqueId({ length: 2 });

const PropertyControls = ({ component, setComponent }) => {
  const [currentTab, setCurrentTab] = useState('values');

  const currentApp = useSelector((state) => state.current_app);
  const processFlows = currentApp.build.process_flows.docs;

  const flowOptions = processFlows.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });

  const sizeInput = (propertyName, index) => {
    if (propertyName === 'id') {
      return (
        <PFInput
          onChange={(e) => {
            setComponent({
              ...component,
              id: e.target.value
            });
          }}
          value={component.id ? component.id
            : ''}
          key={index}
          style={{ width: 50, height: 30 }}
          fontSize='label'
        />
      );
    }
    return (
      <PFInput
        onChange={(e) => {
          setComponent({
            ...component,
            attributes: {
              ...component.attributes,
              [propertyName]: e.target.value
            }
          });
        }}
        value={component.attributes[propertyName]
          ? component.attributes[propertyName] : ''}
        key={index}
        style={{ width: 50, height: 30 }}
        fontSize='label'
      />
    );
  };

  const sizeSelect = (options, propertyName, index) => {
    return (
      <PFSelect
        options={options}
        onChange={(e) => {
          setComponent({
            ...component,
            attributes: {
              ...component.attributes,
              [propertyName]: e.target.value
            }
          });
        }}
        value={component.attributes[propertyName]
          ? component.attributes[propertyName] : ''}
        key={index}
        style={{
          height: 30,
          backgroundColor: 'var(--purple-400)',
          fontSize: 'var(--text-label)'
        }}
        optionsStyles={{
          color: 'var(--white)'
        }}
        listStyles={{

          border: '1px solid var(--purple-300)',
          backgroundColor: 'var(--purple-500)'
        }}
      />
    );
  };

  const createControl = (componentName) => {
    let controls;
    const fieldType = componentProperties[componentName]?.type;
    if (!componentProperties[componentName]) {
      controls = [{
        type: 'input',
        propertyName: 'id',
        propertyLabel: 'ID'
      }];
    } else {
      controls = [{
        type: 'input',
        propertyName: 'id',
        propertyLabel: 'ID'
      }, ...componentProperties[componentName].controls];
    }

    return controls.map((control, index) => {
      if (control.propertyName === 'name') {
        return (
          <PFContainer key={`pftext-${index}`}
            display='grid' alignItems='center'
            gridTemplateColumns='100px 1fr' gap='s'>

            <PFText size='header'>
              {control.propertyLabel}
            </PFText>
            <PFContainer>
              <PFInput
                onChange={(e) => {
                  setComponent({
                    ...component,
                    attributes: {
                      ...component.attributes,
                      name: e.target.value
                    },
                    settings: {
                      ...component.settings,
                      form: {
                        ...component.settings.form,
                        name: e.target.value
                      }
                    }
                  });
                }}
                value={component.attributes.name
                  ? component.attributes.name : ''}
                style={{ width: 50, height: 30 }}
                fontSize='label'
              />
            </PFContainer>
          </PFContainer>
        );
      }
      if (fieldType === 'meta' && control.propertyName === 'display') {
        return (
          <>
            <PFContainer key={`pftext-${index}`}
              display='grid' alignItems='center'
              gridTemplateColumns='100px 1fr' gap='s'>

              <PFText size='header'>
                {'Name'}
              </PFText>
              <PFContainer>
                <PFInput
                  onChange={(e) => {
                    setComponent({
                      ...component,
                      settings: {
                        ...component.settings,
                        form: {
                          ...component.settings.form,
                          name: e.target.value
                        }
                      }
                    });
                  }}
                  value={component.settings.form.name
                    ? component.settings.form.name : ''}
                  style={{ width: 50, height: 30 }}
                  fontSize='label'
                />
              </PFContainer>
              <PFText size='header'>
                {control.propertyLabel}
              </PFText>
              <PFContainer>
                {sizeSelect(control.options, control.propertyName, index)}
              </PFContainer>
            </PFContainer>
          </>
        );
      }
      if (control.type === 'input') {
        if (fieldType === 'meta' && componentName === 'Metawrapper') {
          return (
            <>
              <PFContainer key={'id-control'}
                display='grid'
                alignItems='center'
                gridTemplateColumns='100px 1fr'
                gap='s'
                style={{
                  marginBottom: '10px'
                }}
              >
                <PFText size='label'>
                  {control.propertyLabel}
                </PFText>
                <PFContainer>
                  {sizeInput(control.propertyName, index)}
                </PFContainer>
              </PFContainer>
              <PFContainer key={'setting-name-control'}
                display='grid' alignItems='center'
                gridTemplateColumns='100px 1fr' gap='s'>
                <PFText size='header'>
                  {'Name'}
                </PFText>
                <PFContainer>
                  <PFInput
                    onChange={(e) => {
                      setComponent({
                        ...component,
                        settings: {
                          ...component.settings,
                          form: {
                            ...component.settings.form,
                            name: e.target.value
                          }
                        }
                      });
                    }}
                    value={component.settings.form.name
                      ? component.settings.form.name : ''}
                    style={{ width: 50, height: 30 }}
                    fontSize='label'
                  />
                </PFContainer>
              </PFContainer>
            </>
          );
        } else {
          return (
            <PFContainer key={`pftext-${index}`}
              display='grid' alignItems='center'
              gridTemplateColumns='100px 1fr' gap='s'>

              <PFText size='label'>
                {control.propertyLabel}
              </PFText>
              <PFContainer>
                {sizeInput(control.propertyName, index)}
              </PFContainer>
            </PFContainer>
          );
        }
      } else {
        return (
          <PFContainer key={`pftexts-${index}`}
            display='grid' alignItems='center'
            gridTemplateColumns='100px 1fr' gap='s'>
            <PFText size='label'>
              {control.propertyLabel}
            </PFText>
            <PFContainer>
              {sizeSelect(control.options, control.propertyName, index)}
            </PFContainer>
          </PFContainer>
        );
      }
    });
  };

  const renderComponentControls = () => {
    return (
      <PFContainer display='flex' flexDirection='column'
        gap='s'>

        {createControl(component.component).map((control, index) => {
          return (
            <PFContainer key={`pfcontrol-${index}`}>

              {control}

            </PFContainer>
          );
        })}

      </PFContainer>
    );
  };

  const FormValues = () => {
    return (
      <PFContainer display='flex'
        justifyContent='space-between' alignItems='center' gap='s'>
        <PFText size='header'>
          Initial values
        </PFText>
        <PFButton
          type='none'
          padding='xs'
          onClick={() => {
            setComponent({
              ...component,
              attributes: {
                ...component.attributes,
                values: {
                  ...component.attributes.values,
                  [`value_${uid()}`]: ''
                }
              }
            });
          }}>

          <PFIcon icon='bx bx-plus' size='l' color='var(--purple-100)' />
        </PFButton>

      </PFContainer>
    );
  };

  const FormValidations = () => {
    return (
      <PFContainer display='flex'
        justifyContent='space-between' alignItems='center' gap='s'>
        <PFText size='header'>
          Fields
        </PFText>
        <PFButton
          type='none'
          padding='xs'
          onClick={() => {
            setComponent({
              ...component,
              attributes: {
                ...component.attributes,
                validations: {
                  ...component.attributes.validations,
                  '': {
                    checks: [],
                    multiple: false,
                    type: ''
                  }
                }
              }
            });
          }}>

          <PFIcon icon='bx bx-plus' size='l' color='var(--purple-100)' />
        </PFButton>

      </PFContainer>
    );
  };

  return (
    <PFContainer padding={'top-m right-s bottom-m left-s'}>
      <PFText margin='bottom-s' size='header'>Attributes</PFText>
      {component.component === 'Form'
        ? <PFContainer display='flex' flexDirection='column' gap='s'>
          <PFContainer display='grid' alignItems='center'
            gridTemplateColumns='100px 1fr' gap='s'>
            <PFText size='label'>
              Form ID
            </PFText>
            <PFInput
              onChange={(e) => {
                setComponent({
                  ...component,
                  attributes: {
                    ...component.attributes,
                    form_id: e.target.value
                  }
                });
              }}
              value={component.attributes.form_id}
              style={{ width: 50, height: 30 }}
              fontSize='label'

            />
          </PFContainer>
          <PFContainer display='grid' alignItems='center'
            gridTemplateColumns='100px 1fr' gap='s'>
            <PFText size='label'>
              Trigger
            </PFText>
            <PFSelect
              options={flowOptions}
              onChange={(e) => {
                setComponent({
                  ...component,
                  attributes: {
                    ...component.attributes,
                    trigger_id: e.target.value
                  }
                });
              }}
              value={component.attributes.trigger_id}
              style={{ height: 30, fontSize: 'var(--text-label)' }}
              optionsStyles={{
                color: 'var(--white)'
              }}
              listStyles={{

                border: '1px solid var(--purple-300)',
                backgroundColor: 'var(--purple-500)'
              }} />
          </PFContainer>

          <PFContainer display='flex'
            justifyContent='space-between' alignItems='center' gap='s'>
            <PFText size='label'>
              Submits on valid
            </PFText>
            <PFCheckbox checked={component.attributes.submit.onValid}
              onChange={() => {
                setComponent({
                  ...component,
                  attributes: {
                    ...component.attributes,
                    submit: {
                      ...component.attributes.submit,
                      onValid: !component.attributes.submit.onValid
                    }

                  }
                });
              }} />
          </PFContainer>

          <PFContainer display='flex'
            alignItems='center' gap='s'>
            <PFButton onClick={() => {
              setCurrentTab('values');
            }} style={{
              backgroundColor: currentTab === 'values'
                ? 'var(--purple-300)' : 'var(--purple-400)',
              flex: 1
            }}>
              <PFText size='label' style={{
                color: currentTab === 'values'
                  ? 'var(--white)' : 'var(--purple-100)'
              }}>
                Initial values
              </PFText>
            </PFButton>

            <PFButton onClick={() => {
              setCurrentTab('validations');
            }} style={{
              backgroundColor: currentTab === 'validations'
                ? 'var(--purple-300)' : 'var(--purple-400)',
              flex: 1
            }}>
              <PFText size='label' style={{
                color: currentTab === 'validations'
                  ? 'var(--white)' : 'var(--purple-100)'
              }}>
                Validations
              </PFText>
            </PFButton>

          </PFContainer>

          {
            currentTab === 'values'
              ? <FormValues />
              : <FormValidations />
          }
          {currentTab === 'values' && Object
            .keys(component.attributes.values)
            .map((field, index) => {
              return (<PFContainer key={`value-${index}`} display='flex'
                justifyContent='space-between' alignItems='center' gap='s'>
                <PFContainer style={{ flex: 1 }}>
                  <PFInput
                    onChange={(e) => {
                      const newValues = Object.fromEntries(
                        Object.entries({ ...component.attributes.values })
                          .map(([key, value]) => {
                            if (key === field) {
                              return [e.target.value, value];
                            } else {
                              return [key, value];
                            }
                          })
                      );
                      const updatedValues = {
                        ...component,
                        attributes: {
                          ...component.attributes,
                          values: newValues
                        }
                      };
                      delete updatedValues.attributes.values[field];
                      setComponent(updatedValues);
                    }}
                    value={field}
                    style={{ width: 50, height: 30 }}
                    fontSize='label'

                  />
                </PFContainer>
                <PFContainer style={{ flex: 1 }}>
                  <PFInput
                    onChange={(e) => {
                      const updatedComponent = { ...component };
                      updatedComponent.attributes.values[field]
                        = e.target.value;
                      setComponent(updatedComponent);
                    }}
                    value={component.attributes.values[field] === null
                      ? '' : component.attributes.values[field]}
                    style={{ width: 50, height: 30 }}
                    fontSize='label'

                  />
                </PFContainer>

                <PFButton
                  type='none'
                  padding='xs'
                  onClick={() => {
                    const updatedComponent = { ...component };
                    delete updatedComponent.attributes.values[field];
                    setComponent({
                      ...component,
                      attributes: {
                        ...component.attributes,
                        values: {
                          ...updatedComponent.attributes.values
                        }
                      }
                    });
                  }}>

                  <PFIcon icon='bx bx-trash' size='m'
                    color='var(--purple-100)' />
                </PFButton>
              </PFContainer>);
            })}
          {currentTab === 'validations' && Object
            .keys(component.attributes.validations)
            .map((field, index) => {
              const validations = component.attributes
                .validations[field].checks;
              return (
                <PFContainer key={`v-container-${index}`}>
                  <PFContainer key={`field-${index}`}
                    margin='bottom-s'
                    radius='s' padding='s' background='var(--purple-350)'>
                    <PFContainer display='flex'
                      justifyContent='space-between'
                      alignItems='center' gap='s'>
                      <PFText size='header'>
                        Field {index + 1}
                      </PFText>
                      <PFButton
                        type='none'
                        padding='xs'
                        onClick={() => {
                          const updatedComponent = { ...component };
                          delete updatedComponent.attributes
                            .validations[field];
                          setComponent({
                            ...component,
                            attributes: {
                              ...component.attributes,
                              validations: {
                                ...updatedComponent.attributes.validations
                              }
                            }
                          });
                        }}>

                        <PFIcon icon='bx bx-trash' size='m'
                          color='var(--purple-100)' />
                      </PFButton>
                    </PFContainer>

                    <PFContainer display='grid' alignItems='center'
                      gridTemplateColumns='60px 1fr' gap='s' margin='bottom-xs'>
                      <PFText size='label'>
                        Name
                      </PFText>
                      <PFSelect

                        options={Object.keys(component.attributes.values)
                          .map((key) => ({
                            label: key,
                            value: key,
                          }))}
                        onChange={(e) => {
                          const newValues = Object.fromEntries(
                            Object.entries({
                              ...component
                                .attributes.validations
                            })
                              .map(([key, value]) => {
                                if (key === field) {
                                  return [e.target.value, value];
                                } else {
                                  return [key, value];
                                }
                              })
                          );
                          const newObject = {
                            ...component,
                            attributes: {
                              ...component.attributes,
                              validations: newValues
                            }
                          };
                          delete newObject.attributes.validations[field];
                          setComponent(newObject);
                        }}
                        value={field}
                        style={{
                          height: 30,
                          backgroundColor: 'var(--purple-400)',
                          fontSize: 'var(--text-label)'
                        }}
                        optionsStyles={{
                          color: 'var(--white)'
                        }}
                        listStyles={{

                          border: '1px solid var(--purple-300)',
                          backgroundColor: 'var(--purple-500)'
                        }}
                      />

                    </PFContainer>
                    <PFContainer display='grid' alignItems='center'
                      gridTemplateColumns='60px 1fr' gap='s'
                      margin='bottom-xs'>
                      <PFText size='label'>
                        Type
                      </PFText>
                      <PFSelect
                        options={fieldTypes}
                        onChange={(e) => {
                          setComponent({
                            ...component,
                            attributes: {
                              ...component.attributes,
                              validations: {
                                ...component.attributes.validations,
                                [field]: {
                                  ...component.attributes.validations[field],
                                  type: e.target.value
                                }
                              }
                            }
                          });
                        }}
                        style={{
                          height: 30,
                          backgroundColor: 'var(--purple-400)',
                          fontSize: 'var(--text-label)'
                        }}
                        optionsStyles={{
                          color: 'var(--white)'
                        }}
                        listStyles={{

                          border: '1px solid var(--purple-300)',
                          backgroundColor: 'var(--purple-500)'
                        }}
                        value={component.attributes.validations[field].type}
                      />

                    </PFContainer>
                    <PFContainer display='flex'
                      justifyContent='space-between'
                      alignItems='center' gap='s'>
                      <PFText size='label'>
                        Multiple
                      </PFText>
                      <PFCheckbox checked={component.attributes.
                        validations[field].multiple === null
                        ? false : component.attributes.
                          validations[field].multiple}
                        onChange={() => {
                          setComponent({
                            ...component,
                            attributes: {
                              ...component.attributes,
                              validations: {
                                ...component.attributes.validations,
                                [field]: {
                                  ...component.attributes.validations[field],
                                  multiple: !component.attributes.
                                    validations[field].multiple
                                }
                              }
                            }
                          });
                        }} />
                    </PFContainer>
                    <PFButton
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      type='none'
                      padding='top-s bottom-s'
                      onClick={() => {
                        setComponent({
                          ...component,
                          attributes: {
                            ...component.attributes,
                            validations: {
                              ...component.attributes.validations,
                              [field]: {
                                ...component.attributes.validations[field],
                                checks: [
                                  ...component.attributes
                                    .validations[field].checks,
                                  {
                                    condition: null,
                                    err: '',
                                    key: '',
                                    val: ''
                                  }
                                ]
                              }
                            }
                          }
                        });
                      }}>
                      <PFIcon icon='bx bx-plus' size='m'
                        color='var(--purple-100)' />
                      <PFText size='label' color='var(--purple-100)' >
                        Add validation
                      </PFText>

                    </PFButton>
                  </PFContainer>
                  {
                    validations?.map((validation, indexV) => {
                      const conditionals = validation.condition;
                      return (
                        <PFContainer key={`v-container-${indexV}`}>
                          <PFContainer key={`validation-${indexV}`}
                            radius='s' padding='s'
                            background='var(--purple-300)'
                            margin='left-m bottom-s'>
                            <PFContainer display='flex'
                              justifyContent=
                              'space-between'
                              alignItems='center' gap='s'>
                              <PFText size='header'>
                                Validation {indexV + 1}
                              </PFText>
                              <PFButton
                                type='none'
                                padding='xs'
                                onClick={() => {
                                  const updatedComponent = { ...component };
                                  const newchecks
                                    = [...updatedComponent
                                      .attributes.validations[field]
                                      .checks].filter(
                                        (check, indexCheck) =>
                                          indexCheck !== indexV);
                                  setComponent({
                                    ...component,
                                    attributes: {
                                      ...component.attributes,
                                      validations: {
                                        ...component.attributes.validations,
                                        [field]: {
                                          ...component.attributes
                                            .validations[field],
                                          checks: newchecks
                                        }
                                      }
                                    }
                                  });
                                }}>

                                <PFIcon icon='bx bx-trash'
                                  size='m' color='var(--purple-100)' />
                              </PFButton>
                            </PFContainer>

                            <PFContainer display='grid' alignItems='center'
                              gridTemplateColumns='60px 1fr'
                              gap='s' margin='bottom-xs'>
                              <PFText size='label'>
                                Type
                              </PFText>
                              <PFSelect
                                options={validationTypes}
                                onChange={(e) => {
                                  const newComponent = { ...component };
                                  newComponent.attributes
                                    .validations[field]
                                    .checks[indexV] = {
                                    ...newComponent.attributes
                                      .validations[field]
                                      .checks[indexV],
                                    key: e.target.value
                                  };
                                  setComponent(newComponent);
                                }}
                                value={validation.key}
                                style={{
                                  height: 30,
                                  backgroundColor: 'var(--purple-400)',
                                  fontSize: 'var(--text-label)'
                                }}
                                optionsStyles={{
                                  fontSize: '12px'
                                }}
                              />

                            </PFContainer>
                            <PFContainer display='grid' alignItems='center'
                              gridTemplateColumns='60px 1fr' gap='s'
                              margin='bottom-xs'>
                              <PFText size='label'>
                                Value
                              </PFText>
                              <PFInput
                                onChange={(e) => {
                                  const newComponent = { ...component };
                                  newComponent.attributes
                                    .validations[field].checks[indexV] = {
                                    ...newComponent.attributes
                                      .validations[field].checks[indexV],
                                    val: e.target.value
                                  };
                                  setComponent(newComponent);
                                }}
                                style={{ height: 30 }}
                                fontSize='label'
                                value={validation.val === null
                                  ? '' : validation.val}
                              />

                            </PFContainer>
                            <PFContainer display='grid' alignItems='center'
                              gridTemplateColumns='60px 1fr' gap='s'>
                              <PFText size='label'>
                                Message
                              </PFText>
                              <PFInput
                                onChange={(e) => {
                                  const newComponent = { ...component };
                                  newComponent.attributes
                                    .validations[field].checks[indexV] = {
                                    ...newComponent.attributes
                                      .validations[field].checks[indexV],
                                    err: e.target.value
                                  };
                                  setComponent(newComponent);
                                }}
                                style={{ height: 30 }}
                                fontSize='label'
                                value={validation.err === null
                                  ? '' : validation.err}
                              />
                            </PFContainer>
                            <PFButton
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                              type='none'
                              padding='top-s bottom-s'
                              onClick={() => {
                                const newComponent = { ...component };
                                newComponent.attributes
                                  .validations[field].checks[indexV] = {
                                  ...newComponent.attributes
                                    .validations[field].checks[indexV],
                                  condition: [
                                    ...newComponent.attributes
                                      .validations[field]
                                      .checks[indexV].condition,
                                    {
                                      key: '',
                                      ref: '',
                                      val: ''
                                    }
                                  ]
                                };
                                setComponent(newComponent);
                              }}>

                              <PFIcon icon='bx bx-plus'
                                size='m' color='var(--purple-100)' />
                              <PFText size='label' color='var(--purple-100)' >
                                Add condition
                              </PFText>

                            </PFButton>
                          </PFContainer>
                          {conditionals?.map((condition, indexC) => {
                            return (
                              <PFContainer key={`condition-${indexC}`}
                                margin='left-l bottom-s'
                                radius='s' padding='s'
                                background='var(--purple-100-50)'>
                                <PFContainer display='flex'
                                  justifyContent=
                                  'space-between'
                                  alignItems='center' gap='s'>
                                  <PFText size='header'>
                                    Conditional {indexC + 1}
                                  </PFText>
                                  <PFButton
                                    type='none'
                                    padding='xs'
                                    onClick={() => {
                                      const updatedComponent
                                        = { ...component };
                                      const newConditions
                                        = [...component
                                          .attributes.validations[field]
                                          .checks[indexV].condition].filter(
                                            (check, indexCon) =>
                                              indexCon !== indexC);
                                      updatedComponent.attributes
                                        .validations[field]
                                        .checks[indexV]
                                        .condition = newConditions;

                                      setComponent({ ...updatedComponent });
                                    }}>

                                    <PFIcon icon='bx bx-trash'
                                      size='m' color='var(--purple-100)' />
                                  </PFButton>
                                </PFContainer>

                                <PFContainer
                                  display='grid'
                                  alignItems='center'
                                  gridTemplateColumns='60px 1fr'
                                  gap='s' margin='bottom-xs'>
                                  <PFText size='label'>
                                    Field
                                  </PFText>
                                  <PFSelect
                                    options={Object.keys(component
                                      .attributes.values)
                                      .map((key) => ({
                                        label: key,
                                        value: key,
                                      }))}
                                    onChange={(e) => {
                                      const newComponent = { ...component };
                                      newComponent.attributes
                                        .validations[field]
                                        .checks[indexV].condition[indexC] = {
                                        ...newComponent.attributes
                                          .validations[field]
                                          .checks[indexV].condition[indexC],
                                        ref: e.target.value
                                      };
                                      setComponent(newComponent);
                                    }}
                                    value={condition.ref}
                                    style={{
                                      height: 30,
                                      backgroundColor: 'var(--purple-400)',
                                      fontSize: 'var(--text-label)'
                                    }}
                                    optionsStyles={{
                                      color: 'var(--white)'
                                    }}
                                    listStyles={{

                                      border: '1px solid var(--purple-300)',
                                      backgroundColor: 'var(--purple-500)'
                                    }}
                                  />

                                </PFContainer>
                                <PFContainer
                                  display='grid'
                                  alignItems='center'
                                  gridTemplateColumns='60px 1fr' gap='s'
                                  margin='bottom-xs'>
                                  <PFText size='label'>
                                    Operator
                                  </PFText>
                                  <PFSelect
                                    options={conditionalOperators}
                                    onChange={(e) => {
                                      const newComponent = { ...component };
                                      newComponent.attributes
                                        .validations[field]
                                        .checks[indexV].condition[indexC] = {
                                        ...newComponent.attributes
                                          .validations[field]
                                          .checks[indexV].condition[indexC],
                                        key: e.target.value
                                      };
                                      setComponent(newComponent);
                                    }}
                                    value={condition.key}
                                    style={{
                                      height: 30,
                                      backgroundColor: 'var(--purple-400)',
                                      fontSize: 'var(--text-label)'
                                    }}
                                    optionsStyles={{
                                      color: 'var(--white)'
                                    }}
                                    listStyles={{

                                      border: '1px solid var(--purple-300)',
                                      backgroundColor: 'var(--purple-500)'
                                    }}
                                  />

                                </PFContainer>
                                <PFContainer
                                  display='grid' alignItems='center'
                                  gridTemplateColumns='60px 1fr' gap='s'>
                                  <PFText size='label'>
                                    Value
                                  </PFText>
                                  <PFInput
                                    onChange={(e) => {
                                      const newComponent = { ...component };
                                      newComponent.attributes
                                        .validations[field]
                                        .checks[indexV].condition[indexC] = {
                                        ...newComponent.attributes
                                          .validations[field]
                                          .checks[indexV].condition[indexC],
                                        val: e.target.value
                                      };
                                      setComponent(newComponent);
                                    }}
                                    style={{ height: 30, width: '100px' }}
                                    fontSize='label'
                                    value={condition.val === null
                                      ? '' : condition.val}
                                  />

                                </PFContainer>
                              </PFContainer>
                            );
                          })}
                        </PFContainer>
                      );
                    })
                  }

                </PFContainer>
              );
            })}
        </PFContainer>
        : renderComponentControls()}
    </PFContainer>
  );
};

PropertyControls.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
};


export default PropertyControls;

