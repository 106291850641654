import FIELDS_NAMES from './FIELDS_NAMES';
const { NULLABLE, MAX_LENGTH, UNIQUE, DEFAULT_VALUE,
  LENGTH, SCALE, INDEX, TABLE, COLUMN
} = FIELDS_NAMES;
export const FIELD_TYPES = {
  INPUT: 'input',
  SELECT: 'select'
};
const { INPUT, SELECT } = FIELD_TYPES;

export default {
  ['alias']: {
    name: 'alias',
    type: INPUT,
    label: 'Field name',
    placeholder: 'eg. Name',
    gridColumn: 'span 4'
  },
  ['column_name']: {
    name: 'alias',
    type: INPUT,
    label: 'Field name',
    placeholder: 'eg. Name',
    gridColumn: 'span 4'
  },
  [MAX_LENGTH]: {
    name: MAX_LENGTH,
    type: INPUT,
    label: 'Max length',
    placeholder: 'eg. 100',
    gridColumn: 'span 2'
  },
  [DEFAULT_VALUE]: {
    name: DEFAULT_VALUE,
    type: INPUT,
    label: 'Default value',
    placeholder: 'eg. Steve',
    gridColumn: 'span 3'
  },
  [LENGTH]: {
    name: LENGTH,
    type: INPUT,
    label: 'Length',
    placeholder: 'eg. 20',
    gridColumn: 'span 4',
    inputType: 'number'
  },
  [SCALE]: {
    name: SCALE,
    type: INPUT,
    label: 'Scale',
    placeholder: 'eg. 2',
    gridColumn: 'span 2',
    inputType: 'number'
  },
  [NULLABLE]: {
    name: NULLABLE,
    type: SELECT,
    label: 'Nullable',
    placeholder: '',
    options: [
      { label: 'YES', value: 'true' },
      { label: 'NO', value: 'false' },
    ],
    gridColumn: 'span 2'
  },
  [UNIQUE]: {
    name: UNIQUE,
    type: SELECT,
    label: 'Unique',
    placeholder: '',
    options: [
      { label: 'YES', value: 'true' },
      { label: 'NO', value: 'false' },
    ],
    gridColumn: 'span 2'
  },
  [INDEX]: {
    name: INDEX,
    type: SELECT,
    label: 'Index',
    placeholder: '',
    options: [
      { label: 'YES', value: 'true' },
      { label: 'NO', value: 'false' },
    ],
    gridColumn: 'span 3'
  },
  [TABLE]: {
    name: TABLE,
    type: SELECT,
    label: 'Table',
    placeholder: '',
    gridColumn: 'span 3'
  },
  [COLUMN]: {
    name: COLUMN,
    type: SELECT,
    label: 'Column',
    placeholder: '',
    gridColumn: 'span 3'
  },

};
