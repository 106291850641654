import { twoapp } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFLoader,
  PFModal,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AutomationSelectionModal = ({
  active,
  onDismiss,
  jobs
}) => {
  const navigate = useNavigate();

  const currentApp = useSelector((state) => state.current_app);
  const appId = currentApp._id;
  const processflows = currentApp.build.process_flows.docs;
  const pfOptions = processflows.map((pf) => ({
    label: pf.alias, value: pf._id
  }));

  const [selectedPf, setSelectedPf] = useState('');

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);
  const createJob = () => {
    createAsyncCall(async () => {
      const name = `Automation ${jobs.length + 1}`;

      const { id: jobId }
        = await twoapp.createJob(
          appId, {
          alias: name,
          identifier: selectedPf,
          cron_expression: '0 0/15 * * * ?',
          type: 'PROCESS_FLOW',
          app_id: appId,
          domain: null,
          input_params: {
            form_id: 'variables',
            values: {}
          },
          is_active: false
        });
      navigate(`/editor/apps/${appId}/automations/${jobId}`);
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">Select a processflow</PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer margin="top-l">
            <PFSelect value={selectedPf} options={pfOptions} onChange={(e) => {
              setSelectedPf(e.target.value);
            }} />
            <PFButton width={'100%'} margin='top-m' disabled={!selectedPf}
              onClick={createJob}>
              {
                createLoading
                  ? <PFLoader color='var(--purple-200)' />
                  : <PFText color='var(--white)'>Create</PFText>
              }

            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
    </>
  );
};

AutomationSelectionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  jobs: PropTypes.array
};

export default AutomationSelectionModal;
