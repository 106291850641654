import styled from 'styled-components';

export const Toast = styled.div.attrs({ role: 'alert' })<{
  variant: string | undefined;
  configStyles?: string | undefined;
}>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 3rem;
  border-radius: 0.5rem;
  padding: 1rem;
  padding-right: 2rem;
  color: white;
  background-color: ${(props) => {
    switch (props.variant) {
      case 'default':
        return '#004DAD';
      case 'success':
        return '#026363';
      case 'warning':
        return '#FE9902';
      case 'danger':
        return '#AF0A45';
    }
  }};

  ${(props) => props.configStyles};
`;
export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 0.5rem;
`;
