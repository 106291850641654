import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFFunctionEditorHeader } from 'PFComponents/navigation';

const OperationEditorHeader = ({
  opId,
  openTesting,
  save,
  saveLoading,
  saved
}) => {
  const navigate = useNavigate();

  const { operations, _id: funcId } = useSelector(
    (state) => state.current_function);

  const operationsOptions = operations.map((op) => {
    return { label: op.name, value: op._id };
  });

  const changeOperation = (e) => {
    const id = e.target.value;
    navigate(`/editor/functions/${funcId}/operations/${id}`);
  };

  const backToFunctions = () => {
    navigate(`/functions/${funcId}/operations`);
  };

  return (
    <PFFunctionEditorHeader backAction={backToFunctions}>
      <PFContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <PFContainer display="flex" gap="m">
          <PFContainer width={300}>
            <PFSelect
              className="header"
              prefix="Operation:"
              value={opId}
              onChange={changeOperation}
              options={operationsOptions} />
          </PFContainer>
          <PFButton type="secondary" width={80} onClick={openTesting}>
            Test
          </PFButton>
        </PFContainer>
        <PFContainer display="flex" gap="m">
          {!saveLoading && saved
            && <PFContainer
              display="flex" alignItems="center" gap="s">
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type="secondary" width={80}
            onClick={save} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
    </PFFunctionEditorHeader>
  );
};

OperationEditorHeader.propTypes = {
  opId: PropTypes.string.isRequired,
  openTesting: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default OperationEditorHeader;
