import Rating from '@mui/material/Rating';
import { format } from 'PFUtils';

export default {
  'String': {
    type: 'string',
    width: 300
  },
  'Integer': {
    type: 'number',
    width: 100
  },
  'Decimal': {
    type: 'number',
    width: 100
  },
  'Auto Increment': {
    type: 'number',
    width: 100
  },
  'Boolean': {
    type: 'checkbox',
    width: 100
  },
  'Date & Time': {
    type: 'datetime-local',
    valueGetter: ({ value }) => value && new Date(value),
    width: 200
  },
  'Select': {
    type: 'singleSelect',
    width: 200
  },
  'Currency': {
    type: 'number',
    valueFormatter: ({ value }) => format.currency(value),
    width: 200
  },
  'Email': {
    type: 'string',
    width: 200
  },
  'Phone': {
    type: 'string',
    valueFormatter: ({ value }) => format.phoneNumber(value),
    width: 150
  },
  'Rating': {
    type: 'number',
    width: 140,
    renderCell: (params) => <Rating readOnly value={params.value} />
  },
  'Big Integer': {
    type: 'number',
    width: 100
  },
  'Short String': {
    type: 'string',
    width: 200
  },
  'Long String': {
    type: 'string',
    width: 400
  },
  'Date': {
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    width: 200
  },
  'Float': {
    type: 'number',
    width: 100
  },
  'Money': {
    type: 'number',
    valueFormatter: ({ value }) => format.currency(value),
    width: 200
  }
};
