/* eslint-disable */
import {
  PFButton,
  PFContainer, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import '../../DesignEditor.css';
import { validateSizeInput } from '../../utils';
import EditorControl from '../Controls/EditorControl';
import './Spacings.css';

function composeSpacings({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);

  const configStyles = {
    [styleKey]: styleValue
  };

  return {
    configStyles
  };
}

const Spacings = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles,
}) => {
  return (
    <PFContainer>
      <PFButton onClick={handleActiveSection}
        type='support' padding='left-s right-s' height='auto'>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'} />
          Spacings
        </PFText>
      </PFButton>
      <PFContainer
        className={`${!activeSection
          ? `pf-design-editor__section--up`
          : `pf-design-editor__section--down`}`}>
        <PFContainer className='spacing-control'>
          <PFContainer className='trapezoid trapezoid-m-bottom'></PFContainer>
          <PFContainer className='trapezoid trapezoid-m-top'></PFContainer>
          <PFContainer className='trapezoid trapezoid-m-left'></PFContainer>
          <PFContainer className='trapezoid trapezoid-m-right'></PFContainer>
          <PFContainer className='trapezoid trapezoid-p-left'></PFContainer>
          <PFContainer className='trapezoid trapezoid-p-right'></PFContainer>
          <PFContainer className='trapezoid trapezoid-p-bottom'></PFContainer>
          <PFContainer className='trapezoid trapezoid-p-top'></PFContainer>
          <PFContainer className='inner-rectangle'></PFContainer>
          <PFText size='s' style={{
            position: 'absolute',
            left: '35px',
            top: '10px'
          }}>Margin</PFText>
          {
            ruleSet['Margin'].map((rule, index) => {
              const ruleKey = Object.keys(rule)[0];
              const ruleValueControls = rule[ruleKey];
              return (
                <Fragment
                  key={`design-editor-rule-${rule.key}-${index}}`}
                >
                  {
                    ruleValueControls.map((rule, index) => {
                      return (
                        <PFContainer className={rule.className} key={index}>
                          <PFContainer className='vertical'>
                            <EditorControl
                              className={`spacing-input ${rule.key}`}
                              key={`design-editor-rule-${rule.key}-${index}}`}
                              componentBreakpointStyles={componentBreakpointStyles}
                              typeConfig={undefined}
                              onChange={(event) => {
                                const normalizedInputValue
                                  = validateSizeInput(
                                    componentBreakpointStyles[
                                    rule.key], event.target.value);

                                const composedValue = composeSpacings({
                                  alias: undefined,
                                  composedValue:
                                    { [rule.key]: normalizedInputValue },
                                  componentBreakpointStyles
                                });
                                composeStyles({
                                  composedAttributes: composedValue,
                                });
                              }}
                              styleConfig={{
                                key: rule.key,
                                value: rule.value, alias: rule.alias
                              }}
                              type={typeof rule.type === 'undefined'
                                ? 'editor-control-button' : rule.type}>
                              {rule.label}
                            </EditorControl>
                          </PFContainer>
                        </PFContainer>
                      );
                    })
                  }
                </Fragment>
              );
            })
          }
          <PFContainer width='60%' height='60%'
            style={{
              position: 'relative',

            }}>
            <PFText size='s' style={{
              position: 'absolute',
              left: '70px',
              top: '-95px'
            }}>Padding</PFText>
            {
              ruleSet['Padding'].map((rule, index) => {
                const ruleKey = Object.keys(rule)[0];
                const ruleValueControls = rule[ruleKey];
                return (
                  <Fragment
                    key={`design-editor-rule-${rule.key}-${index}}`}
                  >
                    {
                      ruleValueControls.map((rule, index) => {
                        return (
                          <PFContainer className={rule.className} key={index}>
                            <PFContainer className='vertical'>
                              <EditorControl
                                className={`spacing-input ${rule.key}`}
                                key={`design-editor-rule-${rule.key}-${index}}`}
                                componentBreakpointStyles={componentBreakpointStyles}
                                typeConfig={undefined}
                                onChange={(event) => {
                                  const normalizedInputValue
                                    = validateSizeInput(
                                      componentBreakpointStyles[
                                      rule.key], event.target.value);

                                  const composedValue = composeSpacings({
                                    alias: undefined,
                                    composedValue:
                                      { [rule.key]: normalizedInputValue },
                                    componentBreakpointStyles
                                  });
                                  composeStyles({
                                    composedAttributes: composedValue,

                                  });
                                }}
                                styleConfig={{
                                  key: rule.key,
                                  value: rule.value, alias: rule.alias
                                }}
                                type={typeof rule.type === 'undefined'
                                  ? 'editor-control-button' : rule.type}>
                                {rule.label}
                              </EditorControl>
                            </PFContainer>
                          </PFContainer>
                        );
                      })
                    }
                  </Fragment>
                );
              })
            }

          </PFContainer>


        </PFContainer>
      </PFContainer>
    </PFContainer >
  );
};

export default Spacings;

Spacings.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.object.isRequired,
  componentBreakpointStyles: PropTypes.object
};

