import {
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton,
  PFLine,
  PFLoader,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { UpdateFilters, UpdateOrigin, UpdateValues } from './components';


const UpdateRecord = ({ index, instruction, setInstruction, isCreated }) => {
  const { execute } = instruction;
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [db_fetch, alerts] = useAsyncCalls(false);
  const currentUserId = useSelector((state) => state.user._id);

  const { docs: databases } = useSelector(
    (state) => state.current_function.associated_dbs);
  const [db, setDb] = useState(null);

  const tableSelected = !!db && !!execute?.schema_db && !!execute?.table;
  const table = tableSelected
    ? db.tables.find((table) => table.table_name === execute.table)
    : null;
  const columnOptions = !!table
    ? table.fields.map((field) => ({
      label: field.alias, value: field.column_name,
    }))
    : null;

  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  useEffect(() => {
    db_fetch.asyncCall(async () => {
      if (!!execute.schema_db) {
        const database = await builderTest(currentUserId)
          .getDatabase(execute.schema_db);
        setDb(database);
      }
    });
  }, [execute.schema_db]);

  const setSchemaDB = (schema_db) => setExecute({
    schema_db, schema: `$\{${schema_db}\}`,
    table: '', filters: [], new_values: {},
  });
  const setTable = (table) => setExecute({
    ...execute, table, filters: [], new_values: {},
  });
  const setFilters = (filters) => setExecute({ ...execute, filters });
  const setNewValues = (new_values) => setExecute({ ...execute, new_values });

  return (
    <>
      <PFContainer type="form" padding="m" radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex"
          justifyContent="space-between" alignItems="center">
          <PFContainer height={30} display="flex" alignItems="center" gap="s">
            {
              !isCollapsed && <PFIconButton
                icon="bx bx-chevron-left"
                onClick={() => setType('')} />
            }
            <PFText type="secondary">Instruction {index + 1}:</PFText>
            <PFText>Update record</PFText>
            {db_fetch.loading
              ? <PFLoader color="var(--secondary)" size="s"
                margin="top-xs horizontal-s" />
              : null
            }
          </PFContainer>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed
              ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
          </PFButton>
        </PFContainer>
        <PFContainer display={isCollapsed
          ? 'none' : 'flex'} flexDirection='column' gap='m'>
          <PFLine />
          <PFContainer display="flex" flexDirection="column" gap="l">
            <UpdateOrigin
              databases={databases}
              db={db}
              schemaDB={execute?.schema_db}
              setSchemaDB={setSchemaDB}
              table={execute?.table}
              setTable={setTable}
              tableSelectionDisabled={!db || db_fetch.loading}
            />
            {tableSelected
              ? <>
                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s">
                    <PFText>Filter by</PFText>
                    <PFText type="support" size="s">
                      Define the conditions needed to locate
                      the record to update
                    </PFText>
                  </PFContainer>
                  <UpdateFilters
                    filters={execute.filters}
                    setFilters={setFilters}
                    columnOptions={columnOptions} />
                </PFContainer>
                <PFContainer>
                  <PFText>Update values</PFText>
                  <UpdateValues
                    newValues={execute?.new_values}
                    setNewValues={setNewValues}
                    columnOptions={columnOptions.filter(
                      (op) => op.value !== 'id'
                    )} />
                </PFContainer>
              </>
              : null}
          </PFContainer>
        </PFContainer>

      </PFContainer>
      {alerts()}
    </>
  );
};

UpdateRecord.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default UpdateRecord;
