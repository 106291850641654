
// eslint-disable-next-line max-len
import {
  PFColorpicker,
  PFContainer,
  PFInput,
  PFSelect,
  PFText,
  PFTooltip
} from 'PFComponents/common';

import PropTypes from 'prop-types';
import { EditorDropdownOptions } from '../../constants';

function isRuleActive(
  componentBreakpointStyles,
  styleKey,
  styleValue,
  excludeFromRule = '') {
  if (!!componentBreakpointStyles && componentBreakpointStyles[styleKey]) {
    // Safechecking string to exclude !important in rule value
    const value = componentBreakpointStyles[styleKey].split('!');

    if (excludeFromRule.length) {
      return value[0].split(excludeFromRule)[0].trim() === styleValue;
    } else {
      return value[0].trim() === styleValue;
    }
  }
  return false;
}

const editorControl = {
  'editor-control-button-toggle': ({
    componentBreakpointStyles,
    style,
    styleConfig,
    children,
    controlStateRule,
    onClick,
  }) => {
    let activeClass = 'pf-design-editor__button';

    if (componentBreakpointStyles[styleConfig.key]) {
      activeClass = componentBreakpointStyles[styleConfig.key]
        .includes(controlStateRule)
        ? 'pf-design-editor__button pf-design-editor__button--active'
        : 'pf-design-editor__button';
    }

    return (
      <PFTooltip
        placement='bottom'
        enterNextDelay={1000}
        enterDelay={1000}
        title={
          styleConfig.value
            .replace(/-/g, ' ')
            .replace(/-([a-z])/g, (res, letter) => letter.toUpperCase())
            .replace(/^\w/, (c) => c.toUpperCase())
        }
        arrowStyles={{
          '&:before': {
            border: '1px solid var(--purple-300)',
            backgroundColor: 'var(--purple-500)',
          }
        }}
        tooltipStyles={{
          border: '1px solid var(--purple-300)',
          paddingTop: '7px',
          paddingBottom: '7px',
          marginTop: '8px !important',
          backgroundColor: 'var(--purple-500)',
          fontWeight: 'normal',
          fontFamily: 'Poppins',
          fontSize: 'var(--text-size-xs)',
          // eslint-disable-next-line max-len
          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);'
        }}
      >
        <span
          style={style}
          onClick={onClick}
          className={`material-symbols-outlined ${activeClass}`}>
          {children}
        </span>
      </PFTooltip >
    );
  },
  'editor-control-button': ({
    style,
    styleConfig,
    children,
    componentBreakpointStyles,
    onClick,
  }) => {
    return (
      <PFTooltip
        placement='bottom'
        enterNextDelay={500}
        enterDelay={500}
        title={
          styleConfig.value
            .replace(/-/g, ' ')
            .replace(/-([a-z])/g, (res, letter) => letter.toUpperCase())
            .replace(/^\w/, (c) => c.toUpperCase())
        }
        arrowStyles={{
          '&:before': {
            border: '1px solid var(--purple-300)',
            backgroundColor: 'var(--purple-500)',
          }
        }}
        tooltipStyles={{
          border: '1px solid var(--purple-300)',
          paddingTop: '7px',
          paddingBottom: '7px',
          marginTop: '8px !important',
          backgroundColor: 'var(--purple-500)',
          fontWeight: 'normal',
          fontFamily: 'Poppins',
          fontSize: 'var(--text-size-xs)',
          // eslint-disable-next-line max-len
          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);'
        }} >
        <span
          style={style}
          onClick={onClick}
          className={`material-symbols-outlined
              ${isRuleActive(componentBreakpointStyles,
            styleConfig.key,
            styleConfig.value)
              ? 'pf-design-editor__button pf-design-editor__button--active'
              : 'pf-design-editor__button'}
              `}>
          {children}
        </span>
      </PFTooltip>
    );
  },
  'editor-control-button-text': ({
    onClick,
    children,
    componentBreakpointStyles,
    styleConfig,
    excludeFromRule,
    style,
  }) => {
    return (
      <span
        style={style}
        onClick={onClick}
        className={`${isRuleActive(
          componentBreakpointStyles,
          styleConfig.key,
          styleConfig.value,
          excludeFromRule)
          // eslint-disable-next-line max-len
          ? 'pf-design-editor__button-text pf-design-editor__button-text--active'
          : 'pf-design-editor__button-text'}
              `}>
        {children}
      </span>
    );
  },
  'editor-control-input': ({
    onChange,
    styleConfig,
    className,
    placeholder,
    componentBreakpointStyles
  }) => {
    const currentRuleValue
      = componentBreakpointStyles
        ? componentBreakpointStyles[styleConfig.key] : '';

    return (
      <PFContainer className={`pf-design-editor__sizing-control-input 
        ${className && className}`}>
        <PFInput
          placeholder={placeholder}
          onChange={onChange}
          fontSize="m"
          value={currentRuleValue}
        />
      </PFContainer>
    );
  },
  'editor-control-size': ({
    onChange,
    children,
    styleConfig,
    className,
    style,
    componentBreakpointStyles
  }) => {
    const currentRuleValue
      = componentBreakpointStyles
        ? componentBreakpointStyles[styleConfig.key] || '' : '';

    const sizeValue = (/\d+/g).test(currentRuleValue)
      ? currentRuleValue.match(/\d+/g)[0] : '';
    const sizeUnit = (/[a-zA-Z%]+/g).test(currentRuleValue)
      ? currentRuleValue.match(/[a-zA-Z%]+/g)[0] : 'px';

    if (sizeUnit === 'auto'
      || sizeUnit === 'inherit'
      || sizeUnit === 'initial'
      || sizeUnit === 'unset') {
      return (
        <PFContainer className={`pf-mui-dropdown ${className && className}`}>
          <PFSelect
            style={style}
            onChange={onChange}
            placeholder=''
            value={currentRuleValue}
            className='pf-design-editor__dropdown'
            options={EditorDropdownOptions['Size'].filter((sizeOption) => {
              return sizeOption.value !== 'fr'
                && sizeOption.value !== 'max-content'
                && sizeOption.value !== 'min-content';
            })}
            optionsStyles={{
              color: 'var(--white)'
            }}
            listStyles={{
              borderRadius: '8px',
              border: '1px solid var(--purple-300)',
              backgroundColor: 'var(--purple-500)'
            }}
          />

        </PFContainer >
      );
    } else {
      return (
        <PFContainer style={style} >
          <PFContainer className={`pf-design-editor__sizing-control 
          ${className && className}`}>
            <PFInput
              type='number'
              onChange={onChange}
              fontSize="label"
              value={sizeValue}
              rightContent={(
                <PFContainer className='pf-mui-dropdown'>
                  <PFSelect
                    onChange={onChange}
                    value={sizeUnit}
                    options={
                      EditorDropdownOptions['Size'].filter((sizeOption) => {
                        return sizeOption.value !== 'fr'
                          && sizeOption.value !== 'max-content'
                          && sizeOption.value !== 'min-content';
                      })}
                    optionsStyles={{
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      color: 'var(--white)'
                    }}
                    listStyles={{
                      borderRadius: '8px',
                      border: '1px solid var(--purple-300)',
                      backgroundColor: 'var(--purple-500)'
                    }}
                  />
                </PFContainer>
              )}
            />
          </PFContainer>
          {
            children
              ? <PFText
                padding='xs'
                size='s'
                style={{ textAlign: 'center' }}
              >
                {children}</PFText>
              : null
          }
        </PFContainer >
      );
    }
  },
  'editor-control-dropdown': ({
    onChange,
    style,
    styleConfig,
    typeConfig,
    componentBreakpointStyles
  }) => {
    const currentRuleValue
      = componentBreakpointStyles
        ? componentBreakpointStyles[styleConfig.key] : '';

    const options
      = typeof EditorDropdownOptions[typeConfig] !== undefined
        ? EditorDropdownOptions[typeConfig] : [{
          label: 'No options',
          value: ''
        }];

    return (
      <div className='pf-mui-dropdown'>
        <PFSelect
          style={{ fontSize: '12px', ...style }}
          onChange={onChange}
          placeholder=''
          value={currentRuleValue || ''}
          className='pf-design-editor__dropdown'
          options={options}
          optionsStyles={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            color: 'var(--white)'
          }}
          listStyles={{
            borderRadius: '8px',
            border: '1px solid var(--purple-300)',
            backgroundColor: 'var(--purple-500)'
          }}
        />
      </div>
    );
  },
  'editor-control-colorpicker': ({
    onChange,
    style,
    styleConfig,
    componentBreakpointStyles
  }) => {
    const currentRuleValue
      = componentBreakpointStyles
        ? componentBreakpointStyles[styleConfig.key] : '';

    return (
      <PFColorpicker
        parentContainer='pf-component-menu-toolbar'
        controlId={styleConfig.key}
        onChange={onChange}
        style={style}
        value={currentRuleValue}
      />
    );
  },

};

function EditorControl({
  controlStateRule,
  componentBreakpointStyles,
  children,
  className,
  excludeFromRule,
  onChange,
  onClick,
  styleConfig,
  type,
  typeConfig,
  style,
  placeholder
}) {
  if (type) {
    return editorControl[type]({
      controlStateRule,
      componentBreakpointStyles,
      children,
      className,
      excludeFromRule,
      onChange,
      onClick,
      styleConfig,
      type,
      typeConfig,
      style,
      placeholder
    });
  } else {
    return null;
  }
}

export default EditorControl;

EditorControl.propTypes = {
  controlStateRule: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  excludeFromRule: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  styleConfig: PropTypes.object,
  type: PropTypes.string,
  typeConfig: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  componentBreakpointStyles: PropTypes.object
};
