/* eslint-disable max-len */

const PFIconComponentTelephone = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="5.4834" y="17.373" width="44" height="16" rx="3" fill="#332851" />
      <path d="M14.9434 24.933L14.6234 26.433H15.9634V27.473H14.4034L13.9934 29.373H12.9234L13.3334 27.473H11.3434L10.9334 29.373H9.8634L10.2734 27.473H8.7434V26.433H10.4934L10.8134 24.933H9.3234V23.883H11.0334L11.4434 21.973H12.5134L12.1034 23.883H14.0934L14.5034 21.973H15.5734L15.1634 23.883H16.5434V24.933H14.9434ZM13.8734 24.933H11.8834L11.5634 26.433H13.5534L13.8734 24.933ZM23.2637 24.933L22.9437 26.433H24.2837V27.473H22.7237L22.3137 29.373H21.2437L21.6537 27.473H19.6637L19.2537 29.373H18.1837L18.5937 27.473H17.0637V26.433H18.8137L19.1337 24.933H17.6437V23.883H19.3537L19.7637 21.973H20.8337L20.4237 23.883H22.4137L22.8237 21.973H23.8937L23.4837 23.883H24.8637V24.933H23.2637ZM22.1937 24.933H20.2037L19.8837 26.433H21.8737L22.1937 24.933ZM29.154 25.273V26.233H25.034V25.273H29.154ZM36.6426 24.933L36.3226 26.433H37.6626V27.473H36.1026L35.6926 29.373H34.6226L35.0326 27.473H33.0426L32.6326 29.373H31.5626L31.9726 27.473H30.4426V26.433H32.1926L32.5126 24.933H31.0226V23.883H32.7326L33.1426 21.973H34.2126L33.8026 23.883H35.7926L36.2026 21.973H37.2726L36.8626 23.883H38.2426V24.933H36.6426ZM35.5726 24.933H33.5826L33.2626 26.433H35.2526L35.5726 24.933Z" fill="#AD90FF" />
      <line x1="40.15" y1="18.1445" x2="40.15" y2="32.1445" stroke="#BEA7FF" strokeWidth="0.3" />
    </svg>
  );
};

export default PFIconComponentTelephone;
