import { PFContainer } from 'PFComponents/common';
import {
  PFDashboardHeader
} from 'PFComponents/navigation';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserProfileSidebar } from './components';
/* eslint-disable max-len */
import UserUpdatePassword from './components/UserUpdatePassword/UserUpdatePassword';

const PFLayoutAppDashboard = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <PFDashboardHeader />
        <PFContainer padding="horizontal-l vertical-m"
          display="flex" gap="xl">
          <UserProfileSidebar />
          <PFContainer flex={1}>
            <PFContainer>
              <Routes>
                <Route path="/" element={<></>} />
                <Route path="/update-password/*"
                  element={<UserUpdatePassword />} />
                <Route path="*" element={<Navigate to="/profile" />} />
              </Routes>
            </PFContainer>
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

export default PFLayoutAppDashboard;
