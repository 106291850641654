import {
  PFButton,
  PFContainer,
  PFIcon, PFSelect
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';

const Query = ({ filters, setFilters, columnOptions,
  setCurrentOn, joinOptions, tableName, db_schema }) => {
  const addFilter = () => {
    setFilters([...filters, {
      column: '', operator: '=', value: '', separator: 'AND', cast: '',
    }]);
  };

  const renderFilters = () => {
    return filters.map((filter, i) => {
      const setFilter = (filter) => {
        filters[i] = filter;
        setFilters(filters);
        setCurrentOn(filters);
      };
      const removeFilter = () => {
        filters.splice(i, 1);
        setFilters(filters);
        setCurrentOn(filters);
      };

      const setColumn = (column) => setFilter({ ...filter, column });
      const setValue = (value) => setFilter({ ...filter, value });
      const jfOptions = Object.fromEntries(Object
        .entries(joinOptions)
        .filter(([key]) => key !== `${tableName}_${db_schema}`));
      const filteredOptions = Object.values(jfOptions)
        .reduce((acc, arr) => acc.concat(arr), []);

      return (
        <React.Fragment key={i}>
          <PFSelect
            placeholder="Column"
            value={filter.column}
            onChange={(e) => setColumn(e.target.value)}
            options={columnOptions} />
          <PFContainer
            radius='s' justifyContent='center'
            alignItems='center' display='flex'
            style={{ fontSize: 'var(--text-size-m)' }}>
            =
          </PFContainer>
          <PFSelect
            placeholder="Value"
            value={filter.value}
            onChange={(e) => setValue(e.target.value)}
            options={filteredOptions} />
          <PFButton type="support" onClick={removeFilter}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="grid" gap="s" margin="top-s"
      gridTemplateColumns="1fr .3fr 1fr .2fr">
      {renderFilters()}
      <PFButton type="support" onClick={addFilter}
        style={{ justifySelf: 'start' }}>
        + add filter
      </PFButton>
    </PFContainer>
  );
};

Query.propTypes = {
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  columnOptions: PropTypes.array,
  setCurrentOn: PropTypes.func,
  joinOptions: PropTypes.array,
  tableName: PropTypes.string,
  db_schema: PropTypes.string,
};

export default Query;
