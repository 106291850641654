import { Fragment } from 'react';
import styled from 'styled-components';
// Although this is a frowned upon practice (styling a fragment), this serves as a template for our text components
export const Element = styled(Fragment)<{ configStyles: string }>`
    ${(props) => props.configStyles};
`;
export const TextInput = styled.textarea`
    width: inherit;
    height: 100%;
    background: none;
    outline: none;
    box-shadow: none;
    resize: none;
    overflow: hidden;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
`;
export const EditableElement = styled.span`
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
`;
