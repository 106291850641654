import { createSlice } from '@reduxjs/toolkit';

const currentFunctionSlice = createSlice({
  name: 'current_function',
  initialState: null,
  reducers: {
    setCurrentFunction: (_state, { payload }) => {
      return payload === null ? null : { ...payload };
    },
    updateOperation: (state, { payload }) => {
      const { _id, name } = payload;
      state.operations = state.operations.map((op) => {
        if (op._id === _id) op.name = name;
        return op;
      });
      return state;
    },
    removeOperation: (state, { payload }) => {
      state.operations = state.operations.filter((op) => op._id !== payload);
      return state;
    },
    addDB: (state, { payload }) => {
      state.associated_dbs.count++;
      state.associated_dbs.docs.push(payload);
      return state;
    },
    removeDB: (state, { payload }) => {
      state.associated_dbs.count--;
      state.associated_dbs.docs = state.associated_dbs.docs.filter(
        (db) => db._id !== payload);
      return state;
    },
    addPermission: (state, { payload }) => {
      state.permissions.accounts.count++;
      state.permissions.accounts.docs.push(payload);
      return state;
    },
    updatePermission: (state, { payload }) => {
      const { _id, role } = payload;
      state.permissions.accounts.docs
        = state.permissions.accounts.docs.map((acc) => {
          if (acc._id === _id) acc.role = role;
          return acc;
        });
      return state;
    },
    removePermission: (state, { payload: _id }) => {
      state.permissions.accounts.docs
        = state.permissions.accounts.docs.filter((acc) => acc._id !== _id);
      return state;
    },
  }
});

export default currentFunctionSlice.reducer;
export const {
  setCurrentFunction,
  updateOperation,
  removeOperation,
  addDB,
  removeDB,
  addPermission,
  updatePermission,
  removePermission,
} = currentFunctionSlice.actions;
