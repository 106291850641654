import styled from 'styled-components';

const Parragraph = styled.p<{
    className: string;
    configStyles: string;
}>`
    font-size: ${(props) => props.theme?.typography?.base1 || '1rem'};
    font-weight: normal;

    ${(props) => props.configStyles}
`;

export { Parragraph };
