import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';


/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
 */

/**
 * Renders just the relevant information of the node.
 *
 * @param {Object} props - Node summary props.
 * @param {ProcessFlowNode} props.node - Node to summarize.
 * @param {string?} props.title - Title to identify the node type with.
 * @param {func} props.goEdit - Function to go to the editor tab.
 * @param {func} props.removeNode - Function to remove the node.
 *
 * @return {React.ReactElement} - Node summary component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeSummary = ({ node, title, goEdit, removeNode }) => {
  if (node._id === 'holder_node') {
    return (
      <></>
    );
  }
  const typeTitle = title ?? node.type.replace(/_/g, ' ');

  return (
    <PFContainer className="node-summary" height={80}
      style={{
        position: 'relative'
      }}>
      {
        node?.async
          ? <PFContainer style={{
            position: 'absolute',
            right: '16px',
            top: '-22px',
            background: 'var(--purple-200)',
            borderRadius: 'var(--size-s) var(--size-s) 0 0',
            cursor: 'default'
          }}
            padding='xs' >
            <PFText weight={600} size='xs'>
              ASYNC
            </PFText>
          </PFContainer>
          : null
      }

      <PFContainer className="node-summary-options"
        height={50} style={{ marginTop: -50 }} gap="m"
        display="flex" justifyContent="center" alignItems="center">
        <PFButton type="support" padding='0' onClick={goEdit}>
          <PFIcon icon="bx bx-edit" color="var(--secondary)" size="l" />
        </PFButton>
        <PFButton type="support" padding='0' onClick={(removeNode)}>
          <PFIcon icon="bx bx-trash-alt" color="var(--secondary)" size="l" />
        </PFButton>
      </PFContainer>
      <PFContainer type="form" padding="s"
        display="flex" alignItems="center" gap="s"
        width={400} radius="m">
        <PFContainer className="flow-node-icon-container">
          <PFContainer className={`flow-node-icon ${node.type}`} />
        </PFContainer>
        <PFContainer>
          <PFText>
            {node.alias}
          </PFText>
          <PFText type="support" size="s" weight="bold">
            {typeTitle} · {node?._id}
          </PFText>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

NodeSummary.propTypes = {
  node: PropTypes.object.isRequired,
  title: PropTypes.string,
  goEdit: PropTypes.func.isRequired,
  removeNode: PropTypes.func.isRequired,
};

export default NodeSummary;
