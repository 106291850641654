import PropTypes from 'prop-types';

import { PFIcon } from 'PFComponents/common';
import { componentUtils } from 'PFUtils';

import './PFFileInput.css';

/**
 * Standard file input component of the PFComponents library. This file input
 * complies with the Pefai style guidelines. The underlying implementation of
 * this file input is the React *input* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFFileInput properties.
 * @param {string} [props.label] - Text to be displayed above the file input.
 * @param {string} [props.name] - Name of the file input (required if
 * associated to a 'useForm' hook).
 * @param {string} [props.placeholder] - Placeholder to be displayed inside the
 * file input.
 * @param {func} [props.fieldHook] - Function provided by the 'useForm' hook to
 * manage the content of a field.
 * @param {string} [props.className] - Class name to be applied to the file
 * input.
 * @param {any} [props.value] - Current value of the file input.
 * @param {string} [props.error] - Current error of the file input value.
 * @param {func} [props.onChange] - Function to be called when the value of the
 * file input changes (not necessary if fieldHook is present).
 * @param {func} [props.onBlur] - Function to be called when the file input
 * calls 'onBlur'.
 * @param {boolean} [props.disabled=false] - Determines if the file input is
 * currently disabled.
 * @param {string} [props.margin] - Determines the margin config of the file
 * input component.
 *
 * @return {React.ReactElement} The file input component.
 *
 * @author Andres Barragan  <andres@pefai.com>
 */
const PFFileInput = ({
  label,
  name,
  placeholder,
  fieldHook,
  className,
  value,
  error,
  onChange,
  onBlur,
  disabled,
  margin,
}) => {
  const hook = fieldHook ? fieldHook(name) : {};

  const fieldValue = hook.value ?? value;
  const fieldError = hook.error ?? error;
  const fieldUpdate = hook.updateAndValidate
    ? (e) => hook.updateAndValidate(e.target.files[0])
    : null;

  const configStyle
    = componentUtils.getStylesFromConfig({ margin });

  return (
    <div
      className={`pf input-container file ${fieldError ? 'error' : ''}`}
      style={configStyle}
    >
      {label
        ? <label className="pf input-label file">{label}</label>
        : null
      }
      <div className={`pf file-input-container 
      ${className} ${fieldError ? 'error' : ''}`}>
        <label className="pf file-input-placeholder">
          {fieldValue?.name ?? placeholder}
        </label>
        <PFIcon icon="bx bx-paperclip" className="input-icon" />
        <input
          className="pf input file"
          name={name}
          type="file"
          onChange={onChange ?? fieldUpdate}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
      {fieldError
        ? <label className="pf input-error file">{fieldError}</label>
        : null
      }
    </div>
  );
};

PFFileInput.defaultProps = {
  label: null,
  placeholder: 'Upload a file',
  disabled: false,
  error: null,
};

PFFileInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fieldHook: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
};

export default PFFileInput;
