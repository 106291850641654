import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { useAsyncCall, useShortcut } from 'PFHooks';
import {
  PageJSONEditorHeader,
  PageJSONEditorSidebar
} from './components';

const PFScreenPageJSONEditor = () => {
  const { app_id, builder_path } = useParams();

  const [fetchAsyncCall, fetchLoading, fetchError, dismissFetchError]
    = useAsyncCall(true);
  const [page, setPage] = useState(null);

  const [tab, setTab] = useState('blob');

  const [blobSaveAsyncCall, blobSaveLoading,
    blobSaveError, dismissBlobSaveError] = useAsyncCall(false);
  const [blob, setBlob] = useState('[]');
  const [savedBlob, setSavedBlob] = useState(false);

  const [settings, setSettings] = useState('{}');
  const [savedSettings, setSavedSettings] = useState(false);
  const [settingsSaveAsyncCall, settingsSaveLoading,
    settingsSaveError, dismissSettingsSaveError] = useAsyncCall(false);

  useEffect(() => {
    fetchAsyncCall(async () => {
      const page = await builderV2.getPageConfig(app_id, builder_path);
      if (!!page.components) {
        setBlob(JSON.stringify(page.components, null, '\t'));
      } else {
        setBlob('[]');
      }

      const new_settings = {
        requires_auth: page.requires_auth,
        dynamic_data: page.dynamic_data,
        on_load_events: page.on_load_events,
        meta: page.meta,
        data: page.data
      };
      setSettings(JSON.stringify(new_settings, null, '\t'));

      setPage(page);

      setTab('blob');
      setSavedBlob(false);
      setSavedSettings(false);
    });
  }, [app_id, builder_path]);

  const saveBlob = async () => {
    blobSaveAsyncCall(async () => {
      const newComponents = JSON.parse(blob);
      await builderV2.updatePageBlob(page.app_id, page.blob_id, newComponents);
      setSavedBlob(true);
    });
  };

  const saveSettings = async () => {
    settingsSaveAsyncCall(async () => {
      const newSettings = JSON.parse(settings);
      await builderV2.updatePageSettings(
        page.app_id, page.page_id, newSettings);
      setSavedSettings(true);
    });
  };

  useShortcut('ctrl+s', tab === 'blob' ? saveBlob : saveSettings);

  return (
    <>
      <PageJSONEditorHeader
        appId={app_id}
        pageId={page?.page_id ?? ''}
        builderPath={builder_path}
        multisteps={page?.multistep_components ?? []}
        tab={tab}
        setTab={setTab}
        save={tab === 'blob' ? saveBlob : saveSettings}
        saveLoading={tab === 'blob' ? blobSaveLoading : settingsSaveLoading}
        saved={tab === 'blob' ? savedBlob : savedSettings} />
      <PFContainer display="flex" flex={1} alignItems='stretch'>
        <PageJSONEditorSidebar appId={app_id} builderPath={builder_path} />
        <PFContainer flex={1}>
          <PFContainer width="100%" height="100%">
            {fetchLoading || !page
              ? <PFLoader area color="var(--primary)" />
              : <Editor
                height="100%"
                width="100%"
                language="json"
                value={tab === 'blob' ? blob : settings}
                theme="vs-dark"
                onChange={tab === 'blob' ? setBlob : setSettings}
              />
            }
            <PFAlert
              message={fetchError}
              open={!!fetchError}
              onClose={dismissFetchError} />
            <PFAlert
              message={blobSaveError}
              open={!!blobSaveError}
              onClose={dismissBlobSaveError} />
            <PFAlert
              message={settingsSaveError}
              open={!!settingsSaveError}
              onClose={dismissSettingsSaveError} />
          </PFContainer>
        </PFContainer>
      </PFContainer >
    </>
  );
};

export default PFScreenPageJSONEditor;
