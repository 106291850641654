import styled from 'styled-components';
export const SearchInput = styled.input<{ mapType?: string; configStyles?: string }>`
    box-sizing: border-box;
    border: 0.0625rem solid transparent;
    width: 100%;
    height: 2rem;
    padding: 0 0.75rem;
    border-radius: 3px;
    font-size: 0.875rem;
    outline: none;
    text-overflow: ellipsis;
    position: ${(props) => (props.mapType === 'standaloneSearchbox' ? 'relative' : 'absolute')};
    &:active {
        border-color: #0279fe;
    }
    &:focus,
    &:focus-visible {
        border-color: #0279fe;
        box-shadow: 0px 0px 0px 1px #0279fe;
        outline: none;
    }
    ${(props) => props.configStyles};
`;
