import {
  PFButton,
  PFContainer,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import '../../DesignEditor.css';
import { EditorControl } from '../../components';
import { validateSizeInput } from '../../utils';

function composeBorder({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);

  let configStyles = {
    [styleKey]: styleValue
  };

  if (styleKey === 'border-radius') {
    configStyles = {
      ...configStyles,
      'border-radius': styleValue,
      'border-top-left-radius': styleValue,
      'border-top-right-radius': styleValue,
      'border-bottom-right-radius': styleValue,
      'border-bottom-left-radius': styleValue,
    };
  }

  return {
    configStyles
  };
}

const Borders = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles,
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <PFButton onClick={handleActiveSection}
        type='support' padding='0' height='auto'>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'} />
          Borders
        </PFText>
      </PFButton>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up'
        : 'pf-design-editor__section--down'}>
        <PFContainer
          display='flex'
          justifyContent='space-between'
          gap='s'
        >
          <PFContainer
            className='pf-designer-editor__rule'
            alignSelf='flex-start'
          >
            <PFText className='pf-design-editor__rule-title'>
              Radius
            </PFText>
          </PFContainer>
          <PFContainer
            display='grid'
            gridTemplateColumns='1fr 1fr'
            gap='s'
            width="177px">
            {
              ruleSet[0]['border-radius'].map((rule, index) => {
                if (index === 0) {
                  return (
                    <PFContainer
                      key={`design-editor-rule-${rule.key}-${index}}`}
                      style={{ gridColumn: 'span 2' }}
                    >
                      <EditorControl
                        componentBreakpointStyles={componentBreakpointStyles}
                        onChange={(event) => {
                          const normalizedInputValue
                            = validateSizeInput(
                              componentBreakpointStyles[
                              rule.key], event.target.value);

                          const composedValue = composeBorder({
                            composedValue: {
                              [rule.key]: normalizedInputValue
                            },
                          });

                          composeStyles({
                            composedAttributes: composedValue,

                          });
                        }}
                        className={rule.className}
                        styleConfig={{ key: rule.key, value: rule.value }}
                        type={typeof rule.type === 'undefined'
                          ? 'editor-control-button' : rule.type}
                      >
                        {rule.label}
                      </EditorControl>
                    </PFContainer>
                  );
                } else {
                  return (
                    <PFContainer
                      key={`design-editor-rule-${rule.key}-${index}}`}
                    >
                      <EditorControl
                        componentBreakpointStyles={componentBreakpointStyles}
                        onChange={(event) => {
                          const normalizedInputValue
                            = validateSizeInput(
                              componentBreakpointStyles[
                              rule.key], event.target.value);

                          const composedValue = composeBorder({
                            composedValue:
                              { [rule.key]: normalizedInputValue },
                          });

                          composeStyles({
                            composedAttributes: composedValue,

                          });
                        }}
                        className={rule.className}
                        styleConfig={{ key: rule.key, value: rule.value }}
                        type={typeof rule.type === 'undefined'
                          ? 'editor-control-button' : rule.type}
                      >
                        {rule.label}
                      </EditorControl>
                    </PFContainer>);
                }
              })
            }
          </PFContainer>
        </PFContainer>
        <PFContainer>
          <PFText
            className='pf-design-editor__rule-title'
            color='var(--purple-100)'
            padding='top-s bottom-s'
            style={{ alignSelf: 'flex-start' }}
          >
            All Borders
          </PFText>
          <PFContainer display='grid' gridTemplateColumns='1fr 2fr 1fr' gap='s'>
            {
              ruleSet[1]['border'].map((rule, index) => {
                if (rule.type === 'editor-control-colorpicker') {
                  return (
                    <Fragment
                      key={`design-editor-rule-${rule.key}-${index}}`}
                    >
                      <PFText className='pf-design-editor__rule-title'>
                        {rule.text}</PFText>
                      <EditorControl
                        componentBreakpointStyles={componentBreakpointStyles}
                        onChange={(event) => {
                          let normalizedInputValue = '';

                          if (rule.type === 'editor-control-colorpicker') {
                            normalizedInputValue = event;
                          }
                          const composedValue = composeBorder({
                            composedValue:
                              { [rule.key]: normalizedInputValue },
                          });

                          composeStyles({
                            composedAttributes: composedValue,

                          });
                        }}
                        className={rule.className}
                        styleConfig={{ key: rule.key, value: rule.value }}
                        type={typeof rule.type === 'undefined'
                          ? 'editor-control-button' : rule.type}
                      >
                        {rule.label}
                      </EditorControl>
                    </Fragment>
                  );
                } else {
                  if (rule.key.includes('-style')) {
                    return (
                      <PFContainer style={{
                        width: '100%',
                        gridColumn: '2 / -1'
                      }} key={`design-editor-rule-${rule.key}-${index}}`}
                      >
                        <EditorControl
                          componentBreakpointStyles={componentBreakpointStyles}
                          onChange={(event) => {
                            let normalizedInputValue = '';

                            if (rule.type === 'editor-control-size') {
                              normalizedInputValue
                                = validateSizeInput(
                                  componentBreakpointStyles[
                                  rule.key], event.target.value);
                            } else {
                              normalizedInputValue = event.target.value;
                            }
                            const composedValue = composeBorder({
                              composedValue: {
                                [rule.key]: normalizedInputValue
                              },
                            });

                            composeStyles({
                              composedAttributes: composedValue,

                            });
                          }
                          }
                          className={rule.className}
                          typeConfig={rule.typeConfig}
                          styleConfig={{ key: rule.key, value: rule.value }}
                          type={typeof rule.type === 'undefined'
                            ? 'editor-control-button' : rule.type}>
                          {rule.label}
                        </EditorControl>
                      </PFContainer>
                    );
                  } else {
                    return (
                      <EditorControl
                        key={index}
                        componentBreakpointStyles={componentBreakpointStyles}
                        onChange={(event) => {
                          let normalizedInputValue = '';
                          if (rule.type === 'editor-control-size') {
                            normalizedInputValue
                              = validateSizeInput(
                                componentBreakpointStyles[
                                rule.key], event.target.value
                              || '0');
                          } else {
                            normalizedInputValue = event.target.value;
                          }
                          const composedValue = composeBorder({
                            composedValue:
                              { [rule.key]: normalizedInputValue },
                          });

                          composeStyles({
                            composedAttributes: composedValue,

                          });
                        }}
                        className={rule.className}
                        typeConfig={rule.typeConfig}
                        styleConfig={{ key: rule.key, value: rule.value }}
                        type={typeof rule.type === 'undefined'
                          ? 'editor-control-button' : rule.type}
                      >
                        {rule.label}
                      </EditorControl>
                    );
                  }
                }
              })
            }
          </PFContainer>
        </PFContainer >
      </PFContainer>
    </PFContainer >
  );
};

export default Borders;

Borders.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object
};
