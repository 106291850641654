import React, { ReactNode } from 'react';
import * as Styled from './icon.styled';
import DOMPurify from 'dompurify';

export type IconProps = {
    /**
     * a node to be rendered in Icon component
     */
    children?: ReactNode;

    /**
     * class name to be used as external styling
     */
    className?: string;

    /**
     * for component level styling override (Design System)
     * @config implementation required
     */
    configStyles?: string;

    /**
     * To identify the component
     */
    id?: string;

    /**
     * It's the SVG corresponding to the icon
     */
    svgSrc?: string;

    /**
     * Defines the tag that wraps the icon
     */
    type?: 'span' | 'i';
};

export function Icon({ children, className, configStyles, id, svgSrc, type = 'i', ...props }: IconProps) {
    const sanitizedSVG = svgSrc && DOMPurify.sanitize(svgSrc);

    if (sanitizedSVG) {
        return (
            <Styled.Icon
                as={type}
                className={className}
                configStyles={configStyles}
                id={id}
                dangerouslySetInnerHTML={{ __html: sanitizedSVG }}
                {...props}
            />
        );
    } else {
        return (
            <Styled.Icon as={type} className={className} configStyles={configStyles} id={id} {...props}>
                {children}
            </Styled.Icon>
        );
    }
}
