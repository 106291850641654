export const validationTypes = [
  {
    label: 'required',
    value: 'required'
  },
  {
    label: 'min',
    value: 'min'
  },
  {
    label: 'max',
    value: 'max'
  },
  {
    label: 'length',
    value: 'length'
  },
  {
    label: 'matches',
    value: 'matches'
  },
  {
    label: 'match field',
    value: 'match_field'
  },
  {
    label: 'email',
    value: 'email'
  },
  {
    label: 'url',
    value: 'url'
  },
  {
    label: 'uuid',
    value: 'uuid'
  }
];
