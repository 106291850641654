import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EnvironmentSelectionModal } from './components';

const PFScreenFunctionEnvironments = () => {
  const currentFunction = useSelector((state) => state.current_function);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { function_id } = useParams();

  const baseURL = `/functions/${function_id}/environments`;

  const renderEnvironments = () => {
    if (!currentFunction.env_dbs) return [];

    return Object.keys(currentFunction.env_dbs).map((envName) => {
      return (
        <PFContainer key={envName} style={{
          position: 'relative'
        }}>
          <PFItemCard
            path={`${baseURL}/${envName}`}
            options={{}}>
            <PFText margin="top-m">{envName}</PFText>
          </PFItemCard>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Environments</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={() => {
            setIsModalOpen(true);
          }}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>Create new environment</PFText>
          </PFActionCard>
          {renderEnvironments()}
        </PFContainer>
      </PFContainer>
      <EnvironmentSelectionModal
        active={isModalOpen} onDismiss={() => setIsModalOpen(false)} />
    </PFContainer>
  );
};

export default PFScreenFunctionEnvironments;
