import { toolkit } from 'PFApis';
import {
  PFButton,
  PFCodeEditor,
  PFContainer,
  PFIconButton,
  PFInput,
  PFLine,
  PFLoader,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const PFOperationTester = ({ functionId, operation, active, onDismiss }) => {
  const [params, setParams] = useState({});
  const [response, setResponse] = useState(null);
  const [copyText, setCopyText] = useState('Copy');

  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);

  useEffect(() => {
    dismissError();
    setResponse(null);
    if (!!operation) {
      const paramsArray = operation.request_values.map(([key]) => [key, '']);
      setParams(Object.fromEntries(paramsArray));
    }
  }, [functionId, operation]);

  useEffect(() => {
    if (copyText === 'Copied!') {
      const timerId = setTimeout(() => {
        setCopyText('Copy');
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [copyText]);

  const renderParams = () => {
    return Object.entries(params).map(([key, val]) => {
      const updateValue = (e) => {
        setParams({ ...params, [key]: e.target.value });
      };

      return (
        <React.Fragment key={key}>
          <PFContainer
            background="var(--input-form-background)"
            padding="horizontal-s" radius="s"
            display="flex" alignItems="center" justifyContent="flex-end">
            <PFText type="secondary">{key}</PFText>
          </PFContainer>
          <PFInput
            value={val}
            onChange={updateValue}
            placeholder={`${key} value`} />
        </React.Fragment>
      );
    });
  };

  const runOperation = async () => {
    asyncCall(async () => {
      dismissError();
      setResponse(null);
      const res = await toolkit.testRunOperation(
        functionId, operation._id, params);
      setResponse(res);
    });
  };

  if (!operation) return null;

  const getResponseStatus = () => {
    if (!!response) {
      return response.data.status;
    } else if (!!error) {
      return error.response.status;
    }
  };

  const getResponseJSON = () => {
    if (!!response) {
      return JSON.stringify(response.data.response, null, '  ');
    } else if (!!error) {
      return JSON.stringify(error.response.data, null, '  ');
    }
  };

  const copyJSONResponse = () => {
    generalUtils.copyToClipboard(getResponseJSON());
    setCopyText('Copied!');
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="m" width={600} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" justifyContent="space-between">
            <PFContainer display="flex" alignItems="center" gap="xs">
              <PFIconButton icon="bx bx-chevron-left" onClick={onDismiss} />
              <PFText margin="left-s">Test:</PFText>
              <PFText type="secondary">{operation.name}</PFText>
            </PFContainer>
            <PFButton loading={loading}
              onClick={runOperation}
              padding="horizontal-l">
              Run
            </PFButton>
          </PFContainer>

          {Object.keys(params).length > 0
            ? <PFContainer>
              <PFText margin="bottom-s">Parameters</PFText>
              <PFContainer
                display="grid"
                gridTemplateColumns="auto 1fr"
                gap="s">
                {renderParams()}
              </PFContainer>
              <PFText type="support" size="s" margin="top-s"
                style={{ textAlign: 'right' }}>
                Changes should be saved before testing!
              </PFText>
            </PFContainer>
            : null
          }

          {loading
            ? <>
              <PFLine />
              <PFLoader area size="s" color="var(--primary)" />
            </>
            : null}

          {!!response || !!error
            ? <>
              <PFLine />
              <PFContainer margin="bottom-m" display="flex"
                flexDirection="column" gap="s">
                <PFContainer display="flex"
                  alignItems="center" justifyContent="space-between">
                  <PFContainer display="flex" gap="s">
                    <PFText>Response</PFText>
                    <PFText type="secondary">[{getResponseStatus()}]</PFText>
                  </PFContainer>
                  <PFButton type="secondary" height={28}
                    onClick={copyJSONResponse}>
                    <PFText type="secondary" size="s">{copyText}</PFText>
                  </PFButton>
                </PFContainer>
                <PFCodeEditor disabled value={getResponseJSON()} />
              </PFContainer>
            </>
            : null}
        </PFContainer>
      </PFModal>
    </>
  );
};

PFOperationTester.propTypes = {
  functionId: PropTypes.string.isRequired,
  operation: PropTypes.object,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default PFOperationTester;
