import { generalUtils } from 'PFUtils';
import { cloneDeep } from 'lodash';
import { useRef, useState } from 'react';
import {
  filterBlobByBuildId,
  findComponentById,
  getComposition,
  updateIdsRecursive,
  validateAllowedPaste
} from './index';

const useCanvas = (extras = {}) => {
  const [hover, setHover] = useState(null);
  const [selected, setSelected] = useState(null);
  const [dragging, setDragging] = useState(null);
  const [dropZone, setDropZone] = useState(null);
  const [multistepRef, setMultistepRef] = useState({});
  const [lastElementAdded, setLastElementAdded] = useState(null);
  const [history, setHistory] = useState([]);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(-1);
  const [isPrevVersion, setIsPrevVersion] = useState(false);

  const viewportHeight = window.innerHeight - 68;
  const jumpedBack = useRef(false);
  const jumpedFront = useRef(false);

  const getStep = (multistepId) => {
    return multistepRef[multistepId];
  };

  const setStep = (multistepId, step) => {
    setMultistepRef({ ...multistepRef, [multistepId]: step });
  };

  const deleteComponent = ({ blob, setBlob }) => {
    if (!selected?.id) return false;
    const newComponents = filterBlobByBuildId(cloneDeep(blob),
      selected.id);
    setBlob(newComponents);
    setHover(null);
    setSelected(null);
  };

  const copyComponent = ({ canvas, blob }) => {
    if (canvas.selected) {
      const selectedComponentId = canvas.selected.id;
      const composition
        = getComposition(blob[0], selectedComponentId, blob[0]);
      if (navigator.clipboard) {
        generalUtils.copyToClipboard(JSON.stringify(composition));
      }
    }
  };

  const pasteComponent = ({ selectedComponent, blob, setBlob, setError }) => {
    if (selectedComponent) {
      const selectedComponentId = selectedComponent.id;
      const updatedBlob = cloneDeep(blob);

      generalUtils.pasteFromClipboard()
        .then((copiedComponent) => {
          const parsedCopiedComponent = JSON.parse(copiedComponent).component;
          const { isAllowedToPaste, error }
            = validateAllowedPaste(
              selectedComponent,
              parsedCopiedComponent,
              updatedBlob
            );
          const newChild = parsedCopiedComponent;
          const copiedComponentId = parsedCopiedComponent.build.id;
          const parentSelectedComponentId = selectedComponent.parentNode.id;
          if (isAllowedToPaste) {
            if (selectedComponentId === copiedComponentId) {
              const parentComponent
                = findComponentById(updatedBlob[0], parentSelectedComponentId);
              if (parentComponent) {
                parentComponent.children.push(updateIdsRecursive(newChild));
                setBlob(updatedBlob);
              }
            } else {
              const targetComposition
                = getComposition(
                  updatedBlob[0],
                  selectedComponentId,
                  updatedBlob[0]);
              const targetSelected = findComponentById(
                updatedBlob[0], targetComposition.component.build.id);
              if (targetSelected) {
                targetSelected.children.push(updateIdsRecursive(newChild));
                setBlob(updatedBlob);
              }
            }
          } else if (error) {
            setError(error);
          } else {
            const parentComponent
              = findComponentById(
                updatedBlob[0], parentSelectedComponentId);
            if (parentComponent) {
              parentComponent.children.push(updateIdsRecursive(newChild));
              setBlob(updatedBlob);
            }
          }
        })
        .catch((err) => {
          console.error('Error on paste: ', err);
        });
    }
  };

  const undo = ({ setBlob }) => {
    if (currentVersionIndex > 2) {
      jumpedBack.current = true;
      setIsPrevVersion(true);
      setCurrentVersionIndex((prevIndex) => prevIndex - 1);
      setBlob(history[currentVersionIndex - 1]);
    }
  };

  const redo = ({ setBlob }) => {
    if (currentVersionIndex < history.length - 1) {
      jumpedFront.current = true;
      setIsPrevVersion(true);
      setCurrentVersionIndex((prevIndex) => prevIndex + 1);
      setBlob(history[currentVersionIndex + 1]);
    }
  };

  const setSelectedCanvasComponent = ({ target, ...rest }) => {
    const { e } = rest;
    if (selected?.id === target.id) {
      setSelected(null);
    } else if (!!target.id) {
      if (target.tagName === 'SPAN') {
        setSelected(target.parentElement);
      } else {
        setSelected(target);
      }
    }
    if (e?.detail === 2) {
      setSelected(null);
    }
  };


  return {
    copyComponent,
    currentVersionIndex,
    deleteComponent,
    dragging,
    dropZone,
    getStep,
    history,
    hover,
    isPrevVersion,
    jumpedBack,
    jumpedFront,
    lastElementAdded,
    pasteComponent,
    redo,
    selected,
    setCurrentVersionIndex,
    setDragging,
    setDropZone,
    setHistory,
    setHover,
    setIsPrevVersion,
    setLastElementAdded,
    setSelected,
    setSelectedCanvasComponent,
    setStep,
    undo,
    viewportHeight,
    ...extras
  };
};

export default useCanvas;
