import {
  PFButton, PFContainer,
  PFIcon, PFInput, PFInputAutosize
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import ShortUniqueId from 'short-unique-id';

const InputView = ({ response, operation, updateOperation }) => {
  const uid = new ShortUniqueId({ length: 3 });

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...response];
    newInputs[index][name] = value;
    updateOperation({
      ...operation,
      response_format: newInputs
    });
  };

  return (
    <PFContainer>
      <PFContainer display='flex' flexDirection='column'
        gap='s'>
        {response?.map((responseItem, index) => (
          <PFContainer key={index} display='flex' gap='s'>
            <PFInput
              type="text"
              name="name"
              value={responseItem.name}
              onChange={(e) => handleInputChange(index, e)}
            />
            <PFInputAutosize
              style={{
                height: '40px'
              }}
              width='100%'
              flex='1'
              type="text"
              name="value"
              value={responseItem.value}
              onChange={(e) => handleInputChange(index, e)}
            />
            <PFButton type='none' height='auto'
              onClick={() => {
                const newResponse = response
                  .filter((_, responseIndex) =>
                    responseIndex !== index);
                updateOperation({
                  ...operation,
                  response_format: newResponse
                });
              }}>
              <PFIcon icon='bx bx-trash'
                color='var(--purple-100)' size='l' />
            </PFButton>
          </PFContainer>
        ))}
      </PFContainer>
      <PFButton type="support" onClick={() => {
        updateOperation({
          ...operation,
          response_format: [
            ...operation.response_format,
            {
              name: `param_${uid()}`,
              value: ''
            }
          ]
        });
      }}
        margin='top-s'
        style={{ justifySelf: 'start' }}>
        + add parameter
      </PFButton>
    </PFContainer>
  );
};

InputView.propTypes = {
  response: PropTypes.array,
  updateOperation: PropTypes.func,
  operation: PropTypes.object
};

export default InputView;
