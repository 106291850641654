/**
 * Function to copy the text provided to the user's clipboard.
 *
 * @param {string} text - Text to be copied to the clipboard
 */
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

/**
 * Returns the text available in the clipboard
 */

export const pasteFromClipboard = () => {
  if (navigator.clipboard) {
    return navigator.clipboard.readText();
  }
};

