import PFIconComponentButton from './PFIconComponentButton';
import PFIconComponentContainer from './PFIconComponentContainer';
import PFIconComponentCurrency from './PFIconComponentCurrency';
import PFIconComponentEmail from './PFIconComponentEmail';
import PFIconComponentForm from './PFIconComponentForm';
import PFIconComponentFormMeta from './PFIconComponentFormMeta';
import PFIconComponentHeading from './PFIconComponentHeading';
import PFIconComponentImage from './PFIconComponentImage';
import PFIconComponentInput from './PFIconComponentInput';
import PFIconComponentLabel from './PFIconComponentLabel';
import PFIconComponentLink from './PFIconComponentLink';
import PFIconComponentLinkBlock from './PFIconComponentLinkBlock';
import PFIconComponentNumber from './PFIconComponentNumber';
import PFIconComponentParagraph from './PFIconComponentParagraph';
import PFIconComponentPassword from './PFIconComponentPassword';
import PFIconComponentTelephone from './PFIconComponentTelephone';
import PFIconComponentText from './PFIconComponentText';
import PFIconComponentUrl from './PFIconComponentUrl';
import PFIconComponentVideo from './PFIconComponentVideo';

export default {
  'pfi pfi-component-button': PFIconComponentButton,
  'pfi pfi-component-container': PFIconComponentContainer,
  'pfi pfi-component-heading': PFIconComponentHeading,
  'pfi pfi-component-link': PFIconComponentLink,
  'pfi pfi-component-link-block': PFIconComponentLinkBlock,
  'pfi pfi-component-paragraph': PFIconComponentParagraph,
  'pfi pfi-component-text': PFIconComponentText,
  'pfi pfi-component-image': PFIconComponentImage,
  'pfi pfi-component-video': PFIconComponentVideo,
  'pfi pfi-component-form': PFIconComponentForm,
  'pfi pfi-component-label': PFIconComponentLabel,
  'pfi pfi-component-form-meta': PFIconComponentFormMeta,
  'pfi pfi-component-input': PFIconComponentInput,
  'pfi pfi-component-password': PFIconComponentPassword,
  'pfi pfi-component-telephone': PFIconComponentTelephone,
  'pfi pfi-component-currency': PFIconComponentCurrency,
  'pfi pfi-component-email': PFIconComponentEmail,
  'pfi pfi-component-url': PFIconComponentUrl,
  'pfi pfi-component-number': PFIconComponentNumber
};
