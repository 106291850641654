import {
  PFButton, PFContainer, PFForm, PFIcon, PFInput, PFSelect, PFText
} from 'PFComponents/common';
import { useForm } from 'PFHooks';
import store, { currentDBActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { DATA_TYPES, getForm } from 'src/constants/DB';
import { getFields, getFormTypes } from 'src/constants/DB/getForm';
import DefaultInput from '../DefaultInput';

const ColumnForm = ({ columnType, loading, asyncCall, tableName, setActive,
  selectedTable, setSelectedTable, selectedColumn, setSelectedColumn,
  defaultValue, setDefaultValue,
  isDefaultRequired, setIsDefaultRequired,
  setUpdateTable, nullable, setNullable, resetStep, unique, setUnique,
  decimalScale, setDecimalScale, decimalLength, setDecimalLength,
  setStringLength, stringLength, setFormError, apiRef }) => {
  const currentDB = useSelector((state) => state.current_db);
  useEffect(() => {
    if (columnType === 'Foreign Key') {
      setSelectedTable(currentDB.tables[0].table_name);
      setSelectedColumn(currentDB.tables[0].fields[0].column_name);
    }
  }, []);
  const currentUserId = useSelector((state) => state.user._id);
  const defaultForm = getForm('add', columnType, {});
  const [form, fieldHook] = useForm(defaultForm);
  const distribution = getFormTypes('add', columnType).distribution;
  const fields = getFields(getFormTypes('add', columnType));
  const tableOptions = currentDB.tables.map((table) => {
    return (
      {
        label: table.alias,
        value: table.table_name
      }
    );
  });
  const columnOptions = selectedTable
    ? currentDB.tables.find((table) => table.table_name === selectedTable)
      .fields.map((field) => {
        return (
          {
            label: field.alias,
            value: field.column_name
          }
        );
      }).filter((el) => el.value === 'id') : [];
  const renderField = (field) => {
    let options = field.options;
    if (field.name === 'unique') {
      return (
        <PFContainer>
          <PFText>{field.label}</PFText>
          <PFSelect options={[
            { label: 'YES', value: 'true' },
            { label: 'NO', value: 'false' }
          ]} onChange={(e) => {
            setUnique(e.target.value);
          }} value={unique} zIndex={1500} />
        </PFContainer>
      );
    }

    if (field.name === 'default_value') {
      return (<DefaultInput columnType={columnType} defaultValue={defaultValue}
        setDefaultValue={setDefaultValue} isDefaultRequired={isDefaultRequired}
        setIsDefaultRequired={setIsDefaultRequired} nullable={nullable}
        unique={unique} fieldLabel={field.label} />);
    }
    if (field.name === 'length' && columnType === 'String') {
      return (<>
        <PFText>{field.label}</PFText>
        <PFInput className={stringLength ? '' : 'error'}
          type='text'
          placeholder={field.placeholder}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setStringLength(e.target.value);
            }
          }} name={field.name} value={stringLength}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {stringLength ? <></>
          : <PFText color='var(--failure)' size='s'
            margin='top-xs'>
            Field length missing</PFText>}
      </>);
    }
    if (field.name === 'length' && columnType === 'Decimal') {
      return (<>
        <PFText>{field.label}</PFText>
        <PFInput className={decimalLength ? '' : 'error'}
          type='text'
          placeholder={field.placeholder}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setDecimalLength(e.target.value);
            }
          }} name={field.name} value={decimalLength}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {decimalLength ? <></>
          : <PFText color='var(--failure)' size='s'
            margin='top-xs'>
            Field length missing</PFText>}
      </>);
    }
    if (field.name === 'scale' && columnType === 'Decimal') {
      return (<>
        <PFText>{field.label}</PFText>
        <PFInput type='text' className={decimalLength ? '' : 'error'}
          placeholder={field.placeholder}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setDecimalScale(e.target.value);
            }
          }} name={field.name} value={decimalScale}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {decimalScale ? <></>
          : <PFText color='var(--failure)' size='s'
            margin='top-xs'>
            Field scale missing</PFText>}
      </>);
    }
    if (field.name === 'nullable') {
      return (
        <>
          <PFText>{field.label}</PFText>
          <PFSelect options={options} onChange={(e) => {
            setNullable(e.target.value);
            if (e.target.value === 'true') {
              setIsDefaultRequired(false);
            }
          }}
            name={field.name} zIndex={1500} value={nullable} />
        </>
      );
    }
    if (field.name === 'table') {
      options = tableOptions;
      return (
        <>
          <PFText>{field.label}</PFText>
          <PFSelect options={options} onChange={(e) => {
            setSelectedTable(e.target.value);
            setSelectedColumn('id');
          }} value={selectedTable} zIndex={1500} />
        </>
      );
    } else if (field.name === 'column') {
      options = field.options;
      return (
        <>
          <PFText>{field.label}</PFText>
          <PFSelect options={columnOptions} onChange={(e) => {
            setSelectedColumn(e.target.value);
          }} value={selectedColumn} zIndex={1500} />
        </>
      );
    } else {
      options = field.options;
    }
    switch (field.type) {
      case 'input':
        return (
          <>
            <PFText>{field.label}</PFText>
            <PFInput type={field.inputType
              ? field.inputType : 'text'}
              placeholder={field.placeholder}
              fieldHook={fieldHook} name={field.name}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          </>
        );
      case 'select':
        return (
          <>
            <PFText>{field.label}</PFText>
            <PFSelect options={options} fieldHook={fieldHook}
              name={field.name} zIndex={1500} />
          </>
        );

      default:
        return <></>;
    }
  };


  const onSubmit = (values) => {
    if (nullable === 'false' && !defaultValue
      && columnType !== 'Foreign Key' && unique === 'false') {
      setIsDefaultRequired(true);
      return;
    }
    if (columnType === 'Decimal' && (!decimalLength || !decimalScale)) {
      return;
    }
    if (columnType === 'String' && (!stringLength)) {
      return;
    }

    if (columnType === 'String' && defaultValue.length
      > parseInt(stringLength)) {
      setFormError(`Default value can't be higher than the length.`);
      return;
    }

    if (columnType === 'Decimal') {
      const [integerPart, decimalPart] = defaultValue.split('.');
      const defaultLength = integerPart.concat('', decimalPart).length;

      const maxInteger = parseInt(decimalLength) - parseInt(decimalScale);

      if (parseInt(decimalScale) > parseInt(decimalLength)) {
        setFormError(`Scale can't be higher than the length`);
        return;
      } else if (defaultValue && (defaultLength > parseInt(decimalLength)
        || integerPart.length > maxInteger)) {
        setFormError(`Default value exceeds length and 
        scale configuration`);
        return;
      } else if (parseInt(decimalLength) > 38) {
        setFormError(`Length can't be higher than 38.`);
        return;
      }
    }

    asyncCall(async () => {
      if (decimalLength && columnType === 'Decimal') {
        values.length = parseInt(decimalLength);
      }
      if (decimalScale && columnType === 'Decimal') {
        values.scale = parseInt(decimalScale);
      }
      if (stringLength && columnType === 'String') {
        values.length = parseInt(stringLength);
      }
      if (selectedTable) values.table = selectedTable;
      if (selectedColumn) values.column = selectedColumn;
      if (defaultValue) values.default_value = defaultValue;
      if (nullable) values.nullable = nullable;
      if (unique) values.unique = unique;
      if (unique === 'true') {
        values.default_value = defaultValue;
      }

      const newFieldData = {
        ...values,
        type_name: DATA_TYPES[columnType].type_name,
        data_type: DATA_TYPES[columnType].data_type
      };
      let newField;
      if (columnType === 'Foreign Key') {
        const targetColumn = currentDB.tables
          .find((table) => table.table_name === values.table).fields
          .find((el) => el.column_name === values.column);

        newField = await builderTest(currentUserId).addDBTableColumnFk(
          currentDB._id, tableName, {
          ...newFieldData,
          data_type: targetColumn.data_type,
          type_name: targetColumn.type_name,
          nullable: 'true'
        });
      } else {
        newField = await builderTest(currentUserId).addDBTableColumn(
          currentDB._id, tableName, newFieldData);
      }
      if (defaultValue) {
        setUpdateTable((prev) => !prev);
      }

      store.dispatch(currentDBActions.addTableField({
        table_name: tableName,
        new_field: newField
      }));

      setActive(false);
      resetStep();
      form.setConfig(defaultForm);
    });
  };
  return (
    <PFForm form={form} submit={onSubmit}>
      <PFContainer display="grid" gap="m"
        gridTemplateColumns='repeat(6, 1fr)' >
        {
          fields.map((field, index) => {
            return (
              <PFContainer key={index} style={{
                gridColumn: distribution[index]
                  ? `span ${distribution[index]}` : 'auto'
              }}>

                {renderField(field)}
              </PFContainer>
            );
          })
        }
      </PFContainer>
      {
        nullable === 'false' && unique === 'true'
        && apiRef.current.getRowsCount() > 0
        && <PFContainer margin='top-m'>
          <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
            size='s' margin='right-xs' />
          <PFText size='s' color='var(--purple-100)'
            style={{
              display: 'inline'
            }}>
            Caution: </PFText>
          <PFText size='s'
            style={{
              display: 'inline'
            }}>
            You are adding a column that is not nullable
            and unique on a table that already has records.
          </PFText>
          <PFText size='s'>
            This is not allowed.
          </PFText>

        </PFContainer>
      }
      {
        defaultValue && columnType === 'Integer'
        && nullable === 'true' && unique === 'false'
        && <PFContainer margin='top-m'>
          <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
            size='s' margin='right-xs' />
          <PFText size='s' color='var(--purple-100)'
            style={{
              display: 'inline'
            }}>
            Caution: </PFText>
          <PFText size='s'
            style={{
              display: 'inline'
            }}>
            Integer range goes from -2147483648 to 2147483647.
            Default values are not validated until a record is added.
          </PFText>

        </PFContainer>
      }
      {
        defaultValue && columnType === 'Big Integer'
        && nullable === 'true' && unique === 'false'
        && <PFContainer margin='top-m'>
          <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
            size='s' margin='right-xs' />
          <PFText size='s' color='var(--purple-100)'
            style={{
              display: 'inline'
            }}>
            Caution: </PFText>
          <PFText size='s'
            style={{
              display: 'inline'
            }}>
            Big Integer range goes from -9223372036854775808
            to 9223372036854775807.
            Default values are not validated until a record is added.
          </PFText>

        </PFContainer>
      }
      <PFContainer display='flex' justifyContent='flex-end'
        margin='top-m'>
        <PFButton padding="horizontal-xl" submit loading={loading}
          height='auto' width='240px' disabled={nullable === 'false'
            && unique === 'true'
            && apiRef.current.getRowsCount() > 0}
          style={{
            paddingTop: 'var(--size-s)',
            paddingRight: 'var(--size-s)',
            paddingBottom: 'var(--size-s)',
            paddingLeft: 'var(--size-s)',
          }}>
          Add column
        </PFButton>
      </PFContainer>

    </PFForm >

  );
};

ColumnForm.propTypes = {
  tableName: PropTypes.string.isRequired,
  columnType: PropTypes.string,
  loading: PropTypes.bool,
  asyncCall: PropTypes.func,
  setActive: PropTypes.func,
  selectedTable: PropTypes.string,
  setSelectedTable: PropTypes.func,
  selectedColumn: PropTypes.string,
  setSelectedColumn: PropTypes.func,
  setColumnType: PropTypes.func,
  setDefaultValue: PropTypes.func,
  defaultValue: PropTypes.string,
  isDefaultRequired: PropTypes.bool,
  setIsDefaultRequired: PropTypes.func,
  setUpdateTable: PropTypes.func,
  nullable: PropTypes.string,
  setNullable: PropTypes.func,
  resetStep: PropTypes.func,
  unique: PropTypes.string,
  setUnique: PropTypes.func,
  decimalScale: PropTypes.string,
  setDecimalScale: PropTypes.func,
  decimalLength: PropTypes.string,
  setDecimalLength: PropTypes.func,
  stringLength: PropTypes.string,
  setStringLength: PropTypes.func,
  apiRef: PropTypes.object,
  setFormError: PropTypes.func
};

export default ColumnForm;
