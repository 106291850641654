import {
  PFButton,
  PFContainer,
  PFIcon,
  PFLine,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import ScenarioInstructions from './ScenarioInstructions';
import ScenarioOperand from './ScenarioOperand';


const Scenario = ({ index, operand, setOperand, flow, setFlow, remove }) => {
  return (
    <PFContainer border="1px solid var(--primary)" padding="m" radius="s"
      display="flex" flexDirection="column" gap="m" margin="top-s"
      style={{ position: 'relative' }}>
      <PFContainer background="white" padding="horizontal-s" style={{
        position: 'absolute', top: -10, left: 20
      }}>
        <PFText type="secondary" size="s">
          {!!operand ? `Scenario ${index + 1}` : 'Default Scenario'}
        </PFText>
      </PFContainer>
      {!!operand
        ? <PFContainer background="white" style={{
          position: 'absolute', top: -20, right: 20
        }}>
          <PFButton type="support" onClick={remove}>
            <PFIcon icon="bx bx-trash" color="var(--primary)" />
          </PFButton>
        </PFContainer>
        : null}
      <PFContainer display="flex" flexDirection="column" gap="m">
        {!!operand
          ? <>
            <PFContainer>
              <PFText>Conditions</PFText>
              <ScenarioOperand operand={operand} setOperand={setOperand} />
            </PFContainer>
            <PFLine />
          </>
          : null}
        <PFContainer>
          <PFText>List of instructions</PFText>
          <ScenarioInstructions instructions={flow} setInstructions={setFlow} />
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

Scenario.propTypes = {
  index: PropTypes.number,
  operand: PropTypes.object,
  setOperand: PropTypes.func,
  flow: PropTypes.array.isRequired,
  setFlow: PropTypes.func.isRequired,
  remove: PropTypes.func,
};

export default Scenario;
