import {
  PFButton,
  PFContainer, PFIcon, PFInput, PFLink, PFLoader, PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import curly from '../assets/curly.svg';

const DictionaryPrompt = ({
  flowPrefix,
  setCopiedAlert,
  closeDictionary,
  copyOptions,
  setFlowPrefix,
  promptInfo, setPromptInfo,
  setCopyOptions,
  copyLoading
}) => {
  const [filteredValue, setFilteredValue] = useState(copyOptions);
  return (
    <PFContainer background={'var(--purple-500)'}
      width='572px' radius='m' border='1px solid var(--purple-300)'
      style={{ overflow: 'hidden' }}>
      <PFContainer
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        padding='top-s bottom-s right-m left-m'>
        <PFButton style={{
          backgroundColor: 'var(--purple-300)',
          borderRadius: '100%',
          display: 'flex'
        }} height='auto' padding='0'
          onClick={() => {
            setFlowPrefix(null);
            setPromptInfo(null);
            setCopyOptions(null);
            setFilteredValue('');
          }}>
          <PFIcon
            icon='bx bx-chevron-left'
            color='var(--purple-400)'
            size='l' />
        </PFButton>
        <PFContainer flex={1}>
          <PFInput
            style={{
              color: '#fff'
            }}
            onChange={(e) => {
              setFilteredValue(e.target.value);
            }}
            value={filteredValue}
          />
        </PFContainer>
        <PFButton
          type='support'
          padding='left-s right-xs'
          style={{
            backgroundColor: 'var(--purple-250)',
            borderRadius: 'var(--radius-s)',
            display: 'flex',
            gap: 'var(--size-xs)',
            alignItems: 'center',
            height: 'auto'
          }}
          onClick={() => {
            closeDictionary();
          }}>
          <PFText color={'var(--gray-100)'}
          >ESC</PFText>
          <PFIcon icon='bx bx-x'
            color={'var(--gray-100)'} />
        </PFButton>
      </PFContainer>
      {!copyLoading ? <PFContainer padding='s' style={{
        borderBottom: '1px solid var(--purple-300)',
        borderTop: '1px solid var(--purple-300)',
      }}>
        <PFText size='m'>
          {promptInfo?.nodeAlias} - Response variables
        </PFText>
      </PFContainer> : null}

      <PFContainer
        className='vars_container'
        padding='s'
        height='260px'
        position='relative'>
        {
          !copyLoading
            ? copyOptions && copyOptions?.length > 0
              ? copyOptions.filter((el) => {
                return filteredValue ? el.var_name
                  .toLowerCase().includes(filteredValue)
                  : true;
              }).map((option, index) => {
                return (
                  <div
                    key={index}
                    className='var_item'
                    style={{
                      padding: 'var(--size-s)',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 'var(--size-m)',
                      borderRadius: 'var(--radius-s)',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      const payload = flowPrefix.replace('${variable}'
                        , option.var_name);
                      setCopiedAlert(true);
                      setTimeout(() => {
                        setCopiedAlert(false);
                      }, 3000);
                      generalUtils.copyToClipboard(payload);
                      closeDictionary();
                    }}>
                    <PFContainer
                      background='#2C263E'
                      radius='s'
                      width='fit-content'
                      padding='s'>
                      <img width={'20px'} src={curly} />
                    </PFContainer>
                    <PFContainer flex={1}>
                      <PFText color='#fff'>{option.var_name}</PFText>
                      <PFText size='s' color={'var(--gray-100)'}>
                        {promptInfo?.type} - {promptInfo?.name}
                      </PFText>

                    </PFContainer>
                    <PFIcon
                      icon={`bx bx-copy`} color={'var(--gray-100)'}
                      size='l' />
                  </div>

                );
              })
              : <PFContainer display='flex' justifyContent='center' gap='xs'
                margin='top-m'>
                <PFText>Please test your
                </PFText>
                <div onClick={() => {
                  setFlowPrefix(null);
                  setPromptInfo(null);
                  setCopyOptions(null);
                  setFilteredValue('');
                }}>
                  <PFLink
                    color='var(--purple-200)'
                    to={promptInfo.path}
                    target='_blank'>
                    {promptInfo.type === 'API_CALL'
                      ? 'integration' : 'function'}
                  </PFLink>
                </div>
                <PFText>before using its response variables.
                </PFText>
              </PFContainer>
            : <PFContainer display='flex' justifyContent='center'>
              <PFLoader color='var(--purple-100)' />
            </PFContainer>
        }


      </PFContainer>

    </PFContainer>
  );
};

DictionaryPrompt.propTypes = {
  flowPrefix: PropTypes.string,
  setCopiedAlert: PropTypes.func,
  closeDictionary: PropTypes.func,
  copyOptions: PropTypes.array,
  setFlowPrefix: PropTypes.func,
  promptInfo: PropTypes.object,
  setPromptInfo: PropTypes.func,
  setCopyOptions: PropTypes.func,
  copyLoading: PropTypes.bool
};

export default DictionaryPrompt;
