import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFIconButton,
  PFInput,
  PFLine,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';

const DomainCreationModal = ({ appId, baseURL, active, onDismiss }) => {
  const navigate = useNavigate();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm({
    domain: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide your custom domain',
    },
  });

  const onSubmit = ({ domain }) => {
    asyncCall(async () => {
      await builderV2.createCustomDomain(appId, domain);
      store.dispatch(currentAppActions.addDomain(domain));
      navigate(`${baseURL}/domains/${domain}`);
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFForm form={form} submit={onSubmit}>
            <PFContainer margin="bottom-m"
              display="flex" alignItems="center" justifyContent="space-between">
              <PFText type="support">Create a new custom domain</PFText>
              <PFIconButton onClick={onDismiss} icon="bx bx-x" />
            </PFContainer>
            <PFContainer display="flex" flexDirection="column" gap="s">
              <PFInput
                placeholder="example.com"
                name="domain"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Domain
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
            </PFContainer>
            <PFContainer margin="top-l" display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={loading}>
                Register domain
              </PFButton>
            </PFContainer>
          </PFForm>
        </PFContainer >
      </PFModal >
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </>
  );
};

DomainCreationModal.propTypes = {
  appId: PropTypes.string.isRequired,
  baseURL: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default DomainCreationModal;
