import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import store, { currentFunctionActions, userActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';


const DBSelectionModal = ({
  active,
  onDismiss
}) => {
  const navigate = useNavigate();

  const currentFunction = useSelector((state) => state.current_function);
  const currentUserId = useSelector((state) => state.user._id);
  const functionDBs = currentFunction.associated_dbs?.docs ?? [];

  const userDBs = useSelector((state) => state.user.associated_dbs.docs);

  const filteredDBs = userDBs.filter((db) =>
    !functionDBs.find((d) => d._id === db._id));

  const [selectedDBs, setSelectedDBs] = useState([]);

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);
  const [connectAsyncCall, connectLoading, connectError, dismissConnectError]
    = useAsyncCall(false);

  const createDatabase = () => {
    createAsyncCall(async () => {
      let alias = `${currentFunction.info.alias} DB`;
      const dbCount = functionDBs.length + 1;
      if (dbCount > 1) alias += ` ${dbCount}`;

      const { InsertedID }
        = await builderTest(currentUserId).createDatabase({ alias });
      await builderV2.associateDBToFunction(currentFunction._id, InsertedID);
      store.dispatch(userActions.addDB({ alias, _id: InsertedID }));
      navigate(`/databases/${InsertedID}`);
    });
  };

  const connectDBs = () => {
    connectAsyncCall(async () => {
      const dbsToConnect = selectedDBs.map(
        (id) => filteredDBs.find((d) => d._id === id));
      for await (const db of dbsToConnect) {
        await builderV2.associateDBToFunction(currentFunction._id, db._id);
        store.dispatch(currentFunctionActions.addDB(db));
      }
      setSelectedDBs([]);
      onDismiss();
    });
  };

  const renderFilteredDBs = () => {
    return filteredDBs.map((db) => {
      const selected = selectedDBs.includes(db._id);

      const handleSelection = () => {
        if (selected) {
          setSelectedDBs(selectedDBs.filter(
            (id) => id !== db._id));
        } else {
          setSelectedDBs([...selectedDBs, db._id]);
        }
      };

      return (
        <PFButton key={db._id} type="none" onClick={handleSelection}>
          <PFContainer height={100} radius="m" width="100%" padding="m"
            display="flex" flexDirection="column" justifyContent="end"
            background="var(--strokes)"
            style={{ position: 'relative', textAlign: 'left' }}
            border={selected ? '1px solid var(--secondary)' : 'none'}>
            <PFContainer background={selected ? 'var(--secondary)' : 'white'}
              display='flex' alignItems='center' justifyContent='center'
              border={selected ? 'none' : '1px solid var(--secondary)'}
              style={{
                position: 'absolute', top: 15, right: 15,
                width: 24, borderRadius: '50%', aspectRatio: 1
              }}>
              <PFIcon icon="bx bx-check" color="white" />
            </PFContainer>
            <PFText size="s">{db.alias}</PFText>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">Connect to a database</PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="s">
            <PFButton type="none" onClick={createDatabase}
              loading={createLoading} padding="none">
              <PFContainer height={100} radius="m" width="100%"
                display="flex" flexDirection="column" justifyContent="center"
                background="var(--button-main-primary-background)">
                <PFContainer display="flex"
                  alignItems="center" justifyContent="center" gap="s">
                  <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
                  <PFText color="white">Create new</PFText>
                </PFContainer>
              </PFContainer>
            </PFButton>
            {renderFilteredDBs()}
          </PFContainer>
          <PFContainer margin="top-l" display="flex" justifyContent="end">
            <PFButton onClick={connectDBs}
              loading={connectLoading}
              disabled={selectedDBs.length === 0}
              padding="horizontal-xl">
              Confirm connection
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
      <PFAlert
        message={connectError}
        open={!!connectError}
        onClose={dismissConnectError} />
    </>
  );
};

DBSelectionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default DBSelectionModal;
