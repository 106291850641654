import { PFContainer, PFSelect, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';


const QuerySort = ({
  execute,
  db,
  setSortOrder,
  setSortColumn
}) => {
  const getColumnOptions = () => {
    const targetTable = execute.table;
    const joinTables = execute.joins?.map((join) => {
      return join.table;
    });
    const tables = [targetTable, ...joinTables];
    const result = [];

    tables.forEach((tableName) => {
      const tableSchema = db.tables.find((schema) =>
        schema.table_name === tableName);

      if (tableSchema) {
        const columnOptions = tableSchema.fields.map((field) => {
          return {
            label: `${tableSchema.table_name} -> ${field.alias}`,
            value: `${tableSchema.table_name}.${field.column_name}`
          };
        });
        result.push(...columnOptions);
      }
    });
    return result;
  };
  const pagination = execute.pagination ? execute.pagination : {};
  return (
    <PFContainer display="grid" gap="m" alignItems="center"
      gridTemplateColumns=".2fr 1fr .2fr 1fr">
      <PFText type="secondary">Column</PFText>
      <PFSelect
        value={pagination.sort_column
          ? pagination.sort_column : ''}
        onChange={(e) => {
          setSortColumn(e.target.value);
        }}
        options={getColumnOptions()} />
      <PFText type="secondary">Order</PFText>
      <PFSelect
        value={pagination.sort_order
          ? pagination.sort_order : ''}
        onChange={(e) => {
          setSortOrder(e.target.value);
        }}
        options={[
          { label: 'Ascending', value: 'ASC' },
          { label: 'Descending', value: 'DESC' }]} />
    </PFContainer>
  );
};

QuerySort.propTypes = {
  execute: PropTypes.object.isRequired,
  db: PropTypes.object,
  setSortOrder: PropTypes.func,
  setSortColumn: PropTypes.func,
};

export default QuerySort;
