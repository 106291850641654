import { PFButton, PFIcon } from 'PFComponents/common';
import PropTypes from 'prop-types';

const AddButton = ({ nextNode, hovered, onClick }) => {
  const addButtonStyles = {
    paddingLeft: nextNode ? 'revert-layer' : 0,
    paddingTop: 27,
    marginLeft: nextNode ? 'revert-layer' : 0,
    opacity: (nextNode && hovered) || !nextNode ? 1 : 0
  };

  return (
    <PFButton type="support"
      onClick={() => {
        onClick();
      }}
      style={addButtonStyles}>
      <PFIcon icon="bx bx-plus-circle" size="l" />
    </PFButton>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  hovered: PropTypes.bool,
  nextNode: PropTypes.string
};

export default AddButton;
