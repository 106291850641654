import { PFContainer, PFText } from 'PFComponents/common';
import { dnd } from 'PFUtils';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  calcRadiusByBoundary,
  calcTagBoundaries,
  getIframeRelativeScrollPos,
  useBreakpointContext
} from '../../utils/index';


function Inspector({ canvas, blob }) {
  const { hover, selected, dropZone, viewportHeight } = canvas;
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const iframeScrollPosition = getIframeRelativeScrollPos('responsive-frame');
  const { activeBreakpoint } = useBreakpointContext();

  useEffect(() => {
    forceUpdate();
  }, [selected, JSON.stringify(blob), activeBreakpoint]);

  const renderControls = (target, viewportHeight, color = 'secondary') => {
    if (!target) return null;

    const outlineStyle = {
      position: 'absolute',
      border: `2px solid var(--${color})`,
      pointerEvents: 'none',
      zIndex: 5,
      width: target.getBoundingClientRect().width,
      height: target.getBoundingClientRect().height,
      top: target.getBoundingClientRect().top + iframeScrollPosition.y,
      left: target.getBoundingClientRect().left + iframeScrollPosition.x,
    };

    const infoPanelStyle = {
      fontSize: 'var(--text-size-s)',
      position: 'absolute',
      top: target ? calcTagBoundaries(target, 'info', viewportHeight) : 0,
      left: target
        ? target.getBoundingClientRect().left
        : 0,
      padding: '2px 4px',
      boxSizing: 'border-box',
      background: `var(--${color})`,
      display: target ? 'block' : 'none',
      zIndex: 6,
    };

    const name = target.dataset?.alias ?? target.id;
    const tag = target.dataset?.tag ? ` - ${target.dataset?.tag}` : '';
    return (
      <>
        <PFContainer style={outlineStyle} />
        <PFContainer style={infoPanelStyle}>
          <PFText size="s">{name}{tag}</PFText>
        </PFContainer>
      </>
    );
  };

  const renderDropOutline = (dropZone) => {
    if (!dropZone) return null;

    const { target, orientation } = dropZone;

    const outlineStyle = {
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: 5,
      width: target.getBoundingClientRect().width,
      height: target.getBoundingClientRect().height,
      top: target.getBoundingClientRect().top + iframeScrollPosition.y,
      left: target.getBoundingClientRect().left + iframeScrollPosition.x,
    };
    const position = dnd.getDropPosition(dropZone);

    switch (position) {
      case 'BEFORE':
        if (orientation === 'VERTICAL') {
          outlineStyle.backgroundColor = `var(--warning-100-20)`;
          outlineStyle.borderTop = `3px solid var(--success-saturated)`;
          outlineStyle.marginTop = -1;
        } else if (orientation === 'HORIZONTAL') {
          outlineStyle.borderLeft = `3px solid var(--warning-100)`;
          outlineStyle.marginLeft = -1;
        }
        break;
      case 'AFTER':
        if (orientation === 'VERTICAL') {
          outlineStyle.backgroundColor = `var(--warning-100-20)`;
          outlineStyle.borderBottom = `3px solid var(--success-saturated)`;
          outlineStyle.marginBottom = 1;
        } else if (orientation === 'HORIZONTAL') {
          outlineStyle.borderRight = `3px solid var(--warning-100)`;
          outlineStyle.marginRight = 1;
        }
        break;
      case 'FIRST':
        if (orientation === 'VERTICAL') {
          outlineStyle.backgroundColor = `var(--warning-100-20)`;
          outlineStyle.border = `3px solid var(--warning-100)`;
          outlineStyle.borderTop = `3px dashed var(--success-saturated)`;
        } else if (orientation === 'HORIZONTAL') {
          outlineStyle.borderLeft = `3px solid var(--warning-100)`;
          outlineStyle.marginLeft = -1;
        }
        break;
      case 'LAST':
        if (orientation === 'VERTICAL') {
          outlineStyle.backgroundColor = `var(--warning-100-20)`;
          outlineStyle.border = `3px solid var(--warning-100)`;
          outlineStyle.borderBottom = `3px dashed var(--success-saturated)`;
        } else if (orientation === 'HORIZONTAL') {
          outlineStyle.borderRight = `3px solid var(--warning-100)`;
          outlineStyle.marginRight = -1;
        }
        break;
      default:
        outlineStyle.border = `3px solid var(--warning-100)`;
        outlineStyle.backgroundColor = `var(--warning-100-20)`;
        break;
    }
    return (
      <PFContainer style={outlineStyle} >
        <PFText size='xs' style={{
          position: 'absolute',
          backgroundColor:
            position === 'BEFORE' || position === 'AFTER'
              ? 'var(--success-saturated)'
              : 'var(--warning-100)',
          borderRadius: target ? calcRadiusByBoundary(target) : 0,
          padding: '2px 4px',
          width: 'fit-content',
          top: target
            ? calcTagBoundaries(target, 'dropzone', viewportHeight)
            : 0,
          left:
            position === 'BEFORE' || position === 'AFTER'
              ? 0
              : -3
        }}>
          {dropZone.component}
        </PFText>
      </PFContainer>);
  };

  const renderHoveredControls = () => {
    const hoverIsSelected = !!selected?.id && hover?.id === selected?.id;
    return renderControls(
      !hoverIsSelected ? hover : null,
      viewportHeight);
  };

  const renderSelectedControls = () => {
    const dropZoneId = dropZone?.target?.id;
    const selectedIsDropZone = !!dropZoneId && selected?.id === dropZoneId;
    return renderControls(
      !selectedIsDropZone ? selected : null,
      viewportHeight,
      'primary'
    );
  };

  const renderDropZoneControls = () => {
    return renderDropOutline(dropZone);
  };

  return (
    <>
      <PFContainer style={{
        position: 'absolute', pointerEvents: 'none',
        top: 0, left: 0, bottom: 0, right: 0,
      }}>
        {renderSelectedControls()}
        {renderHoveredControls()}
        {renderDropZoneControls()}
      </PFContainer>
    </>
  );
}

Inspector.propTypes = {
  blob: PropTypes.array,
  canvas: PropTypes.object.isRequired
};

export default Inspector;
