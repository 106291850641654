import {
  PFContainer, PFLine, PFLoader, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { handlers } from '../utils';
import NodeResult from './NodeResult';

const NodeResults = ({ loading, nodeResults }) => {
  const { handleNodeTypes, isEmpty } = handlers;
  return (
    <PFContainer width='70%'>
      <PFText color='var(--black)'>Node results</PFText>
      {
        loading
          ? <PFContainer display='flex' justifyContent='center'>
            <PFLoader color='var(--purple-100)' />
          </PFContainer>
          : <PFContainer margin='top-s' className='custom-scroll'
            style={{
              maxHeight: '600px',
              overflow: 'auto'
            }}
            display='flex' flexDirection='column'
            gap='l' padding='right-s'>
            {
              nodeResults?.length > 0
                ? nodeResults.map((node_result, index) => {
                  if (!node_result.request) return null;
                  const requestDate = new Date(node_result.request.timestamp);
                  const responseDate = new Date(node_result
                    .response.timestamp);
                  const nodeDate = new Date(node_result.start_time);
                  const nodeResultsFormatted = handleNodeTypes(node_result);
                  return (<PFContainer key={index}>
                    <PFContainer display='flex' gap='xs'>
                      <PFText color='var(--purple-200)'>
                        {node_result.node_name}
                      </PFText>
                      <PFText color='var(--black)'>
                        -
                      </PFText>
                      <PFText color='var(--purple-100)'>
                        {nodeDate.toLocaleString()}
                      </PFText>
                    </PFContainer>

                    <PFContainer background='var(--purple-25)'
                      padding='m' radius='m' margin='top-s'>
                      {
                        !isEmpty(nodeResultsFormatted.request.body) ? <>
                          <NodeResult type='request'
                            timestamp={requestDate.toLocaleString()}
                            body={nodeResultsFormatted.request.body} />
                          <PFLine margin='top-m bottom-m' />
                        </>
                          : <></>
                      }

                      <NodeResult type='response'
                        timestamp={responseDate.toLocaleString()}
                        body={nodeResultsFormatted.response.body}
                        message={node_result.response.message} />

                    </PFContainer>
                  </PFContainer>);
                })
                : <PFContainer padding='s' background='var(--purple-25)'
                  radius='s' display='flex' gap='xs' justifyContent='center'>
                  <PFText color='var(--black)' style={{
                    display: 'flex'
                  }}>
                    Click
                  </PFText>
                  <PFText color='var(--purple-100)' weight={'600'}>
                    Run
                  </PFText>
                  <PFText color='black'>to test this Process Flow</PFText>
                </PFContainer>
            }
          </PFContainer>
      }


    </PFContainer>
  );
};

NodeResults.propTypes = {
  loading: PropTypes.bool,
  nodeResults: PropTypes.array
};

export default NodeResults;
