import { PFContainer, PFInput, PFSelect, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { conditionalOperators } from './common/conditionalOperators';

const ConditionalVisibilityControls = ({
  component,
  setComponent,
  formFieldNames }) => {
  const handleVisiblePropertyChange = (propertyName, propertyValue) => {
    const updatedComponent = { ...component };
    if (!updatedComponent.settings) {
      updatedComponent.settings = {};
    }
    if (!updatedComponent.settings.form) {
      updatedComponent.settings.form = {};
    }
    if (!updatedComponent.settings.form.type) {
      updatedComponent.settings.form.type = 'form';
    }
    if (!Array.isArray(updatedComponent.settings.form.visible)) {
      updatedComponent.settings.form.visible = [];
    }
    // eslint-disable-next-line max-len
    if (updatedComponent.settings.form.visible.length === 0 || updatedComponent.settings.form.visible[updatedComponent.settings.form.visible.length - 1] === undefined) {
      // eslint-disable-next-line max-len
      updatedComponent.settings.form.visible.push({
        key: '',
        ref: '',
        val: ''
      });
    }
    if (propertyValue === 'true') {
      propertyValue = true;
    } else if (propertyValue === 'false') {
      propertyValue = false;
    }
    // eslint-disable-next-line max-len
    updatedComponent.settings.form.visible[updatedComponent.settings.form.visible.length - 1][propertyName] = propertyValue;
    setComponent(updatedComponent);
  };
  return (
    <PFContainer padding={'top-m right-s bottom-m left-s'}>
      <PFText margin='bottom-s' size='header'>Conditional Visibility</PFText>
      <PFContainer display='flex' flexDirection='column'
        gap='s'>
        <PFContainer>
          <PFContainer
            display='grid' alignItems='center'
            gridTemplateColumns='100px 1fr' gap='s'>
            <PFText size='header'>
              {'Reference Field'}
            </PFText>
            <PFContainer>
              <PFSelect
                options={Object.keys(formFieldNames)
                  .map((key) => ({
                    label: key,
                    value: key
                  }))}
                onChange={(e) =>
                  handleVisiblePropertyChange('ref', e.target.value)}
                // eslint-disable-next-line max-len
                value={component.settings?.form?.visible?.[component.settings?.form?.visible?.length - 1]?.ref ?? ''}
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  height: 30,
                  backgroundColor: 'var(--purple-400)',
                  fontSize: 'var(--text-label)'
                }}
                optionsStyles={{
                  color: 'var(--white)'
                }}
                listStyles={{
                  border: '1px solid var(--purple-300)',
                  backgroundColor: 'var(--purple-500)'
                }}
              />
            </PFContainer>
            <PFText size='header'>
              {'Operator'}
            </PFText>
            <PFContainer>
              <PFSelect
                options={conditionalOperators}
                onChange={(e) =>
                  handleVisiblePropertyChange('key', e.target.value)}
                // eslint-disable-next-line max-len
                value={component.settings?.form?.visible?.[component.settings?.form?.visible?.length - 1]?.key ?? ''}
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  height: 30,
                  backgroundColor: 'var(--purple-400)',
                  fontSize: 'var(--text-label)'
                }}
                optionsStyles={{
                  color: 'var(--white)'
                }}
                listStyles={{
                  border: '1px solid var(--purple-300)',
                  backgroundColor: 'var(--purple-500)'
                }}
              />
            </PFContainer>
            <PFText size='header'>
              {'Value'}
            </PFText>
            <PFContainer>
              <PFInput
                onChange={(e) =>
                  handleVisiblePropertyChange('val', e.target.value)}
                // eslint-disable-next-line max-len
                value={component.settings?.form?.visible?.[component.settings?.form?.visible?.length - 1]?.val ?? ''}
                style={{ width: 50, height: 30 }}
                fontSize='label'
              />
            </PFContainer>
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};
ConditionalVisibilityControls.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  formFieldNames: PropTypes.object.isRequired
};


export default ConditionalVisibilityControls;
