import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DomainCreationModal } from './components';


const PFScreenAppDomains = () => {
  const currentApp = useSelector((state) => state.current_app);
  const domains = currentApp.domains;

  const [creationFormOpen, setCreationFormOpen] = useState(false);

  const baseURL = `/apps/${currentApp._id}/domains`;

  const renderDomains = () => {
    return domains.map(({ domain, status, env }) => {
      return (
        <PFItemCard
          key={domain}
          path={`${baseURL}/${domain}`}
          options={{
            'Copy domain': () => generalUtils.copyToClipboard(domain)
          }}>
          <PFText type="secondary" size="s" clip style={{ maxWidth: 250 }}>
            {env}
          </PFText>
          <PFText margin={!env ? 'top-m' : ''}>{domain}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            {status}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <>
      <PFContainer type="main">
        <PFContainer>
          <PFContainer margin='bottom-m'>
            <PFText tag="h2" size="l">Domains</PFText>
          </PFContainer>
          <PFContainer display="grid"
            gridTemplateColumns="1fr 1fr 1fr" gap="m">
            <PFActionCard onClick={() => setCreationFormOpen(true)}>
              <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
              <PFText>New Domain</PFText>
            </PFActionCard>
            {renderDomains()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <DomainCreationModal
        appId={currentApp._id}
        baseURL={baseURL}
        active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
    </>
  );
};

export default PFScreenAppDomains;
