import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  NodeBasicAction,
  NodeBasicUIAction,
  NodeChangeStep,
  NodeConditional,
  NodeCustomAPI,
  NodeCustomSave,
  NodeDeleteFromSession,
  NodeFunctionCall,
  NodeLogin,
  NodeSummary,
  NodeSwitch
} from './components';


/**
 * @typedef {import('../../../../utils/flows').ProcessFlowNode} ProcessFlowNode
 */

/**
 * Renders components necessary to display and edit a process flow node
 * information.
 *
 * @param {Object} props - Node editor props.
 * @param {ProcessFlowNode} props.node - Node to edit.
 * @param {Object} props.config - Node render configuration.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.removeNode - Function to remove the node.
 *
 * @return {React.ReactElement} - Node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeEditor = ({ node, config, updateNode, removeNode }) => {
  const [tab, setTab] = useState(config.editor_tab ?? 'SUMMARY');

  const goBack = () => setTab('SUMMARY');

  const renderEditor = () => {
    const props = { node, updateNode, goBack };

    switch (node.type) {
      case 'API_CALL':
        return <NodeCustomAPI {...props} />;
      case 'FUNCTION_CALL':
        return <NodeFunctionCall {...props} />;
      case 'CHANGE_STEP':
        return <NodeChangeStep {...props} />;
      case 'AUTH':
        return <NodeLogin {...props} />;
      case 'CONDITIONAL_IFELSE':
        return <NodeConditional {...props} />;
      case 'CONDITIONAL_SWITCH':
        return <NodeSwitch {...props} />;
      case 'ACTION':
        return <NodeBasicUIAction {...props} />;
      case 'SAVE_IN_SESSION_CUSTOM':
        return <NodeCustomSave {...props} />;
      case 'UTILS':
        return <NodeDeleteFromSession {...props} />;
      default:
        return <NodeBasicAction {...props} />;
    };
  };

  return (
    <>
      {tab === 'SUMMARY'
        ? <NodeSummary
          node={node}
          goEdit={() => setTab('EDIT')}
          removeNode={removeNode} />
        : renderEditor()
      }
    </>
  );
};

NodeEditor.propTypes = {
  node: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  removeNode: PropTypes.func.isRequired,
};

export default NodeEditor;
