export default [
  {
    label: '=',
    value: 'EQUALS',
  },
  {
    label: '>',
    value: 'GREATER_THAN',
  },
  {
    label: '>=',
    value: 'GREATER_THAN_EQUALS',
  },
  {
    label: '<',
    value: 'LESS_THAN',
  },
  {
    label: '<=',
    value: 'LESS_THAN_EQUALS',
  },
];
