import { PFContainer, PFInput, PFSelect, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import DEFAULT_VALUES_TYPES from 'src/constants/DB/DEFAULT_VALUES_TYPES';
import './DefaultInput.css';

const DefaultInput = ({ columnType, defaultValue, setDefaultValue,
  isDefaultRequired, setIsDefaultRequired, nullable, unique,
  fieldLabel }) => {
  const renderInput = (type) => {
    switch (type) {
      case 'Integer':
      case 'Big Integer':
        return (
          <PFInput type='string'
            value={defaultValue} onChange={(e) => {
              if (/^-?\d*$/.test(e.target.value)) {
                setDefaultValue(e.target.value);
                if (e.target.value) {
                  setIsDefaultRequired(false);
                }
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }} />
        );

      case 'Float':
      case 'Decimal':
        return (
          <PFInput type='string'
            value={defaultValue} onChange={(e) => {
              if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                setDefaultValue(e.target.value);
                if (e.target.value) {
                  setIsDefaultRequired(false);
                }
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }} />
        );

      case 'Date':
        return (
          <input
            className='default-input'
            type={'date'}
            value={defaultValue} onChange={(e) => {
              const maxDate = new Date('9999-12-31');
              const inputDate = new Date(e.target.value);
              if (inputDate > maxDate) {
                setDefaultValue('9999-12-31');
              } else {
                setDefaultValue(e.target.value);
              }
              if (e.target.value) {
                setIsDefaultRequired(false);
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }} />
        );
      case 'Date & Time':
        return (
          <input className='default-input'
            type={'datetime-local'}
            value={defaultValue} onInput={(e) => {
              const maxDate = new Date('9999-12-31T23:59:59');
              const inputDate = new Date(e.target.value);
              if (inputDate > maxDate) {
                setDefaultValue('9999-12-31T23:59:59');
              } else {
                setDefaultValue(e.target.value);
              }
              if (e.target.value) {
                setIsDefaultRequired(false);
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }} max="9999-12-31T23:59:59" min='1753-01-01T00:00:00' style={{
              cursor: 'text',
            }} />
        );
      default:
        return (
          <PFInput
            type={DEFAULT_VALUES_TYPES[columnType].type}
            value={defaultValue} onChange={(e) => {
              setDefaultValue(e.target.value);
              if (e.target.value) {
                setIsDefaultRequired(false);
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }} />
        );
    }
  };

  const defaultLabel = nullable === 'false'
    ? `${fieldLabel}`
    : `${fieldLabel} (optional)`;

  if (DEFAULT_VALUES_TYPES[columnType].type === 'checkbox') {
    return (
      <>
        {
          unique === 'true' ? <></>
            : <PFContainer>
              <PFText>{defaultLabel}</PFText>
              <PFSelect options={[
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' }
              ]} onChange={(e) => {
                setDefaultValue(e.target.value);
                if (e.target.value) {
                  setIsDefaultRequired(false);
                }
              }} value={defaultValue} zIndex={1500} />
              {isDefaultRequired
                ? <PFText size='s'
                  color='var(--failure)'>
                  Default value required
                </PFText>
                : <></>}
            </PFContainer>
        }
      </>
    );
  }

  return (
    <>
      {unique === 'true' ? <></>
        : <PFContainer>
          <PFText >{defaultLabel}</PFText>
          {renderInput(columnType)}

          {isDefaultRequired
            ? <PFText size='s'
              color='var(--failure)'>
              Default value required
            </PFText>
            : <></>}

        </PFContainer>}
    </>
  );
};

DefaultInput.propTypes = {
  columnType: PropTypes.string,
  setDefaultValue: PropTypes.func,
  defaultValue: PropTypes.string,
  isDefaultRequired: PropTypes.bool,
  setIsDefaultRequired: PropTypes.func,
  nullable: PropTypes.string,
  setNullable: PropTypes.func,
  unique: PropTypes.string,
  fieldLabel: PropTypes.string
};

export default DefaultInput;

