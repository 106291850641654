import NODE_TYPE_ACTION_OPTIONS from './NODE_TYPE_ACTION_OPTIONS';
import NODE_TYPE_CONDITIONAL_OPTIONS from './NODE_TYPE_CONDITIONAL_OPTIONS';
import NODE_TYPE_UI_ACTION_OPTIONS from './NODE_TYPE_UI_ACTION_OPTIONS';

const NODE_OPTIONS_BY_CATEGORY = {
  'ACTION': NODE_TYPE_ACTION_OPTIONS,
  'CONDITIONAL': NODE_TYPE_CONDITIONAL_OPTIONS,
  'UI_ACTIONS': NODE_TYPE_UI_ACTION_OPTIONS
};

export default NODE_OPTIONS_BY_CATEGORY;
