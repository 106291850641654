import { PFContainer } from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  AddButton, NodeSelection, TypeSelection
} from './components';


const NODE_OPTIONS = PROCESS_FLOW.NODE_OPTIONS_BY_CATEGORY;

const NodeCreation = ({ nextNode, createNode, nodes,
  flowAlias }) => {
  const [tab, setTab] = useState('START');
  const [isHovered, setIsHovered] = useState(false);

  const CreationTab = ({ hovered }) => {
    switch (tab) {
      case 'START':
        return (
          <AddButton
            nextNode={nextNode}
            hovered={hovered}
            onClick={() => setTab('TYPE_SELECTION')} />
        );
      case 'TYPE_SELECTION':
        return (<TypeSelection
          setTab={setTab}
          setIsHovered={setIsHovered}
          createNode={createNode}
          options={NODE_OPTIONS.ACTION}
          nodes={nodes}
          next={nextNode}
          flowAlias={flowAlias} />);
      case 'ACTION':
        return (<NodeSelection
          title="New action"
          setTab={setTab}
          setIsHovered={setIsHovered}
          options={NODE_OPTIONS.ACTION}
          createNode={createNode}
          next={nextNode}
        />);
      case 'CONDITIONAL':
        return (<NodeSelection
          title="New conditional"
          setTab={setTab}
          setIsHovered={setIsHovered}
          options={NODE_OPTIONS.CONDITIONAL}
          createNode={createNode}
          next={nextNode}
        />);
      case 'UI_ACTION':
        return (<NodeSelection
          title="New UI action"
          setTab={setTab}
          setIsHovered={setIsHovered}
          options={NODE_OPTIONS.UI_ACTIONS}
          createNode={createNode}
          next={nextNode} />);
    };
  };

  CreationTab.propTypes = {
    hovered: PropTypes.bool
  };

  return (
    <PFContainer type="editor"
      onMouseEnter={() => {
        if (tab === 'START') {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (tab === 'START') {
          setIsHovered(false);
        }
      }}
      style={{
        backgroundColor: (
          isHovered && tab === 'START'
          || tab === 'TYPE_SELECTION')
          ? 'var(--container-editor-background)'
          : 'unset'
      }}
    >
      <CreationTab hovered={isHovered} />
    </PFContainer>
  );
};

NodeCreation.propTypes = {
  createNode: PropTypes.func.isRequired,
  nextNode: PropTypes.string,
  nodes: PropTypes.object,
  flowAlias: PropTypes.string,
};

export default NodeCreation;
