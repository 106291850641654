/**
 * Tests if a string is valid or not checking if it is null or if it's
 * lenght is more than 0.
 *
 * @param {string} string - String to check.
 *
 * @return {bool} True if the string is valid, false if not.
 */
export const isValidString = (string) => {
  return !!string || string.length !== 0;
};

/**
 * Validates that content is present (any content). Returns 'null' if it is
 * valid (no error message to display).
 *
 * @param {string} content - Content to check.
 * @param {string} errorMessage - Error message to return should the text not
 * pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validateContent = (content, errorMessage) => {
  if (!content) {
    return errorMessage ?? 'Please provide some content';
  }

  return null;
};


/**
 * Validates a text and returns an error message if it is not valid. Returns
 * 'null' if it is valid (no error message to display).
 *
 * @param {string} text - Text to check.
 * @param {string} errorMessage - Error message to return should the text not
 * pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validateText = (text, errorMessage) => {
  if (!isValidString(text)) {
    return errorMessage ?? 'Please provide some text';
  }

  return null;
};

/**
 * Validates an email and returns an error message if it is not valid. Returns
 * 'null' if it is valid (no error message to display).
 *
 * @param {string} email - Email to check.
 * @param {string} errorMessage - Error message to return should the email not
 * pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validateEmail = (email, errorMessage) => {
  // eslint-disable-next-line max-len
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  if (!isValidString(email)) {
    return errorMessage ?? 'Please provide an email';
  } else if (!emailRegex.test(email)) {
    return errorMessage ?? 'Please provide a valid email';
  }

  return null;
};

/**
 * Compares two values and returns an error message if they are not the same.
 * Returns 'null' if it is valid (no error message to display).
 *
 * @param {string} fieldValue - Email to check.
 * @param {string} errorMessage - Error message to return should the email not
 * @param {object} params - Extra params
 * pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validateConfirmation = (fieldValue, errorMessage, params) => {
  if (params?.submit) {
    if (params.form.values[params.originValue]
      !== params.form.values[params.referenceValue]) {
      return errorMessage ?? 'The password does not match';
    }
  }

  return null;
};

/**
 * Validates a password and returns an error message if it is not valid. Returns
 * 'null' if it is valid (no error message to display).
 *
 * @param {string} password - Password to check.
 * @param {string} errorMessage - Error message to return should the password
 * not pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validatePassword = (password, errorMessage) => {
  // eslint-disable-next-line max-len
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;

  if (!isValidString(password)) {
    return errorMessage ?? 'The password does not match';
  } else if (!passwordRegex.test(password)) {
    return errorMessage ?? 'The password does not match';
  }

  return null;
};

/**
 * Validates a digit code and returns an error message if it is not valid.
 * Returns 'null' if it is valid (no error message to display).
 *
 * @param {string} digitCode - Value to check.
 * @param {string} errorMessage - Error message to return should the digit code
 * not pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validateSixDigitCode = (digitCode, errorMessage) => {
  // eslint-disable-next-line max-len
  const digitCodeRegex = /^\d{6}$/;

  if (!isValidString(digitCode)) {
    return errorMessage ?? 'Please provide a digit code';
  } else if (!digitCodeRegex.test(digitCode)) {
    return errorMessage ?? 'Please provide a valid digit code';
  }

  return null;
};


/**
 * Validates a JSON string and returns an error message if it is not valid.
 * Returns 'null' if it is valid (no error message to display). This validation
 * passes when using handlebars too ({{*}}).
 *
 * @param {string} jsonString - JSON string to check.
 * @param {string} errorMessage - Error message to return should the string not
 * pass the validation check.
 *
 * @return {string|null} Error message.
 */
export const validateJSON = (jsonString, errorMessage) => {
  if (!jsonString) return null;

  try {
    jsonString = jsonString.replace(/{{([^{}]*)}}/g, null);
    JSON.parse(jsonString);
  } catch (e) {
    return errorMessage ?? 'Please provide a valid JSON';
  }

  return null;
};

export const validateXML = (xmlString, errorMessage) => {
  if (!xmlString) return null;

  try {
    xmlString = xmlString.replace(/{{([^{}]*)}}/g, null);
    const parser = new DOMParser();
    parser.parseFromString(xmlString, 'text/xml');
  } catch (e) {
    return errorMessage ?? 'Please provide a valid XML';
  }

  return null;
};

export default {
  isValidString,
  validateContent,
  validateText,
  validateEmail,
  validateJSON,
  validatePassword,
  validateSixDigitCode,
  validateConfirmation,
  validateXML
};
