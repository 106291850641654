import PropTypes from 'prop-types';
import PFButton from '../PFButton/PFButton';
import PFIcon from '../PFIcon/PFIcon';
import './PFIconButton.scss';

/**
 * Standard icon button component of the PFComponents library. This button
 * complies with the Pefai style guidelines. The underlying implementation of
 * this icon button is the PFButton and PFIcon components.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFIconButton properties.
 * @param {string} props.icon - Icon to be displayed inside the button.
 * @param {React.ReactNode} props.icon - Icon to be displayed inside the button.
 * @param {function} [props.onClick] - Function to be performed on a click
 * action.
 * @param {string} [props.className] - Class name to be applied to the icon
 * button.
 * @param {string} [props.margin] - Determines the margin config of the icon
 * button component.
 * @param {boolean} [props.disabled] - Determines if the button is disabled
 * or not.
 *
 * @return {React.ReactElement} The icon button component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFIconButton = ({
  icon,
  onClick,
  className,
  margin,
  disabled = false
}) => {
  const iconButtonClassName = `icon-button ${className}`;

  return (
    <PFButton type="support"
      onClick={onClick}
      className={iconButtonClassName}
      padding="none"
      margin={margin}
      height={28}
      disabled={disabled}
      style={{
        borderRadius: '50%',
        aspectRatio: 1,
      }}>
      <PFIcon icon={icon} size="l" />
    </PFButton>
  );
};

PFIconButton.defaultProps = {
  className: '',
};

PFIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PFIconButton;
