import { PFButton, PFContainer, PFSwitch, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import QueryAggregation from './QueryAggregation';

const QueryAggregations = ({ aggregations, setAggregations, execute, db }) => {
  const getAggregationOnly = () => {
    if (aggregations.length === 0) {
      return false;
    }

    const firstItem = aggregations[0];
    return firstItem.aggregation_only ?? false;
  };

  const addAggregation = () => {
    setAggregations([...aggregations, {
      column: '', operation: '', alias: '',
      aggregation_only: getAggregationOnly()
    }]);
  };

  const setAggregationOnly = () => {
    const newAggregations = aggregations.map((item) => ({
      ...item,
      aggregation_only: !item.aggregation_only
    }));
    setAggregations(newAggregations);
  };

  const renderAggregations = () => {
    return aggregations.map((aggregation, i) => {
      const setAggregation = (aggregation) => {
        aggregations[i] = aggregation;
        setAggregations(aggregations);
      };
      const removeAggregation = () => {
        aggregations.splice(i, 1);
        setAggregations(aggregations);
      };

      return (
        <QueryAggregation key={i}
          index={i}
          aggregation={aggregation}
          setAggregation={setAggregation}
          removeAggregation={removeAggregation}
          execute={execute}
          db={db} />
      );
    });
  };

  return (
    <PFContainer margin="top-s" display="flex"
      flexDirection="column" gap="s">
      {renderAggregations()}
      <PFButton type="support" onClick={addAggregation}
        style={{ alignSelf: 'start' }}>
        + add aggregate data
      </PFButton>
      {
        aggregations?.length > 0
          ? <PFContainer display='flex'
            margin='bottom-s top-s'>
            <PFContainer display='flex' gap='s' justifyContent='space-between'
              flex={1}>
              <PFText>Aggregate results only</PFText>
              <PFSwitch checked={getAggregationOnly()}
                onChange={() => {
                  setAggregationOnly();
                }} />
            </PFContainer>
          </PFContainer>
          : null
      }
    </PFContainer>
  );
};

QueryAggregations.propTypes = {
  aggregations: PropTypes.array.isRequired,
  setAggregations: PropTypes.func.isRequired,
  execute: PropTypes.object,
  db: PropTypes.object
};

export default QueryAggregations;
