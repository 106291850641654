import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';
import * as Styled from './accordionitem.styled';

export type AccordionitemProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * To know if the accordion is open
     */
    handleIsOpen?: boolean;

    /**
     * A function to change the current state in the reducer on each click
     */
    handleOnClick?: () => void;

    /**
     * To identify an Accordion Item.
     */
    id?: string;

    /**
     * to identify the Accordion Item
     */
    tabId: string;
};

export function Accordionitem({
    id,
    children,
    className,
    configStyles,
    handleIsOpen = false,
    handleOnClick,
    tabId,
    ...props
}: AccordionitemProps) {
    const childrenArray = Children.toArray(children);
    const headerCloned = cloneElement(childrenArray[0] as ReactElement, {
        isOpen: handleIsOpen,
        handleOnClick: handleOnClick,
    });

    return (
        <Styled.Accordionitem
            className={className}
            configStyles={configStyles}
            tabId={tabId}
            handleIsOpen={handleIsOpen}
            id={id}
            {...props}
        >
            {headerCloned}
            {handleIsOpen && <>{children[1]}</>}
        </Styled.Accordionitem>
    );
}
