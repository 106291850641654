import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import FlowLink from './FlowLink';


/**
 * Component to draw a flow-like structure. Consists of nodes interlinked
 * between each other.
 *
 * @param {Object} props - Props of the component.
 * @param {*} props.children - Nodes to be displayed inside the flow. Each
 * element should hold the props: node, row, column, links and plus.
 * @param {int} [props.lineOffset=40] - Offset from the top that the line drawn
 * will have.
 *
 * @return {React.ReactElement} The PFFlowRenderer component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFFlowRenderer = ({ children, lineOffset }) => {
  const flowRef = useRef(null);

  const nodes = Array.isArray(children) ? children : [children];
  const nodeRefs = useRef({});

  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (!flowRef.current) return;

    // Redraw links when the component changes sizes
    const buildLinks = () => setLinks(renderLinks());

    const resizeObserver = new ResizeObserver(buildLinks);
    resizeObserver.observe(flowRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const renderNodes = () => {
    return nodes.map((nodeComp) => {
      const { node, config } = nodeComp.props;
      const { links, row, column, suffix, plus } = config;
      const style = { gridRowStart: row, gridColumnStart: column };
      if (plus) style.justifySelf = 'start';

      return (
        <div
          key={node._id}
          className="pf flow-node"
          ref={(e) => nodeRefs.current[node._id] = {
            component: e,
            suffix: suffix,
            links,
          }}
          style={style}>
          {nodeComp}
        </div>
      );
    });
  };

  const renderLinks = () => {
    const nodesConfig = nodeRefs.current;
    const nodes = [];

    let firstNode = true;
    for (const sourceNodeId in nodesConfig) {
      const node = nodesConfig[sourceNodeId];
      if (!node.component) continue;

      const { right, left, top } = node.component.getBoundingClientRect();
      const sourcePosition = { x: right, y: top + lineOffset };

      // Draw flow preceding line
      if (firstNode) {
        firstNode = false;
        nodes.push(
          <FlowLink
            key="flow-start"
            id="flow-start"
            sourcePosition={{ x: left - 200, y: top + lineOffset }}
            targetPosition={{ x: left, y: top + lineOffset }}
          />
        );
      }

      for (const targetNodeId of node.links) {
        const targetNode = nodesConfig[targetNodeId];
        const { left, top } = targetNode.component.getBoundingClientRect();
        const xOffset = !!targetNode.suffix ? 200 : 0;

        const targetPosition = { x: left + xOffset, y: top + lineOffset };
        const linkId = `link-${sourceNodeId}-${targetNodeId}`;
        const includesInterNode
          = linkId.includes('IFELSE_NEW')
          || linkId.match(/SCENARIO \d+_SWTCH_NEW/)
          || linkId.includes('DEFAULT_SWTCH_NEW');

        !includesInterNode && nodes.push(
          <FlowLink
            key={linkId}
            id={linkId}
            sourcePosition={sourcePosition}
            targetPosition={targetPosition}
            turnOffset={xOffset / 2}
          />
        );
      }
    }

    return nodes;
  };

  return (
    <div ref={flowRef} className="pf pf-flow">
      <div>{links}</div>
      <div style={{
        position: 'relative',
        display: 'grid',
        justifyItems: 'end',
        gridRowGap: 80,
        gridColumnGap: 25,
      }}>
        {renderNodes()}
      </div>
    </div>
  );
};

PFFlowRenderer.defaultProps = {
  lineOffset: 40,
};

PFFlowRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  lineOffset: PropTypes.number,
};

export default PFFlowRenderer;
