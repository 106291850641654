import styled from 'styled-components';

export const TooltipWrapper = styled.div``;

export const Tooltip = styled.span.attrs({ role: 'tooltip' })<{
    targetPosition:
        | {
              targetX: number;
              targetY: number;
              targetHeight: number;
              targetWidth: number;
          }
        | undefined;
    tooltipDimensions:
        | {
              tooltipWidth: number;
              tooltipHeight: number;
          }
        | undefined;
    tooltipPosition: string | undefined;
    gap: number | undefined;
    ref: any | undefined;
    configStyles: string | undefined;
    visualVp: VisualViewport | null;
}>`
    min-width: ${(props) => props.tooltipDimensions?.tooltipWidth}px;
    min-height: ${(props) => props.tooltipDimensions?.tooltipHeight}px;
    width: max-content;
    height: max-content;
    position: absolute;
    pointer-events: none;

    ${({ tooltipPosition, targetPosition, gap, tooltipDimensions, visualVp }) => {
        const calcYcenter = (
            targetPosition: {
                targetX: number;
                targetY: number;
                targetHeight: number;
                targetWidth: number;
            },
            tooltipDimensions: {
                tooltipWidth: number;
                tooltipHeight: number;
            },
            visualVp: VisualViewport,
        ) => {
            const targetCenterY = targetPosition.targetY + visualVp.pageTop + targetPosition.targetHeight / 2;
            const tooltipCenterY = tooltipDimensions.tooltipHeight / 2;
            const topPosition = targetCenterY - tooltipCenterY;
            return topPosition;
        };

        const calcXcenter = (
            targetPosition: {
                targetX: number;
                targetY: number;
                targetHeight: number;
                targetWidth: number;
            },
            tooltipDimensions: {
                tooltipWidth: number;
                tooltipHeight: number;
            },
            visualVp: VisualViewport,
        ) => {
            const targetCenterX = targetPosition.targetX + visualVp.pageLeft + targetPosition.targetWidth / 2;
            const tooltipCenterX = tooltipDimensions.tooltipWidth / 2;
            const leftPosition = targetCenterX - tooltipCenterX;
            return leftPosition;
        };

        if (tooltipDimensions && targetPosition && gap && visualVp) {
            switch (tooltipPosition) {
                case 'bottom':
                    return `
                      bottom: unset;
                      left: ${calcXcenter(targetPosition, tooltipDimensions, visualVp)}px;
                      top: ${targetPosition.targetHeight + targetPosition.targetY + gap + visualVp.pageTop}px;
                    `;
                case 'left':
                    return `
                      bottom: unset;
                      top: ${calcYcenter(targetPosition, tooltipDimensions, visualVp)}px;
                      left: ${targetPosition.targetX - tooltipDimensions.tooltipWidth - gap}px;
                    `;
                case 'right':
                    return `
                      bottom: unset;
                      top: ${calcYcenter(targetPosition, tooltipDimensions, visualVp)}px;
                      left: ${targetPosition.targetWidth + targetPosition.targetX + gap}px;
                    `;

                case 'top':
                    return `
                      bottom: unset;
                      left: ${calcXcenter(targetPosition, tooltipDimensions, visualVp)}px;
                      top: ${targetPosition.targetY - tooltipDimensions.tooltipHeight - gap * 2 + visualVp.pageTop}px;
                    `;
            }
        }
    }}

    ${(props) => props.configStyles}
`;
