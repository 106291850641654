import { authPefai } from 'PFApis';
import { images } from 'PFAssets';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFInput, PFLink, PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UpdatePasswordForm = ({ otp, username }) => {
  const [updatePasswordForm, fieldHook] = useForm({
    password: {
      default_value: '',
      validation_type: 'password',
      error_message: 'Please provide a password'
    },
    confirm_password: {
      default_value: '',
      validation_type: 'confirmation',
      params: {
        referenceValue: 'password',
        originValue: 'confirm_password'
      },
      error_message: 'Please confirm your password'
    }
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (!otp) navigate('/forgot-password');
  }, []);
  const [asyncCall, updatePasswordLoading, updatePasswordError,
    errorDismiss] = useAsyncCall();

  const updatePassword = (values) => {
    asyncCall(async () => {
      await authPefai.resetPassword({
        username: username,
        otp: otp,
        Password: values.password
      });
      navigate('/');
    });
  };

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="center" margin='top-xl bottom-xl'>
      <PFForm form={updatePasswordForm} submit={updatePassword} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="center" gap="s">
          <PFLink to='/'>
            <img src={images.pefaiLogo} alt="Pefai" height={50} />
          </PFLink>
          <PFText tag="h2" weight={400} margin="top-xl bottom-l" style={{
            textAlign: 'center'
          }}>
            Update your password
          </PFText>
          <PFInput placeholder="Create your password" name="password"
            type='password' fieldHook={fieldHook}
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)' size='s'>1 uppercase</PFText>
            <PFText color='var(--purple-100)' size='s'>1 lowercase</PFText>
            <PFText color='var(--purple-100)' size='s'>1 symbol</PFText>
            <PFText color='var(--purple-100)' size='s'>1 number</PFText>
            <PFText color='var(--purple-100)' size='s'>
              8 characters at least</PFText>
          </PFContainer>
          <PFInput placeholder="Confirm your password" name="confirm_password"
            fieldHook={fieldHook} type='password'
            width="100%" margin="bottom-m"
            style={{ height: 50 }} />
          <PFButton submit loading={updatePasswordLoading}
            width="100%" height={50}
            style={{ borderRadius: 25 }}>
            Update password
          </PFButton>
        </PFContainer>
      </PFForm>
      <PFAlert message={updatePasswordError}
        open={!!updatePasswordError} onClose={errorDismiss} />
    </PFContainer>
  );
};

UpdatePasswordForm.propTypes = {
  otp: PropTypes.string,
  username: PropTypes.string
};


export default UpdatePasswordForm;
