import React, { ReactNode } from 'react';
import * as Styled from './video.styled';

export type VideoProps = {
    /**
     * To identify the component
     */
    id?: string;
    /**
     * Video address
     */
    src: string;
    /**
     * Supported video formats
     */
    type?: 'video/mp4' | 'video/webm' | 'video/ogg';
    /**
     * The visible text that is displayed in browsers that do not support the <video> element.
     */
    fallback?: ReactNode | ReactNode[];
    /**
     * Sets the width of the video player
     */
    width?: string;
    /**
     *  	Sets the height of the video player
     */
    height?: string;
    /**
     * Specifies that the video will start playing as soon as it is ready
     */
    autoplay?: boolean;
    /**
     * Specifies that video controls should be displayed
     */
    controls?: boolean;
    /**
     * Specifies if and how the author thinks the video should be loaded when the page loads
     */
    preload?: 'auto' | 'metadata' | 'none';
    /**
     * Specifies that the video will start over again, every time it is finished
     */
    loop?: boolean;
    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;
    /**
     * playinline for mobile purposes
     */
    playsinline?: boolean;
    /**
     * muted
     */
    muted?: boolean;
};

export function Video({
    id,
    src,
    type,
    fallback,
    width,
    height,
    autoplay = false,
    controls = true,
    preload = 'auto',
    loop = false,
    children,
    playsinline = true,
    muted = true,
    ...props
}: VideoProps) {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
        if (videoRef.current && playsinline) {
            videoRef.current.setAttribute('playsinline', '');
            videoRef.current.setAttribute('webkit-playsinline', '');
        }
    }, []);

    return (
        <Styled.Video
            ref={videoRef}
            width={width}
            height={height}
            autoPlay={autoplay}
            controls={controls}
            preload={preload}
            loop={loop}
            id={id}
            muted={muted}
            {...props}
        >
            <source src={src} type={type} />
            {fallback}
        </Styled.Video>
    );
}
