import { PFButton, PFContainer, PFIcon, PFInput } from 'PFComponents/common';

const ParamTypeFields = ({ form, property, fieldHook, remove }) => {
  const params = form.values[property] ?? [];

  return params.map((_, i) => {
    const baseFieldName = `${property}[${i}]`;

    const removeField = () => remove(i);

    return (
      <PFContainer key={i} display="flex" gap="s" alignItems="flex-end">
        <PFInput name={`${baseFieldName}.key`} fieldHook={fieldHook}
          label={i === 0 ? 'Key' : ''} />
        <PFInput name={`${baseFieldName}.value`} fieldHook={fieldHook}
          label={i === 0 ? 'Value' : ''} width="100%" />
        <PFButton type="support" onClick={removeField}>
          <PFIcon icon="bx bx-trash" size="l" />
        </PFButton>
      </PFContainer>
    );
  });
};

export default ParamTypeFields;
