import { builderV2 } from 'PFApis';
import {
  PFContainer, PFInput,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CopyRootButton from './CopyRootButton';


const APISelector = ({ reference, setReference, params, setParams }) => {
  const currentApp = useSelector((state) => state.current_app);
  const apiDeclarations = currentApp.build.api_declarations.docs;

  const [api, setApi] = useState(null);
  const [fetch, alerts] = useAsyncCalls(false);

  useEffect(() => {
    if (reference) {
      fetch.asyncCall(async () => {
        const api = await builderV2.getAPI(currentApp._id, reference);
        setApi(api);
      });
    }
  }, [reference]);

  const apiOptions = apiDeclarations.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });

  const renderParams = () => {
    return api.params.map(({ key }) => {
      const value = params[key] ?? '';
      const setValue = (value) => setParams({ ...params, [key]: value });

      return (
        <React.Fragment key={key}>
          <PFText type="secondary" size="s" clip
            style={{ marginTop: 6 }}>
            {key}
          </PFText>
          <PFInput
            value={value} onChange={(e) => setValue(e.target.value)}
            placeholder={key}
            fontSize="s" style={{ height: 30 }} />
        </React.Fragment>
      );
    });
  };

  if ((fetch.loading && reference) || fetch.error) {
    return (
      <PFContainer className="node-custom-api-editor">
        <PFContainer padding="top-m" radius="m"
          display="flex" alignItems="center" justifyContent="center">
          <PFLoader color="var(--secondary)" />
        </PFContainer>
        {alerts()}
      </PFContainer>
    );
  }

  return (
    <>
      <PFSelect
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        options={apiOptions}
        placeholder="Select an API"
        style={{ fontSize: 'var(--text-label)' }}
        optionsStyles={{
          color: 'var(--white)'
        }}
        listStyles={{
          border: '1px solid var(--purple-300)',
          backgroundColor: 'var(--purple-500)'
        }}
      />

      {!!api && api.params?.length > 0
        ? <PFContainer>
          <PFContainer display="grid" gridTemplateColumns="auto 1fr" gap="s">
            {renderParams()}
          </PFContainer>
          <CopyRootButton copyTarget={`{{dynamic:${reference
            ? reference : ''}.api_response.}}`} />
        </PFContainer>
        : null
      }
    </>
  );
};

APISelector.propTypes = {
  reference: PropTypes.string.isRequired,
  setReference: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
};

export default APISelector;
