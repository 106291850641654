import ShortUniqueId from 'short-unique-id';
import COMPONENT_DEFAULT_STYLES from './DefaultStyles';

const uid = new ShortUniqueId({ length: 6 });

export default [
  {
    name: 'Container',
    component: 'Container',
    category: 'Layout',
    description: '',
    icon: 'pfi pfi-component-container',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['CONTAINER_MEDIUM'],
          'background-color': '#F3F3F3'
        },
        'semanticType': 'div'
      },
      'children': [],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Button',
    component: 'Button',
    category: 'Basic',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['BUTTON']
        },
        'disabled': false,
        'type': 'button',
        'onClick': () => { }
      },
      'children': [
        {
          'attributes': {
            'isTextEditable': true,
            'className': '',
            'configStyles': {
              'font-family': 'Arial, Helvetica, sans-serif'
            },
            'value': 'Button'
          },
          'children': null,
          'component': 'Text',
          'id': 'Text-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Text-' + uid(),
            'ref': 'Text-' + uid(),
            'alias': 'Text'
          }
        }
      ],
      'component': 'Button',
      'id': 'Button-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Button-' + uid(),
        'ref': 'Button-' + uid(),
        'alias': 'Button'
      }
    })
  },
  {
    name: 'Link',
    component: 'Link',
    category: 'Basic',
    description: '',
    icon: 'pfi pfi-component-link',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['LINK']
        },
        'target': '_self',
        'href': ''
      },
      'children': [
        {
          'attributes': {
            'isTextEditable': true,
            'className': '',
            'configStyles': {
              'font-family': 'Arial, Helvetica, sans-serif'
            },
            'value': 'Link'
          },
          'children': null,
          'component': 'Text',
          'id': 'Text-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Text-' + uid(),
            'ref': 'Text-' + uid(),
            'alias': 'Text'
          }
        }
      ],
      'component': 'Link',
      'id': 'Link-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Link-' + uid(),
        'ref': 'Link-' + uid(),
        'alias': 'Link'
      }
    })
  },
  {
    name: 'Link Block',
    component: 'Link',
    category: 'Basic',
    description: '',
    icon: 'pfi pfi-component-link-block',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['LINK_BLOCK'],
        },
        'target': '_self',
        'href': ''
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CONTAINER_MEDIUM'],
              'background-color': '#f3f3f3'
            },
            'semanticType': 'div'
          },
          'children': [],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
            },
          },
          'children': [
            {
              'attributes': {
                'isTextEditable': true,
                'className': '',
                'configStyles': {},
                'value': 'Link block text'
              },
              'children': null,
              'component': 'Text',
              'id': 'Text-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Text-' + uid(),
                'ref': 'Text-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Parragraph',
          'id': 'Paragraph-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Parragraph-' + uid(),
            'ref': 'Parragraph-' + uid(),
            'alias': 'Parragraph'
          }
        },
      ],
      'component': 'Link',
      'id': 'Link-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Link-' + uid(),
        'ref': 'Link-' + uid(),
        'alias': 'Link Block'
      }
    })
  },
  {
    name: 'List',
    component: 'List',
    category: 'Basic',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {},
        'type': 'ol',
        'variant': 'vertical'
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {}
          },
          'children': [
            {
              'attributes': {
                'type': 'span',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                },
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'List item 1'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Text',
              'id': 'TextSpan-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'TextSpan-' + uid(),
                'ref': 'TextSpan-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
        {
          'attributes': {
            'className': '',
            'configStyles': {}
          },
          'children': [
            {
              'attributes': {
                'type': 'span',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                },
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'List item 2'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Text',
              'id': 'TextSpan-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'TextSpan-' + uid(),
                'ref': 'TextSpan-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'List',
      'id': 'List-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'List-' + uid(),
        'ref': 'List-' + uid(),
        'alias': 'List'
      }
    })
  },
  {
    name: 'Icon',
    component: 'Icon',
    category: 'Basic',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {},
      },
      'children': [{
        'attributes': {
          'className': 'material-symbols-outlined',
          'configStyles': {
            ...COMPONENT_DEFAULT_STYLES['ICON']
          },
          'type': 'span'
        },
        'children': [
          {
            'attributes': {
              'isTextEditable': true,
              'className': '',
              'configStyles': {},
              'value': 'grade'
            },
            'children': [],
            'component': 'Text',
            'id': 'Text-' + uid(),
            'namespace': '',
            'events': null,
            'settings': null,
            'viewport': null,
            'build': {
              'id': 'Text-' + uid(),
              'ref': 'Text-' + uid(),
              'alias': 'Text'
            }
          }
        ],
        'component': 'Icon',
        'id': 'Icon-' + uid(),
        'namespace': '',
        'settings': null,
        'viewport': null,
        'build': {
          'id': 'Icon-' + uid(),
          'ref': 'Icon-' + uid(),
          'alias': 'Icon'
        }
      }],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'events': null,
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Readmore',
    component: 'Readmore',
    category: 'Basic',
    description: '',
    icon: 'pfi pfi-component-container',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['READMORE']
        },
        'minHeight': '10px',
        'readLessText': 'Show less',
        'readMoreText': 'Show more'
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {}
          },
          'children': [
            {
              'attributes': {
                'type': 'span',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                },
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    // eslint-disable-next-line max-len
                    'value': 'This is the hideable content, drag elements here'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Text',
              'id': 'TextSpan-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'TextSpan-' + uid(),
                'ref': 'TextSpan-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'Readmore',
      'id': 'Readmore-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Readmore-' + uid(),
        'ref': 'Readmore-' + uid(),
        'alias': 'Readmore'
      }
    })
  },
  {
    name: 'Heading',
    component: 'Heading',
    category: 'Typography',
    description: '',
    icon: 'pfi pfi-component-heading',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['HEADINGS']
        },
        'level': 1
      },
      'children': [
        {
          'attributes': {
            'isTextEditable': true,
            'value': 'Heading',
            'className': '',
            'configStyles': {},
          },
          'children': null,
          'component': 'Text',
          'id': 'Text-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Text-' + uid(),
            'ref': 'Text-' + uid(),
            'alias': 'Text'
          }
        }
      ],
      'component': 'Heading',
      'id': 'Heading-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Heading-' + uid(),
        'ref': 'Heading-' + uid(),
        'alias': 'Heading'
      }
    })
  },
  {
    name: 'Paragraph',
    component: 'Parragraph',
    category: 'Typography',
    description: '',
    icon: 'pfi pfi-component-paragraph',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
        },
      },
      'children': [
        {
          'attributes': {
            'isTextEditable': true,
            'className': '',
            'configStyles': {},
            'value': 'Text'
          },
          'children': null,
          'component': 'Text',
          'id': 'Text-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Text-' + uid(),
            'ref': 'Text-' + uid(),
            'alias': 'Text'
          }
        }
      ],
      'component': 'Parragraph',
      'id': 'Paragraph-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Parragraph-' + uid(),
        'ref': 'Parragraph-' + uid(),
        'alias': 'Parragraph'
      }
    })
  },
  {
    name: 'Text',
    component: 'Text',
    category: 'Typography',
    description: '',
    icon: 'pfi pfi-component-text',
    create: () => (
      {
        'attributes': {
          'type': 'span',
          'configStyles': {
            ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
          },
        },
        'children': [
          {
            'attributes': {
              'isTextEditable': true,
              'className': '',
              'configStyles': {},
              'value': 'Text'
            },
            'children': null,
            'component': 'Text',
            'id': 'Text-' + uid(),
            'namespace': '',
            'events': null,
            'settings': null,
            'viewport': null,
            'build': {
              'id': 'Text-' + uid(),
              'ref': 'Text-' + uid(),
              'alias': 'Text'
            }
          }
        ],
        'component': 'Text',
        'id': 'TextSpan-' + uid(),
        'namespace': '',
        'events': null,
        'settings': null,
        'viewport': null,
        'build': {
          'id': 'TextSpan-' + uid(),
          'ref': 'TextSpan-' + uid(),
          'alias': 'Text'
        }
      }
    )
  },
  {
    name: 'Accordion',
    component: 'Accordioncontainer',
    category: 'Accordion',
    description: '',
    icon: 'pfi pfi-component-container',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          'width': '100%',
          'box-sizing': 'border-box',
          'padding-bottom': '8px',
          'padding-left': '8px',
          'padding-right': '8px',
          'padding-top': '8px',
        }
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              'box-sizing': 'border-box',
              'min-height': '100px',
              'padding-bottom': '8px',
              'padding-left': '8px',
              'padding-right': '8px',
              'padding-top': '8px',
              'width': '100%'
            },
            'tabId': 'accordion-item-1',
            'handleIsOpen': true
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  'width': '100%',
                  'min-height': '40px',
                  'box-sizing': 'border-box'
                },
              },
              'children': [
                {
                  'attributes': {
                    'type': 'span',
                    'configStyles': {
                      ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                    },
                  },
                  'children': [
                    {
                      'attributes': {
                        'isTextEditable': true,
                        'className': '',
                        'configStyles': {},
                        'value': 'Accordion header'
                      },
                      'children': null,
                      'component': 'Text',
                      'id': 'Text-' + uid(),
                      'namespace': '',
                      'events': null,
                      'settings': null,
                      'viewport': null,
                      'build': {
                        'id': 'Text-' + uid(),
                        'ref': 'Text-' + uid(),
                        'alias': 'Text'
                      }
                    }
                  ],
                  'component': 'Text',
                  'id': 'TextSpan-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'TextSpan-' + uid(),
                    'ref': 'TextSpan-' + uid(),
                    'alias': 'Text'
                  }
                },
                {
                  'attributes': {
                    'className': '',
                    'configStyles': {
                      'display': 'flex',
                      'align-items': 'center',
                      'justify-content': 'center',
                      'padding-top': '8px',
                      'padding-right': '8px',
                      'padding-bottom': '8px',
                      'padding-left': '8px',
                    },
                    'isAccordionIcon': true
                  },
                  'children': [
                    {
                      'attributes': {
                        'className': 'material-symbols-outlined',
                        'configStyles': {
                          ...COMPONENT_DEFAULT_STYLES['ICON']
                        },
                        'type': 'span'
                      },
                      'children': [
                        {
                          'attributes': {
                            'isTextEditable': true,
                            'className': '',
                            'configStyles': {},
                            'value': 'expand_more'
                          },
                          'children': [],
                          'component': 'Text',
                          'id': 'Text-' + uid(),
                          'namespace': '',
                          'events': null,
                          'settings': null,
                          'viewport': null,
                          'build': {
                            'id': 'Text-' + uid(),
                            'ref': 'Text-' + uid(),
                            'alias': 'Text'
                          }
                        }
                      ],
                      'component': 'Icon',
                      'id': 'Icon-' + uid(),
                      'namespace': '',
                      'settings': null,
                      'viewport': null,
                      'build': {
                        'id': 'Icon-' + uid(),
                        'ref': 'Icon-' + uid(),
                        'alias': 'Icon'
                      }
                    }
                  ],
                  'component': 'Accordionicon',
                  'id': 'Accordionicon-' + uid(),
                  'namespace': '',
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Accordionicon-' + uid(),
                    'ref': 'Accordionicon-' + uid(),
                    'alias': 'Accordionicon'
                  }
                }
              ],
              'component': 'Accordionheader',
              'id': 'Accordionheader-' + uid(),
              'namespace': '',
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Accordionheader-' + uid(),
                'ref': 'Accordionheader-' + uid(),
                'alias': 'Accordionheader'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  'width': '100%',
                  'min-height': '60px',
                  'box-sizing': 'border-box'
                },
              },
              'children': [
                {
                  'attributes': {
                    'type': 'span',
                    'configStyles': {
                      ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                    },
                  },
                  'children': [
                    {
                      'attributes': {
                        'isTextEditable': true,
                        'className': '',
                        'configStyles': {},
                        'value': 'Accordion content'
                      },
                      'children': null,
                      'component': 'Text',
                      'id': 'Text-' + uid(),
                      'namespace': '',
                      'events': null,
                      'settings': null,
                      'viewport': null,
                      'build': {
                        'id': 'Text-' + uid(),
                        'ref': 'Text-' + uid(),
                        'alias': 'Text'
                      }
                    }
                  ],
                  'component': 'Text',
                  'id': 'TextSpan-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'TextSpan-' + uid(),
                    'ref': 'TextSpan-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Accordioncontent',
              'id': 'Accordioncontent-' + uid(),
              'namespace': '',
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Accordioncontent-' + uid(),
                'ref': 'Accordioncontent-' + uid(),
                'alias': 'Accordioncontent'
              }
            }
          ],
          'component': 'Accordionitem',
          'id': 'Accordionitem-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Accordionitem-' + uid(),
            'ref': 'Accordionitem-' + uid(),
            'alias': 'Accordionitem'
          }
        }
      ],
      'component': 'Accordioncontainer',
      'id': 'Accordioncontainer-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Accordioncontainer-' + uid(),
        'ref': 'Accordioncontainer-' + uid(),
        'alias': 'Accordioncontainer'
      }
    })
  },
  {
    name: 'Accordion Item',
    component: 'Accordionitem',
    category: 'Accordion',
    description: '',
    icon: 'pfi pfi-component-container',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          'box-sizing': 'border-box',
          'min-height': '100px',
          'padding-bottom': '8px',
          'padding-left': '8px',
          'padding-right': '8px',
          'padding-top': '8px',
          'width': '100%'
        },
        'tabId': 'accordion-item-2',
        'handleIsOpen': true
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              'width': '100%',
              'min-height': '40px',
              'box-sizing': 'border-box'
            },
          },
          'children': [
            {
              'attributes': {
                'type': 'span',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                },
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Accordion header'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Text',
              'id': 'TextSpan-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'TextSpan-' + uid(),
                'ref': 'TextSpan-' + uid(),
                'alias': 'Text'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  'display': 'flex',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'padding-top': '8px',
                  'padding-right': '8px',
                  'padding-bottom': '8px',
                  'padding-left': '8px',
                },
                'isAccordionIcon': true
              },
              'children': [
                {
                  'attributes': {
                    'className': 'material-symbols-outlined',
                    'configStyles': {
                      ...COMPONENT_DEFAULT_STYLES['ICON']
                    },
                    'type': 'span'
                  },
                  'children': [
                    {
                      'attributes': {
                        'isTextEditable': true,
                        'className': '',
                        'configStyles': {},
                        'value': 'expand_more'
                      },
                      'children': [],
                      'component': 'Text',
                      'id': 'Text-' + uid(),
                      'namespace': '',
                      'events': null,
                      'settings': null,
                      'viewport': null,
                      'build': {
                        'id': 'Text-' + uid(),
                        'ref': 'Text-' + uid(),
                        'alias': 'Text'
                      }
                    }
                  ],
                  'component': 'Icon',
                  'id': 'Icon-' + uid(),
                  'namespace': '',
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Icon-' + uid(),
                    'ref': 'Icon-' + uid(),
                    'alias': 'Icon'
                  }
                }
              ],
              'component': 'Accordionicon',
              'id': 'Accordionicon-' + uid(),
              'namespace': '',
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Accordionicon-' + uid(),
                'ref': 'Accordionicon-' + uid(),
                'alias': 'Accordionicon'
              }
            }
          ],
          'component': 'Accordionheader',
          'id': 'Accordionheader-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Accordionheader-' + uid(),
            'ref': 'Accordionheader-' + uid(),
            'alias': 'Accordionheader'
          }
        },
        {
          'attributes': {
            'className': '',
            'configStyles': {
              'width': '100%',
              'min-height': '60px',
              'box-sizing': 'border-box'
            },
          },
          'children': [
            {
              'attributes': {
                'type': 'span',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
                },
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Accordion content'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Text',
              'id': 'TextSpan-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'TextSpan-' + uid(),
                'ref': 'TextSpan-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Accordioncontent',
          'id': 'Accordioncontent-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Accordioncontent-' + uid(),
            'ref': 'Accordioncontent-' + uid(),
            'alias': 'Accordioncontent'
          }
        }
      ],
      'component': 'Accordionitem',
      'id': 'Accordionitem-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Accordionitem-' + uid(),
        'ref': 'Accordionitem-' + uid(),
        'alias': 'Accordionitem'
      }
    })
  },
  {
    name: 'Image',
    component: 'Image',
    category: 'Media',
    description: '',
    icon: 'pfi pfi-component-image',
    create: () => ({
      'attributes': {
        'className': '',
        'loading': 'auto',
        'configStyles': {},
        'src': 'https://assets-dev.pefai.com/pef-bld/pefai-placeholder.png',
        'alt': 'placeholder image',
        'width': '140',
        'height': '140'
      },
      'children': [],
      'component': 'Image',
      'id': 'Image-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Image-' + uid(),
        'ref': 'Image-' + uid(),
        'alias': 'Image'
      }
    })
  },
  {
    name: 'Video',
    component: 'Video',
    category: 'Media',
    description: '',
    icon: 'pfi pfi-component-video',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {},
        'src': '',
        'autoplay': false,
        'controls': true,
        'preload': 'auto',
        'loop': false
      },
      'children': [],
      'component': 'Video',
      'id': 'Video-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Video-' + uid(),
        'ref': 'Video-' + uid(),
        'alias': 'Video'
      }
    })
  },
  {
    name: 'Form',
    component: 'Form',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-form',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_WRAPPER']
        }
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
            },
          },
          'children': [
            {
              'attributes': {
                'isTextEditable': true,
                'className': '',
                'configStyles': {},
                'value': 'Form Title'
              },
              'children': null,
              'component': 'Text',
              'id': 'Text-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Text-' + uid(),
                'ref': 'Text-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Parragraph',
          'id': 'Paragraph-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Parragraph-' + uid(),
            'ref': 'Parragraph-' + uid(),
            'alias': 'Parragraph'
          }
        },
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['FORM']
            },
            'form_id': 'Form-' + uid(),
            'submit': {
              'onValid': false
            },
            'trigger_id': '',
            'validations': {},
            'values': {}
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
                  ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
                },
                'name': 'input',
                'type': 'text',
                'placeholder': 'Input',
                'autocomplete': false,
                'readOnly': false
              },
              'children': [],
              'component': 'Input',
              'id': 'Input-' + uid(),
              'namespace': '',
              'settings': {
                'form': {
                  'name': 'input',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Input-' + uid(),
                'ref': 'Input-' + uid(),
                'alias': 'Input'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['BUTTON']
                },
                'disabled': false,
                'type': 'submit',
                'onClick': () => { }
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Submit'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Button',
              'id': 'Button-' + uid(),
              'namespace': '',
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Button-' + uid(),
                'ref': 'Button-' + uid(),
                'alias': 'Button'
              }
            }
          ],
          'component': 'Form',
          'id': 'Form-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Form-' + uid(),
            'ref': 'Form-' + uid(),
            'alias': 'Form'
          }
        },
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Multistep',
    component: 'Multistep',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-container',
    create: () => {
      const initial_step_id = 'Step-' + uid();
      return {
        'attributes': {
          'className': '',
          'configStyles': {
            ...COMPONENT_DEFAULT_STYLES['MULTI_STEP']
          },
          'semanticType': 'div'
        },
        'children': [
          {
            'attributes': {},
            'children': [],
            'component': 'Step',
            'id': initial_step_id,
            'namespace': '',
            'events': null,
            'settings': null,
            'viewport': null,
            'build': {
              'id': 'Step-' + uid(),
              'ref': 'Step-' + uid(),
              'alias': 'Step 1'
            }
          }
        ],
        'component': 'Multistep',
        'id': 'Multistep-' + uid(),
        'namespace': '',
        'settings': {
          'default_step': initial_step_id,
        },
        'viewport': null,
        'build': {
          'id': 'Multistep-' + uid(),
          'ref': 'Multistep-' + uid(),
          'alias': 'Multistep'
        }
      };
    }
  },
  {
    name: 'Label',
    component: 'Label',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-label',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['LABEL']
        },
        'htmlFor': ''
      },
      'children': [
        {
          'attributes': {
            'isTextEditable': true,
            'className': '',
            'configStyles': {},
            'value': 'Label'
          },
          'children': null,
          'component': 'Text',
          'id': 'Text-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Text-' + uid(),
            'ref': 'Text-' + uid(),
            'alias': 'Text'
          }
        }
      ],
      'component': 'Label',
      'id': 'Label-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Label-' + uid(),
        'ref': 'Label-' + uid(),
        'alias': 'Label'
      }
    })
  },
  {
    name: 'Form Meta',
    component: 'Formfieldmetadata',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-form-meta',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          'display': 'block',
          'width': '100%',
          'height': 'auto',
          'min-height': '20px'
        },
        'display': '',
        'validationType': ''
      },
      'children': [
        {
          'attributes': {
            'type': 'span',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['PARAGRAPH']
            },
          },
          'children': [
            {
              'attributes': {
                'isTextEditable': true,
                'className': '',
                'configStyles': {},
                'value': 'Form field meta data'
              },
              'children': null,
              'component': 'Text',
              'id': 'Text-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Text-' + uid(),
                'ref': 'Text-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Text',
          'id': 'TextSpan-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'TextSpan-' + uid(),
            'ref': 'TextSpan-' + uid(),
            'alias': 'Text'
          }
        }
      ],
      'component': 'Formfieldmetadata',
      'id': 'Formfieldmetadata-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'type': 'meta',
          'name': ''
        }
      },
      'viewport': null,
      'build': {
        'id': 'Formfieldmetadata-' + uid(),
        'ref': 'Formfieldmetadata-' + uid(),
        'alias': 'Formfieldmetadata'
      }
    })
  },
  {
    name: 'Meta Wrapper',
    component: 'Metawrapper',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-form-meta',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          'display': 'flex',
          'padding-top': '4px',
          'padding-right': '8px',
          'padding-bottom': '4px',
          'padding-left': '8px',
          'box-sizing': 'border-box',
          'width': '100%',
          'height': 'auto',
          'min-height': '20px'
        }
      },
      'children': [],
      'component': 'Metawrapper',
      'id': 'Metawrapper-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'type': 'meta',
          'name': ''
        }
      },
      'viewport': null,
      'build': {
        'id': 'Metawrapper-' + uid(),
        'ref': 'Metawrapper-' + uid(),
        'alias': 'Metawrapper'
      }
    })
  },
  {
    name: 'Checkbox',
    component: 'Simplecheckbox',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['HORIZONTAL_GROUP']
        }
      },
      'children': [
        {
          'attributes': {
            'type': 'span',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_FIELD']
            },
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {},
                'disabled': false,
                'name': '',
              },
              'children': [],
              'component': 'Simplecheckbox',
              'id': 'Simplecheckbox-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simplecheckbox-' + uid(),
                'ref': 'Simplecheckbox-' + uid(),
                'alias': 'Simplecheckbox'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL']
                },
                'htmlFor': ''
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Checkbox 1'
                  },
                  'children': [],
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Label',
              'id': 'Label-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
        {
          'attributes': {
            'type': 'span',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_FIELD']
            },
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {},
                'disabled': false,
                'name': '',
              },
              'children': [],
              'component': 'Simplecheckbox',
              'id': 'Simplecheckbox-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simplecheckbox-' + uid(),
                'ref': 'Simplecheckbox-' + uid(),
                'alias': 'Simplecheckbox'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL']
                },
                'htmlFor': ''
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Checkbox 2'
                  },
                  'children': [],
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Label',
              'id': 'Label-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Radio',
    component: 'Simpleradio',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['HORIZONTAL_GROUP']
        }
      },
      'children': [
        {
          'attributes': {
            'type': 'span',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_FIELD']
            },
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {},
                'disabled': false,
                'fieldValue': '',
                'name': '',
                'value': ''
              },
              'children': [],
              'component': 'Simpleradio',
              'id': 'Simpleradio-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simpleradio-' + uid(),
                'ref': 'Simpleradio-' + uid(),
                'alias': 'Simpleradio'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL']
                },
                'htmlFor': ''
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Radio 1'
                  },
                  'children': [],
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Label',
              'id': 'Label-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
        {
          'attributes': {
            'type': 'span',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_FIELD']
            },
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {},
                'disabled': false,
                'fieldValue': '',
                'name': '',
                'value': ''
              },
              'children': [],
              'component': 'Simpleradio',
              'id': 'Simpleradio-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simpleradio-' + uid(),
                'ref': 'Simpleradio-' + uid(),
                'alias': 'Simpleradio'
              }
            },
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL']
                },
                'htmlFor': ''
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Radio 2'
                  },
                  'children': [],
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Label',
              'id': 'Label-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Check Pill',
    component: 'Simplecheckbox',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['HORIZONTAL_GROUP']
        }
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECKED_CHECK_PILL']
            }
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_PILL']
                },
                'disabled': false,
                'name': '',
              },
              'children': [],
              'component': 'Simplecheckbox',
              'id': 'Simplecheckbox-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simplecheckbox-' + uid(),
                'ref': 'Simplecheckbox-' + uid(),
                'alias': 'Simplecheckbox'
              }
            },
            {
              'id': 'Label-' + uid(),
              'component': 'Label',
              'settings': null,
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL'],
                  ...COMPONENT_DEFAULT_STYLES['PILL']
                },
                'htmlFor': ''
              },
              'events': null,
              'children': [
                {
                  'id': 'Text-' + uid(),
                  'component': 'Text',
                  'settings': null,
                  'attributes': {
                    'className': '',
                    'configStyles': {},
                    'isTextEditable': true,
                    'value': 'Check Pill 1'
                  },
                  'events': null,
                  'children': [],
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECKED_CHECK_PILL']
            }
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_PILL']
                },
                'disabled': false,
                'name': '',
              },
              'children': [],
              'component': 'Simplecheckbox',
              'id': 'Simplecheckbox-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simplecheckbox-' + uid(),
                'ref': 'Simplecheckbox-' + uid(),
                'alias': 'Simplecheckbox'
              }
            },
            {
              'id': 'Label-' + uid(),
              'component': 'Label',
              'settings': null,
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL'],
                  ...COMPONENT_DEFAULT_STYLES['PILL']
                },
                'htmlFor': ''
              },
              'events': null,
              'children': [
                {
                  'id': 'Text-' + uid(),
                  'component': 'Text',
                  'settings': null,
                  'attributes': {
                    'className': '',
                    'configStyles': {},
                    'isTextEditable': true,
                    'value': 'Check Pill 2'
                  },
                  'events': null,
                  'children': [],
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Radio Pill',
    component: 'Simpleradio',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-button',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['HORIZONTAL_GROUP']
        }
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECKED_RADIO_PILL']
            }
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_PILL']
                },
                'disabled': false,
                'name': '',
                'fieldValue': ''
              },
              'children': [],
              'component': 'Simpleradio',
              'id': 'Simpleradio-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simpleradio-' + uid(),
                'ref': 'Simpleradio-' + uid(),
                'alias': 'Simpleradio'
              }
            },
            {
              'id': 'Label-' + uid(),
              'component': 'Label',
              'settings': null,
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL'],
                  ...COMPONENT_DEFAULT_STYLES['PILL']
                },
                'htmlFor': ''
              },
              'events': null,
              'children': [
                {
                  'id': 'Text-' + uid(),
                  'component': 'Text',
                  'settings': null,
                  'attributes': {
                    'className': '',
                    'configStyles': {},
                    'isTextEditable': true,
                    'value': 'Radio Pill 1'
                  },
                  'events': null,
                  'children': [],
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECKED_RADIO_PILL']
            }
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['CHECK_RADIO_PILL']
                },
                'disabled': false,
                'name': '',
                'fieldValue': ''
              },
              'children': [],
              'component': 'Simpleradio',
              'id': 'Simpleradio-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simpleradio-' + uid(),
                'ref': 'Simpleradio-' + uid(),
                'alias': 'Simpleradio'
              }
            },
            {
              'id': 'Label-' + uid(),
              'component': 'Label',
              'settings': null,
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['LABEL'],
                  ...COMPONENT_DEFAULT_STYLES['PILL']
                },
                'htmlFor': ''
              },
              'events': null,
              'children': [
                {
                  'id': 'Text-' + uid(),
                  'component': 'Text',
                  'settings': null,
                  'attributes': {
                    'className': '',
                    'configStyles': {},
                    'isTextEditable': true,
                    'value': 'Radio Pill 2'
                  },
                  'events': null,
                  'children': [],
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Toggle',
    component: 'Checkbox',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-container',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['HORIZONTAL_GROUP']
        }
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['CHECKED_TOGGLE']
            }
          },
          'children': [
            {
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['CHECK_TOGGLE']
                },
                'disabled': false,
                'name': '',
              },
              'children': [],
              'component': 'Simplecheckbox',
              'id': 'Simplecheckbox-' + uid(),
              'namespace': '',
              'events': null,
              'settings': {
                'form': {
                  'name': '',
                  'type': 'field'
                }
              },
              'viewport': null,
              'build': {
                'id': 'Simplecheckbox-' + uid(),
                'ref': 'Simplecheckbox-' + uid(),
                'alias': 'Simplecheckbox'
              }
            },
            {
              'id': 'Label-' + uid(),
              'component': 'Label',
              'settings': null,
              'attributes': {
                'className': '',
                'configStyles': {
                  ...COMPONENT_DEFAULT_STYLES['TOGGLE']
                },
                'htmlFor': ''
              },
              'events': null,
              'children': [
                {
                  'id': 'Text-' + uid(),
                  'component': 'Text',
                  'settings': null,
                  'attributes': {
                    'className': '',
                    'configStyles': {
                      'position': 'absolute',
                      'top': 'calc(50% - 10px)',
                      'transform': 'translateY(-50%)',
                      'left': '72px',
                      'color': '#333',
                      'width': 'max-content'
                    },
                    'isTextEditable': true,
                    'type': 'span'
                  },
                  'events': null,
                  'children': [
                    {
                      'id': 'Text-' + uid(),
                      'component': 'Text',
                      'settings': null,
                      'attributes': {
                        'className': '',
                        'configStyles': {},
                        'isTextEditable': true,
                        'value': 'Toggle label'
                      },
                      'events': null,
                      'children': [],
                      'viewport': null,
                      'build': {
                        'id': 'Text-' + uid(),
                        'ref': 'Text-' + uid(),
                        'alias': 'Text'
                      }
                    }
                  ],
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'viewport': null,
              'build': {
                'id': 'Label-' + uid(),
                'ref': 'Label-' + uid(),
                'alias': 'Label'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        }
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Input',
    component: 'Input',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-input',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDER'],
        },
        'name': '',
        'type': 'text',
        'placeholder': 'Input',
        'autocomplete': false,
        'readOnly': false
      },
      'children': [],
      'component': 'Input',
      'id': 'Input-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': '',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Input-' + uid(),
        'ref': 'Input-' + uid(),
        'alias': 'Input'
      }
    })
  },
  {
    name: 'Password',
    component: 'Password',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-password',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          'display': 'flex',
          'alignItems': 'center',
          'box-sizing': 'border-box',
          'padding-top': '8px',
          'padding-right': '8px',
          'padding-bottom': '8px',
          'padding-left': '8px',
          'width': '100%',
          'height': 'auto',
          'backgroundColor': '#FFFFFF'
        },
        'semanticType': 'div'
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
              ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
            },
            'name': '',
            'placeholder': 'Password',
            'autocomplete': false,
            'readOnly': false,
            'value': '1234'
          },
          'children': [],
          'component': 'Password',
          'id': 'Password-' + uid(),
          'namespace': '',
          'settings': {
            'form': {
              'name': '',
              'type': 'field'
            }
          },
          'viewport': null,
          'build': {
            'id': 'Password-' + uid(),
            'ref': 'Password-' + uid(),
            'alias': 'Password'
          }
        }
      ],
      'component': 'Container',
      'id': 'Container-' + uid(),
      'namespace': '',
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'Container-' + uid(),
        'ref': 'Container-' + uid(),
        'alias': 'Container'
      }
    })
  },
  {
    name: 'Telephone',
    component: 'Telephone',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-telephone',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
        },
        'name': '',
        'format': '##-####-####',
        'placeholder': 'Telephone',
        'autocomplete': false,
        'readOnly': false
      },
      'children': [],
      'component': 'Telephone',
      'id': 'Telephone-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': '',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Telephone-' + uid(),
        'ref': 'Telephone-' + uid(),
        'alias': 'Telephone'
      }
    })
  },
  {
    name: 'Currency',
    component: 'Currency',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-currency',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
        },
        'name': '',
        'locale': 'en-US',
        'currency': 'USD',
        'placeholder': 'Currency',
        'autocomplete': false,
        'readOnly': false
      },
      'children': [],
      'component': 'Currency',
      'id': 'Currency-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': '',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Currency-' + uid(),
        'ref': 'Currency-' + uid(),
        'alias': 'Currency'
      }
    })
  },
  {
    name: 'Email',
    component: 'Email',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-email',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
        },
        'name': '',
        'placeholder': 'Email',
        'autocomplete': false,
        'readOnly': false
      },
      'children': [],
      'component': 'Email',
      'id': 'Email-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': '',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Email-' + uid(),
        'ref': 'Email-' + uid(),
        'alias': 'Email'
      }
    })
  },
  {
    name: 'Url',
    component: 'Url',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-url',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
        },
        'name': '',
        'placeholder': 'Url',
        'autocomplete': false,
        'readOnly': false
      },
      'children': [],
      'component': 'Url',
      'id': 'Url-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': '',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Url-' + uid(),
        'ref': 'Url-' + uid(),
        'alias': 'Url'
      }
    })
  },
  {
    name: 'Number',
    component: 'Number',
    category: 'Form',
    description: '',
    icon: 'pfi pfi-component-number',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
          ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
        },
        'name': '',
        'placeholder': 'Number',
        'autocomplete': false,
        'readOnly': false
      },
      'children': [],
      'component': 'Number',
      'id': 'Number-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': '',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Number-' + uid(),
        'ref': 'Number-' + uid(),
        'alias': 'Number'
      }
    })
  },

  {
    name: 'Autocomplete',
    component: 'Autocomplete',
    category: 'Form Dropdowns',
    description: '',
    icon: 'pfi pfi-component-number',
    create: () => ({
      'attributes': {
        'className': '',
        'configStyles': {
          'width': '100%',
          'height': 'auto'
        },
      },
      'children': [
        {
          'attributes': {
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
              ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
            },
            'placeholderText': 'Type search term'
          },
          'children': null,
          'component': 'AutocompleteSearchbar',
          'id': 'AutocompleteSearchbar-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'AutocompleteSearchbar-' + uid(),
            'ref': 'AutocompleteSearchbar-' + uid(),
            'alias': 'Autocomplete Searchbar'
          }
        },
        {
          'attributes': {
            'configStyles': {
              'width': '100%',
              'min-height': '50px',
              'padding-top': '4px',
              'padding-right': '8px',
              'padding-bottom': '4px',
              'padding-left': '8px',
              'background-color': '#ffffff',
              'box-sizing': 'border-box',
            },
            'showDropdown': true
          },
          'children': [
            {
              'attributes': {
                'configStyles': {
                  'width': '100%',
                  'min-width': '24px',
                  'padding-left': '4px',
                  'padding-right': '4px',
                  'padding-bottom': '4px'
                },
                'value': ''
              },
              'children': [
                {
                  'attributes': {
                    'className': '',
                    'configStyles': {
                      'height': '32px',
                      'width': '100%'
                    },
                    'semanticType': 'div'
                  },
                  'children': [
                    {
                      'attributes': {
                        'type': 'span',
                        'configStyles': {},
                      },
                      'children': [
                        {
                          'attributes': {
                            'isTextEditable': true,
                            'className': '',
                            'configStyles': {},
                            'value': 'Option'
                          },
                          'children': null,
                          'component': 'Text',
                          'id': 'Text-' + uid(),
                          'namespace': '',
                          'events': null,
                          'settings': null,
                          'viewport': null,
                          'build': {
                            'id': 'Text-' + uid(),
                            'ref': 'Text-' + uid(),
                            'alias': 'Text'
                          }
                        }
                      ],
                      'component': 'Text',
                      'id': 'Text-' + uid(),
                      'namespace': '',
                      'events': null,
                      'settings': null,
                      'viewport': null,
                      'build': {
                        'id': 'Text-' + uid(),
                        'ref': 'Text-' + uid(),
                        'alias': 'Text'
                      }
                    }
                  ],
                  'component': 'Container',
                  'id': 'Container-' + uid(),
                  'namespace': '',
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Container-' + uid(),
                    'ref': 'Container-' + uid(),
                    'alias': 'Container'
                  }
                },
              ],
              'component': 'AutocompleteResultsOption',
              'id': 'AutocompleteResultsOption-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'AutocompleteResultsOption-' + uid(),
                'ref': 'AutocompleteResultsOption-' + uid(),
                'alias': 'Option'
              }
            },
          ],
          'component': 'AutocompleteResults',
          'id': 'AutocompleteResults-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'AutocompleteResults-' + uid(),
            'ref': 'AutocompleteResults-' + uid(),
            'alias': 'Autocomplete Results'
          }
        },
      ],
      'component': 'Autocomplete',
      'id': 'Autocomplete-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': 'autocomplete',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Autocomplete-' + uid(),
        'ref': 'Autocomplete-' + uid(),
        'alias': 'Autocomplete'
      }
    })
  },
  {
    name: 'Select',
    component: 'Autocomplete',
    category: 'Form Dropdowns',
    description: '',
    icon: 'pfi pfi-component-number',
    create: () => ({
      'attributes': {
        'enableAutocomplete': false,
        'configStyles': {
          'width': '100%',
          'height': 'auto'
        },
      },
      'children': [
        {
          'attributes': {
            'configStyles': {
              ...COMPONENT_DEFAULT_STYLES['FORM_FIELD'],
              ...COMPONENT_DEFAULT_STYLES['FORM_FIELD_BORDERS']
            },
            'placeholderText': 'Select an option'
          },
          'children': null,
          'component': 'AutocompleteSearchbar',
          'id': 'AutocompleteSearchbar-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'AutocompleteSearchbar-' + uid(),
            'ref': 'AutocompleteSearchbar-' + uid(),
            'alias': 'Autocomplete Searchbar'
          }
        },
        {
          'attributes': {
            'configStyles': {
              'width': '100%',
              'min-height': '50px',
              'padding-top': '4px',
              'padding-right': '8px',
              'padding-bottom': '4px',
              'padding-left': '8px',
              'background-color': '#ffffff',
              'box-sizing': 'border-box',
            },
            'showDropdown': true
          },
          'children': [
            {
              'attributes': {
                'configStyles': {
                  'width': '100%',
                  'min-width': '24px',
                  'padding-left': '4px',
                  'padding-right': '4px',
                  'padding-bottom': '4px'
                },
                'value': 'option 1'
              },
              'children': [
                {
                  'attributes': {
                    'className': '',
                    'configStyles': {
                      'height': '32px',
                      'width': '100%'
                    },
                    'semanticType': 'div'
                  },
                  'children': [
                    {
                      'attributes': {
                        'type': 'span',
                        'configStyles': {},
                      },
                      'children': [
                        {
                          'attributes': {
                            'isTextEditable': true,
                            'className': '',
                            'configStyles': {},
                            'value': 'Option 1'
                          },
                          'children': null,
                          'component': 'Text',
                          'id': 'Text-' + uid(),
                          'namespace': '',
                          'events': null,
                          'settings': null,
                          'viewport': null,
                          'build': {
                            'id': 'Text-' + uid(),
                            'ref': 'Text-' + uid(),
                            'alias': 'Text'
                          }
                        }
                      ],
                      'component': 'Text',
                      'id': 'Text-' + uid(),
                      'namespace': '',
                      'events': null,
                      'settings': null,
                      'viewport': null,
                      'build': {
                        'id': 'Text-' + uid(),
                        'ref': 'Text-' + uid(),
                        'alias': 'Text'
                      }
                    }
                  ],
                  'component': 'Container',
                  'id': 'Container-' + uid(),
                  'namespace': '',
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Container-' + uid(),
                    'ref': 'Container-' + uid(),
                    'alias': 'Container'
                  }
                },
              ],
              'component': 'AutocompleteResultsOption',
              'id': 'AutocompleteResultsOption-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'AutocompleteResultsOption-' + uid(),
                'ref': 'AutocompleteResultsOption-' + uid(),
                'alias': 'Option'
              }
            },
          ],
          'component': 'AutocompleteResults',
          'id': 'AutocompleteResults-' + uid(),
          'namespace': '',
          'events': null,
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'AutocompleteResults-' + uid(),
            'ref': 'AutocompleteResults-' + uid(),
            'alias': 'Autocomplete Results'
          }
        },
      ],
      'component': 'Autocomplete',
      'id': 'Autocomplete-' + uid(),
      'namespace': '',
      'settings': {
        'form': {
          'name': 'autocomplete',
          'type': 'field'
        }
      },
      'viewport': null,
      'build': {
        'id': 'Autocomplete-' + uid(),
        'ref': 'Autocomplete-' + uid(),
        'alias': 'Autocomplete'
      }
    })
  },
  {
    name: 'Option',
    component: 'AutocompleteOption',
    category: 'Form Dropdowns',
    description: '',
    icon: 'pfi pfi-component-number',
    create: () => ({
      'attributes': {
        'configStyles': {
          'width': '100%',
          'min-width': '24px',
          'padding-left': '4px',
          'padding-right': '4px',
        },
        'value': ''
      },
      'children': [
        {
          'attributes': {
            'className': '',
            'configStyles': {
              'height': '32px',
              'width': '100%'
            },
            'semanticType': 'div'
          },
          'children': [
            {
              'attributes': {
                'type': 'span',
                'configStyles': {},
              },
              'children': [
                {
                  'attributes': {
                    'isTextEditable': true,
                    'className': '',
                    'configStyles': {},
                    'value': 'Option'
                  },
                  'children': null,
                  'component': 'Text',
                  'id': 'Text-' + uid(),
                  'namespace': '',
                  'events': null,
                  'settings': null,
                  'viewport': null,
                  'build': {
                    'id': 'Text-' + uid(),
                    'ref': 'Text-' + uid(),
                    'alias': 'Text'
                  }
                }
              ],
              'component': 'Text',
              'id': 'Text-' + uid(),
              'namespace': '',
              'events': null,
              'settings': null,
              'viewport': null,
              'build': {
                'id': 'Text-' + uid(),
                'ref': 'Text-' + uid(),
                'alias': 'Text'
              }
            }
          ],
          'component': 'Container',
          'id': 'Container-' + uid(),
          'namespace': '',
          'settings': null,
          'viewport': null,
          'build': {
            'id': 'Container-' + uid(),
            'ref': 'Container-' + uid(),
            'alias': 'Container'
          }
        },
      ],
      'component': 'AutocompleteResultsOption',
      'id': 'AutocompleteResultsOption-' + uid(),
      'namespace': '',
      'events': null,
      'settings': null,
      'viewport': null,
      'build': {
        'id': 'AutocompleteResultsOption-' + uid(),
        'ref': 'AutocompleteResultsOption-' + uid(),
        'alias': 'Option'
      }
    })
  },
];

