import {
  PFAlert, PFContainer,
  PFIcon, PFLink, PFLoader, PFText
} from 'PFComponents/common';
import { PFDashboardHeader } from 'PFComponents/navigation';
import { useAsyncCall } from 'PFHooks';
import { PFScreenDBTables } from 'PFScreens';
import store, { currentDBActions } from 'PFStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { DBDashboardActions, DBDashboardSidebar } from './components';


const PFLayoutDBDashboard = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const currentDB = useSelector((state) => state.current_db);
  const currentUserId = useSelector((state) => state.user._id);
  const currentApp = useSelector((state) => state.current_app);
  const currentFunction = useSelector((state) => state.current_function);
  const { db_id } = useParams();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);

  useEffect(() => {
    asyncCall(async () => {
      const db = await builderTest(currentUserId).getDatabase(db_id);
      store.dispatch(currentDBActions.setCurrentDB(db));
    });
  }, [db_id]);

  const getBackInfo = () => {
    let backLink;
    let backText;
    if (currentApp) {
      backLink = `/functions/${currentFunction._id}/databases`;
      backText = 'Back to app databases';
    } else {
      if (currentFunction) {
        backLink = `/functions/${currentFunction._id}/databases`;
        backText = 'Back to function databases';
      } else {
        backLink = `/databases`;
        backText = 'Back to all databases';
      }
    }
    return {
      backLink: backLink,
      backText: backText
    };
  };

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <PFDashboardHeader />
        {loading || !currentDB
          ? <PFLoader area color="white" />
          : <PFContainer padding="horizontal-l vertical-m">
            <PFContainer display='flex' flexDirection='column'>
              <PFContainer width='fit-content'>
                <PFLink to={getBackInfo().backLink}>
                  <PFContainer display="flex" alignItems="center">
                    <PFIcon icon="bx bx-chevron-left" size="l"
                      color='var(--purple-100)' />
                    <PFText type="secondary"
                      color='var(--purple-100)'>
                      {getBackInfo().backText}
                    </PFText>
                  </PFContainer>
                </PFLink>
              </PFContainer>
              <PFContainer display='flex'
                justifyContent='space-between' gap='m'>
                <PFContainer>
                  <PFText tag="h1" size="l">{currentDB.info.alias}</PFText>
                </PFContainer>
                <DBDashboardActions />
              </PFContainer>
            </PFContainer>
            <PFContainer display='flex' margin='top-l'>
              <DBDashboardSidebar />
              <PFContainer flex={1} margin='left-l'>

                <PFContainer>
                  <Routes>
                    <Route path="/tables"
                      element={<PFScreenDBTables />} />
                  </Routes>
                </PFContainer>
              </PFContainer>
            </PFContainer>

          </PFContainer>
        }
      </PFContainer>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default PFLayoutDBDashboard;
