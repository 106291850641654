import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 6 });

const NODE_TYPE_CONDITIONAL_OPTIONS = [
  {
    name: 'Yes / No',
    type: 'CONDITIONAL_IFELSE',
    description: 'Bifurcate your flow in 2',
    icon: 'bx bx-directions',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        type: 'CONDITIONAL_IFELSE',
        alias: 'Conditional node',
        next: next || null,
        conditionals: [{
          expression: '',
        }],
        then: {
          type: 'NODE',
          value: next || null
        },
        else: {
          type: 'NODE',
          value: null
        }
      };
    },
  },
  {
    name: 'Multiple switch',
    type: 'CONDITIONAL_SWITCH',
    description: 'Create as big a tree as you need',
    icon: 'bx bx-sitemap',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        type: 'CONDITIONAL_SWITCH',
        alias: 'Switch node',
        conditionals: [{
          expression: '',
          match_node: next || null,
        }],
        default: {
          type: 'NODE',
          value: null
        }
      };
    },
  }
];

export default NODE_TYPE_CONDITIONAL_OPTIONS;
