
import { PFAlert } from 'PFComponents/common';
import useAsyncCall from './useAsyncCall';

/**
 * Hook to perform and track several asynchronous calls.
 *
 * @param {...boolean} loadingDefaults - Default loading states for the async
 * calls that will be performed (one for each object to be returned).
 *
 * @return {[]} Control variables and alerts array.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const useAsyncCalls = (...loadingDefaults) => {
  const resultingHooks = loadingDefaults.map((loadingDefault) => {
    const [asyncCall, loading, error, dismissError]
      = useAsyncCall(loadingDefault);
    return { asyncCall, loading, error, dismissError };
  });

  const alerts = () => {
    return resultingHooks.map(({ error, dismissError }, i) => {
      return (
        <PFAlert
          key={i}
          message={error}
          open={!!error}
          onClose={dismissError} />
      );
    });
  };

  return [...resultingHooks, alerts];
};

export default useAsyncCalls;
