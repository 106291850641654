import { PFButton, PFContainer, PFIcon, PFInput } from 'PFComponents/common';
import PropTypes from 'prop-types';

const InputParam = ({ inputName, setInputName, inputValue, setInputValue,
  deleteInputParam }) => {
  return (
    <PFContainer display='flex' gap='s' alignItems='center'>
      <PFInput placeholder='Variable name' value={inputName}
        onChange={(e) => setInputName(e.target.value)} />

      <PFContainer flex={1}>
        <PFInput placeholder='Value' value={inputValue}
          onChange={(e) => setInputValue(e.target.value)} />
      </PFContainer>
      <PFButton type='none' height='auto' padding='0'
        onClick={deleteInputParam}>
        <PFIcon icon='bx bx-trash' color='var(--purple-100)'
          size='l' />
      </PFButton>
    </PFContainer>

  );
};

InputParam.propTypes = {
  inputName: PropTypes.string,
  setInputName: PropTypes.func,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  deleteInputParam: PropTypes.func,
};

export default InputParam;
