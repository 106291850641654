import {
  PFButton, PFContainer,
  PFIcon, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import validators from 'src/utils/validators';
import InputView from './InputView';
import JsonView from './JsonView';

const ResponseEditor = ({ operation, updateOperation, variables }) => {
  const [toggleConfiguration, setToggleConfiguration] = useState(false);
  const response = operation.response_format;
  const [stringResponse, setStringResponse] = useState('');
  const [saved, setSaved] = useState(false);

  const generateResponse = () => {
    const autogereatedResponse = variables
      .filter((unfilteredVariable) => !!unfilteredVariable.name)
      .map((variable) => {
        return {
          name: variable.name,
          value: '${' + variable.name + '}'
        };
      });
    return autogereatedResponse;
  };

  const parseToStringObject = (responseObject) => {
    const parsedObject = responseObject.reduce((obj, item) => {
      obj[item.name] = item.value;
      return obj;
    }, {});
    const stringifiedObject = JSON.stringify(parsedObject);
    return stringifiedObject;
  };

  useEffect(() => {
    setStringResponse(parseToStringObject(response));
  }, [toggleConfiguration]);

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFText type="secondary">Response</PFText>
        <PFContainer display='flex' gap='m'>
          {
            !toggleConfiguration
              ? <PFButton onClick={() => {
                updateOperation({
                  ...operation,
                  response_format: generateResponse()
                });
              }} height='auto' type='none'
                style={{
                  display: 'flex',
                  gap: 'var(--size-s)',
                  alignItems: 'center'
                }}>
                <PFIcon icon='bx bx-refresh'
                  color='var(--purple-200)' />
                <PFText color='var(--purple-200)'>
                  Auto-generate response
                </PFText>
              </PFButton>
              : null
          }
          <PFButton onClick={() => {
            setToggleConfiguration(((prev) => !prev));
          }} height='auto' type='none'
            style={{
              display: 'flex',
              gap: 'var(--size-s)',
              alignItems: 'center'
            }} disabled={!!validators.validateJSON(stringResponse)}>
            <PFIcon icon={!toggleConfiguration
              ? 'bx bxs-cog' : 'bx bx-arrow-back'}
              color='var(--purple-200)' />
            <PFText color='var(--purple-200)'>
              {!toggleConfiguration
                ? 'Custom configuration'
                : 'Return to basic configuration'}
            </PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
      <PFContainer>
        {
          toggleConfiguration
            ? <JsonView operation={operation}
              updateOperation={updateOperation}
              stringResponse={stringResponse}
              setStringResponse={setStringResponse}
              saved={saved} setSaved={setSaved} />

            : <InputView response={response}
              updateOperation={updateOperation}
              operation={operation} />
        }

      </PFContainer>
    </PFContainer>
  );
};

ResponseEditor.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
  variables: PropTypes.array,
};

export default ResponseEditor;
