import { builderV2 } from 'PFApis';
import {
  PFContainer, PFInput,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CopyRootButton from './CopyRootButton';


const OperationSelector = ({ reference, setReference, params, setParams }) => {
  const currentApp = useSelector((state) => state.current_app);
  const functions = currentApp.build.associated_functions.docs;

  const [func, setFunc] = useState(null);
  const [operation, setOperation] = useState(null);

  const [fetch, alerts] = useAsyncCalls(false);

  const [selectedFunction, selectedOperation] = reference.split('/', 2);
  const setSelectedFunction
    = (newFunc) => setReference(`${newFunc}/`);
  const setSelectedOperation
    = (newOperation) => setReference(`${selectedFunction}/${newOperation}`);

  useEffect(() => {
    if (selectedFunction) {
      fetch.asyncCall(async () => {
        const func = await builderV2.getFunction(selectedFunction);
        const op = selectedOperation
          ? func.operations.find((op) => op._id === selectedOperation)
          : null;
        setFunc(func);
        setOperation(op);
      });
    }
  }, [selectedFunction]);

  useEffect(() => {
    if (func && selectedOperation) {
      const op = func.operations.find((op) => op._id === selectedOperation);
      setOperation(op);
    }
  }, [func, selectedOperation]);

  const functionOptions = functions.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });

  const getOperationOptions = () => {
    return func.operations.map((op) => ({
      label: op.name, value: op._id
    }));
  };

  const renderParams = () => {
    return Object.keys(operation.request_values).map((key) => {
      const value = params[key] ?? '';
      const setValue = (value) => setParams({ ...params, [key]: value });

      return (
        <React.Fragment key={key}>
          <PFText type="secondary" size="s" clip style={{ marginTop: 6 }}>
            {key}
          </PFText>
          <PFInput
            value={value} onChange={(e) => setValue(e.target.value)}
            placeholder={key}
            fontSize="s" style={{ height: 30 }} />
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <PFSelect
        name="function_id"
        value={selectedFunction}
        onChange={(e) => setSelectedFunction(e.target.value)}
        options={functionOptions}
        placeholder="Select a function"
        style={{ fontSize: 'var(--text-label)' }}
        optionsStyles={{
          color: 'var(--white)'
        }}
        listStyles={{
          border: '1px solid var(--purple-300)',
          backgroundColor: 'var(--purple-500)'
        }}
      />

      {fetch.loading || fetch.error
        ? <PFContainer height={80} padding="m" radius="m"
          display="flex" alignItems="center" justifyContent="center">
          <PFLoader color="var(--primary)" />
        </PFContainer>
        : <>
          {!!func && func.operations.length > 0
            ? <>
              <PFSelect
                name="operation_id"
                value={selectedOperation}
                onChange={(e) => setSelectedOperation(e.target.value)}
                options={getOperationOptions()}
                placeholder="Select an operation"
                style={{ fontSize: 'var(--text-label)' }}
                optionsStyles={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  color: 'var(--white)'
                }}
                listStyles={{
                  border: '1px solid var(--purple-300)',
                  backgroundColor: 'var(--purple-500)'
                }}
              />
              {!!operation
                ? <PFContainer>
                  <PFContainer display="grid" gridTemplateColumns="auto 1fr"
                    gap="s">
                    {renderParams()}
                  </PFContainer>
                  <CopyRootButton copyTarget={`{{dynamic:${selectedFunction
                    ? selectedFunction : ''}/${selectedOperation
                      ? selectedOperation : ''}.response.}}`} />
                </PFContainer>

                : null}
            </>
            : null}
        </>}

      {alerts()}
    </>
  );
};

OperationSelector.propTypes = {
  reference: PropTypes.string.isRequired,
  setReference: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
};

export default OperationSelector;
