import {
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton,
  PFInput,
  PFLine,
  PFLoader,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { RecordOrigin, RecordValues } from './components';


const InsertRecord = ({ index, instruction, setInstruction, isCreated }) => {
  const { var_name, execute } = instruction;
  const currentUserId = useSelector((state) => state.user._id);
  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  const [db_fetch, alerts] = useAsyncCalls(false);

  const { docs: databases } = useSelector(
    (state) => state.current_function.associated_dbs);
  const [db, setDb] = useState(null);

  const tableSelected = !!db && !!execute?.schema_db && !!execute?.table;
  const table = tableSelected
    ? db.tables.find((table) => table.table_name === execute.table)
    : null;
  const columnOptions = !!table
    ? table.fields.filter((field) =>
      field.column_name !== 'id'
    ).map((field) => ({
      label: field.alias, value: field.column_name,
    }))
    : null;

  useEffect(() => {
    db_fetch.asyncCall(async () => {
      if (!!execute.schema_db) {
        const database = await builderTest(currentUserId)
          .getDatabase(execute.schema_db);
        setDb(database);
      }
    });
  }, [execute.schema_db]);

  const setSchemaDB = (schema_db) => setExecute({
    schema_db, schema: `$\{${schema_db}\}`, table: '', values: {},
  });
  const setTable = (table) => setExecute({
    ...execute, table, values: {},
  });
  const setValues = (values) => setExecute({ ...execute, values });

  return (
    <>
      <PFContainer type="form" padding="m" radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex"
          justifyContent="space-between" alignItems="center">
          <PFContainer height={30} display="flex" alignItems="center" gap="s">
            {
              !isCollapsed && <PFIconButton
                icon="bx bx-chevron-left"
                onClick={() => setType('')} />
            }
            <PFText type="secondary">Instruction {index + 1}:</PFText>
            <PFText>Insert record</PFText>
            {db_fetch.loading
              ? <PFLoader color="var(--secondary)" size="s"
                margin="top-xs horizontal-s" />
              : null
            }
          </PFContainer>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed
              ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
          </PFButton>
        </PFContainer>
        <PFContainer display={isCollapsed
          ? 'none' : 'flex'} flexDirection='column' gap='m'>
          <PFLine />
          <PFContainer display="flex" alignItems="center" gap="m">
            <PFText type="secondary">
              Target variable
            </PFText>
            <PFContainer flex={1}>
              <PFInput value={var_name}
                onChange={(e) => setVarName(e.target.value)}
                placeholder="This is where the new record ID is stored" />
            </PFContainer>
          </PFContainer>
          <PFLine />
          <PFContainer display="flex" flexDirection="column" gap="l">
            <RecordOrigin
              databases={databases}
              db={db}
              schemaDB={execute?.schema_db}
              setSchemaDB={setSchemaDB}
              table={execute?.table}
              setTable={setTable}
              tableSelectionDisabled={!db || db_fetch.loading}
            />
            {tableSelected
              ? <>
                <PFContainer>
                  <PFText>Values to insert</PFText>
                  <RecordValues
                    values={execute?.values}
                    setValues={setValues}
                    columnOptions={columnOptions} />
                </PFContainer>
              </>
              : null}
          </PFContainer>
        </PFContainer>

      </PFContainer>
      {alerts()}
    </>
  );
};

InsertRecord.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default InsertRecord;
