import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFCodeEditor,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput, PFModal,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { API } from 'PFConstants';
import { useAsyncCall, useForm } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ParamTypeFields } from './components';


const verbOptions = API.VERBS.map((verb) => ({ label: verb, value: verb }));

/**
 * Componennt that presents a modal to edit an API declaration.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFAPIDeclarationModalEditor properties.
 *
 * @return {React.ReactElement} The api declaration modal editor component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFAPIDeclarationModalEditor = ({
  api,
  open,
  onClose,
}) => {
  const currentApp = useSelector((state) => state.current_app);
  const [tab, setTab] = useState(null);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm(formConfigFromApi(api));

  useEffect(() => {
    form.setConfig(formConfigFromApi(api));
  }, [api]);

  useEffect(() => {
    if (form.values.application_type === 'application/soap+xml') {
      handleTabChange('soap');
    } else {
      handleTabChange('json');
    }
  }, [form.values?.application_type]);

  const handleTabChange = (newTab) => {
    const newFromConfig = formConfigFromApi(form.values, true);
    newFromConfig.application_type
      = `application/${newTab === 'json' ? 'json' : 'soap+xml'}`;
    newFromConfig.response_type = 'application/json';
    form.setConfig(newFromConfig);
    setTab(newTab);
  };

  const addParamField = (property) => {
    const newFromConfig = formConfigFromApi(form.values, true);
    newFromConfig[property].push({ key: '', value: '' });
    form.setConfig(newFromConfig);
  };

  const removeParamField = (property, index) => {
    const newFromConfig = formConfigFromApi(form.values, true);
    newFromConfig[property].splice(index, 1);
    form.setConfig(newFromConfig);
  };

  const saveAPI = (values) => {
    values.headers = values.headers ?? [];
    values.headers = values.headers.filter((h) => !!h.key);

    values.query = values.query ?? [];
    values.query = values.query.filter((q) => !!q.key);

    asyncCall(async () => {
      let newApiId;
      if (!!api) {
        await builderV2.updateAPI(currentApp._id, api._id, values);
        store.dispatch(currentAppActions.updateApiDeclaration({
          _id: api._id, alias: values.info.alias
        }));
        newApiId = api._id;
      } else {
        const { InsertedID }
          = await builderV2.createAPI(currentApp._id, values);
        store.dispatch(currentAppActions.addApiDeclaration({
          _id: InsertedID, alias: values.info.alias
        }));
        newApiId = InsertedID;
      }
      const newApi = await builderV2.getAPI(currentApp._id, newApiId);

      onClose(newApi);
    });
  };

  return (
    <PFModal active={open} onDismiss={onClose} outsideDismiss={false}>
      <PFForm form={form} submit={saveAPI}>
        <PFContainer type="form" width={600} radius="m" >
          <PFContainer padding="vertical-s horizontal-m"
            display="flex" justifyContent="space-between">
            <PFInput name="info.alias" fieldHook={fieldHook}
              className="alt" width="60%" placeholder="API name" />
            <PFButton type="none" onClick={onClose}>
              <PFContainer display="flex"
                alignItems="center" justifyContent="center"
                background="var(--purple-50)" height={30} width={30}
                style={{ borderRadius: '50%' }}>
                <PFIcon icon="bx bx-x" size="l" color="var(--secondary)" />
              </PFContainer>
            </PFButton>
          </PFContainer>
          <PFContainer
            display='flex'
            alignItems='center'
            gap='l'
            padding='top-s right-m left-m'
          >
            <PFButton
              type='support'
              onClick={() => handleTabChange('json')}
              style={{
                borderBottom:
                  tab === 'json'
                    ? '4px solid'
                    : '4px solid transparent'
              }}
            >
              JSON
            </PFButton>
            <PFButton
              type='support'
              onClick={() => handleTabChange('soap')}
              style={{
                borderBottom:
                  tab === 'soap'
                    ? '4px solid'
                    : '4px solid transparent'
              }}
            >
              SOAP
            </PFButton>
          </PFContainer>
          <PFContainer padding="m"
            display="flex" flexDirection="column" gap="m">
            <PFContainer display="flex" gap="s">
              <PFContainer width={150}>
                <PFSelect name="method" fieldHook={fieldHook}
                  label="Verb" options={verbOptions} />
              </PFContainer>
              <PFInput name="url" fieldHook={fieldHook}
                label="Endpoint" width="100%" />
            </PFContainer>
            <PFContainer>
              <PFText>Headers</PFText>
              <PFContainer margin="top-s"
                display="flex" flexDirection="column" gap="s">
                <ParamTypeFields
                  form={form}
                  property="headers"
                  fieldHook={fieldHook}
                  remove={(i) => removeParamField('headers', i)} />
              </PFContainer>
              <PFButton type="support" onClick={() => addParamField('headers')}>
                + add new header
              </PFButton>
            </PFContainer>
            <PFContainer>
              <PFText>Query Params</PFText>
              <PFContainer margin="top-s"
                display="flex" flexDirection="column" gap="s">
                <ParamTypeFields
                  form={form}
                  property="query"
                  fieldHook={fieldHook}
                  remove={(i) => removeParamField('query', i)} />
              </PFContainer>
              <PFButton type="support" onClick={() => addParamField('query')}>
                + add new query param
              </PFButton>
            </PFContainer>
            <PFContainer>
              <PFCodeEditor name="body" label="Request body"
                fieldHook={fieldHook} minHeight={100} />
            </PFContainer>
            <PFButton margin="top-s" submit loading={loading}>Save</PFButton>
          </PFContainer>
        </PFContainer>
      </PFForm>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFModal>
  );
};

const formConfigFromApi = (api, avoidEmptyParams) => {
  const setParamsTypeDefault = (property) => {
    if (!!property && property.length > 0) {
      return property;
    } else {
      return [];
    }
  };

  return {
    application_type: {
      default_value: !!api?.application_type
        ? api?.application_type : 'application/json',
      validation_type: 'text',
      error_message: 'Please provide an API application type'
    },
    response_type: {
      default_value: !!api?.response_type
        ? api?.response_type : 'application/json',
      validation_type: 'text',
      error_message: 'Please provide an API response type'
    },
    info: {
      alias: {
        default_value: api?.info?.alias ?? '',
        validation_type: 'text',
        error_message: 'Please provide a name for the API'
      },
      description: api?.info?.description ?? '',
    },
    method: {
      default_value: api?.method ?? 'GET',
      validation_type: 'text',
      error_message: 'Missing'
    },
    url: {
      default_value: api?.url ?? '',
      validation_type: 'text',
      error_message: 'Please provide an API endpoint'
    },
    headers: setParamsTypeDefault(api?.headers),
    query: setParamsTypeDefault(api?.query),
    body: {
      default_value: api?.body ?? '',
      validation_type: api?.application_type === 'application/soap+xml'
        ? 'xml'
        : 'json',
      error_message: 'Please provide valid request body'
    }
  };
};

PFAPIDeclarationModalEditor.defaultProps = {
  apiDeclarationId: '',
};

PFAPIDeclarationModalEditor.propTypes = {
  api: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PFAPIDeclarationModalEditor;
