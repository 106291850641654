import { PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';
import useFocusOnRender from './useFocusOnRender';

function DecimalCell({ params, apiRef }) {
  const { id, value, field, hasFocus } = params;
  const ref = useFocusOnRender(hasFocus);
  return (
    <PFContainer width='100%' display='flex' justifyContent='center'>
      <input ref={ref}
        type='string'
        value={value}
        onChange={(e) => {
          if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            apiRef.current.setEditCellValue({
              id: id, field: field,
              value: e.target.value
            });
          } else {
            apiRef.current.setEditCellValue({
              id: id, field: field,
              value: !value ? '' : value.replace(/[^0-9]/g, '')
            });
          }
        }} style={{
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none'
        }} />
    </PFContainer>
  );
}
DecimalCell.propTypes = {
  params: PropTypes.object,
  apiRef: PropTypes.object,
};

export default DecimalCell;
