/* eslint-disable */
import {
  GridActionsCellItem,
  GridRowModes
} from '@mui/x-data-grid';
import {
  PFButton, PFContainer, PFIcon, PFModal,
  PFText
} from 'PFComponents/common';
import { useState } from 'react';

export const rowActions = (id, rows, apiRef, setExitModeWarning) => {
  const { values, setValues, models, setModels, deleteRow } = rows;
  const [open, setOpen] = useState(false);

  const isInEditMode = models[id]?.mode === GridRowModes.Edit;

  const handleEditClick = (id) => () => {
    setModels({
      ...models,
      [id]: { mode: GridRowModes.Edit }
    });
  };

  const handleSaveClick = (id) => () => {
    setModels({
      ...models,
      [id]: { mode: GridRowModes.View }
    });
  };

  const handleDeleteClick = (id) => () => {
    deleteRow(id);
    setOpen(false);
  };

  const handleCancelClick = (id) => () => {
    setModels({
      ...models,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = values.find((row) => row.ctrl_index === id);
    if (editedRow.isNew) {
      setValues(values.filter((row) => row.ctrl_index !== id));
    }
  };

  if (isInEditMode) {
    return [
      <GridActionsCellItem
        key={`${id}-save`}
        icon={<PFIcon icon="bx bx-save" />}
        label="Save"
        sx={{
          color: 'primary.main',
        }}
        onClick={handleSaveClick(id)}
      />,
      <GridActionsCellItem
        key={`${id}-cancel`}
        icon={<PFIcon icon="bx bx-x" />}
        label="Cancel"
        className="textPrimary"
        onClick={handleCancelClick(id)}
        color="inherit"
      />,
    ];
  }

  return [
    <GridActionsCellItem
      key={`${id}-edit`}
      icon={<PFIcon icon="bx bx-pencil" />}
      label="Edit"
      className="textPrimary"
      onClick={() => {
        if (Object.keys(apiRef.current.state.editRows).length > 0) {
          setExitModeWarning(true);
        } else {
          handleEditClick(id)();
        }
      }}
      color="inherit"
    />,
    <div>

      <GridActionsCellItem
        key={`${id}-delete`}
        icon={<PFIcon icon="bx bx-trash-alt" />}
        label="Delete"
        onClick={() => {
          if (Object.keys(apiRef.current.state.editRows).length > 0) {
            setExitModeWarning(true);
          } else {
            setOpen(true);
          }
        }}
        color="inherit"
      />
      <PFModal active={open} zIndex={10000}>
        <PFContainer type="toolbar" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="secondary">
              Are you sure you want to delete this row?
            </PFText>
          </PFContainer>
          <PFContainer margin="top-l"
            display="flex" justifyContent="end" gap="m">
            <PFButton type="secondary"
              width={100}
              onClick={() => {
                setOpen(false);
              }}>
              Cancel
            </PFButton>
            <PFButton width={100}
              onClick={handleDeleteClick(id)}>
              Yes
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
    </div>
  ];
};
