const createWebSocket = (url) => {
  const websocket = {
    socket: null,
    eventListeners: {},
    isConnected: false,

    connect: async () => {
      if (websocket.socket && websocket.isConnected) {
        return;
      }

      if (websocket.socket) {
        websocket.socket.close();
        websocket.socket = null;
      }

      return new Promise((resolve, reject) => {
        websocket.socket = new WebSocket(url);

        websocket.socket.onopen = () => {
          console.log(`WebSocket connection (${url}) established.`);
          websocket.isConnected = true;
          resolve();
        };

        websocket.socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          const { event: eventName, data: eventData } = data;
          const listeners = websocket.eventListeners[eventName] || [];
          listeners.forEach((listener) => listener(eventData));
        };

        websocket.socket.onclose = () => {
          console.log(`WebSocket connection (${url}) closed.`);
          websocket.isConnected = false;
        };

        websocket.socket.onerror = (error) => {
          console.error(`WebSocket connection (${url}) error:`, error);
          websocket.isConnected = false;
          reject(error);
        };
      });
    },

    on: (eventName, callback) => {
      if (!websocket.eventListeners[eventName]) {
        websocket.eventListeners[eventName] = [];
      }
      websocket.eventListeners[eventName].push(callback);
    },

    off: (eventName, callback) => {
      if (websocket.eventListeners[eventName]) {
        websocket.eventListeners[eventName]
          = websocket.eventListeners[eventName].filter(
            (listener) => listener !== callback
          );
      }
    },

    send: (eventName, data) => {
      if (websocket.socket && websocket.socket.readyState === WebSocket.OPEN) {
        const messageData = { event: eventName, data };
        websocket.socket.send(JSON.stringify(messageData));
      }
    },

    disconnect: () => {
      if (websocket.socket) {
        websocket.socket.close();
        websocket.socket = null;
        websocket.isConnected = false;
      }
    },
  };

  return websocket;
};

export default { createWebSocket };
