import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import { FUNCTIONS } from 'PFConstants';
import PropTypes from 'prop-types';


const Selector = ({ index, instruction, setInstruction, setIsCreated }) => {
  const renderInstructionTypes = () => {
    return FUNCTIONS.INSTRUCTIONS.map(({ name, type, icon, create,
      upcoming = false }, index) => {
      return (
        <PFButton key={`${type}-${index}`} type="none" padding="s"
          onClick={() => {
            setInstruction(create());
            setIsCreated(true);
          }}
          disabled={upcoming}
          style={{
            background: 'var(--purple-25)',
            borderRadius: 8,
          }}>
          <PFContainer display="flex" gap="s"
            flexDirection="column" alignItems="center"
            style={{
              position: 'relative'
            }}>
            <PFIcon icon={icon} size="l" color="var(--primary)"
              margin="top-s" />
            <PFContainer display='flex' alignItems="center">
              <PFText weight={300} size="s">{name}</PFText>
            </PFContainer>
            {
              upcoming
                ? <PFContainer
                  background='var(--purple-100)'
                  style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-10px'
                  }} >
                  <PFText weight={300} size="xs" color='var(--white)'
                    padding='xs'>
                    Upcoming
                  </PFText>
                </PFContainer> : null
            }

          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFText type="secondary">Instruction {index + 1}</PFText>
      </PFContainer>
      <PFContainer display="grid" gap="s"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr">
        {renderInstructionTypes()}
      </PFContainer>
    </PFContainer>
  );
};

Selector.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  setIsCreated: PropTypes.func
};

export default Selector;
