import { builderV2 } from 'PFApis';
import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { useAsyncCall, useForm, useShortcut } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  APIIntegrationForm,
  IntegrationEditorHeader,
  IntegrationEditorSidebar
} from './components';
import './components/APIIntegration.css';
import { formConfigFromApi } from './utils';

const PFScreenIntegrationEditor = () => {
  const { app_id, api_id } = useParams();

  const [fetchAsyncCall, fetchLoading, fetchError, dismissFetchError]
    = useAsyncCall(true);
  const [saveAsyncCall, saveLoading, saveError, dismissSaveError]
    = useAsyncCall(false);

  const [api, setApi] = useState(null);
  const [saved, setSaved] = useState(false);
  const [formattingKeyOptions, setFormattingKeyOptions] = useState([]);
  const [arrayError, setArrayError] = useState(null);
  const [inputParameters, setInputParameters] = useState([]);

  const [form, fieldHook] = useForm(formConfigFromApi(api));

  const saveApi = () => {
    if (!form.validate()) return;

    const newApi = { ...api, ...form.values };
    newApi.headers = newApi.headers.filter((h) => !!h.key);
    newApi.query = newApi.query.filter((q) => !!q.key);
    newApi.params = newApi.params.filter((p) => !!p.key);
    if (newApi.response_formatter?.find((el) => !/^[a-zA-Z0-9]*$/
      .test(el.format))) {
      setArrayError(`File extension does 
      not allow spaces or special characters.`);
      return;
    }
    newApi.response_formatter = newApi.response_formatter
      ? newApi.response_formatter.map((el) => {
        return ({
          ...el,
          'format': `base64:binary:${el.format}`
        });
      }) : null;

    saveAsyncCall(async () => {
      await builderV2.updateAPI(app_id, api_id, newApi);
      store.dispatch(currentAppActions.updateApiDeclaration({
        _id: newApi._id, alias: newApi.info.alias
      }));
      setSaved(true);
      const newInputParameters = formattingKeyOptions
        .map(({ value }) => {
          return ({
            name: value,
            value: ''
          });
        }, {});
      setInputParameters(newInputParameters);
    });
  };

  const cleanApi = (api) => {
    const newParams = api.params.filter((el) => el.format !== null);

    const newFormatter = api.response_formatter ? api
      .response_formatter.map((el) => {
        return ({
          ...el,
          format: el.format.replace(/^base64:binary:/, '')
        });
      }) : [];
    return ({
      ...api,
      params: newParams,
      response_formatter: newFormatter
    });
  };
  useEffect(() => {
    fetchAsyncCall(async () => {
      setSaved(false);
      const api = await builderV2.getAPI(app_id, api_id);
      setApi(cleanApi({ ...api }));
      if (api.body?.match(/{{(.*?)}}/g)) {
        const matches = api.body.match(/{{(.*?)}}/g);
        const valuesInsideBraces = matches.map((match) => match.slice(2, -2));
        const newFormattingKeyOptions = valuesInsideBraces.map((element) => (
          { label: element, value: element }
        ));
        setFormattingKeyOptions(newFormattingKeyOptions);
        const newInputParameters = newFormattingKeyOptions
          .map(({ value }) => {
            return ({
              name: value,
              value: ''
            });
          }, {});
        setInputParameters(newInputParameters);
      } else {
        setFormattingKeyOptions([]);
      }
    });
  }, [app_id, api_id]);

  useEffect(() => {
    form.setConfig(formConfigFromApi(api));
  }, [api]);

  useEffect(() => {
    if (form.values.body) {
      const matches = form.values.body.match(/{{(.*?)}}/g);
      if (!matches) return;
      const valuesInsideBraces = matches.map((match) => match.slice(2, -2));
      const newFormattingKeyOptions = valuesInsideBraces.map((element) => (
        { label: element, value: element }
      ));
      setFormattingKeyOptions(newFormattingKeyOptions);
    } else {
      setFormattingKeyOptions([]);
    }
  }, [form.values.body]);

  useShortcut('ctrl+s', saveApi);
  return (
    <>
      <IntegrationEditorHeader
        appId={app_id}
        apiId={api_id}
        save={saveApi}
        saveLoading={saveLoading}
        saved={saved}
        inputParameters={inputParameters} />
      <PFContainer display="flex" flex={1} alignItems='stretch'>
        <IntegrationEditorSidebar appId={app_id} apiId={api_id}
          setInputParameters={setInputParameters} />
        <PFContainer flex={1}>
          <PFContainer width="100%" height="100%" type="form">
            {fetchLoading || !api
              ? <PFLoader area color="var(--primary)" />
              : <PFContainer className='field-label' >
                <APIIntegrationForm form={form} fieldHook={fieldHook}
                  saveAPI={saveApi} saveLoading={saveLoading}
                  formattingKeyOptions={formattingKeyOptions} />
              </PFContainer>
            }
            <PFAlert
              message={fetchError}
              open={!!fetchError}
              onClose={dismissFetchError} />
            <PFAlert
              message={saveError}
              open={!!saveError}
              onClose={dismissSaveError} />
            <PFAlert
              message={arrayError}
              open={!!arrayError}
              onClose={() => setArrayError(null)} />
          </PFContainer>
        </PFContainer>
      </PFContainer >
    </>
  );
};

export default PFScreenIntegrationEditor;
