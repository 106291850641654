import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFCheckbox,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import { useAsyncCall, useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const FUNCTION_CALL_TYPE = PROCESS_FLOW.NODE_TYPE_ACTION_OPTIONS
  .find((n) => n.type === 'FUNCTION_CALL');

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit a custom API node
 *
 * @param {Object} props - NodeFunctionCall props.
 * @param {ProcessFlowNode} props.node - FUNCTION_CALL type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Custom API node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeFunctionCall = ({ node, updateNode, goBack }) => {
  const currentApp = useSelector((state) => state.current_app);
  const functions = currentApp.build.associated_functions.docs;

  const [func, setFunc] = useState(null);
  const [nodeName, setNodeName] = useState(node?.alias ?? '');
  const [asyncNode, setAsyncNode] = useState(node?.async ?? false);

  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm(buildFormConfig(node, func, null));

  const selectedFunction = form.values.function_id;
  const selectedOperation = form.values.operation_id;

  useEffect(() => {
    if (selectedFunction) {
      asyncCall(async () => {
        const func = await builderV2.getFunction(selectedFunction);
        const op = selectedOperation
          ? func.operations.find((op) => op._id === selectedOperation)
          : null;
        form.setConfig(buildFormConfig(node, func, op));
        setFunc(func);
      });
    }
  }, [selectedFunction]);

  useEffect(() => {
    if (func && selectedOperation) {
      const op = func.operations.find((op) => op._id === selectedOperation);
      setNodeName(op?.name);
      form.setConfig(buildFormConfig(node, func, op));
    }
  }, [func, selectedOperation]);

  const functionOptions = functions.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });

  const getOperationOptions = () => {
    return func.operations.map((op) => ({
      label: op.name, value: op._id
    }));
  };

  const renderParams = () => {
    const params = Object.keys(form.values.params);

    return params.map((key) => {
      return (
        <React.Fragment key={key}>
          <PFContainer
            background="var(--input-form-background)"
            padding="horizontal-s" radius="s"
            display="flex" alignItems="center" justifyContent="flex-end">
            <PFText type="secondary" size="s">{key}</PFText>
          </PFContainer>
          <PFInput
            style={{ fontSize: 'var(--text-size-s)' }}
            name={`params.${key}`}
            fieldHook={fieldHook}
            placeholder={`${key} value`} />
        </React.Fragment>
      );
    });
  };

  const saveNode = (values) => {
    const updatedNode = { ...node };
    updatedNode.alias = nodeName ?? 'API Call node';
    updatedNode.function_id = values.function_id;
    updatedNode.operation_id = values.operation_id;
    updatedNode.params = values.params;
    updatedNode.async = asyncNode;

    updateNode(updatedNode);
    goBack();
  };

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={saveNode}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)}
              style={{
                color: 'var(--purple-100)'
              }} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={FUNCTION_CALL_TYPE.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{FUNCTION_CALL_TYPE.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {FUNCTION_CALL_TYPE.description}
              </PFText>
            </PFContainer>
          </PFContainer>

          <PFContainer display="flex" flexDirection="column" gap="s"
            style={{
              marginTop: '-10px'
            }}>
            <PFContainer display='flex' justifyContent='flex-end'>
              <PFContainer display='flex' gap='s' alignItems='center'>
                <PFText color='var(--purple-400)' size='s'>
                  ASYNC
                </PFText>
                <PFCheckbox checked={asyncNode}
                  onChange={() => {
                    setAsyncNode((prev) => !prev);
                  }} />
              </PFContainer>
            </PFContainer>
            <PFSelect
              name="function_id"
              fieldHook={fieldHook}
              options={functionOptions}
              placeholder="Select a function" />

            {loading || error
              ? <PFContainer height={80} padding="m" radius="m"
                display="flex" alignItems="center" justifyContent="center">
                <PFLoader color="var(--primary)" />
              </PFContainer>
              : <>
                {!!func && func.operations.length > 0
                  ? <PFContainer display="flex" flexDirection="column">
                    <PFSelect
                      name="operation_id"
                      fieldHook={fieldHook}
                      options={getOperationOptions()}
                      placeholder="Select an operation" />
                  </PFContainer>
                  : null}

                {!!form.values.params
                  ? <PFContainer margin="top-m">
                    <PFText weight={300} margin="bottom-s">Parameters</PFText>
                    <PFContainer
                      display="grid"
                      gridTemplateColumns="auto 1fr"
                      gap="s">
                      {renderParams()}
                    </PFContainer>
                  </PFContainer>
                  : null
                }

                <PFButton submit margin="top-m">Save</PFButton>
              </>}
          </PFContainer>
        </PFContainer>
      </PFForm>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

const buildFormConfig = (node, func, operation) => {
  const formConfig = {
    function_id: {
      default_value: func?._id ?? node.function_id ?? '',
      validation_type: 'text',
      error_message: 'Please select a function'
    },
    operation_id: {
      default_value: operation?._id ?? node.operation_id ?? '',
      validation_type: 'text',
      error_message: 'Please select an operation'
    },
    params: {}
  };

  if (!!operation) {
    for (const key in operation.request_values) {
      formConfig.params[key] = node.params[key] ?? '';
    }
  }

  return formConfig;
};

NodeFunctionCall.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeFunctionCall;
