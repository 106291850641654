import React, { ReactNode, useEffect, useRef, cloneElement } from 'react';
import * as Styled from './fileupload.styled';

export type FileuploadProps = {
    /**
     * accept is a string that defines the file types the file input should accept.
     */
    accept?: string;

    /**
     *  for content nested inside the span element (role button).
     */
    children: ReactNode;

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * Convention [Forms] : This is prop (aux) is used to clear this field's value on a form
     * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
     */
    clearValue?: any;

    /**
     * config driven styles override
     */
    configStyles?: string | undefined;

    /**
     * To disable the input[file] field.
     */
    disabled?: boolean;

    /**
     * file size error message
     */
    fileSizeErrorMessage?: string;

    /**
     * file size limit in bytes
     */
    fileSizeLimit?: number;

    /**
     * Handlers: used for utility, helper, external dependency functions
     * + filesManager: this is the structure for file management actions and attributes
     * - upload: that takes files as a parameter and post the file to a given endpoint
     * - files: array that holds files to be posted and read
     * - error: attribute used to detect if any error occured while uploading
     * - loading: attribute to detect loading action
     */
    handlers: {
        file: { upload: any; files: File[] | [] | null; error: string | null; loading: boolean };
    };

    /**
     * To identifies the input[file] semantic element.
     */
    id: string;

    /**
     * based on Formik
     * meta object to handle field additional descriptive data
     *  - error
     *  - touched
     *  - value
     */
    meta?: any;

    /**
     * multiple file tag
     */
    multiple?: boolean;

    /**
     * name specifies a name for an input element
     */
    name: string;

    /**
     * specifies a short hint that describes the expected value of an input field
     */
    placeholder?: string;

    /**
     * based on Formik
     * setError form handler
     */
    setError?: any;

    /**
     * Convention [Forms] : This prop is used to validate a field or form status, it affects the "touched" action (edited due to focus but no change)
     * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
     */
    setTouched?: () => {};

    /**
     * Convention [Forms] : This is prop (aux) is used to set this field's value on a form
     * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
     */
    setValue?: any;

    /**
     * value contains a string that represents the path to the selected file(s).
     */
    value?: string[] | undefined;
};

export function Fileupload({
    accept,
    children,
    className,
    clearValue,
    configStyles,
    disabled,
    handlers,
    id,
    multiple,
    name,
    setValue,
    value,
}: FileuploadProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {
        file: { files, upload, loading },
    } = handlers;

    useEffect(() => {
        if (Array.isArray(value)) {
            setValue(value);
        }
    }, []);

    useEffect(() => {
        if (files !== null) {
            setValue(files);
        }
    }, [files]);

    const handleChange = (e: any) => {
        const fileList = [...e.currentTarget.files] || [];
        if (fileList) {
            upload(fileList);
        }
    };

    const handleChangeClearValues = (e: any) => {
        if (!fileInputRef?.current) return;
        e.preventDefault();
        e.stopPropagation();
        clearValue(null);
        fileInputRef.current.value = '';
    };

    return (
        <Styled.FileUpload htmlFor={id} configStyles={configStyles} onClick={(e) => {}}>
            <input
                accept={accept}
                className={className}
                disabled={disabled}
                id={id}
                multiple={multiple}
                name={name}
                onChange={(event) => handleChange(event)}
                ref={fileInputRef}
                type="file"
            />
            {Array.isArray(children) &&
                children.map((child, index) => {
                    if (child.props.controlType) {
                        return React.cloneElement(child as any, {
                            key: `file-upload-child-${index}`,
                            isLoading: loading,
                            value,
                            onClick: (e) => handleChangeClearValues(e),
                            ...child.props,
                        });
                    } else {
                        return React.cloneElement(child as any, {
                            key: `file-upload-child-${index}`,
                            isLoading: loading,
                            value,
                            onClick: (e) => handleChangeClearValues(e),
                            ...child.props,
                        });
                    }
                })}
        </Styled.FileUpload>
    );
}

export type FileUploadControlProps = {
    /**
     * semantic unique identifier attribute
     */
    id?: string | undefined;

    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode[] | ReactNode;

    /**
     * configuration driven styling overrides
     */
    configStyles?: string | undefined;

    /**
     * React className
     */
    className?: string | undefined;

    /**
     *
     */
    controlType?: string;

    /**
     * on click handler for fileUpload usage
     */
    onClick?: any;

    /**
     * placeholder text
     */
    placeholder?: string;

    /**
     * loading state for
     */
    isLoading?: boolean;

    /**
     * parent ref
     */
    ref?: any;

    /**
     * values to show on
     */
    value?: string[];
};

export function FileUploadControl(props: FileUploadControlProps) {
    const { children, controlType = 'values', placeholder = 'Upload Files', value, isLoading, onClick } = props;

    function FileControl(controlType) {
        let componentProps = props;
        if (controlType === 'clearValues') {
            componentProps = {
                onClick,
                ...props,
            };
            return <Styled.FileUploadControl {...componentProps}>{children}</Styled.FileUploadControl>;
        }

        if (controlType === 'loading') {
            return isLoading ? (
                <Styled.FileUploadControl {...componentProps} onClick={null}>
                    {children}
                </Styled.FileUploadControl>
            ) : null;
        }

        if (controlType === 'values') {
            return (
                <Styled.FileUploadControl {...componentProps} onClick={null}>
                    {Array.isArray(value) && value.length
                        ? value
                              .map((file: any) => {
                                  return file['name'];
                              })
                              .join(', ')
                        : placeholder}
                </Styled.FileUploadControl>
            );
        }
    }

    return <>{FileControl(controlType)}</>;
}
