import { builderV2 } from 'PFApis';
import { PFActionCard } from 'PFComponents/cards';
import {
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageCard, PageCreationModal } from './components';


const PFScreenAppPages = () => {
  const navigate = useNavigate();

  const currentApp = useSelector((state) => state.current_app);
  const appId = currentApp._id;
  const pages = currentApp.build.pages.docs;

  const [creationFormOpen, setCreationFormOpen] = useState(false);
  const [create, alerts] = useAsyncCalls(false);

  const baseURL = `/editor/apps/${appId}/pages`;

  const createPage = (values) => {
    create.asyncCall(async () => {
      const { BuilderPath } = await builderV2.createPage(appId, values);
      navigate(`${baseURL}/${BuilderPath}`);
    });
  };

  const renderPages = () => {
    return pages.map((page) => {
      return (
        <PageCard
          key={page._id}
          appId={appId}
          page={page}>
        </PageCard>
      );
    });
  };

  return (
    <>
      <PFContainer type="main">
        <PFContainer>
          <PFContainer margin='bottom-m'>
            <PFText tag="h2" size="l">Pages</PFText>
          </PFContainer>
          <PFContainer display="grid"
            gridTemplateColumns="1fr 1fr 1fr" gap="m">
            <PFActionCard onClick={() => setCreationFormOpen(true)}>
              <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
              <PFText>New Page</PFText>
            </PFActionCard>
            {renderPages()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <PageCreationModal
        onSubmit={createPage}
        loading={create.loading}
        active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
      {alerts()}
    </>
  );
};

export default PFScreenAppPages;
