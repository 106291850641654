import {
  PFLine
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import {
  getAncestryByName
} from 'src/screens/PFScreenPageEditor/components/Canvas/utils';
import {
  ConditionalVisibilityControls, CustomComponentSettings,
  DynamicDataSelector, MultistepControls, PropertyControls,
  RepeaterEditor
} from './components';

const ConfigureEditor = ({ canvas, component, setComponent }) => {
  const settings = component.settings ?? {};
  const setSettings = (settings) => setComponent({ ...component, settings });

  const isMultistep = component.component === 'Multistep';

  const dynamicData = settings.dynamic_data ?? [];
  const setDynamicData = (dynamic_data) => {
    if (dynamic_data.length > 0) {
      setSettings({ ...settings, dynamic_data });
    } else {
      const newSettings = { ...settings };
      delete newSettings.dynamic_data;
      setSettings(newSettings);
    }
  };

  const repeater = settings.repeater;
  const setRepeater = (repeater) => {
    if (!!repeater) {
      setSettings({ ...settings, repeater });
    } else {
      const newSettings = { ...settings };
      delete newSettings.repeater;
      setSettings(newSettings);
    }
  };
  const isFormDescendant = getAncestryByName(
    component,
    canvas.history[canvas.history.length - 1][0],
    'Form'
  );

  return (
    <>
      {isMultistep
        ? (<MultistepControls
          canvas={canvas}
          component={component}
          setComponent={setComponent} />)
        : (<CustomComponentSettings
          canvas={canvas}
          component={component}
          setComponent={setComponent}
        />)}
      <PFLine />
      <PropertyControls
        component={component}
        setComponent={setComponent}
      />
      {isFormDescendant
        ? (
          <>
            <PFLine />
            <ConditionalVisibilityControls
              component={component}
              setComponent={setComponent}
              formFieldNames={isFormDescendant.attributes.values}
            />
          </>
        ) : null}
      <PFLine />
      <RepeaterEditor
        repeater={repeater}
        setRepeater={setRepeater} />
      <PFLine />
      <DynamicDataSelector
        dynamicData={dynamicData}
        setDynamicData={setDynamicData}
        showCacheable={true} />
    </>
  );
};

ConfigureEditor.propTypes = {
  canvas: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
};


export default ConfigureEditor;
