/* eslint-disable max-len */

const PFIconComponentUrl = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="5.4834" y="17.373" width="44" height="16" rx="3" fill="#332851" />
      <path d="M16.8934 23.863L15.3634 29.373H14.1034L12.7434 25.113L11.3634 29.373H10.1134L8.5834 23.863H9.7334L10.7834 28.323L12.1634 23.863H13.3634L14.7634 28.323L15.8134 23.863H16.8934ZM25.3895 23.863L23.8595 29.373H22.5995L21.2395 25.113L19.8595 29.373H18.6095L17.0795 23.863H18.2295L19.2795 28.323L20.6595 23.863H21.8595L23.2595 28.323L24.3095 23.863H25.3895ZM33.8856 23.863L32.3556 29.373H31.0956L29.7356 25.113L28.3556 29.373H27.1056L25.5756 23.863H26.7256L27.7756 28.323L29.1556 23.863H30.3556L31.7556 28.323L32.8056 23.863H33.8856ZM35.5617 28.113V29.373H34.2717V28.113H35.5617Z" fill="#AD90FF" />
      <line x1="37.6334" y1="18.373" x2="37.6334" y2="32.373" stroke="#BEA7FF" strokeWidth="0.3" />
    </svg>
  );
};

export default PFIconComponentUrl;
