import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { twoapp } from 'PFApis';
import { PFItemCard } from 'PFComponents/cards';
import { PFText } from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import AppCreationModal from './AppCreationModal';

const AppCard = ({ app }) => {
  const navigate = useNavigate();

  const [cloneFormOpen, setCloneFormOpen] = useState(false);

  const isOwner = app.role === 'OWNER';

  const cardTools = {
    'Copy ID': () => generalUtils.copyToClipboard(app._id)
  };

  if (isOwner) {
    cardTools['Clone'] = () => setCloneFormOpen(true);
  }

  const cloneApp = async (values) => {
    if (isOwner) {
      const { InsertedID } = await twoapp.cloneApp(app._id, values);
      navigate(`/apps/${InsertedID}/pages`);
    }
  };

  return (
    <>
      <PFItemCard path={`/apps/${app._id}/pages`}
        options={cardTools}>
        <PFText type="secondary" size="s">{app._id}</PFText>
        <PFText>{app.name}</PFText>
      </PFItemCard>
      <AppCreationModal
        title={`Clone '${app.name}'`}
        callToAction="Clone app"
        active={cloneFormOpen}
        onDismiss={() => setCloneFormOpen(false)}
        onSubmit={cloneApp} />
    </>
  );
};

AppCard.propTypes = {
  app: PropTypes.object.isRequired,
};

export default AppCard;
