import {
  PFButton, PFContainer, PFInput,
  PFSelect, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InputParam from './InputParam';

const AutomationEditor = ({ automation, updateAutomation }) => {
  const currentApp = useSelector((state) => state.current_app);
  const processflows = currentApp.build.process_flows.docs;
  const pfOptions = processflows.map((pf) => ({
    label: pf.alias, value: pf._id
  }));
  const parts = automation?.process_identifier?.split(':');

  const selectedPf = parts ? parts[parts.length - 1] : '';
  const setCron = (cron_expression) => updateAutomation({
    ...automation,
    cron_expression
  });
  const setInputParams = (input_params) => updateAutomation({
    ...automation,
    input_params
  });

  const addInputParam = () => {
    setInputParams([
      ...automation.input_params,
      {
        name: '',
        value: ''
      }]);
  };

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m"
      width='600px'>
      <PFContainer height={30} display="flex" alignItems="center" gap="s">
        <PFText>Automation configuration</PFText>

      </PFContainer>
      <PFContainer display='flex' gap='s' alignItems='center'>
        <PFText color='var(--purple-200)'>Cron Expresion</PFText>
        <PFContainer flex={1}>

          <PFInput value={automation?.cron_expression}
            onChange={(e) => setCron(e.target.value)} />
        </PFContainer>
      </PFContainer>

      <PFContainer>
        <PFContainer display="flex" alignItems="center" gap="s"
          margin='bottom-s'>
          <PFText>Execution variables</PFText>
          <PFText type="support" size="s">
            Available values set for all executions
          </PFText>
        </PFContainer>
        <PFContainer display="grid" gap="s" margin="top-s">
          {
            automation?.input_params.map((inputElement, index) => {
              const setInputName = (name) => {
                const paramsCopy = [...automation.input_params];
                paramsCopy[index] = {
                  ...inputElement,
                  name: name
                };
                setInputParams(paramsCopy);
              };

              const setInputValue = (value) => {
                const paramsCopy = [...automation.input_params];
                paramsCopy[index] = {
                  ...inputElement,
                  value: value
                };
                setInputParams(paramsCopy);
              };

              const deleteInputParam = () => {
                const paramsCopy = [...automation.input_params];
                const newParams = [
                  ...paramsCopy.slice(0, index),
                  ...paramsCopy.slice(index + 1)
                ];
                setInputParams(newParams);
              };

              return (<InputParam key={index} inputName={inputElement.name}
                setInputName={setInputName}
                inputValue={inputElement.value}
                setInputValue={setInputValue}
                deleteInputParam={deleteInputParam} />);
            })
          }

        </PFContainer>
        <PFButton type="support" onClick={addInputParam}
          style={{ justifySelf: 'start' }}>
          <PFText color='var(--purple-200)'>+ add variable</PFText>

        </PFButton>
      </PFContainer>

      <PFContainer display='flex' gap='s'
        alignItems='center'>
        <PFText color='var(--purple-200)'>Process Flow</PFText>
        <PFContainer flex={1}>

          <PFSelect options={pfOptions} value={selectedPf} disabled />
        </PFContainer>
      </PFContainer>
    </PFContainer>

  );
};

AutomationEditor.propTypes = {
  automation: PropTypes.object,
  updateAutomation: PropTypes.func,
};

export default AutomationEditor;
