import { createSlice } from '@reduxjs/toolkit';

const currentAppSlice = createSlice({
  name: 'current_app',
  initialState: null,
  reducers: {
    setCurrentApp: (_state, { payload }) => {
      return payload === null ? null : { ...payload };
    },
    updateApiDeclaration: (state, { payload }) => {
      const { _id, alias } = payload;
      state.build.api_declarations.docs
        = state.build.api_declarations.docs.map((api) => {
          if (api._id === _id) api.alias = alias;
          return api;
        });
      return state;
    },
    addApiDeclaration: (state, { payload }) => {
      state.build.api_declarations.count++;
      state.build.api_declarations.docs.push(payload);
      return state;
    },
    removeApiDeclaration: (state, { payload }) => {
      state.build.api_declarations.count--;
      state.build.api_declarations.docs
        = state.build.api_declarations.docs.filter(
          (api) => api._id !== payload);
      return state;
    },
    updateFlow: (state, { payload }) => {
      const { _id, alias } = payload;
      state.build.process_flows.docs
        = state.build.process_flows.docs.map((flow) => {
          if (flow._id === _id) flow.alias = alias;
          return flow;
        });
      return state;
    },
    removeFlow: (state, { payload }) => {
      state.build.process_flows.count--;
      state.build.process_flows.docs
        = state.build.process_flows.docs.filter((flow) => flow._id !== payload);
      return state;
    },
    addFunction: (state, { payload }) => {
      state.build.associated_functions.count++;
      state.build.associated_functions.docs.push(payload);
      return state;
    },
    removeFunction: (state, { payload }) => {
      state.build.associated_functions.count--;
      state.build.associated_functions.docs
        = state.build.associated_functions.docs.filter(
          (func) => func._id !== payload);
      return state;
    },
    addEnv: (state, { payload }) => {
      state.envs[payload] = {};
      return state;
    },
    removeEnv: (state, { payload }) => {
      delete state.envs[payload];
      return state;
    },
    addDomain: (state, { payload }) => {
      const now = new Date();
      state.prod.push({ domain: payload, last_updated: now.toISOString() });
      return state;
    },
    updateDomain: (state, { payload }) => {
      const { domain, env } = payload;
      state.domains = state.domains.map((domainInfo) => {
        if (domainInfo.domain === domain) domainInfo.env = env;
        return domainInfo;
      });
      return state;
    },
    addPermission: (state, { payload }) => {
      state.permissions.accounts.count++;
      state.permissions.accounts.docs.push(payload);
      return state;
    },
    updatePermission: (state, { payload }) => {
      const { _id, role } = payload;
      state.permissions.accounts.docs
        = state.permissions.accounts.docs.map((acc) => {
          if (acc._id === _id) acc.role = role;
          return acc;
        });
      return state;
    },
    removePermission: (state, { payload: _id }) => {
      state.permissions.accounts.docs
        = state.permissions.accounts.docs.filter((acc) => acc._id !== _id);
      return state;
    },
    addCustomComponent: (state, { payload }) => {
      state.component_library.components.push(payload);
      return state;
    },
    updateCustomComponent: (state, { payload }) => {
      const { _id } = payload;
      state.component_library.components
        = state.component_library.components.map((comp) => {
          if (comp._id === _id) return payload;
          return comp;
        });
      return state;
    },
  }
});

export default currentAppSlice.reducer;
export const {
  setCurrentApp,
  updateApiDeclaration,
  addApiDeclaration,
  removeApiDeclaration,
  updateFlow,
  removeFlow,
  addFunction,
  removeFunction,
  addEnv,
  removeEnv,
  addDomain,
  updateDomain,
  addPermission,
  updatePermission,
  removePermission,
  addCustomComponent,
  updateCustomComponent,
} = currentAppSlice.actions;
