import {
  PFButton,
  PFContainer,
  PFLine,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { EventEditor } from './components';

const InteractEditor = ({ component, setComponent }) => {
  const addEvent = () => {
    const newEvent = {
      event: '',
      actions: null
    };

    const updatedEvents = component.events
      ? [...component.events, newEvent]
      : [newEvent];

    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const eventBlock = (eventIndex) => {
    const eventActions = component.events[eventIndex].actions || [];
    return (
      <EventEditor
        key={`event-${eventIndex}`}
        component={component}
        setComponent={setComponent}
        eventIndex={eventIndex}
        eventActions={eventActions}
      />);
  };

  const createControls = (events) => events.map(
    (event, eventIndex) => eventBlock(eventIndex));

  return (
    <>
      <PFContainer padding='s'>
        <PFText size='header' margin='bottom-s'>Events</PFText>
        <PFLine />
        <PFContainer>
          {
            component.events
            && createControls(component.events).map((control) => control)
          }
          <PFButton
            width='100%'
            type='secondary'
            onClick={addEvent}
            style={{ fontSize: 'var(--text-label)' }}
          >+ add new event</PFButton>
        </PFContainer>
      </PFContainer>
    </>
  );
};

InteractEditor.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
};

export default InteractEditor;
