import { PFContainer } from 'PFComponents/common';
import {
  PFScreenBuilderV2ForgotPassword,
  PFScreenBuilderV2Login, PFScreenBuilderV2Signup
} from 'PFScreens';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';


const PFLayoutGuest = () => {
  const { activeTheme } = useSelector((state) => state.theme);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <Routes>
          <Route path="/" element={<PFScreenBuilderV2Login />} />
          <Route path="/signup/*" element={
            <PFScreenBuilderV2Signup />} />
          <Route path="/forgot-password/*"
            element={<PFScreenBuilderV2ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </PFContainer>
    </PFContainer>
  );
};

export default PFLayoutGuest;
