import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLine,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import store, { userActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


const FunctionCreationModal = ({ active, onDismiss }) => {
  const navigate = useNavigate();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm({
    alias: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a name for your new app',
    }
  });

  const onSubmit = (values) => {
    asyncCall(async () => {
      const { InsertedID } = await builderV2.createFunction(values);
      store.dispatch(userActions.addFunction({ ...values, _id: InsertedID }));
      navigate(`/functions/${InsertedID}/operations`);
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFForm form={form} submit={onSubmit}>
            <PFContainer margin="bottom-m"
              display="flex" alignItems="center" justifyContent="space-between">
              <PFText type="support">Create a new function</PFText>
              <PFButton type="support" onClick={onDismiss}
                padding="none" height={28}
                style={{
                  background: 'var(--purple-50)',
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}>
                <PFIcon icon="bx bx-x" size="l" />
              </PFButton>
            </PFContainer>
            <PFContainer display="flex" flexDirection="column" gap="s">
              <PFInput
                name="alias"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Name
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
            </PFContainer>
            <PFContainer margin="top-l" display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={loading}>
                Create function
              </PFButton>
            </PFContainer>
          </PFForm>
        </PFContainer >
      </PFModal >
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </>
  );
};

FunctionCreationModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default FunctionCreationModal;
