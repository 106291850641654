import {
  PFButton,
  PFCheckbox,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  addParam, CUSTOM_SAVE_TYPE, formConfigFromParams, removeParam,
  saveNode
} from './customSaveUtils';

const NodeCustomSave = ({ node, updateNode, goBack }) => {
  const [nodeName, setNodeName] = useState(node?.alias ?? '');
  const [asyncNode, setAsyncNode] = useState(node?.async ?? false);

  const [form, fieldHook] = useForm(formConfigFromParams(node.params));

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={(values) => {
        saveNode(values, node, nodeName, asyncNode,
          updateNode, goBack);
      }}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)}
              style={{
                color: 'var(--purple-100)'
              }} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={CUSTOM_SAVE_TYPE.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{CUSTOM_SAVE_TYPE.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {CUSTOM_SAVE_TYPE.description}
              </PFText>
            </PFContainer>
          </PFContainer>


          <PFContainer>
            <PFContainer display='flex' justifyContent='flex-end'>
              <PFContainer display='flex' gap='s' alignItems='center'>
                <PFText color='var(--purple-400)' size='s'>
                  ASYNC
                </PFText>
                <PFCheckbox checked={asyncNode}
                  onChange={() => {
                    setAsyncNode((prev) => !prev);
                  }} />
              </PFContainer>
            </PFContainer>
            <PFText margin="bottom-s">Variables</PFText>
            <PFContainer>
              <CustomSaveParams
                params={form.values.params}
                fieldHook={fieldHook}
                removeParam={removeParam}
                form={form} />
              <PFButton
                type="support"
                onClick={() => addParam(form)}
                style={{ justifySelf: 'start' }}>
                + add new variable
              </PFButton>
            </PFContainer>
          </PFContainer>

          <PFButton submit>Save</PFButton>
        </PFContainer>
      </PFForm>
    </PFContainer>
  );
};

const CustomSaveParams = ({ params, fieldHook, removeParam, form }) => {
  return params.map((_, i) => {
    const buttonConfig = {};
    if (i === 0) {
      buttonConfig.disabled = true;
    }

    return (
      <PFContainer key={i} display='flex' gap='s' margin='bottom-xs'>
        <PFInput
          name={`params[${i}].variableName`}
          placeholder="Variable name"
          fieldHook={fieldHook}
          style={{
            fontSize: 'var(--text-size-s)',
            width: '160px'
          }}
          width='auto' />

        <PFInput
          name={`params[${i}].value`}
          placeholder="Value"
          fieldHook={fieldHook}
          style={{
            fontSize: 'var(--text-size-s)',
            width: '160px'
          }} />

        <PFContainer display='block'>
          <PFButton
            type="support"
            onClick={() => removeParam(form, i)}
            {...buttonConfig}>
            <PFIcon icon="bx bx-trash" />
          </PFButton>
        </PFContainer>

      </PFContainer>
    );
  });
};

NodeCustomSave.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeCustomSave;
