import axios from 'axios';
import { twoappOrigin } from 'PFConfig/env';
import { storageUtils } from 'PFUtils';

const twoapp = axios.create({
  baseURL: twoappOrigin + '/twoapp/v1'
});

const jwt = storageUtils.retrieveFromLocalStorage('access_token');

twoapp.interceptors.request.use(
  async (config) => {
    const jwt = storageUtils.retrieveFromLocalStorage('access_token');
    if (jwt) config.headers.set('Authorization', 'Bearer ' + jwt);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

twoapp.interceptors.response.use(
  async (res) => {
    return res?.data?.data;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err.response?.data?.data ?? err.message);
  }
);

export default {
  // APPS
  createApp: async (appData) =>
    await twoapp.post(`/apps/`, appData),
  cloneApp: async (appId, appData) =>
    await twoapp.post(`/apps/${appId}/clone`, appData),
  // Chronos
  getJobs: async (appId) =>
    await twoapp.get(`/chrono/${appId}/job?include_inactive=true`,
      {
        headers: {
          'Authorization': 'Bearer ' + jwt
        }
      }
    ),
  createJob: async (appId, jobData) =>
    await twoapp.post(`/chrono/${appId}/job`, jobData, {
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    }),
  activateJob: async (appId, jobId) =>
    await twoapp.patch(`/chrono/${appId}/job/${jobId}/activate`, {}, {
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    }),
  deactivateJob: async (appId, jobId) =>
    await twoapp.patch(`/chrono/${appId}/job/${jobId}/deactivate`, {}, {
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    }),
  updateJob: async (appId, jobId, jobData) =>
    await twoapp.put(`/chrono/${appId}/job/${jobId}`, jobData, {
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    }),
  // PF TEST
  inputParameters: async (flowId, domain, sessionId) =>
    await twoapp.get(`/flow-executor/${flowId}`, {
      headers: {
        'Domain': domain,
        'x-session-id': sessionId
      }
    }),
  testFlow: async (flowId, flowData, domain, sessionId) =>
    await twoapp.post(`/flow-executor/${flowId}`, flowData, {
      headers: {
        'domain': domain,
        'x-session-id': sessionId
      }
    }),
  testIntegration: async (appId, integrationId, integrationData
    , domain, sessionId) =>
    await twoapp
      .post(
        `/apps/${appId}/integration-executor/${integrationId}`
        , integrationData, {
        headers: {
          'domain': domain,
          'x-session-id': sessionId
        }
      })
};
