import React, { ReactNode, useState } from 'react';
import * as Styled from './readmore.styled';

export type ReadmoreProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode | ReactNode[];

    /**
     * placeholder for external stylesheets
     */
    className?: string;

    /**
     * for component level styling override (Design System)
     * @config implementation required
     */
    configStyles?: string;

    /**
     * Minimum height of the content that will be displayed
     */
    minHeight: string;

    /**
     * Text to be shown in the anchor tag to show less text
     */
    readLessText: string;

    /**
     * Text to be shown in the anchor tag to show more text
     */
    readMoreText: string;
};

export function Readmore({
    children,
    className,
    configStyles,
    minHeight,
    readLessText,
    readMoreText,
    ...props
}: ReadmoreProps) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Styled.Readmore className={className} configStyles={configStyles} {...props}>
            <Styled.Content minHeight={isExpanded ? '100%' : minHeight}>{children}</Styled.Content>
            <Styled.Button onClick={handleExpand}>{isExpanded ? readLessText : readMoreText}</Styled.Button>
        </Styled.Readmore>
    );
}
