import { useGridApiRef } from '@mui/x-data-grid';
import { PFContainer } from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';
import {
  TableEditor,
  TableEditorFooter,
  TableEditorHeader
} from './components';
import { ExitModeModal } from './components/TableEditor/components';
import { addRowLocally } from './utils';

const PFScreenTableEditor = () => {
  const { db_id, table_name } = useParams();
  const apiRef = useGridApiRef();
  const currentDB = useSelector((state) => state.current_db);
  const table = currentDB.tables.find((t) => t.table_name === table_name);
  const currentUserId = useSelector((state) => state.user._id);
  const [updateTable, setUpdateTable] = useState(false);
  const [values, setValues] = useState([]);
  const [models, setModels] = useState({});
  const [selection, setSelection] = useState([]);
  const [exitModeWarning, setExitModeWarning] = useState(false);
  const [fetch, deletion, alerts] = useAsyncCalls(true, false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (Object.keys(apiRef.current.state.editRows).length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const fetchRows = () => {
    fetch.asyncCall(async () => {
      const newRows = await builderTest(currentUserId)
        .getDBTableRecords(db_id, table_name);
      setValues(newRows);
    });
  };

  const deleteRows = async (...ids) => {
    deletion.asyncCall(async () => {
      if (ids.length === 1) {
        const id = ids[0];
        const rowToDelete = values.find((row) => row.ctrl_index === id);
        await builderTest(currentUserId)
          .deleteDBTableRecord(db_id, table_name, rowToDelete.id);

        setValues((values.filter((row) => row.ctrl_index !== id))
          .map((el, newIndex) => ({
            ...el,
            ctrl_index: newIndex
          })));
      } else if (ids.length > 1) {
        const idsToDelete = values
          .filter((row) => ids.includes(row.ctrl_index))
          .map((row) => row.id);
        await builderTest(currentUserId)
          .deleteDBTableRecords(db_id, table_name, idsToDelete);

        setValues((values.filter((row) => !ids.includes(row.ctrl_index)))
          .map((el, newIndex) => ({
            ...el,
            ctrl_index: newIndex
          })));
      }
    });
  };

  useEffect(() => {
    setValues([]);
    fetchRows();
  }, [db_id, table_name, updateTable]);

  const rows = {
    values, setValues,
    models, setModels,
    selection, setSelection,
    deleteRow: (id) => deleteRows(id)
  };

  return (
    <>
      <TableEditorHeader
        dbId={db_id}
        tables={currentDB.tables}
        currentTable={table_name}
        fetchRows={fetchRows}
        exportData={(options) => apiRef.current.exportDataAsCsv(options)}
        selectedRows={selection}
        deleteSelectedRows={() => deleteRows(...selection)}
        addRow={() => {
          if (Object.keys(apiRef.current.state.editRows).length > 0) {
            setExitModeWarning(true);
          } else {
            const numberOfRows = apiRef.current.getRowsCount();
            const rowsPerPage = 100;
            apiRef.current.setPageSize(rowsPerPage);
            let pageIndex;
            if (!numberOfRows || numberOfRows === 0) {
              pageIndex = 0;
            } else {
              pageIndex = Math.floor((numberOfRows - 1) / rowsPerPage) + 1;
            }
            apiRef.current.setPage(pageIndex);
            addRowLocally(table, rows);
          }
        }}
        apiRef={apiRef} setExitModeWarning={setExitModeWarning} />
      <PFContainer height="calc(100vh - 100px)" type="toolbar"
      >
        {!!table
          ? <TableEditor
            apiRef={apiRef}
            dbId={currentDB._id}
            tableConfig={table}
            rows={rows}
            setUpdateTable={setUpdateTable}
            loading={fetch.loading || deletion.loading}
            setExitModeWarning={setExitModeWarning} />
          : null}
      </PFContainer>
      <TableEditorFooter
        dbId={db_id}
        tables={currentDB.tables}
        currentTable={table_name}
        apiRef={apiRef} setExitModeWarning={setExitModeWarning} />
      <ExitModeModal open={exitModeWarning} close={() => {
        setExitModeWarning(false);
      }} />
      {alerts()}
    </>
  );
};

export default PFScreenTableEditor;
