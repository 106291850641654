import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 6 });

const NODE_TYPE_UI_ACTION_OPTIONS = [
  {
    name: 'Navigate',
    type: 'ACTION',
    description: 'Navigate your application!',
    icon: 'bx bx-subdirectory-right',
    config: {
      prompt: 'Path',
      error_message: 'Please provide a path to navigate to'
    },
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        type: 'ACTION',
        alias: 'Navigate node',
        next: next || null,
        action: 'navigate',
        params: ''
      };
    },
  },
  {
    name: 'Redirect',
    type: 'ACTION',
    description: 'Take your users somewhere else',
    icon: 'bx bx-arrow-from-left',
    config: {
      prompt: 'URL',
      error_message: 'Please provide a URL to redirect to'
    },
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        type: 'ACTION',
        alias: 'Redirect node',
        next: next || null,
        action: 'redirect',
        params: ''
      };
    },
  },

  {
    name: 'Change step',
    type: 'CHANGE_STEP',
    description: 'Change the current step in a multistep form',
    icon: 'bx bx-walk',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        type: 'CHANGE_STEP',
        alias: 'Change step node',
        next: next || null,
        multistep: '',
        step: '',
      };
    },
  },
  {
    name: 'Error',
    type: 'ACTION',
    description: 'Notify an error :(',
    icon: 'bx bx-error',
    config: {
      prompt: 'Error',
      error_message: 'Please provide an error to display'
    },
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        type: 'ACTION',
        alias: 'Error node',
        next: next || null,
        action: 'error',
        params: ''
      };
    },
  }
];

export default NODE_TYPE_UI_ACTION_OPTIONS;
