import { Snackbar, SnackbarContent } from '@mui/material';
import PropTypes from 'prop-types';

const ExitModeModal = ({ open, close }) => {
  return (<Snackbar open={open} ContentProps={{
    classes: {
      root: {
        background: 'red'
      }
    }
  }}
    autoHideDuration={3000} onClose={(e, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      close();
    }} >
    <SnackbarContent style={{
      backgroundColor: 'var(--purple-200)',
    }}
      message={<span id="client-snackbar">
        You are currently editing a row, finish it first.
      </span>}
    />
  </Snackbar>);
};

ExitModeModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func
};

export default ExitModeModal;
