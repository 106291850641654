import { PFContainer, PFIcon, PFText } from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import curly from '../../assets/curly.svg';

const PFFunctionOperationEditor = ({ variableName, variableOrigin }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div
      className='var_item'
      style={{
        padding: 'var(--size-s)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 'var(--size-m)',
        borderRadius: 'var(--radius-s)',
        cursor: 'pointer',
        overflow: 'hidden'
      }}
      onClick={() => {
        generalUtils.copyToClipboard('${' + variableName + '}');
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}>
      <PFContainer
        background='#2C263E'
        radius='s'
        width='fit-content'
        padding='xs'>
        <img width={'20px'} src={curly} />
      </PFContainer>
      <PFContainer className='pf-scroll' flex={1}
        style={{
          overflow: 'auto',
          maxWidth: '100px'
        }}>
        <PFText size='s' color='#fff'>{variableName}</PFText>
        <PFText size='xs' color={'var(--gray-100)'}>
          {variableOrigin}
        </PFText>

      </PFContainer>
      <PFIcon
        icon={`bx bx-${copied ? 'check' : 'copy'}`}
        color={'var(--gray-100)'}
        size='m' />
    </div>
  );
};

PFFunctionOperationEditor.propTypes = {
  variableName: PropTypes.string,
  variableOrigin: PropTypes.string,
};

export default PFFunctionOperationEditor;
