import React, { ReactNode, useState } from 'react';
import * as Styled from './text.styled';
export type TextProps = {
    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode;
    /**
     * classes for external library implementations
     */
    className?: string;
    /**
     * for component level styling override (Design System)
     * @config implementation required
     */
    configStyles?: string;
    /**
     * To identify the Text component
     */
    id?: string;
    /**
     * semantic and regular text types
     */
    type?: 'bold' | 'deleted' | 'emphasis' | 'italic' | 'small' | 'span' | 'strong' | 'subscript' | 'superscript';
    /**
     * a node to render wrapless text value
     */
    value?: string;
    onBlur?: (e: any) => {};
    /**
     * a property to know if the rendered text is editable
     */
    isTextEditable?: boolean;
    /**
     * property that is passed to the component to know if it is inside a drag n drop enviroment
     */
    isDragDrop?: boolean;
    /**
     * used to ser higher context value of the compoennt
     */
    setValue?: (e) => {};
};
const TextSemanticTypes: Record<string, React.ElementType> = {
    bold: 'b',
    deleted: 'del',
    emphasis: 'em',
    italic: 'i',
    small: 'small',
    span: 'span',
    strong: 'strong',
    subscript: 'sub',
    superscript: 'sup',
};
function TextElement(textProps: TextProps) {
    const {
        children,
        className,
        configStyles = '',
        id,
        type = '',
        value = '',
        onBlur = (e) => {},
        isTextEditable,
        setValue = (e) => {},
        ...props
    } = textProps;
    const [isEditable, setIsEditable] = useState<boolean>(false);

    if (props['draggable'] !== undefined && isTextEditable) {
        const handleDoubleCick = () => {
            setIsEditable(true);
        };
        const handleBlur = (e: { target: { value: React.SetStateAction<string> } }) => {
            setIsEditable(false);
            onBlur(e.target.value);
        };
        const handleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
            setValue(`${e.target.value.toString()}`);
        };
        const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
            e.target.select();
        };
        return (
            <Styled.EditableElement
                id={id}
                as={TextSemanticTypes[type]}
                className={className}
                onDoubleClick={handleDoubleCick}
            >
                {isEditable ? (
                    <Styled.TextInput
                        rows={1}
                        defaultValue={value}
                        onBlur={handleBlur}
                        onFocus={(e) => handleFocus(e)}
                        onKeyUp={(e: any) => {
                            e.preventDefault();
                            handleChange(e);
                        }}
                        autoFocus
                    />
                ) : (
                    <>{value}</>
                )}
            </Styled.EditableElement>
        );
    } else {
        if (TextSemanticTypes.hasOwnProperty(type)) {
            return (
                <Styled.Element
                    as={TextSemanticTypes[type]}
                    className={className}
                    configStyles={configStyles}
                    id={id}
                    {...props}
                >
                    {children}
                </Styled.Element>
            );
        } else {
            return <>{value}</>;
        }
    }
}
export function Text(textProps: TextProps) {
    return <TextElement {...textProps} />;
}
