import { PefaiComponent } from 'PFAssets';
import { dnd } from 'PFUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Portal } from 'react-portal';
import ShortUniqueId from 'short-unique-id';
import { findComponentById } from '../../utils';
import { ComponentMenu } from './components';
import ListWrapper from './ListWrapper';

const uid = new ShortUniqueId({ length: 6 });

const Wrapper = ({
  canvas,
  component,
  setComponent,
  tag,
  componentChildren,
  setComponentChildren,
  addComponent,
  removeComponent,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { build, component: name, attributes } = component;

  const RenderableComponent = PefaiComponent[name];

  if (!RenderableComponent) return null;

  const selected = component.build.id === canvas.selected?.id;

  const createCopy = () => {
    return { ...component, build: { ...build, id: name + '-' + uid() } };
  };

  const props = {
    'component': name,
    ...attributes,
    'configStyles': {
      ...attributes.configStyles,
      ...(isDragging && {
        opacity: 0.2,
      })
    },
    // * Builder specific props
    // ToDo: id should be an editable property, decide if
    // build.id or regular id
    'id': build.id,
    'data-alias': build.alias,
    'data-tag': tag,
    'draggable': true,
    // This is the natural property of the Text component
    // to edit it's value prop, same as the convention
    // for form fieldds (Formik's setValue(value))
    'setValue': (value) => {
      const componentUpdatedValue = {
        ...component,
        attributes: {
          ...component.attributes,
          value
        }
      };
      setComponent(componentUpdatedValue);
    },
    'onDragStart': (e) => {
      e.stopPropagation();
      canvas.setDragging(createCopy());
      setIsDragging(true);
    },
    'onDragEnd': (e) => {
      e.stopPropagation();
      canvas.setDropZone(null);
      canvas.setDragging(null);
      canvas.setSelected(document.getElementById(canvas.lastElementAdded));
      setIsDragging(false);
      if (e.dataTransfer.dropEffect === 'none') {
        setIsDragging(false);
      } else {
        const dropTarget = canvas.dropZone?.target;
        if (dropTarget) {
          if (dropTarget.id !== component.build.id) {
            removeComponent();
          }
        }
      }
    },
    'onDragOver': (e) => {
      let targetChild;
      e.preventDefault();
      e.stopPropagation();
      const dropZone = dnd.getDropZone(component, e);
      if (canvas.hover) {
        const draggedComponent = findComponentById(
          canvas.history[canvas.history.length - 1][0],
          canvas.hover.id);
        if (draggedComponent) {
          targetChild = findComponentById(
            draggedComponent,
            dropZone.target.id
          );
        }
      }

      if (!targetChild) {
        const dropZone = dnd.getDropZone(component, e);
        canvas.setDropZone(dropZone);
      } else {
        canvas.setDropZone(null);
      }
    },
    'onDrop': (e) => {
      e.preventDefault();
      e.stopPropagation();
      const dropZone = dnd.getDropZone(component, e);
      const componentToAdd = canvas.dragging;
      const targetChild = findComponentById(
        componentToAdd,
        dropZone.target.id
      );
      const position = dnd.getDropPosition(dropZone);

      canvas.setLastElementAdded(canvas.dragging.build.id);
      if (componentToAdd.id !== component.id && !targetChild) {
        addComponent[position](componentToAdd);
      }
    },
  };

  return (
    <>
      <RenderableComponent {...props}>
        <ListWrapper
          canvas={canvas}
          components={componentChildren}
          setComponents={setComponentChildren} />
      </RenderableComponent>
      {
        document
          && document.getElementById('pefai-component-menu')
          ? <Portal node={document
            && document.getElementById('pefai-component-menu')}>
            <ComponentMenu
              canvas={canvas}
              selected={selected}
              component={component}
              setComponent={setComponent} />
          </Portal>
          : null
      }
    </>
  );
};

Wrapper.propTypes = {
  canvas: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  tag: PropTypes.string,
  componentChildren: PropTypes.array,
  setComponentChildren: PropTypes.func.isRequired,
  addComponent: PropTypes.object.isRequired,
  removeComponent: PropTypes.func.isRequired,
};

export default Wrapper;

