import { TextareaAutosize } from '@mui/material';
import { componentUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import './PFInputAutosize.scss';

/**
 * Standard input component of the PFComponents library. This input complies
 * with the Pefai style guidelines. The underlying implementation of this input
 * is the React *input* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFFileInput properties.
 * @param {string} [props.label] - Text to be displayed above the input.
 * @param {string} [props.name] - Name of the input (required if associated to
 * a 'useForm' hook).
 * @param {string} [props.placeholder] - Placeholder to be displayed inside the
 * input.
 * @param {func} [props.fieldHook] - Function provided by the 'useForm' hook to
 * manage the content of a field.
 * @param {string} [props.className] - Class name to be applied to the file
 * input.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the input.
 * @param {any} [props.value] - Current value of the input.
 * @param {string} [props.error] - Current error of the input value.
 * @param {func} [props.onChange] - Function to be called when the value of the
 * input changes (not necessary if fieldHook is present).
 * @param {func} [props.onInput] - Function to be called when the element
 * gets input.
 * @param {func} [props.onKeyDown] - Function to be called when the value of the
 * input changes (not necessary if fieldHook is present).
 * @param {func} [props.onBlur] - Function to be called when the file input
 * calls 'onBlur'.
 * @param {number} [props.rows=3] - Determines the number of initial rows
 * @param {boolean} [props.disabled=false] - Determines if the input is
 * currently disabled.
 * @param {string} [props.fontSize] - Determines the size of the font inside
 * the input.
 * @param {bool} [props.autoFocus=false] - Makes the input focus after it has
 * been rendered.
 * @param {string|number} [props.width] - Determines the width of the input
 * container.
 * @param {string} [props.margin] - Determines the margin config of the file
 * input component.
 * @param {React.ReactNode} [props.leftContent] - Content to be diplayed to the
 * left of the input.
 * @param {React.ReactNode} [props.rightContent] - Content to be diplayed to the
 * right of the input.
 *
 * @return {React.ReactElement} The input component.
 *
 */
const PFInputAutosize = ({
  label,
  name,
  placeholder,
  fieldHook,
  className,
  style,
  value,
  error,
  onChange,
  onInput,
  onKeyDown,
  onBlur,
  disabled,
  fontSize,
  autoFocus,
  width,
  margin,
  leftContent,
  rightContent,
}) => {
  const hook = fieldHook ? fieldHook(name) : {};

  const fieldValue = hook.value ?? value;
  const fieldError = hook.error ?? error;
  const fieldUpdate = hook.updateAndValidate
    ? (e) => hook.updateAndValidate(e.target.value)
    : null;

  const containerConfigStyle
    = componentUtils.getStylesFromConfig({ margin });
  const containerStyle = { width, ...containerConfigStyle };

  const inputConfigStyle
    = componentUtils.getStylesFromConfig({ font: fontSize });
  const inputContainerStyle = { ...style, ...inputConfigStyle };

  return (
    <div
      className={`pf inputauto-container ${fieldError ? 'error' : ''}`}
      style={containerStyle}
    >
      {label
        ? <label className={`pf inputauto-label`}>{label}</label>
        : null
      }
      <div className={`pf inputauto-wrapper ${className} 
       ${fieldError ? 'error' : ''}`}>
        {leftContent}
        <TextareaAutosize
          className={`pf inputauto ${className} 
          ${fieldError ? 'error' : ''}`}
          style={inputContainerStyle}
          name={name}
          placeholder={placeholder}
          value={fieldValue}
          onChange={onChange ?? fieldUpdate}
          onKeyDown={onKeyDown}
          onInput={onInput}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {rightContent}
      </div>
      {fieldError
        ? <label className={`pf inputauto-error`}>{fieldError}</label>
        : null
      }
    </div>
  );
};

PFInputAutosize.defaultProps = {
  label: null,
  placeholder: '',
  style: {},
  disabled: false,
  error: null,
  autoFocus: false,
};

PFInputAutosize.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fieldHook: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInput: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fontSize: PropTypes.string,
  autoFocus: PropTypes.bool,
  margin: PropTypes.string,
  leftContent: PropTypes.any,
  rightContent: PropTypes.any,
};

export default PFInputAutosize;
