import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { componentUtils } from 'PFUtils';
import PropTypes from 'prop-types';
/* eslint-disable */

/**
 * Standard switch component of the PFComponents library. This switch
 * complies with the Pefai style guidelines. The underlying implementation of
 * this switch is the MaterialUI *switch* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFSwitch properties.
 * @param {boolean} [props.checked] - Determines if the switch is currently
 * checked.
 * @param {func} [props.onChange] - Action to be called when the switch
 * changes.
 * @param {string} [props.margin] - Determines the margin config of the switch
 * component.
 *
 * @return {React.ReactElement} The switch component.
 *
 */
const PFSwitch = ({
  checked,
  onChange,
  margin,
}) => {
  const configStyle = componentUtils.getStylesFromConfig({ margin });

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? 'var(--purple-100)' : 'var(--purple-100)',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: 'var(--purple-100)',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));


  return (
    <IOSSwitch
      disableRipple
      checked={checked}
      onChange={onChange}
      sx={{
        'color': 'var(--secondary)',
        '&.Mui-checked': {
          color: 'var(--secondary)',
        },
        'padding': 0,
        'margin': configStyle.margin
      }}
    />
  );
};

PFSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  margin: PropTypes.string,
};

export default PFSwitch;
