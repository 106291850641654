import React, { ReactNode } from 'react';
import * as Styled from './simplecheckbox.styled';

export type SimplecheckboxProps = {
    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;

    /**
     * class names overrides
     */
    className?: string;

    /**
     * styling overrides
     */
    configStyles?: string | undefined;

    /**
     * to disable the checkbox element
     */
    disabled?: boolean;

    /**
     * Identifies the input[checkbox] semantic element
     */
    id: string;

    /**
     * Specifies the name of the checkbox element
     */
    name: string;

    /**
     * onChange event handler
     */
    onChange?: any;

    /**
     * field value
     */
    value: string;
};

export function Simplecheckbox({
    children,
    className,
    configStyles,
    disabled,
    id,
    name,
    onChange,
    value,
    ...props
}: SimplecheckboxProps) {
    return (
        <Styled.Checkbox
            className={className}
            configStyles={configStyles}
            defaultChecked={value === 'true'}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            value={value}
            {...props}
        />
    );
}
