import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFContainer
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DictionaryHeader,
  DictionaryPrompt,
  DictionaryTable
} from './components';
import './PFVariablesDictionary.css';

/**
 * PFVariablesDictionary component to manage forms,
 * processflows and page url variables
 *
 * @memberof module:PFComponents
 *
 * @param {Function} [props.closeDictionary={}] - Method to close
 * the dictionary modal
 *
 * @return {React.ReactElement} The VariablesDictionary component.
 *
 * @author Abel Guitron <abel@pefai.com>
 */

const PFVariablesDictionary = ({
  closeDictionary, setCopiedAlert
}) => {
  const currentApp = useSelector((state) => state.current_app);
  const [currentTab, setCurrentTab] = useState('form_vars');
  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);
  const [catalogVars, setCatalogVars] = useState([]);
  const [flowPrefix, setFlowPrefix] = useState(null);
  const [nodeType, setNodeType] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [copyOptions, setCopyOptions] = useState([]);
  const [promptInfo, setPromptInfo] = useState({});
  const [copyAsyncCall, copyLoading,
    copyError, dismissCopyError] = useAsyncCall(true);
  useEffect(() => {
    asyncCall(async () => {
      const variablesCatalog = await builderV2.
        getVariablesCatalog(currentApp._id, currentTab);
      setCatalogVars(variablesCatalog);
    });
  }, [currentTab]);
  return (
    <article onClick={() => {
      closeDictionary();
    }} style={{
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      zIndex: '100'
    }} >
      <div style={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
        onClick={(e) => {
          e.stopPropagation();
        }}>

        {
          flowPrefix
            ? <DictionaryPrompt
              flowPrefix={flowPrefix}
              nodeType={nodeType}
              setCopiedAlert={setCopiedAlert}
              closeDictionary={closeDictionary}
              copyOptions={copyOptions}
              setFlowPrefix={setFlowPrefix}
              promptInfo={promptInfo}
              setPromptInfo={setPromptInfo}
              setCopyOptions={setCopyOptions}
              copyLoading={copyLoading} />
            : <PFContainer background={'var(--purple-500)'}
              width='572px' radius='m' border='1px solid var(--purple-300)'
              style={{ overflow: 'hidden' }}>

              <DictionaryHeader
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                closeDictionary={closeDictionary} />

              <DictionaryTable
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                loading={loading}
                catalogVars={catalogVars}
                setCatalogVars={setCatalogVars}
                setFlowPrefix={setFlowPrefix}
                setNodeType={setNodeType}
                setCopiedAlert={setCopiedAlert}
                filterValue={filterValue}
                closeDictionary={closeDictionary}
                setCopyOptions={setCopyOptions}
                setPromptInfo={setPromptInfo}
                asyncCall={copyAsyncCall} />
            </PFContainer>
        }
      </div>

      <PFAlert message={error} open={!!error} onClose={dismissError} />
      <PFAlert message={copyError} open={!!copyError}
        onClose={dismissCopyError} />
    </article>
  );
};

PFVariablesDictionary.propTypes = {
  closeDictionary: PropTypes.func,
  setCopiedAlert: PropTypes.func,
};

export default PFVariablesDictionary;
