import PropTypes from 'prop-types';

import { componentUtils } from 'PFUtils';
import pefaiIcons from './pefai-icons';

/**
 * Standard icon component of the PFComponents library. This icon complies
 * with the Pefai style guidelines. The underlying implementation of this
 * file input is the React *i* component along with boxicons® css.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFIcon properties.
 * @param {string} props.icon - Class name identifier for the icon.
 * @param {string} [props.className] - Class name to be applied to the icon.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the icon.
 * @param {Object} [props.size] - Font size of the icon.
 * @param {Object} [props.color] - Color of the icon font.
 * @param {string} [props.margin] - Determines the margin config of the icon
 * component.
 *
 * @return {React.ReactElement} The icon component.
 *
 * @author Andres Barragan  <andres@pefai.com>
 */
const PFIcon = ({
  icon,
  className,
  style,
  size,
  color,
  margin,
}) => {
  const iconClassName = `pf icon ${icon} text ${className}`;

  const configStyle = componentUtils.getStylesFromConfig({
    margin, font: size
  });
  const iconStyle = { ...configStyle, color, ...style };

  if (icon.startsWith('pfi')) {
    const Icon = pefaiIcons[icon];
    return <Icon />;
  };

  return (
    <i
      className={iconClassName}
      style={iconStyle}
    />
  );
};

PFIcon.defaultProps = {
  className: '',
  style: {},
};

PFIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
};

export default PFIcon;
