import { useEffect, useRef } from 'react';

function useFocusOnRender(hasFocus) {
  const ref = useRef();

  useEffect(() => {
    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  return ref;
}

export default useFocusOnRender;
