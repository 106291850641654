import FIELDS_NAMES from './FIELDS_NAMES';
const { NULLABLE, UNIQUE,
  LENGTH, SCALE, INDEX,
} = FIELDS_NAMES;

export default {
  'String': {
    type_name: 'String',
    data_type: 'VARCHAR',
    [NULLABLE]: 'true',
    [LENGTH]: 100,
    [UNIQUE]: 'false',
    [INDEX]: 'false'
  },
  'Integer': {
    type_name: 'Integer',
    data_type: 'INTEGER',
    [NULLABLE]: 'true',
    [UNIQUE]: 'false',
    [INDEX]: 'false',
  },
  'Big Integer': {
    type_name: 'Big Integer',
    data_type: 'BIGINTEGER',
    [NULLABLE]: 'true',
    [UNIQUE]: 'false',
    [INDEX]: 'false',
  },
  'Decimal': {
    type_name: 'Decimal',
    data_type: 'DECIMAL',
    [NULLABLE]: 'true',
    [LENGTH]: 16,
    [SCALE]: 4,
    [UNIQUE]: 'false',
    [INDEX]: 'false',

  },
  'Float': {
    type_name: 'Float',
    data_type: 'FLOAT',
    [NULLABLE]: 'true',
    [UNIQUE]: 'false',
    [INDEX]: 'false',

  },
  'Date': {
    type_name: 'Date',
    data_type: 'DATE',
    [NULLABLE]: 'true',
    [UNIQUE]: 'false',
    [INDEX]: 'false',
  },
  'Date & Time': {
    type_name: 'Date & Time',
    data_type: 'DATETIME',
    [NULLABLE]: 'true',
    [UNIQUE]: 'false',
    [INDEX]: 'false',
  },

  'Boolean': {
    type_name: 'Boolean',
    data_type: 'BOOLEAN',
    [NULLABLE]: 'true',
    [INDEX]: 'false',
  },
  'Money': {
    type_name: 'Money',
    data_type: 'MONEY',
    [NULLABLE]: 'true',
    [UNIQUE]: 'false',
  },
  'Auto Increment': {
    type_name: 'Auto Increment',
    data_type: 'BIGINTEGER',
    generation_strategy: 'IDENTITY'
  }
};
