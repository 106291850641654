/* eslint-disable max-len */

const PFIconComponentText = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect opacity="0.3" x="7.00709" y="12.1663" width="41.3393" height="26.3393" rx="2.16967" stroke="#AD91FF" strokeWidth="1.66066" strokeLinecap="round" strokeDasharray="6 6" />
      <path d="M22.2334 20.8745V21.8825H20.4784V27.2285H19.2094V21.8825H17.4634V20.8745H22.2334ZM27.621 24.5465C27.621 24.6725 27.606 24.8165 27.576 24.9785H23.922C23.94 25.4225 24.06 25.7495 24.282 25.9595C24.504 26.1695 24.78 26.2745 25.11 26.2745C25.404 26.2745 25.647 26.2025 25.839 26.0585C26.037 25.9145 26.163 25.7195 26.217 25.4735H27.558C27.492 25.8215 27.351 26.1335 27.135 26.4095C26.919 26.6855 26.64 26.9015 26.298 27.0575C25.962 27.2135 25.587 27.2915 25.173 27.2915C24.687 27.2915 24.255 27.1895 23.877 26.9855C23.499 26.7755 23.205 26.4785 22.995 26.0945C22.785 25.7105 22.68 25.2575 22.68 24.7355C22.68 24.2135 22.785 23.7605 22.995 23.3765C23.205 22.9865 23.499 22.6895 23.877 22.4855C24.255 22.2815 24.687 22.1795 25.173 22.1795C25.665 22.1795 26.094 22.2815 26.46 22.4855C26.832 22.6895 27.117 22.9715 27.315 23.3315C27.519 23.6855 27.621 24.0905 27.621 24.5465ZM26.343 24.4205C26.361 24.0125 26.253 23.7035 26.019 23.4935C25.791 23.2835 25.509 23.1785 25.173 23.1785C24.831 23.1785 24.543 23.2835 24.309 23.4935C24.075 23.7035 23.946 24.0125 23.922 24.4205H26.343ZM31.5548 27.2285L30.4208 25.6265L29.4668 27.2285H28.0718L29.7188 24.6275L28.0358 22.2425H29.4578L30.5648 23.8175L31.5188 22.2425H32.9138L31.2398 24.7715L32.9768 27.2285H31.5548ZM36.2922 26.1395V27.2285H35.6262C35.0682 27.2285 34.6392 27.0935 34.3392 26.8235C34.0452 26.5535 33.8982 26.1035 33.8982 25.4735V23.3135H33.2052V22.2425H33.8982V21.0095H35.1672V22.2425H36.2832V23.3135H35.1672V25.4915C35.1672 25.7315 35.2152 25.8995 35.3112 25.9955C35.4072 26.0915 35.5692 26.1395 35.7972 26.1395H36.2922Z" fill="#AD90FF" />
    </svg>
  );
};

export default PFIconComponentText;
