export const handleNodeTypes = (data) => {
  let requestBody;
  let responseBody;

  switch (data.node_type) {
    case 'API_CALL':
      requestBody = {
        ...data.request.params
      };
      responseBody = {
        ...data.response.api_response
      };
      break;
    case 'FUNCTION_CALL':
      requestBody = {
        ...data.request.params
      };
      responseBody = {
        ...data.response.function_response.response
      };
      break;
    case 'CONDITIONAL_SWITCH':
      requestBody = data.request.conditionals;
      responseBody = {
        case: data.response.case
      };
      break;
    case 'CONDITIONAL_IFELSE':
      requestBody = data.request.conditionals;
      responseBody = {
        case: data.response.case
      };
      break;
    case 'ACTION':
      if (data.request.log_type === 'error_request') {
        requestBody = {
          message: data.request.message
        };
      } else if (data.request.log_type === 'redirect_request') {
        requestBody = {
          url: data.request.url
        };
      } else {
        requestBody = {
          path: data.request.path
        };
      }
      responseBody = {
        success: data.response.success
      };
      break;
    case 'CHANGE_STEP':
      requestBody = {
        multi_step_id: data.request.multi_step_id,
        path: data.request.path,
        step: data.request.step
      };
      responseBody = {
        success: data.response.success
      };
      break;
    case 'SAVE_IN_SESSION':
      requestBody = null;
      responseBody = 'SAVED';
      break;
    case 'REMOVE_SESSION':
    case 'AUTH':
      requestBody = null;
      responseBody = {
        success: data.response.success
      };
      break;

    default:
      break;
  }

  return {
    request: {
      body: requestBody
    },
    response: {
      body: responseBody
    }
  };
};

export function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  }

  return value === null || value === undefined;
}
