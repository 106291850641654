import {
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import { useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const CONDITIONAL_SWITCH_TYPE = PROCESS_FLOW.NODE_TYPE_CONDITIONAL_OPTIONS
  .find((n) => n.type === 'CONDITIONAL_SWITCH');

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit a switch node
 *
 * @param {Object} props - NodeSwitch props.
 * @param {ProcessFlowNode} props.node - CONDITIONAL_SWITCH type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Conditional node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeSwitch = ({ node, updateNode, goBack }) => {
  const [nodeName, setNodeName] = useState(node?.alias ?? '');

  const [form, fieldHook] = useForm({
    conditionals: node.conditionals,
    default: node.default,
  });

  const addScenario = () => {
    const newConfig = form.values;
    newConfig.conditionals.push({ expression: '', match_node: null });
    form.setConfig(newConfig);
  };

  const removeScenario = (index) => {
    const newConfig = form.values;
    newConfig.conditionals.splice(index, 1);
    form.setConfig(newConfig);
  };

  const saveNode = (values) => {
    const updatedNode = { ...node };
    updatedNode.alias = nodeName ?? 'Conditional node';
    updatedNode.conditionals = values.conditionals;

    updateNode(updatedNode);
    goBack();
  };

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={saveNode}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)}
              style={{
                color: 'var(--purple-100)'
              }} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={CONDITIONAL_SWITCH_TYPE.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{CONDITIONAL_SWITCH_TYPE.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {CONDITIONAL_SWITCH_TYPE.description}
              </PFText>
            </PFContainer>
          </PFContainer>

          <PFContainer
            display="grid"
            gridTemplateColumns="auto 1fr auto"
            gap="s">
            <Scenarios
              conditionals={form.values.conditionals}
              fieldHook={fieldHook}
              removeScenario={removeScenario} />
            <PFContainer />
            <PFButton
              type="support"
              onClick={addScenario}
              style={{ justifySelf: 'start' }}>
              + add new scenario
            </PFButton>
          </PFContainer>

          <PFButton submit>Save</PFButton>
        </PFContainer>
      </PFForm>
    </PFContainer>
  );
};

const Scenarios = ({ conditionals, fieldHook, removeScenario }) => {
  return conditionals.map((_, i) => {
    const buttonConfig = {};
    if (i === 0) {
      buttonConfig.disabled = true;
    }

    return (
      <React.Fragment key={i}>
        <PFText type="secondary" size="s" margin="top-s">
          Scenario {i + 1}
        </PFText>
        <PFInput
          name={`conditionals[${i}].expression`}
          fieldHook={fieldHook}
          style={{ fontSize: 'var(--text-size-s)' }} />
        <PFButton
          type="support"
          onClick={() => removeScenario(i)}
          {...buttonConfig}>
          <PFIcon icon="bx bx-trash" />
        </PFButton>
      </React.Fragment>
    );
  });
};

NodeSwitch.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeSwitch;
