import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ShortUniqueId from 'short-unique-id';

import { PFContainer, PFText } from 'PFComponents/common';
import DrawerSection from './components/DrawerSection';

import './ComponentLibrary.css';

const uid = new ShortUniqueId({ length: 6 });

const ComponentLibrary = ({ canvas, open, setOpenDrawer }) => {
  const library = useSelector((state) => state.current_app.component_library);

  const hasCustomComponents = library.components.length > 0;

  const renderComponents = () => {
    const componentCreator = (label, component) => {
      const listCreator = (components) => {
        if (!components) return null;
        return components.map((comp) =>
          componentCreator(comp.build.alias, comp)());
      };

      return () => ({
        ...component,
        id: label + '-' + uid(),
        children: listCreator(component.children),
        build: {
          id: label + '-' + uid(),
          ref: component.build.ref,
          alias: label,
        },
      });
    };

    const componentsByCategory = library.components
      .reduce((acc, { info, component }) => {
        const { label, category } = info;
        return {
          ...acc,
          [category]: (acc[category] || []).concat({
            name: label,
            icon: 'pfi pfi-component-container',
            create: componentCreator(label, component)
          })
        };
      }, {});

    return Object.keys(componentsByCategory).map((category) => (
      <DrawerSection key={category}
        componentsDrawer={componentsByCategory}
        categoryName={category} canvas={canvas}
        setOpenDrawer={setOpenDrawer} />
    ));
  };

  return (
    <PFContainer type="toolbar" width={280}
      style={{
        top: 5, bottom: 0, position: 'absolute', overflowY: 'auto',
        borderLeft: '3px solid var(--container-editor-background)',
        transition: 'all 200ms ease', boxShadow: '5px 0 10px -2px #000a',
        right: open ? -280 : 10, marginBottom: '60px'
      }} className='component-library'>
      <PFContainer padding="m">
        <PFText margin="bottom-m">Custom Components</PFText>
        {hasCustomComponents
          ? renderComponents()
          : <PFText size="s" color="gray">No custom components yet</PFText>}
      </PFContainer>
    </PFContainer>
  );
};

ComponentLibrary.propTypes = {
  canvas: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpenDrawer: PropTypes.func
};

export default ComponentLibrary;
