export default [
  {
    parent: 'Button',
    notAllowedChildren: [
      'Heading',
      'Button',
      'Link',
      'Parragraph',
      'Text',
      'Video',
      'Form',
      'Label',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: ['block'],
    allowChildren: true
  },
  {
    parent: 'Link',
    notAllowedChildren: [
      'Button',
      'Link',
      'Heading',
      'Parragraph',
      'List',
      'Form',
      'Label',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: [],
    allowChildren: true
  },
  {
    parent: 'Heading',
    notAllowedChildren: [
      'Container',
      'Button',
      'Heading',
      'Parragraph',
      'List',
      'Image',
      'Form',
      'Label',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: ['block'],
    allowChildren: true
  },
  {
    parent: 'Parragraph',
    notAllowedChildren: [
      'Container',
      'Button',
      'Heading',
      'Parragraph',
      'List',
      'Form',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: ['block'],
    allowChildren: true
  },
  {
    parent: 'Text',
    notAllowedChildren: [
      'Container',
      'Button',
      'Heading',
      'Parragraph',
      'List',
      'Form',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: ['block'],
    allowChildren: true
  },
  {
    parent: 'Image',
    notAllowedChildren: [],
    notAllowedAttributes: ['block'],
    allowChildren: false
  },
  {
    parent: 'Video',
    notAllowedChildren: [],
    notAllowedAttributes: ['block'],
    allowChildren: false
  },
  {
    parent: 'Form',
    notAllowedChildren: ['Form'],
    notAllowedAttributes: [],
    allowChildren: true
  },
  {
    parent: 'Label',
    notAllowedChildren: [
      'Container',
      'Heading',
      'Button',
      'List',
      'Form',
      'Label',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: ['block'],
    allowChildren: true
  },
  {
    parent: 'Input',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Telephone',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Currency',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Radio',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Checkbox',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Textarea',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Email',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Url',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'Number',
    notAllowedChildren: [],
    notAllowedAttributes: [],
    allowChildren: false
  },
  {
    parent: 'List',
    notAllowedChildren: [
      'List',
      'Image',
      'Button',
      'Link',
      'Parragraph',
      'Text',
      'Video',
      'Form',
      'Label',
      'Input',
      'Video',
      'Number',
      'Telephone',
      'Currency',
      'Radio',
      'Checkbox',
      'Textarea',
      'Email',
      'Url'
    ],
    notAllowedAttributes: ['none'],
    allowChildren: true
  },
];
