import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const IntegrationEDitorSidebar = ({ appId, apiId, setInputParameters }) => {
  const navigate = useNavigate();

  const apiDeclarations = useSelector((state) =>
    state.current_app.build.api_declarations.docs);

  const [search, setSearch] = useState('');

  const changeApi = (id) => {
    setInputParameters([]);
    navigate(`/editor/apps/${appId}/integrations/${id}`);
  };

  const renderApis = () => {
    let filteredApis;
    if (search.length > 0) {
      const searchRegex = new RegExp(search, 'i');
      filteredApis = apiDeclarations.filter((api) => {
        return api.alias.match(searchRegex);
      });
    } else {
      filteredApis = apiDeclarations;
    }

    return filteredApis.map((api) => {
      return (
        <PFButton type="none" key={api._id} onClick={() => changeApi(api._id)}
          style={{ textAlign: 'left' }}>
          <PFText type={api._id === apiId ? 'primary' : 'secondary'}>
            {api.alias}
          </PFText>
        </PFButton>
      );
    });
  };

  return (
    <PFContainer style={{ marginRight: 300 }}>
      <PFContainer type="toolbar" height="100%" width={300}
        style={{ position: 'fixed', overflowY: 'auto', paddingBottom: 100 }}>
        <PFContainer padding="m">
          <PFContainer>
            <PFText>Your integrations</PFText>
            <PFInput name="integrations-search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              margin="vertical-m"
              placeholder="Find an integration"
              leftContent={(
                <PFIcon icon="bx bx-search"
                  margin="left-s"
                  color="var(--secondary)" />
              )} />
          </PFContainer>
          <PFContainer display="flex"
            flexDirection="column" gap="s"
            alignItems="start">
            {renderApis()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

IntegrationEDitorSidebar.propTypes = {
  appId: PropTypes.string.isRequired,
  apiId: PropTypes.string.isRequired,
  setInputParameters: PropTypes.func
};

export default IntegrationEDitorSidebar;
