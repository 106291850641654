/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import { PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import './PFColorpicker.css';

const PFColorpicker = ({
  onChange,
  value = '',
  controlId = '',
  parentContainer = '',
  ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const colorpickerPanelRef = useRef(null);

  const handleToggle = (event) => {
    adjustPosition(event.target);
  };

  const handleChange = (newColor) => {
    onChange(newColor.hex);
    if (newColor.source === 'hsv' || newColor.hex === '') {
      setIsOpen(false);
    }
  };

  function adjustPosition(target) {
    if (!colorpickerPanelRef) return;
    setIsOpen(!isOpen);

    const viewportHeight = window.innerHeight;
    const trigger = target.getBoundingClientRect();

    if (trigger.bottom + 253 > viewportHeight) {
      colorpickerPanelRef.current.style.top
        = trigger.top - trigger.height - 298 + 'px';
    } else {
      colorpickerPanelRef.current.style.top = trigger.top - 30 + 'px';
    }
  }

  useEffect(() => {
    const scrollTarget
      = document.getElementById(parentContainer);

    if (!scrollTarget) return;

    function handleClose(event) {
      if (!event.target.closest(`[data-id='PFColorpicker-${controlId}']`)
        || (event.type === 'keydown' && event.key === 'Escape'
          || event.key === 'Enter')) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClose);
    document.addEventListener('keydown', handleClose);
    scrollTarget.addEventListener('scroll', handleClose);
    return (() => {
      document.removeEventListener('mousedown', handleClose);
      document.addEventListener('keydown', handleClose);
      scrollTarget.removeEventListener('scroll', handleClose);
    });
  }, []);

  return (
    <div className='PFColorpicker' data-id={`PFColorpicker-${controlId}`}>
      <div className='PFColorpickerToggler' onClick={handleToggle}>
        <span
          className='PFColorpickerButton'
          style={{ backgroundColor: value }} />
        <PFText>{value ? value : '#'}</PFText>
      </div>
      <div
        className='PFColorpickerPanel'
        ref={colorpickerPanelRef}>
        {
          isOpen
            ? <>
              <SketchPicker
                disableAlpha={true}
                color={value ? value : ''}
                onChange={handleChange}
                {...props} />
              <span
                className='PFColorpickerClearValue'
                onClick={() => {
                  handleChange({ hex: '' });
                }}>Clear</span>
            </>
            : null
        }
      </div>

    </div >

  );
};

PFColorpicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  controlId: PropTypes.string,
  parentContainer: PropTypes.string,
};


export default PFColorpicker;
