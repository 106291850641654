import {
  PFButton,
  PFContainer,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import '../../DesignEditor.css';
import { EditorControl } from '../../components';

function composeBackground({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);

  const configStyles = {
    [styleKey]: styleValue
  };

  return {
    configStyles
  };
}

const Background = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles,
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <PFButton onClick={handleActiveSection}
        type='support' padding='0' height='auto'>
        <PFText
          className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'} />
          Background
        </PFText>
      </PFButton>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up'
        : 'pf-design-editor__section--down'}>
        {
          ruleSet.map((layoutRule, index) => {
            const ruleKey = Object.keys(layoutRule)[0];
            const ruleValueControls = layoutRule[ruleKey];
            return (
              <PFContainer key={
                `design-editor-${ruleKey}-${index}`
              } className="pf-designer-editor__rule">
                <PFText className='pf-design-editor__rule-title'>
                  {ruleKey}
                </PFText>
                <PFContainer
                  className=
                  "pf-design-editor__control pf-design-editor__control--small"
                >
                  {
                    ruleValueControls.map((rule, index) => {
                      return (
                        <EditorControl
                          key={`design-editor-rule-${rule.key}-${index}}`}
                          componentBreakpointStyles={componentBreakpointStyles}
                          onChange={(event) => {
                            const composedValue = composeBackground({
                              composedValue: { [rule.key]: event },
                            });
                            composeStyles({
                              composedAttributes: composedValue,
                            });
                          }}
                          styleConfig={{ key: rule.key, value: rule.value }}
                          typeConfig={rule.typeConfig}
                          type={typeof rule.type === 'undefined'
                            ? 'editor-control-button' : rule.type}>
                          {rule.label}
                        </EditorControl>
                      );
                    })
                  }
                </PFContainer>
              </PFContainer>);
          })
        }
      </PFContainer>
    </PFContainer >
  );
};

export default Background;

Background.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object
};
