import {
  PFButton, PFContainer, PFIcon, PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
const CopyPanel = ({ children, label, stringToClipboard }) => {
  const [copied, setCopied] = useState(false);
  return (
    <PFContainer margin='top-m bottom-s'>
      <PFText>{label}</PFText>
      <PFContainer padding='s' border='1px solid var(--purple-300)'
        background='var(--purple-700)' margin='top-xs'>
        {children}
        <PFContainer display='flex' justifyContent='flex-end'
          margin='top-m'>
          <PFButton height='auto' width='95px'
            style={{
              display: 'flex',
              gap: 'var(--size-xs)',
              alignItems: 'center',
              backgroundColor: 'var(--purple-300)',
              borderRadius: 'var(--radius-s)',
              justifyContent: 'space-between'
            }} onClick={() => {
              generalUtils.copyToClipboard(stringToClipboard);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 1500);
            }}
          >
            <PFText>{copied ? 'Copied!' : 'Copy'}</PFText>
            <PFIcon icon={copied ? 'bx bx-check' : 'bx bx-copy'}
              color='var(--purple-100' />
          </PFButton>
        </PFContainer>
      </PFContainer>
    </PFContainer>

  );
};

CopyPanel.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  stringToClipboard: PropTypes.string,
};

export default CopyPanel;
