import { PFButton, PFContainer, PFIcon } from 'PFComponents/common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  APICall, Arithmetic,
  AssignValue,
  Conditional,
  InsertRecord,
  Javascript,
  Query,
  Selector,
  UpdateRecord
} from '../..';


const emptyInstruction = { var_name: '', type: '', execute: {} };

const ScenarioInstructions = ({ instructions, setInstructions }) => {
  const appendInstruction = () => {
    setInstructions([...instructions, emptyInstruction]);
  };
  const [isCreated, setIsCreated] = useState(false);

  const getInstructionForm = (index, instruction) => {
    const setInstruction = (value) => {
      instructions[index] = value;
      setInstructions(instructions);
    };

    const props = { index, instruction, setInstruction, isCreated };

    switch (instruction.type) {
      case 'select_query':
        return <Query {...props} />;
      case 'update_sql':
        return <UpdateRecord {...props} />;
      case 'insert_sql':
        return <InsertRecord {...props} />;
      case 'assign':
        return <AssignValue {...props} />;
      case 'arithmetic':
        return <Arithmetic {...props} />;
      case 'api_call':
        return <APICall {...props} />;
      case 'custom_code':
        switch (instruction.execute.language) {
          case 'Javascript':
            return <Javascript {...props} />;
        }
      case 'conditional':
        return <Conditional {...props} />;
      default:
        return (<Selector setIsCreated={setIsCreated}
          {...props} />);
    }
  };

  const renderInstructions = () => {
    return instructions.map((instruction, i) => {
      const prependInstruction = () => {
        instructions.splice(i, 0, emptyInstruction);
        setInstructions(instructions);
      };
      const removeInstruction = () => {
        instructions.splice(i, 1);
        setInstructions(instructions);
      };

      return (
        <React.Fragment key={i}>
          <PFContainer display="flex" justifyContent="center">
            <PFButton onClick={prependInstruction} type="support">
              + add instruction
            </PFButton>
          </PFContainer>
          <PFContainer border="1px solid var(--strokes)" radius="m"
            style={{ position: 'relative' }}>
            <PFContainer background="white"
              style={{ position: 'absolute', top: -20, right: 20 }}>
              <PFButton type="support" onClick={removeInstruction}>
                <PFIcon icon="bx bx-trash" />
              </PFButton>
            </PFContainer>
            {getInstructionForm(i, instruction)}
          </PFContainer>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="flex" flexDirection="column" gap="xs">
      {renderInstructions()}
      <PFContainer display="flex" justifyContent="center">
        <PFButton onClick={appendInstruction} type="support">
          + add instruction
        </PFButton>
      </PFContainer>
    </PFContainer>
  );
};

ScenarioInstructions.propTypes = {
  instructions: PropTypes.array.isRequired,
  setInstructions: PropTypes.func.isRequired,
};

export default ScenarioInstructions;
