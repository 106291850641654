import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFSelect
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';


const RecordValues = ({ values, setValues, columnOptions }) => {
  const addValue = () => {
    const index = Object.keys(values).length;
    setValues({ ...values, [`!${index}`]: '' });
  };

  const renderValues = () => {
    const valuesArray = Object.entries(values);

    return valuesArray.map(([col, val], i) => {
      const setColumn = (col) => {
        valuesArray[i] = [col, val];
        setValues(Object.fromEntries(valuesArray));
      };
      const setValue = (val) => {
        valuesArray[i] = [col, val];
        setValues(Object.fromEntries(valuesArray));
      };
      const removeValue = () => {
        valuesArray.splice(i, 1);
        setValues(Object.fromEntries(valuesArray));
      };

      return (
        <React.Fragment key={i}>
          <PFSelect
            placeholder="Column"
            value={col}
            onChange={(e) => setColumn(e.target.value)}
            options={columnOptions} />
          <PFInput
            placeholder="Value"
            value={val}
            onChange={(e) => setValue(e.target.value)} />
          <PFButton type="support" onClick={removeValue}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="grid" gap="s" margin="top-s"
      gridTemplateColumns="1fr 1fr .1fr">
      {renderValues()}
      <PFButton type="support" onClick={addValue}
        style={{ justifySelf: 'start' }}>
        + add column
      </PFButton>
    </PFContainer>
  );
};

RecordValues.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  columnOptions: PropTypes.array,
};

export default RecordValues;
