import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import { PFItemCard } from 'PFComponents/cards';
import { PFText } from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import { generalUtils } from 'PFUtils';
import PageCreationModal from './PageCreationModal';

const PageCard = ({ appId, page }) => {
  const navigate = useNavigate();

  const [copyFormOpen, setCopyFormOpen] = useState(false);
  const [duplicate, alerts] = useAsyncCalls(false);

  const baseURL = `/editor/apps/${appId}/pages`;
  const baseJSONEditorURL = `/json-editor/apps/${appId}/pages`;

  const duplicatePage = (values) => {
    duplicate.asyncCall(async () => {
      const res = await builderV2.copyPage(appId, page._id, values);
      navigate(`${baseURL}/${res.builder_path}`);
    });
  };

  const options = {
    'JSON Editor': () => navigate(`${baseJSONEditorURL}/${page.builder_path}`),
    'Copy ID': () => generalUtils.copyToClipboard(page._id),
    'Duplicate': () => setCopyFormOpen(true),
  };

  return (
    <>
      <PFItemCard
        key={page._id}
        path={`${baseURL}/${page.builder_path}`}
        options={options}>
        <PFText type="secondary" size="s" clip
          style={{ maxWidth: 250 }}>
          {page.path}
        </PFText>
        <PFText>{page.alias}</PFText>
        <PFText type="secondary" size="s" margin="top-s">
          id: {page._id}
        </PFText>
      </PFItemCard>
      <PageCreationModal
        title={`Make a copy of '${page.alias}'`}
        callToAction="Copy page"
        onSubmit={duplicatePage}
        loading={duplicate.loading}
        active={copyFormOpen}
        onDismiss={() => setCopyFormOpen(false)} />
      {alerts()}
    </>
  );
};

PageCard.propTypes = {
  appId: PropTypes.string.isRequired,
  page: PropTypes.object.isRequired,
};

export default PageCard;
