import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Standard alert component of the PFComponents library. This alert complies
 * with the Pefai style guidelines. The underlying implementation of this
 * alert is the Material UI *Alert and Snackbar* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - Props of the PFAlert component.
 * @param {string} props.message - Message of the alert.
 * @param {"error"|"warning"|"info"|"success"} [props.severity="error"] -
 * Indicates the style that the alert will display.
 * @param {bool} props.open - If true, the alert is shown.
 * @param {func} props.onClose - Function to be called when the alert is
 * dismissed.
 * @param {boolean|number} [props.autoHide=false] - Indicates if the alert
 * should be hidden after a certain amount of time (defaults to 5s).
 * @param {string|number} [props.width="100%"] - Indicates the width of the
 * component.
 *
 * @return {React.ReactElement} The alert component.
 *
 * @author Andres Barragan  <andres@pefai.com>
 */
const PFAlert = ({
  message,
  severity,
  open,
  onClose,
  autoHide,
  width,
}) => {
  const snackbarConfig = {
    open,
    onClose
  };

  if (!!autoHide) {
    if (isNaN(autoHide)) {
      snackbarConfig.autoHideDuration = 5000;
    } else {
      snackbarConfig.autoHideDuration = autoHide;
    }
  }

  return (
    <Snackbar {...snackbarConfig}>
      <Alert onClose={onClose} severity={severity} sx={{ width }}>
        {typeof message === 'string' ? message : message?.message}
      </Alert>
    </Snackbar>
  );
};

PFAlert.defaultProps = {
  severity: 'error',
  autoHide: false,
  width: '100%',
};

PFAlert.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  severity: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  autoHide: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default PFAlert;
