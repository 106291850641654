import {
  PFButton,
  PFCheckbox,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const ACTION_NODES = PROCESS_FLOW.NODE_TYPE_ACTION_OPTIONS;

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit the basic information of a node (alias);
 *
 * @param {Object} props - NodeBasicAction props.
 * @param {ProcessFlowNode} props.node - Action type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Custom API node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeBasicAction = ({ node, updateNode, goBack }) => {
  const [nodeName, setNodeName] = useState(node?.alias ?? '');
  const [asyncNode, setAsyncNode] = useState(node?.async ?? false);

  const nodeTypeInfo = ACTION_NODES.find(
    (n) => n.type === node.type);

  const saveNode = () => {
    const updatedNode = { ...node };
    updatedNode.alias = nodeName;
    updatedNode.async = asyncNode;

    updateNode(updatedNode);
    goBack();
  };
  return (
    <PFContainer className="node-custom-api-editor">
      <PFContainer type="form" padding="m" width={400} radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex" alignItems="center" gap="s">
          <PFButton
            type="support"
            className="flow-dismiss-button"
            onClick={goBack}>
            <PFIcon icon="bx bx-chevron-left" size="l" />
          </PFButton>
          <PFInput className="alt" width="100%"
            value={nodeName} placeholder="Node name"
            onChange={(e) => setNodeName(e.target.value)}
            style={{
              color: 'var(--purple-100)'
            }} />
        </PFContainer>

        <PFContainer display="flex" gap="m" alignItems="flex-start">
          <PFContainer background="var(--purple-25)" padding="s" radius="s">
            <PFIcon icon={nodeTypeInfo.icon}
              size="l" color="var(--primary)" />
          </PFContainer>
          <PFContainer>
            <PFText>{nodeTypeInfo.name}</PFText>
            <PFText type="secondary" size="s" weight={300}>
              {nodeTypeInfo.description}
            </PFText>
          </PFContainer>
        </PFContainer>

        {
          node?.type === 'SAVE_IN_SESSION'
            || node?.type === 'REMOVE_SESSION'
            ? <PFContainer display="flex" flexDirection="column" gap="s"
              style={{
                marginTop: '-10px'
              }}>
              <PFContainer display='flex' justifyContent='flex-end'>
                <PFContainer display='flex' gap='s' alignItems='center'>
                  <PFText color='var(--purple-400)' size='s'>
                    ASYNC
                  </PFText>
                  <PFCheckbox checked={asyncNode}
                    onChange={() => {
                      setAsyncNode((prev) => !prev);
                    }} />
                </PFContainer>
              </PFContainer>
            </PFContainer>
            : null
        }

        <PFButton onClick={saveNode}>Save</PFButton>
      </PFContainer>
    </PFContainer>
  );
};

NodeBasicAction.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeBasicAction;
