import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';

const NodeResult = ({ type = 'request', timestamp, body, message }) => {
  const [toggleFormat, setToggleFormat] = useState(false);

  return (
    <>
      <PFContainer display='flex' gap='l'>
        <PFContainer>
          <PFText color='var(--black)'>
            {type === 'request' ? 'Request' : 'Response'}
          </PFText>
          <PFText color='var(--black)'>{timestamp}</PFText>
        </PFContainer>

        <pre
          color='var(--black)'
          style={{
            margin: '0',
            flex: 1,
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word',
          }}
        >
          {toggleFormat
            ? JSON.stringify(body, null, 2)
            : JSON.stringify(body, null, 0)}
          {
            message
              ? <p style={{
                margin: 0,
                marginTop: 'var(--size-s)'
              }}>message: {message}</p>
              : <></>
          }
        </pre>

        <PFContainer display='flex' justifyContent='flex-end'>
          <PFButton
            onClick={() => {
              setToggleFormat((prev) => !prev);
            }}
          >
            <PFIcon
              icon='bx bxs-file-json'
              color={
                toggleFormat ? 'var(--purple-200)' : 'var(--purple-100-50)'
              }
              size='l'
            />
          </PFButton>
        </PFContainer>
      </PFContainer>
    </>
  );
};

NodeResult.propTypes = {
  type: PropTypes.string,
  timestamp: PropTypes.string,
  body: PropTypes.object,
  message: PropTypes.string,
};

export default NodeResult;
