import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { PFTableCreationModal } from 'PFComponents/editors';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, { currentDBActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';


const PFScreenDBTables = () => {
  const currentDB = useSelector((state) => state.current_db);
  const { tables } = currentDB;
  const currentUserId = useSelector((state) => state.user._id);
  const [creationFormOpen, setCreationFormOpen] = useState(false);

  const [deleteAsyncCall, , deleteError, dismissDeleteError]
    = useAsyncCall(false);

  const baseURL = `/editor/databases/${currentDB._id}/tables`;

  const [DeletionDialog, confirmDeletion] = useConfirmation(
    'Are you sure you want to delete this table?',
  );

  const deleteTable = (tableName) => {
    deleteAsyncCall(async () => {
      if (await confirmDeletion()) {
        store.dispatch(currentDBActions.removeTable(tableName));
        await builderTest(currentUserId)
          .deleteDBTable(currentDB._id, tableName);
      }
    });
  };


  const renderTables = () => {
    return tables.map((table) => {
      return (
        <PFItemCard
          key={table.table_name}
          path={`${baseURL}/${table.table_name}`}
          options={{
            'Delete': () => deleteTable(table.table_name),
            'Copy ID': () => generalUtils.copyToClipboard(table.table_name)
          }}>
          <PFText margin="top-m">{table.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {table.table_name}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Tables</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={() => setCreationFormOpen(true)}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Table</PFText>
          </PFActionCard>
          {renderTables()}
        </PFContainer>
      </PFContainer>
      <DeletionDialog />
      <PFAlert
        message={deleteError}
        open={!!deleteError}
        onClose={dismissDeleteError} />
      <PFTableCreationModal
        dbId={currentDB._id}
        baseURL={baseURL}
        active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
    </PFContainer>
  );
};

export default PFScreenDBTables;
