/* eslint-disable indent */
import PropTypes from 'prop-types';

import { componentUtils } from 'PFUtils';

import './PFText.css';

/**
 * Standard text component of the PFComponents library. This text complies
 * with the Pefai style guidelines. The underlying implementation of this
 * text is the React *h*, p* components.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFText properties.
 * @param {React.ReactNode} props.children - Child elements to be
 * rendered inside the text.
 * @param {"primary"|"secondary"|"support"|"none"} [props.type="primary"] -
 * Type of text to be rendered, this changes the appearance of the text.
 * @param {"h1"|"h2"|"h3"|"h4"|"h5"|"h6"|"p"|"span"} [props.tag="p"] - HTML tag
 * to use be used as text.
 * @param {string} [props.className] - Class name to be applied to the text.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the text.
 * @param {string} [props.color] - Determines the color of the text.
 * @param {string|number} [props.weight="normal"] - Determines the font weight
 * of the text.
 * @param {string} [props.size] - Determines the font size of the text.
 * @param {string} [props.margin] - Determines the margin config of the text
 * component.
 * @param {string} [props.padding] - Determines the padding config of the text
 * component.
 *
 * @return {React.ReactElement} The text component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFText = ({
  children,
  type,
  tag,
  className,
  clip,
  style,
  color,
  size,
  weight,
  margin,
  padding,
}) => {
  const textClassName = `pf text-component 
  ${clip ? 'clip' : ''} ${type} ${tag} ${className}`;

  const configStyle
    = componentUtils.getStylesFromConfig({ margin, padding, font: size });
  const textStyle = {
    color,
    fontWeight: weight,
    ...configStyle,
    ...style
  };

  switch (tag) {
    case 'h1':
      return <h1 className={textClassName} style={textStyle}>{children}</h1>;
    case 'h2':
      return <h2 className={textClassName} style={textStyle}>{children}</h2>;
    case 'h3':
      return <h3 className={textClassName} style={textStyle}>{children}</h3>;
    case 'h4':
      return <h4 className={textClassName} style={textStyle}>{children}</h4>;
    case 'h5':
      return <h5 className={textClassName} style={textStyle}>{children}</h5>;
    case 'h6':
      return <h6 className={textClassName} style={textStyle}>{children}</h6>;
    case 'span':
      return (<span className={textClassName} style={textStyle}>
        {children}
      </span>);
    default:
      return <p className={textClassName} style={textStyle}>{children}</p>;
  }
};

PFText.defaultProps = {
  type: 'primary',
  tag: 'p',
  className: '',
  clip: false,
  style: {},
  weight: 'normal',
};

PFText.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'support', 'none']),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
  className: PropTypes.string,
  clip: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  padding: PropTypes.string,
};

export default PFText;
