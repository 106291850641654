import { builderV2 } from 'PFApis';
import {
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLink,
  PFLoader,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls, useConfirmation, useForm } from 'PFHooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppFunctionEnvironmentModal } from './components';

const PFScreenAppEnvDetail = () => {
  const { env_name } = useParams();

  const currentApp = useSelector((state) => state.current_app);
  const baseURL = `/apps/${currentApp._id}/environments`;
  const [functionEnvs, setFunctionEnvs] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [functionEnv, setFunctionEnv] = useState({});
  const [fetch, save, alerts] = useAsyncCalls(true, false);

  const [env, setEnv] = useState(null);
  const [form, fieldHook] = useForm(formConfigFromEnv(null));

  useEffect(() => {
    fetch.asyncCall(async () => {
      setEnv(null);
      const res = await builderV2.getAppEnv(currentApp._id, env_name);
      setEnv(res);
      form.setConfig(formConfigFromEnv(res));

      const envRes = await builderV2.getFunctionEnvs(currentApp._id, env_name);
      setFunctionEnvs(envRes);
    });
  }, [env_name]);

  const [ConfirmDialog, confirmUpdate] = useConfirmation(
    'Are you sure you want to update this environment?',
  );

  const saveEnv = (values) => {
    save.asyncCall(async () => {
      if (await confirmUpdate()) {
        const newEnv = envFromFormValues(values);
        await builderV2.updateAppEnv(currentApp._id, env_name, newEnv);
      }
    });
  };

  const addEnvVariable = () => {
    let newFormConfig = {};
    if (form.values.vars) {
      const envObject = envFromFormValues(form.values);
      newFormConfig = formConfigFromEnv(envObject);
    } else {
      newFormConfig.vars = [];
    }
    newFormConfig.vars.push({
      'key': {
        default_value: '',
        validation_type: 'text',
        error_message: 'Please provide a key name'
      },
      'value': {
        default_value: '',
        validation_type: 'text',
        error_message: 'Please provide a value'
      },
    });
    form.setConfig(newFormConfig);
  };

  const removeEnvVariable = (index) => {
    const envObject = envFromFormValues(form.values);
    const newFormConfig = formConfigFromEnv(envObject);
    newFormConfig.vars.splice(index, 1);
    form.setConfig(newFormConfig);
  };

  const renderEnvVars = () => {
    return form.values.vars.map((_, i) => {
      return (
        <React.Fragment key={i}>
          <PFInput name={`vars[${i}].key`} fieldHook={fieldHook} />
          <PFInput name={`vars[${i}].value`} fieldHook={fieldHook} />
          <PFButton type="support" onClick={() => removeEnvVariable(i)}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  const renderFunctionEnvs = () => {
    return functionEnvs?.map((functionElement) => {
      return (
        <PFContainer key={functionElement._id} style={{
          position: 'relative'
        }} width='100%'>
          <PFButton type='none'
            padding='right-m left-m bottom-m top-m'
            style={{
              border: '1px solid var(--purple-100)',
              borderRadius: 'var(--radius-m)',
              textAlign: 'start'
            }} height='200px' width='100%'
            options={{
              'Delete': () => { }
            }}
            onClick={() => {
              setIsModalOpen(true);
              setFunctionEnv(functionElement);
            }}
          >
            <PFText type="secondary" size="s" margin="top-s">
              {functionElement._id}
            </PFText>
            <PFText margin="top-s">{functionElement.alias}</PFText>
            {
              functionElement.env
                ? <PFText type="secondary" size="s" margin="top-s">
                  Connected to  {functionElement.env}
                </PFText>
                : <PFText type="secondary" size="s" margin="top-s">
                  Connection not set up yet
                </PFText>
            }

          </PFButton>
        </PFContainer>
      );
    });
  };

  return (
    <>
      <PFContainer type="main">
        <PFContainer>
          <PFContainer margin='bottom-m'>
            <PFText tag="h2" size="l">
              Environments:
              <PFText tag="span" type="secondary"> {env_name}</PFText>
            </PFText>
            <PFContainer display="flex">
              <PFLink to={baseURL}>
                <PFText type="secondary" size="s">Environments</PFText>
              </PFLink>
              <PFIcon icon="bx bx-chevron-right" color="var(--secondary)" />
              <PFLink>
                <PFText type="secondary" size="s">{env_name}</PFText>
              </PFLink>
            </PFContainer>
          </PFContainer>
          {fetch.loading || !env
            ? <PFLoader color="white" area />
            : <PFContainer type="editor" padding="l" radius="m">
              <PFText margin="bottom-m">Environment variables</PFText>
              <PFForm form={form} submit={saveEnv}>
                <PFContainer display="grid" gap="s"
                  gridTemplateColumns="1fr 2fr .2fr">
                  {form.values?.vars?.length > 0
                    ? <>
                      <PFText>Key</PFText>
                      <PFText>Value</PFText>
                      <PFContainer />
                      {renderEnvVars()}
                    </>
                    : null}
                  <PFButton type="support" onClick={addEnvVariable}
                    style={{ justifySelf: 'start' }}>
                    + add new
                  </PFButton>
                </PFContainer>
                <PFContainer display="flex" justifyContent="end">
                  <PFButton width={200} loading={save.loading} submit>
                    Save
                  </PFButton>
                </PFContainer>
              </PFForm>
            </PFContainer>
          }
          <PFContainer margin='top-l bottom-l'>
            <PFText tag="h2" size="l">Function environments</PFText>
          </PFContainer>
          <PFContainer display="grid"
            gridTemplateColumns="1fr 1fr 1fr" gap="m">
            {renderFunctionEnvs()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <AppFunctionEnvironmentModal
        active={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        functionEnv={functionEnv}
        setFunctionEnv={setFunctionEnv}
        functionEnvs={functionEnvs}
        functionEnvName={env_name} />
      <ConfirmDialog />
      {alerts()}
    </>
  );
};

const envFromFormValues = (values) => {
  const env = {};
  for (const { key, value } of values.vars) {
    env[key] = value;
  };
  return env;
};

const formConfigFromEnv = (env) => {
  const form = {};
  if (!env) return form;

  form.vars = [];

  for (const key in env) {
    form.vars.push({
      'key': {
        default_value: key,
        validation_type: 'text',
        error_message: 'Please provide a key name'
      },
      'value': {
        default_value: env[key],
        validation_type: 'text',
        error_message: 'Please provide a value'
      },
    });
  };

  return form;
};

export default PFScreenAppEnvDetail;
