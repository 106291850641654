import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import { componentUtils } from 'PFUtils';

import './PFLoader.css';

/**
 * Standard loader component of the PFComponents library. This loader complies
 * with the Pefai style guidelines. The underlying implementation of this
 * loader is the Material UI *CircularProgress* component.
 *
 * @memberof module:PFComponents
 *
 * @param {string} [props.className] - Class name to be applied to the loader.
 * @param {"m"|"s"} [props.size="s"] - Size of the loader.
 * @param {string} [props.color] - Color of the loader.
 * @param {boolean} [props.area] - Determines if the loader should be centered
 * inside an area.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the loader.
 * @param {string} [props.margin] - Determines the margin config of the loader
 * component.
 *
 * @return {React.ReactElement} The loader component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFLoader = ({
  className,
  size,
  color,
  area,
  style,
  margin,
}) => {
  const loaderClassName = `pf loader ${className} ${area ? 'area' : ''}`;

  const configStyle = componentUtils.getStylesFromConfig({ margin });
  const loaderStyle = { color: color, ...configStyle, ...style };

  return (
    <div className={loaderClassName} style={loaderStyle}>
      <CircularProgress color="inherit" size={size === 's' ? 20 : 40} />
    </div>
  );
};

PFLoader.defaultProps = {
  className: '',
  size: 'm',
  style: {},
};

PFLoader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  area: PropTypes.bool,
  style: PropTypes.object,
  margin: PropTypes.string,
};

export default PFLoader;
