import {
  PFButton,
  PFContainer,
  PFIcon, PFSelect,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import ActionEditor from '../ActionEditor/ActionEditor';
import ProcessFlowSelector from '../ProcessFlowSelector/ProcessFlowSelector';

const eventOptions = [
  { label: 'Is clicked', value: 'ON_CLICK' },
  { label: 'Is focused', value: 'ON_FOCUS' },
  { label: 'Is mouse enter', value: 'ON_MOUSE_ENTER' },
  { label: 'Is dragged', value: 'ON_DRAG_ENTER' }
];

const EventEditor = ({ component, setComponent, eventActions, eventIndex }) => {
  const addAction = (eventIndex) => {
    const updatedEvents = [...component.events];

    const newAction = {
      action: '',
      params: {}
    };

    if (!component.events[eventIndex].actions) {
      updatedEvents[eventIndex].actions = [newAction];
    } else {
      updatedEvents[eventIndex].actions
        = [...updatedEvents[eventIndex].actions, newAction];
    }
    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const addProcessFlow = (eventIndex) => {
    const updatedEvents = [...component.events];

    if (!component.events[eventIndex].trigger) {
      updatedEvents[eventIndex].trigger = '';
    } else {
      updatedEvents[eventIndex].triggers = [
        ...updatedEvents[eventIndex].trigger
      ];
    }

    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const removeEvent = (eventIndex) => {
    const updatedEvents = [...component.events];
    updatedEvents.splice(eventIndex, 1);

    if (updatedEvents.length > 0) {
      setComponent({
        ...component,
        events: updatedEvents
      });
    } else {
      setComponent({
        ...component,
        events: null
      });
    }
  };

  const handleEventChange = (newValue, eventIndex) => {
    const updatedEvents = [...component.events];
    updatedEvents[eventIndex].event = newValue;
    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const hasProcessFlow
    = component.events[eventIndex].hasOwnProperty('trigger');

  return (
    <PFContainer
      key={`event-container-${eventIndex}`}
      display='flex'
      flexDirection='column'
      gap='s'
      margin='bottom-s'>
      <PFContainer display='flex'
        justifyContent="space-between" alignItems='center'>
        <PFText size='label'>{`Event ${eventIndex + 1}`}</PFText>
        <PFButton onClick={() => removeEvent(eventIndex)}
          type='support'
        >
          <PFIcon
            icon='bx bx-trash'
            size='m'
          />
        </PFButton>
      </PFContainer>
      <PFContainer display="grid" gap="s" alignItems="center"
        gridTemplateColumns="80px 1fr">
        <PFText size='label'>When</PFText>
        <PFSelect
          placeholder='Select an event'
          style={{ fontSize: 'var(--text-label)' }}
          options={eventOptions}
          value={component.events[eventIndex].event}
          onChange={(e) => handleEventChange(e.target.value, eventIndex)}
          optionsStyles={{ fontSize: '12px' }} />
      </PFContainer>
      <PFContainer display='flex'
        justifyContent="space-between" alignItems='center'>
        <PFText size='s'>Actions</PFText>
        <PFButton
          onClick={() => addAction(eventIndex)}
          type='support'>
          <PFIcon
            icon='bx bx-plus'
            size='s'
          />
        </PFButton>
      </PFContainer>
      {eventActions.map(
        (action, actionIndex) =>
        (<ActionEditor
          key={`action-${actionIndex}`}
          component={component}
          setComponent={setComponent}
          eventIndex={eventIndex}
          actionIndex={actionIndex}
        />)
      )}
      <PFContainer display='flex'
        justifyContent="space-between" alignItems='center'>
        <PFText size='s'>Process flows</PFText>
        <PFButton
          onClick={
            () => addProcessFlow(eventIndex)
          }
          type='support'
          disabled={hasProcessFlow}
          style={{ cursor: `${hasProcessFlow ? 'default' : 'pointer'}` }}
        >
          <PFIcon
            icon={
              hasProcessFlow
                ? ''
                : 'bx bx-plus'
            }
            size='s'
          />
        </PFButton>
      </PFContainer>
      {
        hasProcessFlow
          ? <ProcessFlowSelector
            component={component}
            setComponent={setComponent}
            eventIndex={eventIndex}
          />
          : null
      }
    </PFContainer>
  );
};


EventEditor.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  eventActions: PropTypes.array.isRequired,
  eventIndex: PropTypes.number.isRequired,
};

export default EventEditor;
