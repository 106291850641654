export const componentProperties = {
  Container: {
    controls: [
      {
        type: 'select',
        propertyName: 'semanticType',
        propertyLabel: 'Semantic',
        options: [
          { label: 'Article', value: 'Article' },
          { label: 'Aside', value: 'aside' },
          { label: 'Div', value: 'div' },
          { label: 'Footer', value: 'footer' },
          { label: 'Header', value: 'header' },
          { label: 'Main', value: 'main' },
          { label: 'Nav', value: 'nav' },
          { label: 'Section', value: 'section' },
        ]
      }
    ]
  },
  Readmore: {
    controls: [
      {
        type: 'input',
        propertyName: 'minHeight',
        propertyLabel: 'Visible Height'
      },
      {
        type: 'input',
        propertyName: 'readLessText',
        propertyLabel: 'Read less text'
      },
      {
        type: 'input',
        propertyName: 'readMoreText',
        propertyLabel: 'Read more text'
      }
    ]
  },
  Heading: {
    controls: [
      {
        type: 'select',
        propertyName: 'level',
        propertyLabel: 'Level',
        options: [
          {
            label: 'H1',
            value: 1
          },
          {
            label: 'H2',
            value: 2
          },
          {
            label: 'H3',
            value: 3
          },
          {
            label: 'H4',
            value: 4
          },
          {
            label: 'H5',
            value: 5
          },
          {
            label: 'H6',
            value: 6
          },
        ]
      }
    ]
  },
  Text: {
    controls: [
      {
        type: 'select',
        propertyName: 'type',
        propertyLabel: 'Type',
        options: [
          {
            label: 'Span',
            value: 'span'
          }
        ]
      }
    ]
  },
  Image: {
    controls: [
      {
        type: 'input',
        propertyName: 'src',
        propertyLabel: 'Src'
      },
      {
        type: 'input',
        propertyName: 'alt',
        propertyLabel: 'Alt'
      },
      {
        type: 'input',
        propertyName: 'width',
        propertyLabel: 'Width'
      },
      {
        type: 'input',
        propertyName: 'height',
        propertyLabel: 'Height'
      },
      {
        type: 'select',
        propertyName: 'loading',
        propertyLabel: 'Loading',
        options: [
          {
            label: 'Auto',
            value: 'auto'
          },
          {
            label: 'Lazy',
            value: 'lazy'
          },
          {
            label: 'Eager',
            value: 'eager'
          },
        ]
      }
    ]
  },
  Button: {
    controls: [
      {
        type: 'select',
        propertyName: 'type',
        propertyLabel: 'Type',
        options: [
          {
            label: 'Button',
            value: 'button'
          },
          {
            label: 'Submit',
            value: 'submit'
          }
        ]
      }
    ]
  },
  Link: {
    controls: [
      {
        type: 'input',
        propertyName: 'href',
        propertyLabel: 'Href'
      },
      {
        type: 'select',
        propertyName: 'target',
        propertyLabel: 'Target',
        options: [
          {
            label: '_self',
            value: '_self'
          },
          {
            label: '_blank',
            value: '_blank'
          },
          {
            label: '_parent',
            value: '_parent'
          },
          {
            label: '_top',
            value: '_top'
          }
        ]
      }
    ]
  },
  Label: {
    controls: [
      {
        type: 'input',
        propertyName: 'htmlFor',
        propertyLabel: 'HtmlFor'
      }
    ]
  },
  Formfieldmetadata: {
    type: 'meta',
    controls: [
      {
        type: 'select',
        propertyName: 'display',
        propertyLabel: 'Display',
        options: [
          {
            label: 'Error',
            value: 'error'
          },
          {
            label: 'Success',
            value: 'success'
          },
          {
            label: 'Touched',
            value: 'touched'
          },
          {
            label: 'Value',
            value: 'value'
          },
          {
            label: 'Unset',
            value: ''
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'validationType',
        propertyLabel: 'Validation Type',
        options: [
          {
            label: 'required',
            value: 'required'
          },
          {
            label: 'min',
            value: 'min'
          },
          {
            label: 'max',
            value: 'max'
          },
          {
            label: 'length',
            value: 'length'
          },
          {
            label: 'matches',
            value: 'matches'
          },
          {
            label: 'match field',
            value: 'oneOf'
          },
          {
            label: 'email',
            value: 'email'
          },
          {
            label: 'url',
            value: 'url'
          },
          {
            label: 'shape',
            value: 'typeError'
          },
          {
            label: 'uuid',
            value: 'uuid'
          },
          {
            label: 'flag',
            value: 'flag'
          },
          {
            label: 'any',
            value: ''
          },
        ]
      }
    ]
  },
  Metawrapper: {
    type: 'meta',
    controls: []
  },
  List: {
    controls: [
      {
        type: 'select',
        propertyName: 'type',
        propertyLabel: 'Type',
        options: [
          {
            label: 'ul',
            value: 'ul'
          },
          {
            label: 'ol',
            value: 'ol'
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'variant',
        propertyLabel: 'Variant',
        options: [
          {
            label: 'Vertical',
            value: 'vertical'
          },
          {
            label: 'Horizontal',
            value: 'horizontal'
          }
        ]
      }
    ]
  },
  Icon: {
    controls: [
      {
        type: 'input',
        propertyName: 'className',
        propertyLabel: 'Class Name'
      },
      {
        type: 'input',
        propertyName: 'svgSrc',
        propertyLabel: 'SVG source'
      },
      {
        type: 'select',
        propertyName: 'type',
        propertyLabel: 'Type',
        options: [
          {
            label: 'Span',
            value: 'span'
          },
          {
            label: 'i',
            value: 'i'
          }
        ]
      }
    ]
  },
  Video: {
    controls: [
      {
        type: 'input',
        propertyName: 'src',
        propertyLabel: 'Src'
      },
      {
        type: 'input',
        propertyName: 'width',
        propertyLabel: 'Width'
      },
      {
        type: 'input',
        propertyName: 'height',
        propertyLabel: 'Height'
      },
      {
        type: 'select',
        propertyName: 'type',
        propertyLabel: 'Type',
        options: [
          {
            label: 'video/mp4',
            value: 'video/mp4'
          },
          {
            label: 'video/webm',
            value: 'video/webm'
          },
          {
            label: 'video/ogg',
            value: 'video/ogg'
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'autoplay',
        propertyLabel: 'Autoplay',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'controls',
        propertyLabel: 'Controls',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'loop',
        propertyLabel: 'Loop',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'preload',
        propertyLabel: 'Preload',
        options: [
          {
            label: 'Auto',
            value: 'auto'
          },
          {
            label: 'Metadata',
            value: 'metadata'
          },
          {
            label: 'None',
            value: 'none'
          }
        ]
      }
    ]
  },
  Toast: {
    type: 'field',
    controls: [
      {
        type: 'select',
        propertyName: 'isToastOpen',
        propertyLabel: 'isOpen',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'overlap',
        propertyLabel: 'Overlap',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Dropdown: {
    controls: [
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      }
    ]
  },
  Pdfviewer: {
    controls: [
      {
        type: 'input',
        propertyName: 'srcFile',
        propertyLabel: 'Src'
      },
      {
        type: 'select',
        propertyName: 'scalable',
        propertyLabel: 'Scalable',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'downloadable',
        propertyLabel: 'Downloadable',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'navigable',
        propertyLabel: 'Navigable',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Tooltip: {
    controls: [
      {
        type: 'input',
        propertyName: 'tooltipTargetId',
        propertyLabel: 'Target ID'
      },
      {
        type: 'select',
        propertyName: 'tooltipPosition',
        propertyLabel: 'Position',
        options: [
          {
            label: 'Top',
            value: 'top'
          },
          {
            label: 'Bottom',
            value: 'bottom'
          },
          {
            label: 'Left',
            value: 'left'
          },
          {
            label: 'Right',
            value: 'right'
          }
        ]
      },
      {
        type: 'input',
        propertyName: 'gap',
        propertyLabel: 'Gap'
      }
    ]
  },
  Formsubmit: {
    controls: [
      {
        type: 'select',
        propertyName: 'isSubmitting',
        propertyLabel: 'Has loader',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'input',
        propertyName: 'isSubmittingMessageId',
        propertyLabel: 'Loader ID'
      }
    ]
  },
  Modal: {
    controls: [
      {
        type: 'select',
        propertyName: 'isModalOpen',
        propertyLabel: 'isModalOpen',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'modeless',
        propertyLabel: 'Modeless',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'overlay',
        propertyLabel: 'Overlay',
        options: [
          {
            label: 'Auto',
            value: 'auto'
          },
          {
            label: 'Full',
            value: 'full'
          }
        ]
      }
    ]
  },
  Table: {
    controls: [
      {
        type: 'input',
        propertyName: 'tableHeadId',
        propertyLabel: 'Head ID'
      },
      {
        type: 'select',
        propertyName: 'columnDataType',
        propertyLabel: 'Data type',
        options: [
          {
            label: 'String',
            value: 'string'
          },
          {
            label: 'Number',
            value: 'numbre'
          }
        ]
      },
      {
        type: 'input',
        propertyName: 'virtualizedRows',
        propertyLabel: 'Virtulized Rows'
      }
    ]
  },
  TableHeadRow: {
    controls: [
      {
        type: 'input',
        propertyName: 'tableHeadId',
        propertyLabel: 'Head ID'
      }
    ]
  },
  TableHeadColumn: {
    controls: [
      {
        type: 'select',
        propertyName: 'columnType',
        propertyLabel: 'Data type',
        options: [
          {
            label: 'String',
            value: 'string'
          },
          {
            label: 'Number',
            value: 'numbre'
          }
        ]
      }
    ]
  },
  TableBodyCell: {
    controls: [
      {
        type: 'select',
        propertyName: 'columnType',
        propertyLabel: 'Data type',
        options: [
          {
            label: 'String',
            value: 'string'
          },
          {
            label: 'Number',
            value: 'numbre'
          }
        ]
      }
    ]
  },
  Accordionitem: {
    controls: [
      {
        type: 'input',
        propertyName: 'tabId',
        propertyLabel: 'Tab ID'
      }
    ]
  },
  ContentSlider: {
    controls: [
      {
        type: 'select',
        propertyName: 'scrollOnDrag',
        propertyLabel: 'Scroll on drag',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  ContentSliderNavigationButton: {
    controls: [
      {
        type: 'select',
        propertyName: 'direction',
        propertyLabel: 'Direction',
        options: [
          {
            label: 'Left',
            value: 'left'
          },
          {
            label: 'Right',
            value: 'right'
          }
        ]
      },
      {
        type: 'input',
        propertyName: 'tabId',
        propertyLabel: 'Tab ID'
      }
    ]
  },
  Input: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Password: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Email: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'pattern',
        propertyLabel: 'Pattern'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Textarea: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'cols',
        propertyLabel: 'Columns'
      },
      {
        type: 'input',
        propertyName: 'rows',
        propertyLabel: 'Rows'
      },
      {
        type: 'input',
        propertyName: 'minLength',
        propertyLabel: 'Min length'
      },
      {
        type: 'input',
        propertyName: 'maxLength',
        propertyLabel: 'Max length'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Telephone: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'format',
        propertyLabel: 'Format'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Url: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'pattern',
        propertyLabel: 'Pattern'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Simpleradio: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'fieldValue',
        propertyLabel: 'Field value'
      },
      {
        type: 'select',
        propertyName: 'disabled',
        propertyLabel: 'Disabled',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Simplecheckbox: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'select',
        propertyName: 'disabled',
        propertyLabel: 'Is Disabled',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Filebrowser: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'accept',
        propertyLabel: 'Accept'
      },
      {
        type: 'input',
        propertyName: 'fileSizeErrorMessage',
        propertyLabel: 'Error message (size)'
      },
      {
        type: 'input',
        propertyName: 'fileSizeLimit',
        propertyLabel: 'Size limit'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'multiple',
        propertyLabel: 'Multiple',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Number: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'max',
        propertyLabel: 'Max'
      },
      {
        type: 'input',
        propertyName: 'min',
        propertyLabel: 'Min'
      },
      {
        type: 'input',
        propertyName: 'step',
        propertyLabel: 'Step'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Currency: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'currency',
        propertyLabel: 'Currency'
      },
      {
        type: 'input',
        propertyName: 'locale',
        propertyLabel: 'Locale'
      },
      {
        type: 'input',
        propertyName: 'suffix',
        propertyLabel: 'Suffix'
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
      {
        type: 'select',
        propertyName: 'autocomplete',
        propertyLabel: 'Autocomplete',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'readOnly',
        propertyLabel: 'Read only',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'disabled',
        propertyLabel: 'Disabled',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  AutocompleteResults: {
    type: 'field',
    controls: [
      {
        type: 'select',
        propertyName: 'showDropdown',
        propertyLabel: 'Is Open',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  AutocompleteSearchbar: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      },
    ]
  },
  AutocompleteResultsOption: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'value',
        propertyLabel: 'Value'
      },
    ]
  },
  Autocomplete: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
    ]
  },
  Rangeslider: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'currencyCode',
        propertyLabel: 'Currency code'
      },
      {
        type: 'input',
        propertyName: 'currencyLocale',
        propertyLabel: 'Locale'
      },
      {
        type: 'input',
        propertyName: 'decimalSeparator',
        propertyLabel: 'Decimal separator'
      },
      {
        type: 'input',
        propertyName: 'groupSeparator',
        propertyLabel: 'Group separator'
      },
      {
        type: 'input',
        propertyName: 'initialMax',
        propertyLabel: 'Initial max'
      },
      {
        type: 'input',
        propertyName: 'initialMin',
        propertyLabel: 'Initial min'
      },
      {
        type: 'input',
        propertyName: 'maxValue',
        propertyLabel: 'maxValue'
      },
      {
        type: 'input',
        propertyName: 'minValue',
        propertyLabel: 'minValue'
      },
      {
        type: 'input',
        propertyName: 'prefixSymbol',
        propertyLabel: 'prefixSymbol'
      },
      {
        type: 'input',
        propertyName: 'suffixSymbol',
        propertyLabel: 'suffixSymbol'
      },
      {
        type: 'input',
        propertyName: 'step',
        propertyLabel: 'Step'
      },
      {
        type: 'select',
        propertyName: 'enableTooltip',
        propertyLabel: 'Tooltip',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'select',
        propertyName: 'isRangeValid',
        propertyLabel: 'isRangeValid',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      }
    ]
  },
  Fileupload: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'name',
        propertyLabel: 'Name'
      },
      {
        type: 'input',
        propertyName: 'accept',
        propertyLabel: 'Accept'
      },
      {
        type: 'input',
        propertyName: 'fileSizeErrorMessage',
        propertyLabel: 'Error message'
      },
      {
        type: 'input',
        propertyName: 'fileSizeLimit',
        propertyLabel: 'File size limit'
      },
      {
        type: 'select',
        propertyName: 'multiple',
        propertyLabel: 'Multiple',
        options: [
          {
            label: 'Yes',
            value: true
          },
          {
            label: 'No',
            value: false
          }
        ]
      },
      {
        type: 'input',
        propertyName: 'placeholder',
        propertyLabel: 'Placeholder'
      }
    ]
  },
  Downloadlink: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'debounceSize',
        propertyLabel: 'Debounce'
      },
      {
        type: 'input',
        propertyName: 'fileName',
        propertyLabel: 'File name'
      },
      {
        type: 'input',
        propertyName: 'url',
        propertyLabel: 'Url'
      }
    ]
  },
  Cardpayments: {
    type: 'field',
    controls: [
      {
        type: 'input',
        propertyName: 'debounceSize',
        propertyLabel: 'Debounce'
      },
      {
        type: 'input',
        propertyName: 'fileName',
        propertyLabel: 'File name'
      },
      {
        type: 'input',
        propertyName: 'url',
        propertyLabel: 'Url'
      }
    ]
  }
};
