export const fieldTypes = [
  {
    label: 'string',
    value: 'string'
  },
  {
    label: 'number',
    value: 'number'
  },
  {
    label: 'date',
    value: 'date'
  },
  {
    label: 'boolean',
    value: 'boolean'
  },
  {
    label: 'object',
    value: 'object'
  },
];
