/* eslint-disable max-len */
import { configureStore } from '@reduxjs/toolkit';

import { buildEnv } from 'PFConfig/env';
import authSlice, * as authActions from './slices/authSlice';
import currentAppSlice, * as currentAppActions from './slices/currentAppSlice';
import currentDBSlice, * as currentDBActions from './slices/currentDBSlice';
import currentFunctionSlice, * as currentFunctionActions from './slices/currentFunctionSlice';
import themeSlice, * as themeActions from './slices/themeSlice';
import userSlice, * as userActions from './slices/userSlice';

export default configureStore({
  reducer: {
    auth: authSlice,
    current_app: currentAppSlice,
    current_function: currentFunctionSlice,
    current_db: currentDBSlice,
    theme: themeSlice,
    user: userSlice,
  },
  middleware:
    (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: buildEnv !== 'production',
});

export {
  authActions,
  currentAppActions,
  currentDBActions,
  currentFunctionActions,
  themeActions,
  userActions
};

