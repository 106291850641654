import {
  PFButton, PFCheckbox, PFContainer,
  PFIcon, PFSelect, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import APISelector from './APISelector';
import OperationSelector from './OperationSelector';


const DYNAMIC_DATA_OPTIONS = [
  { label: 'Integration', value: 'API_DECLARATION' },
  { label: 'Function', value: 'FUNCTION_OPERATION' },
];

const SourceSelector = ({ data, setData, removeData, showCacheable }) => {
  const { source, reference, params, is_cacheable
  } = data;

  const onChangeSource = (e) => {
    const newSource = e.target.value;

    setData({
      source: newSource,
      reference: newSource === 'FUNCTION_OPERATION' ? '/' : '',
      params: {},
      is_cacheable: false
    });
  };

  const renderReferenceSelector = () => {
    const props = {
      reference,
      setReference: (reference) => setData({ ...data, reference, params: {} }),
      params,
      setParams: (params) => setData({ ...data, params })
    };

    switch (source) {
      case 'API_DECLARATION':
        return <APISelector {...props} />;
      case 'FUNCTION_OPERATION':
        return <OperationSelector {...props} />;
      default:
        return null;
    };
  };

  return (
    <PFContainer display="flex" flexDirection="column" gap="s">
      {
        showCacheable
          ? <PFContainer display='flex' alignItems='center'
            justifyContent='space-between' gap='s'>
            <PFText size='s'>Store result</PFText>
            <PFCheckbox checked={is_cacheable}
              onChange={() => {
                setData({ ...data, is_cacheable: !is_cacheable });
              }} />
          </PFContainer>
          : null
      }

      <PFContainer display="flex">
        <PFContainer flex={1}>
          <PFSelect
            value={source}
            onChange={onChangeSource}
            options={DYNAMIC_DATA_OPTIONS}
            style={{ fontSize: 'var(--text-label)' }}
            optionsStyles={{
              color: 'var(--white)'
            }}
            listStyles={{
              border: '1px solid var(--purple-300)',
              backgroundColor: 'var(--purple-500)'
            }}
          />
        </PFContainer>
        <PFButton type="support" onClick={removeData}>
          <PFIcon icon="bx bx-trash" size="m" />
        </PFButton>
      </PFContainer>
      {renderReferenceSelector()}
    </PFContainer>
  );
};

SourceSelector.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  removeData: PropTypes.func.isRequired,
  showCacheable: PropTypes.bool,
};

export default SourceSelector;
