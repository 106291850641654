import { PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import './Breadcrumbs.css';

const Breadcrumbs = ({ canvas }) => {
  const getSelectedComponentAncestry = useCallback(() => {
    const elements = [];
    let currentNode = canvas.selected;

    while (currentNode && currentNode.tagName !== 'BODY') {
      elements.push(currentNode);
      currentNode = currentNode.parentNode;
    }

    if (currentNode && currentNode.tagName === 'BODY') {
      elements.push(currentNode);
    }

    return elements.reverse();
  }, [canvas.selected]);

  return (
    <PFContainer className='pf-breadcrumbs'>
      <ul>
        {
          getSelectedComponentAncestry().map((familyMember, level) => {
            /**
             * Since we're on an iframe we'll ignore the component's
             * boilerplate elements.
             */
            if (level < 2
              || !familyMember.getAttribute('data-alias')) return null;
            return (
              <li
                onMouseOver={() => {
                  canvas.setHover(familyMember);
                }}
                onClick={
                  () =>
                    canvas.setSelectedCanvasComponent({ target: familyMember })}
                key={level}>
                <span>
                  {familyMember.getAttribute('data-alias')}
                </span>
              </li>
            );
          })
        }
      </ul>
    </PFContainer>
  );
};

Breadcrumbs.propTypes = {
  canvas: PropTypes.object
};

export default Breadcrumbs;
