import PropTypes from 'prop-types';

import { PFContainer, PFLine } from 'PFComponents/common';
import TreeNode from './TreeNode';

const TreeLevel = ({ canvas, parent, nodes, setNodes, sideline }) => {
  const renderTreeNodes = () => {
    return nodes.map((node, index) => {
      const setNode = (newNode) => {
        const newNodes = [...nodes];
        newNodes.splice(i, 1, newNode);
        setNodes(newNodes);
      };

      return (
        <TreeNode
          key={node.build.id}
          canvas={canvas}
          index={index}
          parent={parent}
          node={node}
          setNode={setNode} />
      );
    });
  };

  return (
    <PFContainer display="flex">
      {sideline && <PFLine orientation="vertical" margin="bottom-s" />}
      <PFContainer style={{ marginLeft: sideline ? 6 : 0 }}>
        {renderTreeNodes()}
      </PFContainer>
    </PFContainer>
  );
};

TreeLevel.defaultProps = {
  sideline: false,
};

TreeLevel.propTypes = {
  canvas: PropTypes.object.isRequired,
  parent: PropTypes.object,
  nodes: PropTypes.array.isRequired,
  setNodes: PropTypes.func.isRequired,
  sideline: PropTypes.bool
};

export default TreeLevel;
