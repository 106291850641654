import {
  PFButton,
  PFContainer, PFIcon, PFInputAutosize, PFLoader
} from 'PFComponents/common';
import PropTypes from 'prop-types';

/* eslint-disable */
const AIInput = ({
  functionDescription,
  setFunctionDescription,
  getAIResponse,
  loading }) => {
  return (
    <PFContainer display="flex" alignItems="center" gap="m">
      <PFContainer flex={1}>
        <PFInputAutosize value={functionDescription}
          onChange={(e) => {
            setFunctionDescription(e.target.value);
          }}
          placeholder="What do you need this instruction to do"
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              e.target.blur();
              getAIResponse();
            }
          }}
        />
      </PFContainer>

      {
        loading ? <PFLoader color="var(--primary)" size='s'
          margin='left-s' />
          : <PFButton height='auto'
            padding='top-xs bottom-xs left-s right-s'
            style={{
              borderRadius: '100%'
            }}
            onClick={() => {
              getAIResponse();
            }}>
            <PFIcon icon='bx bxs-magic-wand' size='s' />
          </PFButton>
      }

    </PFContainer>
  );
};

AIInput.propTypes = {
  functionDescription: PropTypes.string,
  setFunctionDescription: PropTypes.func,
  getAIResponse: PropTypes.func,
  loading: PropTypes.bool,
};

export default AIInput;
