import { createSlice } from '@reduxjs/toolkit';

import { storageUtils } from 'PFUtils';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    access_token: storageUtils.retrieveFromLocalStorage('access_token') ?? null
  },
  reducers: {
    setAccessToken: (_state, { payload }) => {
      storageUtils.saveToLocalStorage('access_token', payload);
      return { access_token: payload };
    },
    removeAccessToken: (_state) => {
      storageUtils.deleteFromLocalStorage('access_token');
      return { access_token: null };
    }
  }
});

export default authSlice.reducer;
export const {
  setAccessToken,
  removeAccessToken
} = authSlice.actions;
