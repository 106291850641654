export const phoneNumber = (phoneStr) => {
  const cleaned = ('' + phoneStr).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{1,2})?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : '';
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};

export const currency = (currencyStr) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(currencyStr);
};
