import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, { currentFunctionActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DBSelectionModal } from './components';


const PFScreenFunctionDBs = () => {
  const currentFunction = useSelector((state) => state.current_function);
  const dbs = currentFunction.associated_dbs?.docs ?? [];

  const [selectionOpen, setSelectionOpen] = useState(false);

  const [removeAsyncCall, , removeError, dismissRemoveError]
    = useAsyncCall(false);

  const [RemovalDialog, confirmRemoval] = useConfirmation(
    'Are you sure you want to disconnect this database?',
  );

  const removeDB = (dbId) => {
    removeAsyncCall(async () => {
      if (await confirmRemoval()) {
        store.dispatch(currentFunctionActions.removeDB(dbId));
        await builderV2.removeDBFromFunction(currentFunction._id, dbId);
      }
    });
  };

  const renderDBs = () => {
    return dbs.map((db) => {
      return (
        <PFItemCard key={db._id} path={`/databases/${db._id}/tables`}
          options={{
            'Disconnect': () => removeDB(db._id),
            'Copy ID': () => generalUtils.copyToClipboard(db._id)
          }}>
          <PFText padding="top-m">{db.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {db._id}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Databases</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={() => setSelectionOpen(true)}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Database</PFText>
          </PFActionCard>
          {renderDBs()}
        </PFContainer>
      </PFContainer>
      <DBSelectionModal active={selectionOpen}
        onDismiss={() => setSelectionOpen(false)} />
      <RemovalDialog />
      <PFAlert
        message={removeError}
        open={!!removeError}
        onClose={dismissRemoveError} />
    </PFContainer>
  );
};

export default PFScreenFunctionDBs;
