import {
  PFContainer, PFInput
} from 'PFComponents/common';
import PropTypes from 'prop-types';

const AutomationNameEditor = ({ automation, updateAutomation }) => {
  const setName = (alias) => updateAutomation({
    ...automation,
    alias
  });
  return (
    <PFContainer className="editor" background="none"
      display="flex" gap="m" alignItems="center"
      justifyContent="space-between"
      style={{ position: 'relative', zIndex: 1 }}>
      <PFContainer display="flex" alignItems="center" gap="s">
        <PFInput className="alt" width="400px"
          value={automation?.alias} placeholder="Operation name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          fontSize='l' />

      </PFContainer>
    </PFContainer>
  );
};

AutomationNameEditor.propTypes = {
  automation: PropTypes.object,
  updateAutomation: PropTypes.func
};

export default AutomationNameEditor;
