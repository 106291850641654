import { PFButton, PFContainer, PFModal, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';

/**
 * Custom hook to display a custom confirmation window to the user.
 *
 * @param {string} title - Title of the confirmation window.
 * @param {string} message - Message of the confirmation window.
 * @param {object} styles - Message of the confirmation window.
 *
 * @return {[ReactComponent, Promise<bool>]} - Dialog component and promise
 * that will return the confirmation as a boolean.
 */
const useConfirmation = (title, message) => {
  const [promise, setPromise] = useState(null);

  const confirm = () => new Promise((resolve, reject) => {
    setPromise({ resolve });
  });

  const handleConfirm = () => {
    promise?.resolve(true);
    setPromise(null);
  };

  const handleCancel = () => {
    promise?.resolve(false);
    setPromise(null);
  };

  const ConfirmationDialog = ({ styles }) => (
    <PFModal active={promise !== null} zIndex={10000} >
      <PFContainer type="form" padding="l"
        radius="l" width={800} style={{
          ...styles
        }}>
        <PFContainer margin="bottom-m"
          display="flex" alignItems="center" justifyContent="space-between">
          <PFText type="support">{title ?? ''}</PFText>
        </PFContainer>
        <PFContainer>
          <PFText>{message ?? ''}</PFText>
        </PFContainer>
        <PFContainer margin="top-l"
          display="flex" justifyContent="end" gap="m">
          <PFButton type="secondary" width={100} onClick={handleCancel}>
            Cancel
          </PFButton>
          <PFButton width={100} onClick={handleConfirm}>
            Yes
          </PFButton>
        </PFContainer>
      </PFContainer >
    </PFModal >
  );

  ConfirmationDialog.propTypes = {
    styles: PropTypes.object
  };

  return [ConfirmationDialog, confirm];
};


export default useConfirmation;
