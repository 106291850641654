// Constant that manages the threshold for a component to be considered as
// dropped outside (either before or after)
const BORDER_DROP = 5;

// List of components that allow for other components to be dropped inside
export const containerComponents = [
  'Accordioncontainer',
  'Accordioncontent',
  'Accordionheader',
  'Accordionicon',
  'Accordionitem',
  'Button',
  'Container',
  'Form',
  'Formfieldmetadata',
  'Heading',
  'Icon',
  'Label',
  'Link',
  'List',
  'Metawrapper',
  'Multistep',
  'Parragraph',
  'Readmore'
];

/**
 * Funciton the get the information of a drop zone (to later be used in
 * 'getDropPosition').
 *
 * @param {object} component - Component configuration of the drop zone to be
 * used.
 * @param {object} event - Event that triggered this component to be used.
 *
 * @return {object} Information used
 */
export const getDropZone = (component, event) => {
  return {
    component: component.component,
    target: event.target,
    mouse: { x: event.clientX, y: event.clientY },
    orientation: 'VERTICAL' // TODO: Determine orientation
  };
};

/**
 * Function to return the position where a component should be dropped
 * depending of the position of the mouse and the parent component.
 *
 * @param {Object} dropZone Dropzone object of a canvas.
 *
 * @return {'BEFORE'|'AFTER'|'FIRST'|'LAST'} Position where the component
 * should be dropped.
 */
export const getDropPosition = (dropZone) => {
  const { component, target, mouse, orientation } = dropZone;

  const { bottom, top, left, right } = target.getBoundingClientRect();
  const { x, y } = mouse;

  const componentIsContainer = containerComponents.includes(component);

  if (orientation === 'VERTICAL') {
    if (componentIsContainer) {
      if (y <= top + BORDER_DROP) return 'BEFORE';
      else if (y >= bottom - BORDER_DROP) return 'AFTER';
    }
    const midY = (bottom - top) / 2;
    if (y <= midY) {
      if (componentIsContainer) {
        return 'FIRST';
      } else {
        return 'BEFORE';
      }
    } else if (y > midY) {
      if (componentIsContainer) {
        return 'LAST';
      } else return 'AFTER';
    }
  } else if (orientation === 'HORIZONTAL') {
    if (componentIsContainer) {
      if (x <= left + BORDER_DROP) return 'BEFORE';
      else if (x >= right - BORDER_DROP) return 'AFTER';
    }

    const midX = (right - left) / 2;
    if (x <= midX) {
      if (componentIsContainer) return 'FIRST';
      else return 'BEFORE';
    } else if (x > midX) {
      if (componentIsContainer) return 'LAST';
      else return 'AFTER';
    }
  }
};
