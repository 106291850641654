import { PFContainer, PFSelect, PFText } from 'PFComponents/common';
import { DB } from 'PFConstants';
import { format } from 'PFUtils';
import { AddColumnComponent } from '../components';
import { rowActions } from './actions';
import { DecimalCell, IntegerCell } from './CustomizedCells';

const { TYPE_TO_COLUMN } = DB;

export const getColumnsDefinition = (
  tableConfig,
  rows,
  setUpdateTable,
  apiRef,
  setExitModeWarning
) => {
  const actionsColumn = getActionsColumn(rows, apiRef, setExitModeWarning);
  const addColumn = getAddColumn(tableConfig,
    setUpdateTable, setExitModeWarning);
  const customComparator = (a, b) => {
    const stringValueA = String(a).toLowerCase();
    const stringValueB = String(b).toLowerCase();
    const pattern = /(\d+)|(\D+)/g;

    const parseString = (str) => {
      const groups = [];
      let match;
      while ((match = pattern.exec(str)) !== null) {
        groups.push(match[0]);
      }
      return groups;
    };

    const groupsA = parseString(stringValueA);
    const groupsB = parseString(stringValueB);

    for (let i = 0; i < Math.min(groupsA.length, groupsB.length); i++) {
      const groupA = groupsA[i];
      const groupB = groupsB[i];

      if (!isNaN(groupA) && !isNaN(groupB)) {
        const numA = parseInt(groupA, 10);
        const numB = parseInt(groupB, 10);
        if (numA !== numB) {
          return numA - numB;
        }
      } else {
        if (groupA !== groupB) {
          return groupA.localeCompare(groupB);
        }
      }
    }

    return groupsA.length - groupsB.length;
  };

  const columns = tableConfig.fields.map((dbField) => {
    if (dbField.column_name === 'id') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        hideable: false,
        ...TYPE_TO_COLUMN[dbField.type_name]
      };
    }
    if (dbField.type_name === 'Date') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        renderEditCell: (params) => (
          <input type="date" value={params.value}
            onChange={(e) => {
              const maxDate = new Date('9999-12-31');
              const inputDate = new Date(e.target.value);
              if (inputDate > maxDate) {
                apiRef.current.setEditCellValue({
                  id: params.id, field: params.field,
                  value: '9999-12-31'
                });
              } else {
                apiRef.current.setEditCellValue({
                  id: params.id, field: params.field,
                  value: e.target.value
                });
              }
            }} style={{
              width: '100%',
              backgroundColor: 'transparent',
              border: 'none'
            }} />
        ),
        ...TYPE_TO_COLUMN[dbField.type_name]
      };
    }
    if (dbField.type_name === 'Date & Time') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        renderEditCell: (params) => {
          const date = new Date(params.value);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
          return (
            <input type='datetime-local'
              value={params.value ? formattedDate : params.value}
              onInput={(e) => {
                const maxDate = new Date('9999-12-31T23:59');
                const inputDate = new Date(e.target.value);
                if (inputDate > maxDate) {
                  apiRef.current.setEditCellValue({
                    id: params.id, field: params.field,
                    value: '9999-12-31T23:59'
                  });
                } else {
                  apiRef.current.setEditCellValue({
                    id: params.id, field: params.field,
                    value: e.target.value
                  });
                }
              }} max="9999-12-31T23:59:59"
              min='1753-01-01T00:00'
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: 'none'
              }} />
          );
        },
        renderCell: (params) => {
          let formattedDate;
          if (params.value) {
            const [datePart, timePart] = params.value.split('T');
            const [year, month, day] = datePart.split('-');
            const [hours, minutes] = timePart.split(':');
            formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
          } else {
            formattedDate = params.value;
          }

          return (<PFText>
            {formattedDate}
          </PFText>);
        },
        ...TYPE_TO_COLUMN[dbField.type_name],
        type: 'string'
      };
    }
    if (dbField.type_name === 'Boolean') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        renderEditCell: (params) => {
          return (<PFContainer width='100%'>
            <PFSelect value={params.value}
              placeholder='select '
              onChange={(e) => {
                apiRef.current.setEditCellValue({
                  id: params.id, field: params.field,
                  value: e.target.value
                });
              }} style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: 'none'
              }} options={[
                { label: 'true', value: 'true' },
                { label: 'false', value: 'false' }
              ]} />
          </PFContainer>
          );
        },
        ...TYPE_TO_COLUMN[dbField.type_name]
      };
    }
    if (dbField.type_name === 'Integer'
      || dbField.type_name === 'Big Integer') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        renderEditCell: (params) => {
          return <IntegerCell params={params} apiRef={apiRef} />;
        },
        ...TYPE_TO_COLUMN[dbField.type_name],
        type: 'string'
      };
    }
    if (dbField.type_name === 'Float'
      || dbField.type_name === 'Decimal') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        renderEditCell: (params) => {
          /* eslint-disable max-len */
          return (<DecimalCell params={params} apiRef={apiRef} />
          );
        },
        type: 'string',
        width: 300
      };
    }
    if (dbField.type_name === 'Money') {
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        renderEditCell: (params) => {
          return (<DecimalCell params={params} apiRef={apiRef} />
          );
        },
        renderCell: (params) => {
          return (<PFText>
            {params.value || params.value === '0'
              || params.value === 0
              ? format.currency(params.value) : params.value}
          </PFText>);
        },
        type: 'string',
        width: 300
      };
    }
    if (dbField.type_name === 'String') {
      let columnWidth;
      if (dbField.length < 50) {
        columnWidth = 150;
      } else {
        columnWidth = 350;
      }
      return {
        field: dbField.column_name,
        headerName: dbField.alias,
        editable: !dbField.is_primary_key,
        headerAlign: 'left',
        sortComparator: customComparator,
        ...TYPE_TO_COLUMN[dbField.type_name],
        width: columnWidth
      };
    }
    return {
      field: dbField.column_name,
      headerName: dbField.alias,
      editable: !dbField.is_primary_key,
      headerAlign: 'left',
      ...TYPE_TO_COLUMN[dbField.type_name]
    };
  });

  return [actionsColumn, ...columns, addColumn];
};

const getActionsColumn = (rows, apiRef, setExitModeWarning) => {
  return {
    field: 'Row actions',
    type: 'actions',
    width: 80,
    cellClassName: 'actions',
    getActions: ({ id }) => rowActions(id, rows, apiRef, setExitModeWarning),
  };
};

const getAddColumn = (tableConfig, setUpdateTable, setExitModeWarning) => {
  return {
    field: 'Add column',
    type: 'actions',
    sortable: false,
    columnSelector: false,
    width: 150,
    editable: false,
    renderHeader: () => (
      <AddColumnComponent
        tableName={tableConfig.table_name}
        setUpdateTable={setUpdateTable}
        setExitModeWarning={setExitModeWarning} />
    ),
    getActions: () => [],
  };
};

