import React, { ReactNode, useState } from 'react';
import * as Styled from './password.styled';

const icons = {
    visibility: (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0002 5.00016C13.1585 5.00016 15.9752 6.77516 17.3502 9.5835C15.9752 12.3918 13.1585 14.1668 10.0002 14.1668C6.84183 14.1668 4.02516 12.3918 2.65016 9.5835C4.02516 6.77516 6.84183 5.00016 10.0002 5.00016ZM10.0002 3.3335C5.8335 3.3335 2.27516 5.92516 0.833496 9.5835C2.27516 13.2418 5.8335 15.8335 10.0002 15.8335C14.1668 15.8335 17.7252 13.2418 19.1668 9.5835C17.7252 5.92516 14.1668 3.3335 10.0002 3.3335ZM10.0002 7.50016C11.1502 7.50016 12.0835 8.4335 12.0835 9.5835C12.0835 10.7335 11.1502 11.6668 10.0002 11.6668C8.85016 11.6668 7.91683 10.7335 7.91683 9.5835C7.91683 8.4335 8.85016 7.50016 10.0002 7.50016ZM10.0002 5.8335C7.9335 5.8335 6.25016 7.51683 6.25016 9.5835C6.25016 11.6502 7.9335 13.3335 10.0002 13.3335C12.0668 13.3335 13.7502 11.6502 13.7502 9.5835C13.7502 7.51683 12.0668 5.8335 10.0002 5.8335Z"
                fill="black"
            />
        </svg>
    ),
    visibilityOff: (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0002 4.99984C13.1585 4.99984 15.9752 6.77484 17.3502 9.58317C16.8585 10.5998 16.1668 11.4748 15.3418 12.1832L16.5168 13.3582C17.6752 12.3332 18.5918 11.0498 19.1668 9.58317C17.7252 5.92484 14.1668 3.33317 10.0002 3.33317C8.94183 3.33317 7.92516 3.49984 6.96683 3.80817L8.34183 5.18317C8.8835 5.07484 9.4335 4.99984 10.0002 4.99984ZM9.1085 5.94984L10.8335 7.67484C11.3085 7.88317 11.6918 8.2665 11.9002 8.7415L13.6252 10.4665C13.6918 10.1832 13.7418 9.88317 13.7418 9.57484C13.7502 7.50817 12.0668 5.83317 10.0002 5.83317C9.69183 5.83317 9.40016 5.87484 9.1085 5.94984ZM1.67516 3.22484L3.9085 5.45817C2.55016 6.52484 1.47516 7.9415 0.833496 9.58317C2.27516 13.2415 5.8335 15.8332 10.0002 15.8332C11.2668 15.8332 12.4835 15.5915 13.6002 15.1498L16.4502 17.9998L17.6252 16.8248L2.85016 2.0415L1.67516 3.22484ZM7.92516 9.47484L10.1002 11.6498C10.0668 11.6582 10.0335 11.6665 10.0002 11.6665C8.85016 11.6665 7.91683 10.7332 7.91683 9.58317C7.91683 9.5415 7.92516 9.5165 7.92516 9.47484V9.47484ZM5.09183 6.6415L6.55016 8.09984C6.3585 8.55817 6.25016 9.05817 6.25016 9.58317C6.25016 11.6498 7.9335 13.3332 10.0002 13.3332C10.5252 13.3332 11.0252 13.2248 11.4752 13.0332L12.2918 13.8498C11.5585 14.0498 10.7918 14.1665 10.0002 14.1665C6.84183 14.1665 4.02516 12.3915 2.65016 9.58317C3.2335 8.3915 4.0835 7.40817 5.09183 6.6415Z"
                fill="black"
            />
        </svg>
    ),
};

export type PasswordProps = {
    /**
     * to allow the browser to predict the value
     */
    autoComplete?: string;

    /**
     * class names override
     */
    className?: string;

    /**
     * styling overrides
     */
    configStyles?: string;

    /**
     * to disable or enable the input element
     */
    disabled?: boolean;

    /**
     * an identifier for the input element
     */
    id: string;

    /**
     * specifies the name of the input element
     */
    name: string;

    /**
     * a function to be triggered when the input element loses focus
     */
    onBlur?: () => void;

    /**
     * a function to be triggered when the input element value change
     */
    onChange?: any;

    /**
     * a function to be triggered when the input element gets focus
     */
    onFocus?: () => void;

    /**
     * a placeholder for the input element
     */
    placeholder?: string;

    /**
     * to keep a user from changing the value
     */
    readOnly?: boolean;

    /**
     * to specify that the input must be filled
     */
    required?: boolean;

    /**
     * field value
     */
    value?: string;

    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;
};

export function Password({
    autoComplete = 'off',
    className,
    children,
    configStyles,
    disabled,
    id,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    readOnly = false,
    required,
    value,
    ...props
}: PasswordProps) {
    const [revealPassword, setRevealPassword] = useState<boolean>(false);

    return (
        <>
            <Styled.Password
                autoComplete={autoComplete}
                className={className}
                configStyles={configStyles}
                disabled={disabled}
                name={name}
                id={id}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                readOnly={readOnly}
                required={required}
                type={revealPassword ? 'text' : 'password'}
                value={value || ''}
                {...props}
            />
            <Styled.IconBox onClick={() => setRevealPassword(!revealPassword)}>
                {revealPassword ? icons.visibilityOff : icons.visibility}
            </Styled.IconBox>
        </>
    );
}
