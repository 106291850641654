import { Snackbar, SnackbarContent } from '@mui/material';
import PropTypes from 'prop-types';
import PFText from '../PFText/PFText';

const PFAlertUser = ({ open, close, message }) => {
  return (<Snackbar open={open}
    autoHideDuration={3000} onClose={(e, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      close();
    }} >
    <SnackbarContent style={{
      backgroundColor: 'var(--purple-200)',
    }}
      message={<PFText id="client-snackbar">
        {message}
      </PFText>}
    />
  </Snackbar>);
};

PFAlertUser.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  message: PropTypes.string
};

export default PFAlertUser;
