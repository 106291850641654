import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import store, { currentAppActions, currentFunctionActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DBCreationModal } from './components';


const PFScreenUserDBs = () => {
  const dbs = useSelector((state) => state.user.associated_dbs.docs ?? []);

  const [creationFormOpen, setCreationFormOpen] = useState(false);
  useEffect(() => {
    store.dispatch(currentAppActions.setCurrentApp(null));
    store.dispatch(currentFunctionActions.setCurrentFunction(null));
  }, []);

  const renderDBs = () => {
    return dbs.map((db) => {
      return (
        <PFItemCard key={db._id} path={`/databases/${db._id}/tables`}
          options={{
            'Copy ID': () => generalUtils.copyToClipboard(db._id)
          }}>
          <PFText padding="top-m">{db.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {db._id}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <PFContainer>
      <PFContainer display="flex" margin="vertical-m"
        justifyContent="space-between" alignItems="flex-end">
        <PFText tag="h1" size="l" weight={400}>Your databases</PFText>
        <PFText type="secondary">{dbs.length} Total</PFText>
      </PFContainer>
      <PFContainer display="grid"
        gridTemplateColumns="1fr 1fr" gap="m">
        <PFActionCard onClick={() => setCreationFormOpen(true)}>
          <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
          <PFText>New Database</PFText>
        </PFActionCard>
        {renderDBs()}
      </PFContainer>
      <DBCreationModal active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
    </PFContainer>
  );
};

export default PFScreenUserDBs;
