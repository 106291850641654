import React, { ReactNode, useEffect } from 'react';
import * as Styled from './modal.styled';
import { Portal } from 'react-portal';

export type ModalProps = {
  /**
   * To identify the component
   */
  id?: string;
  /**
   * content to be rendered insie the modal
   */
  children?: ReactNode;

  /**
   * class names override
   */
  className?: string;

  /**
   * for component-level styling override (Design System)
   */
  configStyles?: string;

  /**
   * determines if the modal is open or closed from the parent
   */
  isModalOpen: boolean;

  /**
   * to set the modal to open or closed
   */
  isModalOpenHandler: (isOpen: boolean) => void;

  /**
   * determines if the modal can be closed in other ways besides actions made inside the modal
   */
  modeless?: boolean;

  /**
   * size of the modal
   */
  overlay?: 'auto' | 'full';
};

export function Modal({
  id,
  children,
  className,
  configStyles,
  isModalOpen,
  isModalOpenHandler,
  modeless = false,
  overlay = 'auto',
}: ModalProps) {
  const closeModal = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      isModalOpenHandler(false);
    }
  };

  const handleCloseModalClick = () => {
    isModalOpenHandler(false);
  };

  useEffect(() => {
    if (modeless) {
      window.addEventListener('keydown', closeModal);
      return () => window.removeEventListener('keydown', closeModal);
    } else {
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = 'auto');
    }
  }, [modeless]);

  return isModalOpen ? (
    <Portal>
      <Styled.Modal configStyles={configStyles} className={className} id={id}>
        <Styled.ModalOverlay onClick={() => modeless && isModalOpenHandler(false)}></Styled.ModalOverlay>
        <Styled.ModalContent overlay={overlay}>
          <Styled.CloseButton onClick={handleCloseModalClick}>
            <Styled.CloseIcon>+</Styled.CloseIcon>
          </Styled.CloseButton>
          {children}
        </Styled.ModalContent>
      </Styled.Modal>
    </Portal>
  ) : null;
}
