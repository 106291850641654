import axios from 'axios';
import {
  twoappOrigin
} from 'PFConfig/env';
import { storageUtils } from 'PFUtils';

const authPefai = axios.create({
  baseURL: twoappOrigin + '/twoapp/v1',
});

authPefai.interceptors.request.use(
  async (config) => {
    const jwt = storageUtils.retrieveFromLocalStorage('access_token');
    if (jwt) config.headers.set('Authorization', 'Bearer ' + jwt);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

authPefai.interceptors.response.use(
  async (res) => {
    return res?.data?.data;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err.response?.data?.data ?? err.message);
  }
);

export default {
  login: async (values) =>
    await authPefai.post('/login/password', values),

  getUser: async () =>
    await authPefai.get('/account/info'),

  createAccount: async (appData) =>
    await authPefai.post(`/account/`, appData),

  verifyEmail: async (appData) =>
    await authPefai.put(`/account/activate`, appData),

  updatePassword: async (appData) =>
    await authPefai.put(`/account/password`, appData),

  resetPasswordRequest: async (appData) =>
    await authPefai.post(`/account/password/reset`, appData),

  resetPassword: async (appData) =>
    await authPefai.put(`/account/password/reset`, appData),
};
