import isEventValueValid from './DropdownPropCheck';

/**
 * This function helps create a combination string of
 * {SizeValue}{SizeUnit} e.g. "10px" depending on the eventValue
 * where it can be any of the 2 mentioned.
 *
 * This function will validate if no SizeValue is passed it will
 * condition to 0 to provide an inital value.
 *
 * In case eventValue is empty a "" string will be returned.
 * > This is a specific case due to styled-components sanitizing bad rule value
 * that'll remove the rule fully.
 *
 * @param {string} currentRuleValue? - current css rule value, if any
 * @param {string} eventValue - event.target.value as string
 * @return {string} - a sanitized {SizeValue}{SizeUnit} or empty string
 */
const validateSizeInput = (currentRuleValue, eventValue) => {
  let recomposedValue = '';
  const numberRegex = new RegExp(/\d+/g);
  const stringRegex = new RegExp(/[a-zA-Z%]+/g);
  const validationLookupSet = ['auto', 'inherit', 'unset',
    'initial', 'max-content', 'min-content'];

  // Return '' to clear value
  if (!eventValue) {
    return '';
  }

  // Validation - for when we are trying to match a string value
  // rather than a composition of {SizeValue}{SizeUnit}
  if (isEventValueValid(eventValue, 'Size', validationLookupSet)) {
    return eventValue;
  }

  // Validation - to compose a {SizeValue}{SizeUnit} return value
  if (currentRuleValue
    && !isEventValueValid(currentRuleValue, 'Size', validationLookupSet)) {
    const sizeValue = currentRuleValue.match(numberRegex)[0];
    const sizeUnit = currentRuleValue.match(stringRegex)[0];

    if (!eventValue.match(stringRegex) || parseInt(eventValue)) {
      recomposedValue = `${eventValue}${sizeUnit}`;
    } else {
      recomposedValue = `${sizeValue}${eventValue}`;
    }
  } else {
    // This will validate if no attribute is in object
    if (!isNaN(parseInt(eventValue))) {
      // Size Unit (default value) - on change with no Size Unit, set to 'px'
      recomposedValue = `${eventValue}px`;
    } else {
      // Size Value (default value) - on change with no Size Value, set to 0
      recomposedValue = `0${eventValue}`;
    }
  }


  return recomposedValue;
};

export default validateSizeInput;
