import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFModal,
  PFSelect
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import store, { currentDBActions } from 'PFStore';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { CREATE_DATA_TYPES } from 'src/constants/DB';


const dataTypeOptions = Object.values(CREATE_DATA_TYPES).map(
  ({ type_name }) => ({ label: type_name, value: type_name }));

const PFTableCreationModal = ({
  dbId,
  baseURL,
  active,
  onDismiss,
  updateState
}) => {
  const navigate = useNavigate();
  const currentUserId = useSelector((state) => state.user._id);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm(formConfigFromTable(null));

  const onSubmit = (values) => {
    values.fields = values.fields.map((field) => ({
      ...field,
      ...CREATE_DATA_TYPES[field.type_name]
    }));
    asyncCall(async () => {
      const { InsertedID } = await builderTest(currentUserId).
        createDBTable(dbId, values);

      if (updateState) {
        const db = await builderTest(currentUserId).
          getDatabase(dbId);
        store.dispatch(currentDBActions.setCurrentDB(db));
      }

      onDismiss();
      form.setConfig(formConfigFromTable(null));
      navigate(`${baseURL}/${InsertedID}`);
    });
  };

  const addField = () => {
    const newFormConfig = formConfigFromTable(form.values);
    newFormConfig.fields.push({
      alias: {
        default_value: '',
        validation_type: 'text',
        error_message: 'Field name missing',
      },
      type_name: {
        default_value: 'String',
        validation_type: 'text',
        error_message: 'Field type missing',
      },
    });
    form.setConfig(newFormConfig);
  };

  const removeField = (index) => {
    const newFormConfig = formConfigFromTable(form.values);
    newFormConfig.fields.splice(index, 1);
    form.setConfig(newFormConfig);
  };

  const renderFields = () => {
    return form.values.fields.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <PFInput name={`fields[${i}].alias`}
            disabled={i === 0}
            placeholder="Field name"
            fieldHook={fieldHook}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }} />
          <PFSelect name={`fields[${i}].type_name`}
            options={item.alias === 'ID'
              ? dataTypeOptions
              : dataTypeOptions.filter((el) => el.label !== 'Auto Increment')}
            disabled={i === 0}
            placeholder="Field type"
            fieldHook={fieldHook}
            optionsStyles={{
              color: 'var(--white)'
            }}
            listStyles={{
              border: '1px solid var(--purple-300)',
              backgroundColor: 'var(--purple-500)'
            }}
          />
          <PFButton type="support"
            disabled={i === 0}
            onClick={() => removeField(i)}>
            <PFIcon icon={i === 0 ? 'bx bx-key' : 'bx bx-trash'} size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss} outsideDismiss={false}>
        <PFContainer type="toolbar" padding="l" radius="l" width={800}>
          <PFForm form={form} submit={onSubmit}>
            <PFContainer margin="bottom-m" gap="l"
              display="flex" justifyContent="space-between">
              <PFInput
                width="100%"
                className="alt"
                placeholder="Table name"
                name="alias"
                fieldHook={fieldHook}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }} />
              <PFButton type="support" onClick={onDismiss}
                padding="none" height={28}
                style={{
                  marginTop: 7,
                  background: 'var(--purple-400)',
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}>
                <PFIcon icon="bx bx-x" size="l" />
              </PFButton>
            </PFContainer>
            <PFContainer display="grid" gap="s" margin="top-l"
              gridTemplateColumns="1.5fr 1fr .2fr">
              {renderFields()}
              <PFButton type="support" onClick={addField}
                style={{ textAlign: 'left', justifySelf: 'start' }}>
                + add new field
              </PFButton>
            </PFContainer>
            <PFContainer margin="top-l" display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={loading}>
                Create table
              </PFButton>
            </PFContainer>
          </PFForm>
        </PFContainer >
      </PFModal >
      <PFAlert message={error} open={!!error}
        onClose={dismissError} />
    </>
  );
};

PFTableCreationModal.propTypes = {
  dbId: PropTypes.string.isRequired,
  baseURL: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  updateState: PropTypes.bool,
};

const formConfigFromTable = (table) => {
  let tableFields = table?.fields ?? [];
  tableFields.shift();
  tableFields = tableFields.map((field) => {
    return {
      ...field,
      alias: {
        default_value: field?.alias ?? '',
        validation_type: 'text',
        error_message: 'Field name missing',
      },
      type_name: {
        default_value: field?.type_name ?? 'String',
        validation_type: 'text',
        error_message: 'Field type missing',
      },
    };
  });

  return {
    alias: {
      default_value: table?.alias ?? '',
      validation_type: 'text',
      error_message: 'Please provide a name for your table',
    },
    description: table?.description ?? '',
    fields: [
      {
        alias: {
          default_value: 'ID',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        type_name: {
          default_value: 'Auto Increment',
          validation_type: 'text',
          error_message: 'Field type missing',
        },
        is_primary_key: true,
      },
      ...tableFields
    ]
  };
};

export default PFTableCreationModal;
