import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFSelect,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import CopyRootButton from '../DynamicDataSelector/CopyRootButton';


const destinationOptions = [
  { label: 'Self', value: 'self' },
  { label: 'Children', value: 'children' }
];

const RepeaterEditor = ({ repeater, setRepeater }) => {
  const addRepeater = () => setRepeater({
    source: '',
    item_name: 'item',
    destination: 'self'
  });
  const removeRepeater = () => setRepeater(null);

  const { source, item_name: itemName, destination } = repeater ?? {};
  const setSource = (source) => setRepeater({ ...repeater, source });
  const setDestination
    = (destination) => setRepeater({ ...repeater, destination });

  return (
    <PFContainer padding="horizontal-s vertical-m">
      <PFContainer display="flex" justifyContent="space-between"
        alignItems="center" height={24}>
        <PFText style={{ fontSize: 'var(--text-header)' }}>Repeater</PFText>
        {!!repeater
          ? <PFButton type="support" padding="none" height={30}
            onClick={removeRepeater}>
            <PFIcon icon="bx bx-trash" size="m" />
          </PFButton>
          : null}
      </PFContainer>
      <PFContainer display="flex" flexDirection="column" gap="m" margin="top-m">
        {!repeater
          ? <PFButton width="100%" type="secondary" onClick={addRepeater}
            style={{ fontSize: 'var(--text-label)' }}
          >
            Set repeater
          </PFButton>
          : <PFContainer>
            <PFContainer display="grid" gap="s" alignItems="center"
              gridTemplateColumns="auto 1fr"
              style={{
                position: 'relative'
              }}>
              <PFText type="secondary" style={{ fontSize: '12px' }}>
                Repeat</PFText>
              <PFSelect
                margin='bottom-s'
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                options={destinationOptions}
                style={{ fontSize: '12px' }}
                optionsStyles={{ fontSize: '12px' }} />
              <PFText type="secondary" style={{ fontSize: '12px' }}>
                Source</PFText>
              <PFInput
                value={source}
                onChange={(e) => {
                  const filteredValue = e.target.value.replace(/[{}]/g, '');
                  setSource(filteredValue);
                }}
                placeholder="Source"
                fontSize="label" style={{ height: 30 }} />

            </PFContainer>
            <CopyRootButton copyTarget={`{{repeater:${itemName
              ? itemName : ''}.}}`} />
          </PFContainer>
        }
      </PFContainer>
    </PFContainer>
  );
};

RepeaterEditor.propTypes = {
  repeater: PropTypes.object,
  setRepeater: PropTypes.func.isRequired,
};

export default RepeaterEditor;
