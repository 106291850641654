import styled from 'styled-components';

const FileUpload = styled.label<{ configStyles: string | undefined }>`
    > input {
        display: none;
    }
    ${(props) => props.configStyles}
`;

const FileUploadControl = styled.div<any>`
    ${(props) => props.configStyles}
`;

export { FileUpload, FileUploadControl };
