import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFLink,
  PFLoader,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useAsyncCalls } from 'PFHooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DomainRow, EnvSelectionModal } from './components';

const PFScreenAppDomainDetail = () => {
  const { domain } = useParams();

  const currentApp = useSelector((state) => state.current_app);

  const baseURL = `/apps/${currentApp._id}/domains`;

  const [domainInfo, setDomainInfo] = useState(null);
  const [envSelectionOpen, setEnvSelectionOpen] = useState(null);
  const [fetch, alerts] = useAsyncCalls(true);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const returnToVersion = (versionId) => {
    asyncCall(async () => {
      await builderV2.returnToAppVersion(currentApp?._id,
        domainInfo?.domain, versionId);
      fetch.asyncCall(async () => {
        setDomainInfo(null);
        const res = await builderV2.getDomainInfo(currentApp._id, domain);
        setDomainInfo(res);
      });
    });
  };

  useEffect(() => {
    fetch.asyncCall(async () => {
      setDomainInfo(null);
      const res = await builderV2.getDomainInfo(currentApp._id, domain);
      setDomainInfo(res);
    });
  }, [domain]);

  const tableColumns = '.8fr .8fr 1.2fr 1fr .8fr';
  const env = domainInfo?.associated_app?.env;

  const setEnv = (newEnv) => {
    domainInfo.associated_app.env = newEnv;
    setDomainInfo(domainInfo);
  };

  const renderDeploys = () => {
    const deploys = [domainInfo.current, ...domainInfo.history.docs];

    return deploys.map(({ _id, created_at, author }, index) => {
      const date = new Date(created_at);
      const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      const timeOptions = { hour12: false, timeStyle: 'short' };

      return (
        <DomainRow key={_id} deployId={_id}
          author={author} date={date}
          dateOptions={dateOptions}
          loading={loading} timeOptions={timeOptions}
          returnToVersion={returnToVersion} index={index} />
      );
    });
  };

  return (
    <>
      <PFContainer type="main">
        <PFContainer >
          <PFContainer margin='bottom-m'>
            <PFText tag="h2" size="l">
              Domains:
              <PFText tag="span" type="secondary"> {domain}</PFText>
            </PFText>
            <PFContainer display="flex">
              <PFLink to={baseURL}>
                <PFText type="secondary" size="s">Domains</PFText>
              </PFLink>
              <PFIcon icon="bx bx-chevron-right" color="var(--secondary)" />
              <PFLink>
                <PFText type="secondary" size="s">{domain}</PFText>
              </PFLink>
            </PFContainer>
          </PFContainer>
          {fetch.loading || loading || !domainInfo
            ? <PFLoader color="white" area />
            : <PFContainer>
              <PFText>
                Status:
                <PFText tag="span"
                  type="secondary"> {domainInfo.cdn_info.status}</PFText>
              </PFText>
              <PFContainer display="flex" gap="s">
                <PFText>
                  Environment:
                  <PFText tag="span" type="secondary"> {env ?? 'none'}</PFText>
                </PFText>
                <PFButton type="support" padding="none" height={20}
                  onClick={() => setEnvSelectionOpen(true)}>
                  <PFIcon icon="bx bx-edit" margin="top-xs" />
                </PFButton>
              </PFContainer>
              {!!domainInfo.current
                ? <PFContainer margin="top-l">
                  <PFText>Deploys</PFText>
                  <PFContainer margin="vertical-m" radius="m"
                    border="1px solid var(--table-strokes)"
                    style={{ overflow: 'hidden' }}>
                    <PFContainer padding="m" background="var(--table-strokes)"
                      display="grid" gridTemplateColumns={tableColumns}>
                      <PFText>Status</PFText>
                      <PFText>Time</PFText>
                      <PFText>Author</PFText>
                      <PFText>Id</PFText>
                    </PFContainer>
                    {renderDeploys()}
                  </PFContainer>
                </PFContainer>
                : null
              }
            </PFContainer>
          }
        </PFContainer>
      </PFContainer>

      <EnvSelectionModal
        appId={currentApp._id}
        domain={domain}
        setDomainEnv={setEnv}
        envs={currentApp.envs}
        active={envSelectionOpen}
        onDismiss={() => setEnvSelectionOpen(false)} />
      <PFAlert message={error} open={!!error} onClose={dismissError} />
      {alerts()}
    </>
  );
};

export default PFScreenAppDomainDetail;
