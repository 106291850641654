import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton, PFContainer, PFIcon, PFIconButton, PFLine, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AIInput, AIOutput } from './components';

const GenAI = ({ index, instruction,
  setInstruction, isCreated, operationId }) => {
  const functionId = useSelector((state) => state.current_function._id);

  const [functionDescription, setFunctionDescription] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [toggleConfig, setToggleConfig] = useState(false);

  const { var_name, execute } = instruction;

  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });
  const setExecuteAndName = (execute, var_name) => {
    setInstruction({ ...instruction, execute, var_name });
  };

  const setScript = (script) => setExecute({ ...execute, script });
  const setSettings = (settings) => setExecute({ ...execute, settings });

  const { settings } = execute;
  const setTarget = (target) => setSettings({ ...settings, target });
  const setArgs = (args) => setSettings({ ...settings, args });


  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  const getAIResponse = () => {
    asyncCall(async () => {
      const result = await builderV2.aiGeneration(functionId,
        operationId, functionDescription);
      setExecuteAndName(result.execute, result.var_name);
    });
  };

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFContainer height={30} display="flex" alignItems="center" gap="s">
          {
            !isCollapsed && <PFIconButton
              disabled={toggleConfig}
              icon="bx bx-chevron-left"
              onClick={() => setType('')} />
          }
          <PFText type="secondary">Instruction {index + 1}:</PFText>
          <PFText>GenAI</PFText>
        </PFContainer>
        <PFButton type='none' padding='0' onClick={() => {
          setIsCollapsed((prev) => !prev);
        }}>
          <PFIcon icon={`bx bx-chevron-${isCollapsed
            ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
        </PFButton>
      </PFContainer>
      <PFContainer display={isCollapsed
        ? 'none' : 'flex'} flexDirection='column' gap='m'>
        <PFLine />
        {
          instruction
            && typeof instruction === 'object'
            && instruction.execute?.description
            ? <AIOutput var_name={var_name} settings={settings}
              setArgs={setArgs} execute={execute} setVarName={setVarName}
              setTarget={setTarget} setScript={setScript}
              toggleConfig={toggleConfig}
              setToggleConfig={setToggleConfig} />
            : <AIInput operationId={operationId}
              functionId={functionId} functionDescription={functionDescription}
              setFunctionDescription={setFunctionDescription}
              getAIResponse={getAIResponse} loading={loading} />
        }
      </PFContainer>
      <PFAlert severity='info'
        message={'Please run the GenAI again'} open={!!error}
        onClose={dismissError} />
    </PFContainer>
  );
};

GenAI.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool,
  operationId: PropTypes.string
};

export default GenAI;
