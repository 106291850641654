import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls, useConfirmation } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { EnvCreationModal } from './components';


const PFScreenAppEnvs = () => {
  const currentApp = useSelector((state) => state.current_app);
  const envs = currentApp.envs;

  const [creationFormOpen, setCreationFormOpen] = useState(false);
  const [del, alerts] = useAsyncCalls(false);

  const baseURL = `/apps/${currentApp._id}/environments`;

  const [DeletionDialog, confirmDeletion] = useConfirmation(
    'Are you sure you want to delete this API integration?',
  );

  const deleteEnv = (envName) => {
    del.asyncCall(async () => {
      if (await confirmDeletion()) {
        store.dispatch(currentAppActions.removeEnv(envName));
        await builderV2.deleteAppEnv(currentApp._id, envName);
      }
    });
  };

  const renderEnvs = () => {
    const envsList = Object.keys(envs);
    return envsList.map((env) => {
      return (
        <PFItemCard
          key={env}
          path={`${baseURL}/${env}`}
          options={{
            'Delete': () => deleteEnv(env),
            'Copy name': () => generalUtils.copyToClipboard(env)
          }}>
          <PFText>{env}</PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <>
      <PFContainer type="main">
        <PFContainer>
          <PFContainer margin='bottom-m'>
            <PFText tag="h2" size="l">Environments</PFText>
          </PFContainer>
          <PFContainer display="grid"
            gridTemplateColumns="1fr 1fr 1fr" gap="m">
            <PFActionCard onClick={() => setCreationFormOpen(true)}>
              <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
              <PFText>New Env</PFText>
            </PFActionCard>
            {renderEnvs()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <EnvCreationModal
        appId={currentApp._id}
        baseURL={baseURL}
        active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
      <DeletionDialog />
      {alerts()}
    </>
  );
};

export default PFScreenAppEnvs;
