import PropTypes from 'prop-types';

import { PFButton, PFIcon, PFText } from 'PFComponents/common';

const OptionsButton = ({ label, icon, onClick, loading }) => {
  return (
    <PFButton type="none" padding='xs' width={70}
      onClick={onClick}
      loading={loading}
      style={{
        color: 'white', background: '#1E192B',
        borderRadius: 5
      }}>
      <PFIcon icon={icon} />
      <PFText size="xs">{label}</PFText>
    </PFButton>
  );
};

OptionsButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default OptionsButton;
