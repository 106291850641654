import {
  PFButton, PFCodeEditor,
  PFContainer, PFIcon, PFIconButton,
  PFInput,
  PFLine,
  PFSelect,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Parameters } from './components';


const Javascript = ({ index, instruction, setInstruction, isCreated }) => {
  const { var_name, execute } = instruction;
  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });

  const setScript = (script) => setExecute({ ...execute, script });
  const setSettings = (settings) => setExecute({ ...execute, settings });

  const { settings } = execute;
  const setMode = (mode) => setSettings({ ...settings, mode });
  const setTarget = (target) => setSettings({ ...settings, target });
  const setArgs = (args) => setSettings({ ...settings, args });
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFContainer height={30} display="flex" alignItems="center" gap="s">
          {
            !isCollapsed && <PFIconButton
              icon="bx bx-chevron-left"
              onClick={() => setType('')} />
          }
          <PFText type="secondary">Instruction {index + 1}:</PFText>
          <PFText>Javascript</PFText>
        </PFContainer>
        <PFButton type='none' padding='0' onClick={() => {
          setIsCollapsed((prev) => !prev);
        }}>
          <PFIcon icon={`bx bx-chevron-${isCollapsed
            ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
        </PFButton>
      </PFContainer>
      <PFContainer display={isCollapsed
        ? 'none' : 'flex'} flexDirection='column' gap='m'>
        <PFLine />
        <PFContainer display="flex" alignItems="center" gap="m">
          <PFText type="secondary" style={{ width: 120 }}>
            Target variable
          </PFText>
          <PFContainer flex={1}>
            <PFInput value={var_name}
              onChange={(e) => setVarName(e.target.value)}
              placeholder="This is where the function result will be stored" />
          </PFContainer>
        </PFContainer>
        <PFLine />
        <PFContainer display="flex" flexDirection="column" gap="l">
          <PFContainer display="grid" gridTemplateColumns="120px auto"
            alignItems="center" gap="m">
            <PFText type="secondary">Mode</PFText>
            <PFSelect value={settings?.mode}
              onChange={(e) => setMode(e.target.value)}
              options={[
                { label: 'async', value: 'async' },
                { label: 'sync', value: 'sync' }
              ]}
            />
            <PFText type="secondary">Value</PFText>

            <PFInput value={settings?.target}
              onChange={(e) => setTarget(e.target.value)}
              placeholder
              ="The name of the function in the script to be executed"
            />
          </PFContainer>
          <PFContainer>
            <PFText>Parameters</PFText>
            <Parameters
              args={settings?.args}
              setArgs={setArgs} />
          </PFContainer>
          <PFContainer>
            <PFText margin="bottom-s">Script</PFText>
            <PFCodeEditor
              language="js"
              value={execute?.script}
              onChange={(e) => setScript(e.target.value)} />
          </PFContainer>
        </PFContainer>
      </PFContainer>

    </PFContainer>
  );
};

Javascript.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default Javascript;
