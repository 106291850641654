export default [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Integer',
    value: 'int',
  },
  {
    label: 'Double',
    value: 'double',
  },
  {
    label: 'Bool',
    value: 'bool',
  },
  {
    label: 'Map',
    value: 'map',
  },
  {
    label: 'List',
    value: 'list',
  },
];
