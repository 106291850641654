export default {
  'String': {
    type_name: 'String',
    data_type: 'VARCHAR',
    length: 100,
    icon: 'string'
  },
  'Integer': {
    type_name: 'Integer',
    data_type: 'INTEGER',
    icon: 'integer'
  },
  'Decimal': {
    type_name: 'Decimal',
    data_type: 'DECIMAL',
    length: 16,
    scale: 4,
    icon: 'decimal'
  },
  'Boolean': {
    type_name: 'Boolean',
    data_type: 'BOOLEAN',
    icon: 'boolean'
  },
  'Date & Time': {
    type_name: 'Date & Time',
    data_type: 'DATETIME',
    icon: 'datetime'
  },
  'Big Integer': {
    type_name: 'Big Integer',
    data_type: 'BIGINTEGER',
    icon: 'biginteger'
  },
  'Date': {
    type_name: 'Date',
    data_type: 'DATE',
    icon: 'date'
  },
  'Float': {
    type_name: 'Float',
    data_type: 'FLOAT',
    icon: 'float'
  },
  'Money': {
    type_name: 'Money',
    data_type: 'MONEY',
    icon: 'money'
  },
  'Foreign Key': {
    type_name: 'Foreign Key',
    data_type: 'FOREIGNKEY',
    icon: 'foreignkey'
  }
};
