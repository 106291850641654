import PropTypes from 'prop-types';
import { useState } from 'react';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFIconButton,
  PFInput,
  PFModal,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { ROLES } from 'PFConstants';
import { useAsyncCalls, useForm } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';

const defaultForm = {
  email: {
    default_value: '',
    validation_type: 'text',
    error_message: 'Please provide an email'
  },
  app_role: 'EDITOR',
  function_role: 'VIEWER',
  db_role: 'VIEWER'
};

const AddPermission = ({ appId, roleOptions, open, handleDismiss }) => {
  const [form, fieldHook] = useForm(defaultForm);
  const [add, alerts] = useAsyncCalls(false);

  const [success, setSuccess] = useState(null);

  const addPermission = (values) => {
    add.asyncCall(async () => {
      const response = await builderV2.addAppPermission(appId, values);
      const user = await builderV2.getUserPreview(values.email);
      setSuccess(response);
      store.dispatch(currentAppActions.addPermission({
        ...user, role: values.app_role,
      }));
      handleDismiss();
      form.setConfig(defaultForm);
    });
  };

  const propagatedRoleOptions = ROLES.OPTIONS.slice(2);

  return (
    <>
      <PFModal active={open}>
        <PFForm form={form} submit={addPermission}>
          <PFContainer type="form" padding="l" radius="l" width={800}
            display="flex" flexDirection="column" gap="l">
            <PFContainer display="flex" alignItems="center"
              justifyContent="space-between">
              <PFContainer display="flex" alignItems="center" gap="m">
                <PFIconButton icon="bx bx-chevron-left"
                  onClick={handleDismiss} />
                <PFText type="support">Add account access</PFText>
              </PFContainer>
              <PFIconButton icon="bx bx-x" onClick={handleDismiss} />
            </PFContainer>
            <PFContainer display="grid"
              gridTemplateColumns="2fr 1fr 1fr 1fr" gap="s">
              <PFContainer />
              <PFText style={{ textAlign: 'center' }}>App</PFText>
              <PFText style={{ textAlign: 'center' }}>Functions</PFText>
              <PFText style={{ textAlign: 'center' }}>Databases</PFText>
              <PFInput type="email" placeholder="User email"
                name="email" fieldHook={fieldHook} />
              <PFSelect name='app_role' fieldHook={fieldHook}
                options={roleOptions} />
              <PFSelect name='function_role' fieldHook={fieldHook}
                options={propagatedRoleOptions} />
              <PFSelect name='db_role' fieldHook={fieldHook}
                options={propagatedRoleOptions} />
            </PFContainer>
            <PFContainer display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={add.loading}>
                Add account
              </PFButton>
            </PFContainer>
          </PFContainer >
        </PFForm>
      </PFModal>
      <PFAlert severity="success" message={success}
        open={success !== null} onClose={() => setSuccess(null)} />
      {alerts()}
    </>
  );
};

AddPermission.propTypes = {
  appId: PropTypes.string.isRequired,
  roleOptions: PropTypes.array.isRequired,
  open: PropTypes.bool,
  handleDismiss: PropTypes.func.isRequired,
};

export default AddPermission;
