/* eslint-disable no-unused-vars */
import { builderV2 } from 'PFApis';
import { PFContainer, PFLoader, PFText } from 'PFComponents/common';
import { PFVariablesDictionary } from 'PFComponents/special';
import { useAsyncCalls, useShortcut } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas, PageEditorHeader } from './components';
import { BreakpointProvider } from './components/Canvas/utils';

// Todo: these should be moved to the theme object call
const themeBreakpoints = [
  {
    key: 'full-width',
    value: '100%',
    alias: 'full width',
    mediaQuery: '',
    iconClassName: 'bx-devices'
  },
  {
    key: 'lg',
    value: '991px',
    alias: 'generic desktop',
    // eslint-disable-next-line max-len
    mediaQuery: '@media (min-width: 768px) and (max-width: 1023px)',
    iconClassName: 'bx-desktop'
  },
  {
    key: 'sm',
    value: '767px',
    alias: 'generic mobile',
    mediaQuery: '@media (max-width: 767px)',
    iconClassName: 'bx-mobile-alt'
  },
];

const CopyAlert = ({ children, isOpen }) => {
  return (
    <>
      {
        isOpen && <PFContainer
          radius='s'
          padding='left-m right-m top-s bottom-s'
          background='#181524'
          style={{
            position: 'fixed',
            zIndex: '200',
            bottom: '15px',
            left: '15px'
          }}>
          <PFText color='#fff'>{children}</PFText>
        </PFContainer>
      }
    </>
  );
};

const PFScreenPageEditor = () => {
  const { app_id, builder_path } = useParams();
  const [fetch, save, alerts] = useAsyncCalls(true, false);
  const [page, setPage] = useState(null);
  const [theme, setTheme] = useState(null);
  const [blob, setBlob] = useState([]);
  const [savedBlob, setSavedBlob] = useState(false);
  const [togglePageSetings, setTogglePageSettings] = useState(false);
  const [openDictionary, setOpenDictionary] = useState(false);
  const [copiedAlert, setCopiedAlert] = useState(false);

  useEffect(() => {
    fetch.asyncCall(async () => {
      const page = await builderV2.getPageConfig(app_id, builder_path);
      if (!!page.components) {
        setBlob(page.components);
      } else {
        setBlob(null);
      }

      const theme = await builderV2.getTheme(app_id, page.theme_id);
      setTheme(theme);

      setPage(page);
      setSavedBlob(false);
    });
  }, [app_id, builder_path]);

  const saveBlob = async () => {
    save.asyncCall(async () => {
      await builderV2.updatePageBlob(page.app_id, page.blob_id, blob);
    });
  };

  const customComponentUpdate = async (componentId, component) => {
    const res = await builderV2.updateCustomComponentWithBlobRefresh(
      page.app_id,
      componentId,
      page.blob_id,
      component,
      blob);

    setBlob(res?.blob ?? null);
    setSavedBlob(true);

    return res?.custom_component;
  };

  useShortcut('ctrl+s', saveBlob);

  useShortcut('ctrl+k', () => {
    setOpenDictionary(true);
  });

  return (
    <>
      {
        openDictionary
        && <PFVariablesDictionary closeDictionary={() => {
          setOpenDictionary(false);
        }} setCopiedAlert={setCopiedAlert} />
      }
      <PFContainer display="flex"
        flexDirection="column" flex={1}
        style={{
          filter: openDictionary ? 'blur(10px)' : 'unset',
          overflow: openDictionary ? 'hidden' : 'visible',
        }}>

        <BreakpointProvider
          themeBreakpoints={themeBreakpoints}
        >
          <PageEditorHeader
            appId={app_id}
            pageId={page?.page_id ?? ''}
            builderPath={builder_path}
            save={saveBlob}
            saveLoading={save.loading}
            saved={savedBlob}
            page={page}
            setPage={setPage}
            togglePageSetings={togglePageSetings}
            setTogglePageSettings={setTogglePageSettings}
          />
          <PFContainer flex={1} style={{

          }}>
            <PFContainer width="100%" height="100%">
              {fetch.loading || !page || !theme
                ? <PFLoader area color="var(--primary)" />
                : <Canvas
                  blob={blob}
                  setBlob={setBlob}
                  theme={theme}
                  saveBlob={saveBlob}
                  customComponentUpdate={customComponentUpdate}
                  setTogglePageSettings={setTogglePageSettings}
                  toggleDictionary={(status) => {
                    setOpenDictionary(status);
                  }}
                />
              }
            </PFContainer>
          </PFContainer>
          {alerts()}
          <CopyAlert isOpen={copiedAlert}>
            Copied to clipboard
          </CopyAlert>
        </BreakpointProvider>
      </PFContainer>

    </>
  );
};

CopyAlert.propTypes = {
  children: PropTypes.string,
  isOpen: PropTypes.bool
};

export default PFScreenPageEditor;
