/* eslint-disable max-len */
import { Button } from '@pefai/scl-react.components.actionable.buttons.button';
import { Icon } from '@pefai/scl-react.components.actionable.icons.icon';
import { Readmore } from '@pefai/scl-react.components.actionable.readmore';


import { Accordioncontainer } from '@pefai/scl-react.components.informational.accordion.accordioncontainer';
import { Accordioncontent } from '@pefai/scl-react.components.informational.accordion.accordioncontent';
import { Accordionheader } from '@pefai/scl-react.components.informational.accordion.accordionheader';
import { Accordionitem } from '@pefai/scl-react.components.informational.accordion.accordionitem';


import { Autocomplete, AutocompleteResults, AutocompleteResultsOption, AutocompleteSearchbar } from '@pefai/scl-react.components.forms.form-fields.autocomplete';
import { Checkbox } from '@pefai/scl-react.components.forms.form-fields.checkbox';
import { Currency } from '@pefai/scl-react.components.forms.form-fields.currency';
import { Email } from '@pefai/scl-react.components.forms.form-fields.email';
import { FileUploadControl, Fileupload } from '@pefai/scl-react.components.forms.form-fields.fileupload';
import { Formfieldmetadata } from '@pefai/scl-react.components.forms.form-fields.formfieldmetadata';
import { Input } from '@pefai/scl-react.components.forms.form-fields.input';
import { Label } from '@pefai/scl-react.components.forms.form-fields.label';
import { Metawrapper } from '@pefai/scl-react.components.forms.form-fields.metawrapper';
import { Number } from '@pefai/scl-react.components.forms.form-fields.number';
import { Password } from '@pefai/scl-react.components.forms.form-fields.password';
import { Radio } from '@pefai/scl-react.components.forms.form-fields.radio';
import { Select, SelectOption } from '@pefai/scl-react.components.forms.form-fields.select';
import { Simplecheckbox } from '@pefai/scl-react.components.forms.form-fields.simplecheckbox';
import { Simpleradio } from '@pefai/scl-react.components.forms.form-fields.simpleradio';
import { Telephone } from '@pefai/scl-react.components.forms.form-fields.telephone';
import { Textarea } from '@pefai/scl-react.components.forms.form-fields.textarea';
import { Url } from '@pefai/scl-react.components.forms.form-fields.url';
import { Formsubmit } from '@pefai/scl-react.components.forms.submit.formsubmit';
import { Countdown } from '@pefai/scl-react.components.informational.counters.countdown';
import { List } from '@pefai/scl-react.components.informational.lists.list';
import { Googlemaps } from '@pefai/scl-react.components.informational.location.googlemaps';
import { Image } from '@pefai/scl-react.components.informational.media.image';
import { Video } from '@pefai/scl-react.components.informational.media.video';
import { Toast } from '@pefai/scl-react.components.informational.notifications.toast';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadColumn,
  TableHeadRow
} from '@pefai/scl-react.components.informational.tables.table';
import { Tooltip } from '@pefai/scl-react.components.informational.tooltips.tooltip';
import { Container } from '@pefai/scl-react.components.layouts.containers.container';
import { ContentSlider } from '@pefai/scl-react.components.layouts.containers.content-slider';
import { Modal } from '@pefai/scl-react.components.layouts.containers.modal';
import { Link } from '@pefai/scl-react.components.navigation.link';
import { Heading } from '@pefai/scl-react.components.typography.heading';
import { Parragraph } from '@pefai/scl-react.components.typography.parragraph';
import { Text } from '@pefai/scl-react.components.typography.text';


const Components = Object.freeze({
  Accordioncontainer,
  Accordionitem,
  Accordionheader,
  Accordioncontent,
  Autocomplete,
  AutocompleteSearchbar,
  AutocompleteResults,
  AutocompleteResultsOption,
  Button,
  Checkbox,
  Container,
  ContentSlider,
  Countdown,
  Currency,
  Email,
  Fileupload,
  FileUploadControl,
  Form: Container,
  Formfieldmetadata,
  Formsubmit,
  Googlemaps,
  Heading,
  Icon,
  Image,
  Input,
  Label,
  Link,
  List,
  Metawrapper,
  Modal,
  Multistep: Container,
  Number,
  Parragraph,
  Password,
  Radio,
  Readmore,
  Select,
  SelectOption,
  Simplecheckbox,
  Simpleradio,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadColumn,
  TableHeadRow,
  Telephone,
  Text,
  Textarea,
  Toast,
  Tooltip,
  Url,
  Video,
});

export default Components;
