import { images } from 'PFAssets';
import { PFButton, PFContainer, PFIcon } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


const PFDBEditorHeader = ({ children, backAction }) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (!!backAction) {
      backAction();
      return;
    }
    navigate(-1);
  };

  return (
    <PFContainer style={{ marginBottom: 60 }}>
      <PFContainer type="editor" background="var(--container-header-background)"
        width="100%" style={{ position: 'fixed', zIndex: 10 }}>
        <PFContainer padding='vertical-s horizontal-m'
          display="flex" alignItems="center" justifyContent="space-between">
          <PFContainer display="flex" alignItems="center">
            <PFContainer> {/* Logo */}
              <img src={images.pefaiLogoShort} alt="Pefai"
                height={25} style={{ marginTop: 4 }} />
            </PFContainer>
            <PFContainer padding="horizontal-m"> {/* Actions */}
              <PFButton type="support" onClick={goBack}>
                <PFIcon icon="bx bx-arrow-back" size="l" color="white" />
              </PFButton>
            </PFContainer>
          </PFContainer>
          <PFContainer flex={1}>
            {children}
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

PFDBEditorHeader.propTypes = {
  children: PropTypes.node,
  backAction: PropTypes.func,
};

export default PFDBEditorHeader;
