import React, { ReactNode, useState, useEffect } from 'react';
import * as Styled from './toast.styled';
import { Portal } from 'react-portal';

export type ToastProps = {
    /**
     * content of the toast
     */
    children?: ReactNode;

    /**
     * class names override
     */
    className?: string;

    /**
     * styling overrides
     */
    configStyles?: string;

    /**
     * To identify the component
     */
    id?: string;

    /**
     * to set whether the toast is open or not
     */
    isToastOpen: boolean;

    /**
     * defines the type of toast
     */
    variant?: 'default' | 'success' | 'warning' | 'danger';

    overlap?: boolean | undefined;
};

export function Toast({
    children,
    configStyles,
    id,
    isToastOpen = false,
    overlap,
    variant = 'default',
    className,
}: ToastProps) {
    const [isOpen, setIsOpen] = useState<boolean | undefined>(isToastOpen);

    const onCloseClickHandler = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        setIsOpen(isToastOpen);
    }, [isToastOpen]);

    return (
        <>
            {overlap
                ? isOpen && (
                      <Portal>
                          <Styled.Toast variant={variant} id={id} configStyles={configStyles} className={className}>
                              {children}
                              <Styled.CloseButton onClick={onCloseClickHandler}>
                                  <span
                                      style={{
                                          display: 'inline-block',
                                          transform: 'rotate(45deg)',
                                          fontSize: '1.25rem',
                                      }}
                                  >
                                      +
                                  </span>
                              </Styled.CloseButton>
                          </Styled.Toast>
                      </Portal>
                  )
                : isOpen && (
                      <Styled.Toast id={id} variant={variant} configStyles={configStyles} className={className}>
                          {children}
                          <Styled.CloseButton onClick={onCloseClickHandler}>
                              <span
                                  style={{ display: 'inline-block', transform: 'rotate(45deg)', fontSize: '1.25rem' }}
                              >
                                  +
                              </span>
                          </Styled.CloseButton>
                      </Styled.Toast>
                  )}
        </>
    );
}
