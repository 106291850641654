import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import { componentUtils } from 'PFUtils';

/**
 * Standard checkbox component of the PFComponents library. This checkbox
 * complies with the Pefai style guidelines. The underlying implementation of
 * this checkbox is the MaterialUI *checkbox* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFCheckbox properties.
 * @param {boolean} [props.checked] - Determines if the checkbox is currently
 * checked.
 * @param {func} [props.onChange] - Action to be called when the checkbox
 * changes.
 * @param {string} [props.margin] - Determines the margin config of the checkbox
 * component.
 *
 * @return {React.ReactElement} The checkbox component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFCheckbox = ({
  checked,
  onChange,
  margin,
}) => {
  const configStyle = componentUtils.getStylesFromConfig({ margin });

  return (
    <Checkbox
      disableRipple
      checked={checked}
      onChange={onChange}
      sx={{
        'color': 'var(--secondary)',
        '&.Mui-checked': {
          color: 'var(--secondary)',
        },
        'padding': 0,
        'margin': configStyle.margin
      }}
    />
  );
};

PFCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  margin: PropTypes.string,
};

export default PFCheckbox;
