import {
  PFButton,
  PFContainer,
  PFIcon,
  PFSelect
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';


const Query = ({ columns, setColumns, columnOptions }) => {
  const addColumn = () => {
    setColumns([...columns, columnOptions[0].value]);
  };

  const renderColumns = () => {
    return columns.map((column, i) => {
      const setColumn = (col) => {
        columns[i] = col;
        setColumns(columns);
      };
      const removeColumn = () => {
        columns.splice(i, 1);
        setColumns(columns);
      };

      return (
        <React.Fragment key={i}>
          <PFSelect
            placeholder="Column"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
            options={columnOptions} />
          <PFButton type="support" onClick={removeColumn}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="grid" gap="s" margin="top-s"
      gridTemplateColumns="1.1fr .1fr">
      {renderColumns()}
      <PFButton type="support" onClick={addColumn}
        style={{ justifySelf: 'start' }}>
        + add column
      </PFButton>
    </PFContainer>
  );
};

Query.propTypes = {
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
  columnOptions: PropTypes.array,
};

export default Query;
