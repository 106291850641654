import {
  PFButton,
  PFContainer, PFForm, PFIcon, PFInput, PFSelect, PFText
} from 'PFComponents/common';
import { useForm } from 'PFHooks';
import store, { currentDBActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { DATA_TYPES } from 'src/constants/DB';
import {
  getFields, getFormSettings,
  getFormTypes
} from 'src/constants/DB/getForm';
import DefaultInput from '../../DefaultInput';

const ColumnSettingsFrom = ({
  columnType,
  loading,
  asyncCall,
  column_name,
  selectedTable,
  setSelectedTable,
  selectedColumn,
  setSelectedColumn,
  defaultValue,
  setDefaultValue,
  isDefaultRequired, setIsDefaultRequired,
  setUpdateTable,
  nullable, setNullable, exitForm, unique, setUnique,
  decimalScale, setDecimalScale, decimalLength, setDecimalLength,
  setStringLength, stringLength, setFormError, apiRef
}) => {
  const currentDB = useSelector((state) => state.current_db);
  const currentUserId = useSelector((state) => state.user._id);
  const { table_name } = useParams();
  const table = currentDB.tables.find((t) => t.table_name === table_name);
  const field = table.fields.find((f) => f.column_name === column_name);
  const defaultForm = getFormSettings('add', columnType, field);
  const [form, fieldHook] = useForm(defaultForm);
  const distribution = getFormTypes('add', columnType).distribution;
  const fields = getFields(getFormTypes('add', columnType));
  const navigate = useNavigate();

  const setInitialValues = () => {
    if (defaultForm.default_value) {
      setDefaultValue(form.values.default_value);
    }
    if (defaultForm.nullable) {
      setNullable(form.values.nullable);
    }

    if (defaultForm.unique && columnType !== 'Boolean') {
      setUnique(form.values.unique);
    }

    if (defaultForm.length && columnType === 'Decimal') {
      setDecimalLength(form.values.length);
    }

    if (defaultForm.scale && columnType === 'Decimal') {
      setDecimalScale(form.values.scale);
    }

    if (defaultForm.length && columnType === 'String') {
      setStringLength(form.values.length);
    }
  };

  useEffect(() => {
    setInitialValues();
  }, []);

  const renderField = (field, form) => {
    let options = field.options;
    if (field.name === 'unique') {
      return (
        <PFContainer>
          <PFText>{field.label}</PFText>
          <PFSelect options={[
            { label: 'YES', value: 'true' },
            { label: 'NO', value: 'false' }
          ]} onChange={(e) => {
            setUnique(e.target.value);
          }} value={unique} zIndex={1500} />
        </PFContainer>
      );
    }
    if (field.name === 'default_value') {
      return (
        <DefaultInput
          columnType={columnType}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
          isDefaultRequired={isDefaultRequired}
          setIsDefaultRequired={setIsDefaultRequired}
          nullable={nullable}
          unique={unique}
          fieldLabel={field.label} />
      );
    }
    if (field.name === 'length' && columnType === 'String') {
      return (<>
        <PFText>{field.label}</PFText>
        <PFInput className={stringLength ? '' : 'error'}
          type='text'
          placeholder={field.placeholder}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setStringLength(e.target.value);
            }
          }} name={field.name} value={stringLength}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {stringLength ? <></>
          : <PFText color='var(--failure)' size='s'
            margin='top-xs'>
            Field length missing</PFText>}
      </>);
    }
    if (field.name === 'length' && columnType === 'Decimal') {
      return (<>
        <PFText>{field.label}</PFText>
        <PFInput className={decimalLength ? '' : 'error'}
          type='text'
          placeholder={field.placeholder}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setDecimalLength(e.target.value);
            }
          }} name={field.name} value={decimalLength}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {decimalLength ? <></>
          : <PFText color='var(--failure)' size='s'
            margin='top-xs'>
            Field length missing</PFText>}
      </>);
    }
    if (field.name === 'scale' && columnType === 'Decimal') {
      return (<>
        <PFText>{field.label}</PFText>
        <PFInput type='text' className={decimalLength ? '' : 'error'}
          placeholder={field.placeholder}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setDecimalScale(e.target.value);
            }
          }} name={field.name} value={decimalScale}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
        {decimalScale ? <></>
          : <PFText color='var(--failure)' size='s'
            margin='top-xs'>
            Field scale missing</PFText>}
      </>);
    }
    if (field.name === 'nullable') {
      return (
        <>
          <PFText>{field.label}</PFText>
          <PFSelect options={options} onChange={(e) => {
            setNullable(e.target.value);
            if (e.target.value === 'true') {
              setIsDefaultRequired(false);
            }
          }}
            name={field.name} zIndex={1500} value={nullable} />
        </>
      );
    }
    if (field.name === 'table') {
      options = tableOptions;
      return (
        <>
          <PFText>{field.label}</PFText>
          <PFSelect options={options} onChange={(e) => {
            setSelectedTable(e.target.value);
            setSelectedColumn(null);
          }} value={selectedTable} zIndex={1500} />
        </>
      );
    } else if (field.name === 'column') {
      options = field.options;
      return (
        <>
          <PFText>{field.label}</PFText>
          <PFSelect options={columnOptions} onChange={(e) => {
            setSelectedColumn(e.target.value);
          }} value={selectedColumn} zIndex={1500} />
        </>
      );
    } else {
      options = field.options;
    }
    switch (field.type) {
      case 'input':
        return (
          <>
            <PFText>{field.label}</PFText>
            <PFInput type={field.inputType
              ? field.inputType : 'text'}
              placeholder={field.placeholder}
              fieldHook={fieldHook}
              name={field.name} onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          </>
        );
      case 'select':
        return (
          <>
            <PFText>{field.label}</PFText>
            <PFSelect options={options} fieldHook={fieldHook}
              name={field.name} zIndex={1500} />
          </>
        );

      default:
        return <></>;
    }
  };
  const onSubmit = (values) => {
    if (nullable === 'false' && !defaultValue
      && unique === 'false') {
      setIsDefaultRequired(true);
      return;
    }
    if (columnType === 'Decimal' && (!decimalLength || !decimalScale)) {
      return;
    }
    if (columnType === 'String' && (!stringLength)) {
      return;
    }

    if (columnType === 'String' && defaultValue.length
      > parseInt(stringLength)) {
      setFormError(`Default value can't be higher than the length.`);
      return;
    }
    if (columnType === 'Decimal') {
      const [integerPart, decimalPart] = defaultValue.split('.');
      const defaultLength = integerPart.concat('', decimalPart).length;

      const maxInteger = parseInt(decimalLength) - parseInt(decimalScale);

      if (parseInt(decimalScale) > parseInt(decimalLength)) {
        setFormError(`Scale can't be higher than the length`);
        return;
      } else if (defaultValue && (defaultLength > parseInt(decimalLength)
        || integerPart.length > maxInteger)) {
        setFormError(`Default value exceeds length and 
        scale configuration`);
        return;
      } else if (parseInt(decimalLength) > 38) {
        setFormError(`Length can't be higher than 38.`);
        return;
      }
    }

    if (field.type_name === 'Integer' && columnType === 'Date'
      || field.type_name === 'Decimal' && columnType === 'Date'
      || field.type_name === 'Big Integer' && columnType === 'Date'
      || field.type_name === 'Float' && columnType === 'Date'
      || field.type_name === 'Money' && columnType === 'Date') {
      /* eslint-disable max-len */
      setFormError('Sorry, you cannot change the type of this column to Date.');
      return;
    }

    if (field.type_name === 'Date & Time' && columnType == 'Integer'
      || field.type_name === 'Date & Time' && columnType === 'Decimal'
      || field.type_name === 'Date & Time' && columnType === 'Boolean'
      || field.type_name === 'Date & Time' && columnType === 'Big Integer'
      || field.type_name === 'Date & Time' && columnType === 'Float'
      || field.type_name === 'Date & Time' && columnType === 'Money') {
      /* eslint-disable max-len */
      setFormError('Sorry, you cannot change a Date & Time column to this type.');
      return;
    }

    if (field.type_name === 'Date' && columnType == 'Integer'
      || field.type_name === 'Date' && columnType === 'Decimal'
      || field.type_name === 'Date' && columnType === 'Boolean'
      || field.type_name === 'Date' && columnType === 'Big Integer'
      || field.type_name === 'Date' && columnType === 'Float'
      || field.type_name === 'Date' && columnType === 'Money') {
      /* eslint-disable max-len */
      setFormError('Sorry, you cannot change a Date column to this type.');
      return;
    }

    if (field.type_name === 'Boolean' && columnType == 'Date') {
      /* eslint-disable max-len */
      setFormError('Sorry, you cannot change a Boolean column to this type.');
      return;
    }

    asyncCall(async () => {
      if (decimalLength && columnType === 'Decimal') {
        values.length = parseInt(decimalLength);
      }
      if (decimalScale && columnType === 'Decimal') {
        values.scale = parseInt(decimalScale);
      }
      if (stringLength && columnType === 'String') {
        values.length = parseInt(stringLength);
      }
      if (selectedTable) values.table = selectedTable;
      if (selectedColumn) values.column = selectedColumn;
      if (nullable) values.nullable = nullable;
      if (unique) values.unique = unique;
      if (unique === 'true') {
        values.default_value = '';
      }
      if (values.index && field.index === 'true' && values.index === 'false') {
        values.deleteIndex = true;
      } else {
        values.deleteIndex = false;
      }

      const newFieldData = {
        ...values,
        type_name: DATA_TYPES[columnType].type_name,
        data_type: DATA_TYPES[columnType].data_type,
        column_name: field.column_name,
        default_value: defaultValue
      };

      const updatedField = await builderTest(currentUserId)
        .updateDBTableColumn(currentDB._id, table_name, newFieldData);
      if ((updatedField.column_name !== updatedField.new_column_name)
        || (defaultValue)
        || (nullable === 'false' && unique === 'true')) {
        setUpdateTable((prev) => !prev);
      }

      if ((columnType !== field.type_name)) {
        navigate(0);
      }

      store.dispatch(currentDBActions.updateTableField({
        table_name,
        updated_field: updatedField
      }));
      exitForm();
      apiRef.current.hideColumnMenu();
    });
  };
  return (
    <>
      {
        columnType === 'Auto Increment' ? <PFText>Not editable</PFText>
          : (<PFForm form={form} submit={onSubmit}>
            <PFContainer display="grid" gap="m"
              gridTemplateColumns='repeat(6, 1fr)' >
              {
                fields.map((field, index) => {
                  return (
                    <PFContainer key={index} style={{
                      gridColumn: distribution[index]
                        ? `span ${distribution[index]}` : 'auto'
                    }}>
                      {renderField(field, form)}
                    </PFContainer>
                  );
                })
              }
            </PFContainer>
            {
              nullable === 'false'
              && unique === 'true'
              && apiRef.current.getRowsCount() > 0
              && <PFContainer margin='top-m'>
                <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
                  size='s' margin='right-xs' />
                <PFText size='s' color='var(--purple-100)'
                  style={{
                    display: 'inline'
                  }}>
                  Caution: </PFText>
                <PFText size='s'
                  style={{
                    display: 'inline'
                  }}>
                  You are setting a column to be not nullable
                  and unique on a table that already has records.
                  Make sure those records to match this configuration
                  before submitting.
                </PFText>

              </PFContainer>
            }
            {
              defaultValue && columnType === 'Integer'
              && nullable === 'true' && unique === 'false'
              && <PFContainer margin='top-m'>
                <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
                  size='s' margin='right-xs' />
                <PFText size='s' color='var(--purple-100)'
                  style={{
                    display: 'inline'
                  }}>
                  Caution: </PFText>
                <PFText size='s'
                  style={{
                    display: 'inline'
                  }}>
                  Integer range goes from -2147483648 to 2147483647.
                  Default values are not validated until a record is added.
                </PFText>

              </PFContainer>
            }
            {
              defaultValue && columnType === 'Big Integer'
              && nullable === 'true' && unique === 'false'
              && <PFContainer margin='top-m'>
                <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
                  size='s' margin='right-xs' />
                <PFText size='s' color='var(--purple-100)'
                  style={{
                    display: 'inline'
                  }}>
                  Caution: </PFText>
                <PFText size='s'
                  style={{
                    display: 'inline'
                  }}>
                  Big Integer range goes from -9223372036854775808
                  to 9223372036854775807.
                  Default values are not validated until a record is added.
                </PFText>

              </PFContainer>
            }

            {
              columnType !== field.type_name
                ? <PFContainer margin='top-m'>
                  <PFIcon icon='bx bx-user-voice' color={'var(--purple-100)'}
                    size='s' margin='right-xs' />
                  <PFText size='s' color='var(--purple-100)'
                    style={{
                      display: 'inline'
                    }}>
                    Caution: </PFText>
                  <PFText size='s'
                    style={{
                      display: 'inline'
                    }}>
                    Changing the type of a column with existing records may lead to data parsing issues. Please make sure that this change aligns with your intentions.
                  </PFText>

                </PFContainer>
                : null
            }
            <PFContainer display='flex' justifyContent='flex-end'
              margin='top-m'>
              <PFButton padding="horizontal-xl" submit loading={loading}
                height='auto' width='240px'
                style={{
                  paddingTop: 'var(--size-s)',
                  paddingRight: 'var(--size-s)',
                  paddingBottom: 'var(--size-s)',
                  paddingLeft: 'var(--size-s)',
                }}>
                Update column
              </PFButton>
            </PFContainer>

          </PFForm>)
      }
    </>
  );
};

ColumnSettingsFrom.propTypes = {
  columnType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  asyncCall: PropTypes.func,
  column_name: PropTypes.string,
  selectedTable: PropTypes.string,
  setSelectedTable: PropTypes.func,
  selectedColumn: PropTypes.string,
  setSelectedColumn: PropTypes.func,
  setDefaultValue: PropTypes.func,
  defaultValue: PropTypes.string,
  isDefaultRequired: PropTypes.bool,
  setIsDefaultRequired: PropTypes.func,
  setUpdateTable: PropTypes.func,
  nullable: PropTypes.string,
  setNullable: PropTypes.func,
  exitForm: PropTypes.func,
  unique: PropTypes.string,
  setUnique: PropTypes.func,
  decimalScale: PropTypes.string,
  setDecimalScale: PropTypes.func,
  decimalLength: PropTypes.string,
  setDecimalLength: PropTypes.func,
  stringLength: PropTypes.number,
  setStringLength: PropTypes.func,
  apiRef: PropTypes.object,
  setFormError: PropTypes.func,
};

export default ColumnSettingsFrom;
