import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';

const DictionaryHeader = ({
  filterValue,
  setFilterValue,
  closeDictionary
}) => {
  return (
    <PFContainer
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      padding='top-s bottom-s right-m left-m'>
      <PFIcon
        icon='bx bx-search'
        color='#fff'
        size='l' />
      <PFContainer flex={1}>
        <PFInput
          style={{
            color: '#fff'
          }}
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue} />
      </PFContainer>
      <PFButton
        type='support'
        padding='left-s right-xs'
        style={{
          backgroundColor: 'var(--purple-250)',
          borderRadius: 'var(--radius-s)',
          display: 'flex',
          gap: 'var(--size-xs)',
          alignItems: 'center',
          height: 'auto'
        }}
        onClick={closeDictionary}>
        <PFText color={'var(--gray-100)'}
        >ESC</PFText>
        <PFIcon icon='bx bx-x'
          color={'var(--gray-100)'} />
      </PFButton>
    </PFContainer>
  );
};

DictionaryHeader.propTypes = {
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  closeDictionary: PropTypes.func,
};

export default DictionaryHeader;
