import React, { ReactNode, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import * as Styled from './tooltip.styled';

export type TooltipProps = {
    /**
     * Determines whether the tooltip will be visible even when the cursor is not over the target or even when the target is not focused
     */
    alwaysVisible?: boolean;

    /**
     * Specifies the children inside the tooltip
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * To handle styles
     */
    configStyles?: string;

    /**
     * Specifies the gap between target and tooltip
     */
    gap?: number;

    /**
     * Specifies a unique ID for Tooltip component.
     */
    id: string;

    /**
     * Specifies the placement of the tooltip.
     */
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';

    /**
     * Is the ID of the component to which the tooltip will respond
     */
    tooltipTargetId: string;
};

const Portal = (props: { children: ReactNode | ReactNode[] }) => {
    return ReactDOM.createPortal(props.children, document.body);
};

export function Tooltip({
    alwaysVisible = false,
    children,
    className,
    configStyles,
    gap = 8,
    id,
    tooltipPosition,
    tooltipTargetId,
}: TooltipProps) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPos, setTooltipPos] = useState<string>('');
    const [tooltipDimensions, setTooltipDimensions] = useState<{ tooltipWidth: number; tooltipHeight: number }>({
        tooltipWidth: 0,
        tooltipHeight: 0,
    });
    const [targetExist, setTargetExist] = useState(false);

    const targetRef = useRef<HTMLElement | null>();
    const tooltipRef = useRef<HTMLSpanElement | null>();

    const isOutOfViewport = (tooltipRef: any) => {
        let bounding = tooltipRef.current.getBoundingClientRect();
        setTooltipDimensions({
            tooltipWidth: tooltipRef.current.getBoundingClientRect().width,
            tooltipHeight: tooltipRef.current.getBoundingClientRect().height,
        });
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        let out = { top: true, left: true, bottom: true, right: true, x: true, y: true };

        out.top = bounding.top < 0;
        out.left = bounding.left < 50;
        out.bottom = bounding.bottom > viewportHeight;
        out.right = bounding.left + bounding.width > viewportWidth;
        out.x = bounding.x < 0;
        out.y = bounding.y < 0;

        return out;
    };

    const calculateTargetPosition = (targetRef: any) => {
        return {
            targetX: targetRef.current.getBoundingClientRect().x,
            targetY: targetRef.current.getBoundingClientRect().y,
            targetHeight: targetRef.current.getBoundingClientRect().height,
            targetWidth: targetRef.current.getBoundingClientRect().width,
        };
    };

    const handleMouseOver = () => {
        if (tooltipRef.current) {
            const { bottom, top, left, right, x, y } = isOutOfViewport(tooltipRef);
            let newPosition = tooltipPosition;

            if (y && top && tooltipPosition === 'top') {
                newPosition = 'bottom';
            } else if (y && bottom && tooltipPosition === 'bottom') {
                newPosition = 'top';
            } else if (x && left && tooltipPosition === 'left') {
                newPosition = 'right';
            } else if (x && right && tooltipPosition === 'right') {
                newPosition = 'left';
            }
            if (newPosition) {
                setTooltipPos(newPosition);
            }
            setShowTooltip(true);
        }
    };

    const handleMouseOut = () => setShowTooltip(false);

    const handleFocus = () => {
        if (tooltipRef.current) {
            const { bottom, top, left, right, x, y } = isOutOfViewport(tooltipRef);
            let newPosition = tooltipPosition;

            if (y && top && tooltipPosition === 'top') {
                newPosition = 'bottom';
            } else if (y && bottom && tooltipPosition === 'bottom') {
                newPosition = 'top';
            } else if (x && left && tooltipPosition === 'left') {
                newPosition = 'right';
            } else if (x && right && tooltipPosition === 'right') {
                newPosition = 'left';
            }
            if (newPosition) {
                setTooltipPos(newPosition);
            }
            setShowTooltip(true);
        }
    };

    const handleFocusOut = () => setShowTooltip(false);

    useEffect(() => {
        const targetedElement = document.getElementById(tooltipTargetId);

        if (targetedElement) {
            targetRef.current = targetedElement;

            setTargetExist(true);

            targetedElement.addEventListener('mouseover', handleMouseOver);
            targetedElement.addEventListener('mouseleave', handleMouseOut);
            targetedElement.addEventListener('focus', handleFocus);
            targetedElement.addEventListener('blur', handleFocusOut);
            targetedElement.addEventListener('click', (e) => e.preventDefault());

            return () => {
                targetedElement.removeEventListener('mouseover', handleMouseOver);
                targetedElement.removeEventListener('mouseleave', handleMouseOut);
                targetedElement.removeEventListener('focus', handleFocus);
                targetedElement.removeEventListener('blur', handleFocusOut);
                targetedElement.removeEventListener('click', (e) => e.preventDefault());
            };
        }
    }, []);

    useEffect(() => {
        if (tooltipPosition) {
            setTooltipPos(tooltipPosition);
        }
    }, [tooltipPosition]);

    useEffect(() => {
        if (showTooltip && tooltipRef.current) {
            let { bottom, top, left, right, x, y } = isOutOfViewport(tooltipRef);

            if (x && left && tooltipPosition === 'left') {
                setTooltipPos('right');
            } else if (x && right && tooltipPosition === 'right') {
                setTooltipPos('left');
            } else if (y && top && tooltipPosition === 'top') {
                setTooltipPos('bottom');
            } else if (y && bottom && tooltipPosition === 'bottom') {
                setTooltipPos('top');
            } else if (left && tooltipPosition === 'left') {
                setTooltipPos('right');
            } else if (right && tooltipPosition === 'right') {
                setTooltipPos('left');
            } else if (top && tooltipPosition === 'top') {
                setTooltipPos('bottom');
            } else if (bottom && tooltipPosition === 'bottom') {
                setTooltipPos('top');
            }
            let bounding = tooltipRef.current.getBoundingClientRect();
            setTooltipDimensions({
                tooltipWidth: bounding.width,
                tooltipHeight: bounding.height,
            });
        }
    }, [showTooltip]);

    return (
        <Portal>
            {targetExist && (
                <Styled.TooltipWrapper className={className}>
                    {
                        <Styled.Tooltip
                            id={id}
                            gap={gap}
                            ref={tooltipRef}
                            tooltipPosition={tooltipPos}
                            targetPosition={calculateTargetPosition(targetRef)}
                            tooltipDimensions={tooltipDimensions}
                            configStyles={configStyles}
                            visualVp={window.visualViewport}
                        >
                            {(alwaysVisible || showTooltip) && children}
                        </Styled.Tooltip>
                    }
                </Styled.TooltipWrapper>
            )}
        </Portal>
    );
}
