import { useSelector } from 'react-redux';

import {
  PFLayoutAuthenticatedUser,
  PFLayoutGuest
} from 'PFLayouts';

import './App.css';

function App() {
  const { access_token } = useSelector((state) => state.auth);

  if (!!access_token) {
    return <PFLayoutAuthenticatedUser />;
  } else {
    return <PFLayoutGuest />;
  }
}

export default App;
