import { createSlice } from '@reduxjs/toolkit';

const currentDBSlice = createSlice({
  name: 'current_db',
  initialState: null,
  reducers: {
    setCurrentDB: (_state, { payload }) => {
      return { ...payload };
    },
    removeTable: (state, { payload }) => {
      state.tables = state.tables.filter(
        (table) => table.table_name !== payload);
      return state;
    },
    addTableField: (state, { payload }) => {
      const { table_name, new_field } = payload;
      state.tables = state.tables.map((table) => {
        if (table.table_name === table_name) {
          table.fields.push(new_field);
        }
        return table;
      });
      return state;
    },
    updateTableField: (state, { payload }) => {
      const { table_name, updated_field } = payload;
      state.tables = state.tables.map((table) => {
        if (table.table_name === table_name) {
          table.fields = table.fields.map((field) => {
            if (field.column_name === updated_field.column_name) {
              field = updated_field;
              field.column_name = updated_field.new_column_name;
            }
            return field;
          });
        }

        return table;
      });
      return state;
    },
    removeTableField: (state, { payload }) => {
      const { table_name, field_name } = payload;
      state.tables = state.tables.map((table) => {
        if (table.table_name === table_name) {
          table.fields = table.fields.filter((field) =>
            field.column_name !== field_name);
        }
        return table;
      });
      return state;
    },
    addPermission: (state, { payload }) => {
      state.permissions.accounts.count++;
      state.permissions.accounts.docs.push(payload);
      return state;
    },
    updatePermission: (state, { payload }) => {
      const { _id, role } = payload;
      state.permissions.accounts.docs
        = state.permissions.accounts.docs.map((acc) => {
          if (acc._id === _id) acc.role = role;
          return acc;
        });
      return state;
    },
    removePermission: (state, { payload: _id }) => {
      state.permissions.accounts.docs
        = state.permissions.accounts.docs.filter((acc) => acc._id !== _id);
      return state;
    },
  }
});

export default currentDBSlice.reducer;
export const {
  setCurrentDB,
  removeTable,
  addTableField,
  updateTableField,
  removeTableField,
  addPermission,
  updatePermission,
  removePermission,
} = currentDBSlice.actions;
