import styled from 'styled-components';

export const MetaWrapper = styled.div.attrs({ role: 'alert' })<{
    configStyles: string | undefined;
}>`
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;

    &.meta-error {
        border-color: #ff0000;
        color: #ff0000;
    }
    &.meta-success {
        border-color: #00ff00;
        color: #00ff00;
    }
    &.meta-untouched {
        border-color: #e0e0e0;
        color: #e0e0e0;
    }

    ${(props) => props.configStyles};
`;
