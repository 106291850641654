const buildEnv = process.env.NODE_ENV;
const twoappOrigin = process.env.REACT_APP_TWOAPP_ORIGIN;
const twoappTestOrigin = process.env.REACT_APP_TEST_ORIGIN;
const privateApiKey = process.env.REACT_APP_PRIVATE_KEY;
const apiPublic = process.env.REACT_APP_PUBLIC_API;

export {
  buildEnv,
  twoappOrigin,
  twoappTestOrigin,
  privateApiKey,
  apiPublic
};

