import PropTypes from 'prop-types';

import MultistepPrep from './MultistepPrep';
import StandardPrep from './StandardPrep';

const ListWrapper = ({ canvas, components, setComponents }) => {
  const renderComponents = () => {
    if (!components) return null;

    return components.map((component, i) => {
      const setComponent = (newComponent) => {
        const newComponents = [...components];
        newComponents.splice(i, 1, newComponent);
        setComponents(newComponents);
      };

      const addPreviousComponent = (newComponent) => {
        const newComponents = [...components];
        newComponents.splice(i, 0, newComponent);
        setComponents(newComponents);
      };

      const addNextComponent = (newComponent) => {
        const newComponents = [...components];
        newComponents.splice(i + 1, 0, newComponent);
        setComponents(newComponents);
      };

      const removeComponent = () => {
        const newComponents = [...components];
        newComponents.splice(i, 1);
        setComponents(newComponents);
      };

      const key = component.build.id;
      const wrapperProps = {
        canvas: canvas,
        component: component,
        setComponent: setComponent,
        addPreviousComponent: addPreviousComponent,
        addNextComponent: addNextComponent,
        removeComponent: removeComponent,
      };

      switch (component.component) {
        case 'Multistep':
          return <MultistepPrep key={key} {...wrapperProps} />;
        default:
          return <StandardPrep key={key} {...wrapperProps} />;
      }
    });
  };

  return renderComponents();
};

ListWrapper.propTypes = {
  canvas: PropTypes.object.isRequired,
  components: PropTypes.array,
  setComponents: PropTypes.func.isRequired,
};

export default ListWrapper;
