import React, { ReactNode } from 'react';
import * as Styled from './simpleradio.styled';

export type SimpleradioProps = {
    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;

    /**
     * class names overrides
     */
    className?: string;

    /**
     * styling overrides
     */
    configStyles?: string;

    /**
     * to disable the radio element
     */
    disabled?: boolean;

    /**
     * Radio field value
     */
    fieldValue: string;

    /**
     * Identifies the input[radio] semantic element
     */
    id: string;

    /**
     * Specifies the name of the radio element
     */
    name: string;

    /**
     * onChange event handler
     */
    onChange?: any;

    /**
     * field value
     */
    value?: string;
};

export function Simpleradio({
    children,
    className,
    configStyles,
    disabled,
    fieldValue,
    id,
    name,
    onChange,
    value,
    ...props
}: SimpleradioProps) {
    return (
        <Styled.Radio
            defaultChecked={fieldValue === value}
            className={className}
            configStyles={configStyles}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            value={fieldValue}
            {...props}
        />
    );
}
