import React, { ReactNode, Children } from 'react';
import * as Styled from './list.styled';
export type ListProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode[] | ReactNode;

    /**
     * a set of classes separated by one space applied to the button
     */
    className?: string;

    /**
     * for component level styling override (Design System)
     */
    configStyles?: string;

    /**
     * To identify the component
     */
    id?: string;

    /**
     * Type of list
     */
    type: 'ul' | 'ol' | undefined;

    /**
     * Ways to present listed content
     */
    variant: 'vertical' | 'horizontal';
};

export function List({
    children = [],
    variant = 'vertical',
    type = 'ul',
    className,
    configStyles,
    id,
    ...props
}: ListProps) {
    // Ensure children is always an array - per React's children optimization of object | array when there's a single child or children instead
    const childrenAsArray = Children.toArray(children);
    return (
        <Styled.List as={type} variant={variant} className={className} configStyles={configStyles} id={id} {...props}>
            {childrenAsArray.map((child, index) => {
                return <Styled.Li key={`list-${index}`}>{child}</Styled.Li>;
            })}
        </Styled.List>
    );
}
