import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFAppEditorHeader } from 'PFComponents/navigation';
import MultistepCreationModal from './MultistepCreationModal';

const PageJSONEditorHeader = ({
  appId,
  pageId,
  builderPath,
  multisteps,
  tab,
  setTab,
  save,
  saveLoading,
  saved
}) => {
  const navigate = useNavigate();

  const pages = useSelector((state) =>
    state.current_app.build.pages.docs);

  const [multistepSelectionOpen, setMultistepSelectionOpen] = useState(false);

  const pageOptions = pages.map((page) => {
    return { label: page.alias, value: page.builder_path };
  });

  const changePage = (e) => {
    const path = e.target.value;
    navigate(`/json-editor/apps/${appId}/pages/${path}`);
  };

  const backToApps = () => {
    navigate(`/apps/${appId}/pages`);
  };

  return (
    <PFAppEditorHeader backAction={backToApps}>
      <PFContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <PFContainer display="flex" alignItems="center" gap="m">
          <PFContainer width={300}>
            <PFSelect
              className="header"
              prefix="Page:"
              value={builderPath}
              onChange={changePage}
              options={pageOptions} />
          </PFContainer>
          <PFContainer display="flex" alignItems="center" gap="m">
            <PFButton type="support" padding="top-xs"
              onClick={() => setTab('blob')}
              style={{ color: tab === 'blob' ? 'white' : null }}>
              <PFIcon icon="bx bx-code-alt" size="l" />
            </PFButton>
            <PFButton type="support" padding="top-xs"
              onClick={() => setTab('settings')}
              style={{ color: tab === 'settings' ? 'white' : null }}>
              <PFIcon icon="bx bx-cog" size="l" />
            </PFButton>
            {!!pageId
              ? <PFButton type="support" padding="top-xs"
                onClick={() => setMultistepSelectionOpen(true)}
                style={{ color: multistepSelectionOpen ? 'white' : null }}>
                <PFIcon icon="bx bx-carousel" size="l" />
              </PFButton>
              : null
            }
          </PFContainer>
        </PFContainer>
        <PFContainer display="flex" gap="m">
          {!saveLoading && saved
            && <PFContainer
              display="flex" alignItems="center" gap="s">
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type="secondary" width={80}
            onClick={save} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
      {!!pageId
        ? <MultistepCreationModal appId={appId} pageId={pageId}
          multisteps={multisteps}
          active={multistepSelectionOpen}
          onDismiss={() => setMultistepSelectionOpen(false)} />
        : null
      }
    </PFAppEditorHeader>
  );
};

PageJSONEditorHeader.propTypes = {
  appId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  builderPath: PropTypes.string.isRequired,
  multisteps: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default PageJSONEditorHeader;
