import {
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton,
  PFInput,
  PFLine,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { FUNCTIONS } from 'PFConstants';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';


const AssignValue = ({ index, instruction, setInstruction, isCreated }) => {
  const { var_name, execute } = instruction;
  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const setValue = (value) => setExecute({ ...execute, value });
  const setFormat = (format) => setExecute({ ...execute, format });

  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFContainer height={30} display="flex" alignItems="center" gap="s">
          {
            !isCollapsed && <PFIconButton
              icon="bx bx-chevron-left"
              onClick={() => setType('')} />
          }
          <PFText type="secondary">Instruction {index + 1}:</PFText>
          <PFText>Assign value</PFText>
        </PFContainer>
        <PFButton type='none' padding='0' onClick={() => {
          setIsCollapsed((prev) => !prev);
        }}>
          <PFIcon icon={`bx bx-chevron-${isCollapsed
            ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
        </PFButton>
      </PFContainer>
      <PFContainer display={isCollapsed
        ? 'none' : 'flex'} flexDirection='column' gap='m'>
        <PFLine />
        <PFContainer display="flex" alignItems="center" gap="m">
          <PFText type="secondary" style={{ width: 120 }}>
            Target variable
          </PFText>
          <PFContainer flex={1}>
            <PFInput value={var_name}
              onChange={(e) => setVarName(e.target.value)}
              placeholder="This is where the value will be assigned" />
          </PFContainer>
        </PFContainer>
        <PFLine />
        <PFContainer display="grid" gridTemplateColumns="120px auto"
          alignItems="center" gap="m">
          <PFText type="secondary">Value to assign</PFText>
          <PFInput value={execute?.value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="The value to be assigned"
          />
          <PFText type="secondary">Format</PFText>
          <PFSelect value={execute?.format}
            onChange={(e) => setFormat(e.target.value)}
            placeholder="Format of the value"
            options={FUNCTIONS.FORMATS}
          />
        </PFContainer>
      </PFContainer>

    </PFContainer>
  );
};

AssignValue.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default AssignValue;
