import {
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import { useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const ACTION_NODES = PROCESS_FLOW.NODE_TYPE_UI_ACTION_OPTIONS;

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit the information of a CHANGE_STEP action node.
 *
 * @param {Object} props - NodeChangeStep props.
 * @param {ProcessFlowNode} props.node - Action type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Custom API node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeChangeStep = ({ node, updateNode, goBack }) => {
  const [nodeName, setNodeName] = useState(node?.alias ?? '');

  const [form, fieldHook] = useForm({
    multistep: {
      default_value: node.multistep,
      validation_type: 'text',
      error_message: 'Please provide a multistep id'
    },
    step: {
      default_value: node.step,
      validation_type: 'text',
      error_message: 'Please provide a step to switch to'
    },
    path: {
      default_value: node.path
    }
  });

  const nodeTypeInfo = ACTION_NODES.find(
    (n) => n.type === node.type);

  const saveNode = (values) => {
    const updatedNode = { ...node, ...values };
    updatedNode.alias = nodeName;

    updateNode(updatedNode);
    goBack();
  };

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={saveNode}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)}
              style={{
                color: 'var(--purple-100)'
              }} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={nodeTypeInfo.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{nodeTypeInfo.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {nodeTypeInfo.description}
              </PFText>
            </PFContainer>
          </PFContainer>

          <PFContainer display="grid" gridTemplateColumns="auto 1fr" gap="s">
            <PFText type="secondary" size="s" margin="top-s">
              Multistep
            </PFText>
            <PFInput name="multistep" fieldHook={fieldHook}
              width="100%" style={{ fontSize: 'var(--text-size-s)' }} />
            <PFText type="secondary" size="s" margin="top-s">
              Step
            </PFText>
            <PFInput name="step" fieldHook={fieldHook}
              width="100%" style={{ fontSize: 'var(--text-size-s)' }} />
            <PFText type="secondary" size="s" margin="top-s">
              Navigate
            </PFText>
            <PFInput name="path" fieldHook={fieldHook}
              width="100%" style={{ fontSize: 'var(--text-size-s)' }} />
          </PFContainer>

          <PFButton submit>Save</PFButton>
        </PFContainer>
      </PFForm>
    </PFContainer>
  );
};

NodeChangeStep.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeChangeStep;
