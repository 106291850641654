import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const EnvironmentDetailSelectionModal = ({
  active,
  onDismiss,
  dbEnv, associatedDBs,
  setDBEnv,
  functionEnvName,
  function_id
}) => {
  const navigate = useNavigate();
  const environmentsOptions = [
    { label: 'PROD', value: 'PROD' },
    { label: 'DEFAULT', value: 'DEFAULT' },
  ];
  const { env: currentEnv } = dbEnv;
  const setCurrentEnv = (env) => setDBEnv({
    ...dbEnv,
    env
  });
  const [connectAsyncCall, connectLoading, connectError, dismissConnectError]
    = useAsyncCall(false);

  const connectFunctions = () => {
    const dbsCopy = [...associatedDBs].map((dbElement) => {
      if (dbEnv._id === dbElement._id) {
        return dbEnv;
      }
      return dbElement;
    });

    connectAsyncCall(async () => {
      await builderV2.functionAddDbsEnv(function_id, functionEnvName, dbsCopy);
      navigate(0);
    });
  };

  const renderEnvironments = () => {
    return environmentsOptions.map((envElement) => {
      const selected = currentEnv === envElement.value;

      const handleSelection = () => {
        setCurrentEnv(envElement.value);
      };

      return (
        <PFButton key={envElement.value} type="none" onClick={handleSelection}>
          <PFContainer height={100} radius="m" width="100%" padding="m"
            display="flex" flexDirection="column" justifyContent="end"
            background="var(--strokes)"
            style={{ position: 'relative', textAlign: 'left' }}
            border={selected ? '1px solid var(--secondary)' : 'none'}>
            <PFContainer background={selected ? 'var(--secondary)' : 'white'}
              display='flex' alignItems='center' justifyContent='center'
              border={selected ? 'none' : '1px solid var(--secondary)'}
              style={{
                position: 'absolute', top: 15, right: 15,
                width: 24, borderRadius: '50%', aspectRatio: 1
              }}>
              <PFIcon icon="bx bx-check" color="white" />
            </PFContainer>
            <PFText size="s">{envElement.label}</PFText>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">
              Select the database environment to connect
            </PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="s">
            {renderEnvironments()}
          </PFContainer>
          <PFContainer margin="top-l" display="flex" justifyContent="end">
            <PFButton onClick={connectFunctions}
              loading={connectLoading}
              padding="horizontal-xl">
              Confirm connection
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert
        message={connectError}
        open={!!connectError}
        onClose={dismissConnectError} />
    </>
  );
};

EnvironmentDetailSelectionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  dbEnv: PropTypes.object,
  associatedDBs: PropTypes.array,
  setDBEnv: PropTypes.func,
  functionEnvName: PropTypes.string,
  function_id: PropTypes.string,
};

export default EnvironmentDetailSelectionModal;
