import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const AutomationEditorSidebar = ({ jobs }) => {
  const { automation_id } = useParams();
  const currentApp = useSelector((state) => state.current_app);
  const appId = currentApp._id;
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const renderOperations = () => {
    let filteredOperations;
    if (search.length > 0) {
      const searchRegex = new RegExp(search, 'i');
      filteredOperations = jobs.filter((job) => {
        return job.alias.match(searchRegex);
      });
    } else {
      filteredOperations = jobs;
    }

    return filteredOperations.map((job) => {
      const changeOperation = () => {
        navigate(`/editor/apps/${appId}/automations/${job.id}`);
      };
      return (
        <PFButton type="none" key={job.id} onClick={changeOperation}
          style={{ textAlign: 'left' }}>
          <PFText type={job.id == automation_id ? 'primary' : 'secondary'}>
            {job.alias}
          </PFText>
        </PFButton>
      );
    });
  };

  return (
    <PFContainer style={{ marginRight: 300 }}>
      <PFContainer type="toolbar" height="100%" width={300}
        className='pf-scroll'
        style={{ position: 'fixed', overflowY: 'auto', paddingBottom: 100 }}>
        <PFContainer padding="m">
          <PFContainer>
            <PFText>Automations</PFText>
            <PFInput name="integrations-search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              margin="vertical-m"
              placeholder="Find automation"
              leftContent={(
                <PFIcon icon="bx bx-search"
                  margin="left-s"
                  color="var(--secondary)" />
              )} />
          </PFContainer>
          <PFContainer display="flex"
            flexDirection="column" gap="s"
            alignItems="start">
            {renderOperations()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

AutomationEditorSidebar.propTypes = {
  jobs: PropTypes.array,
};

export default AutomationEditorSidebar;
