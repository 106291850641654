import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFLink,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';

const MultistepCreationModal = ({
  appId,
  pageId,
  multisteps,
  active,
  onDismiss
}) => {
  const navigate = useNavigate();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);

  const baseURL = `/json-editor/apps/${appId}/pages/${pageId}/multisteps`;

  const createMultistep = () => {
    asyncCall(async () => {
      const { InsertedID }
        = await builderV2.createPageMultistep(appId, pageId);
      navigate(`${baseURL}/${InsertedID}`);
    });
  };

  const renderMultisteps = () => {
    return multisteps.map((multi) => {
      return (
        <PFLink to={`${baseURL}/${multi}`} key={multi}>
          <PFContainer height={100} radius="m" width="100%" padding="m"
            display="flex" flexDirection="column" justifyContent="end"
            background="var(--strokes)" border="1px solid var(--secondary)">
            <PFText type="secondary" size="s">Multistep</PFText>
            <PFText size="s">{multi}</PFText>
          </PFContainer>
        </PFLink>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">Edit a multistep</PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="s">
            <PFButton type="none" onClick={createMultistep}
              loading={loading} padding="none">
              <PFContainer height={100} radius="m" width="100%"
                display="flex" flexDirection="column" justifyContent="center"
                background="var(--button-main-primary-background)">
                <PFContainer display="flex"
                  alignItems="center" justifyContent="center" gap="s">
                  <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
                  <PFText color="white">Create new</PFText>
                </PFContainer>
              </PFContainer>
            </PFButton>
            {renderMultisteps()}
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </>
  );
};

MultistepCreationModal.propTypes = {
  appId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  builderPath: PropTypes.string.isRequired,
  multisteps: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default MultistepCreationModal;
