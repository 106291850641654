import {
  PFButton,
  PFCheckbox,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import { useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const AUTH_LOGIN_TYPE = PROCESS_FLOW.NODE_TYPE_ACTION_OPTIONS
  .find((n) => n.type === 'AUTH');

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit a login node
 *
 * @param {Object} props - NodeLogin props.
 * @param {ProcessFlowNode} props.node - AUTH type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Login node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeLogin = ({ node, updateNode, goBack }) => {
  const [nodeName, setNodeName] = useState(node?.alias ?? '');
  const [asyncNode, setAsyncNode] = useState(node?.async ?? false);

  const [form, fieldHook] = useForm(formConfigFromParams(node.params));

  const addParam = () => {
    const newConfig = form.values;
    newConfig.params.push({ key: '', value: '' });
    form.setConfig(newConfig);
  };

  const removeParam = (index) => {
    const newConfig = form.values;
    newConfig.params.splice(index, 1);
    form.setConfig(newConfig);
  };

  const saveNode = (values) => {
    const params = {};
    for (const { key, value } of values.params) {
      params[key] = value;
    }

    const updatedNode = { ...node };
    updatedNode.alias = nodeName ?? 'Login node';
    updatedNode.params = params;
    updatedNode.async = asyncNode;

    updateNode(updatedNode);
    goBack();
  };

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={saveNode}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)}
              style={{
                color: 'var(--purple-100)'
              }} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={AUTH_LOGIN_TYPE.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{AUTH_LOGIN_TYPE.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {AUTH_LOGIN_TYPE.description}
              </PFText>
            </PFContainer>
          </PFContainer>

          <PFContainer>
            <PFContainer display='flex' justifyContent='flex-end'>
              <PFContainer display='flex' gap='s' alignItems='center'>
                <PFText color='var(--purple-400)' size='s'>
                  ASYNC
                </PFText>
                <PFCheckbox checked={asyncNode}
                  onChange={() => {
                    setAsyncNode((prev) => !prev);
                  }} />
              </PFContainer>
            </PFContainer>

            <PFText margin="bottom-s">Login parameters</PFText>
            <PFContainer>
              <LoginParams
                params={form.values.params}
                fieldHook={fieldHook}
                removeParam={removeParam} />
              <PFButton
                type="support"
                onClick={addParam}
                style={{ justifySelf: 'start' }}>
                + add new param
              </PFButton>
            </PFContainer>
          </PFContainer>

          <PFButton submit>Save</PFButton>
        </PFContainer>
      </PFForm>
    </PFContainer>
  );
};

const LoginParams = ({ params, fieldHook, removeParam }) => {
  return params.map((_, i) => {
    const buttonConfig = {};
    if (i === 0) {
      buttonConfig.disabled = true;
    }

    return (
      <PFContainer key={i} display='flex' gap='s' margin='bottom-xs'>
        <PFContainer display='block' width='160px'>
          <PFInput
            name={`params[${i}].key`}
            placeholder="Key"
            fieldHook={fieldHook}
            style={{ fontSize: 'var(--text-size-s)' }}
            width='auto' />
        </PFContainer>
        <PFContainer display='block' width='160px'>
          <PFInput
            name={`params[${i}].value`}
            placeholder="Value"
            fieldHook={fieldHook}
            style={{ fontSize: 'var(--text-size-s)' }}
            width='auto' />
        </PFContainer>
        <PFContainer display='block'>
          <PFButton
            type="support"
            onClick={() => removeParam(i)}
            {...buttonConfig}>
            <PFIcon icon="bx bx-trash" />
          </PFButton>
        </PFContainer>

      </PFContainer>
    );
  });
};

const formConfigFromParams = (params) => {
  const newConfig = { params: [] };
  for (const key in params) {
    newConfig.params.push({ key, value: params[key] });
  };
  if (newConfig.params.length === 0) {
    newConfig.params.push({ key: '', value: '' });
  }
  return newConfig;
};

NodeLogin.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeLogin;
