import { PFContainer, PFIcon, PFLink, PFText } from 'PFComponents/common';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const DBDashboardSidebar = () => {
  const currentDB = useSelector((state) => state.current_db);
  const location = useLocation();
  const getLinkIconColor = (pathEnding) => {
    return location.pathname.endsWith(pathEnding)
      ? 'white'
      : 'var(--secondary)';
  };

  const getLinkTextColor = (pathEnding) => {
    return location.pathname.endsWith(pathEnding)
      ? 'white'
      : 'var(--secondary)';
  };

  const basePath = `/databases/${currentDB._id}`;

  return (
    <PFContainer type="main"
      display="flex" flexDirection="column" gap="s"
      width='200px'>

      <PFContainer display="flex" flexDirection="column" gap="m">
        <PFLink to={`${basePath}/tables`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-code" size="l"
              color={getLinkIconColor('/tables')} />
            <PFText color={getLinkTextColor('/tables')}>Tables</PFText>
          </PFContainer>
        </PFLink>
      </PFContainer>
    </PFContainer>
  );
};

export default DBDashboardSidebar;
