import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFSelect,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';


const actionOptions = [
  { label: 'Navigate to', value: 'navigate' },
  { label: 'Redirect to', value: 'redirect' },
  { label: 'Update property', value: 'update_property' },
  { label: 'Toggle property', value: 'toggle_property' }
];

const ActionEditor = ({ component, setComponent, eventIndex, actionIndex }) => {
  const actionValue
    = component.events[eventIndex].actions[actionIndex].action;

  const removeAction = (eventIndex, actionIndex) => {
    const updatedEvents = [...component.events];

    if (updatedEvents[eventIndex].actions.length > 0) {
      updatedEvents[eventIndex].actions.splice(actionIndex, 1);
    } else {
      updatedEvents[eventIndex].actions = null;
    }
    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const handleActionChange = (newValue, eventIndex, actionIndex) => {
    const updatedEvents = [...component.events];
    updatedEvents[eventIndex].actions[actionIndex].action = newValue;
    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const handleDestination = (newValue, eventIndex, actionIndex) => {
    const updatedEvents = [...component.events];

    if (
      updatedEvents[eventIndex].actions[actionIndex].params
    ) {
      updatedEvents[eventIndex].actions[actionIndex].params.url = newValue;
      setComponent({
        ...component,
        events: updatedEvents
      });
    }
  };

  const handleModelName = (newValue, eventIndex, actionIndex) => {
    const updatedEvents = [...component.events];

    if (
      updatedEvents[eventIndex].actions[actionIndex].params
    ) {
      updatedEvents[eventIndex]
        .actions[actionIndex].params.modelName = newValue;

      setComponent({
        ...component,
        events: updatedEvents
      });
    }
  };

  const handlePropertyNameChange = (
    newPropertyName,
    eventIndex,
    actionIndex
  ) => {
    const updatedEvents = [...component.events];
    if (
      updatedEvents[eventIndex].actions[actionIndex]
      && typeof newPropertyName !== 'undefined'
    ) {
      const action = updatedEvents[eventIndex].actions[actionIndex];

      if (action.params) {
        if (!action.params.property) {
          action.params.property = {
            propertyName: 'propertyValue'
          };
        }
        const propertyKeys = Object.keys(action.params.property);
        const oldKeyName = propertyKeys.length > 0 ? propertyKeys[0] : '';
        const updatedActions = updatedEvents[eventIndex].actions.map(
          (action, index) => {
            if (index === actionIndex) {
              const updatedProperty = {
                [newPropertyName]: action.params.property[oldKeyName]
              };
              const updatedParams = {
                ...action.params, property: updatedProperty
              };
              return { ...action, params: updatedParams };
            }
            return action;
          });

        updatedEvents[eventIndex].actions = updatedActions;

        setComponent({
          ...component,
          events: updatedEvents
        });
      }
    }
  };

  const handlePropertyValueChange = (
    newPropertyValue,
    currentPropertyName,
    eventIndex,
    actionIndex
  ) => {
    const updatedEvents = [...component.events];

    if (
      updatedEvents[eventIndex].actions[actionIndex]
    ) {
      const action = updatedEvents[eventIndex].actions[actionIndex];

      if (action.params) {
        if (!action.params.property) {
          action.params.property = {
            propertyName: 'propertyValue'
          };
        }
        action.params.property[currentPropertyName] = newPropertyValue;
        const updatedActions = updatedEvents[eventIndex].actions.map(
          (action, index) => {
            if (index === actionIndex) {
              const updatedParams = {
                ...action.params, property: action.params.property
              };
              return { ...action, params: updatedParams };
            }
            return action;
          });
        updatedEvents[eventIndex].actions = updatedActions;

        setComponent({
          ...component,
          events: updatedEvents
        });
      }
    }
  };

  const getActionParams = () => {
    const params
      = component.events[eventIndex].actions[actionIndex].params;

    if (actionValue === 'redirect') {
      return (
        <>
          <PFText size='s'>Destination</PFText>
          <PFInput
            value={
              params.url
                ? params.url
                : ''
            }
            onChange={
              (e) => handleDestination(
                e.target.value, eventIndex, actionIndex
              )}
            placeholder='www.pefai.com'
            fontSize='s'
          />
        </>
      );
    } else if (actionValue === 'navigate') {
      return (
        <>
          <PFText size='s'>Destination</PFText>
          <PFInput
            value={
              params.path
                ? params.path
                : ''
            }
            onChange={
              (e) => {
                const updatedEvents = [...component.events];

                if (
                  updatedEvents[eventIndex].actions[actionIndex].params
                ) {
                  updatedEvents[eventIndex].actions[actionIndex]
                    .params.path = e.target.value;
                  setComponent({
                    ...component,
                    events: updatedEvents
                  });
                }
              }}
            placeholder='www.pefai.com'
            fontSize='s'
          />
        </>
      );
    } else if (
      actionValue === 'update_property'
      || actionValue === 'toggle_property'
    ) {
      const modelNameValue = params.modelName
        ? params.modelName
        : '';

      const propertyNameValue
        = params
          && params.property
          ? Object.keys(params.property)[0] || ''
          : 'propertyName';

      const propertyValue
        = params
          && params.property
          ? params.property[propertyNameValue]
          : '';
      return (
        <>
          <PFText size='s'>Model Name</PFText>
          <PFInput
            value={modelNameValue}
            onChange={
              (e) => handleModelName(e.target.value, eventIndex, actionIndex)
            }
            placeholder='aside_panel'
            fontSize='s'
          />
          <PFText size='s'>Property</PFText>
          <PFInput
            value={propertyNameValue}
            onChange={
              (e) => handlePropertyNameChange(
                e.target.value,
                eventIndex,
                actionIndex
              )}
            placeholder='className'
            fontSize='s'
          />
          <PFText size='s'>Value</PFText>
          <PFInput
            value={propertyValue}
            onChange={(e) => handlePropertyValueChange(
              e.target.value,
              propertyNameValue,
              eventIndex,
              actionIndex
            )}
            placeholder='d-none'
            fontSize='s'
          />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <PFContainer
      key={`action-card-${actionIndex}`}
      display='flex'
      flexDirection='column'
      radius="s"
      padding="s"
      border='1px solid var(--purple-400)'
      background='var(--purple-300)'
    >
      <PFContainer display='flex'
        justifyContent="space-between" alignItems='center'>
        <PFText size='header'>{`Action ${actionIndex + 1}`}</PFText>
        <PFButton onClick={() => removeAction(eventIndex, actionIndex)}
          type='support'
        >
          <PFIcon
            icon='bx bx-trash'
            size='m'
          />
        </PFButton>
      </PFContainer>
      <PFContainer display="grid" gap="s" alignItems="center"
        gridTemplateColumns="auto 1fr" padding='bottom-s'>
        <PFText size='label'>Type</PFText>
        <PFSelect
          placeholder='Select an action'
          style={{
            fontSize: 'var(--text-label)',
            backgroundColor: 'var(--purple-400)'
          }}
          optionsStyles={{
            fontSize: '12px'
          }}
          options={actionOptions}
          value={actionValue}
          onChange={
            (e) => handleActionChange(
              e.target.value,
              eventIndex,
              actionIndex
            )} />
        {getActionParams()}
      </PFContainer>
    </PFContainer>
  );
};

ActionEditor.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  eventIndex: PropTypes.number.isRequired,
  actionIndex: PropTypes.number.isRequired,
};

export default ActionEditor;
