import { PFContainer, PFInput, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';


const QueryPagination = ({
  execute,
  setPageSize,
  setPageNumber
}) => {
  return (
    <>
      <PFContainer display="grid" gap="m" alignItems="center"
        gridTemplateColumns="1fr 2fr 1fr 2fr">
        <PFText type="secondary">Page size</PFText>
        <PFInput
          value={execute.pagination.page_size
            ? execute.pagination.page_size : ''}
          onChange={(e) => {
            setPageSize(e.target.value);
          }} />
        <PFText type="secondary">Page number</PFText>
        <PFInput
          value={execute.pagination.page_number
            ? execute.pagination.page_number : ''}
          onChange={(e) => {
            setPageNumber(e.target.value);
          }} />

      </PFContainer>
    </>

  );
};

QueryPagination.propTypes = {
  execute: PropTypes.object.isRequired,
  db: PropTypes.array,
  setOffset: PropTypes.func,
  setLimit: PropTypes.func,
  setPageSize: PropTypes.func,
  setPageNumber: PropTypes.func
};

export default QueryPagination;
