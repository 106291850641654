import {
  PFButton,
  PFContainer,
  PFIcon, PFSelect, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import '../APIIntegration.css';
import FileFormattingFields from '../FileFormattingFields';
import ParamTypeFields from '../ParamTypeFields';


const AdvancedSettings = ({ form, fieldHook,
  removeParamField, addParamField, formattingKeyOptions }) => {
  const [toggle, setToggle] = useState(false);
  const formattingOptions = [{
    label: 'asset:base64',
    value: 'asset:base64'
  },
  {
    label: 'identity:base64',
    value: 'identity:base64'
  }];

  return (
    <PFContainer>
      <PFButton onClick={() => {
        setToggle((prev) => !prev);
      }}
        padding='0'
        height='auto'
        type='none'
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 'var(--size-m)'
        }}>
        <PFText>Advanced request and response settings</PFText>
        <PFIcon icon={`bx bx-${toggle ? 'chevron-up' : 'chevron-down'}`}
          color='var(--purple-100)' size='l' />
      </PFButton>
      {
        toggle
          ? <PFContainer>
            <PFContainer margin='top-m'>
              <PFText>Request parameter formatting</PFText>
              <PFContainer margin="top-s"
                display="flex" flexDirection="column" gap="s">
                <ParamTypeFields form={form}
                  property="params"
                  fieldHook={fieldHook}
                  remove={(i) => removeParamField('params', i)}
                  inputTypes={[
                    {
                      type: 'select',
                      options: formattingKeyOptions
                    },
                    {
                      type: 'select',
                      options: formattingOptions,
                      flex: true
                    }
                  ]}
                  valueName='format' valueLabel={'Source and format'} />
              </PFContainer>
              <PFButton type="support" onClick={() => addParamField('params'
                , 'format')}>
                + add formatting for a parameter
              </PFButton>
            </PFContainer>
            <PFContainer margin='top-l'>
              <PFText>Response type</PFText>
              <PFContainer width='500px'>
                <PFSelect options={[
                  { label: 'application/json', value: 'application/json' },
                  { label: 'application/pdf', value: 'application/pdf' },
                ]} name={'response_type'} fieldHook={fieldHook} />
              </PFContainer>
            </PFContainer>
            <PFContainer margin='top-l'>
              <PFText>Response parameter file formatting</PFText>
              <PFContainer margin="top-s"
                display="flex" flexDirection="column" gap="s"
                className='field-label'>
                <FileFormattingFields form={form}
                  property="response_formatter"
                  fieldHook={fieldHook}
                  remove={(i) => removeParamField('response_formatter', i)}
                  valueName='format' valueLabel={'File extension'}
                  keyName='field' keyLabel={'Path in response JSON'} />
              </PFContainer>
              <PFButton type="support"
                onClick={() => addParamField('response_formatter'
                  , 'file-format')}>
                + add formatting for a parameter
              </PFButton>
            </PFContainer>

          </PFContainer>

          : <></>
      }

    </PFContainer>
  );
};

AdvancedSettings.propTypes = {
  form: PropTypes.object,
  fieldHook: PropTypes.func,
  removeParamField: PropTypes.func,
  removeParamField: PropTypes.func,
  addParamField: PropTypes.func,
  formattingKeyOptions: PropTypes.array,
};

export default AdvancedSettings;
