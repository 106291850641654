import {
  PFContainer, PFInput, PFSelect, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';

const SelectTables = ({ execute, setTableName, setColumn1, setColumn2,
  setTimestamp, setDatabase, databaseOptions, tableOptions,
  columnOptions }) => {
  return (
    <PFContainer>
      <PFContainer display='flex' gap='s' >
        <PFContainer display='flex' gap='s'
          alignItems='center' flex={1}>
          <PFContainer >
            <PFText>Database</PFText>
          </PFContainer>
          <PFContainer flex={1}>
            <PFSelect value={execute?.database}
              onChange={(e) => {
                setDatabase(e.target.value);
              }}
              options={databaseOptions} />
          </PFContainer>
        </PFContainer>

        <PFContainer display='flex' gap='s'
          alignItems='center' flex={1}>
          <PFContainer >
            <PFText>Table</PFText>
          </PFContainer>
          <PFContainer flex={1}>
            <PFSelect options={tableOptions}
              disabled={!execute?.database} value={execute?.table_name}
              onChange={(e) => {
                setTableName(e.target.value);
              }} />
          </PFContainer>
        </PFContainer>

      </PFContainer>
      {
        execute?.table_name
          ? <>
            {
              execute?.type === 'vector'
                ? <PFContainer display='flex' gap='s'
                  margin='top-m' padding='left-s right-s'>
                  <PFContainer display='flex' gap='s'
                    alignItems='center' flex={1}>
                    <PFContainer >
                      <PFText>Column 1</PFText>
                    </PFContainer>
                    <PFContainer flex={1}>
                      <PFSelect options={columnOptions} value={execute?.column1}
                        onChange={(e) => setColumn1(e.target.value)} />
                    </PFContainer>
                  </PFContainer>

                  <PFContainer display='flex' gap='s'
                    alignItems='center' flex={1}>
                    <PFContainer >
                      <PFText>Column 2</PFText>
                    </PFContainer>
                    <PFContainer flex={1}>
                      <PFSelect options={columnOptions} value={execute?.column2}
                        onChange={(e) => setColumn2(e.target.value)} />
                    </PFContainer>
                  </PFContainer>

                </PFContainer>
                : null
            }
            {
              execute?.type === 'scalar'
                ? <PFContainer display='flex' gap='s'
                  margin='top-m' padding='left-s right-s'>
                  <PFContainer display='flex' gap='s'
                    alignItems='center' flex={1}>
                    <PFContainer >
                      <PFText>Column</PFText>
                    </PFContainer>
                    <PFContainer flex={1}>
                      <PFSelect options={columnOptions} value={execute?.column1}
                        onChange={(e) => setColumn1(e.target.value)} />
                    </PFContainer>
                  </PFContainer>

                  <PFContainer display='flex' gap='s'
                    alignItems='center' flex={1}>
                    <PFContainer >
                      <PFText>Timestamp</PFText>
                    </PFContainer>
                    <PFContainer flex={1}>
                      <PFInput value={execute?.timestamp}
                        onChange={(e) => {
                          setTimestamp(e.target.value);
                        }} placeholder='YYYY-MM-DD HH:mm' />
                    </PFContainer>
                  </PFContainer>

                </PFContainer>
                : null
            }
          </>
          : null
      }

    </PFContainer>
  );
};

SelectTables.propTypes = {
  execute: PropTypes.object,
  setTableName: PropTypes.func,
  setColumn1: PropTypes.func,
  setColumn2: PropTypes.func,
  setTimestamp: PropTypes.func,
  setDatabase: PropTypes.func,
  databaseOptions: PropTypes.array,
  tableOptions: PropTypes.array,
  columnOptions: PropTypes.array,
};

export default SelectTables;
