import { PFContainer } from 'PFComponents/common';
import { PFPublishButton } from 'PFComponents/special';
import { permissions } from 'PFUtils';
import { useSelector } from 'react-redux';
import { SharingPanel } from './components';


const AppDashboardActions = () => {
  const currentApp = useSelector((state) => state.current_app);

  const userRole = currentApp.current_user_role;
  const hasAdminAccess = permissions.hasAdminAccess(userRole);

  return (
    <PFContainer type="main"
      display="flex" justifyContent="flex-end" gap="s">
      {hasAdminAccess
        ? <>
          <SharingPanel />
          <div title='Launch all changes'>
            <PFPublishButton type="secondary" height={50}
              text="Launch"
              padding="horizontal-l" />
          </div>
        </>
        : <PFContainer height={30} />}
    </PFContainer>
  );
};

export default AppDashboardActions;
