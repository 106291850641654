import PropTypes from 'prop-types';

import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLine,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';

const AppCreationModal = ({
  title = 'Create a new app',
  callToAction = 'Create app',
  active,
  onDismiss,
  onSubmit,
}) => {
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm({
    name: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a name for your new app',
    }
  });

  const submitForm = (values) => {
    asyncCall(async () => await onSubmit(values));
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFForm form={form} submit={submitForm}>
            <PFContainer margin="bottom-m"
              display="flex" alignItems="center" justifyContent="space-between">
              <PFText type="support">{title}</PFText>
              <PFButton type="support" onClick={onDismiss}
                padding="none" height={28}
                style={{
                  background: 'var(--purple-50)',
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}>
                <PFIcon icon="bx bx-x" size="l" />
              </PFButton>
            </PFContainer>
            <PFContainer display="flex" flexDirection="column" gap="s">
              <PFInput
                name="name"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Name
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
            </PFContainer>
            <PFContainer margin="top-l" display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={loading}>
                {callToAction}
              </PFButton>
            </PFContainer>
          </PFForm>
        </PFContainer >
      </PFModal >
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </>
  );
};

AppCreationModal.propTypes = {
  title: PropTypes.string,
  callToAction: PropTypes.string,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AppCreationModal;
