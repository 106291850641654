import {
  PFButton,
  PFContainer,
  PFIcon,
  PFLink,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFAppEditorHeader } from 'PFComponents/navigation';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageSettings from './PageSettings/PageSettings';


import { useBreakpointContext } from '../Canvas/utils';


const styleActiveBreakpointLabel = (activeBreakpoint) => {
  const breakpointValue = activeBreakpoint ? activeBreakpoint['value'] : '100%';

  const matchedLabelValue = breakpointValue.
    match(/^(\d+)([a-zA-Z%]+)$/);

  if (matchedLabelValue) {
    const numberLabel = matchedLabelValue[1];
    const unitLabel = matchedLabelValue[2];
    return (
      <>
        <>{numberLabel}</>
        <span style={{ color: 'white' }}>{` ${unitLabel}`}</span>
      </>
    );
  }
};

const PageEditorHeader = ({
  appId,
  pageId,
  builderPath,
  save,
  saveLoading,
  saved,
  page,
  setPage,
  togglePageSetings,
  setTogglePageSettings,

}) => {
  const {
    activeBreakpoint,
    themeBreakpoints,
    updateBreakpoint
  } = useBreakpointContext();

  const navigate = useNavigate();

  const pages = useSelector((state) =>
    state.current_app.build.pages.docs);

  const pageOptions = pages.map((page) => {
    return { label: page.alias, value: page.builder_path };
  });

  const changePage = (e) => {
    0;
    const path = e.target.value;
    navigate(`/editor/apps/${appId}/pages/${path}`);
  };

  const backToApps = () => {
    navigate(`/apps/${appId}/pages`);
  };

  return (
    <PFAppEditorHeader backAction={backToApps}>
      {
        togglePageSetings && <PageSettings
          setTogglePageSettings={setTogglePageSettings}
          page={page} setPage={setPage} />
      }
      <PFContainer
        display='flex'
        justifyContent='space-between'
        alignItems='center'>
        <PFContainer display='flex' alignItems='center' gap='m'>
          <PFContainer width={300}>
            <PFSelect
              className='header'
              prefix='Page:'
              value={builderPath}
              onChange={changePage}
              options={pageOptions}
              optionsStyles={{
                color: 'var(--white)'
              }}
              listStyles={{
                borderRadius: '8px',
                border: '1px solid var(--purple-300)',
                backgroundColor: 'var(--purple-500)'
              }}
            />
          </PFContainer>
          <PFButton
            type='none'
            padding='xs'
            onClick={() => {
              setTogglePageSettings((prev) => {
                return !prev;
              });
            }}>
            <PFIcon icon='bx bx-cog' size='l'
              color='var(--purple-100)' margin='top-xs' />
          </PFButton>
          <PFLink to={`/json-editor/apps/${appId}/pages/${builderPath}`}>
            <PFIcon icon='bx bxs-file-json' size='l' margin='top-xs' />
          </PFLink>
        </PFContainer>
        <PFContainer display='flex' alignItems='center' gap='m'>
          {themeBreakpoints.map((breakpoint, index) => {
            return (
              <PFButton
                key={`breakpoint-control-${index}`}
                type='none'
                padding='xs'
                onClick={() => {
                  updateBreakpoint(themeBreakpoints[index]);
                }}>
                <PFIcon icon={`bx ${breakpoint.iconClassName}`} size='l'
                  color={activeBreakpoint !== null
                    && activeBreakpoint.key === breakpoint.key
                    ? 'white' : 'var(--purple-100)'} margin='top-xs' />
              </PFButton>
            );
          })}
          <PFText color='var(--purple-100)'
            size='m'>
            {styleActiveBreakpointLabel(activeBreakpoint)}
          </PFText>
        </PFContainer>
        <PFContainer display='flex' gap='m'>
          {!saveLoading && saved
            && <PFContainer
              display='flex' alignItems='center' gap='s'>
              <PFText type='secondary'>Saved</PFText>
              <PFIcon
                icon='bx bx-check-circle'
                color='var(--secondary)'
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type='secondary' width={80}
            onClick={save} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
    </PFAppEditorHeader>
  );
};

PageEditorHeader.propTypes = {
  appId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  builderPath: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  page: PropTypes.object,
  setPage: PropTypes.func.isRequired,
  togglePageSetings: PropTypes.bool,
  setTogglePageSettings: PropTypes.func
};

export default PageEditorHeader;
