import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useGridApiContext } from '@mui/x-data-grid';
import { PFButton, PFContainer, PFModal, PFText } from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import store, { currentDBActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';


const DeleteColumn = ({ colDef, setUpdateTable, setExitModeWarning }) => {
  const { db_id, table_name } = useParams();
  const column_name = colDef.field;
  const currentUserId = useSelector((state) => state.user._id);
  const [confirmationActive, setConfirmationActive] = useState(false);
  const apiRef = useGridApiContext();
  const [del, alerts] = useAsyncCalls(false);

  const deleteColumn = async () => {
    del.asyncCall(async () => {
      await builderTest(currentUserId)
        .removeDBTableColumn(db_id, table_name, column_name);
      store.dispatch(currentDBActions.removeTableField({
        table_name,
        field_name: column_name
      }));
      setUpdateTable((prev) => !prev);
    });
  };

  const openConfirmation = () => setConfirmationActive(true);
  const closeConfirmation = () => setConfirmationActive(false);
  return (
    <>
      <MenuItem onClick={() => {
        if (Object.keys(apiRef.current.state.editRows).length > 0) {
          setExitModeWarning(true);
        } else {
          openConfirmation();
        }
      }}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete column</ListItemText>
      </MenuItem >
      <PFModal active={confirmationActive} zIndex={10000}>
        {
          column_name === 'id'
            ? <PFContainer type="toolbar" padding="l" radius="l" width={800}>
              <PFContainer margin="bottom-m"
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <PFText type="secondary">
                  ID column can't be deleted.
                </PFText>
              </PFContainer>
              <PFContainer margin="top-l"
                display="flex" justifyContent="end" gap="m">
                <PFButton width={100}
                  onClick={closeConfirmation}
                  loading={del.loading}>
                  Ok
                </PFButton>
              </PFContainer>
            </PFContainer >
            : <PFContainer type="toolbar" padding="l" radius="l" width={800}>
              <PFContainer margin="bottom-m"
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <PFText type="secondary">
                  Are you sure you want to delete this column?
                </PFText>
              </PFContainer>
              <PFContainer margin="top-l"
                display="flex" justifyContent="end" gap="m">
                <PFButton type="secondary" width={100}
                  onClick={closeConfirmation}>
                  Cancel
                </PFButton>
                <PFButton width={100}
                  onClick={deleteColumn}
                  loading={del.loading}>
                  Yes
                </PFButton>
              </PFContainer>
            </PFContainer >
        }
        {alerts()}
      </PFModal >
    </>
  );
};

DeleteColumn.propTypes = {
  colDef: PropTypes.object.isRequired,
  setExitModeWarning: PropTypes.func,
  setUpdateTable: PropTypes.func,
};

export default DeleteColumn;
