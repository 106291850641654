import { authPefai } from 'PFApis';
import { PFScreenLoading, PFScreenMoodboard } from 'PFScreens';
import store, { authActions, userActions } from 'PFStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { PFLayoutAppDashboard } from './PFLayoutAppDashboard';
import { PFLayoutAppEditor } from './PFLayoutAppEditor';
import { PFLayoutDBDashboard } from './PFLayoutDBDashboard';
import { PFLayoutDBEditor } from './PFLayoutDBEditor';
import { PFLayoutFunctionDashboard } from './PFLayoutFunctionDashboard';
import { PFLayoutFunctionEditor } from './PFLayoutFunctionEditor';
import { PFLayoutJSONEditor } from './PFLayoutJSONEditor';
import { PFLayoutUserContent } from './PFLayoutUserContent';
import { PFLayoutUserProfile } from './PFLayoutUserProfile';

const PFLayoutGuest = () => {
  const { access_token } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchAsync = async () => {
      try {
        const user = await authPefai.getUser();
        store.dispatch(userActions.setUser(user));
      } catch (error) {
        store.dispatch(authActions.removeAccessToken());
      }
    };

    if (access_token) fetchAsync();
  }, []);

  if (!user) {
    return <PFScreenLoading />;
  } else {
    return (
      <Routes>
        <Route path="/moodboard" element={<PFScreenMoodboard />} />
        <Route path="/apps/:app_id/*" element={<PFLayoutAppDashboard />} />
        <Route path="/editor/apps/:app_id/*" element={<PFLayoutAppEditor />} />
        <Route path="/json-editor/apps/:app_id/*"
          element={<PFLayoutJSONEditor />} />
        <Route path="/functions/:function_id/*"
          element={<PFLayoutFunctionDashboard />} />
        <Route path="/editor/functions/:function_id/*"
          element={<PFLayoutFunctionEditor />} />
        <Route path="/databases/:db_id/*"
          element={<PFLayoutDBDashboard />} />
        <Route path="/editor/databases/:db_id/*"
          element={<PFLayoutDBEditor />} />
        <Route path="/profile/*"
          element={<PFLayoutUserProfile />} />
        <Route path="/*" element={<PFLayoutUserContent />} />
      </Routes>
    );
  }
};

export default PFLayoutGuest;
