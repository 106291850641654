export default {
  NULLABLE: 'nullable',
  UNIQUE: 'unique',
  DEFAULT_VALUE: 'default_value',
  LENGTH: 'length',
  SCALE: 'scale',
  INDEX: 'index',
  TABLE: 'table',
  COLUMN: 'column'
};
