import React, { ReactNode, useRef, Children} from 'react';
import * as Styled from './table.styled';

import { useVirtual, VirtualItem } from 'react-virtual';

type TableProps = {
  /**
   * Row node to render in the table component
   */
  children?: ReactNode | ReactNode[];

  /**
   * Class names override
   */
  className?: string;

  /**
   * An array to specify the type of data in each column.
   * string: left-aligned content
   * number: right-aligned content
   */
  columnDataType?: 'string' | 'number';

  /**
   * Styling overrides
   */
  configStyles?: string;

  /**
   * To identify a table component.
   */
  id?: string;

  /**
   * To identify the rows that belong to the header
   */
  tableHeadId: string;

  /**
   * The number of items to render above and below the visible area. Increasing this number will increase the amount of time it takes to render the virtualizer, but might decrease the likelihood of seeing slow-rendering blank items at the top and bottom of the virtualizer when scrolling.
   */
  virtualizedRows?: number;
};

function Table({className, configStyles, children, columnDataType='string', id, tableHeadId, virtualizedRows=1} : TableProps) {
  let tableContainerRef = useRef<HTMLDivElement>(null);
  let tableHead: ReactNode | ReactNode[];
  let tableBody: ReactNode | ReactNode[];
  
  const headChildrenRows = Children.toArray(children).filter((child) => child['props']['id'] === tableHeadId);
  const bodyChildrenRows = Children.toArray(children).filter((child) => child['props']['id'] !== tableHeadId);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: bodyChildrenRows.length,
    overscan: virtualizedRows
  });

  const calculatePaddingTop = (virtualRows: VirtualItem[]) => {
    return virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  };

  const calculatePaddingBottom = (virtualRows: VirtualItem[], totalSize: number) => {
    return virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
  };

  const {virtualItems: virtualRows, totalSize} = rowVirtualizer;

  const paddingTop = calculatePaddingTop(virtualRows);
  const paddingBottom = calculatePaddingBottom(virtualRows, totalSize);

  tableHead = (
    <TableHead key={'table-head'}>
      {headChildrenRows.map((headRow) => headRow)}
    </TableHead>
  );

  tableBody = (
    <TableBody key={'table-body'}>
      {
        paddingTop > 0 && (
          <TableBodyRow key={'upper-offset-cell'}>
            <TableBodyCell heightOffset={paddingTop}/>
          </TableBodyRow>
        )
      }
      { virtualRows.length > 0 ?
        virtualRows.map((virtualRow) => bodyChildrenRows[virtualRow.index]) : bodyChildrenRows
      }
      {paddingBottom > 0 && (
        <TableBodyRow key={'lower-offset-cell'}>
          <TableBodyCell heightOffset={paddingBottom}/>
        </TableBodyRow>
      )}
    </TableBody>
  );

  return (
    <Styled.TableContainer ref={tableContainerRef}>
      <Styled.Table
        id={id}
        className={className}
        configStyles={configStyles}
        tableHeadId={tableHeadId}
      >
        {tableHead}
        {tableBody}
      </Styled.Table>
    </Styled.TableContainer>
  );
}

type ChildrenProps = {
  children: ReactNode | ReactNode[];
  id?: string;
}

function TableHead({children, id}: ChildrenProps) {
  return (
    <Styled.TableHead id={id}>
      {children}
    </Styled.TableHead>
  );
}

function TableHeadRow({children, id}: ChildrenProps) {
  return (
    <Styled.TableHeadRow id={id}>
      {children}
    </Styled.TableHeadRow>
  );
}

function TableHeadColumn({children, columnDataType, id}: {children: ReactNode | ReactNode[], columnDataType?: string, id?: string}) {
  return (
    <Styled.TableHeadColumn columnDataType={columnDataType} id={id}>
      {children}
    </Styled.TableHeadColumn>
  );
}

function TableBody({children, id}: ChildrenProps) {
  return (
    <Styled.TableBody id={id}>
      {children}
    </Styled.TableBody>
  );
}

function TableBodyRow({children, id}: ChildrenProps) {
  return (
    <Styled.TableBodyRow id={id}>
      {children}
    </Styled.TableBodyRow>
  );
}

function TableBodyCell({children, columnDataType, heightOffset, id}: {children?: ReactNode | ReactNode[], columnDataType?: string, heightOffset?: number, id?: string}) {
  return (
    <Styled.TableBodyCell columnDataType={columnDataType} heightOffset={heightOffset} id={id}>
      {children}
    </Styled.TableBodyCell>
  );
}
export type {TableProps, ChildrenProps};
export {Table, TableHead, TableHeadRow, TableHeadColumn, TableBody, TableBodyRow, TableBodyCell};