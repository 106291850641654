import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (_state, { payload }) => {
      return { ...payload };
    },
    resetUser: () => {
      return null;
    },
    addFunction: (state, { payload }) => {
      state.associated_functions.count++;
      state.associated_functions.docs.push(payload);
      return state;
    },
    removeFunction: (state, { payload }) => {
      state.associated_functions.count--;
      state.associated_functions.docs
        = state.associated_functions.docs.filter(
          (func) => func._id !== payload);
      return state;
    },
    addDB: (state, { payload }) => {
      state.associated_dbs.count++;
      state.associated_dbs.docs.push(payload);
      return state;
    },
    removeDB: (state, { payload }) => {
      state.associated_dbs.count--;
      state.associated_dbs.docs
        = state.associated_dbs.docs.filter(
          (db) => db._id !== payload);
      return state;
    },
  }
});

export default userSlice.reducer;
export const {
  setUser,
  resetUser,
  addFunction,
  removeFunction,
  addDB,
  removeDB,
} = userSlice.actions;
