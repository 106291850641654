import {
  PFAlert,
  PFButton, PFContainer,
  PFIcon, PFLoader, PFSelect, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import { permissions } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { SharingPanel } from './components';

const DBDashboardActions = () => {
  const currentDB = useSelector((state) => state.current_db);
  const currentUserId = useSelector((state) => state.user._id);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const userRole = currentDB.current_user_role;
  const hasAdminAccess = permissions.hasAdminAccess(userRole);
  const navigate = useNavigate();
  const { db_id } = useParams();
  const dbCount = currentDB?.associated_dbs.count;
  const [published, setPublished] = useState(false);

  const getEnvOptions = () => {
    if (dbCount === 0) return [];
    const currentDbOption = {
      label: currentDB?.env,
      value: currentDB?._id
    };

    if (currentDB.env === 'PROD') {
      const associatedOptions = currentDB.associated_dbs
        .docs.map(({ _id }) => ({
          label: 'DEFAULT', value: _id
        }));
      return [...associatedOptions, currentDbOption];
    }

    const associatedOptions = currentDB.associated_dbs
      .docs.map(({ env, _id }) => ({
        label: env, value: _id
      }));

    return [...associatedOptions, currentDbOption];
  };

  const enableProdDb = (shouldNavigate = false) => {
    asyncCall(async () => {
      const databaseId = await builderTest(currentUserId)
        .publishDatabase(db_id, 'PROD');
      if (shouldNavigate) {
        navigate(`/databases/${databaseId}/tables`);
      }
      setPublished(true);
      setTimeout(() => {
        setPublished(false);
      }, 1500);
    });
  };

  return (
    <PFContainer type="main"
      display="flex" justifyContent="flex-end" gap="s">
      {hasAdminAccess
        ? <PFContainer display='flex' gap='s'
          justifyContent='space-between' alignItems='center' >
          {
            dbCount > 0
              ? <PFContainer width='200px' margin='right-l'>
                <PFSelect options={getEnvOptions()} onChange={(e) => {
                  navigate(`/databases/${e.target.value}/tables`);
                }} value={db_id} />
              </PFContainer>
              : null
          }

          <PFContainer display='flex' gap='s'>
            <SharingPanel />
            <>
              {
                currentDB.env === 'PROD'
                  ? null
                  : <>
                    {
                      dbCount > 0
                        ? <PFButton type="secondary"
                          height={50}
                          width='220px'
                          padding="horizontal-m"
                          style={{
                            display: 'flex',
                            gap: 'var(--size-s)', alignItems: 'center'
                          }} onClick={enableProdDb}>
                          {
                            loading
                              ? <PFContainer
                                display='flex' justifyContent='center'
                                width='100%'>
                                <PFLoader color='var(--pruple-200)' />
                              </PFContainer>
                              : <>
                                {
                                  published
                                    ? <PFContainer
                                      display='flex' justifyContent='center'
                                      width='100%' alignItems='center' >
                                      <PFIcon icon='bx bx-check' size='l' />
                                      <PFText color='var(--purple-100)'>
                                        Published!
                                      </PFText>
                                    </PFContainer>
                                    : <>
                                      <PFIcon icon='bx bx-rocket' size='l' />
                                      <PFText color='var(--purple-100)'>
                                        Publish changes to Prod
                                      </PFText>
                                    </>
                                }

                              </>
                          }

                        </PFButton>
                        : <PFButton type="secondary"
                          height={50}
                          width='220px'
                          padding="horizontal-m"
                          style={{
                            display: 'flex',
                            gap: 'var(--size-s)', alignItems: 'center'
                          }} onClick={() => enableProdDb(true)}>
                          {
                            loading ? <PFContainer
                              display='flex' justifyContent='center'
                              width='100%'>
                              <PFLoader color='var(--pruple-200)' />
                            </PFContainer>
                              : <>
                                <PFIcon icon='bx bx-upload' size='l' />
                                <PFText color='var(--purple-100)'>
                                  Enable Prod DB
                                </PFText>
                              </>
                          }

                        </PFButton>
                    }

                  </>
              }
            </>


          </PFContainer>

        </PFContainer>
        : <PFContainer height={30} />}
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default DBDashboardActions;
