import {
  PFButton, PFIcon, PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const CopyRootButton = ({ copyTarget }) => {
  const [copiedAlert, setCopiedAlert] = useState(null);

  return (
    <PFButton type='support' height='auto' width={'100%'}
      margin='top-s' padding='s'
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 'var(--size-s)',
        width: 'fit-content'
      }}
      onClick={() => {
        generalUtils.copyToClipboard(copyTarget);
        setCopiedAlert(true);
        setTimeout(() => {
          setCopiedAlert(false);
        }, [3000]);
      }}>
      <PFIcon icon='bx bx-copy' />
      {
        copiedAlert
          ? <PFText size='s'>Copied to clipboard!</PFText>
          : <PFText size='s'>Copy root variable</PFText>
      }

    </PFButton>
  );
};

CopyRootButton.propTypes = {
  copyTarget: PropTypes.string
};
export default CopyRootButton;
