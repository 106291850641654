export const conditionalOperators = [
  {
    label: 'equals',
    value: 'equals'
  },
  {
    label: 'notEquals',
    value: 'notEquals'
  },
  {
    label: 'empty',
    value: 'empty'
  },
  {
    label: 'notEmpty',
    value: 'notEmpty'
  },
  {
    label: 'greaterThan',
    value: 'greaterThan'
  },
  {
    label: 'lowerThan',
    value: 'lowerThan'
  },
];
