import {
  PFContainer,
  PFIcon, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ShortUniqueId from 'short-unique-id';
import 'src/App.css';
import curly from './assets/curly.svg';

const CopyOptions = ({ setTab, createNode,
  next, setIsHovered, flowAlias, nodeElement }) => {
  const uid = new ShortUniqueId({ length: 6 });
  const [copied, setCopied] = useState(false);
  return (
    <div
      className='var_item'
      style={{
        padding: 'var(--size-s)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 'var(--size-m)',
        borderRadius: 'var(--radius-s)',
        cursor: 'pointer',
        overflow: 'hidden'
      }}
      onClick={() => {
        setIsHovered(false);
        setTab('START');
        createNode({
          ...nodeElement,
          _id: uid(),
          next: next || null
        });
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}>
      <PFContainer
        background='#2C263E'
        radius='s'
        width='fit-content'
        padding='s'>
        <img width={'20px'} src={curly} />
      </PFContainer>
      <PFContainer flex={1}>
        <PFText color='#fff'>{nodeElement.alias}</PFText>
        <PFText size='s' color={'var(--gray-100)'}>
          {flowAlias}
        </PFText>

      </PFContainer>
      <PFIcon
        icon={`bx bx-${copied ? 'check' : 'copy'}`}
        color={'var(--gray-100)'}
        size='l' />
    </div>
  );
};

CopyOptions.propTypes = {
  setTab: PropTypes.func.isRequired,
  createNode: PropTypes.func,
  next: PropTypes.string,
  setIsHovered: PropTypes.func,
  flowAlias: PropTypes.string,
  nodeElement: PropTypes.object
};

export default CopyOptions;
