import React, { ReactNode } from 'react';
import * as Styled from './parragraph.styled';

export type ParragraphProps = {
    /**
     * To identify the component
     */
    id?: string;
    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode;

    /**
     * classes for external library implementations
     */
    className?: string;

    /**
     * for component level styling override (Design System)
     * @config implementation required
     */
    configStyles?: string;
};

export function Parragraph({ children, className = '', configStyles = '', id, ...props }: ParragraphProps) {
    return (
        <Styled.Parragraph className={className} configStyles={configStyles} id={id} {...props}>
            {children}
        </Styled.Parragraph>
    );
}
