import PropTypes from 'prop-types';

import { PFContainer, PFSelect, PFText } from 'PFComponents/common';

const UpdateOrigin = ({
  databases,
  db,
  schemaDB,
  setSchemaDB,
  table,
  setTable,
  tableSelectionDisabled,
}) => {
  return (
    <PFContainer display="grid" gap="m" alignItems="center"
      gridTemplateColumns=".2fr 1fr .2fr 1fr">
      <PFText type="secondary">Database</PFText>
      <PFSelect
        value={schemaDB}
        onChange={(e) => setSchemaDB(e.target.value)}
        options={databases.map((db) => ({
          label: db.alias, value: db._id
        }))} />
      <PFText type="secondary">Table</PFText>
      <PFSelect
        disabled={tableSelectionDisabled}
        value={table}
        onChange={(e) => setTable(e.target.value)}
        options={!db
          ? []
          : db.tables.map((table) => ({
            label: table.alias, value: table.table_name
          }))} />
    </PFContainer>
  );
};

UpdateOrigin.propTypes = {
  databases: PropTypes.array.isRequired,
  db: PropTypes.object,
  schemaDB: PropTypes.string.isRequired,
  setSchemaDB: PropTypes.func.isRequired,
  table: PropTypes.string.isRequired,
  setTable: PropTypes.func.isRequired,
  tableSelectionDisabled: PropTypes.bool.isRequired,
};

export default UpdateOrigin;
