import {
  PFButton, PFCodeEditor, PFContainer
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import validators from 'src/utils/validators';

const JsonView = ({ operation, updateOperation,
  stringResponse, setStringResponse, saved, setSaved }) => {
  return (
    <PFContainer>
      <PFCodeEditor
        value={stringResponse}
        onChange={(e) => {
          setStringResponse(e.target.value);
        }}
        placeholder="JSON response format"
        error={validators.validateJSON(stringResponse)}
        minHeight={100} />
      <PFContainer display='flex' justifyContent='flex-end'
        margin='top-s'>
        <PFButton
          disabled={!!validators.validateJSON(stringResponse)}
          onClick={() => {
            const parsedResponse = JSON.parse(stringResponse);
            const responseFormatted = Object
              .keys(parsedResponse).map((key) => ({
                name: key,
                value: parsedResponse[key]
              }));
            updateOperation({
              ...operation,
              response_format: responseFormatted
            });
            setSaved(true);
            setTimeout(() => {
              setSaved(false);
            }, 1200);
          }}>
          {saved ? 'Saved!' : 'Save'}
        </PFButton>
      </PFContainer>
    </PFContainer>
  );
};

JsonView.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
  variables: PropTypes.array,
  stringResponse: PropTypes.string,
  setStringResponse: PropTypes.func,
  saved: PropTypes.bool,
  setSaved: PropTypes.func,
};

export default JsonView;
