import { builderV2 } from 'PFApis';
import {
  PFAlert, PFButton, PFContainer, PFIcon, PFLink, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { EnvironmentDetailSelectionModal } from './components';
const PFScreenFunctionEnvironmentDetail = () => {
  const navigate = useNavigate();
  const currentFunction = useSelector((state) => state.current_function);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { function_id, environment_id } = useParams();
  const [dbEnv, setDBEnv] = useState({});
  const [createAsyncCall, , createError, dismissCreateError]
    = useAsyncCall(false);
  const associatedDBs = currentFunction.env_dbs[environment_id];
  const baseURL = `/functions/${function_id}/environments`;

  useEffect(() => {
    const syncArrays = (associatedDbs, envDbs) => {
      const envDbsMap = new Map(envDbs.map((item) => [item._id, item]));
      const newEnvDbs = [];
      let areSame = associatedDbs.length === envDbs.length;
      associatedDbs.forEach((item) => {
        if (envDbsMap.has(item._id)) {
          newEnvDbs.push(envDbsMap.get(item._id));
        } else {
          newEnvDbs.push({
            ...item,
            env: 'DEFAULT'
          });
          areSame = false;
        }
      });
      if (areSame) {
        for (const item of envDbs) {
          if (!associatedDbs.some((aItem) => aItem._id === item._id)) {
            areSame = false;
            break;
          }
        }
      }

      return { newArray: newEnvDbs, areSame };
    };
    const { newArray: dbEnvs, areSame }
      = syncArrays(currentFunction.associated_dbs.docs, associatedDBs);
    if (!areSame) {
      createAsyncCall(async () => {
        await builderV2.functionAddDbsEnv(function_id, environment_id, dbEnvs);
        navigate(0);
      });
    }
  }, []);

  const renderDBEnvs = () => {
    return associatedDBs.map((dbElement) => {
      return (
        <PFContainer key={dbElement._id} style={{
          position: 'relative'
        }} width='100%'>
          <PFButton type='none'
            padding='right-m left-m bottom-m top-m'
            style={{
              border: '1px solid var(--purple-100)',
              borderRadius: 'var(--radius-m)',
              textAlign: 'start'
            }} height='200px' width='100%'
            options={{
              'Delete': () => { }
            }}
            onClick={() => {
              setIsModalOpen(true);
              setDBEnv(dbElement);
            }}
          >
            <PFText type="secondary" size="s" margin="top-s">
              {dbElement._id}
            </PFText>
            <PFText margin="top-s">{dbElement.alias}</PFText>
            <PFText type="secondary" size="s" margin="top-s">
              Connected to  {dbElement.env}
            </PFText>
          </PFButton>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer display='flex' gap='xs'>
          <PFText tag="h2" size="l"
            margin='right-xs'>Environments: </PFText>
          <PFText tag="h2" size="l" color='var(--purple-100)'>
            {environment_id}</PFText>
        </PFContainer>
        <PFContainer display="flex" margin='bottom-m'>
          <PFLink to={baseURL}>
            <PFText type="secondary" size="s">Environments</PFText>
          </PFLink>
          <PFIcon icon="bx bx-chevron-right" color="var(--secondary)" />
          <PFLink>
            <PFText type="secondary" size="s">{environment_id}</PFText>
          </PFLink>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          {renderDBEnvs()}
        </PFContainer>
      </PFContainer>
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
      <EnvironmentDetailSelectionModal
        active={isModalOpen} onDismiss={() => setIsModalOpen(false)}
        dbEnv={dbEnv} setDBEnv={setDBEnv} function_id={function_id}
        associatedDBs={associatedDBs} functionEnvName={environment_id} />
    </PFContainer>
  );
};

export default PFScreenFunctionEnvironmentDetail;
