/**
 * Roles available for Pefai elements (Apps, Functions and Databases)
 */
const ROLES = [
  'VIEWER',
  'EDITOR',
  'ADMIN',
  'OWNER'
];

/**
 * Function that checks if a given role has the minimum required level of
 * permission to perform an action that only a 'viewer' or above can perform.
 *
 * @param {string} role - User role to check.
 * @return {boolean} If the role clears the permission level.
 */
export const hasViewerAccess = (role) => {
  return ROLES.includes(role);
};

/**
 * Function that checks if a given role has the minimum required level of
 * permission to perform an action that only an 'editor' or above can perform.
 *
 * @param {string} role - User role to check.
 * @return {boolean} If the role clears the permission level.
 */
export const hasEditorAccess = (role) => {
  return ROLES.slice(1).includes(role);
};

/**
 * Function that checks if a given role has the minimum required level of
 * permission to perform an action that only an 'admin' or above can perform.
 *
 * @param {string} role - User role to check.
 * @return {boolean} If the role clears the permission level.
 */
export const hasAdminAccess = (role) => {
  return ROLES.slice(2).includes(role);
};

/**
 * Function that checks if a given role has the minimum required level of
 * permission to perform an action that only an 'owner' can perform.
 *
 * @param {string} role - User role to check.
 * @return {boolean} If the role clears the permission level.
 */
export const hasOwnerAccess = (role) => {
  return role === 'OWNER';
};
