import React, { ReactNode } from 'react';
import * as Styled from './label.styled';

export type LabelProps = {
    /**
     * To identify the component
     */
    id?: string;
    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode;

    /**
     * class names override
     */
    className?: string;

    /**
     * for component level styling override (Design System)
     * @config implementation required
     */
    configStyles?: string;

    /**
     * id - for label/input
     */
    htmlFor: string;
};

export function Label({ configStyles = '', className, children, htmlFor, id, ...props }: LabelProps) {
    return (
        <Styled.Label className={className} htmlFor={htmlFor} configStyles={configStyles} id={id} {...props}>
            {children}
        </Styled.Label>
    );
}
