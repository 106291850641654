import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, {
  currentAppActions,
  currentFunctionActions, userActions
} from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FunctionCreationModal } from './components';


const PFScreenUserFunctions = () => {
  const functions = useSelector((state) =>
    state.user.associated_functions.docs ?? []);

  const [creationFormOpen, setCreationFormOpen] = useState(false);
  const [deleteAsyncCall, , deleteError, dismissDeleteError]
    = useAsyncCall(false);
  const [DeletionDialog, confirmDeletion] = useConfirmation(
    'Are you sure you want to delete this function?',
  );
  useEffect(() => {
    store.dispatch(currentAppActions.setCurrentApp(null));
    store.dispatch(currentFunctionActions.setCurrentFunction(null));
  }, []);
  const deleteFunction = (functionId) => {
    deleteAsyncCall(async () => {
      if (await confirmDeletion()) {
        store.dispatch(userActions.removeFunction(functionId));
        await builderV2.deleteFunction(functionId);
      }
    });
  };


  const renderFunctions = () => {
    return functions.map((func) => {
      return (
        <PFItemCard key={func._id} path={`/functions/${func._id}/operations`}
          options={{
            'Delete': () => deleteFunction(func._id),
            'Copy ID': () => generalUtils.copyToClipboard(func._id)
          }}>
          <PFText padding="top-m">{func.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {func._id}
          </PFText>
        </PFItemCard>
      );
    });
  };
  return (
    <PFContainer>
      <PFContainer display="flex" margin="vertical-m"
        justifyContent="space-between" alignItems="flex-end">
        <PFText tag="h1" size="l" weight={400}>Your functions</PFText>
        <PFText type="secondary">{functions.length} Total</PFText>
      </PFContainer>
      <PFContainer display="grid"
        gridTemplateColumns="1fr 1fr" gap="m">
        <PFActionCard onClick={() => setCreationFormOpen(true)}>
          <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
          <PFText>New Function</PFText>
        </PFActionCard>
        {renderFunctions()}
      </PFContainer>
      <FunctionCreationModal active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
      <DeletionDialog />
      <PFAlert
        message={deleteError}
        open={!!deleteError}
        onClose={dismissDeleteError} />
    </PFContainer>
  );
};

export default PFScreenUserFunctions;
