import PropTypes from 'prop-types';

import { componentUtils } from 'PFUtils';

import './PFLine.css';

/**
 * Standard line component of the PFComponents library. This line complies
 * with the Pefai style guidelines.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFLine properties.
 * @param {string} [props.className] - Class name to be applied to the line.
 * @param {"horizontal"|"vertical"} [props.orientation="horizontal] -
 * Determines the orientation of the line.
 * @param {string|number} [props.length] - CSS configuration for the length of
 * the line (either height or width depending on the 'orientation' prop).
 * @param {string|number} [props.width] - CSS configuration for the width of
 * the line (either height or width depending on the 'orientation' prop).
 * @param {string} [props.color] - Color of the line.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the line.
 * @param {string} [props.margin] - Determines the margin config of the line
 * component.
 *
 * @return {React.ReactElement} The line component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFLine = ({
  className,
  orientation,
  length,
  width,
  color,
  style,
  margin,
}) => {
  const lineClassName = `pf line ${className}`;

  const border = {};
  if (orientation === 'horizontal') {
    border.borderRight = 'none';
  } else if (orientation === 'vertical') {
    border.borderBottom = 'none';
  }

  if (!!width) {
    border.borderWidth = width;
  }

  const configStyle = componentUtils.getStylesFromConfig({ margin });
  const lineStyle = {
    width: orientation === 'horizontal' ? length : 0,
    borderColor: color,
    ...configStyle,
    ...border,
    ...style
  };

  return (
    <div
      className={lineClassName}
      style={lineStyle}
    />
  );
};

PFLine.defaultProps = {
  className: '',
  length: '100%',
  orientation: 'horizontal',
  style: {},
};

PFLine.propTypes = {
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  length: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  style: PropTypes.object,
  margin: PropTypes.string,
};

export default PFLine;
