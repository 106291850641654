import { builderV2 } from 'PFApis';
import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import {
  PFScreenAutomationEditor,
  PFScreenFlowEditor,
  PFScreenIntegrationEditor,
  PFScreenPageEditor
} from 'PFScreens';
import store, { currentAppActions } from 'PFStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';

const PFLayoutAppEditor = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const currentApp = useSelector((state) => state.current_app);
  const { app_id } = useParams();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);

  useEffect(() => {
    asyncCall(async () => {
      const app = await builderV2.getApp(app_id);
      store.dispatch(currentAppActions.setCurrentApp(app));
    });
  }, [app_id]);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="editor-background" />
      {loading || !currentApp
        ? <PFLoader area color="white" />
        : <Routes>
          <Route path="/flows/:flow_id" element={<PFScreenFlowEditor />} />
          <Route path="/integrations/:api_id"
            element={<PFScreenIntegrationEditor />} />
          <Route path="/pages/:builder_path" element={<PFScreenPageEditor />} />
          <Route path="/automations/:automation_id"
            element={<PFScreenAutomationEditor />} />
        </Routes>
      }
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default PFLayoutAppEditor;
