import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFAppEditorHeader } from 'PFComponents/navigation';

const MultistepJSONEditorHeader = ({
  appId,
  pageId,
  stepId,
  save,
  saveLoading,
  saved
}) => {
  const navigate = useNavigate();

  const pages = useSelector((state) =>
    state.current_app.build.pages.docs);

  const pageOptions = pages.map((page) => {
    return { label: page.alias, value: page._id };
  });

  const changePage = (e) => {
    const id = e.target.value;
    const path = pages.find((page) => page._id === id)?.builder_path;
    navigate(`/json-editor/apps/${appId}/pages/${path}`);
  };

  const backToPage = () => {
    const path = pages.find((page) => page._id === pageId)?.builder_path;
    navigate(`/json-editor/apps/${appId}/pages/${path}`);
  };

  return (
    <PFAppEditorHeader backAction={backToPage}>
      <PFContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <PFContainer display="flex" alignItems="center" gap="m">
          <PFContainer width={300}>
            <PFSelect
              className="header"
              prefix="Page:"
              value={pageId}
              onChange={changePage}
              options={pageOptions} />
          </PFContainer>
          {!!stepId
            ? <PFContainer display="flex" gap="s">
              <PFText type="secondary">Step:</PFText>
              <PFText>{stepId}</PFText>
            </PFContainer>
            : null
          }
        </PFContainer>
        <PFContainer display="flex" gap="m">
          {!saveLoading && saved
            && <PFContainer
              display="flex" alignItems="center" gap="s">
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type="secondary" width={80}
            onClick={save} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
    </PFAppEditorHeader>
  );
};

MultistepJSONEditorHeader.propTypes = {
  appId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default MultistepJSONEditorHeader;
