import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import { PFScreenTableEditor } from 'PFScreens';
import store, { currentDBActions } from 'PFStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { builderTest } from 'src/apis/builderV2/builderV2';


const PFLayoutDBEditor = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const currentDB = useSelector((state) => state.current_db);
  const { db_id } = useParams();
  const currentUserId = useSelector((state) => state.user._id);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);

  useEffect(() => {
    asyncCall(async () => {
      const db = await builderTest(currentUserId).getDatabase(db_id);
      store.dispatch(currentDBActions.setCurrentDB(db));
    });
  }, [db_id]);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="editor-background" />
      {loading || !currentDB
        ? <PFLoader area color="white" />
        : <Routes>
          <Route path="/tables/:table_name" element={<PFScreenTableEditor />} />
        </Routes>
      }
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default PFLayoutDBEditor;
