import { twoapp } from 'PFApis';
import {
  PFButton, PFContainer,
  PFIcon, PFModal,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFAppEditorHeader } from 'PFComponents/navigation';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';
import './FlowEditorHeader.css';
import FlowEditorTester from './FlowEditorTester';


const FlowEditorHeader = ({ appId, flowId, save, saveLoading, saved }) => {
  const currentUserId = useSelector((state) => state.user._id);
  const currentAppId = useSelector((state) => state.current_app._id);
  const uid = new ShortUniqueId({ length: 6 });
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputParameters, setInputParameters] = useState([]);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [formObject, setFormObject] = useState({});
  const createFormObject = (data) => {
    const result = {};
    data.forEach((item) => {
      result[item.field_name] = '';
    });
    return result;
  };

  useEffect(() => {
    asyncCall(async () => {
      const response = await twoapp
        .inputParameters(flowId, currentAppId, currentUserId);
      const parsedResponse = response.map((element) => ({
        ...element,
        field_name: `param_${uid()}`
      }));
      setInputParameters(parsedResponse);
      setFormObject(createFormObject(parsedResponse));
    });
  }, [saved]);

  const appFlows = useSelector((state) =>
    state.current_app.build.process_flows.docs);

  const appFlowOptions = appFlows.map((flow) => {
    return { label: flow.alias, value: flow._id };
  });

  const changeFlow = (e) => {
    const id = e.target.value;
    navigate(`/editor/apps/${appId}/flows/${id}`);
  };

  const backToApps = () => {
    navigate(`/apps/${appId}/flows`);
  };

  return (
    <PFAppEditorHeader backAction={backToApps}>
      <PFModal active={modalOpen} onDismiss={() => setModalOpen(false)} >
        {
          modalOpen
            ? <FlowEditorTester
              closeTester={() => {
                setModalOpen(false);
              }}
              flowId={flowId} inputParameters={inputParameters}
              setInputParameters={setInputParameters} loading={loading}
              error={error} dismissError={dismissError}
              formObject={formObject} setFormObject={setFormObject}
            />
            : <></>
        }
      </PFModal>
      <PFContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <PFContainer display='flex' gap='s'>
          <PFContainer width={300}>
            <PFSelect
              className="header"
              prefix="Flow:"
              value={flowId}
              onChange={changeFlow}
              options={appFlowOptions} />
          </PFContainer>
          <PFButton type='secondary' height={'auto'} width='120px'
            onClick={() => {
              setModalOpen(true);
            }}>
            Test
          </PFButton>
        </PFContainer>
        <PFContainer display="flex" gap="m">
          {!saveLoading && saved
            && <PFContainer
              display="flex" alignItems="center" gap="s">
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type="secondary" width={80}
            onClick={() => {
              save();
              setFormObject({});
            }} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
    </PFAppEditorHeader>
  );
};

FlowEditorHeader.propTypes = {
  appId: PropTypes.string.isRequired,
  flowId: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};

export default FlowEditorHeader;
