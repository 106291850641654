import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';


const Parameters = ({ args, setArgs }) => {
  const addParameter = () => {
    setArgs({ ...args, ['']: '' });
  };

  const renderArgs = () => {
    const argsArray = Object.entries(args);

    return argsArray.map(([name, val], i) => {
      const setName = (name) => {
        argsArray[i] = [name, val];
        setArgs(Object.fromEntries(argsArray));
      };
      const setValue = (val) => {
        argsArray[i] = [name, val];
        setArgs(Object.fromEntries(argsArray));
      };
      const removeValue = () => {
        argsArray.splice(i, 1);
        setArgs(Object.fromEntries(argsArray));
      };

      return (
        <React.Fragment key={i}>
          <PFInput
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)} />
          <PFInput
            placeholder="Value"
            value={val}
            onChange={(e) => setValue(e.target.value)} />
          <PFButton type="support" onClick={removeValue}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="grid" gap="s" margin="top-s"
      gridTemplateColumns="1fr 1fr .1fr">
      {renderArgs()}
      <PFButton type="support" onClick={addParameter}
        style={{ justifySelf: 'start' }}>
        + add parameter
      </PFButton>
    </PFContainer>
  );
};

Parameters.propTypes = {
  args: PropTypes.object.isRequired,
  setArgs: PropTypes.func.isRequired,
};

export default Parameters;
