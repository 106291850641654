import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 6 });

const NODE_TYPE_ACTION_OPTIONS = [
  {
    name: 'API Call',
    type: 'API_CALL',
    description: 'Connect any SOAP or JSON API supa fast!',
    icon: 'bx bx-cube',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'API_CALL',
        alias: 'API Call node',
        next: next || null,
        api_declaration: '',
        params: {}
      };
    },
  },
  {
    name: 'Function call',
    type: 'FUNCTION_CALL',
    description: 'Call one of your custom function operations',
    icon: 'bx bx-code-alt',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'FUNCTION_CALL',
        alias: 'Function call node',
        next: next || null,
        function_id: '',
        operation_id: '',
        params: {}
      };
    },
  },
  {
    name: 'Save in session',
    type: 'SAVE_IN_SESSION',
    description: 'Save the form information to session',
    icon: 'bx bx-save',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'SAVE_IN_SESSION',
        alias: 'Save in session node',
        next: next || null
      };
    },
  },
  {
    name: 'Log in',
    type: 'AUTH',
    description: 'Login with Pefai authentication',
    icon: 'bx bx-log-in-circle',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'AUTH',
        alias: 'Log in node',
        next: next || null,
        auth_action: 'LOGIN',
        params: {}
      };
    },
  },
  {
    name: 'Log out',
    type: 'REMOVE_SESSION',
    description: 'End current session',
    icon: 'bx bx-log-out-circle',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'REMOVE_SESSION',
        alias: 'Log out node',
        next: next || null
      };
    },
  },
  {
    name: 'Custom Save',
    type: 'SAVE_IN_SESSION_CUSTOM',
    description: 'Select what you save in session',
    icon: 'bx bxs-memory-card',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'SAVE_IN_SESSION_CUSTOM',
        alias: 'Custom save node',
        next: next || null,
        params: {}
      };
    },
  },
  {
    name: 'Delete session',
    type: 'UTILS',
    description: 'Erase content from root variables in session',
    icon: 'bx bx-no-entry',
    create: (next, setTab, setIsHovered) => {
      setIsHovered(false);
      setTab('START');
      return {
        _id: uid(),
        async: false,
        type: 'UTILS',
        alias: 'Delete session node',
        next: next || null,
        params: {}
      };
    },
  }
];

export default NODE_TYPE_ACTION_OPTIONS;
