import React, { ReactNode } from 'react';
import * as Styled from './textarea.styled';

export type TextareaProps = {
    /**
     * allows the browser to predict the value
     */
    autoComplete?: 'on' | 'off';

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * specifies the width in half-width characters of the textarea element
     */
    cols?: number;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * to disable or enable the input element
     */
    disabled?: boolean;

    /**
     * an identifier for the input element
     */
    id: string;

    /**
     * specifies the maximum number of characters the textarea element can have
     */
    maxLength?: number;

    /**
     * specifies the minimum number of characters the textarea element can have
     */
    minLength?: number;

    /**
     * specifies the name of the textarea element
     */
    name: string;

    /**
     * a function to be triggered when the input element loses focus
     */
    onBlur?: () => void;

    /**
     * a function to be triggered when the input element value change
     */
    onChange?: (e) => void;

    /**
     * a function to be triggered when the input element gets focus
     */
    onFocus?: () => void;

    /**
     * a placeholder for the input element
     */
    placeholder?: string;

    /**
     * specifies that the textarea is read-only
     */
    readOnly?: boolean;

    /**
     * specifies that the textarea must be filled
     */
    required?: boolean;

    /**
     * specifies the numbre of lines in the textarea element
     */
    rows?: number;

    /**
     * to set the value of the input element
     */
    value?: string;

    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;
};

export function Textarea({
    id,
    name,
    value,
    cols,
    rows,
    maxLength,
    minLength,
    required,
    placeholder,
    autoComplete = 'off',
    readOnly,
    disabled,
    onChange,
    onBlur,
    onFocus,
    configStyles,
    className,
    children,
    ...props
}: TextareaProps) {
    return (
        <Styled.Textarea
            autoComplete={autoComplete}
            className={className}
            cols={cols}
            configStyles={configStyles}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            rows={rows}
            value={value}
            {...props}
        ></Styled.Textarea>
    );
}
