import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';

const PageJSONEditorSidebar = ({ appId, builderPath }) => {
  const navigate = useNavigate();

  const pages = useSelector((state) =>
    state.current_app.build.pages.docs);

  const [search, setSearch] = useState('');

  const changePage = (path) => {
    navigate(`/json-editor/apps/${appId}/pages/${path}`);
  };

  const renderPages = () => {
    let filteredPages;
    if (search.length > 0) {
      const searchRegex = new RegExp(search, 'i');
      filteredPages = pages.filter((page) => {
        return page.alias.match(searchRegex);
      });
    } else {
      filteredPages = pages;
    }

    return filteredPages.map((page) => {
      return (
        <PFButton type="none" key={page._id}
          onClick={() => changePage(page.builder_path)}
          style={{ textAlign: 'left' }}>
          <PFText type={page.builder_path === builderPath
            ? 'primary' : 'secondary'}>
            {page.alias}
          </PFText>
        </PFButton>
      );
    });
  };

  return (
    <PFContainer style={{ marginRight: 300 }}>
      <PFContainer type="toolbar" height="100%" width={300}
        style={{ position: 'fixed', overflowY: 'auto', paddingBottom: 100 }}>
        <PFContainer padding="m">
          <PFContainer>
            <PFText>Your pages</PFText>
            <PFInput name="pages-search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              margin="vertical-m"
              placeholder="Find a page"
              leftContent={(
                <PFIcon icon="bx bx-search"
                  margin="left-s"
                  color="var(--secondary)" />
              )} />
          </PFContainer>
          <PFContainer display="flex"
            flexDirection="column" gap="s"
            alignItems="start">
            {renderPages()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

PageJSONEditorSidebar.propTypes = {
  appId: PropTypes.string.isRequired,
  builderPath: PropTypes.string.isRequired,
};

export default PageJSONEditorSidebar;
