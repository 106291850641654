
import PropTypes from 'prop-types';
import { useContext } from 'react';
import Frame, { FrameContext } from 'react-frame-component';
import { StyleSheetManager } from 'styled-components';
import { useBreakpointContext } from '../../utils';
import { FrameGlobalStyles } from './FrameGlobalStyles';


// eslint-disable-next-line max-len
const initialContent = '<!DOCTYPE html><html><head></head><body class="pf default-theme"></body></html>';
// eslint-disable-next-line max-len
const frameStyles = 'html, body { padding: 0; margin: 0; height: 100%; width: 100%;  } .frame-content{ width: inherit; height: inherit; }';

const ResponsiveFrame = ({ children, id }) => {
  const { activeBreakpoint } = useBreakpointContext();

  return (
    <Frame
      id={id}
      mountTarget='body'
      head={[
        <link key='font-family' href='https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600' rel='stylesheet' />,
        <style key='frame-styles'>{frameStyles}</style>,
        <link key='icons-family' rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
      ]}
      initialContent={initialContent}
      style={{
        fontFamily: 'inherit',
        width: activeBreakpoint !== null
          ? activeBreakpoint['value'] : '100%',
        transform: 'scale(1.0)',
        height: '100%'
      }}
    >
      <StyleCopyHandler >{children}</StyleCopyHandler>
    </Frame>
  );
};

ResponsiveFrame.propTypes = {
  id: PropTypes.string.isRequired,
  activeBreakpoint: PropTypes.string,
  children: PropTypes.object
};

const StyleCopyHandler = ({ children }) => {
  const { document } = useContext(FrameContext);

  return (
    <StyleSheetManager target={document.head}>
      <>
        <FrameGlobalStyles />
        {children}
      </>
    </StyleSheetManager>
  );
};

StyleCopyHandler.propTypes = {
  children: PropTypes.object
};

export default ResponsiveFrame;
