import {
  PFContainer, PFInput, PFLoader, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';

const FlowEditorTester = ({ formObject, setFormObject,
  inputParameters, loading }) => {
  return (
    <PFContainer width='30%'>
      <PFText color='var(--black)'>
        Input Parameters
      </PFText>
      {
        loading ? <PFContainer>
          <PFLoader color='var(--purple-100)' />
        </PFContainer>
          : <PFContainer className='custom-scroll'
            margin='top-s'
            style={{
              maxHeight: '600px',
              overflow: 'auto'
            }}
            display='flex' flexDirection='column'
            gap='s'>
            {
              inputParameters.map(({
                var_name, simple_name, node_id, field_name
              }, index) => {
                return (
                  <PFContainer key={index} display='flex' gap='s'
                    padding='right-s'>
                    <PFContainer background='var(--purple-25)'
                      padding='s' radius='s' width='30%'
                      style={{
                        overflow: 'auto'
                      }} className='custom-scroll'>
                      <PFText color='var(--purple-100)'>
                        {simple_name}
                      </PFText>
                    </PFContainer>
                    <PFInput width='70%' value={formObject[
                      `${node_id}${var_name}`]?.replace_value}
                      onChange={(e) => {
                        setFormObject({
                          ...formObject,
                          [field_name]: e.target.value
                        });
                      }}
                      style={{
                        backgroundColor: 'var(--purple-25)',
                        boxSizing: 'content-box'
                      }} />
                  </PFContainer>
                );
              })
            }
          </PFContainer>
      }
    </PFContainer>
  );
};

FlowEditorTester.propTypes = {
  formObject: PropTypes.object,
  setFormObject: PropTypes.func,
  inputParameters: PropTypes.array,
  loading: PropTypes.bool
};

export default FlowEditorTester;
