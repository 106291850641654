import PropTypes from 'prop-types';

import { PFButton, PFContainer, PFIcon } from 'PFComponents/common';
import {
  ComponentLibrary,
  ComponentSelection,
  ComponentTree
} from './components';


const Sidebar = ({ canvas, blob, setBlob, openDrawer, setOpenDrawer,
  setTogglePageSettings }) => {
  const handleComponentsClick = () => {
    if (openDrawer) {
      setTogglePageSettings(false);
    }
    setOpenDrawer(openDrawer !== 'components' ? 'components' : null);
  };

  const handleTreeClick = () => {
    if (openDrawer) {
      setTogglePageSettings(false);
    }
    setOpenDrawer(openDrawer !== 'tree' ? 'tree' : null);
  };

  const handleLibraryClick = () => {
    if (openDrawer) {
      setTogglePageSettings(false);
    }
    setOpenDrawer(openDrawer !== 'library' ? 'library' : null);
  };

  const tabIsOpen = (tab) => {
    return openDrawer === tab && !canvas.dragging;
  };

  return (
    <PFContainer style={{ marginRight: 45 }}>
      <PFContainer height="100%" width={45}
        style={{ position: 'fixed', top: 60, zIndex: 10 }}>
        <PFContainer type="toolbar" display="flex" flexDirection="column"
          alignItems="center" padding="vertical-m" gap="xs"
          width={45} height="100%" style={{ position: 'relative', zIndex: 10 }}>
          <PFButton type="support" onClick={handleComponentsClick}>
            <PFIcon icon="bx bx-plus-circle" style={{ fontSize: '24px' }} />
          </PFButton>
          <PFButton type="support" onClick={handleTreeClick}>
            <PFIcon icon="bx bx-layer" style={{ fontSize: '24px' }} />
          </PFButton>
          <PFButton type="support" onClick={handleLibraryClick}>
            <PFIcon icon="bx bx-library" style={{ fontSize: '24px' }} />
          </PFButton>
        </PFContainer>
        <ComponentSelection canvas={canvas}
          open={tabIsOpen('components')} setOpenDrawer={setOpenDrawer} />
        <ComponentTree canvas={canvas} open={tabIsOpen('tree')}
          blob={blob} setBlob={setBlob} />
        <ComponentLibrary canvas={canvas} open={tabIsOpen('library')}
          setOpenDrawer={setOpenDrawer} />
      </PFContainer>
    </PFContainer>
  );
};

Sidebar.propTypes = {
  canvas: PropTypes.object.isRequired,
  blob: PropTypes.array,
  setBlob: PropTypes.func.isRequired,
  openDrawer: PropTypes.string,
  setOpenDrawer: PropTypes.func,
  setTogglePageSettings: PropTypes.func
};

export default Sidebar;
