import {
  PFButton,
  PFContainer,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { EditorControl } from '../../components';
import '../../DesignEditor.css';

function composeBoxSizing({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);

  const configStyles = {
    [styleKey]: styleValue
  };

  return {
    configStyles
  };
}

const BoxSizing = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles,
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <PFButton onClick={handleActiveSection}
        type='support' padding='0' height='auto'>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'} />
          Box Sizing
        </PFText>
      </PFButton>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up'
        : 'pf-design-editor__section--down'}>
        {
          ruleSet.map((rule, index) => {
            if (index !== 0) return null;
            const ruleKey = Object.keys(rule)[0];
            const ruleValueControls = rule[ruleKey];

            return (
              <PFContainer
                key={
                  `design-editor-${ruleKey}-${index}`
                }
                className="pf-designer-editor__rule">
                <PFContainer className="pf-design-editor__control">
                  {
                    ruleValueControls.map((rule, index) => {
                      return (
                        <EditorControl
                          key={`design-editor-rule-${rule.key}-${index}}`}
                          componentBreakpointStyles={componentBreakpointStyles}
                          typeConfig={rule.typeConfig}
                          onChange={(event) => {
                            const composedValue = composeBoxSizing({
                              alias: rule.alias,
                              composedValue: { [rule.key]: event.target.value },
                            });
                            composeStyles({
                              composedAttributes: composedValue,
                            });
                          }}
                          styleConfig={
                            {
                              key: rule.key,
                              value:
                                rule.value,
                              alias: rule.alias
                            }}
                          type={typeof rule.type === 'undefined'
                            ? 'editor-control-button' : rule.type}
                        >
                          {rule.label}
                        </EditorControl>
                      );
                    })
                  }
                </PFContainer>
              </PFContainer>
            );
          })
        }
      </PFContainer>
    </PFContainer >
  );
};

export default BoxSizing;

BoxSizing.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object,
};
