import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ComponentCard from './ComponentCard';


const DrawerSection = ({ componentsDrawer, categoryName, canvas,
  setOpenDrawer }) => {
  const [isTabOpen, setIsTabOpen] = useState(true);

  return (
    <PFContainer margin='bottom-m'>
      <PFContainer
        margin='bottom-s'>
        <PFButton type='support' padding='0' height='auto'
          width='100%'
          style={{
            display: 'flex', gap: 'var(--size-s)',
            justifyContent: 'space-between'
          }} onClick={() => {
            setIsTabOpen((prev) => !prev);
          }}>
          <PFText size='s' >{categoryName}</PFText>
          <PFIcon icon={isTabOpen ? 'bx bxs-chevron-up'
            : 'bx bxs-chevron-down'} color='var(--purple-100)' />
        </PFButton>
      </PFContainer>

      {isTabOpen && <PFContainer display="grid"
        gridTemplateColumns="1fr 1fr" gap="s">
        {
          componentsDrawer[categoryName].map((comp) => {
            return (<ComponentCard key={comp.name} canvas={canvas}
              comp={comp} setOpenDrawer={setOpenDrawer} />);
          })

        }
      </PFContainer>}
    </PFContainer>
  );
};

DrawerSection.propTypes = {
  canvas: PropTypes.object.isRequired,
  componentsDrawer: PropTypes.object.isRequired,
  categoryName: PropTypes.string.isRequired,
  setOpenDrawer: PropTypes.func,
};

export default DrawerSection;
