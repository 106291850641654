import PropTypes from 'prop-types';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFSelect
} from 'PFComponents/common';
import { FUNCTIONS } from 'PFConstants';

const ScenarioOperand = ({ operand, setOperand }) => {
  const [op] = operand;

  const hasOperand2 = !!operand[2];

  const setOperator = (op) => {
    operand.splice(0, 1, op);
    setOperand(operand);
  };

  const addSecondOperand = () => {
    if (!hasOperand2) {
      setOperand([...operand, { value1: '', type: 'EQUALS', value2: '' }]);
    }
  };

  const removeSecondOperand = () => {
    if (hasOperand2) {
      operand.pop();
      setOperand(operand);
    }
  };

  const renderExpresion = (i) => {
    const exp = operand[i];
    const { value1, type, value2 } = exp;

    const setExpression = (exp) => {
      operand.splice(i, 1, exp);
      setOperand(operand);
    };

    const setValue1 = (value1) => setExpression({ ...exp, value1 });
    const setType = (type) => setExpression({ ...exp, type });
    const setValue2 = (value2) => setExpression({ ...exp, value2 });

    return (
      <>
        <PFInput placeholder="Value 1"
          value={value1} onChange={(e) => setValue1(e.target.value)} />
        <PFSelect options={FUNCTIONS.CONDITIONAL_OPERATORS}
          value={type} onChange={(e) => setType(e.target.value)} />
        <PFInput placeholder="Value 2"
          value={value2} onChange={(e) => setValue2(e.target.value)} />
        {hasOperand2
          ? <PFButton type="support" onClick={removeSecondOperand}
            disabled={i <= 1}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
          : null}
      </>
    );
  };

  return (
    <PFContainer margin="top-m" display="grid" gap="s"
      gridTemplateColumns={hasOperand2
        ? '.5fr 2fr .5fr 2fr .2fr'
        : '2fr .5fr 2fr'}>
      {hasOperand2 ? <PFContainer /> : null}
      {renderExpresion(1)}
      {!!operand[2]
        ? <>
          <PFSelect options={FUNCTIONS.LOGICAL_OPERATORS}
            value={op} onChange={(e) => setOperator(e.target.value)} />
          {renderExpresion(2)}
        </>
        : <>
          <PFButton type="support" onClick={addSecondOperand}
            style={{ justifySelf: 'start' }}>
            + add condition
          </PFButton>
        </>}
    </PFContainer>
  );
};

ScenarioOperand.propTypes = {
  operand: PropTypes.object.isRequired,
  setOperand: PropTypes.func.isRequired,
};

export default ScenarioOperand;
