import { useAsyncCall } from 'PFHooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { PFScreenOperationEditor } from 'PFScreens';
import store, { currentFunctionActions } from 'PFStore';

const PFLayoutFunctionEditor = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const currentFunction = useSelector((state) => state.current_function);
  const { function_id } = useParams();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);

  useEffect(() => {
    asyncCall(async () => {
      const func = await builderV2.getFunction(function_id);
      store.dispatch(currentFunctionActions.setCurrentFunction(func));
    });
  }, [function_id]);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="editor-background" />
      {loading || !currentFunction
        ? <PFLoader area color="white" />
        : <Routes>
          <Route path="/operations/:operation_id"
            element={<PFScreenOperationEditor />} />
        </Routes>
      }
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default PFLayoutFunctionEditor;
