import axios from 'axios';
import { twoappOrigin } from 'PFConfig/env';
import { storageUtils } from 'PFUtils';

const crhones = axios.create({
  baseURL: twoappOrigin + '/twoapp/v1'
});

const jwt = storageUtils.retrieveFromLocalStorage('access_token');

crhones.interceptors.request.use(
  async (config) => {
    const jwt = storageUtils.retrieveFromLocalStorage('access_token');
    if (jwt) config.headers.set('Authorization', 'Bearer ' + jwt);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

crhones.interceptors.response.use(
  async (res) => {
    return res?.data;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err.response?.data?.data ?? err.message);
  }
);

export default {
  updateJob: async (appId, jobId, jobData) =>
    await crhones.put(`/chrono/${appId}/job/${jobId}`, jobData, {
      headers: {
        'Authorization': 'Bearer ' + jwt
      }
    })
};
