import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const OperationEditorSidebar = ({ opId }) => {
  const navigate = useNavigate();

  const currentFunction = useSelector((state) => state.current_function);
  const { operations, _id: funcId } = currentFunction;

  const [search, setSearch] = useState('');

  const renderOperations = () => {
    let filteredOperations;
    if (search.length > 0) {
      const searchRegex = new RegExp(search, 'i');
      filteredOperations = operations.filter((op) => {
        return op.name.match(searchRegex);
      });
    } else {
      filteredOperations = operations;
    }

    return filteredOperations.map((op) => {
      const changeOperation = () => {
        navigate(`/editor/functions/${funcId}/operations/${op._id}`);
      };

      return (
        <PFButton type="none" key={op._id} onClick={changeOperation}
          style={{ textAlign: 'left' }}>
          <PFText type={op._id === opId ? 'primary' : 'secondary'}>
            {op.name}
          </PFText>
        </PFButton>
      );
    });
  };

  return (
    <PFContainer style={{ marginRight: 300 }}>
      <PFContainer type="toolbar" height="100%" width={300}
        className='pf-scroll'
        style={{ position: 'fixed', overflowY: 'auto', paddingBottom: 100 }}>
        <PFContainer padding="m">
          <PFContainer>
            <PFText>{currentFunction.info.alias}</PFText>
            <PFInput name="integrations-search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              margin="vertical-m"
              placeholder="Find operation"
              leftContent={(
                <PFIcon icon="bx bx-search"
                  margin="left-s"
                  color="var(--secondary)" />
              )} />
          </PFContainer>
          <PFContainer display="flex"
            flexDirection="column" gap="s"
            alignItems="start">
            {renderOperations()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

OperationEditorSidebar.propTypes = {
  opId: PropTypes.string.isRequired,
};

export default OperationEditorSidebar;
