import PropTypes from 'prop-types';

import {
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLine,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useForm } from 'PFHooks';

const PageCreationModal = ({
  title = 'Create a new page',
  callToAction = 'Create page',
  onSubmit,
  loading,
  active,
  onDismiss,
}) => {
  const [form, fieldHook] = useForm({
    alias: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a name for your page',
    },
    title: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a browser title for your page',
    },
    path: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a path for your page',
    }
  });

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFForm form={form} submit={onSubmit}>
            <PFContainer margin="bottom-m"
              display="flex" alignItems="center" justifyContent="space-between">
              <PFText type="support">{title}</PFText>
              <PFButton type="support" onClick={onDismiss}
                padding="none" height={28}
                style={{
                  background: 'var(--purple-50)',
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}>
                <PFIcon icon="bx bx-x" size="l" />
              </PFButton>
            </PFContainer>
            <PFContainer display="flex" flexDirection="column" gap="s">
              <PFInput
                placeholder="Name displayed inside the project"
                name="alias"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Name
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
              <PFInput
                placeholder="Title displayed on browsers"
                name="title"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Title
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
              <PFInput
                placeholder="Path to get to this page"
                name="path"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Path
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
            </PFContainer>
            <PFContainer margin="top-l" display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={loading}>
                {callToAction}
              </PFButton>
            </PFContainer>
          </PFForm>
        </PFContainer >
      </PFModal >
    </>
  );
};

PageCreationModal.propTypes = {
  title: PropTypes.string,
  callToAction: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default PageCreationModal;
