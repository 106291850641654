import { useSelector } from 'react-redux';

import {
  PFButton,
  PFContainer,
  PFFileInput,
  PFInput,
  PFLine,
  PFLink,
  PFSelect,
  PFText
} from 'PFComponents/common';

const PFScreenMoodboard = () => {
  const { activeTheme } = useSelector((state) => state.theme);

  const renderContainerMoodboard = () => {
    return (
      <PFContainer display="flex" flexDirection="column" gap="l">
        <PFContainer>
          <PFText tag="h3">Typography</PFText>
          <PFLine margin="vertical-s" />
          <PFContainer
            display="flex" gap="s" margin="top-s">
            <PFText>aA</PFText>
            <PFText>|</PFText>
            <PFText>Poppins</PFText>
          </PFContainer>
          <PFContainer
            display="flex" gap="s" margin="top-s">
            <PFText weight="200">thin</PFText>
            <PFText>normal</PFText>
            <PFText weight="600">bold</PFText>
          </PFContainer>
          <PFContainer
            display="flex" gap="s" margin="top-s">
            <PFText type="primary">primary</PFText>
            <PFText type="secondary">secondary</PFText>
            <PFText type="support">support</PFText>
          </PFContainer>
        </PFContainer>

        <PFContainer>
          <PFText tag="h3">Links</PFText>
          <PFLine margin="top-s bottom-m" />
          <PFContainer>
            <PFLink to="/moodboard">Link primary</PFLink>
            <PFLink to="https://google.com" type="secondary" block margin="top-s">
              Link secondary
            </PFLink>
          </PFContainer>
        </PFContainer>

        <PFContainer>
          <PFText tag="h3">Buttons</PFText>
          <PFLine margin="top-s bottom-m" />
          <PFContainer display="flex" flexDirection="column" gap="s">
            <PFText size="s">Enabled</PFText>
            <PFButton>Primary</PFButton>
            <PFButton type="secondary">Secondary</PFButton>
            <PFButton type="support">Support</PFButton>

            <PFText size="s" margin="top-m">Loading</PFText>
            <PFButton loading>Primary</PFButton>
            <PFButton type="secondary" loading>Secondary</PFButton>
            <PFButton type="support" loading>Support</PFButton>

            <PFText size="s" margin="top-m">Disabled</PFText>
            <PFButton disabled>Primary</PFButton>
            <PFButton type="secondary" disabled >Secondary</PFButton>
            <PFButton type="support" disabled >Support</PFButton>
          </PFContainer>
        </PFContainer>

        <PFContainer>
          <PFText tag="h3">Tooltips</PFText>
          <PFLine margin="top-s bottom-m" />
          <PFButton width="100%" tooltip="This is the button tooltip">
            Button with tooltip
          </PFButton>
          <PFContainer
            tooltip="This is the container tooltip"
            radius="s" padding="s" margin="top-m"
            border="1px solid var(--secondary)">
            <PFText>Container with tooltip</PFText>
          </PFContainer>
        </PFContainer>

        <PFContainer>
          <PFText tag="h3">Options</PFText>
          <PFLine margin="top-s bottom-m" />
          <PFContainer display="flex" flexDirection="column" gap="m">
            <PFSelect label="Select label"
              options={[
                { label: 'Value 1', value: 1 },
                { label: 'Value 2', value: 2 },
                { label: 'Value 3', value: 3 },
              ]} />
            <PFSelect label="Select label" error="Select with error"
              options={[
                { label: 'Value 1', value: 1 },
                { label: 'Value 2', value: 2 },
                { label: 'Value 3', value: 3 },
              ]} />
          </PFContainer>
        </PFContainer>

        <PFContainer>
          <PFText tag="h3">Inputs</PFText>
          <PFLine margin="top-s bottom-m" />
          <PFContainer display="flex" flexDirection="column" gap="m">
            <PFInput
              label="Text input label"
              placeholder="Text input placeholder"
            />
            <PFInput
              label="Left content input"
              placeholder="Placeholder"
              leftContent={(
                <PFContainer display="flex">
                  <PFText padding="horizontal-s" type="secondary">Left</PFText>
                  <PFLine orientation="vertical" width={2} />
                </PFContainer>
              )}
            />
            <PFInput
              label="Right content input"
              placeholder="Placeholder"
              rightContent={(
                <PFContainer display="flex">
                  <PFLine orientation="vertical" width={2} />
                  <PFText padding="horizontal-s" type="secondary">Right</PFText>
                </PFContainer>
              )}
            />
            <PFInput
              className="alt"
              label="''alt'' text input label"
              placeholder="Text input placeholder"
            />
            <PFInput
              type="password"
              label="Password input label"
              placeholder="Password input placeholder"
            />
            <PFInput
              type="number"
              label="Number input label"
              placeholder="Number input placeholder"
            />
            <PFFileInput
              label="File input label"
              placeholder="File input placeholder"
            />
            <PFInput
              label="Multiline input label"
              placeholder="Multiline placeholder"
              multiline
            />
            <PFInput
              label="Input label"
              placeholder="Input placeholder"
              error="Input with error"
            />
          </PFContainer>
        </PFContainer>
      </PFContainer>
    );
  };

  const renderMoodBoard = () => {
    const types = ['main', 'form', 'toolbar', 'editor'];

    return types.map((type, i) => {
      return (
        <PFContainer key={i} style={{ flex: 1 }}>
          <PFText tag="h2" size="m" weight="normal" margin="bottom-s">
            {`<${type}>`}
          </PFText>
          <PFContainer type={type} radius="s" padding="m">
            <PFContainer>
              {renderContainerMoodboard()}
            </PFContainer>
          </PFContainer>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer className={activeTheme}
      background="var(--container-editor-background)">
      <PFContainer padding="l">
        <PFText tag="h1" margin="bottom-m">Moodboard</PFText>
        <PFContainer display="flex" gap="m">
          {renderMoodBoard()}
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

export default PFScreenMoodboard;
