import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import StepCreationModal from './StepCreationModal';

const MultistepJSONEditorSidebar = ({
  multistep,
  setMultistep,
  currentStep,
  setCurrentStep,
}) => {
  const steps = multistep.steps_order;

  const [search, setSearch] = useState('');
  const [stepCreationOpen, setStepCreationOpen] = useState(false);

  const renderSteps = () => {
    let filteredSteps;
    if (search.length > 0) {
      const searchRegex = new RegExp(search, 'i');
      filteredSteps = steps.filter((step) => {
        return step.match(searchRegex);
      });
    } else {
      filteredSteps = steps;
    }

    return filteredSteps.map((step) => {
      return (
        <PFButton type="none" key={step}
          onClick={() => setCurrentStep(step)}
          style={{ textAlign: 'left' }}>
          <PFText type={step === currentStep ? 'primary' : 'secondary'}>
            {step}
          </PFText>
        </PFButton>
      );
    });
  };

  return (
    <PFContainer style={{ marginRight: 300 }}>
      <PFContainer type="toolbar" height="100%" width={300}
        style={{ position: 'fixed', overflowY: 'auto', paddingBottom: 100 }}>
        <PFContainer padding="m">
          <PFContainer display="flex" flexDirection="column" gap="m">
            <PFContainer>
              <PFText>Page multistep</PFText>
              <PFText size="s" type="secondary">{multistep._id}</PFText>
            </PFContainer>
            <PFButton onClick={() => setStepCreationOpen(true)}>
              Create step
            </PFButton>
            {steps?.length > 0
              ? <>
                <PFText margin="top-m">Steps</PFText>
                <PFInput name="pages-search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Find a step"
                  leftContent={(
                    <PFIcon icon="bx bx-search"
                      margin="left-s"
                      color="var(--secondary)" />
                  )} />
              </>
              : null
            }
          </PFContainer>
          <PFContainer display="flex" gap="s" margin="vertical-m"
            flexDirection="column" alignItems="start">
            {renderSteps()}
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <StepCreationModal
        multistep={multistep}
        setMultistep={setMultistep}
        setCurrentStep={setCurrentStep}
        active={stepCreationOpen}
        onDismiss={() => setStepCreationOpen(false)} />
    </PFContainer>
  );
};

MultistepJSONEditorSidebar.propTypes = {
  multistep: PropTypes.object.isRequired,
  setMultistep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default MultistepJSONEditorSidebar;
