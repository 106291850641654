import styled from 'styled-components';
import { TableProps } from './table';

export const TableContainer = styled.div`
  height: 100%;
  overflow: auto;
  contain: inline-size;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacings.xsm};
`;

export const Table = styled.table<TableProps>`
  width: 100%;
  overflow: hidden;
  border: ${(props) => props.theme.borders.highlight} solid ${(props) => props.theme.colors.darkGray4};
  border-collapse: separate;
  border-spacing: 0;
  border-radius: ${(props) => props.theme.radius.md};

  ${(props) => props.configStyles}
`;

export const TableHead = styled.thead`
  background-color: ${(props) => props.theme.colors.tertiary1};
`;

export const TableHeadRow = styled.tr``;

export const TableHeadColumn = styled.th<{ columnDataType: string | undefined }>`
  padding: ${(props) => props.theme.spacings.xsm} ${(props) => props.theme.spacings.default};
  text-align: ${(props) => (props.columnDataType === 'number' ? 'right' : 'left')};
  white-space: nowrap;
`;

export const TableBody = styled.tbody``;

export const TableBodyRow = styled.tr``;

export const TableBodyCell = styled.td<{ columnDataType: string | undefined; heightOffset: number | undefined }>`
  border-top: ${(props) => props.theme.borders.highlight} solid ${(props) => props.theme.colors.darkGray4};
  height: ${(props) => (props.heightOffset ? `${props.heightOffset}px` : 'auto')};
  text-align: ${(props) => (props.columnDataType === 'number' ? 'right' : 'left')};
  white-space: nowrap;
  padding: ${(props) => props.theme.spacings.xsm} ${(props) => props.theme.spacings.default};
`;
