import {
  PFButton, PFContainer,
  PFIcon, PFLink, PFText
} from 'PFComponents/common';
import { useSelector } from 'react-redux';

const UserProfileSidebar = () => {
  const user = useSelector((state) => state.user);
  return (
    <PFContainer type="main"
      display="flex" flexDirection="column" gap="s">
      <PFContainer margin='bottom-m'>
        <PFLink to="/">
          <PFContainer display="flex" alignItems="center">
            <PFIcon icon="bx bx-chevron-left" size="l" />
            <PFText type="secondary">Exit to dashboard</PFText>
          </PFContainer>
        </PFLink>
      </PFContainer>
      <PFContainer display='flex' gap='m' margin='bottom-s'
        alignItems='center'>
        <PFButton type="primary" width={40} height={40}
          onClick={() => { }}>
          {user.info.first_name.charAt(0)}
        </PFButton>
        <PFContainer>
          <PFText>{user.info.first_name} {user.info.last_name}</PFText>
          <PFText size='s'>User settings</PFText>
        </PFContainer>
      </PFContainer>
      <PFContainer margin="bottom-xl"
        display="flex" flexDirection="column" gap="m">
        <PFLink to='/profile/update-password'>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-lock" size="l"
              color='var(--white)' />
            <PFText color='var(--white)'>Update password</PFText>
          </PFContainer>
        </PFLink>
      </PFContainer>
    </PFContainer >
  );
};

export default UserProfileSidebar;
