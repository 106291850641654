import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FunctionSelectionModal } from './components';


const PFScreenAppFunctions = () => {
  const currentApp = useSelector((state) => state.current_app);
  const functions = currentApp.build.associated_functions?.docs ?? [];

  const [selectionOpen, setSelectionOpen] = useState(false);

  const [removeAsyncCall, , removeError, dismissRemoveError]
    = useAsyncCall(false);

  const [RemovalDialog, confirmRemoval] = useConfirmation(
    'Are you sure you want to disconnect this function?',
  );

  const removeFunction = (functionId) => {
    removeAsyncCall(async () => {
      if (await confirmRemoval()) {
        store.dispatch(currentAppActions.removeFunction(functionId));
        await builderV2.removeFunctionFromApp(currentApp._id, functionId);
      }
    });
  };

  const renderFunctions = () => {
    return functions.map((func) => {
      return (
        <PFItemCard key={func._id}
          path={`/functions/${func._id}/operations`}
          options={{
            'Disconnect': () => removeFunction(func._id),
            'Copy ID': () => generalUtils.copyToClipboard(func._id)
          }}>
          <PFText padding="top-m">{func.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {func._id}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Functions</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={() => setSelectionOpen(true)}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Function</PFText>
          </PFActionCard>
          {renderFunctions()}
        </PFContainer>
      </PFContainer>
      <FunctionSelectionModal active={selectionOpen}
        onDismiss={() => setSelectionOpen(false)} />
      <RemovalDialog />
      <PFAlert
        message={removeError}
        open={!!removeError}
        onClose={dismissRemoveError} />
    </PFContainer>
  );
};

export default PFScreenAppFunctions;
