/* eslint-disable max-len */

const PFIconComponentImage = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="7.4834" y="13.373" width="40" height="24" rx="2" fill="#493D6B" />
      <path d="M33.2334 18.373H22.6084C22.045 18.3737 21.5049 18.5977 21.1065 18.9961C20.7081 19.3945 20.484 19.9347 20.4834 20.498V28.998C20.484 29.5614 20.7081 30.1016 21.1065 30.5C21.5049 30.8984 22.045 31.1224 22.6084 31.123H33.2334C33.7968 31.1224 34.3369 30.8984 34.7353 30.5C35.1337 30.1016 35.3578 29.5614 35.3584 28.998V20.498C35.3578 19.9347 35.1337 19.3945 34.7353 18.9961C34.3369 18.5977 33.7968 18.3737 33.2334 18.373ZM30.5771 20.498C30.8924 20.498 31.2005 20.5915 31.4626 20.7666C31.7247 20.9418 31.929 21.1907 32.0496 21.4819C32.1702 21.7731 32.2018 22.0936 32.1403 22.4027C32.0788 22.7119 31.927 22.9959 31.7041 23.2187C31.4812 23.4416 31.1972 23.5934 30.8881 23.6549C30.5789 23.7164 30.2585 23.6849 29.9672 23.5642C29.676 23.4436 29.4271 23.2393 29.252 22.9772C29.0769 22.7151 28.9834 22.407 28.9834 22.0918C28.9838 21.6692 29.1519 21.2641 29.4507 20.9653C29.7495 20.6665 30.1546 20.4985 30.5771 20.498ZM22.6084 30.0605C22.3266 30.0605 22.0564 29.9486 21.8571 29.7493C21.6578 29.5501 21.5459 29.2798 21.5459 28.998V26.7525L24.6949 23.9535C24.9987 23.684 25.3938 23.5406 25.7998 23.5523C26.2057 23.564 26.5919 23.73 26.8796 24.0166L29.0362 26.1685L25.1441 30.0605H22.6084ZM34.2959 28.998C34.2959 29.2798 34.184 29.5501 33.9847 29.7493C33.7854 29.9486 33.5152 30.0605 33.2334 30.0605H26.6469L30.6784 26.029C30.9639 25.7863 31.3261 25.6526 31.7008 25.6516C32.0755 25.6506 32.4384 25.7825 32.7251 26.0237L34.2959 27.3326V28.998Z" fill="#A792DF" />
    </svg>
  );
};

export default PFIconComponentImage;
