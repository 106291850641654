import {
  PFButton,
  PFCodeEditor,
  PFContainer,
  PFInput, PFSelect,
  PFText
} from 'PFComponents/common';
import { API } from 'PFConstants';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formConfigFromApi } from '../utils';
import AdvancedSettings from './AdvancedSettings/AdvancedSettings';
import ParamTypeFields from './ParamTypeFields';

const verbOptions = API.VERBS.map((verb) => ({ label: verb, value: verb }));

const APIIntegrationForm = ({
  form,
  fieldHook,
  formattingKeyOptions
}) => {
  const [tab, setTab] = useState('json');

  useEffect(() => {
    if (form.values.application_type === 'application/soap+xml') {
      handleTabChange('soap');
    } else {
      handleTabChange('json');
    }
  }, [form.values?.application_type]);

  const handleTabChange = (newTab) => {
    if (tab !== newTab) {
      const newFromConfig = formConfigFromApi(form.values, true, newTab);
      newFromConfig.application_type = `application/${newTab === 'json'
        ? 'json' : 'soap+xml'}`;
      newFromConfig.response_type = 'application/json';
      form.setConfig(newFromConfig);
      setTab(newTab);
    }
  };
  const addParamField = (property, type = 'default') => {
    const newFromConfig = formConfigFromApi(form.values, true);
    switch (type) {
      case 'format':
        newFromConfig[property].push({ key: '', format: '' });
        break;
      case 'file-format':
        newFromConfig[property].push({ field: '', format: '' });
        break;

      default:
        newFromConfig[property].push({ key: '', value: '' });
        break;
    }
    form.setConfig(newFromConfig);
  };


  const removeParamField = (property, index) => {
    const newFromConfig = formConfigFromApi(form.values, true);
    newFromConfig[property].splice(index, 1);
    form.setConfig(newFromConfig);
  };

  return (
    <PFContainer>
      <PFContainer padding="vertical-m horizontal-l"
        display="flex" justifyContent="space-between">
        <PFInput name="info.alias" fieldHook={fieldHook}
          className="alt" width="60%" placeholder="API name" />
      </PFContainer>
      <PFContainer
        display='flex'
        alignItems='center'
        gap='l'
        padding='top-s right-l left-l'
      >
        <PFButton
          type='support'
          onClick={() => handleTabChange('json')}
          style={{
            borderBottom: tab === 'json'
              ? '4px solid' : '4px solid transparent'
          }}
        >
          JSON
        </PFButton>
        <PFButton
          type='support'
          onClick={() => handleTabChange('soap')}
          style={{
            borderBottom: tab === 'soap'
              ? '4px solid' : '4px solid transparent'
          }}
        >
          SOAP
        </PFButton>
      </PFContainer>
      <PFContainer padding="l"
        display="flex" flexDirection="column" gap="l">
        <PFContainer display="flex" gap="m">
          <PFContainer width={150}>
            <PFSelect name="method" fieldHook={fieldHook}
              options={verbOptions} />
          </PFContainer>
          <PFInput name="url" placeholder="URL"
            fieldHook={fieldHook} width="100%" />
        </PFContainer>
        <PFContainer>
          <PFText>Headers</PFText>
          <PFContainer margin="top-s"
            display="flex" flexDirection="column" gap="s">
            <ParamTypeFields
              form={form}
              property="headers"
              fieldHook={fieldHook}
              remove={(i) => removeParamField('headers', i)} />
          </PFContainer>
          <PFButton type="support" onClick={() => addParamField('headers')}>
            + add new header
          </PFButton>
        </PFContainer>
        <PFContainer>
          <PFText>Query Params</PFText>
          <PFContainer margin="top-s"
            display="flex" flexDirection="column" gap="s">
            <ParamTypeFields
              form={form}
              property="query"
              fieldHook={fieldHook}
              remove={(i) => removeParamField('query', i)} />
          </PFContainer>
          <PFButton type="support" onClick={() => addParamField('query')}>
            + add new query param
          </PFButton>
        </PFContainer>
        <PFContainer>
          <PFText margin='bottom-s'>Request body</PFText>
          <PFCodeEditor name="body" fieldHook={fieldHook} />
        </PFContainer>
        <AdvancedSettings form={form}
          property="params"
          fieldHook={fieldHook}
          removeParamField={removeParamField}
          addParamField={addParamField}
          formattingKeyOptions={formattingKeyOptions} />
      </PFContainer>
    </PFContainer>
  );
};

APIIntegrationForm.propTypes = {
  form: PropTypes.object.isRequired,
  fieldHook: PropTypes.func.isRequired,
  formattingKeyOptions: PropTypes.array
};

export default APIIntegrationForm;
