import styled from 'styled-components';

export const Accordionheader = styled.button.attrs({ type: 'button' })<{
    configStyles: string | undefined;
}>`
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background-color: #ffffff;
    border: none;

    ${(props) => props.configStyles}
`;

export const AccordionIcon = styled.div<{
    configStyles: string | undefined;
    isAccordionIcon: boolean | undefined;
    isOpen: boolean | undefined;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    ${(props) => props.configStyles}
`;
