import styled from 'styled-components';

export const Autocomplete = styled.div<{ configStyles?: {} | undefined }>`
    width: 100%;
    position: relative;
    box-sizing: border-box;
    ${(props) => props.configStyles}
`;

export const AutocompleteSearchbar = styled.input<{ configStyles?: {} | undefined }>`
    width: 100%;
    outline: none;
    ${(props) => props.configStyles}
`;

export const AutocompleteResults = styled.div<{ configStyles?: {} | undefined }>`
    width: 100%;
    position: absolute;
    ${(props) => props.configStyles}
`;

export const AutocompleteOption = styled.div<{ configStyles?: {} | undefined }>`
    width: 100%;
    ${(props) => props.configStyles}
`;
