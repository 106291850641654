import PropTypes from 'prop-types';
import { useState } from 'react';
import ShortUniqueId from 'short-unique-id';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';

const uid = new ShortUniqueId({ length: 6 });

const MultistepControls = ({ canvas, component, setComponent }) => {
  const { id, children, settings } = component;

  const [editingStep, setEditingStep] = useState(null);

  const currentStep = canvas.getStep(id) ?? 0;
  const defaultStep = settings.default_step;

  const setChildren = (children) => setComponent({ ...component, children });

  const addStep = () => {
    const index = children.length + 1;
    const step = {
      'attributes': {},
      'children': [],
      'component': 'Step',
      'id': 'Step-' + uid(),
      'namespace': '',
      'events': null,
      'settings': null,
      'viewport': null,
      'build': {
        'alias': 'Step ' + index,
        'id': 'Step-' + uid()
      }
    };
    setChildren([...children, step]);
  };

  const renderSteps = () => {
    return children.map((child, i) => {
      const { id: step_id, build } = child;
      const selected = i === currentStep;
      const isEditing = i === editingStep;
      const isDefaultStep = step_id === defaultStep;

      const copyId = () => generalUtils.copyToClipboard(step_id);

      const setChild = (child) => {
        const newChildren = [...children];
        newChildren.splice(i, 1, child);
        setChildren(newChildren);
      };

      const changeStep = () => canvas.setStep(id, i);

      const setDefaultStep = () => {
        if (!isDefaultStep) {
          setComponent({
            ...component,
            settings: { ...settings, default_step: step_id }
          });
        }
      };

      const toggleStep = () => setEditingStep(isEditing ? null : i);

      const handleEdit = (e) => {
        const alias = e.target.value;
        setChild({ ...child, build: { ...build, alias } });
      };

      const deleteStep = () => {
        if (children.length > 1) {
          const newChildren = [...children];
          newChildren.splice(i, 1);
          setChildren(newChildren);
        }
      };

      return (
        <PFContainer
          key={i}
          display="flex"
          alignItems="center" x
          justifyContent="space-between">
          <PFContainer display="flex" gap="xs">
            <PFButton onClick={copyId}
              type="support" padding="none" height="auto">
              <PFIcon icon="bx bx-stop" />
            </PFButton>
            {isEditing
              ? <PFInput className="alt" value={build.alias}
                onChange={handleEdit} onBlur={toggleStep}
                fontSize="s" style={{ height: 20 }} autoFocus />
              : <PFButton onClick={changeStep} type="none" padding="none">
                <PFText type={selected ? 'primary' : 'secondary'} size="s">
                  {build.alias}
                </PFText>
              </PFButton>}
          </PFContainer>
          <PFContainer display="flex" gap="s">
            <PFButton onClick={setDefaultStep}
              type="support" padding="none" height="auto">
              <PFIcon icon={`bx bx${isDefaultStep ? 's' : ''}-star`} />
            </PFButton>
            <PFButton onClick={toggleStep}
              style={{ pointerEvents: isEditing ? 'none' : 'auto' }}
              type="support" padding="none" height="auto">
              <PFIcon icon="bx bx-edit" />
            </PFButton>
            <PFButton onClick={deleteStep}
              type="support" padding="none" height="auto">
              <PFIcon icon="bx bx-trash" />
            </PFButton>
          </PFContainer>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer padding="horizontal-s vertical-m">
      <PFText margin="bottom-m">Multistep</PFText>
      <PFContainer display="flex" flexDirection="column" gap="xs">
        {renderSteps()}
        <PFButton onClick={addStep} type="secondary" height={30} margin="top-s">
          <PFText type="secondary" size="s">+ add step</PFText>
        </PFButton>
      </PFContainer>
    </PFContainer>
  );
};

MultistepControls.propTypes = {
  canvas: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
};


export default MultistepControls;
