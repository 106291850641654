import SettingsIcon from '@mui/icons-material/Settings';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useGridApiContext } from '@mui/x-data-grid';
import {
  PFAlert,
  PFAlertUser,
  PFButton,
  PFContainer, PFIcon, PFModal, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAddTypes } from 'src/constants/DB/getForm';
import TypeCard from '../../AddColumn/TypeCard';
import ColumnSettingsFrom from './ColumnSettingsForm';

const ColumnSettings = (props) => {
  const currentDB = useSelector((state) => state.current_db);
  const apiRef = useGridApiContext();
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isDefaultRequired, setIsDefaultRequired] = useState(false);
  const { table_name } = useParams();
  const column_name = props.colDef.field;
  const [defaultValue, setDefaultValue] = useState('');
  const [nullable, setNullable] = useState('true');
  const [unique, setUnique] = useState('false');
  const [decimalLength, setDecimalLength] = useState('16');
  const [decimalScale, setDecimalScale] = useState('4');
  const [stringLength, setStringLength] = useState('100');
  const [formError, setFormError] = useState(null);

  const table = currentDB.tables.find((t) => t.table_name === table_name);
  const field = table.fields.find((f) => f.column_name === column_name);

  const [columnType, setColumnType] = useState(field?.type_name ? field
    .type_name : null);
  const [active, setActive] = useState(false);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);

  const exitForm = () => {
    setActive(false);
    setSelectedColumn(null);
    setSelectedTable(null);
    setDefaultValue('');
    setNullable('true');
    setUnique('false');
    setDecimalLength('16');
    setDecimalScale('4');
    setStringLength('100');
    setIsDefaultRequired(false);
    apiRef.current.hideColumnMenu();
  };

  const resetStep = () => {
    setColumnType(null);
    setSelectedColumn(null);
    setSelectedTable(null);
    setDefaultValue('');
    setNullable('true');
    setUnique('false');
    setDecimalLength('16');
    setDecimalScale('4');
    setStringLength('100');
    setIsDefaultRequired(false);
  };

  const handleError = (errorMessage) => {
    if (errorMessage?.includes('Implicit conversion from data type')
      || errorMessage?.includes('Conversion failed')) {
      /* eslint-disable max-len */
      return 'Changing the type of this column is not allowed due to potential data parsing issues with existing records.';
    } else {
      return errorMessage;
    }
  };

  return (
    <>
      {field ? <>
        <MenuItem onClick={() => {
          if (Object.keys(apiRef.current.state.editRows).length > 0) {
            props.setExitModeWarning(true);
          } else {
            setActive(true);
          }
        }}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText >Column settings</ListItemText>
        </MenuItem >
        <PFModal active={active}
          onDismiss={exitForm}
          zIndex={1400}>
          <PFContainer type="toolbar" padding="l" radius="l" width={800}>

            <PFContainer margin="bottom-m" gap="m"
              display="flex" justifyContent="space-between"
              alignItems='center'>
              <PFContainer display='flex' gap='s'
                alignItems='center'>
                {
                  columnType
                    ? <PFButton type="support" onClick={resetStep}
                      padding="none" height={28}
                      style={{
                        background: 'var(--purple-300)',
                        borderRadius: '50%',
                        aspectRatio: 1,
                      }}>
                      <PFIcon icon="bx bx-chevron-left" size="l" />
                    </PFButton>
                    : <></>
                }
                <PFContainer display="flex" gap='xs'>
                  <PFText>Column settings: </PFText>
                  <PFText type="secondary">
                    {field?.alias} ({columnType})
                  </PFText>
                </PFContainer>
              </PFContainer>

              <PFButton type="support" onClick={exitForm}
                padding="none" height={28}
                style={{
                  background: 'var(--purple-400)',
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}>
                <PFIcon icon="bx bx-x" size="l" />
              </PFButton>
            </PFContainer>
            {
              columnType === null
                ? <PFContainer display="grid" gap="m"
                  gridTemplateColumns="repeat(4, 1fr)" >
                  {
                    getAddTypes(currentDB.tables.length)
                      .filter((unfiltered) => unfiltered.type_name !== 'Foreign Key')
                      .map((item, index) => {
                        return (
                          <TypeCard key={`${item.type_name}-${index}`}
                            columnType={item.type_name}
                            icon={item.icon}
                            setColumnType={setColumnType} />
                        );
                      })
                  }
                </PFContainer>
                : <ColumnSettingsFrom columnType={columnType}
                  asyncCall={asyncCall}
                  loading={loading} column_name={column_name}
                  selectedColumn={selectedColumn}
                  setSelectedColumn={setSelectedColumn}
                  selectedTable={selectedTable}
                  setSelectedTable={setSelectedTable}
                  defaultValue={defaultValue}
                  setDefaultValue={setDefaultValue}
                  isDefaultRequired={isDefaultRequired}
                  setIsDefaultRequired={setIsDefaultRequired}
                  setUpdateTable={props.setUpdateTable}
                  nullable={nullable} setNullable={setNullable}
                  exitForm={exitForm} unique={unique} setUnique={setUnique}
                  decimalScale={decimalScale}
                  setDecimalScale={setDecimalScale}
                  decimalLength={decimalLength}
                  setDecimalLength={setDecimalLength}
                  stringLength={stringLength}
                  setStringLength={setStringLength}
                  setFormError={setFormError}
                  apiRef={apiRef} />
            }

          </PFContainer >
          <PFAlertUser message={formError} open={!!formError} close={() => {
            setFormError(null);
          }} />
          <PFAlert message={handleError(error)}
            open={!!error} onClose={dismissError} />
        </PFModal></> : <></>
      }
    </>
  );
};

ColumnSettings.propTypes = {
  colDef: PropTypes.object.isRequired,
  setUpdateTable: PropTypes.func,
  setExitModeWarning: PropTypes.func,
};

export default ColumnSettings;
