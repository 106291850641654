import axios from 'axios';
import { twoappOrigin } from 'PFConfig/env';
import { storageUtils } from 'PFUtils';


const toolkit = axios.create({
  baseURL: twoappOrigin + '/twoapp/v1/toolkit'
});

toolkit.interceptors.request.use(
  async (config) => {
    const jwt = storageUtils.retrieveFromLocalStorage('access_token');
    if (jwt) config.headers.set('Authorization', 'Bearer ' + jwt);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default {
  testRunOperation: async (functionId, operationId, params) =>
    await toolkit.post(`/run/*/${functionId}/${operationId}`, params),
};
