import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

const BreakpointContext = createContext();

export const BreakpointProvider = ({
  themeBreakpoints = [],
  children
}) => {
  const [activeBreakpoint, setBreakpointConfig]
    = useState(themeBreakpoints[0] || null);

  const updateBreakpoint = (newBreakpoint) => {
    setBreakpointConfig(newBreakpoint);
  };

  return (
    <BreakpointContext.Provider value={{
      themeBreakpoints,
      activeBreakpoint,
      updateBreakpoint
    }}>
      {children}
    </BreakpointContext.Provider>
  );
};

BreakpointProvider.propTypes = {
  themeBreakpoints: PropTypes.array,
  breakpoint: PropTypes.string,
  children: PropTypes.array
};

export const useBreakpointContext = () => {
  const context = useContext(BreakpointContext);
  return context;
};


