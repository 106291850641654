import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { builderV2 } from 'PFApis';
import {
  PFButton,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls, useForm } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import { ComponentForm, OptionsButton } from './components';

const CustomComponentSettings = ({
  canvas,
  component,
  setComponent,
}) => {
  const appId = useSelector((state) => state.current_app._id);

  const instanceInfo = component.settings?.comp_instance_config;

  // Current actions: 'CREATING', 'EDITING', 'CREATING_VARIANT'
  const [currentAction, setCurrentAction] = useState(null);

  const [form, fieldHook] = useForm({});
  const [save, alerts] = useAsyncCalls(false);

  const updateLocalInstance = (config, alias) => {
    const settings = { ...(component.settings ?? {}) };
    const build = { ...(component.build ?? {}), alias };
    if (!!config) {
      settings.comp_instance_config = config;
    } else {
      delete settings.comp_instance_config;
    }
    setComponent({ ...component, settings, build });
  };

  const setCreating = () => {
    form.setConfig({
      label: {
        default_value: '',
        validation_type: 'text',
        error_message: true
      },
      category: {
        default_value: '',
        validation_type: 'text',
        error_message: true
      },
      icon: 'pfi pfi-component-container'
    });
    setCurrentAction('CREATING');
  };

  const setEditing = () => {
    form.setConfig({
      label: {
        default_value: instanceInfo.label,
        validation_type: 'text',
        error_message: true
      },
      category: {
        default_value: instanceInfo.category,
        validation_type: 'text',
        error_message: true
      },
      icon: 'pfi pfi-component-container'
    });
    setCurrentAction('EDITING');
  };

  const setCreatingVariant = () => {
    form.setConfig({
      label: {
        default_value: instanceInfo.label + ' - Variant',
        validation_type: 'text',
        error_message: true
      },
      category: {
        default_value: instanceInfo.category,
        validation_type: 'text',
        error_message: true
      },
      icon: 'pfi pfi-component-container'
    });
    setCurrentAction('CREATING_VARIANT');
  };

  const createComponent = (values) => {
    save.asyncCall(async () => {
      const res = await builderV2.createCustomComponent(appId,
        { ...values, component });
      const config = res.component.settings.comp_instance_config;
      updateLocalInstance(config, config.label);
      store.dispatch(currentAppActions.addCustomComponent(res));
      setCurrentAction(null);
    });
  };

  const updateComponentInfo = ({ label, category }) => {
    save.asyncCall(async () => {
      const res = await builderV2.updateCustomComponent(appId,
        instanceInfo.source_id, { label, category });
      const config = res.component.settings.comp_instance_config;
      updateLocalInstance(config, config.label);
      store.dispatch(currentAppActions.updateCustomComponent(res));
      setCurrentAction(null);
    });
  };

  const updateComponent = () => {
    save.asyncCall(async () => {
      const updatedComponent = await canvas.customComponentUpdate(
        instanceInfo.source_id,
        component);

      store.dispatch(currentAppActions.updateCustomComponent(updatedComponent));
      setCurrentAction(null);
    });
  };

  const detachInstance = () => {
    updateLocalInstance(null, component.component);
  };

  if (!!instanceInfo && !_.isEmpty(instanceInfo)) {
    const { label } = instanceInfo;

    switch (currentAction) {
      case 'EDITING':
        return (
          <PFContainer padding="horizontal-s vertical-m">
            <ComponentForm
              form={form} fieldHook={fieldHook}
              title="Update component" callToAction="Update"
              onSubmit={updateComponentInfo} loading={save.loading}
              onClose={() => setCurrentAction(null)} />
          </PFContainer>
        );
      case 'CREATING_VARIANT':
        return (
          <PFContainer padding="horizontal-s vertical-m">
            <ComponentForm
              form={form} fieldHook={fieldHook}
              title="New variant" callToAction="Create variant"
              onSubmit={createComponent} loading={save.loading}
              onClose={() => setCurrentAction(null)} />
          </PFContainer>
        );
      default:
        return (
          <PFContainer padding="s">
            <PFContainer padding="s" radius="s" background="#AD90FF33">
              <PFText size="xs">This is an instance of</PFText>
              <PFContainer display="flex" alignItems="center" gap="s"
                margin="bottom-s">
                <PFText size="s">{label}</PFText>
                <PFButton onClick={setEditing}
                  type="support" padding="none" height={20}>
                  <PFIcon icon="bx bx-pencil" color="white" />
                </PFButton>
              </PFContainer>
              <PFContainer display="flex" justifyContent="space-between">
                <OptionsButton
                  label="Update"
                  icon="bx bx-revision"
                  onClick={updateComponent}
                  loading={save.loading} />
                <OptionsButton
                  label="Variant"
                  icon="bx bx-add-to-queue"
                  onClick={setCreatingVariant} />
                <OptionsButton
                  label="Detach"
                  icon="bx bx-x"
                  onClick={detachInstance} />
              </PFContainer>
            </PFContainer>
          </PFContainer>
        );
    }
  } else {
    return (
      <PFContainer padding="horizontal-s vertical-m">
        {currentAction === 'CREATING'
          ? <ComponentForm
            form={form} fieldHook={fieldHook}
            title="New component" callToAction="Create component"
            onSubmit={createComponent} loading={save.loading}
            onClose={() => setCurrentAction(null)} />
          : <>
            <PFButton width="100%" type="secondary"
              onClick={setCreating}
              style={{ fontSize: 'var(--text-label)' }}>
              Create custom component
            </PFButton>
          </>}
        {alerts()}
      </PFContainer>
    );
  }
};

CustomComponentSettings.propTypes = {
  canvas: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
};

export default CustomComponentSettings;
