/* eslint-disable */
import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton, PFInput, PFLine,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const APICall = ({ index, instruction, setInstruction, isCreated }) => {
  const currentFunction = useSelector((state) => state.current_function);
  const associatedApps = currentFunction.associated_apps.docs;
  const domainOptions = associatedApps.map(({ _id, name }) => {
    return { label: name, value: _id };
  });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { var_name, execute } = instruction;
  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });
  const [integrations, setIntegrations] = useState();
  const [api, setApi] = useState();
  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);
  const handleDomainChange = (e) => {
    setExecute({ ...execute, domain: e.target.value, api_declaration_id: '', params: {} })
  }
  const handleIntegrationChange = (e) => {
    setExecute({ ...execute, api_declaration_id: e.target.value, params: {} });

  }
  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, [])

  useEffect(() => {
    if (execute?.domain) {
      asyncCall(async () => {
        const app = await builderV2.getApp(execute?.domain);
        setIntegrations(app.build.api_declarations.docs)
      });
    }
  }, [execute?.domain])
  useEffect(() => {
    if (execute?.api_declaration_id) {
      asyncCall(async () => {
        const api = await builderV2.getAPI(execute?.domain, execute.api_declaration_id);
        setApi(api);
      });
    }
  }, [execute?.domain, execute?.api_declaration_id])


  const apiOptions = integrations?.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });
  return (
    <>
      <PFContainer type="form" padding="m" radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex"
          justifyContent="space-between" alignItems="center">
          <PFContainer height={30} display="flex" alignItems="center" gap="s">
            {
              !isCollapsed && <PFIconButton
                icon="bx bx-chevron-left"
                onClick={() => setType('')} />
            }

            <PFText type="secondary">Instruction {index + 1}:</PFText>
            <PFText>API Call</PFText>
          </PFContainer>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
          </PFButton>
        </PFContainer>

        <PFContainer display={isCollapsed ? 'none' : 'flex'} flexDirection='column' gap='m'>
          <PFLine />
          <PFContainer display="flex" alignItems="center" gap="m">
            <PFText type="secondary">
              Target variable
            </PFText>
            <PFContainer flex={1}>
              <PFInput value={var_name}
                onChange={(e) => setVarName(e.target.value)}
                placeholder="This is where your response will be stored" />
            </PFContainer>
          </PFContainer>
          <PFLine />

          <PFContainer display='flex' justifyContent='space-between'>
            <PFContainer display="flex" alignItems="center" gap="m">
              <PFText type="secondary">Domain</PFText>
              <PFContainer width='200px' flex={1}>
                <PFSelect options={domainOptions} value={execute?.domain}
                  onChange={handleDomainChange} />
              </PFContainer>
            </PFContainer>
            <PFContainer display="flex" alignItems="center" gap="m">
              <PFText type="secondary">Integration</PFText>
              <PFContainer flex={1} width='200px'>
                <PFSelect options={apiOptions} value={execute.api_declaration_id} onChange={handleIntegrationChange} />
              </PFContainer>
            </PFContainer>
          </PFContainer>

          {!!api && api.params?.length > 0 && execute.api_declaration_id
            ? <>
              <PFLine />
              <PFContainer margin="bottom-m">
                <PFContainer display="flex" alignItems="center" gap="s"
                  margin='bottom-m'>
                  <PFText>Parameters</PFText>
                  <PFText type="support" size="s">
                    Define the variables sent to the API
                  </PFText>
                </PFContainer>
                {
                  ((loading) || error) && execute.api_declaration_id ?
                    <PFContainer className="node-custom-api-editor" width='100%'>
                      <PFContainer type="form" width={'100%'} height={80}
                        padding="m" radius="m"
                        display="flex" alignItems="center" justifyContent="center">
                        <PFLoader color="var(--primary)" />
                      </PFContainer>
                      <PFAlert message={error} open={!!error} onClose={dismissError} />
                    </PFContainer>
                    :
                    <PFContainer
                      display="grid"
                      gridTemplateColumns="auto 1fr"
                      gap="s">
                      {
                        api.params.map(({ key }) => {
                          return (
                            <React.Fragment key={key}>
                              <PFContainer
                                background="var(--input-form-background)"
                                padding="horizontal-s" radius="s"
                                display="flex" alignItems="center" justifyContent="flex-end">
                                <PFText type="secondary" size="s">{key}</PFText>
                              </PFContainer>
                              <PFInput
                                style={{ fontSize: 'var(--text-size-s)' }}
                                placeholder={`${key} value`} value={execute.params[key]}
                                onChange={(e) => {
                                  setExecute({
                                    ...execute,
                                    params: {
                                      ...execute.params,
                                      [key]: e.target.value
                                    }
                                  })
                                }} />
                            </React.Fragment>
                          );
                        })
                      }
                    </PFContainer>
                }

              </PFContainer>
            </>
            : null
          }
        </PFContainer>

      </PFContainer>

    </>
  );
};

APICall.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default APICall;
