import { builderV2 } from 'PFApis';
import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { PFOperationTester } from 'PFComponents/testers';
import { useAsyncCall, useShortcut } from 'PFHooks';
import store, { currentFunctionActions } from 'PFStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  OperationEditor,
  OperationEditorHeader,
  OperationEditorSidebar,
  OperationNameEditor
} from './components';
import { AvailableVariables } from './components/OperationEditor/components';
import { processOperationForEdit, processOperationForSave } from './utils';


const PFScreenOperationEditor = () => {
  const { function_id, operation_id } = useParams();

  const [fetchAsyncCall, fetchLoading, fetchError, dismissFetchError]
    = useAsyncCall(true);
  const [saveAsyncCall, saveLoading, saveError, dismissSaveError]
    = useAsyncCall(false);

  const [operation, setOperation] = useState(null);
  const [testingOpen, setTestingOpen] = useState(false);
  const [saved, setSaved] = useState(false);
  const [updatePublicApi, setUpdatePublicApi] = useState(false);

  const saveOperation = (params = {}) => {
    const newInstructions = [...operation.instructions]
      .map((instruction) => {
        if (instruction.type === 'time_delta') {
          const { database, table_name,
            ...restExecute } = instruction.execute;

          const tableName = '${' + database + '}' + `.${table_name}`;
          const newInstruction = {
            ...instruction,
            execute: {
              ...restExecute,
              table_name: tableName
            }
          };
          return newInstruction;
        } else {
          return instruction;
        }
      });

    const prevOperation = {
      ...operation,
      instructions: newInstructions
    };

    saveAsyncCall(async () => {
      const newOperation = processOperationForSave({
        ...prevOperation,
        ...params,
        response_format: prevOperation.response_format.reduce((obj, item) => {
          obj[item.name] = item.value;
          return obj;
        }, {})
      });

      await builderV2.updateFunctionOperation(
        function_id, operation_id, newOperation);
      store.dispatch(currentFunctionActions.updateOperation(newOperation));
      setSaved(true);
      setUpdatePublicApi((prev) => !prev);
    });
  };

  useEffect(() => {
    fetchAsyncCall(async () => {
      setSaved(false);
      const operation = await builderV2.getFunctionOperation(
        function_id, operation_id);

      const newInstructions = [...operation.instructions]
        .map((instruction) => {
          if (instruction.type === 'time_delta') {
            const { table_name,
              ...restExecute } = instruction.execute;

            const match = table_name.match(/\${(.*?)}/);
            const database = match ? match[1] : '';
            const tableName = table_name
              .substring(table_name.lastIndexOf('.') + 1);
            const newInstruction = {
              ...instruction,
              execute: {
                ...restExecute,
                database: database,
                table_name: tableName
              }
            };
            return newInstruction;
          } else {
            return instruction;
          }
        });

      const prevOperation = {
        ...operation,
        instructions: newInstructions
      };

      const responseNewFormat = Object
        .keys(prevOperation?.response_format).map((key) => ({
          name: key,
          value: prevOperation?.response_format[key]
        }));

      setOperation(processOperationForEdit({
        ...prevOperation,
        response_format: responseNewFormat
      }));
    });
  }, [function_id, operation_id]);

  useShortcut('ctrl+s', saveOperation);

  const getVariables = () => {
    if (!operation?.request_values && !operation?.instructions) return [];
    const requestVariables = operation.request_values.map(([name]) => ({
      name,
      origin: 'Request variables'
    }));
    const instructionsVariables = operation.instructions.map((item, index) => ({
      name: item.var_name,
      origin: `Instruction ${index + 1}`
    }));

    return [...requestVariables, ...instructionsVariables];
  };

  return (
    <>
      <OperationEditorHeader
        opId={operation_id}
        openTesting={() => setTestingOpen(true)}
        save={saveOperation}
        saveLoading={saveLoading}
        saved={saved} />
      <PFContainer display="flex" flex={1} alignItems='stretch'>
        <OperationEditorSidebar opId={operation_id} />
        <PFContainer flex={1}>
          <PFContainer width="100%" height="100%" padding="l">
            {fetchLoading || !operation
              ? <PFLoader area color="var(--primary)" />
              : <PFContainer display='flex' margin='left-m'>
                <PFContainer flex='1'>
                  <OperationNameEditor
                    operation={operation}
                    updateOperation={setOperation}
                    functionId={function_id}
                    save={saveOperation}
                    saved={updatePublicApi} />

                  <PFContainer padding="top-l"
                    display="flex" justifyContent="center">
                    <OperationEditor
                      operation={operation}
                      updateOperation={setOperation}
                      variables={getVariables()} />
                  </PFContainer>
                </PFContainer>
                <PFContainer width='250px'>
                  <PFContainer style={{
                    position: 'fixed',
                    right: '50px'
                  }}>
                    <AvailableVariables variables={getVariables()} />
                  </PFContainer>
                </PFContainer>


              </PFContainer>
            }
            <PFAlert
              message={fetchError}
              open={!!fetchError}
              onClose={dismissFetchError} />
            <PFAlert
              message={saveError}
              open={!!saveError}
              onClose={dismissSaveError} />
          </PFContainer>
        </PFContainer>
        <PFOperationTester
          functionId={function_id}
          operation={operation}
          active={testingOpen}
          onDismiss={() => setTestingOpen(false)} />
      </PFContainer >
    </>
  );
};

export default PFScreenOperationEditor;
