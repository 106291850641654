import { PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import './ComponentMenu.css';
import {
  ConfigureEditor,
  DesignEditor, InteractEditor, TabSelection
} from './components';
const ComponentMenu = ({ canvas, selected, component, setComponent }) => {
  const [tab, setTab] = useState('Design');


  if (!selected) return null;


  const handleHover = (e) => {
    e.stopPropagation();
    canvas.setHover(null);
  };

  const handleClick = (e) => e.stopPropagation();

  const renderTab = () => {
    const props = { canvas, component, setComponent };

    switch (tab) {
      case 'Design':
        return <DesignEditor {...props} />;
      case 'Configure':
        return <ConfigureEditor {...props} />;
      case 'Interact':
        return <InteractEditor {...props} />;
      default:
        return null;
    }
  };

  return (

    <PFContainer id='pf-component-menu-toolbar'
      type="toolbar" width={280} config={{
        onMouseMove: handleHover,
        onClick: handleClick,
      }} style={{
        height: 'calc(100vh - 60px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: '20px'
      }} className='component-menu'>
      <TabSelection tab={tab} setTab={setTab} />
      {renderTab()}
    </PFContainer>
  );
};

ComponentMenu.propTypes = {
  canvas: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
};


export default ComponentMenu;
