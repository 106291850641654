import { PFContainer, PFIcon, PFLink, PFText } from 'PFComponents/common';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const AppDashboardSidebar = () => {
  const currentApp = useSelector((state) => state.current_app);
  const location = useLocation();

  const basePath = `/apps/${currentApp._id}`;

  const getLinkColor = (pathEnding) => {
    return location.pathname.startsWith(`${basePath}${pathEnding}`)
      ? 'white'
      : 'var(--secondary)';
  };

  return (
    <PFContainer type="main"
      display="flex" flexDirection="column" gap="s"
      width='200px'>

      <PFContainer margin="bottom-xl"
        display="flex" flexDirection="column" gap="m">
        <PFLink to={`${basePath}/pages`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-file-blank" size="l"
              color={getLinkColor('/pages')} />
            <PFText color={getLinkColor('/pages')}>Pages</PFText>
          </PFContainer>
        </PFLink>
        <PFLink to={`${basePath}/integrations`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-world" size="l"
              color={getLinkColor('/integrations')} />
            <PFText color={getLinkColor('/integrations')}>
              Integrations
            </PFText>
          </PFContainer>
        </PFLink>
        <PFLink to={`${basePath}/flows`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-git-merge" size="l"
              color={getLinkColor('/flows')} />
            <PFText color={getLinkColor('/flows')}>Process flows</PFText>
          </PFContainer>
        </PFLink>
        <PFLink to={`${basePath}/functions`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-code-alt" size="l"
              color={getLinkColor('/functions')} />
            <PFText color={getLinkColor('/functions')}>Functions</PFText>
          </PFContainer>
        </PFLink>
        <PFLink to={`${basePath}/automations`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-trip" size="l"
              color={getLinkColor('/automations')} />
            <PFText color={getLinkColor('/automations')}>Automations</PFText>
          </PFContainer>
        </PFLink>
      </PFContainer>
      <PFContainer display="flex" flexDirection="column" gap="m">
        <PFLink to={`${basePath}/environments`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-folder" size="l"
              color={getLinkColor('/environments')} />
            <PFText color={getLinkColor('/environments')}>
              Environments
            </PFText>
          </PFContainer>
        </PFLink>
        <PFLink to={`${basePath}/domains`}>
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-windows" size="l"
              color={getLinkColor('/domains')} />
            <PFText color={getLinkColor('/domains')}>
              Domains
            </PFText>
          </PFContainer>
        </PFLink>
      </PFContainer>
    </PFContainer>
  );
};

export default AppDashboardSidebar;
