import { PFContainer, PFText } from 'PFComponents/common';
import { COMPONENTS } from 'PFConstants';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import './ComponentSelection.css';
import DrawerSection from './components/DrawerSection';


const ComponentSelection = ({ canvas, open, setOpenDrawer }) => {
  const componentsDrawer = COMPONENTS.LIST.reduce((acc, componentD) => {
    const { category } = componentD;
    return {
      ...acc,
      [category]: (acc[category] || []).concat(componentD)
    };
  }, {});

  useEffect(() => {
    const closeLeftPanel = (event) => {
      if (event.key === 'Escape') {
        setOpenDrawer(null);
      }
    };

    window.addEventListener('keyup', closeLeftPanel);

    return () => {
      window.removeEventListener('keyup', closeLeftPanel);
    };
  }, []);

  return (
    <PFContainer type="toolbar" width={280}
      style={{
        top: 5, bottom: 0, position: 'absolute', overflowY: 'auto',
        borderLeft: '3px solid var(--container-editor-background)',
        transition: 'all 200ms ease', boxShadow: '5px 0 10px -2px #000a',
        right: open ? -280 : 10, marginBottom: '60px'
      }} className='component-selection'>
      <PFContainer padding="m">
        <PFText margin="bottom-m">Components</PFText>
        {Object.keys(componentsDrawer).map((categoryName) => (
          <DrawerSection key={categoryName}
            componentsDrawer={componentsDrawer}
            categoryName={categoryName} canvas={canvas}
            setOpenDrawer={setOpenDrawer} />
        ))}

      </PFContainer>
    </PFContainer>
  );
};

ComponentSelection.propTypes = {
  canvas: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpenDrawer: PropTypes.func
};

export default ComponentSelection;
