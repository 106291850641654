import { PFContainer, PFInput, PFLoader, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';

const InputParameters = ({
  inputParameters, loading = false, formObject, setFormObject
}) => {
  return (
    <PFContainer width='30%'>
      <PFText color='var(--black)'>Input Parameters</PFText>
      {loading ? (
        <PFContainer>
          <PFLoader color='var(--purple-100)' />
        </PFContainer>
      ) : (
        <PFContainer
          className='custom-scroll'
          margin='top-s'
          style={{
            maxHeight: '600px',
            overflow: 'auto',
          }}
          display='flex'
          flexDirection='column'
          gap='s'
        >
          {inputParameters.map(
            ({ name, value }, index) => {
              return (
                <PFContainer
                  key={index}
                  display='flex'
                  gap='s'
                  padding='right-s'
                >
                  <PFContainer
                    background='var(--purple-25)'
                    padding='s'
                    radius='s'
                    width='30%'
                    style={{
                      overflow: 'auto',
                    }}
                    className='custom-scroll'
                  >
                    <PFText color='var(--purple-100)'>{name}</PFText>
                  </PFContainer>
                  <PFInput
                    width='70%'
                    value={formObject[name]}
                    onChange={(e) => {
                      setFormObject({
                        ...formObject,
                        [name]: e.target.value,
                      });
                    }}
                    style={{
                      backgroundColor: 'var(--purple-25)',
                      boxSizing: 'content-box',
                    }}
                  />
                </PFContainer>
              );
            }
          )}
        </PFContainer>
      )}
    </PFContainer>
  );
};

InputParameters.propTypes = {
  formObject: PropTypes.object,
  setFormObject: PropTypes.func,
  inputParameters: PropTypes.array,
  loading: PropTypes.bool,
};

export default InputParameters;
