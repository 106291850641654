import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { PFContainer } from 'PFComponents/common';
import {
  PFDashboardHeader,
  PFUserContentTabs
} from 'PFComponents/navigation';
import {
  PFScreenUserApps,
  PFScreenUserDBs,
  PFScreenUserFunctions
} from 'PFScreens';

const PFLayoutUserContent = () => {
  const { activeTheme } = useSelector((state) => state.theme);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <PFDashboardHeader />
        <PFContainer type="main" padding="vertical-l"
          display="flex" flexDirection="column" alignItems="center">
          <PFContainer width={640}>
            <PFUserContentTabs />
            <Routes>
              <Route path="/" element={<PFScreenUserApps />} />
              <Route path="/functions" element={<PFScreenUserFunctions />} />
              <Route path="/databases" element={<PFScreenUserDBs />} />
            </Routes>
          </PFContainer>
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
};

export default PFLayoutUserContent;
