import PropTypes from 'prop-types';

import {
  PFButton,
  PFCodeEditor,
  PFContainer,
  PFIcon,
  PFSelect,
  PFText
} from 'PFComponents/common';
import validators from 'src/utils/validators';

const errorTypes = [
  { label: '404', value: '404' },
  { label: '500', value: '500' },
];

const ErrorEditor = ({ operation, updateOperation }) => {
  const errorMessages = operation.error_messages;
  const updateErrorMessages = (newErrorMessages) => {
    updateOperation({ ...operation, error_messages: newErrorMessages });
  };

  const addError = () => {
    errorMessages.push([errorTypes[0].value, '']);
    updateErrorMessages(errorMessages);
  };

  const renderFields = () => {
    return errorMessages.map(([code, object], i) => {
      const handleCodeUpdate = (e) => {
        const { value } = e.target;
        errorMessages[i] = [value, object];
        updateErrorMessages(errorMessages);
      };

      const handleObjectUpdate = (e) => {
        const { value } = e.target;
        errorMessages[i] = [code, value];
        updateErrorMessages(errorMessages);
      };

      const removeError = () => {
        errorMessages.splice(i, 1);
        updateErrorMessages(errorMessages);
      };

      return (
        <PFContainer key={i} display="flex" flexDirection="column" gap="s">
          <PFContainer display="flex" alignItems="center"
            justifyContent="space-between">
            <PFContainer width={100}>
              <PFSelect value={code} onChange={handleCodeUpdate}
                options={errorTypes} />
            </PFContainer>
            <PFButton type="support" onClick={removeError}>
              <PFIcon icon="bx bx-trash" size="l" />
            </PFButton>
          </PFContainer>
          <PFCodeEditor value={object} onChange={handleObjectUpdate}
            error={validators.validateJSON(object)}
            minHeight={100} placeholder="JSON error" />
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="m">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFText type="secondary">Errors</PFText>
        {errorMessages.length === 0
          ? <PFButton type="support" onClick={addError}>
            + add error response
          </PFButton>
          : null}
      </PFContainer>
      {errorMessages.length > 0
        ? <PFContainer display="flex" flexDirection="column" gap="m">
          {renderFields()}
          <PFButton type="support" onClick={addError}
            style={{ alignSelf: 'start' }}>
            + add error response
          </PFButton>
        </PFContainer>
        : null}
    </PFContainer>
  );
};

ErrorEditor.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
};

export default ErrorEditor;
