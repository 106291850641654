import { authPefai } from 'PFApis';
import { images } from 'PFAssets';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFInput,
  PFLink,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import store, { authActions } from 'PFStore';

const PFScreenBuilderV2Login = () => {
  const [loginForm, fieldHook] = useForm({
    username: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a User ID'
    },
    password: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a Password'
    }
  });
  const [asyncCall, loginLoading, loginError, errorDismiss] = useAsyncCall();

  const login = (values) => {
    asyncCall(async () => {
      const authResponse = await authPefai.login(values);
      if (authResponse !== null) {
        const accessToken = authResponse.access_token;
        store.dispatch(authActions.setAccessToken(accessToken));
      }
    });
  };

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="center" margin='top-xl'>
      <PFForm form={loginForm} submit={login} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="center" gap="s">
          <img src={images.pefaiLogo} alt="Pefai" height={50} />
          <PFText tag="h2" weight={400} margin="top-xl bottom-l">
            Welcome!
          </PFText>
          <PFInput placeholder="Username" name="username"
            fieldHook={fieldHook}
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFInput placeholder="Password" name="password"
            fieldHook={fieldHook}
            width="100%" margin="bottom-m"
            type='password'
            style={{ height: 50 }} />
          <PFButton submit loading={loginLoading}
            width="100%" height={50} margin='bottom-m'
            style={{ borderRadius: 25 }}>
            Sign In
          </PFButton>

          <PFLink to='/forgot-password' type='primary'
            color='var(--purple-150)' style={{
              fontWeight: '400'
            }}>
            Forgot your password?</PFLink>
        </PFContainer>
      </PFForm>
      <PFAlert message={loginError}
        open={!!loginError} onClose={errorDismiss} />
    </PFContainer>
  );
};

export default PFScreenBuilderV2Login;
