import React, { ReactNode, useState, useEffect } from 'react';
import * as Styled from './metawrapper.styled';

export type MetawrapperProps = {
    /**
     * a node to be rendered in Metawrapper
     */
    children?: ReactNode | ReactNode[];

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * styling overrides
     */
    configStyles?: any;

    /**
     * To identify the component
     */
    id: string;

    /**
     * Based on the Formik response when validations are put to the test. Here, there's consideration for adding a new property to understand the details of which validation has failed
     *  - error
     *  - touched
     *  - value
     *  - fieldErrorDetails: {
     *                          - message: Error msg
     *                          - path: field name
     *                          - type: validation type
     *                          }
     */
    meta: {
        error?: string;
        touched?: boolean;
        value?: string;
        fieldErrorDetails?: {
            message?: string;
            path?: string;
            type?: string;
        };
    };
};

export function Metawrapper({ children, className, configStyles, id, meta, ...props }: MetawrapperProps) {
    const [fieldStatus, setFieldStatus] = useState<'meta-error' | 'meta-success' | 'meta-untouched' | undefined>(
        undefined,
    );

    useEffect(() => {
        if (meta) {
            const { value, touched, error } = meta;
            if (touched && value && !error) {
                setFieldStatus('meta-success');
            } else if (touched && error) {
                setFieldStatus('meta-error');
            } else {
                setFieldStatus('meta-untouched');
            }
        }
    }, [meta]);

    return (
        <Styled.MetaWrapper
            className={`${fieldStatus} ${className ? className : ''}`}
            configStyles={configStyles}
            id={id}
            {...props}
        >
            {children}
        </Styled.MetaWrapper>
    );
}
