import biginteger from './biginteger.svg';
import boolean from './boolean.svg';
import date from './date.svg';
import datetime from './datetime.svg';
import decimal from './decimal.svg';
import float from './float.svg';
import foreignkey from './foreignkey.svg';
import integer from './integer.svg';
import money from './money.svg';
import string from './string.svg';

export const addIcons = [
  {
    name: 'biginteger',
    icon: biginteger
  },
  {
    name: 'boolean',
    icon: boolean
  },
  {
    name: 'date',
    icon: date
  },
  {
    name: 'datetime',
    icon: datetime
  },
  {
    name: 'decimal',
    icon: decimal
  },
  {
    name: 'float',
    icon: float
  },
  {
    name: 'foreignkey',
    icon: foreignkey
  },
  {
    name: 'integer',
    icon: integer
  },
  {
    name: 'money',
    icon: money
  },
  {
    name: 'string',
    icon: string
  },
];

export const getIcon = (target) => {
  return addIcons.find((el) => el.name === target);
};
