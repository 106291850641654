export default () => {
  return ({
    'String': '',
    'Integer': null,
    'Decimal': null,
    'Auto Increment': null,
    'Boolean': null,
    'Date & Time': null,
    'Select': '',
    'Currency': null,
    'Email': '',
    'Phone': '',
    'Rating': 0,
    'Big Integer': null,
    'Short String': '',
    'Long String': '',
    'Date': null,
    'Float': null,
    'Money': null
  });
};
