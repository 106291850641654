import { PFContainer, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { TreeLevel } from './components';

const ComponentTree = ({ canvas, open, blob, setBlob }) => {
  return (
    <PFContainer className='pf-componenttree' type="toolbar" width={280}
      style={{
        top: 5, bottom: 0, position: 'absolute', overflowY: 'auto',
        borderLeft: '3px solid var(--container-editor-background)',
        transition: 'all 200ms ease', boxShadow: '5px 0 10px -2px #000a',
        right: open ? -280 : 10,
      }}>
      <PFContainer padding="m">
        <PFText margin="bottom-m">Component tree</PFText>
        {!!blob
          ? <TreeLevel
            canvas={canvas}
            nodes={blob}
            setNodes={setBlob} />
          : null}
      </PFContainer>
    </PFContainer>
  );
};

ComponentTree.propTypes = {
  canvas: PropTypes.object.isRequired,
  open: PropTypes.bool,
  blob: PropTypes.array,
  setBlob: PropTypes.func.isRequired,
};

export default ComponentTree;
