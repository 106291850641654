import {
  PFButton, PFContainer,
  PFIcon, PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import 'src/App.css';
import CopyOptions from './CopyOptions';

const TypeSelection = ({ setTab, createNode,
  next, setIsHovered, nodes, flowAlias }) => {
  const firstRender = useRef(true);
  const componentRef = useRef(null);
  const [toggleCopyOptions, setToggleCopyOptions] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!!componentRef.current
        && !componentRef.current.contains(e.target)
        && !firstRender.current) {
        setTab('START');
      }
      if (firstRender.current) firstRender.current = false;
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const renderOptions = () => {
    return PROCESS_FLOW.NODE_TYPES.map(({ type, title, tooltip }) => {
      return (
        <PFButton
          key={type}
          type="none"
          tooltip={tooltip}
          onClick={() => setTab(type)}
          className="node-type-selection-button">
          <PFContainer
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="m">
            <PFContainer height={40} display="flex" alignItems="center">
              <PFContainer
                className={`flow-node-icon-button ${type}`} />
            </PFContainer>
            <PFText>{title}</PFText>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <div ref={componentRef}>
      <PFContainer display="flex" gap="m" width={400}
        padding="horizontal-m" margin="vertical-s"
        justifyContent='center'>
        {renderOptions()}
      </PFContainer>
      {
        Object.values(nodes).filter((filteredNode) =>
          filteredNode._id !== 'holder_node').length > 0
          ? <PFContainer display='flex' justifyContent='center'
            width={400} padding="horizontal-m" margin="vertical-s">

            <PFButton type='none' padding='xs' height={'auto'}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--size-xs)'
              }} onClick={() => {
                setToggleCopyOptions((prev) => !prev);
              }}>
              <PFIcon color='var(--purple-100)' icon='bx bx-copy' />
              <PFText color='var(--purple-100)'>Copy existing node</PFText>
            </PFButton>
          </PFContainer>
          : <></>
      }

      <PFContainer className='pf-scroll'
        style={{
          maxHeight: '200px',
          overflow: 'auto'
        }}>
        {
          toggleCopyOptions
            ? Object.values(nodes)
              .filter((filteredNode) => filteredNode._id
                !== 'holder_node').
              map((nodeElement, index) => {
                return (
                  <CopyOptions flowAlias={flowAlias} nodeElement={nodeElement}
                    key={index} setIsHovered={setIsHovered}
                    next={next} setTab={setTab}
                    createNode={createNode} />
                );
              })
            : <></>
        }
      </PFContainer>


    </div>
  );
};

TypeSelection.propTypes = {
  setTab: PropTypes.func.isRequired,
  createNode: PropTypes.func.isRequired,
  next: PropTypes.string,
  setIsHovered: PropTypes.func,
  nodes: PropTypes.object,
  flowAlias: PropTypes.string,
};

export default TypeSelection;
