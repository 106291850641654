import PropTypes from 'prop-types';

import {
  PFContainer,
  PFText
} from 'PFComponents/common';

import { EditorControl } from '../../components';

import '../../DesignEditor.css';


function composeDropshadow({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);
  const configStyles = {
    [styleKey]: styleValue
  };

  return {
    configStyles
  };
}

const Dropshadow = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <div onClick={handleActiveSection}>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'}
          />
          Drop Shadow
        </PFText>
      </div>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up'
        : 'pf-design-editor__section--down'}
      >
        {
          ruleSet.map((dropshadowRule, index) => {
            const ruleKey = Object.keys(dropshadowRule)[0];
            const ruleValueControls = dropshadowRule[ruleKey];
            return (
              <PFContainer
                key={
                  `design-editor-${ruleKey}-${index}`
                }
                className="pf-designer-editor__rule">
                <PFContainer className="pf-design-editor__control">
                  {
                    ruleValueControls.map((rule, index) => {
                      return (
                        <EditorControl
                          key={`design-editor-rule-${rule.key}-${index}}`}
                          componentBreakpointStyles={componentBreakpointStyles}
                          placeholder={`inset hor vert blur spread #color`}
                          onChange={(event) => {
                            const composedValue = composeDropshadow({
                              alias: rule.alias,
                              composedValue: { [rule.key]: event.target.value },
                            });
                            composeStyles({
                              composedAttributes: composedValue,
                            });
                          }}
                          styleConfig={{
                            key: rule.key,
                            value: rule.value,
                            alias: rule.alias
                          }}
                          type={typeof rule.type === 'undefined'
                            ? 'editor-control-button' : rule.type}
                        >
                          {rule.label}
                        </EditorControl>
                      );
                    })
                  }
                </PFContainer>
              </PFContainer>
            );
          })
        }
      </PFContainer>
    </PFContainer >
  );
};

export default Dropshadow;

Dropshadow.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object,
};
