/**
 * Retrieve an item from the local storage.
 *
 * @param {string} key - Key of the item.
 *
 * @return {any} Value retrieved from the local storage.
 */
export const retrieveFromLocalStorage = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

/**
 * Save an item to the local storage.
 *
 * @param {string} key - Key of the item.
 * @param {any} value - Value to save.
 */
export const saveToLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Delete an item from the local storage.
 *
 * @param {string} key - Key of the item.
 */
export const deleteFromLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export default {
  retrieveFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
};
