import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGrid, GridRowEditStopReasons, GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector, GridToolbarFilterButton
} from '@mui/x-data-grid';
import { useErrorMessages } from 'PFHooks';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { ColumnSettingsComponent, EmptyTableComponent } from './components';
import { getColumnsDefinition, getRowUpdateObject } from './utils';


const darkTheme = createTheme({ palette: { mode: 'dark' } });

/**
 * Component that renders a table with information from a DB table. The
 * underling implementation of this component is the MUI DataGrid.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - TableEditor properties.
 * @param {Object} props.tableConfig - Configuration of the DB table to be
 * displayed.
 * @param {Object} props.rows - Rows that are present in the table
 * @param {boolean} props.loading - Determines if the table should enter a
 * loading stage.
 *
 * @return {React.ReactElement} The table editor component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const TableEditor = ({
  apiRef,
  dbId,
  tableConfig,
  rows,
  loading,
  setUpdateTable,
  setExitModeWarning
}) => {
  const {
    values, setValues,
    models, setModels,
    selection, setSelection,
  } = rows;

  const [save, alerts] = useErrorMessages(false);
  const columnsDefinition = getColumnsDefinition(tableConfig,
    rows, setUpdateTable, apiRef, setExitModeWarning);
  const currentUserId = useSelector((state) => state.user._id);
  const handleRowSave = async (updatedRow) => {
    const { id, ctrl_index, isNew } = updatedRow;
    const { table_name } = tableConfig;
    const rowUpdates = getRowUpdateObject(tableConfig, updatedRow);
    let resultingRow;
    const saveErr = await save.asyncCall(async () => {
      if (isNew) {
        resultingRow = await builderTest(currentUserId).insertDBTableRecord(
          dbId, table_name, rowUpdates);
      } else {
        resultingRow = await builderTest(currentUserId).updateDBTableRecord(
          dbId, table_name, id, rowUpdates);
      }
    });

    if (!saveErr) {
      resultingRow = { ...resultingRow, ctrl_index };
      setValues(values.map((row) =>
        row.ctrl_index === resultingRow.ctrl_index ? resultingRow : row));
      return resultingRow;
    };
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{
        gap: 'var(--size-l)',
        display: 'flex',
        justifyContent: 'end',
        paddingRight: 'var(--size-l)'

      }}>
        <GridToolbarColumnsButton style={{
          color: 'var(--purple-100)'

        }} />
        <GridToolbarFilterButton style={{ color: 'var(--purple-100)' }} />
        <GridToolbarDensitySelector style={{ color: 'var(--purple-100)' }} />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <DataGrid
          apiRef={apiRef}
          sx={{
            'border': 'none',
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
              borderRight: '1px solid #303030',
              colorScheme: 'dark'
            },
            /* eslint-disable max-len */
            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              marginBottom: 0
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '5px',
              height: '5px',
              background: 'var(--purple-saturated)'
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              background: 'var(--purple-200)',
              borderRadius: '10px',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: 'var(--purple-100)'
            }
          }}
          slots={{
            columnMenu: ColumnSettingsComponent,
            loadingOverlay: LinearProgress,
            noRowsOverlay: EmptyTableComponent,
            toolbar: CustomToolbar
          }}
          slotProps={{
            columnMenu: {
              setValues: setValues,
              setUpdateTable: setUpdateTable,
              setExitModeWarning: setExitModeWarning
            }
          }}

          loading={loading || save.loading}
          rowModesModel={models}
          onRowModesModelChange={(newModel) => setModels(newModel)}
          rowSelectionModel={selection}
          onRowSelectionModelChange={(newSel) => setSelection(newSel)}
          rows={values}
          editMode="row"
          columns={columnsDefinition}
          rowHeight={35}
          getRowId={(row) => row.ctrl_index}
          processRowUpdate={handleRowSave}
          checkboxSelection
          disableRowSelectionOnClick
          onRowEditStart={(params, e) => {
            if (Object.keys(apiRef.current.state.editRows).length > 0) {
              e.defaultMuiPrevented = true;
              setExitModeWarning(true);
            }
          }}
          onRowEditStop={(params, event) => {
            if (params.reason === GridRowEditStopReasons.rowFocusOut) {
              event.defaultMuiPrevented = true;
            }
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pageSizeOptions={[25, 50, 100]}
        />


      </ThemeProvider>
      {alerts()}
    </>
  );
};

TableEditor.defaultProps = {
  className: '',
  style: {},
  type: 'primary',
  config: {},
};

TableEditor.propTypes = {
  apiRef: PropTypes.any.isRequired,
  dbId: PropTypes.string.isRequired,
  tableConfig: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  setUpdateTable: PropTypes.func,
  setExitModeWarning: PropTypes.func
};

export default TableEditor;
