const GlobalProperties = [
  { label: 'inherit', value: 'inherit' },
  { label: 'initial', value: 'initial' },
  { label: 'unset', value: 'unset' }
];

const BaseFonts = [
  { label: 'Arial', value: 'Arial, Helvetica, sans-serif' },
  { label: 'Times New Roman', value: 'Times New Roman, Times, serif' },
  { label: 'Courier New', value: 'Courier New, Courier, monospace' },
  { label: 'Georgia', value: 'Georgia, serif' },
  { label: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
  { label: 'Impact', value: 'Impact, Charcoal, sans-serif' },
  {
    label: 'Lucida Sans Unicode',
    value: 'Lucida Sans Unicode, Lucida Grande, sans-serif'
  },
  { label: 'Trebuchet MS', value: 'Trebuchet MS, Helvetica, sans-serif' },
  {
    label: 'Palatino Linotype',
    value: 'Palatino Linotype, Book Antiqua, Palatino, serif'
  },
  { label: 'Arial Black', value: 'Arial Black, Gadget, sans-serif' },
  { label: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
  { label: 'Comic Sans MS', value: 'Comic Sans MS, cursive, sans-serif' },

];

const EditorDropdownOptions = {
  BaseFontFamilies: [
    ...BaseFonts,
    ...GlobalProperties
  ],
  BaseFontWeights: [
    { label: 'Normal', value: 'normal' },
    { label: 'Bold', value: 'bold' },
    { label: 'Lighter', value: 'lighter' },
    { label: 'Bolder', value: 'bodler' },
    ...GlobalProperties
  ],
  Size: [
    { label: 'fr', value: 'fr' },
    { label: 'px', value: 'px' },
    { label: '%', value: '%' },
    { label: 'em', value: 'em' },
    { label: 'rem', value: 'rem' },
    { label: 'ch', value: 'ch' },
    { label: 'vw', value: 'vw' },
    { label: 'vh', value: 'vh' },
    { label: 'auto', value: 'auto' },
    { label: 'min-content', value: 'min-content' },
    { label: 'max-content', value: 'max-content' },
    ...GlobalProperties
  ],
  Fit: [
    { label: 'Fill', value: 'fill' },
    { label: 'Contain', value: 'contain' },
    { label: 'Cover', value: 'cover' },
    { label: 'None', value: 'none' },
    { label: 'Scale Down', value: 'scale-down' },
    ...GlobalProperties
  ],
  Position: [
    { label: 'Sticky', value: 'sticky' },
    { label: 'Absolute', value: 'absolute' },
    { label: 'Relative', value: 'relative' },
    { label: 'Fixed', value: 'fixed' },
    { label: 'Static', value: 'static' },
    ...GlobalProperties
  ],
  BorderStyles: [
    { label: 'Solid', value: 'solid' },
    { label: 'Dotted', value: 'dotted' },
    { label: 'Dashed', value: 'dashed' },
    { label: 'Hidden', value: 'hidden' },
    ...GlobalProperties
  ],
  BoxSizing: [
    { label: 'Content Box', value: 'content-box' },
    { label: 'Border Box', value: 'border-box' },
    ...GlobalProperties
  ]
};

export default EditorDropdownOptions;
