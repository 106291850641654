export default [
  {
    component: 'Input',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Telephone',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Currency',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Radio',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Checkbox',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Textarea',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Email',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Url',
    requiredParent: 'Form',
    level: null,
  },
  {
    component: 'Number',
    requiredParent: 'Form',
    level: null,
  },
];
