import { useLocation } from 'react-router-dom';

import { PFContainer, PFLink, PFText } from 'PFComponents/common';

const sections = [
  { name: 'Apps', path: '/' },
  { name: 'Functions', path: '/functions' },
  { name: 'Databases', path: '/databases' },
];

const PFUserContentTabs = () => {
  const location = useLocation();

  const renderTabs = () => {
    return sections.map(({ name, path }) => {
      const color = location.pathname === path ? 'white' : 'var(--secondary)';

      return (
        <PFLink key={path} to={path}>
          <PFText size="l" color={color}>{name}</PFText>
        </PFLink>
      );
    });
  };

  return (
    <PFContainer type="main" margin="bottom-xl"
      display="flex" gap="l">
      {renderTabs()}
    </PFContainer>
  );
};

export default PFUserContentTabs;
