import { PFAlert } from 'PFComponents/common';
import useAsyncCall from './useAsyncCall';

const useErrorMessages = (...loadingDefaults) => {
  const resultingHooks = loadingDefaults.map((loadingDefault) => {
    const [asyncCall, loading, error, dismissError]
      = useAsyncCall(loadingDefault);
    return { asyncCall, loading, error, dismissError };
  });

  const alerts = () => {
    return resultingHooks.map(({ error, dismissError }, i) => {
      const parsedError = error;

      return (
        <PFAlert
          key={i}
          message={parsedError}
          open={!!parsedError}
          onClose={dismissError} />
      );
    });
  };

  return [...resultingHooks, alerts];
};

export default useErrorMessages;
