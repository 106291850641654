import styled from 'styled-components';

export const FormFieldMetaData = styled.div<{
    configStyles: string | undefined;
    display: 'error' | 'success' | 'touched' | 'value' | undefined;
}>`
    font-size: 16px;
    color: ${(props) => (props.display === 'error' ? '#FF0000' : '#000000')};
    ${(props) => props.configStyles};
`;
