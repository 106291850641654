
import { PROCESS_FLOW } from 'PFConstants';
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 3 });

export const CUSTOM_SAVE_TYPE = PROCESS_FLOW.NODE_TYPE_ACTION_OPTIONS
  .find((n) => n.type === 'UTILS');

export const addParam = (form) => {
  const newConfig = form.values;
  console.log('NEWCONFIG', newConfig, form);
  newConfig.params.push({ variableName: `root_${uid()}`, value: '' });
  form.setConfig(newConfig);
};

export const removeParam = (form, index) => {
  const newConfig = form.values;
  newConfig.params.splice(index, 1);
  form.setConfig(newConfig);
};

export const saveNode = (values, node, nodeName, asyncNode,
  updateNode, goBack) => {
  const formattedParams = values.params
    .filter((prevFilter) => !!prevFilter.value)
    .reduce((transformedObject, param) => {
      return {
        ...transformedObject,
        [param.variableName]: `removeSession(${param.value})`
      };
    }, {});

  const updatedNode = { ...node };
  updatedNode.alias = nodeName ?? 'Custom session node';
  updatedNode.params = formattedParams;
  updatedNode.async = asyncNode;

  updateNode(updatedNode);
  goBack();
};

export const formConfigFromParams = (params) => {
  const newConfig = { params: [] };
  for (const variableName in params) {
    const targetVariable = params[variableName].match(/\(([^)]+)\)/);
    const parsedTargetVariable = targetVariable ? targetVariable[1] : '';
    newConfig.params.push({ variableName, value: parsedTargetVariable });
  };
  if (newConfig.params.length === 0) {
    newConfig.params.push({ variableName: `root_${uid()}`, value: '' });
  }
  return newConfig;
};
