import { PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { NodeCreation, NodeEditor, NodeSuffix } from './components';
import './PFFlowNode.css';

/**
 * @typedef {import('../../utils/flows').ProcessFlowNode} ProcessFlowNode
 */

/**
 * Renders a process flow node.
 *
 * @param {Object} props - PFFlowNode props.
 * @param {ProcessFlowNode} props.node - Node to render.
 * @param {Object} props.config - Node render configuration.
 * @param {func} props.createNode - Function to create a node.
 * @param {func} props.updateNode - Function to update a node.
 * @param {func} props.removeNode - Function to remove a node.
 *
 * @return {React.ReactElement} - PFFlowNode component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFFlowNode = ({ node, config, createNode, updateNode, removeNode,
  nodes, flowAlias }) => {
  const createNewNode = (initialNodeConfig) => {
    createNode(initialNodeConfig, config);
  };

  const removeThisNode = () => {
    removeNode(node, config);
  };

  return (
    <PFContainer display="flex" alignItems="flex-start">
      <NodeSuffix suffix={config.suffix} type={config.type} />
      {node.type === 'NEW'
        ? <NodeCreation nextNode={node.next} createNode={createNewNode}
          nodes={nodes} flowAlias={flowAlias} />
        : <NodeEditor
          node={node}
          config={config}
          updateNode={updateNode}
          removeNode={removeThisNode} />
      }
    </PFContainer>
  );
};

PFFlowNode.propTypes = {
  node: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  createNode: PropTypes.func.isRequired,
  updateNode: PropTypes.func.isRequired,
  removeNode: PropTypes.func.isRequired,
  nodes: PropTypes.object,
  flowAlias: PropTypes.string,
};

export default PFFlowNode;
