import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFSelect
} from 'PFComponents/common';
import { FUNCTIONS } from 'PFConstants';
import PropTypes from 'prop-types';
import React from 'react';


const operatorOptions = FUNCTIONS.SQL_OPERATORS.map((op) => ({
  label: op.label, value: op.value,
}));

const QueryJoinFilters = ({ filters, setFilters, columnOptions,
  setCurrentJoinFilter }) => {
  const addFilter = () => {
    setFilters([...filters, {
      column: '', operator: '=', value: '', separator: 'AND', cast: '',
    }]);
  };

  const renderFilters = () => {
    return filters.map((filter, i) => {
      const setFilter = (filter) => {
        filters[i] = filter;
        setFilters(filters);
        setCurrentJoinFilter(filters);
      };
      const removeFilter = () => {
        filters.splice(i, 1);
        setFilters(filters);
        setCurrentJoinFilter(filters);
      };

      const setColumn = (column) => setFilter({ ...filter, column });
      const setOperator = (operator) => setFilter({ ...filter, operator });
      const setValue = (value) => setFilter({ ...filter, value });

      return (
        <React.Fragment key={i}>
          <PFSelect
            placeholder="Column"
            value={filter.column}
            onChange={(e) => setColumn(e.target.value)}
            options={columnOptions} />
          <PFSelect
            value={filter.operator}
            onChange={(e) => setOperator(e.target.value)}
            options={operatorOptions} />
          <PFInput
            placeholder="Value"
            value={filter.value}
            onChange={(e) => setValue(e.target.value)} />
          <PFButton type="support" onClick={removeFilter}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="grid" gap="s" margin="top-s"
      gridTemplateColumns="1fr .3fr 1fr .2fr">
      {renderFilters()}
      <PFButton type="support" onClick={addFilter}
        style={{ justifySelf: 'start' }}>
        + add filter
      </PFButton>
    </PFContainer>
  );
};

QueryJoinFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  columnOptions: PropTypes.array,
  setCurrentJoinFilter: PropTypes.func,
};

export default QueryJoinFilters;
