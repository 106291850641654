import {
  PFButton,
  PFContainer, PFIcon, PFInput, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
/* eslint-disable max-len */
import OperationPublicEditor from '../OperationPublicEditor/OperationPublicEditor';

const OperationNameEditor = ({ operation, updateOperation, functionId,
  save, saved }) => {
  const isPublic = operation?.is_public_endpoint;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <PFContainer className="editor" background="none"
      display="flex" gap="m" alignItems="center"
      justifyContent="space-between"
      style={{ position: 'relative', zIndex: 1 }}>
      <PFContainer display="flex" alignItems="center" gap="s">
        <PFButton type='none' height='55px' padding='0'
          width='45px' margin='right-s'
          onClick={() => {
            if (!isPublic) {
              updateOperation({
                ...operation,
                is_public_endpoint: true
              });
              save({
                is_public_endpoint: true
              });
              setIsModalOpen(true);
            } else {
              setIsModalOpen(true);
            }
          }}>
          <PFIcon icon={isPublic ? 'bx bxs-cloud'
            : 'bx bx-cloud-upload'}
            size='xl'
            color={isPublic
              ? 'var(--purple-100)'
              : 'var(--gray-100)'} />
          <PFText size='xs' color={isPublic
            ? 'var(--purple-100)' : 'var(--gray-100)'}
            style={{
              marginTop: '-5px'
            }}>{isPublic ? 'Published' : 'Publish endpoint'}</PFText>
        </PFButton>
        <PFInput className="alt" width="400px"
          value={operation?.name} placeholder="Operation name"
          onChange={(e) => updateOperation({
            ...operation,
            name: e.target.value
          })}
          fontSize='l' />

      </PFContainer>
      <OperationPublicEditor operation={operation}
        updateOperation={updateOperation} isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        functionId={functionId} saved={saved} save={save} />
    </PFContainer>
  );
};

OperationNameEditor.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
  functionId: PropTypes.string,
  save: PropTypes.func,
  saved: PropTypes.bool,
};

export default OperationNameEditor;
