import React, { Children, ReactNode, useEffect, useRef, useState } from 'react';
import * as Styled from './autocomplete.styled';

function getAttributeFromSchema(child: any, attributeKey: string) {
    /**
     * One-App's schema nests props at "attribute" key level
     * {
     *  "attributes": {
     *     --- component props go here---
     *  },
     *  "children": [],
     *  "component": "",
     *  "id": "",
     *  "namespace": "",
     *  "settings": null,
     *  "viewport": null
     * }
     */
    const attribute = child?.attributes?.[attributeKey] || child?.props?.[attributeKey];
    return attribute !== undefined ? attribute : undefined;
}

export type AutocompleteProps = {
    /**
     * React nodes that can be passed as children to the Autocomplete component.
     * It can be an array of React nodes or a single React node.
     */
    children?: ReactNode[] | ReactNode;
    /**
     * A boolean value indicating whether autocomplete functionality is enabled.
     */
    enableAutocomplete?: boolean;
    /**
     * A string representing the CSS class name for styling purposes.
     */
    className?: string | undefined;
    /**
     * A string representing the unique identifier of the Autocomplete component.
     */
    id?: string;
    /**
     * A string representing the current value of the Autocomplete input.
     */
    value?: string;
    /**
     * An optional object for configuring styles.
     * Its type is left intentionally open (`{}`), indicating that it can be any object.
     */
    configStyles?: {};
    /**
     * A function that sets the value of the Autocomplete input.
     * It takes a string parameter.
     */
    setValue?: (value: string) => void;
    /**
     * An optional number indicating the maximum number of results to display in the autocomplete dropdown.
     */
    maxResults?: number;
    /**
     * A boolean indicating the initial state of the autocomplete dropdown.
     */
    initialShowDropdown?: boolean;
};

export function Autocomplete({
    children = [],
    className,
    configStyles,
    enableAutocomplete = true,
    id,
    maxResults = 5,
    setValue = () => {},
    value,
    initialShowDropdown = false,
    ...props
}: AutocompleteProps) {
    const [displayValue, setDisplayValue] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<ReactNode[]>([]);
    const [showDropdown, setShowDropdown] = useState(initialShowDropdown);
    const inputRef = useRef<HTMLInputElement>(null);
    const autocompleteRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setFilteredOptions(getFilteredAutocompleteResults(''));
        if (value) {
            const defaultOptionLabel = getOptionLabelByValue(value);
            setDisplayValue(defaultOptionLabel);
        }
    }, []);

    /**
     * Searches among all the autocomplete options for the one that has exactly the same value in its properties,
     * and once found, the value of the 'optionLabel' property is extracted
     *
     * @param value - It corresponds to the default value that has been set for this field through a higher-level state handler
     * @returns - The string that will be displayed in the Input and is not necessarily the same as the field value.
     */

    function getOptionLabelByValue(value: string) {
        let autocompleteOptions: ReactNode[] = [];

        Children.toArray(children).forEach((child) => {
            if (React.isValidElement(child)) {
                autocompleteOptions = child['props']['children'];
            }
        });

        const defaultOption = autocompleteOptions.find(
            (option) => option['props']?.['value'] === value || option['attributes']?.['value'] === value,
        );

        if (defaultOption) {
            return defaultOption['props']?.['optionLabel'] || defaultOption['attributes']?.['optionLabel'];
        } else {
            return '';
        }
    }

    const getFilteredAutocompleteResults = (inputValue: string) => {
        let autocompleteOptions: ReactNode[] = [];

        React.Children.toArray(children).forEach((child) => {
            if (React.isValidElement(child)) {
                autocompleteOptions = child['props']['children'];
            }
        });

        /**
         * ToDo:
         * This is due how builder app mishandles children.
         * Rather than having them as "children" it's handled as "components"
         */
        if (!autocompleteOptions) return [];

        const filteredOptions = autocompleteOptions.filter((grandChild) => {
            return getAttributeFromSchema(grandChild, 'optionLabel').toLowerCase().includes(inputValue.toLowerCase());
        });

        if (inputValue.length) {
            if (maxResults) {
                return filteredOptions.slice(0, maxResults);
            } else {
                return filteredOptions;
            }
        } else {
            return autocompleteOptions;
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!enableAutocomplete) return false;

        const inputValue = event.target.value;
        const filteredOptions = getFilteredAutocompleteResults(inputValue);

        setDisplayValue(inputValue);
        setFilteredOptions(filteredOptions);
        setShowDropdown(true);
    };

    const handleOptionClick = (value: string, optionLabel: string) => {
        setValue(value);
        setDisplayValue(optionLabel);

        getFilteredAutocompleteResults(value);
    };

    const handleResultsToggle = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <Styled.Autocomplete
            className={className}
            configStyles={configStyles}
            id={id}
            onClick={handleResultsToggle}
            ref={autocompleteRef}
            {...props}
        >
            {React.Children.toArray(children).map((child) => {
                return React.cloneElement(child as React.ReactElement, {
                    handleInputChange,
                    value,
                    displayValue,
                    setDisplayValue,
                    inputRef,
                    enableAutocomplete,
                    setShowDropdown,
                    showDropdown,
                    setValue,
                    children: filteredOptions,
                    handleOptionClick,
                });
            })}
        </Styled.Autocomplete>
    );
}

export type AutocompleteSearchbarProps = {
    className?: string | undefined;
    configStyles?: {};
    displayValue?: string;
    enableAutocomplete?: boolean;
    handleInputChange?: () => void;
    inputRef?: React.RefObject<HTMLInputElement>;
    placeholderText?: string;
    setDisplayValue?: (value: string) => void;
    setShowDropdown?: (value: boolean) => void;
    setValue?: (value: string) => void;
    value?: string;
    children?: any;
};

export function AutocompleteSearchbar({
    className,
    configStyles,
    displayValue,
    enableAutocomplete,
    handleInputChange,
    inputRef,
    placeholderText = 'Placeholder',
    setDisplayValue = () => {},
    setShowDropdown = () => {},
    setValue = () => {},
    value = '',
    children,
    ...props
}: AutocompleteSearchbarProps) {
    const handleClosableEvent = (event: { key: string }) => {
        if (event.key === 'Escape' || event.key === 'Tab') {
            setShowDropdown(false);
            // When display value is empty, clear field values
            if (value !== displayValue) {
                if (displayValue === '') {
                    setValue('');
                    setDisplayValue('');
                    // When display value is not empty but different, restore
                } else if (value !== displayValue) {
                    setDisplayValue(value);
                }
            }
        }
    };

    return (
        // * Note: This might be extended in the future to an editable div rather an input
        <Styled.AutocompleteSearchbar
            autoComplete="off"
            className={className}
            configStyles={configStyles}
            onChange={handleInputChange}
            onFocus={(e) => {
                if (!enableAutocomplete) {
                    e.target.blur();
                }
            }}
            onKeyDown={handleClosableEvent}
            placeholder={placeholderText}
            ref={inputRef}
            type="text"
            value={displayValue}
            {...props}
        />
    );
}

export type AutocompleteResultsProps = {
    children?: ReactNode | ReactNode[];
    className?: string | undefined;
    configStyles?: {};
    handleOptionClick?: any;
    showDropdown?: boolean;
    // ToDo: Figure out this builder issue with component
    component?: string;
};

export function AutocompleteResults({
    children,
    className,
    configStyles,
    handleOptionClick = (value: string, optionLabel: string) => {},
    showDropdown,
    ...props
}: AutocompleteResultsProps) {
    return (
        // ToDo: we do this due to an issue with builder app where data alias is used to select the component as readable
        <div {...props} data-alias="">
            {showDropdown ? (
                <Styled.AutocompleteResults configStyles={configStyles} className={className} {...props}>
                    {React.Children.toArray(children).map((option) => {
                        return React.cloneElement(option as React.ReactElement, {
                            handleOptionClick,
                        });
                    })}
                </Styled.AutocompleteResults>
            ) : null}
        </div>
    );
}

export type AutocompleteResultsOptionProps = {
    children?: any;
    configStyles?: {};
    handleOptionClick?: any;
    optionLabel?: string;
    value?: string;
};

export function AutocompleteResultsOption({
    value,
    configStyles,
    children,
    handleOptionClick = () => {},
    optionLabel,
    ...props
}: AutocompleteResultsOptionProps) {
    return (
        <Styled.AutocompleteOption
            configStyles={configStyles}
            onClick={() => handleOptionClick(value, optionLabel)}
            {...props}
        >
            {children}
        </Styled.AutocompleteOption>
    );
}
