import { PFButton, PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';

/**
 * Button component meant to be placed ahead of PFItemCard components in a grid
 * in order to call an action related to the data in that grid.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFActionCard properties.
 * @param {React.ReactNode} props.children - Child elements to be
 * rendered inside the new.
 * @param {string} [props.className] - Class name to be applied to the action
 * card.
 * @param {func} props.onClick - Function to be called when the card is clicked.
 * @param {bool} [props.loading] - Determines if the action card's is loading.
 *
 * @return {React.ReactElement} The new component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFActionCard = ({
  children,
  className,
  onClick,
  loading,
}) => {
  return (
    <PFButton
      type="none"
      className={className}
      onClick={onClick}
      loading={loading}
      padding="none">
      <PFContainer height={200} radius="l" width="100%"
        display="flex" flexDirection="column" justifyContent="center"
        background="var(--button-main-primary-background)">
        <PFContainer display="flex"
          alignItems="center" justifyContent="center" gap="s">
          {children}
        </PFContainer>
      </PFContainer>
    </PFButton>
  );
};

PFActionCard.defaultProps = {};

PFActionCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default PFActionCard;
