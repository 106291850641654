
import { EditorDropdownOptions } from '../../constants';

/**
 * Checks if a given event value is valid based on the provided lookup set.
 *
 * @param {string} eventValue - Event value provided from a user input
 * ideally (or anything else)
 * @param {string} optionType - The type of options to consider from
 * EditorDropdownOptions
 * @param {string[]} lookupSet - The set of valid values to check against.
 * @return {boolean} Returns true if the event value is valid, otherwise false.
 */
function isEventValueValid(eventValue, optionType, lookupSet) {
  const validValues = EditorDropdownOptions[optionType]
    .filter((option) => lookupSet.includes(option.value))
    .map((option) => option.value);
  return validValues.includes(eventValue);
}

export default isEventValueValid;
