import React, { ReactNode, Children } from 'react';
import * as Styled from './formfieldmetadata.styled';

export type FormfieldmetadataProps = {
    /**
     * a node to be rendered in Formfieldmetadata
     */
    children?: ReactNode | ReactNode[];

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * styling overrides
     */
    configStyles?: any;

    /**
     * Allows selecting the type of information to display based on the data object coming from Formik
     */
    display?: 'error' | 'success' | 'touched' | 'value';

    /**
     * To identify the component
     */
    id: string;

    /**
     * Based on the Formik response when validations are put to the test. Here, there's consideration for adding a new property to understand the details of which validation has failed
     *  - error
     *  - touched
     *  - value
     *  - fieldErrorDetails: {
     *                          - message: Error msg
     *                          - path: field name
     *                          - type: validation type
     *                          }
     */
    meta: {
        error?: string;
        touched?: boolean;
        value?: string;
        fieldErrorDetails?: {
            message?: string;
            path?: string;
            type?: string;
        };
    };

    /**
     * Determines that the content of the component will be displayed for a specific validation.
     */
    validationType?:
        | 'required'
        | 'min'
        | 'max'
        | 'length'
        | 'email'
        | 'url'
        | 'shape'
        | 'matches'
        | 'oneOf'
        | 'uuid'
        | 'typeError';
};

const formatChildrenByDisplay = (
    display: 'error' | 'success' | 'touched' | 'value' | undefined,
    meta: {
        error?: string;
        touched?: boolean;
        value?: string;
        fieldErrorDetails?: {
            message?: string;
            path?: string;
            type?: string;
        };
    },
    children: ReactNode | ReactNode[],
    validationType: FormfieldmetadataProps['validationType'],
) => {
    switch (display) {
        case 'error':
            if (meta?.touched && meta?.error) {
                if (validationType) {
                    if (validationType === meta?.fieldErrorDetails?.type)
                        return Children.toArray(children).length > 0 ? children : meta?.error;
                } else {
                    return Children.toArray(children).length > 0 ? children : meta?.error;
                }
            }
            break;
        case 'success':
            if (meta?.touched && meta?.value && !meta?.error) {
                return children;
            }
            break;
        case 'touched':
            if (meta?.touched) {
                return children || meta?.touched.toString();
            }
            break;
        case 'value':
            if (meta?.touched && meta?.value) {
                return children || meta?.value;
            }
            break;
        default:
            return children;
    }
};

export function Formfieldmetadata({
    children,
    className,
    configStyles,
    display,
    id,
    meta,
    validationType,
    ...props
}: FormfieldmetadataProps) {
    return (
        <Styled.FormFieldMetaData
            configStyles={configStyles}
            id={id}
            className={className}
            display={display}
            {...props}
        >
            {formatChildrenByDisplay(display, meta, children, validationType)}
        </Styled.FormFieldMetaData>
    );
}
