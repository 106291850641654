import PropTypes from 'prop-types';
import { useState } from 'react';

import { builderV2 } from 'PFApis';
import {
  PFButton,
  PFContainer,
  PFIcon,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { ROLES } from 'PFConstants';
import store, { currentFunctionActions } from 'PFStore';

const PermissionManagement = ({
  functionId,
  roleOptions,
  account,
  currentUserId,
  isUnique,
  singleOwner,
  updateAsyncCall,
  deleteAsyncCall,
  setSuccess
}) => {
  const [selectedRole, setSelectedRole] = useState(account.role);

  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
  };

  const roleHasChanged = account.role !== selectedRole;

  const updatePermission = () => {
    updateAsyncCall.asyncCall(async () => {
      const response = await builderV2.addFunctionPermission(functionId, {
        email: account.email,
        function_role: selectedRole,
      });
      setSuccess(response);
      store.dispatch(currentFunctionActions.updatePermission({
        _id: account._id,
        role: selectedRole,
      }));
    });
  };

  const deletePermission = () => {
    deleteAsyncCall.asyncCall(async () => {
      const response
        = await builderV2.removeFunctionPermission(functionId, account._id);
      setSuccess(response);
      store.dispatch(currentFunctionActions.removePermission(account._id));
    });
  };

  const isCurrentUser = account._id === currentUserId;
  const isOwner = account.role === 'OWNER';

  const canEdit = (isCurrentUser && !isUnique && !singleOwner) || !isOwner;
  const canDelete = !isUnique && ((isCurrentUser && !singleOwner) || !isOwner);

  return (
    <PFContainer key={account._id} display="flex"
      justifyContent="space-between" gap="s">
      <PFContainer display="flex" alignItems="center" gap="s">
        <PFButton type="none" width={46} height={46} style={{
          color: 'white', borderRadius: '50%',
          background: 'var(--button-main-primary-background)'
        }}>
          {account.full_name.charAt(0)}
        </PFButton>
        <PFContainer>
          <PFText>{account.full_name}</PFText>
          <PFText size="s" color="grey">{account.email}</PFText>
        </PFContainer>
      </PFContainer>
      <PFContainer display="flex" gap="s">
        {roleHasChanged
          && <PFButton
            onClick={updatePermission}
            type="secondary" width={100}
            loading={updateAsyncCall.loading}>
            Save
          </PFButton>}
        {canDelete
          && <PFButton
            onClick={deletePermission}
            type="support" padding="none"
            loading={deleteAsyncCall.loading}>
            <PFIcon icon="bx bx-trash" size="l" margin="top-xs" />
          </PFButton>}
        <PFContainer width={150}>
          <PFSelect
            value={selectedRole}
            onChange={handleRoleChange}
            options={isOwner ? ROLES.OPTIONS : roleOptions}
            disabled={!canEdit} />
        </PFContainer>
      </PFContainer>
    </PFContainer >
  );
};

PermissionManagement.propTypes = {
  functionId: PropTypes.string.isRequired,
  roleOptions: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isUnique: PropTypes.bool,
  singleOwner: PropTypes.bool,
  updateAsyncCall: PropTypes.object.isRequired,
  deleteAsyncCall: PropTypes.object.isRequired,
  setSuccess: PropTypes.func.isRequired,
};

export default PermissionManagement;
