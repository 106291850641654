import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton, PFInput, PFLine, PFSelect, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { QueryFilters } from '../Query/components';
import { SelectTables } from './components';


const TimeDelta = ({ index, instruction, setInstruction, isCreated }) => {
  const currentUserId = useSelector((state) => state.user._id);
  const { docs: databases } = useSelector(
    (state) => state.current_function.associated_dbs);
  const [asyncCall, , error, dismissError] = useAsyncCall(true);
  const [db, setDb] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { var_name, execute } = instruction;

  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });

  const setDeltaType = (type) => {
    if (type === 'scalar') {
      setExecute({
        ...execute,
        type,
        column2: ''
      });
    } else if (type === 'vector') {
      setExecute({
        ...execute,
        type,
        timestamp: ''
      });
    }
  };
  const setDatabase = (database) => setExecute({
    ...execute,
    database,
    table_name: '',
    column1: '',
    column2: '',
    timestamp: '',
    filters: []
  });
  const setTableName = (table_name) => setExecute({
    ...execute,
    table_name,
    column1: '',
    column2: '',
    timestamp: '',
    filters: []
  });
  const setColumn1 = (column1) => setExecute({ ...execute, column1 });
  const setColumn2 = (column2) => setExecute({ ...execute, column2 });
  const setTimestamp = (timestamp) => setExecute({ ...execute, timestamp });
  const setThreshold = (threshold_in_minutes) => setExecute({
    ...execute, threshold_in_minutes
  });
  const setFilters = (filters) => setExecute({ ...execute, filters });


  const databaseOptions = databases.map((db) => ({
    label: db.alias, value: db._id
  }));
  const tableOptions = db?.tables.map((table) => ({
    label: table.alias, value: table.table_name
  }));

  const columnOptions = db?.tables
    .find((table) => table.table_name
      === execute?.table_name)?.fields.map((column) => ({
        label: column.alias, value: column.column_name
      }));

  const filteredColumnOptions = db?.tables
    .find((table) => table.table_name
      === execute?.table_name)?.fields.map((column) => ({
        label: column.alias, value: '${' + execute?.database + '}'
          + `.${execute?.table_name}.${column.column_name}`
      }));

  useEffect(() => {
    asyncCall(async () => {
      if (!!execute.database) {
        const database = await builderTest(currentUserId)
          .getDatabase(execute.database);
        setDb(database);
      }
    });
  }, [execute?.database]);

  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  return (
    <>
      <PFContainer type="form" padding="m" radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex"
          justifyContent="space-between" alignItems="center">
          <PFContainer height={30} display="flex" alignItems="center" gap="s">
            {
              !isCollapsed && <PFIconButton
                icon="bx bx-chevron-left"
                onClick={() => setType('')} />
            }

            <PFText type="secondary">Instruction {index + 1}:</PFText>
            <PFText>Time Delta</PFText>
          </PFContainer>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed
              ? 'down' : 'up'}`}
              size='l' color='var(--purple-200)' />
          </PFButton>
        </PFContainer>

        <PFContainer display={isCollapsed ? 'none'
          : 'flex'} flexDirection='column' gap='m'>
          <PFLine />
          <PFContainer display="flex" alignItems="center" gap="m">
            <PFText type="secondary">
              Target variable
            </PFText>
            <PFContainer flex={1}>
              <PFInput value={var_name}
                onChange={(e) => setVarName(e.target.value)}
                placeholder="This is where your response will be stored" />
            </PFContainer>
          </PFContainer>
          <PFLine />
          <PFContainer display='flex' gap='s'
            alignItems='center'>
            <PFContainer width='100px'>
              <PFText>Delta type</PFText>
            </PFContainer>
            <PFContainer flex={1}>
              <PFSelect value={execute?.type} options={[
                { label: 'Column to column', value: 'vector' },
                { label: 'Column to value', value: 'scalar' }
              ]} onChange={(e) => setDeltaType(e.target.value)} />
            </PFContainer>

          </PFContainer>
          {
            execute?.type
              ? <>
                <PFLine />
                <SelectTables execute={execute} setTableName={setTableName}
                  setColumn1={setColumn1} setColumn2={setColumn2}
                  setTimestamp={setTimestamp}
                  setDatabase={setDatabase}
                  databaseOptions={databaseOptions}
                  tableOptions={tableOptions}
                  columnOptions={columnOptions} />
                <PFLine />
                <PFContainer display='flex' gap='s'
                  alignItems='center'>
                  <PFContainer >
                    <PFText>Time threshold</PFText>
                  </PFContainer>
                  <PFContainer flex={1}>
                    <PFInput value={execute?.threshold_in_minutes}
                      onChange={(e) => setThreshold(e.target.value)} />
                  </PFContainer>
                </PFContainer>
                {
                  execute?.table_name
                    ? <>
                      <PFLine />
                      <PFContainer>
                        <PFContainer display="flex" alignItems="center" gap="s">
                          <PFText>Filter by</PFText>
                          <PFText type="support" size="s">
                            Define the conditions needed to retrieve a record
                          </PFText>
                        </PFContainer>
                        <QueryFilters
                          filters={execute?.filters}
                          setFilters={setFilters}
                          columnOptions={filteredColumnOptions}
                        />
                      </PFContainer>
                    </>
                    : null
                }
              </>
              : null
          }

        </PFContainer>

      </PFContainer>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </>
  );
};

TimeDelta.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default TimeDelta;
