import { PFButton, PFContainer, PFIcon, PFSelect } from 'PFComponents/common';
import { PFDBEditorHeader } from 'PFComponents/navigation';
import { useConfirmation } from 'PFHooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


const TableEditorHeader = ({
  dbId,
  tables,
  currentTable,
  fetchRows,
  exportData,
  selectedRows,
  deleteSelectedRows,
  addRow,
  apiRef,
  setExitModeWarning
}) => {
  const navigate = useNavigate();
  const tableOptions = tables.map((table) => {
    return { label: table.alias, value: table.table_name };
  });
  const changeTable = (e) => {
    if (Object.keys(apiRef.current.state.editRows).length > 0) {
      setExitModeWarning(true);
    } else {
      const tableName = e.target.value;
      navigate(`/editor/databases/${dbId}/tables/${tableName}`);
    }
  };

  const backToDBs = () => {
    if (Object.keys(apiRef.current.state.editRows).length > 0) {
      setExitModeWarning(true);
    } else {
      navigate(`/databases/${dbId}/tables`);
    }
  };

  const exportTableToCSV = () => {
    if (Object.keys(apiRef.current.state.editRows).length > 0) {
      setExitModeWarning(true);
    } else {
      exportData({ fileName: currentTable });
    }
  };

  const [DeletionDialog, confirmDeletion] = useConfirmation(
    `Are you sure you want to delete the 
    ${selectedRows.length} rows selected?`
  );

  const deleteRows = async () => {
    if (Object.keys(apiRef.current.state.editRows).length > 0) {
      setExitModeWarning(true);
    } else if (await confirmDeletion()) {
      deleteSelectedRows();
    }
  };

  const btnOptions = {
    type: 'support',
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      aspectRatio: 1
    }
  };

  return (
    <PFDBEditorHeader backAction={backToDBs}>
      <PFContainer display="flex"
        alignItems="center" justifyContent="space-between">
        <PFContainer width={300}>
          <PFSelect
            className="header"
            prefix="Table:"
            value={currentTable}
            onChange={changeTable}
            options={tableOptions} />
        </PFContainer>
        <PFContainer display="flex" alignItems="center" gap="s">
          <PFButton onClick={() => {
            if (Object.keys(apiRef.current.state.editRows).length > 0) {
              setExitModeWarning(true);
            } else {
              fetchRows();
            }
          }} tooltip="Refresh data" {...btnOptions}>
            <PFIcon icon="bx bx-refresh" size="l" />
          </PFButton>
          <PFButton tooltip="Export to CSV" onClick={exportTableToCSV}
            {...btnOptions}>
            <PFIcon icon="bx bx-download" size="l" />
          </PFButton>
          <PFButton tooltip="Delete selected rows"
            onClick={deleteRows}
            disabled={selectedRows.length === 0}
            {...btnOptions}>
            <PFIcon icon="bx bx-list-minus" size="l" margin="left-xs" />
          </PFButton>
          <PFButton
            onClick={addRow} tooltip="Add row" {...btnOptions}>
            <PFIcon icon="bx bx-list-plus" size="l" margin="left-xs" />
          </PFButton>
        </PFContainer>
      </PFContainer>
      <DeletionDialog styles={{
        backgroundColor: 'var(--purple-500)'
      }} />
    </PFDBEditorHeader >
  );
};

TableEditorHeader.propTypes = {
  dbId: PropTypes.string.isRequired,
  tables: PropTypes.array.isRequired,
  currentTable: PropTypes.string.isRequired,
  fetchRows: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  deleteSelectedRows: PropTypes.func.isRequired,
  addRow: PropTypes.func.isRequired,
  apiRef: PropTypes.object,
  setExitModeWarning: PropTypes.func,
};

export default TableEditorHeader;
