/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { useReducer } from 'react';

import { PFContainer, PFLine } from 'PFComponents/common';
import {
  Background,
  Borders,
  BoxSizing,
  Dropshadow,
  Layout,
  Position,
  Size,
  Spacings,
  Typography
} from './components';
import { StyleRules } from './constants';

// eslint-disable-next-line max-len
import { useBreakpointContext } from 'src/screens/PFScreenPageEditor/components/Canvas/utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SECTION':
      return {
        ...state,
        [action.section]: !state[action.section],
      };
    default:
      return state;
  }
};

const defaultState = {
  background: true,
  borders: true,
  boxsizing: true,
  dropshadow: true,
  layout: true,
  position: true,
  size: true,
  spacing: true,
  typography: true,
};


const DesignEditor = ({ component, setComponent }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { activeBreakpoint } = useBreakpointContext();

  function handleActiveSection(section) {
    dispatch({ type: 'TOGGLE_SECTION', section });
  }

  function getComposedStyles({ activeBreakpoint, component }) {
    const componentRootStyles = Object.entries(
      component.attributes.configStyles)
      .filter((styleRule) => {
        if (!styleRule[0].includes('@media')) {
          return { ...styleRule };
        }
      });

    if (activeBreakpoint === null) {
      return {
        ...Object.fromEntries(componentRootStyles)
      };
    } else {
      return {
        ...Object.fromEntries(componentRootStyles),
        ...component.attributes.configStyles[activeBreakpoint['mediaQuery']],
      };
    }
  }

  function composeStyles({ composedAttributes }) {
    function removeEmptyAttributes(dirtyConfigStyles) {
      const result = {};
      for (const key in dirtyConfigStyles) {
        if (typeof dirtyConfigStyles[key] === 'object') {
          const nestedResult = removeEmptyAttributes(dirtyConfigStyles[key]);
          if (Object.keys(nestedResult).length > 0) {
            result[key] = nestedResult;
          }
        } else {
          if (dirtyConfigStyles[key] !== '') {
            result[key] = dirtyConfigStyles[key];
          }
        }
      }
      return result;
    }

    const composedStyles = composedAttributes.configStyles;

    let breakpointStyles;
    if (!!activeBreakpoint['mediaQuery']) {
      breakpointStyles = {
        [activeBreakpoint['mediaQuery']]: {
          ...component.attributes.configStyles[activeBreakpoint['mediaQuery']],
          ...composedStyles
        }
      };
    } else {
      breakpointStyles = { ...composedStyles };
    }

    const updatedComponent = {
      ...component,
      attributes: {
        ...component.attributes,
        configStyles: {
          ...removeEmptyAttributes({
            ...component.attributes.configStyles,
            ...breakpointStyles
          })
        }
      }
    };

    setComponent(updatedComponent);
  }

  return (
    <PFContainer className='pf-design-editor'>
      <PFLine />
      <Layout
        activeSection={state['layout']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Layout']}
        handleActiveSection={() => {
          handleActiveSection('layout');
        }}
      />
      <Spacings
        activeSection={state['spacing']}
        composeStyles={composeStyles}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        ruleSet={{
          'Margin': StyleRules['Margin'],
          'Padding': StyleRules['Padding'],
        }}
        handleActiveSection={() => {
          handleActiveSection('spacing');
        }}
      />
      <Size
        activeSection={state['size']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Size']}
        handleActiveSection={() => {
          handleActiveSection('size');
        }}
      />
      <Typography
        activeSection={state['typography']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Fonts']}
        handleActiveSection={() => {
          handleActiveSection('typography');
        }}
      />
      <Borders
        activeSection={state['borders']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Borders']}
        handleActiveSection={() => {
          handleActiveSection('borders');
        }}
      />
      <Position
        activeSection={state['position']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Position']}
        handleActiveSection={() => {
          handleActiveSection('position');
        }}
      />
      <BoxSizing
        activeSection={state['boxsizing']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['BoxSizing']}
        handleActiveSection={() => {
          handleActiveSection('boxsizing');
        }}
      />
      <Dropshadow
        activeSection={state['dropshadow']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Dropshadow']}
        handleActiveSection={() => {
          handleActiveSection('dropshadow');
        }}
      />
      <Background
        activeSection={state['background']}
        componentBreakpointStyles={getComposedStyles({
          activeBreakpoint,
          component
        })}
        composeStyles={composeStyles}
        ruleSet={StyleRules['Background']}
        handleActiveSection={() => {
          handleActiveSection('background');
        }}
      />
    </PFContainer>
  );
};

DesignEditor.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  breakpoint: PropTypes.string
};


export default DesignEditor;
