import {
  PFButton,
  PFContainer,
  PFIcon, PFModal, PFSelect,
  PFText
} from 'PFComponents/common';
import { PFAppEditorHeader } from 'PFComponents/navigation';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IntegrationTester from './IntegrationTester';


const FlowEditorHeader = ({ appId, apiId, save, saveLoading, saved,
  inputParameters }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const apiDeclarations = useSelector((state) =>
    state.current_app.build.api_declarations.docs);

  const apiDeclarationOptions = apiDeclarations.map((api) => {
    return { label: api.alias, value: api._id };
  });

  const changeApi = (e) => {
    const id = e.target.value;
    navigate(`/editor/apps/${appId}/integrations/${id}`);
  };

  const backToApps = () => {
    navigate(`/apps/${appId}/integrations`);
  };

  return (
    <PFAppEditorHeader backAction={backToApps}>
      <PFModal active={modalOpen} onDismiss={() => setModalOpen(false)}>
        {modalOpen ? (
          <IntegrationTester closeTester={() => {
            setModalOpen(false);
          }} integrationId={apiId} inputParameters={inputParameters}
          />
        ) : (
          <></>
        )}
      </PFModal>
      <PFContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <PFContainer display="flex" gap="s">
          <PFContainer width={300}>
            <PFSelect
              className="header"
              prefix="API:"
              value={apiId}
              onChange={changeApi}
              options={apiDeclarationOptions} />
          </PFContainer>
          <PFButton
            type="secondary"
            height={'auto'}
            width="120px"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Test
          </PFButton>
        </PFContainer>

        <PFContainer display="flex" gap="m">
          {!saveLoading && saved
            && <PFContainer
              display="flex" alignItems="center" gap="s">
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton type="secondary" width={80}
            onClick={save} loading={saveLoading}>
            <PFText>Save</PFText>
          </PFButton>
        </PFContainer>
      </PFContainer>
    </PFAppEditorHeader>
  );
};

FlowEditorHeader.propTypes = {
  appId: PropTypes.string.isRequired,
  apiId: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  inputParameters: PropTypes.array,
};

export default FlowEditorHeader;
