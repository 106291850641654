import {
  PFButton, PFContainer, PFIcon,
  PFInput, PFSelect
} from 'PFComponents/common';

const ParamTypeFields = ({ form, property, fieldHook,
  remove, inputTypes = [{
    type: 'input',
  },
  {
    type: 'input',
    width: '100%'
  }], keyName = 'key', valueName = 'value',
  keyLabel = 'Key', valueLabel = 'Value' }) => {
  const params = form.values[property] ?? [];

  const renderField = (inputType, baseFieldName, name, label, i) => {
    switch (inputType.type) {
      case 'select':
        return (
          <PFContainer width='200px' flex={inputType.flex && 1} >
            <PFSelect name={`${baseFieldName}.${name}`} fieldHook={fieldHook}
              label={i === 0 ? label : ''}
              options={inputType.options} />
          </PFContainer>
        );

      default:
        return (
          <PFInput name={`${baseFieldName}.${name}`} fieldHook={fieldHook}
            label={i === 0 ? label : ''} width={inputType.width} />
        );
    }
  };

  return params.map((_, i) => {
    const baseFieldName = `${property}[${i}]`;
    const removeField = () => remove(i);

    return (
      <PFContainer key={i} display="flex" gap="s" alignItems="flex-end">
        {renderField(inputTypes[0], baseFieldName, keyName, keyLabel, i)}
        {renderField(inputTypes[1], baseFieldName, valueName, valueLabel,
          i)}
        <PFButton type="support" onClick={removeField}>
          <PFIcon icon="bx bx-trash" size="l" />
        </PFButton>
      </PFContainer>
    );
  });
};

export default ParamTypeFields;
