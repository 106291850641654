import React, { ReactNode, useState, useRef, useEffect } from 'react';
import * as Styled from './content-slider.styled';
import styled from 'styled-components';

export type ContentSliderProps = {
    /**
     * semantic unique identifier attribute
     */
    id?: string | undefined;

    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode[];

    /**
     * configuration driven styling overrides
     */
    configStyles?: string | undefined;

    /**
     * React className
     */
    className?: string | undefined;

    /**
     * this enables scroll drag behavior on mouse or touch for the component
     */
    scrollOnDrag?: boolean;
};

export function ContentSlider({ children, configStyles, className, id, scrollOnDrag = false }: ContentSliderProps) {
    const contentSliderRef = useRef<any>();

    useEffect(() => {
        if (!scrollOnDrag) return;
        if (!contentSliderRef.current) return;

        /* Rather than attaching styling values we took the classic approach of
         *  editing the ref's scrollValue
         *
         *  Below the code shown is the classic position calculation of horizontal
         *  position approach, no need to innovate here
         */
        let isScrolling = false;
        let startX;
        let scrollLeft;

        const slideStart = (e: MouseEvent) => {
            isScrolling = true;
            startX = e.pageX - contentSliderRef.current.offsetLeft;
            scrollLeft = contentSliderRef.current.scrollLeft;
        };

        const slideEnd = () => {
            isScrolling = false;
        };

        const slideMove = (e) => {
            if (!isScrolling) return;
            e.preventDefault();
            const currentPosX = e.pageX - contentSliderRef.current.offsetLeft;
            const translationX = currentPosX - startX;

            contentSliderRef.current.scrollLeft = scrollLeft - translationX;
        };

        // For desktop devices
        contentSliderRef.current.addEventListener('mousedown', slideStart);
        contentSliderRef.current.addEventListener('mousemove', slideMove);
        contentSliderRef.current.addEventListener('mouseleave', slideEnd);
        contentSliderRef.current.addEventListener('mouseup', slideEnd);

        // For mobile devices
        contentSliderRef.current.addEventListener('touchstart', slideStart);
        contentSliderRef.current.addEventListener('touchmove', slideMove);
        contentSliderRef.current.addEventListener('touchend', slideEnd);

        return () => {
            if (contentSliderRef.current !== null) {
                contentSliderRef.current.removeEventListener('mousedown');
                contentSliderRef.current.removeEventListener('mousemove');
                contentSliderRef.current.removeEventListener('mouseleave');
                contentSliderRef.current.removeEventListener('mouseup');
                contentSliderRef.current.removeEventListener('touchstart');
                contentSliderRef.current.removeEventListener('touchmove');
                contentSliderRef.current.removeEventListener('touchend');
            }
        };
    }, [contentSliderRef]);

    const contentSliderNavigationHandler = (direction: string) => {
        const slideManualScroll =
            direction === 'left'
                ? contentSliderRef.current.scrollLeft - contentSliderRef.current.offsetWidth
                : contentSliderRef.current.scrollLeft + contentSliderRef.current.offsetWidth;
        contentSliderRef.current.scrollLeft = slideManualScroll;
    };

    return (
        <Styled.ContentSlider ref={contentSliderRef} id={id} configStyles={configStyles} className={className}>
            {children?.map((child, index) => {
                const childDirection =
                    child?.['props']?.['direction'] || child?.['props']?.['attributes']?.['direction'];
                if (childDirection) {
                    return React.cloneElement(child as any, {
                        key: `_contentSlider-${index}`,
                        _contentSliderClickHandler: () => {
                            contentSliderNavigationHandler(childDirection);
                        },
                    });
                } else {
                    return <div>{child}</div>;
                }
            })}
        </Styled.ContentSlider>
    );
}

export type ContentSliderNavigationButtonProps = {
    /**
     * a node to be rendered in the special component.
     */
    children?: ReactNode;

    /**
     * configuration driven styling overrides
     */
    configStyles?: string | undefined;

    /**
     * React className
     */
    className?: string | undefined;

    /**
     * direction of the slide
     */
    direction?: string;

    /**
     * Component: ContentSlider
     */
    _contentSliderClickHandler?: (direction: string) => {};
};

export function ContentSliderNavigationButton({
    children,
    configStyles,
    className,
    direction = '',
    _contentSliderClickHandler = (direction) => {
        return false;
    },
}: ContentSliderNavigationButtonProps) {
    return (
        <Styled.ContentSliderNavigationButton
            configStyles={configStyles}
            className={className}
            onClick={() => {
                _contentSliderClickHandler(direction);
            }}
        >
            {children}
        </Styled.ContentSliderNavigationButton>
    );
}
