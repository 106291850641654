import { images } from 'PFAssets';
import { PFButton, PFContainer, PFLink } from 'PFComponents/common';
import store, { authActions } from 'PFStore';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const PFDashboardHeader = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const logout = () => {
    store.dispatch(authActions.removeAccessToken());
    navigate('/');
  };
  const [toggleProfileOptions, setToggleProfileOptions] = useState(false);

  return (
    <PFContainer type="main"
      display="flex" justifyContent="space-between" alignItems="center"
      padding="horizontal-l vertical-m">
      <PFLink to="/">
        <img src={images.pefaiLogo} alt="Pefai" height={30} />
      </PFLink>

      <PFContainer style={{ position: 'relative' }}>
        <PFButton type="primary" width={40} height={40}
          onClick={() => {
            setToggleProfileOptions((prev) => !prev);
          }}>
          {user.info.first_name.charAt(0)}
        </PFButton>
        {
          toggleProfileOptions && <PFContainer width='220px'
            border='1px solid var(--purple-200)' radius='m'
            background='var(--purple-saturated)'
            style={{
              position: 'absolute', right: '0', top: '50px',
              zIndex: '100'
            }}>
            <PFLink height={40} to='/profile/update-password'
              color='var(--white)'
              style={{
                fontWeight: '400', width: '100%'
              }}>
              <PFContainer padding='top-m right-m left-m bottom-s' style={{
                fontSize: 'var(--text-size-m)'
              }}>
                Update password
              </PFContainer>
            </PFLink>
            <PFButton type="support" height={40} onClick={logout}
              padding='right-m bottom-s left-m'
              style={{
                color: 'var(--failure)', fontWeight: '400',
                fontSize: 'var(--text-size-m)', textAlign: 'start',
                maxWidth: '100%', boxSizing: 'content-box'
              }}>
              Log out
            </PFButton>
          </PFContainer>
        }

      </PFContainer>
    </PFContainer>
  );
};

export default PFDashboardHeader;
