import { authPefai } from 'PFApis';
import { images } from 'PFAssets';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFInput,
  PFLink,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const SignupForm = ({ setCurrentUser }) => {
  const [signupForm, fieldHook] = useForm({
    first_name: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a name'
    },
    last_name: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide a last name'
    },
    email: {
      default_value: '',
      validation_type: 'email',
      error_message: 'Please provide an email'
    },
    password: {
      default_value: '',
      validation_type: 'password',
      error_message: 'Please provide a password'
    },
    confirm_password: {
      default_value: '',
      validation_type: 'confirmation',
      params: {
        referenceValue: 'password',
        originValue: 'confirm_password'
      },
      error_message: 'Password does not match'
    }
  });
  const [asyncCall, signupLoading, signupError, errorDismiss] = useAsyncCall();
  const navigate = useNavigate();
  const signup = (values) => {
    asyncCall(async () => {
      await authPefai.createAccount({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        username: values.email,
        password: values.password
      });
      setCurrentUser({
        username: values.email,
        password: values.password
      });
      navigate('/signup/verify-email');
    });
  };

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="center" margin='top-xl bottom-xl'>
      <PFForm form={signupForm} submit={signup} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="center" gap="s">
          <PFLink to='/'>
            <img src={images.pefaiLogo} alt="Pefai" height={50} />
          </PFLink>
          <PFText tag="h2" weight={400} margin="top-xl bottom-l">
            Welcome!
          </PFText>
          <PFInput placeholder="Name" name="first_name"
            fieldHook={fieldHook}
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFInput placeholder="Last name" name="last_name"
            fieldHook={fieldHook}
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFInput placeholder="Email" name="email"
            fieldHook={fieldHook}
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFInput placeholder="Create your password" name="password"
            fieldHook={fieldHook} type='password'
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)' size='s'>1 uppercase</PFText>
            <PFText color='var(--purple-100)' size='s'>1 lowercase</PFText>
            <PFText color='var(--purple-100)' size='s'>1 symbol</PFText>
            <PFText color='var(--purple-100)' size='s'>1 number</PFText>
            <PFText color='var(--purple-100)' size='s'>
              8 characters at least</PFText>
          </PFContainer>
          <PFInput placeholder="Confirm your password" name="confirm_password"
            fieldHook={fieldHook} type='password'
            width="100%" margin="bottom-m"
            style={{ height: 50 }} />
          <PFButton submit loading={signupLoading}
            width="100%" height={50}
            style={{ borderRadius: 25 }}>
            Sign Up
          </PFButton>

          <PFLink to='/'
            margin='top-xl bottom-m' style={{
              width: '100%', height: '50px', display: 'block'
            }}>
            <PFContainer radius='l' padding='top-s bottom-s' style={{
              borderWidth: '2px', borderStyle: 'solid',
              borderColor: '#7105B3', color: 'var(--white)', height: '100%',
              textAlign: 'center', fontWeight: '400'
            }}>
              Sign In
            </PFContainer>
          </PFLink>
          <PFLink type='primary' color='var(--purple-150)' style={{
            fontWeight: '400'
          }}>
            Join the waitlist here</PFLink>
        </PFContainer>
      </PFForm>
      <PFAlert message={signupError}
        open={!!signupError} onClose={errorDismiss} />
    </PFContainer>
  );
};

SignupForm.propTypes = {
  setCurrentUser: PropTypes.func,
};

export default SignupForm;
