import {
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton, PFLine,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Scenario } from './components';


const Conditional = ({ index, instruction, setInstruction, isCreated }) => {
  const { execute } = instruction;
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });

  const { operands_list, flow_list } = execute;
  const setOperandsList
    = (operands_list) => setExecute({ ...execute, operands_list });
  const setFlowList
    = (flow_list) => setExecute({ ...execute, flow_list });
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  const addScenario = () => {
    operands_list.push(['AND', { value1: '', type: 'EQUALS', value2: '' }]);
    flow_list.splice(operands_list.length - 1, 0, []);
    setOperandsList(operands_list);
    setFlowList(flow_list);
  };

  const renderScenarios = () => {
    const scenariosLength = operands_list.length;
    const scenarios = [];

    for (let i = 0; i < scenariosLength; i++) {
      const props = {
        key: i, index: i,
        operand: operands_list[i],
        setOperand: (operand) => {
          operands_list[i] = operand;
          setOperandsList(operands_list);
        },
        flow: flow_list[i],
        setFlow: (flow) => {
          flow_list[i] = flow;
          setFlowList(flow_list);
        },
        remove: () => {
          operands_list.splice(i, 1);
          flow_list.splice(i, 1);
          setOperandsList(operands_list);
          setFlowList(flow_list);
        }
      };

      scenarios.push(<Scenario {...props} />);
    };

    scenarios.push(
      (
        <PFContainer key="add" display="flex" justifyContent="center">
          <PFButton type="support" onClick={addScenario}>
            + add scenario
          </PFButton>
        </PFContainer>
      ),
      (
        <Scenario key="default"
          flow={flow_list[scenariosLength]}
          setFlow={(flow) => {
            flow_list[scenariosLength] = flow;
            setFlowList(flow_list);
          }} />
      )
    );

    return scenarios;
  };

  return (
    <>
      <PFContainer type="form" padding="m" radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex"
          justifyContent="space-between" alignItems="center">
          <PFContainer height={30} display="flex" alignItems="center" gap="s">
            {
              !isCollapsed && <PFIconButton
                icon="bx bx-chevron-left"
                onClick={() => setType('')} />
            }
            <PFText type="secondary">Instruction {index + 1}:</PFText>
            <PFText>Conditional</PFText>
          </PFContainer>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed
              ? 'down' : 'up'}`} size='l' color='var(--purple-200)' />
          </PFButton>
        </PFContainer>
        <PFContainer display={isCollapsed
          ? 'none' : 'flex'} flexDirection='column' gap='m'>
          <PFLine />
          <PFContainer display="flex" flexDirection="column" gap="m">
            {renderScenarios()}
          </PFContainer>
        </PFContainer>

      </PFContainer>
    </>
  );
};

Conditional.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default Conditional;
