import { PFButton, PFContainer, PFLine, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';
import SourceSelector from './SourceSelector';


const DynamicDataSelector = ({ dynamicData, setDynamicData,
  showCacheable = false }) => {
  const addDynamicData = () => {
    setDynamicData([...dynamicData, {
      source: 'API_DECLARATION',
      reference: '',
      params: {}
    }]);
  };

  const renderDynamicData = () => {
    return dynamicData.map((data, i) => {
      const setData = (newData) => {
        dynamicData.splice(i, 1, newData);
        setDynamicData(dynamicData);
      };
      const removeData = () => {
        dynamicData.splice(i, 1);
        setDynamicData(dynamicData);
      };

      return (
        <React.Fragment key={i}>
          <SourceSelector
            data={data}
            setData={setData}
            removeData={removeData}
            showCacheable={showCacheable} />
          <PFLine />
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer padding="horizontal-s vertical-m">
      <PFText margin="bottom-m" style={{ fontSize: 'var(--text-header)' }}>
        Dynamic data</PFText>
      <PFContainer display="flex" flexDirection="column" gap="m">
        {renderDynamicData()}
        <PFButton width="100%" type="secondary" onClick={addDynamicData}
          style={{ fontSize: 'var(--text-label)' }}>
          + add dynamic data
        </PFButton>
      </PFContainer>
    </PFContainer>
  );
};

DynamicDataSelector.propTypes = {
  dynamicData: PropTypes.array.isRequired,
  setDynamicData: PropTypes.func.isRequired,
  showCacheable: PropTypes.bool,
};

export default DynamicDataSelector;
