import { useState } from 'react';

/**
 * Hook to perform asynchronous calls while keeping track of loading and error
 * states.
 *
 * @param {boolean} [default_loading=false] - Determines if the initial loading
 * state.
 * @param {any} [default_error=null] - Determines if the initial error value.
 *
 * @return {[(), boolean, any, ()]} Call function, loading state, error state
 * and error dismiss.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const useAsyncCall = (default_loading = false, default_error = null) => {
  const [loading, setLoading] = useState(default_loading);
  const [error, setError] = useState(default_error);

  const asyncCall = async (funcBody) => {
    setLoading(true);
    setError(null);

    try {
      await funcBody();
    } catch (err) {
      console.error(err);
      setError(err);
      setLoading(false);
      return err;
    }

    setLoading(false);
    return null;
  };

  const dismissError = () => {
    setError(null);
  };

  return [asyncCall, loading, error, dismissError];
};

export default useAsyncCall;
