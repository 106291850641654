import { builderV2 } from 'PFApis';
import {
  PFButton,
  PFContainer,
  PFIcon, PFLoader, PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import curly from '../assets/curly.svg';

const tabs = [
  {
    label: 'Forms',
    value: 'form_vars'
  },
  {
    label: 'Process flows',
    value: 'flow_vars'
  },
  {
    label: 'URL params',
    value: 'url_vars'
  },
  {
    label: 'Custom',
    value: 'custom_vars'
  }

];

const DictionaryItem = ({
  setCopiedAlert,
  variableItem,
  setFlowPrefix,
  currentTab,
  closeDictionary,
  setNodeType, setCopyOptions,
  setPromptInfo,
  asyncCall
}) => {
  const { var_name, var_value, reference, nodeType, ...rest } = variableItem;
  const currentApp = useSelector((state) => state.current_app);
  const apiIntegrations = useSelector((state) => state
    .current_app.build.api_declarations.docs);

  const handleItemClick = (payload) => {
    if (currentTab === 'flow_vars') {
      asyncCall(async () => {
        const flow = await builderV2.
          getFlow(currentApp._id, reference);
        const node = flow.nodes[rest.node];
        const apiIntegration = apiIntegrations.find((api) => api._id
          === node.api_declaration);
        switch (nodeType) {
          case 'API_CALL':
            setPromptInfo({
              type: node.type,
              name: apiIntegrations.find((api) => api._id
                === node.api_declaration).alias,
              nodeAlias: node.alias,
              path: `/editor/apps/${currentApp
                ._id}/integrations/${apiIntegration._id}`
            });
            const integrationVariables = await builderV2.
              getIntegrationVariables(currentApp._id, node.api_declaration);
            setCopyOptions(integrationVariables);

            break;

          case 'FUNCTION_CALL':
            const operation = await builderV2.
              getFunctionOperation(node.function_id, node.operation_id);
            setPromptInfo({
              type: node.type,
              name: operation.name,
              nodeAlias: node.alias,
              path:
                /* eslint-disable max-len */
                `/editor/functions/${node.function_id}/operations/${node.operation_id}`
            });
            const functionVariables = await builderV2.
              getFunctionVariables(node.function_id, node.operation_id);
            setCopyOptions(functionVariables);
            break;

          default:
            break;
        }
      });
    }

    if (/\${[^}]+}/.test(payload)) {
      setNodeType(nodeType);
      setFlowPrefix(payload);

      return;
    };
    setFlowPrefix(null);
    setNodeType('');
    setCopiedAlert(true);
    setTimeout(() => {
      setCopiedAlert(false);
    }, 3000);
    generalUtils.copyToClipboard(payload);
    closeDictionary();
  };

  const renderItem = () => {
    switch (currentTab) {
      case 'flow_vars':
        return (
          <PFContainer flex={1}>
            <PFText color='#fff'>
              {rest.node_name} - {rest.node}{var_value.includes('status')
                && ' - HTTP Code'}
              {var_value.includes('message')
                && ' - Message'}
              {var_value.includes('error')
                && ' - Error'}
            </PFText>
            <PFText color={'var(--gray-100)'} size='s'>
              {rest.name}
            </PFText>
          </PFContainer>
        );

      case 'url_vars':
        return (
          <PFContainer flex={1}>
            <PFText color='#fff'>{var_name}</PFText>
            <PFText color={'var(--gray-100)'} size='s'>
              {rest.name}
            </PFText>
          </PFContainer>
        );

      default:
        return (
          <PFContainer flex={1}>
            <PFText color='#fff'>{var_name}</PFText>
            <PFText color={'var(--gray-100)'} size='s'>
              {reference}
            </PFText>
          </PFContainer>
        );
    }
  };

  return (
    <div
      className='var_item'
      style={{
        padding: 'var(--size-s)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 'var(--size-m)',
        borderRadius: 'var(--radius-s)',
        cursor: 'pointer'
      }}
      onClick={() => handleItemClick(var_value)}>
      <PFContainer
        background='#2C263E'
        radius='s'
        width='fit-content'
        padding='s'>
        <img width={'20px'} src={curly} />
      </PFContainer>
      {
        renderItem()
      }

      <PFIcon
        icon={`bx bx-${/\${[^}]+}/.test(var_value)
          ? 'right-arrow-alt' : 'copy'}`} color={'var(--gray-100)'}
        size='l' />
    </div>
  );
};

const DictionaryTable = ({
  currentTab,
  setCurrentTab,
  loading,
  catalogVars,
  setCatalogVars,
  setFlowPrefix,
  setCopiedAlert,
  filterValue,
  closeDictionary,
  setNodeType,
  setCopyOptions,
  setPromptInfo,
  asyncCall
}) => {
  const filteredVariables = () => {
    return catalogVars.filter(({ var_name, reference, ...rest }) => {
      const loweredFilterValue = filterValue?.toLowerCase();
      const loweredReference = reference?.toLowerCase();

      if (currentTab === 'flow_vars') {
        const parts = rest.var_value.split('.');
        const lastWord = parts[parts.length - 1];
        if (loweredReference.includes(loweredFilterValue)
          || rest.node_name?.toLowerCase().includes(loweredFilterValue)
          || rest.node?.toLowerCase().includes(loweredFilterValue)
          || rest.name?.toLowerCase().includes(loweredFilterValue)) {
          return (true);
        }
        if (rest.var_value.includes('status')
          || rest.var_value.includes('message')) {
          if (lastWord.toLowerCase().includes(loweredFilterValue)) {
            return (true);
          }
        }
        if (rest.var_value.includes('status')
          && 'http code'.includes(loweredFilterValue)) {
          return true;
        }
      } else {
        if (loweredReference.includes(loweredFilterValue)
          || var_name.toLowerCase().includes(loweredFilterValue)) {
          return (true);
        }
      }
    });
  };

  const DictionaryTabs = () => {
    return (
      <PFContainer
        border='1px solid var(--purple-300)'
        display='flex'
        style={{
          borderLeft: 'none',
          borderRight: 'none'
        }}>
        {
          tabs.map((tab) => {
            return (
              <PFButton
                key={tab.value}
                padding='s'
                height='auto'
                style={{
                  borderBottom: `1px solid ${currentTab === tab.value
                    ? '#fff' : 'var(--purple-300)'}`
                }}
                onClick={() => {
                  if (currentTab !== tab.value) {
                    setCurrentTab(tab.value);
                    setCatalogVars([]);
                    setFlowPrefix(null);
                  }
                }}>
                <PFText
                  color={currentTab === tab.value
                    ? '#fff' : 'var(--gray-100)'}>{tab.label}</PFText>
              </PFButton>
            );
          })
        }
      </PFContainer>
    );
  };


  return (
    <>
      <DictionaryTabs />
      <PFContainer
        className='vars_container'
        padding='s'
        height='260px'
        position='relative'>
        {
          loading
            ? <PFContainer style={{
              position: 'absolute',
              left: '50%',
              top: 'calc(50% + 50px)',
              transform: 'translate(-50%, -50%)'
            }}>
              <PFLoader color='var(--purple-100)' />
            </PFContainer>
            : <>
              {
                catalogVars.length > 0
                  ? filteredVariables().map((variableItem, index) => {
                    return (
                      <DictionaryItem
                        key={index}
                        variableItem={variableItem}
                        setCopiedAlert={setCopiedAlert}
                        setFlowPrefix={setFlowPrefix}
                        currentTab={currentTab}
                        closeDictionary={closeDictionary}
                        setNodeType={setNodeType}
                        setCopyOptions={setCopyOptions}
                        setPromptInfo={setPromptInfo}
                        asyncCall={asyncCall} />
                    );
                  })
                  : <></>
              }
            </>
        }


      </PFContainer>
    </>
  );
};

DictionaryTable.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  loading: PropTypes.bool,
  catalogVars: PropTypes.array,
  setCatalogVars: PropTypes.func,
  setFlowPrefix: PropTypes.func,
  setCopiedAlert: PropTypes.func,
  filterValue: PropTypes.string,
  closeDictionary: PropTypes.func,
  setNodeType: PropTypes.func,
  setCopyOptions: PropTypes.func,
  setPromptInfo: PropTypes.func,
  asyncCall: PropTypes.func,
};
DictionaryItem.propTypes = {
  variableItem: PropTypes.object,
  setCopiedAlert: PropTypes.func,
  setFlowPrefix: PropTypes.func,
  currentTab: PropTypes.string,
  closeDictionary: PropTypes.func,
  setNodeType: PropTypes.func,
  setCopyOptions: PropTypes.func,
  setPromptInfo: PropTypes.func,
  asyncCall: PropTypes.func,
};

export default DictionaryTable;
