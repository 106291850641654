import { chrones, twoapp } from 'PFApis';
import { PFAlert, PFContainer, PFLoader } from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AutomationEditor, AutomationEditorHeader,
  AutomationEditorSidebar, AutomationNameEditor
} from './components';

const PFScreenAutomationEditor = () => {
  const currentApp = useSelector((state) => state.current_app);
  const appId = currentApp._id;
  const { automation_id } = useParams();
  const [jobs, setJobs] = useState([]);
  const [saved, setSaved] = useState(false);
  const [automation, setAutomation] = useState(null);

  const [jobAsyncCall, jobLoading, jobError, dismissJobError]
    = useAsyncCall(false);

  const [saveAsyncCall, saveLoading, saveError, dismissSaveError]
    = useAsyncCall(false);

  const [errorMsg, setErrorMsg] = useState(null);

  const currentJob = jobs.find((jobElement) => jobElement.id == automation_id);

  const jobOptions = jobs.map((jobElement) => ({
    label: jobElement.alias,
    value: jobElement.id
  }));

  const updateAutomation = (automation) => {
    setSaved(false);
    setAutomation(automation);
  };
  const saveAutomation = () => {
    const values = {};
    automation.input_params.forEach((item) => {
      values[item.name] = item.value;
    });

    const resultObject = {
      values: values,
      form_id: 'variables'
    };

    saveAsyncCall(async () => {
      const dataRes = await chrones.updateJob(appId, currentJob.id, {
        ...automation,
        input_params: resultObject
      });

      if (dataRes.status >= 400 && dataRes.status <= 600) {
        setErrorMsg(dataRes.message);
        return;
      }
      setSaved(true);
    });
  };

  useEffect(() => {
    jobAsyncCall(async () => {
      const result = await twoapp.getJobs(appId);
      setJobs(result ? result : []);
      const currentAutomation = result
        .find((jobElement) => jobElement.id == automation_id);
      try {
        const parsedObject = currentAutomation?.input_params;
        const values = parsedObject.values;

        if (values && Object.keys(values).length > 0) {
          const resultArray = Object.keys(values).map((key) => ({
            name: key,
            value: values[key]
          }));

          const parsedAutomation = {
            ...currentAutomation,
            input_params: resultArray
          };

          setAutomation(parsedAutomation);
        } else {
          const parsedAutomation = {
            ...currentAutomation,
            input_params: []
          };

          setAutomation(parsedAutomation);
        }
      } catch (error) {
        const parsedAutomation = {
          ...currentAutomation,
          input_params: []
        };

        setAutomation(parsedAutomation);
      }
    });
  }, [automation_id]);
  return (
    <>
      <AutomationEditorHeader save={saveAutomation} saved={saved}
        saveLoading={saveLoading} automation={automation}
        setAutomation={setAutomation} jobOptions={jobOptions} />
      <PFContainer display="flex" flex={1} alignItems='stretch'>
        <AutomationEditorSidebar jobs={jobs} />

        <PFContainer flex={1}>
          <PFContainer width="100%" height="100%" padding="l">
            {jobLoading
              ? <PFLoader area color="var(--primary)" />
              : <PFContainer display='flex' margin='left-m'>
                <PFContainer flex='1'>
                  <AutomationNameEditor
                    automation={automation}
                    updateAutomation={updateAutomation} />

                  <PFContainer padding="top-l"
                    display="flex" justifyContent="center">
                    <AutomationEditor automation={automation}
                      appId={appId} updateAutomation={updateAutomation} />
                  </PFContainer>
                </PFContainer>
              </PFContainer>
            }
          </PFContainer>
        </PFContainer>


      </PFContainer >
      <PFAlert
        message={jobError}
        open={!!jobError}
        onClose={dismissJobError} />
      <PFAlert
        message={saveError}
        open={!!saveError}
        onClose={dismissSaveError} />
      <PFAlert
        message={errorMsg}
        open={!!errorMsg}
        onClose={() => setErrorMsg(null)} />
    </>
  );
};

export default PFScreenAutomationEditor;
