import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  APICall,
  Arithmetic,
  AssignValue,
  Conditional,
  GenAI,
  InsertRecord,
  Javascript,
  Query,
  Selector,
  TimeDelta,
  UpdateRecord
} from './components';
import './InstructionsEditor.css';

const emptyInstruction = { var_name: '', type: '', execute: {} };

const InstructionsEditor = ({ operation, updateOperation }) => {
  const [isCreated, setIsCreated] = useState(false);
  const instructions = operation.instructions;
  const setInstructions = (value) => {
    updateOperation({
      ...operation,
      instructions: value
    });
  };

  const appendInstruction = () => {
    setInstructions([...instructions, emptyInstruction]);
  };

  const getInstructionForm = (index, instruction) => {
    const setInstruction = (value) => {
      instructions[index] = value;
      setInstructions(instructions);
    };

    const props = { index, instruction, setInstruction, isCreated };

    switch (instruction.type) {
      case 'select_query':
        return <Query {...props} />;
      case 'update_sql':
        return <UpdateRecord {...props} />;
      case 'insert_sql':
        return <InsertRecord {...props} />;
      case 'assign':
        return <AssignValue {...props} />;
      case 'arithmetic':
        return <Arithmetic {...props} />;
      case 'api_call':
        return <APICall {...props} />;
      case 'custom_code':
        switch (instruction.execute.language) {
          case 'Javascript':
            return <Javascript {...props} />;
        }
      case 'conditional':
        return <Conditional {...props} />;
      case 'ai_generated_code':
        return <GenAI {...props} operationId={operation._id} />;
      case 'time_delta':
        return <TimeDelta {...props} />;
      default:
        return (<Selector
          setIsCreated={setIsCreated} {...props} />);
    }
  };

  const renderInstructions = () => {
    return instructions.map((instruction, i) => {
      const prependInstruction = () => {
        instructions.splice(i, 0, emptyInstruction);
        setInstructions(instructions);
      };
      const removeInstruction = () => {
        instructions.splice(i, 1);
        setInstructions(instructions);
      };

      return (
        <React.Fragment key={i}>

          <PFContainer type="editor" display="flex" justifyContent="center"
            margin='bottom-m'>
            <PFButton onClick={prependInstruction} type="support">
              <PFText size='l' color='var(--pruple-100)'>+</PFText>
            </PFButton>
          </PFContainer>
          <PFContainer className="instruction-container" margin='bottom-m'>
            {getInstructionForm(i, instruction)}
            <PFContainer type="editor" background="none"
              className="instruction-options">
              <PFButton type="support" onClick={removeInstruction}>
                <PFIcon icon="bx bx-trash" size="l" />
              </PFButton>
            </PFContainer>
          </PFContainer>
        </React.Fragment>
      );
    });
  };

  return (
    <PFContainer display="flex" flexDirection="column" gap="xs">
      {renderInstructions()}
      <PFContainer type="editor" display="flex" justifyContent="center">
        <PFButton onClick={appendInstruction} type="support">
          <PFText size='l' color='var(--pruple-100)'>+</PFText>
        </PFButton>
      </PFContainer>
    </PFContainer>
  );
};

InstructionsEditor.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
};

export default InstructionsEditor;
