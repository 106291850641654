import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TreeLevel from './TreeLevel';

const TreeNode = ({ canvas, index, parent, node, setNode }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { component, build } = node;
  const { id, alias } = build;

  const selected = id === canvas.selected?.id;
  const hasChildren = !!node.children && node.children.length > 0;

  const isStep = component === 'Step';
  const selectedStep = isStep && (canvas.getStep(parent.id) ?? 0) === index;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSelection = () => {
    const iframe = document.getElementById('responsive-frame');
    const iframeContent = iframe.contentDocument
      || iframe.contentWindow.document;

    if (!isStep) {
      const canvasElement = iframeContent.getElementById(id);
      if (!!canvasElement) canvasElement.click();
    } else {
      canvas.setStep(parent.id, index);
      if (parent.build.id !== canvas.selected?.id) {
        const multistepElement = iframeContent.getElementById(parent.build.id);
        if (!!multistepElement) multistepElement.click();
      }
    }
  };

  const onDragStart = (e) => {
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
  };

  return (
    <PFContainer style={{ position: 'relative' }}>
      <PFContainer style={{
        position: 'absolute', top: 0,
        height: 24, background: selected ? '#FFF1' : 'none',
        pointerEvents: 'none'
      }} />
      <PFContainer
        display="flex" height={24}
        config={{
          draggable: true, onDragStart, onDragOver, onDrop,
        }}>
        {hasChildren
          ? (
            <PFButton type="none" onClick={handleToggle}
              padding="none" margin="right-xs">
              <PFIcon color="white"
                icon={`bx bx-chevron-${isExpanded ? 'down' : 'right'}`} />
            </PFButton>
          )
          : (
            <PFContainer width={20} height={20} style={{ padding: 7 }}>
              <PFContainer background='var(--secondary)'
                width="100%" height="100%" />
            </PFContainer>
          )}
        <PFButton type="none" onClick={handleSelection} padding="none">
          <PFContainer display="flex" alignItems="center" gap="xs">
            <PFText
              style={{
                display: 'inline-block',
                textWrap: 'nowrap'
              }}
              size="s" color={selectedStep ? 'var(--secondary)' : ''}>
              {alias}
            </PFText>
            {isStep && (
              <PFIcon icon="bx bx-play"
                color={selectedStep ? 'var(--secondary)' : 'white'} />
            )}
            {component === 'Multistep' && (
              <PFIcon icon="bx bxs-collection bx-rotate-90"
                color="white" size="s" />
            )}
          </PFContainer>
        </PFButton>
      </PFContainer >
      {hasChildren && (
        <PFContainer>
          {isExpanded && (
            <PFContainer style={{ marginLeft: '7px' }}>
              <TreeLevel
                canvas={canvas}
                parent={node}
                nodes={node.children}
                setNodes={(children) => setNode({ ...node, children })}
                sideline />
            </PFContainer>
          )}
        </PFContainer>
      )}
    </PFContainer >
  );
};

TreeNode.propTypes = {
  canvas: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  parent: PropTypes.object,
  node: PropTypes.object.isRequired,
  setNode: PropTypes.func.isRequired,
};

export default TreeNode;
