import { twoapp } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFLoader,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutomationSelectionModal } from './copmonents';

const PFScreenAppAutomations = () => {
  const currentApp = useSelector((state) => state.current_app);
  const appId = currentApp?._id;
  const [jobs, setJobs] = useState([]);
  const [jobAsyncCall, jobLoading, jobError, dismissJobError]
    = useAsyncCall(false);

  useEffect(() => {
    jobAsyncCall(async () => {
      const result = await twoapp.getJobs(currentApp._id);
      setJobs(result ? result : []);
    });
  }, []);

  const [selectionOpen, setSelectionOpen] = useState(false);

  const renderFunctions = () => {
    return jobs.map((job) => {
      return (
        <PFContainer key={job?.id} style={{
          position: 'relative'
        }}>
          {
            job?.is_active
              ? <PFContainer style={{
                position: 'absolute',
                left: '30px',
                top: '20px',
                zIndex: 1000
              }} display='flex' gap='s'
                alignItems='center'>

                <PFContainer width='10px' radius='l'
                  height='10px' background='var(--success)'>

                </PFContainer>
                <PFText size='s'>
                  Active
                </PFText>
              </PFContainer>
              : <PFContainer style={{
                position: 'absolute',
                left: '30px',
                top: '20px',
                zIndex: 1000
              }} display='flex' gap='s'
                alignItems='center'>

                <PFContainer width='10px' radius='l'
                  height='10px' background='var(--failure)'>

                </PFContainer>
                <PFText size='s'>
                  Inactive
                </PFText>
              </PFContainer>
          }
          <PFItemCard
            path={`/editor/apps/${appId}/automations/${job?.id}`}
            options={{}}>
            <PFText padding="top-m">{job.alias}</PFText>
            <PFText type="secondary" size="s" margin="top-s">
              id: {job?.id}
            </PFText>
          </PFItemCard>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Automations</PFText>
        </PFContainer>

        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={() => setSelectionOpen(true)}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Automation</PFText>
          </PFActionCard>
          {
            jobLoading
              ? <PFContainer display='flex' justifyContent='center'>
                <PFLoader color={'var(--purple-200)'} />
              </PFContainer>
              : renderFunctions()
          }
        </PFContainer>


      </PFContainer>
      <AutomationSelectionModal active={selectionOpen}
        onDismiss={() => setSelectionOpen(false)}
        jobs={jobs} />
      <PFAlert
        message={jobError}
        open={!!jobError}
        onClose={dismissJobError} />
    </PFContainer>
  );
};

export default PFScreenAppAutomations;
