import PropTypes from 'prop-types';

/**
 * Draws a line with a corner radius. Line should always be drawn from top-left
 * to bottom-right corners to work.
 *
 * @param {Object} props - FlowLink properties.
 * @param {Object} props.id - ID that identifies the line connector.
 * @param {{ x: int, y: int }} props.sourcePosition - Coordinates of the line's
 * starting position.
 * @param {{ x: int, y: int }} props.targetPosition - Coordinates of the line's
 * ending position.
 * @param {number} [props.turnOffset=0] - Distance offset to where the line
 * should turn in case the destination is at a different height.
 * @param {number} [props.cornerRadius=10] - Corner radius that the line will
 * use (if possible).
 *
 * @return {React.ReactElement} Line connector component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const FlowLink = ({
  id,
  sourcePosition,
  targetPosition,
  turnOffset,
  cornerRadius
}) => {
  // Set the offset for the positions based on the scroll position
  const source = {
    x: sourcePosition.x + window.scrollX,
    y: sourcePosition.y + window.scrollY
  };
  const target = {
    x: targetPosition.x + window.scrollX,
    y: targetPosition.y + window.scrollY
  };

  const controlX = (source.x + target.x) / 2 - turnOffset;
  const controlY = source.y;

  // Calculate the distances between source and target positions
  const xDistance = Math.abs(target.x - source.x);
  const yDistance = Math.abs(target.y - source.y);

  // Check if straight line should be drawn
  const shouldDrawStraightLine = xDistance < 2 * cornerRadius
    || yDistance < 2 * cornerRadius;

  // Construct the path data string
  const pathData = shouldDrawStraightLine
    ? `
        M ${source.x} ${source.y}
        L ${target.x} ${target.y}
      `
    : `
        M ${source.x} ${source.y}
        H ${controlX - cornerRadius}
        Q ${controlX} ${controlY} ${controlX} ${controlY + cornerRadius}
        V ${target.y - cornerRadius}
        Q ${controlX} ${target.y} ${controlX + cornerRadius}
          ${target.y}
        H ${target.x}
      `;

  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: 0, left: 0,
        width: target.x + 5, height: target.y + 5,
      }}>
      <path
        fill="none"
        stroke="var(--secondary)"
        strokeWidth="3"
        d={pathData}
      />
    </svg>
  );
};

FlowLink.defaultProps = {
  id: 'line-connector',
  turnOffset: 0,
  cornerRadius: 20,
};

FlowLink.propTypes = {
  id: PropTypes.string,
  sourcePosition: PropTypes.object.isRequired,
  targetPosition: PropTypes.object.isRequired,
  turnOffset: PropTypes.number,
  cornerRadius: PropTypes.number,
};

export default FlowLink;
