import { PFContainer, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';


/**
 * Renders the element preceding a node depending on the content of the node
 * that leads to it.
 *
 * @param {Object} props - Node suffix props.
 * @param {string?} suffix - Suffix to be used for the node.
 *
 * @return {React.ReactElement} - Node suffix component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeSuffix = ({ suffix, type }) => {
  if (!suffix) return (<></>);

  return (
    <PFContainer
      width={type === 'hidden' ? 25 : 140}
      style={{
        marginTop: type === 'hidden' ? 0 : 14,
        marginLeft: type === 'hidden' ? 0 : '60px'
      }}
    >
      <PFText color="var(--secondary)" size="s" weight="bold"
        style={{
          opacity: type === 'hidden' ? 0 : 1,
          textAlign: 'right',
          paddingBottom: 7
        }}>
        {suffix}
      </PFText>
    </PFContainer>
  );
};

NodeSuffix.propTypes = {
  suffix: PropTypes.string,
  type: PropTypes.string
};

export default NodeSuffix;
