import { PFContainer, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';

const ComponentCard = ({ canvas, comp, setOpenDrawer }) => {
  const draggableConfiguration = {
    draggable: true,
    onDragStart: () => {
      canvas.setDragging(comp.create());
      canvas.setSelected(null);
    },
    onDragEnd: () => {
      canvas.setDropZone(null);
      canvas.setDragging(null);
      canvas.setSelected(document.getElementById(canvas.lastElementAdded));
      setOpenDrawer(null);
    },
  };

  return (
    <PFContainer padding="vertical-m horizontal-s"
      radius="m" background="#231D32"
      config={draggableConfiguration}
      style={{
        cursor: 'pointer'
      }}>
      <PFText size="s">{comp.name}</PFText>
    </PFContainer >
  );
};

ComponentCard.propTypes = {
  canvas: PropTypes.object.isRequired,
  comp: PropTypes.object.isRequired,
  setOpenDrawer: PropTypes.func,
};

export default ComponentCard;
