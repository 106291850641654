import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AppFunctionEnvironmentModal = ({
  active,
  onDismiss,
  functionEnv,
  setFunctionEnv,
  functionEnvName,
  functionEnvs
}) => {
  const navigate = useNavigate();
  const environmentsOptions = functionEnv.envs ? functionEnv.envs
    .map((envElement) => ({
      label: envElement, value: envElement
    })) : [];

  const { env: currentEnv } = functionEnv;
  const currentApp = useSelector((state) => state.current_app);
  const setCurrentEnv = (env) => setFunctionEnv({
    ...functionEnv,
    env
  });

  const [connectAsyncCall, connectLoading, connectError, dismissConnectError]
    = useAsyncCall(false);

  const connectFunctions = () => {
    const functionsCopy = [...functionEnvs].map((functionElement) => {
      if (functionEnv._id === functionElement._id) {
        return functionEnv;
      }
      return functionElement;
    });

    connectAsyncCall(async () => {
      await builderV2.appAddEnv(currentApp._id, functionEnvName, functionsCopy);
      navigate(0);
    });
  };

  const renderFilteredFunctions = () => {
    return environmentsOptions.map((envElement) => {
      const selected = currentEnv === envElement.value;

      const handleSelection = () => {
        setCurrentEnv(envElement.value);
      };

      return (
        <PFButton key={envElement.value} type="none" onClick={handleSelection}>
          <PFContainer height={100} radius="m" width="100%" padding="m"
            display="flex" flexDirection="column" justifyContent="end"
            background="var(--strokes)"
            style={{ position: 'relative', textAlign: 'left' }}
            border={selected ? '1px solid var(--secondary)' : 'none'}>
            <PFContainer background={selected ? 'var(--secondary)' : 'white'}
              display='flex' alignItems='center' justifyContent='center'
              border={selected ? 'none' : '1px solid var(--secondary)'}
              style={{
                position: 'absolute', top: 15, right: 15,
                width: 24, borderRadius: '50%', aspectRatio: 1
              }}>
              <PFIcon icon="bx bx-check" color="white" />
            </PFContainer>
            <PFText size="s">{envElement.label}</PFText>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">
              Select the function environment to connect
            </PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="s">
            {renderFilteredFunctions()}
          </PFContainer>
          <PFContainer margin="top-l" display="flex" justifyContent="end">
            <PFButton onClick={connectFunctions}
              loading={connectLoading}
              padding="horizontal-xl"
              disabled={environmentsOptions.length === 0}>
              Confirm connection
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert
        message={connectError}
        open={!!connectError}
        onClose={dismissConnectError} />
    </>
  );
};

AppFunctionEnvironmentModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  functionEnv: PropTypes.object,
  setFunctionEnv: PropTypes.func,
  functionEnvName: PropTypes.string,
  functionEnvs: PropTypes.array,
};

export default AppFunctionEnvironmentModal;
