import { useCallback, useEffect } from 'react';


/**
 * Hotkey/shortcut keyboard event handler for iframe implementations
 *
 * Note: Since we are reading for event.key for action key,
 * combinations for 'alt+{any key} results in modified key which's
 * character does not represent the passed hotkey config.
 *
 * When keydowned,
 * e.g. "alt+s" = "alt+ß"
 *
 * @param {*} hotKey a string that indicates user keyboard shortcuts with 1 or 2
 * modifiers in the format of "modifier + modifier + actionkey", or
 * "modifier + actionkey" or "actionkey"
 * @param {*} callback a function to be invoked when hotkeys are detected
 * @param {*} iframeid the id of the iframe's target element
 * @return {void}
 *
 *
 * ToDo:
 *
 * - the current useShortcut can be migrated with this approach to handle a
 * single concern
 * - this can be turned into a context provider to avoide multiple entries
 * of the shortcut implementation
 * - chords
 *
 */
const useIframeShortcuts = (hotKey, callback, iframeid) => {
  let firstModifier;
  let secondModifier;
  let actionKey;
  const hotkeysSplit = hotKey.split('+').map((s) => s.trim().toLowerCase());

  /**
   * This is a hard implementation of only 2 modifiers + an action key;
   * based off the most basic hot key shortcuts of common apps.
  *
  * e.g. ctrl+z (undo), ctrl+shift+z(redo), delete (delete action)
  *
  * How ever, this can be further expanded to unlimited (as in 3 modifiers)
  * modifiers by the fact that the last character is our action key.
  */
  if (hotkeysSplit.length === 3) {
    [firstModifier, secondModifier, actionKey] = hotkeysSplit;
  } else if (hotkeysSplit.length === 2) {
    [firstModifier, actionKey] = hotkeysSplit;
  } else if (hotkeysSplit.length === 1) {
    [actionKey] = hotkeysSplit;
  }

  if (!iframeid || !actionKey) return;

  const handleKeyPress = useCallback(
    (event) => {
      /**
       * This is to prevent browser's native hot key behaviors. Not recommended
       * for everything, in the future let's think of a way to gracefully handle
       * this by specific events.
       *
       * This will block browser's page save event only.
       */
      if (hotKey === 'ctrl+s') {
        event.preventDefault();
      }

      /**
       * Our app's specific base modifiers.
       */
      const isCtrlKey = event.ctrlKey || event.metaKey;
      const isAltKey = event.altKey;
      const isShiftKey = event.shiftKey;

      /**
       * This serves the purpose to weed out similar "shortcut" values
       * e.g. ctrl+z, ctrl+shift+z, as executing the last one will trigger
       * both hooks if implemented.
       *
       * Note: this can be abstracted further.
       */
      const pressedModifiers = [
        isCtrlKey && 'ctrl',
        isAltKey && 'alt',
        isShiftKey && 'shift',
      ].filter(Boolean).join('+');

      /**
       * Tricky, this serves 2 purposes, match whatever has been pressed.
       * single key: no pressed modifiers and no shortcut modifiers (true)
       * with modifiers: match what's pressed with what's configured (true)
       */
      const areModifiersMatched
        = pressedModifiers
        === [firstModifier, secondModifier].filter(Boolean).join('+');

      const isKeyMatched = !actionKey || event.key.toLowerCase() === actionKey;

      if (areModifiersMatched && isKeyMatched) {
        callback(event);
      }
    },
    [actionKey, callback, hotKey]
  );


  useEffect(() => {
    const handleKeyEvent = (event) => {
      if (event.target.tagName.toLowerCase() === 'textarea') return event;
      handleKeyPress(event);
    };
    const responsiveFrame = document.getElementById(iframeid);

    if (!responsiveFrame) return;

    /**
     * Bound to iframes content window key down event to be able to catch
     * event .keyCode, metaKey, ctrlKey, shiftKey
     *
     */
    if (responsiveFrame && responsiveFrame.contentWindow) {
      responsiveFrame.contentWindow.addEventListener('keydown', handleKeyEvent);
    }

    return () => {
      if (responsiveFrame && responsiveFrame.contentWindow) {
        responsiveFrame.contentWindow.removeEventListener('keydown',
          handleKeyEvent);
      }
    };
  }, [handleKeyPress, iframeid, actionKey, firstModifier, secondModifier]);
};

export default useIframeShortcuts;
