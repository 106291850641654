import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFContainer,
  PFIcon, PFLink,
  PFLoader, PFText
} from 'PFComponents/common';
import {
  PFDashboardHeader
} from 'PFComponents/navigation';
import { useAsyncCall } from 'PFHooks';
import {
  PFScreenAppAutomations,
  PFScreenAppDomainDetail,
  PFScreenAppDomains,
  PFScreenAppEnvDetail,
  PFScreenAppEnvs,
  PFScreenAppFlows,
  PFScreenAppFunctions,
  PFScreenAppIntegrations,
  PFScreenAppPages
} from 'PFScreens';
import store, { currentAppActions } from 'PFStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { AppDashboardActions, AppDashboardSidebar } from './components';


const PFLayoutAppDashboard = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const currentApp = useSelector((state) => state.current_app);
  const { app_id } = useParams();

  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);

  useEffect(() => {
    asyncCall(async () => {
      const app = await builderV2.getApp(app_id);
      store.dispatch(currentAppActions.setCurrentApp(app));
    });
  }, [app_id]);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <PFDashboardHeader />
        {loading || !currentApp
          ? <PFLoader area color="white" />
          : <PFContainer padding="horizontal-l vertical-m">
            <PFContainer display='flex' flexDirection='column'>
              <PFContainer>
                <PFLink to="/">
                  <PFContainer display="flex" alignItems="center">
                    <PFIcon icon="bx bx-chevron-left" size="l"
                      color='var(--purple-100)' />
                    <PFText type="secondary" color='var(--purple-100)'>
                      Back to all apps</PFText>
                  </PFContainer>
                </PFLink>
              </PFContainer>
              <PFContainer display='flex'
                justifyContent='space-between' gap='m'>
                <PFContainer>
                  <PFText tag="h1" size="l">{currentApp.info.name}</PFText>
                  <PFText size="s">{currentApp._id}</PFText>
                </PFContainer>
                <AppDashboardActions />
              </PFContainer>

            </PFContainer>
            <PFContainer display='flex' margin='top-l'>
              <AppDashboardSidebar />
              <PFContainer flex={1} margin='left-l'>
                <PFContainer>
                  <Routes>
                    <Route path="/pages"
                      element={<PFScreenAppPages />} />
                    <Route path="/integrations"
                      element={<PFScreenAppIntegrations />} />
                    <Route path="/flows"
                      element={<PFScreenAppFlows />} />
                    <Route path="/functions"
                      element={<PFScreenAppFunctions />} />
                    <Route path="/automations"
                      element={<PFScreenAppAutomations />} />
                    <Route path="/environments"
                      element={<PFScreenAppEnvs />} />
                    <Route path="/environments/:env_name"
                      element={<PFScreenAppEnvDetail />} />
                    <Route path="/domains"
                      element={<PFScreenAppDomains />} />
                    <Route path="/domains/:domain"
                      element={<PFScreenAppDomainDetail />} />
                  </Routes>
                </PFContainer>
              </PFContainer>
            </PFContainer>


          </PFContainer>
        }
      </PFContainer>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default PFLayoutAppDashboard;
