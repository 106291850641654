
import { PROCESS_FLOW } from 'PFConstants';

export const CUSTOM_SAVE_TYPE = PROCESS_FLOW.NODE_TYPE_ACTION_OPTIONS
  .find((n) => n.type === 'SAVE_IN_SESSION_CUSTOM');
export const addParam = (form) => {
  const newConfig = form.values;
  newConfig.params.push({ variableName: '', value: '' });
  form.setConfig(newConfig);
};

export const removeParam = (form, index) => {
  const newConfig = form.values;
  newConfig.params.splice(index, 1);
  form.setConfig(newConfig);
};

export const saveNode = (values, node, nodeName, asyncNode,
  updateNode, goBack) => {
  const formattedParams = values.params.reduce((transformedObject, param) => {
    return {
      ...transformedObject,
      [param.variableName]: param.value
    };
  }, {});

  const updatedNode = { ...node };
  updatedNode.alias = nodeName ?? 'Custom session node';
  updatedNode.params = formattedParams;
  updatedNode.async = asyncNode;

  updateNode(updatedNode);
  goBack();
};

export const formConfigFromParams = (params) => {
  const newConfig = { params: [] };
  for (const variableName in params) {
    newConfig.params.push({ variableName, value: params[variableName] });
  };
  if (newConfig.params.length === 0) {
    newConfig.params.push({ variableName: '', value: '' });
  }
  return newConfig;
};
