/**
 * Gets all links available for a process flow node (includes links to add new
 * nodes).
 *
 * @param {ProcessFlowNode} node - Node to get links from.
 * @return {NodeLink[]} - List of links attached to the node.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
export const getNodeLinks = (node) => {
  const link = (id, prevId, suffix) => {
    const displayId = id ?? `${prevId}_${suffix}_NEW`;
    return { id, displayId, prevId, suffix };
  };

  const links = [];
  if (node?.type === 'CONDITIONAL_IFELSE') {
    if (node.then?.type === 'NODE') {
      links.push(link(node.then.value, node._id, 'TRUE'));
    }
    if (node.else?.type === 'NODE') {
      links.push(link(node.else.value, node._id, 'FALSE'));
    }
  } else if (node?.type === 'CONDITIONAL_SWITCH') {
    node.conditionals.forEach((scenario, i) => {
      links.push(link(scenario.match_node, node._id, `SCENARIO ${i + 1}`));
    });
    if (node.default?.type === 'NODE') {
      links.push(link(node.default.value, node._id, 'DEFAULT'));
    }
  } else {
    links.push(link(node.next, node._id, null));
  };

  return links;
};

/**
 * Iterates a process flow structure recursively for it to be rendered.
 *
 * @param {Object<string, ProcessFlowNode>} nodes - Map holding the nodes
 * information.
 * @param {string?} nodeId - Id of the current node.
 * @param {string?} prevId - Id of the previous node.
 * @param {string?} suffix - Reference to the action that follows the previous
 * node (used in conditionals).
 * @param {int} row - Indicates the current row that the node will use.
 * @param {int} column - Indicates the column that the node will live in.
 * @param {NodeCallback} func - Function to be called for each node
 * iterated.
 *
 * @return {int} - Last row visited by iterating the node
 *
 * @author Andres Barragan <andres@pefai.com>
 */
export const traverseFlowRecursive = (
  nodes,
  nodeId,
  prevId,
  suffix,
  row,
  column,
  func,
) => {
  if (!nodeId) {
    func({ type: 'NEW', _id: `${prevId}_${suffix}_NEW` },
      { prevId, links: [], row, column, suffix, plus: true });
    return row;
  };

  const node = nodes[nodeId];
  if (!node) return row;

  const linksInfo = getNodeLinks(node);
  const links = linksInfo.map((l) => l.displayId);

  if (suffix !== null) {
    const isSwitchSuffix
      = suffix.includes('SCENARIO') || suffix === 'DEFAULT';
    const isIfElseSuffix = suffix === 'TRUE' || suffix === 'FALSE';

    func({
      type: 'NEW',
      _id: `${prevId}_${suffix}${isSwitchSuffix ? '_SWTCH'
        : isIfElseSuffix ? '_IFELSE' : ''}_NEW`,
      next: node._id
    },
      {
        prevId,
        links,
        row,
        column: column - 1,
        suffix,
        plus: false
      });
  };

  func(node, {
    prevId,
    links,
    row,
    column,
    suffix,
    type: 'hidden',
    plus: false
  });

  if (!links.some(
    (link) => link.includes('_NEW'))
    && !node.then
    && !node.default) {
    const previousId = node._id;
    const nextId = node.next;
    func(
      {
        type: 'NEW',
        _id: `${previousId}_${suffix}_NEW`,
        next: nextId
      },
      {
        prevId: previousId,
        links,
        row,
        column: column + 1,
        plus: false
      });
  }

  linksInfo.forEach(({ id, suffix }, i) => {
    if (i > 0) row++;
    row = traverseFlowRecursive(
      nodes,
      id,
      node._id,
      suffix,
      row,
      column + 2,
      func);
  });

  return row;
};

/**
 * Iterates a process flow structure by running a callback function for each
 * node traversed.
 *
 * @param {ProcessFlow} flow - Process flow to be traversed
 * @param {NodeCallback} func - Function to be called for each node
 * iterated.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const traverseFlow = (flow, func) => {
  traverseFlowRecursive(
    flow.nodes,
    flow.root_node,
    null,
    null,
    1,
    1,
    func
  );
};

/**
 * Function to add a node to a process flow.
 *
 * @param {ProcessFlowNode} node - Node to be added to the process flow.
 * @param {{prevId: string, suffix: string?}} config - Information on the
 * previous node.
 * @param {ProcessFlow} flow - Process flow to be updated
 * @param {func} setFlow - Function to update the process flow
 */
const addNode = (node, config, flow, setFlow) => {
  const { prevId, suffix } = config;
  const newFlow = { ...flow };

  if (prevId) {
    if (!suffix) {
      newFlow.nodes[prevId].next = node._id;
    } else {
      switch (suffix) {
        case 'TRUE':
          newFlow.nodes[prevId].then = { type: 'NODE', value: node._id };
          break;
        case 'FALSE':
          newFlow.nodes[prevId].else = { type: 'NODE', value: node._id };
          break;
        case 'DEFAULT':
          newFlow.nodes[prevId].default = { type: 'NODE', value: node._id };
          break;
        default:
          const [, i] = suffix.split(' ');
          newFlow.nodes[prevId].conditionals[parseInt(i) - 1].match_node
            = node._id;
          break;
      }
    }
  } else {
    newFlow.root_node = node._id;
  }

  newFlow.nodes[node._id] = node;

  setFlow(newFlow);
};

/**
 * Function to update a node in a process flow.
 *
 * @param {ProcessFlowNode} node - Node to be updated in the process flow.
 * @param {ProcessFlow} flow - Process flow to be updated
 * @param {func} setFlow - Function to update the process flow
 */
const updateNode = (node, flow, setFlow) => {
  const newFlow = { ...flow };
  newFlow.nodes[node._id] = node;
  setFlow(newFlow);
};

/**
 * Function to remove a node from a process flow.
 *
 * @param {ProcessFlowNode} node - Node to be removed.
 * @param {{prevId: string }} config - Information on the previous node.
 * @param {ProcessFlow} flow - Process flow to be updated.
 * @param {func} setFlow - Function to update the process flow.
 */
const removeNode = (node, config, flow, setFlow) => {
  const { prevId, suffix } = config;
  const newFlow = { ...flow };

  let nextId = null;
  if (node.type === 'CONDITIONAL_IFELSE') {
    if (node.then?.type === 'NODE') nextId = node.then.value;
  } else if (node.type === 'CONDITIONAL_SWITCH') {
    if (node.conditionals.length > 0) nextId = node.conditionals[0].match_node;
  } else {
    nextId = node.next;
  };
  if (prevId) {
    if (!suffix) {
      newFlow.nodes[prevId].next = nextId;
    } else {
      switch (suffix) {
        case 'TRUE':
          newFlow.nodes[prevId].then = { type: 'NODE', value: nextId };
          break;
        case 'FALSE':
          newFlow.nodes[prevId].else = { type: 'NODE', value: nextId };
          break;
        case 'DEFAULT':
          newFlow.nodes[prevId].default = { type: 'NODE', value: nextId };
          break;
        default:
          const [, i] = suffix.split(' ');
          newFlow.nodes[prevId].conditionals[parseInt(i) - 1].match_node
            = nextId;
          break;
      }
    }
  } else {
    newFlow.root_node = nextId;
  }

  delete newFlow.nodes[node._id];
  setFlow(newFlow);
};

export default {
  traverseFlow,
  getNodeLinks,
  addNode,
  updateNode,
  removeNode,
};

/**
 * @typedef {Object} ProcessFlow
 * @property {string} _id
 * @property {string} alias
 * @property {string?} root_node
 * @property {ProcessFlowNode[]} nodes
 */

/**
 * @typedef {Object} ProcessFlowNode
 * @property {string} _id
 * @property {string} type
 * @property {string} alias
 * @property {string?} next
 * @property {string?} action
 * @property {string?} api_declaration
 * @property {string?} suffix
 * @property {(string|Object)?} params
 * @property {{ expression: string, match_node: string? }[]} conditionals
 * @property {{ type: string, value: string? }} then
 * @property {{ type: string, value: string? }} else
 * @property {{ type: string, value: string? }} default
 */

/**
 * @typedef {Object} NodeLink
 * @property {string?} id
 * @property {string} displayId
 * @property {string} prevId
 * @property {string?} suffix
 */

/**
 * Function to be called to return the information of an iterated node.
 *
 * @callback NodeCallback
 * @param {ProcessFlowNode} node - Node iterated
 * @param {{
 * prevId: string?,
 * links: string[],
 * row: int,
 * column: int,
 * suffix: string?,
 * plus: bool
 * }} config - Node render configuration.
 */
