import axios from 'axios';
import { twoappOrigin } from 'PFConfig/env';
import { storageUtils } from 'PFUtils';

const builderV2 = axios.create({
  baseURL: twoappOrigin + '/twoapp/v1/builder-v2'
});

builderV2.interceptors.request.use(
  async (config) => {
    const jwt = storageUtils.retrieveFromLocalStorage('access_token');
    if (jwt) config.headers.set('Authorization', 'Bearer ' + jwt);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

builderV2.interceptors.response.use(
  async (res) => {
    return res?.data?.data;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location.href = '/';
    }
    console.error(err);
    return Promise.reject(err.response?.data?.data ?? err.message);
  }
);

export const builderTest = (userId) => {
  const builderV2_2 = axios.create({
    baseURL: twoappOrigin + '/twoapp/v1/dbs/v1',
    headers: {
      post: {
        'User-Id': userId
      },
      patch: {
        'User-Id': userId
      },
      get: {
        'User-Id': userId
      },
      put: {
        'User-Id': userId
      },
      delete: {
        'User-Id': userId
      }
    }
  });
  builderV2_2.interceptors.request.use(
    async (config) => {
      const jwt = storageUtils.retrieveFromLocalStorage('access_token');
      if (jwt) config.headers.set('Authorization', 'Bearer ' + jwt);
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  builderV2_2.interceptors.response.use(
    async (res) => {
      return res?.data?.data;
    },
    (err) => {
      console.error(err);
      return Promise.reject(err.response?.data?.data ?? err.message);
    }
  );

  return ({
    // DATABASES
    createDatabase: async (databaseData) =>
      await builderV2_2.post(`/db-handler/dbs/`, databaseData),
    getDatabase: async (dbId) =>
      await builderV2_2.get(`/db-handler/dbs/${dbId}`),
    createDBTable: async (dbId, tableData) =>
      await builderV2_2.post(`/db-handler/dbs/${dbId}/tables/`, tableData),
    addDBTableColumn: async (dbId, tableName, columnData) =>
      await builderV2_2.post(
        `/db-handler/dbs/${dbId}/tables/${tableName}/columns/`,
        { field: columnData }),
    updateDBTableColumn: async (dbId, tableName, columnData) =>
      await builderV2_2.patch(
        `/db-handler/dbs/${dbId}/tables/${tableName}/columns/`,
        { field: columnData }),
    addDBTableColumnFk: async (dbId, tableName, columnData) =>
      await builderV2_2.post(
        `/db-handler/dbs/${dbId}/tables/${tableName}/columns/fk`,
        { field: columnData }),
    updateDBTableColumnFk: async (dbId, tableName, columnData) =>
      await builderV2_2.patch(
        `/db-handler/dbs/${dbId}/tables/${tableName}/columns/fk`,
        { field: columnData }),
    updateDBTableRecord: async (dbId, tableName, rowId, rowUpdates) =>
      await builderV2_2.put(
        /* eslint-disable max-len */
        `/db-handler/dbs/${dbId}/tables/${tableName}/records/${rowId}`, rowUpdates),
    removeDBTableColumn: async (dbId, tableName, columnName) =>
      await builderV2_2.delete(
        `/db-handler/dbs/${dbId}/tables/${tableName}/columns/${columnName}`),
    insertDBTableRecord: async (dbId, tableName, row) =>
      await builderV2_2.post(
        `/db-handler/dbs/${dbId}/tables/${tableName}/records/`, row),
    getDBTableRecords: async (dbId, tableName) =>
      await builderV2_2.get(
        `/db-handler/dbs/${dbId}/tables/${tableName}/records/`),
    deleteDBTable: async (dbId, tableName) =>
      await builderV2_2.delete(`/db-handler/dbs/${dbId}/tables/${tableName}`),
    deleteDBTableRecord: async (dbId, tableName, rowId) =>
      await builderV2_2.delete(`/db-handler/dbs/${dbId}/tables/${tableName}/records/${rowId}`),
    deleteDBTableRecords: async (dbId, tableName, ids) =>
      await builderV2_2.patch(
        `/db-handler/dbs/${dbId}/tables/${tableName}/records/`, { ids }),
    publishDatabase: async (dbId, env) =>
      await builderV2_2.post(
        `/db-handler/dbs/${dbId}/publish/${env}`),
  });
};

export default {
  // USER
  getUserPreview: async (email) =>
    await builderV2.post(`/account/view`, { email }),
  getUserApps: async (userId) =>
    await builderV2.get(`/account/${userId}/apps`),

  // APPS
  getApp: async (appId) => {
    const app = await builderV2.get(`/apps/${appId}`);
    app.domains = await builderV2.get(`/apps/${appId}/domains/`);
    return app;
  },
  updateApp: async (appId, appData) =>
    await builderV2.put(`/apps/${appId}`, appData),
  publishApp: async (appId, domains) =>
    await builderV2.post(`/apps/${appId}/publish`, { domains }),
  addAppPermission: async (appId, settings) =>
    await builderV2.put(`/apps/${appId}/permissions`, settings),
  removeAppPermission: async (appId, accId) =>
    await builderV2.delete(`/apps/${appId}/permissions/${accId}`),
  returnToAppVersion: async (appId, domain, version) =>
    await builderV2.post(`/apps/${appId}/revert`, { domain, version }),
  appAddEnv: async (appId, env, functions) =>
    await builderV2.post(`/apps/${appId}/envs/functions`, { env, functions }),
  getFunctionEnvs: async (appId, env_name) =>
    await builderV2.get(`/apps/${appId}/envs/${env_name}/functions`),

  // ENVS
  createAppEnv: async (appId, name) =>
    await builderV2.post(`/apps/${appId}/envs/`, { name }),
  getAppEnv: async (appId, name) =>
    await builderV2.get(`/apps/${appId}/envs/${name}`),
  updateAppEnv: async (appId, name, envData) =>
    await builderV2.put(`/apps/${appId}/envs/${name}`, envData),
  deleteAppEnv: async (appId, name) =>
    await builderV2.delete(`/apps/${appId}/envs/${name}`),

  // PAGES
  getAppPages: async (appId) =>
    await builderV2.get(`/apps/${appId}/pages/`),
  createPage: async (appId, pageData) =>
    await builderV2.post(`/apps/${appId}/pages/`, pageData),
  getPageConfig: async (appId, builderPath) =>
    await builderV2.get(`/apps/${appId}/pages/config/${builderPath}`),
  getPagePublishedVersion: async (Domain, path) =>
    await builderV2.get(`/page${path}`, { headers: { Domain } }),
  updatePageBlob: async (appId, blobId, new_components) =>
    await builderV2.put(`/apps/${appId}/blobs/${blobId}`, { new_components }),
  updatePageSettings: async (appId, pageId, settings) =>
    await builderV2.put(`/apps/${appId}/pages/${pageId}`, settings),
  copyPage: async (appId, pageId, pageData) =>
    await builderV2.post(`/apps/${appId}/pages/${pageId}/copy`, pageData),

  // THEMES
  getTheme: async (appId, themeId) =>
    await builderV2.get(`/apps/${appId}/themes/${themeId}`),

  // PAGE MULTISTEPS
  getPageMultisteps: async (appId, pageId) =>
    await builderV2.get(`/apps/${appId}/pages/${pageId}/multisteps/`),
  createPageMultistep: async (appId, pageId) =>
    await builderV2.post(`/apps/${appId}/pages/${pageId}/multisteps/`),
  getMultistep: async (appId, pageId, multistepId) =>
    await builderV2.get(
      `/apps/${appId}/pages/${pageId}/multisteps/${multistepId}`),
  addMultistepStep: async (appId, pageId, multistepId, stepId) =>
    await builderV2.post(
      `/apps/${appId}/pages/${pageId}/multisteps/${multistepId}/steps`,
      { step_id: stepId }),
  updateMultistepStep: async (appId, pageId, multistepId, stepId, content) =>
    await builderV2.put(
      `/apps/${appId}/pages/${pageId}
      /multisteps/${multistepId}/steps/${stepId}`,
      { step: content }),

  // VARIABLE CATALOG
  getVariablesCatalog: async (appId, catalog) =>
    await builderV2.get(`/apps/${appId}/variable-catalog/${catalog}`),
  getFunctionVariables: async (functionId, operationId) =>
    await builderV2.get(`/functions/${functionId}/operations/${operationId}/variable_catalog`),
  getIntegrationVariables: async (appId, integrationId) =>
    await builderV2.get(`/apps/${appId}/variable-catalog/api_vars/${integrationId}`),

  // API DECLARATIONS
  createAPI: async (appId, apiData) =>
    await builderV2.post(`/apps/${appId}/api-declarations/`, apiData),
  getAPI: async (appId, apiId) =>
    await builderV2.get(`/apps/${appId}/api-declarations/${apiId}`),
  updateAPI: async (appId, apiId, apiData) =>
    await builderV2.put(`/apps/${appId}/api-declarations/${apiId}`, apiData),
  deleteAPI: async (appId, apiId) =>
    await builderV2.delete(`/apps/${appId}/api-declarations/${apiId}`),
  updateAPISettings: async (appId, apiId, apiSettings) =>
    await builderV2.put(
      `/apps/${appId}/api-declarations/${apiId}/meta`, apiSettings),
  duplicateAPI: async (appId, apiId, apiData) =>
    await builderV2.post(
      `/apps/${appId}/api-declarations/${apiId}/copy`, apiData),

  // PROCESS FLOWS
  createFlow: async (appId, flowData) =>
    await builderV2.post(`/apps/${appId}/flows/`, flowData),
  getFlow: async (appId, flowId) =>
    await builderV2.get(`/apps/${appId}/flows/${flowId}`),
  updateFlow: async (appId, flowId, flowData) =>
    await builderV2.put(`/apps/${appId}/flows/${flowId}`, flowData),
  deleteFlow: async (appId, flowId) =>
    await builderV2.delete(`/apps/${appId}/flows/${flowId}`),
  copyFlow: async (appId, flowId, flowData) =>
    await builderV2.post(`/apps/${appId}/flows/${flowId}/copy`, flowData),


  // FUNCTIONS
  createFunction: async (functionData) =>
    await builderV2.post(`/functions/`, functionData),
  getFunction: async (functionId) =>
    await builderV2.get(`/functions/${functionId}`),
  updateFunction: async (functionId, functionData) =>
    await builderV2.put(`/functions/${functionId}/`, functionData),
  associateFunctionToApp: async (appId, functionId) =>
    await builderV2.post(`/apps/${appId}/functions/${functionId}/`),
  removeFunctionFromApp: async (appId, functionId) =>
    await builderV2.delete(`/apps/${appId}/functions/${functionId}/`),
  deleteFunction: async (functionId) =>
    await builderV2.delete(`/functions/${functionId}`),
  createFunctionOperation: async (functionId, operationData) =>
    await builderV2.post(`/functions/${functionId}/operations/`, operationData),
  getFunctionOperation: async (functionId, operationId) =>
    await builderV2.get(`/functions/${functionId}/operations/${operationId}`),
  updateFunctionOperation: async (functionId, operationId, operationData) =>
    await builderV2.put(
      `/functions/${functionId}/operations/${operationId}`,
      operationData),
  deleteFunctionOperation: async (functionId, operationId) =>
    await builderV2.delete(
      `/functions/${functionId}/operations/${operationId}`),
  addFunctionPermission: async (functionId, settings) =>
    await builderV2.put(
      `/functions/${functionId}/permissions`, settings),
  removeFunctionPermission: async (functionId, accId) =>
    await builderV2.delete(`/functions/${functionId}/permissions/${accId}`),
  copyFunctionOperation: async (functionId, operationId, operationData) =>
    await builderV2.post(
      `/functions/${functionId}/operations/${operationId}/copy`,
      operationData),
  aiGeneration: async (functionId, operationId, functionDescription) =>
    await builderV2.post(
      `/functions/${functionId}/operations/${operationId}/ai-generation`,
      {
        function_description: functionDescription
      }),
  getPublicApis: async (functionId) =>
    await builderV2.get(
      `/functions/${functionId}/public_api/`),
  functionAddDbsEnv: async (functionId, envName, data) =>
    await builderV2.post(
      `/functions/${functionId}/env_dbs/${envName}`, data),


  // DATABASES
  associateDBToFunction: async (functionId, dbId) =>
    await builderV2.post(`/functions/${functionId}/dbs/${dbId}/`),
  removeDBFromFunction: async (functionId, dbId) =>
    await builderV2.delete(`/functions/${functionId}/dbs/${dbId}/`),
  addDBPermission: async (dbId, settings) =>
    await builderV2.put(`/dbs/${dbId}/permissions`, settings),
  removeDBPermission: async (dbId, accId) =>
    await builderV2.delete(`/dbs/${dbId}/permissions/${accId}`),


  // DYNAMIC COMPONENTS
  getAppDynamicComponents: async (appId) =>
    await builderV2.get(`/apps/${appId}/dynamic-components/`),
  createDynamicComponent: async (appId, componentData) =>
    await builderV2.post(`/apps/${appId}/dynamic-components/`, componentData),
  getDynamicComponent: async (appId, componentId) =>
    await builderV2.get(`/apps/${appId}/dynamic-components/${componentId}`),
  updateDynamicComponent: async (appId, componentId, content) =>
    await builderV2.put(
      `/apps/${appId}/dynamic-components/${componentId}`, content),
  deleteDynamicComponent: async (appId, componentId) =>
    await builderV2.delete(`/apps/${appId}/dynamic-components/${componentId}`),

  // DOMAINS
  getAppDomains: async (appId) =>
    await builderV2.get(`/apps/${appId}/domains/`),
  createCustomDomain: async (appId, domain) =>
    await builderV2.post(`/apps/${appId}/domains/`, { domain }),
  getDomainInfo: async (appId, domain) =>
    await builderV2.get(`/apps/${appId}/domains/${domain}`),
  setDomainEnv: async (appId, domain, env) =>
    await builderV2.put(`/apps/${appId}/domains/${domain}/env`, { env }),

  // CUSTOM COMPONENTS
  getComponentLibrary: async (appId) =>
    await builderV2.get(`/apps/${appId}/components`),
  createCustomComponent: async (appId, config) =>
    await builderV2.post(`/apps/${appId}/components/`, config),
  updateCustomComponent: async (appId, componentId, config) =>
    await builderV2.put(`/apps/${appId}/components/${componentId}`, config),
  updateCustomComponentWithBlobRefresh: async (
    appId, componentId, blobId, component, blob,
  ) => await builderV2.put(
    `/apps/${appId}/components/${componentId}/blob/${blobId}`,
    { component, blob }),
};
