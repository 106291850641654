import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import store, { currentAppActions, userActions } from 'PFStore';

const FunctionSelectionModal = ({
  active,
  onDismiss
}) => {
  const navigate = useNavigate();

  const currentApp = useSelector((state) => state.current_app);
  const appFunctions = currentApp.build.associated_functions?.docs ?? [];

  const userFunctions = useSelector((state) =>
    state.user.associated_functions.docs);

  const filteredFunctions = userFunctions.filter((func) =>
    !appFunctions.find((f) => f._id === func._id));

  const [selectedFunctions, setSelectedFunctions] = useState([]);

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);
  const [connectAsyncCall, connectLoading, connectError, dismissConnectError]
    = useAsyncCall(false);

  const createFunction = () => {
    createAsyncCall(async () => {
      let alias = `${currentApp.info.name} function set`;
      const funcCount = appFunctions.length + 1;
      if (funcCount > 1) alias += ` ${funcCount}`;

      const { InsertedID }
        = await builderV2.createFunction({ alias });
      await builderV2.associateFunctionToApp(currentApp._id, InsertedID);
      store.dispatch(userActions.addFunction({ alias, _id: InsertedID }));
      navigate(`/functions/${InsertedID}/operations`);
    });
  };

  const connectFunctions = () => {
    connectAsyncCall(async () => {
      const funcsToConnect = selectedFunctions.map(
        (id) => filteredFunctions.find((f) => f._id === id));
      for await (const func of funcsToConnect) {
        await builderV2.associateFunctionToApp(currentApp._id, func._id);
        store.dispatch(currentAppActions.addFunction(func));
      }
      setSelectedFunctions([]);
      onDismiss();
    });
  };

  const renderFilteredFunctions = () => {
    return filteredFunctions.map((func) => {
      const selected = selectedFunctions.includes(func._id);

      const handleSelection = () => {
        if (selected) {
          setSelectedFunctions(selectedFunctions.filter(
            (id) => id !== func._id));
        } else {
          setSelectedFunctions([...selectedFunctions, func._id]);
        }
      };

      return (
        <PFButton key={func._id} type="none" onClick={handleSelection}>
          <PFContainer height={100} radius="m" width="100%" padding="m"
            display="flex" flexDirection="column" justifyContent="end"
            background="var(--strokes)"
            style={{ position: 'relative', textAlign: 'left' }}
            border={selected ? '1px solid var(--secondary)' : 'none'}>
            <PFContainer background={selected ? 'var(--secondary)' : 'white'}
              display='flex' alignItems='center' justifyContent='center'
              border={selected ? 'none' : '1px solid var(--secondary)'}
              style={{
                position: 'absolute', top: 15, right: 15,
                width: 24, borderRadius: '50%', aspectRatio: 1
              }}>
              <PFIcon icon="bx bx-check" color="white" />
            </PFContainer>
            <PFText size="s">{func.alias}</PFText>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">Connect to a function set</PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="s">
            <PFButton type="none" onClick={createFunction}
              loading={createLoading} padding="none">
              <PFContainer height={100} radius="m" width="100%"
                display="flex" flexDirection="column" justifyContent="center"
                background="var(--button-main-primary-background)">
                <PFContainer display="flex"
                  alignItems="center" justifyContent="center" gap="s">
                  <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
                  <PFText color="white">Create new</PFText>
                </PFContainer>
              </PFContainer>
            </PFButton>
            {renderFilteredFunctions()}
          </PFContainer>
          <PFContainer margin="top-l" display="flex" justifyContent="end">
            <PFButton onClick={connectFunctions}
              loading={connectLoading}
              disabled={selectedFunctions.length === 0}
              padding="horizontal-xl">
              Confirm connection
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
      <PFAlert
        message={connectError}
        open={!!connectError}
        onClose={dismissConnectError} />
    </>
  );
};

FunctionSelectionModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default FunctionSelectionModal;
