export const getRowUpdateObject = (tableConfig, row) => {
  const fields = [...tableConfig.fields];

  // Remove id from updates
  fields.shift();

  const keyValueRow = fields.map(({ column_name }) => {
    return [column_name, row[column_name]];
  });

  return Object.fromEntries(keyValueRow);
};
