/**
 * *Notes:
 * - empty value rules are treated by Styled Components as invalid rules and
 * get sanitized (rule removed)
 *
 */
const BASE_STYLES = {
  FONT: {
    'margin': '',
    'font-family': 'Arial, Helvetica, sans-serif',
    'font-weight': 'normal',
    'line-height': 'initial',
    'color': '#000000',
    'text-align': 'left',
    // user agent styles
    'margin-block-start': 'unset',
    'margin-block-end': 'unset'
  },
  HEADINGS: {
    'width': '100%',
    'height': 'auto',
    'margin-bottom': '8px',
    'display': 'block',
    'color': '#000000'
  },
  CONTAINER: {
    'display': 'block',
    'box-sizing': 'border-box',
    'position': 'relative'
  },
  CONTAINER_MEDIUM: {
    'min-width': '410px',
    'min-height': '80px',
  },
  CONTAINER_LARGE: {
    'min-width': '100%',
    'min-height': '410px',
  },
  BUTTON: {
    'border-radius': '0px',
    'border-bottom-left-radius': '',
    'border-bottom-right-radius': '',
    'border-top-left-radius': '',
    'border-top-right-radius': '',
    'padding-top': '8px',
    'padding-right': '8px',
    'padding-bottom': '8px',
    'padding-left': '8px',
    'width': '120px',
    'border-bottom-color': '',
    'border-bottom-style': '',
    'border-bottom-width': '',
    'border-left-color': '',
    'border-left-style': '',
    'border-left-width': '',
    'border-right-color': '',
    'border-right-style': '',
    'border-right-width': '',
    'border-top-color': '',
    'border-top-style': '',
    'border-top-width': '',
    'background-color': '#7e26ef',
    'color': '#ffffff',
  },
  FORM_WRAPPER: {
    'border-bottom-color': '#adb5bd',
    'border-bottom-style': 'solid',
    'border-bottom-width': '1px',
    'border-left-color': '#adb5bd',
    'border-left-style': 'solid',
    'border-left-width': '1px',
    'border-right-color': '#adb5bd',
    'border-right-style': 'solid',
    'border-right-width': '1px',
    'border-top-color': '#adb5bd',
    'border-top-style': 'solid',
    'border-top-width': '1px',
    'padding-bottom': '8px',
    'padding-left': '16px',
    'padding-right': '16px',
    'padding-top': '8px',
    'box-sizing': 'border-box'
  },
  FORM: {
    'align-items': 'flex-start',
    'box-sizing': 'border-box',
    'display': 'flex',
    'flex-direction': 'column',
    'flex-wrap': 'nowrap',
    'justify-content': 'flex-start',
    'min-height': '100px',
    'padding-top': '8px',
    'padding-right': '8px',
    'padding-bottom': '8px',
    'padding-left': '8px',
    'row-gap': '8px',
    'width': '100%',
  },
  HORIZONTAL_GROUP: {
    'display': 'flex',
    'columnGap': '8px'
  }
};


const COMPONENT_DEFAULT_STYLES = {
  PARAGRAPH: {
    ...BASE_STYLES['FONT'],
    'height': 'auto',
    'font-size': '16px',
    'margin-bottom': '8px',
    'width': '100%',
    'display': 'inline-block'
  },
  CONTAINER_MEDIUM: {
    ...BASE_STYLES['CONTAINER'],
    ...BASE_STYLES['CONTAINER_MEDIUM']
  },
  CONTAINER_LARGE: {
    ...BASE_STYLES['CONTAINER'],
    ...BASE_STYLES['CONTAINER_LARGE']
  },
  FORM_FIELD: {
    ...BASE_STYLES['FONT'],
    'display': 'block',
    'width': '100%',
    'height': 'auto',
    'padding-top': '12px',
    'padding-right': '16px',
    'padding-bottom': '12px',
    'padding-left': '16px',
    'box-sizing': 'border-box',
    'outline': '0px',
    'text-align': 'left',
    'font-size': '14px'
  },
  FORM_FIELD_BORDERS: {
    'border-top-color': '#cccccc',
    'border-top-style': 'solid',
    'border-top-width': '1px',
    'border-right-color': '#cccccc',
    'border-right-style': 'solid',
    'border-right-width': '1px',
    'border-bottom-color': '#cccccc',
    'border-bottom-style': 'solid',
    'border-bottom-width': '1px',
    'border-left-color': '#cccccc',
    'border-left-style': 'solid',
    'border-left-width': '1px'
  },
  BUTTON: {
    ...BASE_STYLES['FONT'],
    ...BASE_STYLES['BUTTON']
  },
  LINK: {
    ...BASE_STYLES['FONT'],
    'display': 'inline-block',
    'font-size': '16px'
  },
  LINK_BLOCK: {
    'min-width': '410px',
    'display': 'block',
    'padding-bottom': '8px'
  },
  FORM_WRAPPER: {
    ...BASE_STYLES['FORM'],
    ...BASE_STYLES['CONTAINER_LARGE']
  },
  HEADINGS: {
    ...BASE_STYLES['FONT'],
    ...BASE_STYLES['HEADINGS'],
  },
  MULTI_STEP: {
    ...BASE_STYLES['CONTAINER_MEDIUM'],
    'background-color': '#342C49',
    'padding-top': '8px',
    'padding-right': '8px',
    'padding-left': '8px',
    'padding-bottom': '8px',
  },
  FORM: {
    ...BASE_STYLES['FORM']
  },
  LABEL: {
    ...BASE_STYLES['FONT'],
    'font-size': '14px',
    'display': 'inline'
  },
  FORM_FIELD_META_DATA: {
    ...BASE_STYLES['FONT'],
    'color': '#FF0000'
  },
  LIST: {
    ...BASE_STYLES['FONT'],
    'padding-left': '32px'
  },
  DROPDOWN: {
    ...BASE_STYLES['FONT'],
    'padding-top': '14px',
    'padding-bottom': '14px',
    '& > ul': {
      'padding-top': '14px',
      'padding-bottom': '14px',
    }

  },
  ICON: {
    'font-size': '20px'
  },
  READMORE: {
    '& > a': { 'cursor': 'pointer' }
  },
  HORIZONTAL_GROUP: {
    ...BASE_STYLES['HORIZONTAL_GROUP']
  },
  CHECK_RADIO_FIELD: {
    'display': 'flex',
    'align-items': 'center',
    'column-gap': '4px'
  },
  CHECKED_CHECK_PILL: {
    '& > input[type="checkbox"]:checked + label': {
      'background-color': '#7E26EF',
      'color': '#FFFFFF'
    },
    'align-items': 'center',
    'box-sizing': 'border-box',
    'display': 'flex'
  },
  CHECKED_TOGGLE: {
    '& > input[type="checkbox"]:checked + label:before': {
      'left': 'calc(100% - 2px)',
      'transform': 'translateX(-100%)',
    },
    '& > input[type="checkbox"]:checked + label': {
      'background-color': '#7E26EF',
    }
  },
  CHECKED_RADIO_PILL: {
    '& > input[type="radio"]:checked + label': {
      'background-color': '#7E26EF',
      'color': '#FFFFFF'
    },
    'align-items': 'center',
    'box-sizing': 'border-box',
    'display': 'flex'
  },
  CHECK_RADIO_PILL: {
    'display': 'none'
  },
  CHECK_TOGGLE: {
    'display': 'none'
  },
  TOGGLE: {
    'display': 'block',
    'position': 'relative',
    'cursor': 'pointer',
    'width': '60px',
    'height': '30px',
    'background-color': '#AD90FF',
    'border-radius': '15px',
    '&::before': {
      'content': '""',
      'position': 'absolute',
      'top': '2px',
      'left': '2px',
      'width': '26px',
      'height': '26px',
      'background-color': '#fff',
      'border-radius': '50%',
      'transition': '0.3s',
    }
  },
  PILL: {
    '&:active': {
      'background-color': '#7E26EF'
    },
    '&:focus, :focus-visible': {
      'background-color': '#7E26EF'
    },
    '&:hover': {
      'background-color': '#7E26EF',
      'color': '#FFFFFF'
    },
    'background-color': '#ECE5FF',
    'color': '#000000',
    'border-bottom-left-radius': '32px',
    'border-top-right-radius': '32px',
    'border-top-left-radius': '32px',
    'border-bottom-right-radius': '32px',
    'padding-bottom': '12px',
    'padding-left': '30px',
    'padding-right': '30px',
    'padding-top': '12px'
  }
};

export default COMPONENT_DEFAULT_STYLES;
