import {
  PFButton,
  PFContainer,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import React from 'react';
import '../../DesignEditor.css';
import { EditorControl } from '../../components';
import { validateSizeInput } from '../../utils';

function composePosition({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);

  let configStyles = {
    [styleKey]: styleValue
  };

  if (styleKey === 'position' && styleValue !== 'absolute') {
    configStyles = {
      [styleKey]: styleValue,
      'top': '',
      'bottom': '',
      'right': '',
      'left': '',
    };
  }

  return {
    configStyles
  };
}

const Position = ({
  activeSection,
  composeStyles,
  handleActiveSection,
  ruleSet,
  componentBreakpointStyles
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <PFButton onClick={handleActiveSection}
        type='support' padding='0' height='auto'>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'}
          />
          Position
        </PFText>
      </PFButton>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up'
        : 'pf-design-editor__section--down'}
      >
        {
          ruleSet.map((positionRule, index) => {
            if (index !== 0) return null;
            const ruleKey = Object.keys(positionRule)[0];
            const ruleValueControls = positionRule[ruleKey];

            return (
              <PFContainer
                key={
                  `design-editor-${ruleKey}-${index}`
                }
                className="pf-designer-editor__rule">
                <PFContainer className="pf-design-editor__control">
                  {
                    ruleValueControls.map((rule, index) => {
                      return (
                        <EditorControl
                          key={`design-editor-rule-${rule.key}-${index}}`}
                          componentBreakpointStyles={componentBreakpointStyles}
                          typeConfig={rule.typeConfig}
                          onChange={(event) => {
                            const composedValue = composePosition({
                              alias: rule.alias,
                              composedValue: { [rule.key]: event.target.value },

                            });

                            composeStyles({
                              composedAttributes: composedValue,
                            });
                          }}
                          styleConfig={{
                            key: rule.key,
                            alias: rule.alias
                          }}
                          type={typeof rule.type === 'undefined'
                            ? 'editor-control-button' : rule.type}>
                          {rule.label}
                        </EditorControl>
                      );
                    })
                  }
                </PFContainer>
              </PFContainer>
            );
          })
        }
        {
          !!componentBreakpointStyles
            && componentBreakpointStyles['position'] === 'absolute'
            || componentBreakpointStyles['position'] === 'sticky'
            || componentBreakpointStyles['position'] === 'relative'
            ? (
              <PFContainer>
                {
                  ruleSet.map((positionRule, index) => {
                    if (index < 1) return null;
                    const ruleKey = Object.keys(positionRule)[0];
                    const ruleValueControls = positionRule[ruleKey];
                    return (
                      <React.Fragment key={`design-editor-${ruleKey}-${index}`}>
                        {
                          ruleValueControls.map((rule, index) => {
                            return (
                              <PFContainer
                                key={`design-editor-${ruleKey}-${index}`
                                }
                                className="pf-designer-editor__rule">
                                <PFText size='m'>{rule.text}</PFText>
                                <PFContainer
                                  className={
                                    `pf-design-editor__control
                                  pf-design-editor__control--small`}
                                  key={`design-editor-control-${index}`}>
                                  <EditorControl
                                    key={
                                      `design-editor-control-
                                      ${rule.key}-${index}}`}
                                    componentBreakpointStyles
                                    ={componentBreakpointStyles}
                                    typeConfig={rule.typeConfig}
                                    onChange={(event) => {
                                      const normalizedInputValue
                                        = validateSizeInput(
                                          componentBreakpointStyles[
                                          rule.key], event.target.value);

                                      const composedValue = composePosition({
                                        composedValue: {
                                          [rule.key]: normalizedInputValue
                                        },
                                        componentBreakpointStyles
                                      });

                                      composeStyles({
                                        composedAttributes:
                                          composedValue,
                                      });
                                    }}
                                    styleConfig={{
                                      key: rule.key,
                                      value: rule.value,
                                      alias: rule.alias
                                    }}
                                    type={typeof rule.type === 'undefined'
                                      ? 'editor-control-button' : rule.type}
                                  >
                                    {rule.label}
                                  </EditorControl>
                                </PFContainer>
                              </PFContainer>
                            );
                          })
                        }
                      </React.Fragment>
                    );
                  })
                }
              </PFContainer>
            ) : null
        }
      </PFContainer>
    </PFContainer >
  );
};

export default Position;

Position.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object
};
