import { PFContainer, PFIcon, PFLink, PFText } from 'PFComponents/common';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';


const FunctionsDashboardSidebar = () => {
  const currentFunction = useSelector((state) => state.current_function);
  const location = useLocation();
  const getLinkIconColor = (pathEnding) => {
    return location.pathname.endsWith(pathEnding)
      ? 'white'
      : 'var(--secondary)';
  };

  const getLinkTextColor = (pathEnding) => {
    return location.pathname.endsWith(pathEnding)
      ? 'white'
      : 'var(--secondary)';
  };

  const basePath = `/functions/${currentFunction._id}`;

  return (
    <PFContainer type="main"
      display="flex" flexDirection="column" gap="s"
      style={{
        width: '200px'
      }}>
      <PFContainer display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex" flexDirection="column" gap="m"
          margin='bottom-xl'>
          <PFLink to={`${basePath}/operations`}>
            <PFContainer display="flex" alignItems="center" gap="s">
              <PFIcon icon="bx bx-code" size="l"
                color={getLinkIconColor('/operations')} />
              <PFText color={getLinkTextColor('/operations')}>
                Operations</PFText>
            </PFContainer>
          </PFLink>
          <PFLink to={`${basePath}/databases`}>
            <PFContainer display="flex" alignItems="center" gap="s">
              <PFIcon icon="bx bx-data" size="l"
                color={getLinkIconColor('/databases')} />
              <PFText color={getLinkTextColor('/databases')}>Databases</PFText>
            </PFContainer>
          </PFLink>
        </PFContainer>

        <PFContainer>
          <PFLink to={`${basePath}/environments`}>
            <PFContainer display="flex" alignItems="center" gap="s">
              <PFIcon icon="bx bx-folder" size="l"
                color={getLinkIconColor('/environments')} />
              <PFText color={getLinkTextColor('/environments')}>
                Environments
              </PFText>
            </PFContainer>
          </PFLink>
        </PFContainer>

      </PFContainer>
    </PFContainer>
  );
};

export default FunctionsDashboardSidebar;
