export default {
  'String': {
    type_name: 'String',
    data_type: 'VARCHAR',
    length: 100
  },
  'Integer': {
    type_name: 'Integer',
    data_type: 'INTEGER',
  },
  'Decimal': {
    type_name: 'Decimal',
    data_type: 'DECIMAL',
    length: 16,
    scale: 4,
  },
  'Auto Increment': {
    type_name: 'Auto Increment',
    data_type: 'BIGINTEGER',
    generation_strategy: 'IDENTITY'
  },
  'Boolean': {
    type_name: 'Boolean',
    data_type: 'BOOLEAN',
  },
  'Date & Time': {
    type_name: 'Date & Time',
    data_type: 'DATETIME',
  },
  'Select': {
    type_name: 'Select',
    data_type: 'VARCHAR',
    length: 35,
    select_options: [],
  },
  'Currency': {
    type_name: 'Currency',
    data_type: 'DECIMAL',
    length: 16,
    scale: 2,
  },
  'Email': {
    type_name: 'Email',
    data_type: 'VARCHAR',
    length: 50
  },
  'Phone': {
    type_name: 'Phone',
    data_type: 'VARCHAR',
    length: 20
  },
  'Rating': {
    type_name: 'Rating',
    data_type: 'DECIMAL',
    length: 2,
    scale: 1,
  },
  'Big Integer': {
    type_name: 'Big Integer',
    data_type: 'BIGINTEGER',
  },
  'Short String': {
    type_name: 'Short String',
    data_type: 'VARCHAR',
    length: 35
  },
  'Long String': {
    type_name: 'Long String',
    data_type: 'VARCHAR',
    length: 400
  },
  'Date': {
    type_name: 'Date',
    data_type: 'DATE',
  },
  'Float': {
    type_name: 'Float',
    data_type: 'FLOAT',
  },
  'Money': {
    type_name: 'Money',
    data_type: 'MONEY',
  },
  'Foreign Key': {
    type_name: 'Foreign Key',
    data_type: 'FOREIGNKEY',
  }
};
