import React, { ReactNode } from 'react';
import * as Styled from './accordioncontent.styled';

export type AccordioncontentProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * To identify an Accordion Item.
     */
    id?: string;
};

export function Accordioncontent({ children, className, configStyles, id, ...props }: AccordioncontentProps) {
    return (
        <Styled.Accordioncontent className={className} configStyles={configStyles} id={id} {...props}>
            {children}
        </Styled.Accordioncontent>
    );
}
