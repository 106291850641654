
import {
  PFContainer,
  PFInput,
  PFSelect
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { EditorDropdownOptions } from '../../constants';
import '../../DesignEditor.css';


function LayoutGridTemplate({
  fieldValue,
  handleNotationChange,
  handleRemoveRule,
  handleFieldChange,
  ruleLength }) {
  return (
    <PFContainer className="pf-editor-grid-template-rule">
      {
        ruleLength > 1
          ? <span
            className='material-symbols-outlined pf-editor-grid-remove'
            onClick={handleRemoveRule}
          >
            delete
          </span>
          : null
      }
      <PFContainer className='pf-editor-grid-template-toggler'>
        <span
          onClick={handleNotationChange}
          className={`pf-editor-grid-template-default${fieldValue.length === 1
            ? ' active' : ''}`}>
          Default
        </span>
        <span
          onClick={handleNotationChange}
          className={`pf-editor-grid-template-default${fieldValue.length === 2
            ? ' active' : ''}`}>
          Min-Max
        </span>
      </PFContainer>
      <PFContainer className='pf-editor-grid-template-controls'>
        <PFContainer
          display='flex'
          justifyContent='space-around'
          width='100%'>
          {createGridTemplateField({
            ruleNotation: fieldValue,
            ruleValue: fieldValue[0],
            handleFieldChange,
            ruleValueIndex: 0
          })}
          {
            fieldValue.length === 2
              ? createGridTemplateField({
                ruleNotation: fieldValue,
                ruleValue: fieldValue[1],
                handleFieldChange,
                ruleValueIndex: 1
              })
              : null
          }
        </PFContainer>
      </PFContainer>
    </PFContainer>
  );
}

function createGridTemplateField({
  ruleNotation,
  ruleValue,
  ruleValueIndex,
  handleFieldChange
}) {
  const sizeValue = (/\d+/g).test(ruleValue)
    ? ruleValue.match(/\d+/g)[0] : '';
  const sizeUnit = (/[a-z-A-Z%]+/g).test(ruleValue)
    ? ruleValue.match(/[a-z-A-Z%]+/g)[0] : 'px';

  if (sizeUnit === 'auto'
    || sizeUnit === 'max-content'
    || sizeUnit === 'min-content'
  ) {
    return (
      <PFContainer
        className='pf-editor-control'>
        <div className='pf-mui-dropdown'>
          <PFSelect
            className='pf-editor-dropdown single' value={sizeUnit}
            onChange={(event) => {
              handleFieldChange({
                ruleValueIndex,
                value: event.target.value
              });
            }}
            options={EditorDropdownOptions['Size'].filter((gridOption) => {
              return gridOption.value !== 'initial'
                && gridOption.value !== 'unset'
                && gridOption.value !== 'inherit';
            })}
            optionsStyles={{
              color: 'var(--white)'
            }}
            listStyles={{
              borderRadius: '8px',
              border: '1px solid var(--purple-300)',
              backgroundColor: 'var(--purple-500)'
            }}
          />
        </div>
      </PFContainer>
    );
  } else {
    return (
      <PFContainer
        className='pf-editor-control'>
        <PFInput
          fontSize="label"
          type='number'
          className='pf-editor-input'
          value={sizeValue}
          onChange={(event) => {
            handleFieldChange({
              ruleValueIndex,
              // This is the only size rule that needs to force a value
              // otherwise the value gets invalid and removes the rule
              value: event.target.value || '1'
            });
          }}
        />
        <div className='pf-mui-dropdown'>
          <PFSelect
            className='pf-editor-dropdown' value={sizeUnit}
            onChange={(event) => {
              handleFieldChange({
                ruleValueIndex,
                value: event.target.value || 'px'
              });
            }}
            options={ruleNotation.length === 2 && ruleValueIndex === 0
              // To prevent "fraction (fr)" contradiction on min value
              ? EditorDropdownOptions['Size'].filter((gridOption) => {
                return gridOption.value !== 'fr'
                  && gridOption.value !== 'initial'
                  && gridOption.value !== 'unset'
                  && gridOption.value !== 'inherit';
              }) : EditorDropdownOptions['Size'].filter((gridOption) => {
                return gridOption.value !== 'initial'
                  && gridOption.value !== 'unset'
                  && gridOption.value !== 'inherit';
              })}
            optionsStyles={{
              color: 'var(--white)'
            }}
            listStyles={{
              borderRadius: '8px',
              border: '1px solid var(--purple-300)',
              backgroundColor: 'var(--purple-500)'
            }}
          />
        </div>
      </PFContainer>
    );
  }
}

LayoutGridTemplate.propTypes = {
  handleFieldChange: PropTypes.func,
  handleNotationChange: PropTypes.func,
  handleRemoveRule: PropTypes.func,
  fieldValue: PropTypes.array,
  ruleLength: PropTypes.number
};

export default LayoutGridTemplate;
