import PropTypes from 'prop-types';

import {
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';

const ComponentForm = ({
  form,
  fieldHook,
  title,
  callToAction,
  onSubmit,
  loading,
  onClose
}) => {
  return (
    <PFForm form={form} submit={onSubmit}>
      <PFContainer display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex" justifyContent="space-between"
          alignItems="center" height={24}>
          <PFText size="s">{title}</PFText>
          <PFButton type="support" padding="none" height={30}
            onClick={onClose}>
            <PFIcon icon="bx bx-chevrons-left" size="m" />
          </PFButton>
        </PFContainer>
        <PFContainer display="grid" gap="s" alignItems="center"
          gridTemplateColumns="auto 1fr">
          <PFText size="s">Name</PFText>
          <PFInput
            name="label" fieldHook={fieldHook}
            placeholder="Component name"
            fontSize="s" style={{ height: 30 }} />
          <PFText size="s">Category</PFText>
          <PFInput
            name="category" fieldHook={fieldHook}
            placeholder="Component category"
            fontSize="s" style={{ height: 30 }} />
        </PFContainer>
        <PFButton type="secondary" submit
          loading={loading}
          style={{ fontSize: 'var(--text-label)' }}>
          {callToAction}
        </PFButton>
      </PFContainer>
    </PFForm>
  );
};

ComponentForm.propTypes = {
  form: PropTypes.object.isRequired,
  fieldHook: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  callToAction: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ComponentForm;
