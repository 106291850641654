import React, { ReactNode } from 'react';
import NumberFormat from 'react-number-format';
import * as Styled from './telephone.styled';

export type TelephoneProps = {
    /**
     * allows the browser to predict the value
     */
    autoComplete?: string;

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * to disable or enable the Telephone element
     */
    disabled?: boolean;

    /**
     * specifies the telephone format
     */
    format?: string;

    /**
     * an identifier for the Telephone element
     */
    id?: string | undefined;

    /**
     * specifies the name of the Telephone element
     */
    name?: string;

    /**
     * a function to be triggered when the Telephone element loses focus
     */
    onBlur?: (e) => void;

    /**
     * a function to be triggered when the Telephone element value change
     */
    onChange?: (e) => void;

    /**
     * a function to be triggered when the Telephone element gets focus
     */
    onFocus?: (e) => void;

    /**
     * a placeholder for the Telephone element
     */
    placeholder?: string;

    /**
     * specifies that the Telephone is read-only
     */
    readOnly?: boolean;

    /**
     * specifies that the Telephone must be filled
     */
    required?: boolean;

    /**
     * to set the value of the Telephone element
     */
    value?: string;

    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;
};

export function Telephone({
    autoComplete = 'off',
    className,
    configStyles = '',
    disabled,
    format = '##-####-####',
    id,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    readOnly,
    required,
    value,
    children,
    ...props
}: TelephoneProps) {
    return (
        <NumberFormat
            autoComplete={autoComplete}
            className={className}
            customInput={Styled.Number}
            configStyles={configStyles}
            disabled={disabled}
            format={format}
            id={id}
            inputMode="numeric"
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder || format || ''}
            readOnly={readOnly}
            required={required}
            type="tel"
            value={value}
            {...props}
        />
    );
}
