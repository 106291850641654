/* eslint-disable max-len */

const PFIconComponentNumber = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="5.4834" y="17.373" width="44" height="16" rx="3" fill="#332851" />
      <path d="M8.6134 23.383V22.313H10.8734V29.373H9.6734V23.383H8.6134ZM11.9498 28.483C13.0298 27.743 13.8931 27.0197 14.5398 26.313C15.1931 25.5997 15.5198 24.9397 15.5198 24.333C15.5198 23.973 15.4298 23.6864 15.2498 23.473C15.0765 23.2597 14.7931 23.153 14.3998 23.153C13.9931 23.153 13.6865 23.2864 13.4798 23.553C13.2731 23.813 13.1565 24.1864 13.1298 24.673H12.0198C12.0665 23.8664 12.3131 23.2497 12.7598 22.823C13.2131 22.3964 13.7898 22.183 14.4898 22.183C15.1765 22.183 15.7131 22.3664 16.0998 22.733C16.4931 23.093 16.6898 23.593 16.6898 24.233C16.6898 24.7464 16.5298 25.2664 16.2098 25.793C15.8965 26.3197 15.4965 26.8064 15.0098 27.253C14.5231 27.693 14.0198 28.073 13.4998 28.393H16.8498V29.323H11.9498V28.483ZM20.2236 22.093C20.6903 22.093 21.0903 22.1764 21.4236 22.343C21.757 22.503 22.007 22.7264 22.1736 23.013C22.347 23.2997 22.4336 23.6197 22.4336 23.973C22.4336 24.4197 22.307 24.793 22.0536 25.093C21.807 25.3864 21.457 25.563 21.0036 25.623V25.673C21.4903 25.813 21.8703 26.023 22.1436 26.303C22.4236 26.5764 22.5636 26.9564 22.5636 27.443C22.5636 28.0364 22.367 28.5197 21.9736 28.893C21.5803 29.2597 21.017 29.443 20.2836 29.443C19.5303 29.443 18.9236 29.253 18.4636 28.873C18.0036 28.4864 17.7403 27.9164 17.6736 27.163H18.8036C18.8436 27.563 18.987 27.883 19.2336 28.123C19.4803 28.3564 19.8136 28.473 20.2336 28.473C20.627 28.473 20.927 28.3697 21.1336 28.163C21.347 27.9564 21.4536 27.6897 21.4536 27.363C21.4536 26.9697 21.3003 26.6697 20.9936 26.463C20.6936 26.2564 20.257 26.153 19.6836 26.153H19.4136V25.213H19.6836C20.7703 25.2264 21.3136 24.8564 21.3136 24.103C21.3136 23.7764 21.2136 23.5197 21.0136 23.333C20.8136 23.1464 20.5403 23.053 20.1936 23.053C19.847 23.053 19.557 23.1597 19.3236 23.373C19.0903 23.5797 18.9503 23.883 18.9036 24.283H17.7836C17.8303 23.5897 18.0636 23.053 18.4836 22.673C18.9036 22.2864 19.4836 22.093 20.2236 22.093Z" fill="#AD90FF" />
      <line x1="24.6334" y1="18.373" x2="24.6334" y2="32.373" stroke="#BEA7FF" strokeWidth="0.3" />
    </svg>
  );
};

export default PFIconComponentNumber;
