import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Standard tooltip component of the PFComponents library. This tooltip complies
 * with the Pefai style guidelines. The underlying implementation of this
 * tooltip is the Material UI *Tooltip* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFTooltip properties.
 * @param {React.ReactNode} props.children - Child elements to be rendered
 * inside the tooltip.
 * @param {string} props.title - Text to be displayed on the tooltip.
 *
 * @return {React.ReactElement} The tooltip component.
 *
 * @param {string} props.placement - Tooltip placement
 *
 * @param {string} props.enterDelay - Tooltip enter delay in ms
 *
 * @param {string} props.enterNextDelay - Tooltip enter into next tooltip in ms
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFTooltip = ({
  children,
  title,
  enterDelay = 100,
  enterNextDelay = 100,
  placement = 'bottom-start',
  arrowStyles,
  tooltipStyles
}) => {
  const { activeTheme } = useSelector((state) => state.theme);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: 'var(--tooltip-background)',
      ...arrowStyles,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--tooltip-background)',
      color: 'var(--tooltip-content)',
      padding: 'var(--size-m) var(--size-s)',
      fontSize: 'var(--text-size-s)',
      borderRadius: 'var(--radius-s)',
      ...tooltipStyles
    },
  }));

  return (
    <CustomTooltip
      enterNextDelay={enterNextDelay}
      enterDelay={enterDelay}
      title={title}
      className={`pf ${activeTheme}`}
      placement={placement}
      arrow
    >
      {children}
    </CustomTooltip>
  );
};

PFTooltip.propTypes = {
  enterDelay: PropTypes.number,
  enterNextDelay: PropTypes.number,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  arrowStyles: PropTypes.object,
  tooltipStyles: PropTypes.object
};

export default PFTooltip;
