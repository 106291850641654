import { PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { PFFlowNode, PFFlowRenderer } from './components';
import { flowUtils } from './utils';

/**
 * @typedef {import('./utils/flows').ProcessFlow} ProcessFlow
 */

/**
 * Component that renders a Pefai proess flow and allows for it to be edited.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFProcessFlowEditor properties.
 * @param {ProcessFlow} flow - Flow to be managed.
 * @param {func} setFlow - Function to update the flow.
 *
 * @return {React.ReactElement} The process flow editor component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFProcessFlowEditor = ({ flow, setFlow }) => {
  const [newNodeId, setNewNodeId] = useState(null);

  const createNode = (node, config) => {
    setNewNodeId(node._id);
    flowUtils.addNode(node, config, flow, setFlow);
  };

  const updateNode = (node) => {
    flowUtils.updateNode(node, flow, setFlow);
  };

  const removeNode = (node, config) => {
    flowUtils.removeNode(node, config, flow, setFlow);
  };

  const renderNodes = () => {
    const nodes = [];
    flowUtils.traverseFlow(flow, (node, config) => {
      let nodeConfig = config;
      if (node._id === newNodeId) {
        nodeConfig = { ...config, editor_tab: 'EDIT' };
      }

      nodes.push((
        <PFFlowNode
          key={node._id}
          node={node}
          config={nodeConfig}
          createNode={createNode}
          updateNode={updateNode}
          removeNode={removeNode}
          nodes={flow?.nodes}
          flowAlias={flow?.alias} />
      ));
    });
    return nodes;
  };

  return (
    <PFContainer className="process-flow-editor">
      <PFFlowRenderer>
        {renderNodes()}
      </PFFlowRenderer>
    </PFContainer>
  );
};

PFProcessFlowEditor.propTypes = {
  flow: PropTypes.object.isRequired,
  setFlow: PropTypes.func.isRequired,
};

export default PFProcessFlowEditor;
