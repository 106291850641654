
import {
  PFButton,
  PFContainer,
  PFIcon,
  PFLine,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { FUNCTIONS } from 'PFConstants';
import { useAsyncCalls } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import { JoinFilters, QueryColumns, QueryOrigin } from '.';
import QueryJoinFilters from './QueryJoinFilters';

const QueryJoin = ({ index, join, setJoin, removeJoin,
  databases, setUpdateOptions,
  joins, originOptions, joinOptions, setJoinOptions }) => {
  const [db_fetch, alerts] = useAsyncCalls(false);
  const [allOptions_fetch, allOptions_alerts] = useAsyncCalls(false);
  const [db, setDb] = useState(null);
  const currentUserId = useSelector((state) => state.user._id);
  const [currentOn, setCurrentOn]
    = useState(join.on.filter((onElement) => onElement.value?.includes('.')));
  const [currentJoinFilter, setCurrentJoinFilter]
    = useState(join.on.filter((onElement) => !onElement.value?.includes('.')));
  const tableSelected = !!db && !!join?.schema_db && !!join?.table;
  const table = tableSelected
    ? db.tables.find((table) => table.table_name === join.table)
    : null;
  const columnOptions = !!table
    ? table.fields.map((field) => ({
      label: field.alias, value: '${' + db._id + '}'
        + `.${table.table_name}.${field.column_name}`,
    }))
    : [];

  useEffect(() => {
    db_fetch.asyncCall(async () => {
      if (!!join.schema_db) {
        const database = await builderTest(currentUserId)
          .getDatabase(join.schema_db);
        setDb(database);
      }
    });
  }, [join.schema_db]);

  useEffect(() => {
    allOptions_fetch.asyncCall(async () => {
      const currentJoins = joins.map((joinElement) => joinElement.table
        + '_' + joinElement.schema_db);
      const processedIds = new Set();
      const result = {};

      for (const option of currentJoins) {
        const optionSplitted = option.split('_');
        const schemaDbId = optionSplitted[optionSplitted.length - 1];
        if (schemaDbId && !processedIds.has(schemaDbId)) {
          processedIds.add(schemaDbId);

          const database = await builderTest(currentUserId)
            .getDatabase(schemaDbId);

          currentJoins.forEach((item) => {
            const lastUnderscoreIndex = item.lastIndexOf('_');
            const keyPart = item.substring(0, lastUnderscoreIndex);
            const match = database?.tables
              .find((table) => table.table_name === keyPart);
            if (match) {
              result[item] = match.fields.map((matchedItem) => {
                return (
                  {
                    label: matchedItem.alias,
                    value: '${' + schemaDbId + '}'
                      + `.${match.table_name}.${matchedItem.column_name}`
                  }
                );
              });
            }
          });

          setJoinOptions({ ...originOptions, ...result });
        }
      }
    });
  }, []);


  const setType = (type) => setJoin({ ...join, type });
  const setSchemaDB = (schema_db) => {
    setJoin({
      ...join, schema_db, schema: `$\{${schema_db}\}`,
      table: '', on: [], columns: [],
    });
  };
  const setTable = (table) => {
    setJoin({
      ...join, table, on: [], columns: [],
    });
  };
  const setOn = (on) => {
    setJoin({ ...join, on: [...currentOn, ...currentJoinFilter] });
    setCurrentOn((prev) => [...on]);
  };
  const setJoinFilter = (filter) => {
    setJoin({ ...join, on: [...currentOn, ...currentJoinFilter] });
    setCurrentJoinFilter((prev) => [...filter]);
  };

  const setColumns = (columns) => setJoin({ ...join, columns });
  return (
    <PFContainer border="1px solid var(--primary)" padding="m" radius="s"
      display="flex" flexDirection="column" gap="m" margin="top-s"
      style={{ position: 'relative' }}>
      <PFContainer padding="horizontal-s" background="white"
        display="flex" alignItems="center" gap="s"
        style={{
          position: 'absolute', top: db_fetch.loading ? -15 : -8, left: 20
        }}>
        <PFText type="secondary" size="s">
          Join {index + 1}
        </PFText>
        {db_fetch.loading
          ? <PFLoader size="s" color="var(--primary)" margin="top-xs" />
          : null}
      </PFContainer>
      <PFContainer background="white" style={{
        position: 'absolute', top: -20, right: 20
      }}>
        <PFButton type="support" onClick={removeJoin}>
          <PFIcon icon="bx bx-trash" color="var(--primary)" />
        </PFButton>
      </PFContainer>
      <PFContainer display="flex" alignItems="center" gap="m">
        <PFText type="secondary">Join Type</PFText>
        <PFContainer width={150}>
          <PFSelect
            value={join.type}
            onChange={(e) => setType(e.target.value)}
            options={FUNCTIONS.SQL_JOINS} />
        </PFContainer>
      </PFContainer>
      <PFLine />
      <PFContainer display="flex" flexDirection="column" gap="l">
        <QueryOrigin
          databases={databases}
          db={db}
          schemaDB={join?.schema_db}
          setSchemaDB={setSchemaDB}
          table={join?.table}
          setTable={setTable}
          tableSelectionDisabled={!db || db_fetch.loading}
          setUpdateOptions={setUpdateOptions} />
        {tableSelected
          ? <>
            <PFContainer>
              <PFText>On</PFText>
              <JoinFilters
                filters={currentOn}
                setCurrentOn={setCurrentOn}
                setFilters={setOn}
                columnOptions={columnOptions}
                joinOptions={joinOptions}
                tableName={table?.table_name}
                db_schema={db._id} />
            </PFContainer>
            <PFContainer>
              <PFContainer display="flex" alignItems="center" gap="s">
                <PFText>Filter</PFText>
              </PFContainer>
              <QueryJoinFilters
                filters={currentJoinFilter}
                setCurrentJoinFilter={setCurrentJoinFilter}
                setFilters={setJoinFilter}
                columnOptions={columnOptions} />
            </PFContainer>
            <PFContainer>
              <PFText>Columns</PFText>
              <QueryColumns
                columns={join.columns}
                setColumns={setColumns}
                columnOptions={columnOptions} />
            </PFContainer>
          </>
          : null}
      </PFContainer>
      {alerts()}
      {allOptions_alerts()}
    </PFContainer>
  );
};

QueryJoin.propTypes = {
  index: PropTypes.number.isRequired,
  join: PropTypes.object.isRequired,
  setJoin: PropTypes.func.isRequired,
  removeJoin: PropTypes.func.isRequired,
  databases: PropTypes.array.isRequired,
  tableOrigin: PropTypes.string,
  setUpdateOptions: PropTypes.func,
  joinOptions: PropTypes.array,
  setJoinOptions: PropTypes.func,
  currentJoins: PropTypes.array,
  schemaOrigin: PropTypes.string,
  joins: PropTypes.array,
  originOptions: PropTypes.object,
};

export default QueryJoin;
