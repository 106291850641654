import {
  PFButton,
  PFContainer,
  PFIcon,
  PFInput,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { FUNCTIONS } from 'PFConstants';
import PropTypes from 'prop-types';
import { useState } from 'react';


const VariablesSelection = ({ operation, updateOperation }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const requestValues = operation.request_values;
  const updateRequestValues = (newRequestValues) => {
    updateOperation({ ...operation, request_values: newRequestValues });
  };

  const addVariable = () => {
    requestValues.push(['', 'string']);
    updateRequestValues(requestValues);
  };

  const renderFields = () => {
    return requestValues.map(([name, type], i) => {
      const handleNameUpdate = (e) => {
        const { value } = e.target;
        requestValues[i] = [value, type];
        updateRequestValues(requestValues);
      };

      const handleTypeUpdate = (e) => {
        const { value } = e.target;
        requestValues[i] = [name, value];
        updateRequestValues(requestValues);
      };

      const removeVariable = () => {
        requestValues.splice(i, 1);
        updateRequestValues(requestValues);
      };

      return (
        <PFContainer key={i} display="flex" gap="s" alignItems="flex-end">
          <PFContainer flex={2}>
            <PFInput value={name} onChange={handleNameUpdate}
              placeholder="Variable name" />
          </PFContainer>
          <PFContainer flex={1}>
            <PFSelect value={type} onChange={handleTypeUpdate}
              options={FUNCTIONS.PARAM_TYPES} />
          </PFContainer>
          <PFButton type="support" onClick={removeVariable}>
            <PFIcon icon="bx bx-trash" size="l" />
          </PFButton>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer type="form" padding="m" radius="m"
      display="flex" flexDirection="column" gap="s">
      <PFContainer display="flex"
        justifyContent="space-between" alignItems="center">
        <PFContainer display='flex' justifyContent='space-between'
          flex='1'>
          <PFText type="secondary">
            Request variables {'(' + Object.keys(requestValues).length + ')'}
          </PFText>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed
              ? 'down' : 'up'}`} size='l'
              color='var(--purple-200)' />
          </PFButton>
        </PFContainer>
      </PFContainer>
      <PFContainer
        display={isCollapsed ? 'none' : 'flex'}
        flexDirection="column" gap="s">
        {renderFields()}
        <PFButton type="support" onClick={addVariable}
          style={{ alignSelf: 'start' }}>
          + add new variable
        </PFButton>
      </PFContainer>
    </PFContainer>
  );
};

VariablesSelection.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
};

export default VariablesSelection;
