import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFCheckbox,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';

/**
 * PFButton component that can handle publish interactions.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFPublishButton properties.
 * @param {string} [props.text="Launch"] - Text to be used inside the button.
 * @param {"primary"|"secondary"|"support"|"none"} [props.type="primary"] -
 * PFButton type.
 * @param {string|number} [props.height] - PFButton height.
 * @param {string} [props.margin] - PFButton margin.
 * @param {string} [props.padding] - PFBUtton padding.
 * @param {Object} [props.config={}] - PFButton additional params.
 *
 * @return {React.ReactElement} The button component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFPublishButton = ({
  text,
  type,
  height,
  margin,
  padding,
  config,
}) => {
  const currentApp = useSelector((state) => state.current_app);
  const domains = currentApp.domains;

  const [selectedDomains, setSelectedDomains] = useState([]);
  const [open, setOpen] = useState(false);

  const [asyncCall, loading, error, errorDismiss] = useAsyncCall();
  const [success, setSuccess] = useState(false);

  const renderDomains = () => {
    return domains.map(({ domain, status, env }) => {
      const checked = selectedDomains.includes(domain);

      const handleCheck = () => {
        if (!checked) {
          setSelectedDomains([...selectedDomains, domain]);
        } else {
          setSelectedDomains(selectedDomains.filter((d) => d !== domain));
        }
      };

      return (
        <PFContainer key={domain} display="flex" alignItems="center" gap="s">
          <PFCheckbox checked={checked} onChange={handleCheck} />
          <PFContainer>
            <PFText size="s">
              {domain}
              {!!env
                ? <PFText tag="span" type="secondary"> · {env}</PFText>
                : null}
            </PFText>
            <PFText size="xs" type="support">{status}</PFText>
          </PFContainer>
        </PFContainer>
      );
    });
  };

  const publishApp = () => {
    if (selectedDomains.length === 0) return;
    asyncCall(async () => {
      setSuccess(false);
      await builderV2.publishApp(currentApp._id, selectedDomains);
      setSuccess(true);
      setOpen(false);
    });
  };

  return (
    <>
      <PFContainer style={{ position: 'relative' }}>
        <PFButton type={type}
          onClick={() => setOpen(!open)}
          height={height} margin={margin} padding={padding}
          config={config} style={{ borderRadius: 25 }}>
          <PFContainer
            display="flex" alignItems="center" gap="s">
            <PFIcon icon="bx bx-rocket" style={{ fontSize: '1.5em' }} />
            {text}
          </PFContainer>
        </PFButton>
        <PFContainer type="form" width={340} radius="s"
          style={{
            position: 'absolute', top: 60, right: 0,
            overflow: 'hidden', zIndex: 10,
            opacity: open ? 1 : 0,
            pointerEvents: open ? 'all' : 'none',
            transition: 'all 200ms ease'
          }}>
          <PFContainer padding="s" display="flex"
            alignItems="center" justifyContent="space-between">
            <PFText size="s">Publish to your domains</PFText>
            <PFButton type="support" height={24} padding="none"
              onClick={() => setOpen(false)}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="s" />
            </PFButton>
          </PFContainer>
          <PFContainer padding="horizontal-s bottom-m"
            display="flex" flexDirection="column" gap="s">
            {renderDomains()}
          </PFContainer>
          <PFButton width="100%"
            onClick={publishApp}
            loading={loading}
            disabled={selectedDomains.length === 0}
            style={{ borderRadius: 0 }}>
            <PFText color="white" size="s">Launch</PFText>
          </PFButton>
          <PFContainer>
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <PFAlert severity="success" message={'App published'}
        open={success} onClose={() => setSuccess(false)} />
      <PFAlert message={error} open={!!error} onClose={errorDismiss} />
    </>
  );
};

PFPublishButton.defaultProps = {
  text: 'Launch',
  type: 'primary',
  config: {},
};

PFPublishButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'support', 'none']),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  padding: PropTypes.string,
  config: PropTypes.object,
};

export default PFPublishButton;
