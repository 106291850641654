import {
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton,
  PFInput,
  PFLine,
  PFLoader, PFSwitch,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { builderTest } from 'src/apis/builderV2/builderV2';
import {
  QueryAggregations,
  QueryColumns,
  QueryFilters,
  QueryJoins,
  QueryOrigin,
  QueryPagination,
  QuerySort
} from './components';

const Query = ({ index, instruction, setInstruction, isCreated }) => {
  const { var_name, execute } = instruction;
  const currentUserId = useSelector((state) => state.user._id);
  const setVarName = (var_name) => setInstruction({ ...instruction, var_name });
  const setType = (type) => setInstruction({ ...instruction, type });
  const setExecute = (execute) => setInstruction({ ...instruction, execute });
  const [allowPagination, setAllowPagination] = useState(false);
  const [allowSortBy, setAllowSortBy] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [db_fetch, alerts] = useAsyncCalls(false);
  const [allOptions_fetch, allOptions_alerts] = useAsyncCalls(false);

  const { docs: databases } = useSelector(
    (state) => state.current_function.associated_dbs);
  const [db, setDb] = useState(null);
  const tableSelected = !!db && !!execute?.schema_db && !!execute?.table;
  const table = tableSelected
    ? db.tables.find((table) => table.table_name === execute.table)
    : null;
  const columnOptions = !!table
    ? table.fields.map((field) => ({
      label: field.alias, value: field.column_name,
    }))
    : null;

  const originOptions = {
    [execute?.table + '_' + execute.schema_db]: columnOptions
      ? columnOptions.map((optionElement) => ({
        ...optionElement,
        value: '${' + execute?.schema_db + '}'
          + '.' + execute?.table + '.' + optionElement.value
      }))
      : []
  };

  const [joinOptions, setJoinOptions] = useState([]);

  useEffect(() => {
    if (isCreated) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  }, []);

  useEffect(() => {
    db_fetch.asyncCall(async () => {
      if (!!execute.schema_db) {
        const database = await builderTest(currentUserId)
          .getDatabase(execute.schema_db);
        setDb(database);
      }
    });
  }, [execute.schema_db]);
  useEffect(() => {
    if (execute.pagination) {
      if (execute.pagination.page_size || execute.pagination.page_number) {
        setAllowPagination(true);
      } else {
        setAllowPagination(false);
      }

      if (execute.pagination.sort_by || execute.pagination.sort_column) {
        setAllowSortBy(true);
      } else {
        setAllowSortBy(false);
      }
    }
  }, []);
  const setSchemaDB = (schema_db) => {
    setExecute({
      schema_db, schema: `$\{${schema_db}\}`,
      table: '', columns: [], filters: [], joins: [], distinct: 'false',
      aggregations: [], pagination: {}
    });
  }
    ;
  const setTable = (table) => {
    setAllowPagination(false);
    setAllowSortBy(false);
    setExecute({
      ...execute, table, columns: [], filters: [], joins: [], aggregations: [],
      pagination: {}
    });
  };
  const setFilters = (filters) => setExecute({ ...execute, filters });
  const setColumns = (columns) => setExecute({ ...execute, columns });
  const setJoins = (joins) => {
    setExecute({ ...execute, joins });

    allOptions_fetch.asyncCall(async () => {
      const currentJoins = joins.map((joinElement) => joinElement.table
        + '_' + joinElement.schema_db);
      const processedIds = new Set();
      const result = {};
      for (const option of currentJoins) {
        const optionSplitted = option.split('_');
        const schemaDbId = optionSplitted[optionSplitted.length - 1];
        if (schemaDbId && !processedIds.has(schemaDbId)) {
          processedIds.add(schemaDbId);

          const database = await builderTest(currentUserId)
            .getDatabase(schemaDbId);

          currentJoins.forEach((item) => {
            const lastUnderscoreIndex = item.lastIndexOf('_');
            const keyPart = item.substring(0, lastUnderscoreIndex);

            const match = database?.tables
              .find((table) => table.table_name === keyPart);
            if (match) {
              result[item] = match.fields.map((matchedItem) => {
                return (
                  {
                    label: matchedItem.alias,
                    value: '${' + schemaDbId + '}'
                      + `.${match.table_name}.${matchedItem.column_name}`
                  }
                );
              });
            }
          });
          setJoinOptions({ ...originOptions, ...result });
        }
      }
    });
  };
  const setDistinct = (distinct) => setExecute({ ...execute, distinct });
  const setSortColumn = (sort_column) => (
    setExecute({
      ...execute, pagination: {
        ...execute.pagination, sort_column
      }
    }));
  const setSortOrder = (sort_order) => (
    setExecute({
      ...execute, pagination: {
        ...execute.pagination, sort_order
      }
    }));
  const setPageSize = (page_size) => setExecute({
    ...execute, pagination: {
      ...execute.pagination,
      page_size
    }
  });
  const setPageNumber = (page_number) => setExecute({
    ...execute, pagination: {
      ...execute.pagination,
      page_number
    }
  });
  const setAggregations = (aggregations) => {
    setExecute({ ...execute, aggregations });
  };
  return (
    <>
      <PFContainer type="form" padding="m" radius="m"
        display="flex" flexDirection="column" gap="m">
        <PFContainer display="flex"
          justifyContent="space-between" alignItems="center">
          <PFContainer height={30} display="flex" alignItems="center" gap="s">
            {
              !isCollapsed && <PFIconButton
                icon="bx bx-chevron-left"
                onClick={() => setType('')} />
            }
            <PFText type="secondary">Instruction {index + 1}:</PFText>
            <PFText>Query data</PFText>
            {db_fetch.loading
              ? <PFLoader color="var(--secondary)" size="s"
                margin="top-xs horizontal-s" />
              : null
            }
          </PFContainer>
          <PFButton type='none' padding='0' onClick={() => {
            setIsCollapsed((prev) => !prev);
          }}>
            <PFIcon icon={`bx bx-chevron-${isCollapsed
              ? 'down' : 'up'}`} size='l'
              color='var(--purple-200)' />
          </PFButton>
        </PFContainer>
        <PFContainer display={isCollapsed ? 'none'
          : 'flex'} flexDirection='column' gap='m'>
          <PFLine />
          <PFContainer display="flex" alignItems="center" gap="m">
            <PFText type="secondary">Target variable</PFText>
            <PFContainer flex={1}>
              <PFInput value={var_name}
                onChange={(e) => setVarName(e.target.value)}
                placeholder={'This is the name of the variable'
                  + ' your query will be stored in'} />
            </PFContainer>
          </PFContainer>
          <PFLine />
          <PFContainer display="flex" flexDirection="column" gap="l">
            <QueryOrigin
              databases={databases}
              db={db}
              schemaDB={execute?.schema_db}
              setSchemaDB={setSchemaDB}
              table={execute?.table}
              setTable={setTable}
              tableSelectionDisabled={!db || db_fetch.loading}
            />
            {tableSelected
              ? <>
                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s">
                    <PFText>Columns to retrieve</PFText>
                    <PFText type="support" size="s">
                      If no columns are added, the query will return all
                    </PFText>
                  </PFContainer>
                  <QueryColumns
                    columns={execute.columns}
                    setColumns={setColumns}
                    columnOptions={columnOptions} />
                </PFContainer>
                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s">
                    <PFText>Filter by</PFText>
                    <PFText type="support" size="s">
                      Define the conditions needed to retrieve a record
                    </PFText>
                  </PFContainer>
                  <QueryFilters
                    filters={execute.filters}
                    setFilters={setFilters}
                    columnOptions={columnOptions}
                  />
                </PFContainer>
                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s"
                    justifyContent='space-between' margin='bottom-s'>
                    <PFContainer display="flex" alignItems="center" gap="s">
                      <PFText>Sort by</PFText>
                      <PFText type="support" size="s">
                        Define the order in which the records will be presented
                      </PFText>
                    </PFContainer>
                    <PFSwitch onChange={() => {
                      setAllowSortBy((prev) => {
                        if (prev === true) {
                          delete execute.pagination?.sort_column;
                          delete execute.pagination?.sort_order;
                        }
                        return !prev;
                      });
                    }} checked={allowSortBy} />

                  </PFContainer>

                  {
                    allowSortBy
                    && <QuerySort
                      execute={execute}
                      db={db}
                      setSortOrder={setSortOrder}
                      setSortColumn={setSortColumn}
                    />
                  }

                </PFContainer>
                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s"
                    justifyContent='space-between' margin='bottom-s'>
                    <PFContainer display="flex" alignItems="center" gap="s">
                      <PFText>Pagination</PFText>
                      <PFText type="support" size="s">
                        Divide your query into pages
                      </PFText>
                    </PFContainer>
                    <PFSwitch onChange={() => {
                      setAllowPagination((prev) => {
                        if (prev === true) {
                          delete execute.pagination?.page_size;
                          delete execute.pagination?.page_number;
                        }
                        return !prev;
                      });
                    }} checked={allowPagination} />
                  </PFContainer>
                  {
                    allowPagination
                    && <QueryPagination
                      execute={execute}
                      setPageSize={setPageSize}
                      setPageNumber={setPageNumber}
                    />
                  }
                </PFContainer>

                <PFContainer display='flex' justifyContent='space-between'>
                  <PFContainer display="flex" alignItems="center" gap="s"
                    margin='bottom-s'>
                    <PFText>Distinct</PFText>
                    <PFText type="support" size="s">
                      If the query returns a duplicated record
                      (given the retrieved columns), make it unique
                    </PFText>
                  </PFContainer>
                  <PFSwitch
                    checked={execute?.distinct === 'true' ? true : false}
                    onChange={() => {
                      setDistinct(execute?.distinct === 'true'
                        ? 'false' : 'true');
                    }} />

                </PFContainer>
                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s">
                    <PFText>Joins with other tables</PFText>
                    <PFText type="support" size="s">
                      Add more queries, based on a common ID
                    </PFText>
                  </PFContainer>
                  <QueryJoins
                    joins={execute.joins}
                    setJoins={setJoins}
                    tableOrigin={execute?.table}
                    schemaOrigin={execute.schema_db}
                    originOptions={originOptions} joinOptions={joinOptions}
                    setJoinOptions={setJoinOptions} />
                </PFContainer>

                <PFContainer>
                  <PFContainer display="flex" alignItems="center" gap="s">
                    <PFText>Aggregate data</PFText>
                    <PFText type="support" size="s">
                      Calculate operations over all retrieved records
                    </PFText>
                  </PFContainer>
                  <QueryAggregations
                    aggregations={execute.aggregations
                      ? execute.aggregations : []}
                    setAggregations={setAggregations}
                    execute={execute}
                    db={db} />
                </PFContainer>

              </>
              : null}
          </PFContainer>
        </PFContainer>
      </PFContainer>
      {alerts()}
      {allOptions_alerts()}
    </>
  );
};

Query.propTypes = {
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object.isRequired,
  setInstruction: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
};

export default Query;
