import { PFContainer } from 'PFComponents/common';
import { permissions } from 'PFUtils';
import { useSelector } from 'react-redux';
import { SharingPanel } from './components';


const FunctionsDashboardActions = () => {
  const currentFunction = useSelector((state) => state.current_function);

  const userRole = currentFunction.current_user_role;
  const hasAdminAccess = permissions.hasAdminAccess(userRole);

  return (
    <PFContainer type="main"
      display="flex" justifyContent="flex-end" gap="s">
      {hasAdminAccess
        ? <>
          <SharingPanel />
        </>
        : <PFContainer height={30} />}
    </PFContainer>
  );
};

export default FunctionsDashboardActions;
