import { builderV2 } from 'PFApis';
import {
  PFAlert, PFContainer, PFLoader, PFSelect, PFText
} from 'PFComponents/common';
import { PFProcessFlowEditor } from 'PFComponents/editors';
import { PFVariablesDictionary } from 'PFComponents/special';
import { useAsyncCall, useShortcut } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FlowEditorHeader, FlowNameEditor } from './components';
import './PFScreenFlowEditor.css';

const CopyAlert = ({ children, isOpen }) => {
  return (
    <>
      {
        isOpen && <PFContainer
          radius='s'
          padding='left-m right-m top-s bottom-s'
          background='#181524'
          style={{
            position: 'fixed',
            zIndex: '200',
            bottom: '15px',
            left: '15px'
          }}>
          <PFText color='#fff'>{children}</PFText>
        </PFContainer>
      }
    </>
  );
};

const PFScreenFlowEditor = () => {
  const { app_id, flow_id } = useParams();
  const currentApp = useSelector((state) => state.current_app);
  const processFlows = currentApp.build.process_flows.docs;
  const [openDictionary, setOpenDictionary] = useState(false);
  const [fetchAsyncCall, fetchLoading, fetchError, dismissFetchError]
    = useAsyncCall(true);
  const [saveAsyncCall, saveLoading, saveError, dismissSaveError]
    = useAsyncCall(false);

  const [flow, setFlow] = useState(null);
  const [saved, setSaved] = useState(false);

  const [copiedAlert, setCopiedAlert] = useState(false);

  const updateFlow = (flow) => {
    setSaved(false);
    setFlow(flow);
  };
  const pfOptions = processFlows.filter(({ _id }) => _id !== flow?._id)
    .map(({ _id, alias }) => {
      return { label: alias, value: _id };
    });
  const saveFlow = () => {
    saveAsyncCall(async () => {
      if (flow.nodes.hasOwnProperty('holder_node')) {
        const nextNodeId = flow.nodes.holder_node.next;

        const updatedNodes = { ...flow.nodes };
        delete updatedNodes.holder_node;

        const updatedData = {
          ...flow,
          root_node: nextNodeId,
          nodes: updatedNodes
        };

        await builderV2.updateFlow(app_id, flow_id, updatedData);
        store.dispatch(currentAppActions.updateFlow(updatedData));
      } else {
        await builderV2.updateFlow(app_id, flow_id, flow);
        store.dispatch(currentAppActions.updateFlow(flow));
      }

      setSaved(true);
    });
  };

  useEffect(() => {
    fetchAsyncCall(async () => {
      setSaved(false);
      const flow = await builderV2.getFlow(app_id, flow_id);
      const holderNode = {
        '_id': 'holder_node',
        'next': flow.root_node
      };
      const updatedNodes = {
        ...flow.nodes,
        [holderNode._id]: holderNode
      };

      const updatedFlow = {
        ...flow,
        root_node: holderNode._id,
        nodes: updatedNodes
      };
      setFlow(updatedFlow);
    });
  }, [app_id, flow_id]);

  useShortcut('ctrl+s', saveFlow);

  useShortcut('ctrl+k', () => {
    setOpenDictionary(true);
  });

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();

        setOpenDictionary(false);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);


  return (
    <>
      {
        openDictionary
        && <PFVariablesDictionary closeDictionary={() => {
          setOpenDictionary(false);
        }} setCopiedAlert={setCopiedAlert} />
      }
      <div style={{
        filter: openDictionary ? 'blur(10px)' : 'unset'
      }}>
        <FlowEditorHeader
          appId={app_id}
          flowId={flow_id}
          save={saveFlow}
          saveLoading={saveLoading}
          saved={saved} />
        <PFContainer display="flex" flex={1} alignItems='stretch'>
          <PFContainer flex={1}>
            <PFContainer className="flows-editor-background" padding="l">
              {fetchLoading || !flow
                ? <PFLoader area color="white" />
                : <>
                  <PFContainer display='flex' alignItems='center'>
                    <FlowNameEditor flow={flow} updateFlow={updateFlow} />
                    <PFContainer width='170px' margin='left-m' style={{
                      position: 'relative', zIndex: '2'
                    }}>
                      <PFSelect
                        options={pfOptions}
                        onChange={(e) => {
                          updateFlow({ ...flow, async: e.target.value });
                        }}
                        value={flow.async ? flow.async : []}
                        style={{
                          height: 30, fontSize: 'var(--text-label)',
                        }}
                        optionsStyles={{
                          color: 'var(--white)'
                        }}
                        listStyles={{
                          border: '1px solid var(--purple-300)',
                          backgroundColor: 'var(--purple-500)'
                        }}
                        multiple
                        placeholder={`Async process flows: ${flow.async
                          ? flow.async.length : 0}`}
                        iconColor='var(--purple-100)' />
                    </PFContainer>
                  </PFContainer>

                  <PFContainer padding="vertical-xl right-xl"
                    display="flex" justifyContent="flex-start">
                    <PFProcessFlowEditor flow={flow} setFlow={updateFlow} />
                  </PFContainer>
                </>
              }
              <PFAlert
                message={fetchError}
                open={!!fetchError}
                onClose={dismissFetchError} />
              <PFAlert
                message={saveError}
                open={!!saveError}
                onClose={dismissSaveError} />
              <CopyAlert isOpen={copiedAlert}>
                Copied to clipboard
              </CopyAlert>
            </PFContainer>
          </PFContainer>
        </PFContainer >
      </div>

    </>
  );
};

CopyAlert.propTypes = {
  children: PropTypes.string,
  isOpen: PropTypes.bool
};

export default PFScreenFlowEditor;
