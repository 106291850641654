import { PFContainer, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import VariableItem from './VariableItem';

const AvailableVariables = ({ variables }) => {
  return (
    <PFContainer background='var(--purple-600)'>
      <PFContainer >
        {
          variables.filter((filteredElement) =>
            !!filteredElement.name).length > 0
            ? <PFText padding='s'>
              Available variables
            </PFText>
            : <PFText padding='s'>
              No variables available
            </PFText>
        }

      </PFContainer>
      <PFContainer className='pf-scroll' style={{
        maxHeight: '600px',
        overflow: 'auto'
      }}>
        {
          variables.filter((filteredElement) => !!filteredElement.name)
            .map((variableElement, index) => {
              return (
                <VariableItem key={index} variableName={variableElement.name}
                  variableOrigin={variableElement.origin} />
              );
            })
        }
      </PFContainer>
    </PFContainer>
  );
};

AvailableVariables.propTypes = {
  variables: PropTypes.array
};

export default AvailableVariables;
