import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import * as Styled from './googlemaps.styled';

const getAddressComponents = (address: google.maps.GeocoderAddressComponent[]) => {
    if (Array.isArray(address)) {
        return address.map((component) => component.long_name);
    } else {
        return [];
    }
};

export type GooglemapsProps = {
    /**
     * The Google Maps API key.
     */
    apiKey: string;

    /**
     * for component-level styling over ride (Design System)
     */
    configStyles?: string;

    /**
     * Enables/disables all default UI buttons.
     */
    disableControls?: boolean;

    /**
     * If true, the marker can be dragged.
     */
    draggableMarker?: boolean;

    /**
     * The enabled/disabled state of the Fullscreen control.
     */
    fullScreenControl?: boolean;

    /**
     * Setting controls how the API handles gestures on the map
     */
    gestureHandling?: 'cooperative' | 'greedy' | 'none' | 'auto';

    /**
     * Identifies the Map component
     */
    id: string;

    /**
     * Defines the initial location of the marker on the map as well as its center
     */
    initialPosition?: google.maps.LatLngLiteral;

    /**
     * The initial Map zoom level.
     */
    initialZoom?: number;

    /**
     * Defines the height of the map in PX units.
     */
    mapHeight?: number;

    /**
     * Select between map component and searchbox component
     */
    mapType: 'standaloneSearchbox' | 'map' | 'mapWithSearchbox';

    /**
     * Defines the width of the map in PX units.
     */
    mapWidth?: number;

    /**
     * Defines the marker visibility.
     */
    markerVisible?: boolean;

    /**
     * Specifies the name of the Map component
     */
    name: string;

    /**
     * a function to be triggered when the input element loses focus
     */
    onBlur?: () => void;

    /**
     * a function to be triggered when the input element value change
     */
    onChange?: any;

    /**
     * a function to be triggered when the input element gets focus
     */
    onFocus?: () => void;

    /**
     * Defines the placeholder for input searchbox
     */
    searchPlaceholder?: string;

    /**
     * Convention [Forms] : This is prop (aux) is used to set this field's value on a form
     * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
     */
    setValue?: any;

    /**
     * The initial enabled/disabled state of the Street View Pegman control.
     */
    streetViewControl?: boolean;

    /**
     * Specifies the map value as lat, lng and address
     */
    value?: { lat: number; lng: number; address: string; addressComponents: string[] };

    /**
     * The enabled/disabled state of the Zoom control.
     */
    zoomControl?: boolean;
};

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const mapsLibraries: Libraries = ['places'];

export function Googlemaps({
    apiKey,
    configStyles,
    disableControls = false,
    draggableMarker,
    fullScreenControl,
    gestureHandling,
    id,
    initialPosition,
    initialZoom = 15,
    mapHeight,
    mapType,
    mapWidth,
    markerVisible,
    name,
    onBlur,
    onChange,
    onFocus,
    searchPlaceholder = 'Custom input search',
    setValue = () => {},
    streetViewControl,
    value,
    zoomControl,
}: GooglemapsProps) {
    const mapRef = useRef<google.maps.Map | null>(null);
    const [searchBox, setSearchBox] = useState<google.maps.places.Autocomplete | null>(null);
    const [placeBounds, setPlaceBounds] = useState<google.maps.LatLngLiteral | undefined>(undefined);

    function configBounds(position?: GeolocationPosition) {
        if (initialPosition) {
            return initialPosition;
        } else if (value?.lat && value.lng) {
            return {
                lat: value.lat,
                lng: value.lng,
            };
        } else if (position) {
            return {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        } else {
            return { lat: 40.7579747, lng: -73.9855426 };
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setPlaceBounds(configBounds(position));
            },
            function (error) {
                console.log('Geolocation Denied', error);
                setPlaceBounds(configBounds());
            },
        );
    }, [initialPosition]);

    const mapOptions = {
        fullscreenControl: fullScreenControl,
        disableDefaultUI: disableControls,
        gestureHandling: gestureHandling,
        mapTypeControlOptions: {
            position: 6,
        },
        fullscreenControlOptions: {
            position: 8,
        },
        streetViewControl: streetViewControl,
        zoom: initialZoom,
        zoomControl: zoomControl,
    };

    const markerOptions = {
        draggable: draggableMarker,
        visible: markerVisible,
    };

    const containerStyle = {
        width: mapWidth ? `${mapWidth}px` : '100%',
        height: mapHeight ? `${mapHeight}px` : '100%',
    };

    const onLoadMap = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    const onLoadSearchBox = (ref: google.maps.places.Autocomplete) => {
        if (ref) {
            setSearchBox(ref);
        }
    };

    function handlePlaceChanged() {
        const place = searchBox && searchBox.getPlace();
        if (place && place.geometry && place.geometry.location && place.address_components && place.formatted_address) {
            const placeLat = place.geometry.location.lat();
            const placeLng = place.geometry.location.lng();
            setValue({
                lat: placeLat,
                lng: placeLng,
                address: place.formatted_address,
                addressComponents: getAddressComponents(place.address_components),
            });

            setPlaceBounds({
                lat: placeLat,
                lng: placeLng,
            });
        } else {
            console.error('No se pudo obtener la ubicación del lugar');
        }
    }
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries: mapsLibraries,
    });
    if (!isLoaded) return <div>Loading...</div>;
    if (!placeBounds) return <p>Cargando...</p>;
    if (mapType === 'map') {
        return (
            <GoogleMap
                id={id}
                center={placeBounds}
                mapContainerStyle={containerStyle}
                options={mapOptions}
                onLoad={onLoadMap}
            >
                <Marker position={placeBounds} options={markerOptions} />
            </GoogleMap>
        );
    }
    if (mapType === 'mapWithSearchbox') {
        return (
            <GoogleMap
                id={id}
                center={placeBounds}
                mapContainerStyle={containerStyle}
                options={mapOptions}
                onLoad={onLoadMap}
            >
                <Marker position={placeBounds} options={markerOptions} />
                <Autocomplete onLoad={onLoadSearchBox} onPlaceChanged={handlePlaceChanged}>
                    <Styled.SearchInput
                        defaultValue={value?.address}
                        type={'text'}
                        placeholder={searchPlaceholder}
                        name={name}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        configStyles={configStyles}
                    />
                </Autocomplete>
            </GoogleMap>
        );
    }
    if (mapType === 'standaloneSearchbox') {
        return (
            <Autocomplete onLoad={onLoadSearchBox} onPlaceChanged={handlePlaceChanged}>
                <Styled.SearchInput
                    type={'text'}
                    placeholder={searchPlaceholder}
                    name={name}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    mapType={mapType}
                    configStyles={configStyles}
                />
            </Autocomplete>
        );
    }
}
