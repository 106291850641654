export const processOperationForEdit = (operation) => {
  const request_values = Object.entries(operation.request_values);

  // const response_format = !_.isEmpty(operation.response_format)
  //   ? JSON.stringify(operation.response_format, null, '  ')
  //   : '';

  const error_messages = Object.entries(operation.error_messages)
    .map(([code, object]) => [
      code,
      !_.isEmpty(object) ? JSON.stringify(object, null, '  ') : ''
    ]);

  return {
    ...operation,
    request_values,
    // response_format,
    error_messages,
  };
};

export const processOperationForSave = (operation) => {
  const request_values = Object.fromEntries(operation.request_values);

  // const response_format = !!operation.response_format
  //   ? JSON.parse(operation.response_format)
  //   : {};

  const error_messages = Object.fromEntries(operation.error_messages
    .map(([code, object]) => [code, !!object ? JSON.parse(object) : {}]));

  console.log({
    ...operation,
    request_values,
    // response_format,
    error_messages,
  });

  return {
    ...operation,
    request_values,
    // response_format,
    error_messages,
  };
};
