import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, { currentFunctionActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OperationPublicEditor } from '../PFScreenOperationEditor/components';

const PFScreenFunctionOperations = () => {
  const navigate = useNavigate();
  const currentFunction = useSelector((state) => state.current_function);
  const operations = currentFunction.operations;
  const [targetOperation, setTargetOperation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);
  const [deleteAsyncCall, , deleteError, dismissDeleteError]
    = useAsyncCall(false);

  const baseURL = `/editor/functions/${currentFunction._id}/operations`;

  const createOperation = () => {
    createAsyncCall(async () => {
      const name = `Operation ${operations.length + 1}`;

      const { InsertedID }
        = await builderV2.createFunctionOperation(
          currentFunction._id, { name });
      navigate(`${baseURL}/${InsertedID}`);
    });
  };

  const duplicateOperation = async (operationId, operationName) => {
    const duplicateData = { name: `${operationName} copy` };

    const res = await builderV2.copyFunctionOperation(
      currentFunction._id, operationId, duplicateData);
    navigate(`${baseURL}/${res._id}`);
  };

  const [DeletionDialog, confirmDeletion] = useConfirmation(
    'Are you sure you want to delete this operation?',
  );

  const deleteOperation = (opId) => {
    deleteAsyncCall(async () => {
      if (await confirmDeletion()) {
        store.dispatch(currentFunctionActions.removeOperation(opId));
        await builderV2.deleteFunctionOperation(currentFunction._id, opId);
      }
    });
  };


  const renderOperations = () => {
    return operations.map((op) => {
      return (
        <PFContainer key={op._id} style={{
          position: 'relative'
        }}>
          {
            op?.is_public_endpoint
              ? <PFContainer style={{
                position: 'absolute',
                left: '20px',
                top: '15px',
                zIndex: 1000
              }}>
                <PFButton type='none' height='auto' padding='0'
                  width='45px' margin='right-s'
                  onClick={() => {
                    setTargetOperation(op);
                    setIsModalOpen(true);
                  }}>
                  <PFIcon icon={'bx bxs-cloud'}
                    size='xl'
                    color={'var(--purple-100)'} />
                </PFButton>
              </PFContainer>
              : null
          }


          <PFItemCard
            path={`${baseURL}/${op._id}`}
            options={{
              'Delete': () => deleteOperation(op._id),
              'Copy ID': () => generalUtils.copyToClipboard(op._id),
              'Duplicate': () => duplicateOperation(op._id, op.name),
            }}>
            <PFText margin="top-m">{op.name}</PFText>
            <PFText type="secondary" size="s" margin="top-s">
              id: {op._id}
            </PFText>
          </PFItemCard>
        </PFContainer>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Operations</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={createOperation} loading={createLoading}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Operation</PFText>
          </PFActionCard>
          {renderOperations()}
        </PFContainer>
      </PFContainer>
      <DeletionDialog />
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
      <PFAlert
        message={deleteError}
        open={!!deleteError}
        onClose={dismissDeleteError} />
      <OperationPublicEditor operation={targetOperation}
        isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
        functionId={currentFunction?._id} />
    </PFContainer>
  );
};

export default PFScreenFunctionOperations;
