import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';


const ActionNodeSelection = ({
  options,
  title,
  setTab,
  createNode,
  next,
  setIsHovered }) => {
  const firstRender = useRef(true);
  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!!componentRef.current
        && !componentRef.current.contains(e.target)
        && !firstRender.current) {
        setTab('START');
      }
      if (firstRender.current) firstRender.current = false;
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const renderOptions = () => {
    return options.map(({ name, type, icon, create }) => {
      return (
        <PFButton
          key={type}
          type="none"
          onClick={() => createNode(create(next, setTab, setIsHovered))}
          style={{
            background: 'var(--purple-25)',
            borderRadius: 8,
            aspectRatio: 1
          }}>
          <PFContainer
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="s">
            <PFIcon icon={icon} size="l" color="var(--primary)" />
            <PFContainer height={36} display='flex' alignItems="center">
              <PFText weight={300}>{name}</PFText>
            </PFContainer>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <div ref={componentRef}>
      <PFContainer width={400} type="form" padding="m" radius="m">
        <PFContainer
          display="flex"
          alignItems="center"
          gap="s"
          margin="bottom-m">
          <PFButton type="support" className="flow-dismiss-button"
            onClick={() => setTab('TYPE_SELECTION')}>
            <PFIcon icon="bx bx-chevron-left" size="l" />
          </PFButton>
          <PFText type="support" weight={300}>{title}</PFText>
        </PFContainer>
        <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="m">
          {renderOptions()}
        </PFContainer>
      </PFContainer>
    </div>
  );
};

ActionNodeSelection.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  setIsHovered: PropTypes.func,
  createNode: PropTypes.func.isRequired,
  next: PropTypes.string
};

export default ActionNodeSelection;
