import PropTypes from 'prop-types';
import { useState } from 'react';

import { builderV2 } from 'PFApis';
import {
  PFButton,
  PFContainer,
  PFIcon,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCalls } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';

const EnvSelectionModal = ({
  appId,
  domain,
  setDomainEnv,
  envs,
  active,
  onDismiss
}) => {
  const [selectedEnv, setSelectedEnv] = useState(null);

  const [save, alerts] = useAsyncCalls(false);

  const setEnv = () => {
    save.asyncCall(async () => {
      await builderV2.setDomainEnv(appId, domain, selectedEnv);
      store.dispatch(currentAppActions.updateDomain({
        domain, env: selectedEnv,
      }));
      setDomainEnv(selectedEnv);
      setSelectedEnv(null);
      onDismiss();
    });
  };

  const renderEnvs = () => {
    const envsList = Object.keys(envs);
    return envsList.map((env) => {
      const selected = selectedEnv === env;

      const handleSelection = () => {
        if (selected) {
          setSelectedEnv(null);
        } else {
          setSelectedEnv(env);
        }
      };

      return (
        <PFButton key={env} type="none" onClick={handleSelection}>
          <PFContainer height={100} radius="m" width="100%" padding="m"
            display="flex" flexDirection="column" justifyContent="end"
            background="var(--strokes)"
            style={{ position: 'relative', textAlign: 'left' }}
            border={selected ? '1px solid var(--secondary)' : 'none'}>
            <PFContainer background={selected ? 'var(--secondary)' : 'white'}
              display='flex' alignItems='center' justifyContent='center'
              border={selected ? 'none' : '1px solid var(--secondary)'}
              style={{
                position: 'absolute', top: 15, right: 15,
                width: 24, borderRadius: '50%', aspectRatio: 1
              }}>
              <PFIcon icon="bx bx-check" color="white" />
            </PFContainer>
            <PFText size="s">{env}</PFText>
          </PFContainer>
        </PFButton>
      );
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="support">Set up an environment</PFText>
            <PFButton type="support" onClick={onDismiss}
              padding="none" height={28}
              style={{
                background: 'var(--purple-50)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>
          </PFContainer>
          <PFContainer display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="s">
            {renderEnvs()}
          </PFContainer>
          <PFContainer margin="top-l" display="flex" justifyContent="end">
            <PFButton onClick={setEnv}
              loading={save.loading}
              disabled={!selectedEnv}
              padding="horizontal-xl">
              Set up environment
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal >
      {alerts()}
    </>
  );
};

EnvSelectionModal.propTypes = {
  appId: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  setDomainEnv: PropTypes.func.isRequired,
  envs: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default EnvSelectionModal;
