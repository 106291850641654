import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const PFScreenAppIntegrations = () => {
  const navigate = useNavigate();

  const currentApp = useSelector((state) => state.current_app);
  const apis = currentApp.build.api_declarations.docs;

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);
  const [deleteAsyncCall, , deleteError, dismissDeleteError]
    = useAsyncCall(false);

  const baseURL = `/editor/apps/${currentApp._id}/integrations`;

  const createIntegration = () => {
    createAsyncCall(async () => {
      const alias = `API Integration ${apis.length + 1}`;
      const creationData = { info: { alias } };

      const { InsertedID }
        = await builderV2.createAPI(currentApp._id, creationData);
      navigate(`${baseURL}/${InsertedID}`);
    });
  };

  const [DeletionDialog, confirmDeletion] = useConfirmation(
    'Are you sure you want to delete this API integration?',
  );

  const duplicateAPI = async (apiId, apiAlias) => {
    const duplicateData = { alias: `${apiAlias} copy` };

    const res
      = await builderV2.duplicateAPI(currentApp._id, apiId, duplicateData);
    navigate(`${baseURL}/${res._id}`);
  };

  const deleteAPI = (apiId) => {
    deleteAsyncCall(async () => {
      if (await confirmDeletion()) {
        store.dispatch(currentAppActions.removeApiDeclaration(apiId));
        await builderV2.deleteAPI(currentApp._id, apiId);
      }
    });
  };


  const renderPages = () => {
    return apis.map((api) => {
      return (
        <PFItemCard
          key={api._id}
          path={`${baseURL}/${api._id}`}
          options={{
            'Delete': () => deleteAPI(api._id),
            'Copy ID': () => generalUtils.copyToClipboard(api._id),
            'Duplicate': () => duplicateAPI(api._id, api.alias),
          }}>
          <PFText margin="top-m">{api.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {api._id}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer>
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Integrations</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={createIntegration} loading={createLoading}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Integration</PFText>
          </PFActionCard>
          {renderPages()}
        </PFContainer>
      </PFContainer>
      <DeletionDialog />
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
      <PFAlert
        message={deleteError}
        open={!!deleteError}
        onClose={dismissDeleteError} />
    </PFContainer>
  );
};

export default PFScreenAppIntegrations;
