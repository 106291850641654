import { GridColumnMenu } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { ColumnSettings, DeleteColumn } from './components';

const ColumnSettingsComponent = ({ setUpdateTable,
  setExitModeWarning,
  ...props }) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnSettingsItem: ColumnSettings,
        columnDeleteItem: DeleteColumn,
      }}
      slotProps={{
        columnSettingsItem: {
          displayOrder: 90,
          setUpdateTable: setUpdateTable,
          setExitModeWarning: setExitModeWarning
        },
        columnDeleteItem: {
          displayOrder: 100,
          setUpdateTable: setUpdateTable,
          setExitModeWarning: setExitModeWarning
        },
      }}
    />
  );
};

ColumnSettingsComponent.propTypes = {
  setValues: PropTypes.func,
  setUpdateTable: PropTypes.func,
  setExitModeWarning: PropTypes.func,
};

export default ColumnSettingsComponent;
