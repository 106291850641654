import { builderV2 } from 'PFApis';
import {
  PFAlert, PFContainer, PFIcon, PFLink,
  PFLoader, PFText
} from 'PFComponents/common';
import {
  PFDashboardHeader
} from 'PFComponents/navigation';
import { useAsyncCall } from 'PFHooks';
import {
  PFScreenFunctionDBs,
  PFScreenFunctionEnvironmentDetail,
  PFScreenFunctionEnvironments,
  PFScreenFunctionOperations
} from 'PFScreens';
import store, { currentFunctionActions } from 'PFStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  FunctionsDashboardActions,
  FunctionsDashboardSidebar
} from './components';


const PFLayoutFunctionDashboard = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const currentFunction = useSelector((state) => state.current_function);
  const { function_id } = useParams();
  const currentApp = useSelector((state) => state.current_app);

  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);

  useEffect(() => {
    asyncCall(async () => {
      const func = await builderV2.getFunction(function_id);
      store.dispatch(currentFunctionActions.setCurrentFunction(func));
    });
  }, [function_id]);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <PFDashboardHeader />
        {loading || !currentFunction
          ? <PFLoader area color="white" />
          : <PFContainer padding="horizontal-l vertical-m">
            <PFContainer display='flex' flexDirection='column'>
              <PFContainer>
                <PFLink to={currentApp ? `/apps/${currentApp?._id}/functions`
                  : '/functions'}>
                  <PFContainer display="flex" alignItems="center">
                    <PFIcon icon="bx bx-chevron-left" size="l"
                      color='var(--purple-100)' />
                    <PFText type="secondary" color='var(--purple-100)'>
                      {currentApp ? 'Back to app' : 'Back to all functions'}
                    </PFText>
                  </PFContainer>
                </PFLink>
              </PFContainer>
              <PFContainer display='flex'
                justifyContent='space-between' gap='m'>
                <PFContainer>
                  <PFText tag="h1" size="l">
                    {currentFunction.info.alias}</PFText>
                </PFContainer>
                <FunctionsDashboardActions />
              </PFContainer>
            </PFContainer>

            <PFContainer display='flex' margin='top-l'>
              <FunctionsDashboardSidebar />
              <PFContainer flex={1} margin='left-l'>

                <PFContainer>
                  <Routes>
                    <Route path="/operations"
                      element={<PFScreenFunctionOperations />} />
                    <Route path="/databases"
                      element={<PFScreenFunctionDBs />} />
                    <Route path="/environments"
                      element={<PFScreenFunctionEnvironments />} />
                    <Route path="/environments/:environment_id"
                      element={<PFScreenFunctionEnvironmentDetail />} />
                  </Routes>
                </PFContainer>
              </PFContainer>
            </PFContainer>

          </PFContainer>
        }
      </PFContainer>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </PFContainer>
  );
};

export default PFLayoutFunctionDashboard;
