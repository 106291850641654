import {
  PFContainer, PFInput
} from 'PFComponents/common';
import PropTypes from 'prop-types';


const FlowNameEditor = ({ flow, updateFlow }) => {
  return (
    <PFContainer background="none"
      display="flex" gap="m" alignItems="center"
      justifyContent="space-between"
      style={{ position: 'relative', zIndex: 1 }}>
      <PFContainer display="flex" alignItems="center" gap="s">
        <PFInput className="alt" width="400px"
          value={flow?.alias} placeholder="Process flow name"
          onChange={(e) => {
            updateFlow({ ...flow, alias: e.target.value });
          }}
          fontSize='l'
          style={{
            color: 'white',
            borderBottom: '1px solid var(--purple-100)'
          }} />
      </PFContainer>
    </PFContainer>
  );
};

FlowNameEditor.propTypes = {
  flow: PropTypes.object.isRequired,
  updateFlow: PropTypes.func.isRequired,
};

export default FlowNameEditor;
