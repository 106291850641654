export default [
  {
    label: 'INNER',
    value: 'INNER',
  },
  {
    label: 'LEFT OUTER',
    value: 'LEFT_OUTER',
  },
  {
    label: 'RIGHT OUTER',
    value: 'RIGHT_OUTER',
  }
];
