import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFCheckbox,
  PFCodeEditor,
  PFContainer, PFIcon, PFInput, PFLine, PFSelect, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
/* eslint-disable max-len */
import { DynamicDataSelector } from '../../Canvas/components/Wrapper/components/ComponentMenu/components/ConfigureEditor/components';

const PageSettings = ({ setTogglePageSettings, page, setPage }) => {
  const [savedSettings, setSavedSettings] = useState(undefined);
  const currentApp = useSelector((state) => state.current_app);
  const processFlows = currentApp.build.process_flows.docs;
  const pfOptions = processFlows.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });
  const [metaHolder, setMetaHolder] = useState('');
  const [dataHolder, setDataHolder] = useState('');
  const dynamicData = page?.dynamic_data ?? [];

  useEffect(() => {
    const closeLeftPanel = (event) => {
      if (event.key === 'Escape') {
        setTogglePageSettings(false);
      }
    };

    window.addEventListener('keyup', closeLeftPanel);

    return () => {
      window.removeEventListener('keyup', closeLeftPanel);
    };
  }, []);

  useEffect(() => {
    setMetaHolder(JSON.stringify(page?.meta));
    setDataHolder(JSON.stringify(page?.data));
  }, [page?.meta]);

  const setDynamicData = (dynamic_data) => {
    setPage({ ...page, dynamic_data });
  };
  const [settingsSaveAsyncCall, settingsSaveLoading,
    settingsSaveError, dismissSettingsSaveError] = useAsyncCall(false);
  const saveSettings = async () => {
    settingsSaveAsyncCall(async () => {
      let metaCopy;
      let dataCopy;
      try {
        metaCopy = JSON.parse(metaHolder);
        dataCopy = JSON.parse(dataHolder);
        const settings_slice = {
          requires_auth: page.requires_auth,
          dynamic_data: page.dynamic_data,
          on_load_events: page.on_load_events,
          meta: metaCopy,
          data: dataCopy,
          path: page.path
        };
        const newSettings = settings_slice;
        await builderV2.updatePageSettings(
          page.app_id, page.page_id, newSettings);
        setSavedSettings(true);
      } catch (error) {
        setSavedSettings(false);
      }
    });
  };
  return (
    <PFContainer width='280px'
      height='calc(100vh - 60px)'
      background='var(--purple-500)' padding='s' style={{
        'position': 'absolute', 'left': '45px', 'top': '65px', 'zIndex': '100',
        'overflowY': 'auto'
      }}>
      <PFContainer display='flex' justifyContent='space-between'
        margin='bottom-s'>
        <PFButton type='none'
          padding='xs' onClick={() => {
            setTogglePageSettings(false);
          }}
        >
          <PFIcon icon='bx bx-x' size='l' margin='top-xs'
            color='var(--purple-100)' />
        </PFButton>
        <PFContainer display='flex' justifyContent='end' gap='s'
          alignItems='center'>
          {!settingsSaveLoading && savedSettings
            && <PFContainer
              display="flex" alignItems="center" gap="s"
            >
              <PFText type="secondary">Saved</PFText>
              <PFIcon
                icon="bx bx-check-circle"
                color="var(--secondary)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          {!settingsSaveLoading && savedSettings === false
            && <PFContainer
              display="flex" alignItems="center" gap="s"
            >
              <PFText type="secondary" color='var(--failure)'>Failed</PFText>
              <PFIcon
                icon="bx bx-x-circle"
                color="var(--failure)"
                style={{ fontSize: 24 }} />
            </PFContainer>}
          <PFButton padding='left-m right-m' style={{
            backgroundColor: 'var(--purple-200)', height: 'auto',
            fontSize: 'var(--text-header)'
          }} loading={settingsSaveLoading} onClick={saveSettings}>
            Save
          </PFButton>

        </PFContainer>
      </PFContainer>

      <PFContainer display='flex' alignItems='center' justifyContent='space-between' gap='s'>
        <PFText size='header'>Page Settings</PFText>

      </PFContainer>

      <PFContainer >
        <PFContainer margin='top-s' display='flex' justifyContent='space-between' gap='s'
          alignItems='center'>
          <PFText style={{ flex: 'none', fontSize: '12px' }}>Page path</PFText>
          <PFInput value={page ? page.path : ''} onChange={(e) => {
            setPage({
              ...page,
              path: e.target.value
            });
          }} fontSize='s' />
        </PFContainer>

        <PFContainer margin='top-m' >
          <PFCodeEditor name="body" label="Meta"
            minHeight={100} value={metaHolder}
            onChange={(e) => {
              setMetaHolder(e.target.value);
            }} style={{ fontSize: '12px' }} labelStyles={{ fontSize: '13px' }} />
        </PFContainer>

        <PFContainer margin='top-m' >
          <PFCodeEditor name="body" label="Data"
            minHeight={100} value={dataHolder}
            onChange={(e) => {
              setDataHolder(e.target.value);
            }} style={{ fontSize: '12px' }} labelStyles={{ fontSize: '13px' }} />
        </PFContainer>

        <DynamicDataSelector
          dynamicData={dynamicData}
          setDynamicData={setDynamicData} />
        <PFContainer padding='top-m bottom-m left-s right-s'>
          <PFText margin='bottom-m' style={{
            fontSize: '13px'
          }}>
            On load process flow
          </PFText>
          {
            page?.on_load_events.map((item, index) => {
              return (
                <PFContainer key={`item-${index}`} margin='top-m'>
                  <PFText margin='bottom-s' style={{
                    fontSize: '12px'
                  }}>
                    Process flow {index + 1}</PFText>
                  <PFContainer display='flex' gap='s'>
                    <PFContainer flex={1}>
                      <PFSelect placeholder='Process flow'
                        options={pfOptions}
                        value={item.reference}
                        onChange={(e) => {
                          const pageCopy = { ...page };
                          pageCopy.on_load_events[index].reference
                            = e.target.value;
                          setPage(pageCopy);
                        }}
                        style={{ fontSize: '12px' }}
                        optionsStyles={{
                          color: 'var(--white)'
                        }}
                        listStyles={{
                          border: '1px solid var(--purple-300)',
                          backgroundColor: 'var(--purple-500)'
                        }}
                      />
                    </PFContainer>
                    <PFButton
                      type='none'
                      padding='xs'
                      onClick={() => {
                        const pageCopy = { ...page };
                        pageCopy.on_load_events.splice(index, 1);
                        setPage(pageCopy);
                      }}>
                      <PFIcon icon='bx bx-trash' size='l'
                        color='var(--purple-100)' />
                    </PFButton>
                  </PFContainer>
                  <PFLine margin='top-m bottom-m' />
                </PFContainer>
              );
            })
          }
          <PFButton type='none' padding='0 var(--size-s)' width='100%' height='var(--button-height)' style={{ backgroundColor: 'none', borderRadius: 'calc(var(--button-height) / 2)', border: '1px solid var(--purple-100)', color: 'var(--purple-100)', fontSize: 'var(--text-label)' }}
            onClick={() => {
              setPage({
                ...page,
                on_load_events: [
                  ...page.on_load_events,
                  {
                    source: 'PROCESS_FLOW',
                    reference: '',
                    params: null
                  }
                ]
              });
            }}>+ add process flow</PFButton>
        </PFContainer>
        <PFContainer>
          <PFContainer display='flex' justifyContent='space-between' gap='s'
            margin='top-s'>
            <PFText style={{
              width: '120px', fontSize: 'var(--text-label)',
              flex: 1
            }}>Page needs authentication?</PFText>
            <PFCheckbox checked={page ? page.requires_auth : false}
              onChange={() => {
                setPage({
                  ...page,
                  requires_auth: !page.requires_auth
                });
              }} />
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <PFAlert
        message={settingsSaveError}
        open={!!settingsSaveError}
        onClose={dismissSettingsSaveError} />
    </PFContainer >
  );
};

PageSettings.propTypes = {
  setTogglePageSettings: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
};


export default PageSettings;
