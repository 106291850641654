import { builderV2 } from 'PFApis';
import { PFActionCard, PFItemCard } from 'PFComponents/cards';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useConfirmation } from 'PFHooks';
import store, { currentAppActions } from 'PFStore';
import { generalUtils } from 'PFUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const PFScreenAppFlows = () => {
  const navigate = useNavigate();

  const currentApp = useSelector((state) => state.current_app);
  const flows = currentApp.build.process_flows;

  const [createAsyncCall, createLoading, createError, dismissCreateError]
    = useAsyncCall(false);
  const [deleteAsyncCall, , deleteError, dismissDeleteError]
    = useAsyncCall(false);

  const baseURL = `/editor/apps/${currentApp._id}/flows`;

  const createProcessFlow = () => {
    createAsyncCall(async () => {
      const alias = `Process flow ${flows.count + 1}`;
      const { InsertedID }
        = await builderV2.createFlow(currentApp._id, { alias });
      navigate(`${baseURL}/${InsertedID}`);
    });
  };

  const duplicateProcessFlow = async (flowId, flowAlias) => {
    const flowData = { alias: `${flowAlias} copy` };
    const res
      = await builderV2.copyFlow(currentApp._id, flowId, flowData);
    navigate(`${baseURL}/${res._id}`);
  };


  const [DeletionDialog, confirmDeletion] = useConfirmation(
    'Are you sure you want to delete this process flow?',
  );

  const deleteProcessFlow = (flowId) => {
    deleteAsyncCall(async () => {
      if (await confirmDeletion()) {
        store.dispatch(currentAppActions.removeFlow(flowId));
        await builderV2.deleteFlow(currentApp._id, flowId);
      }
    });
  };

  const renderPages = () => {
    return flows.docs.map((flow) => {
      return (
        <PFItemCard
          key={flow._id}
          path={`${baseURL}/${flow._id}`}
          options={{
            'Delete': () => deleteProcessFlow(flow._id),
            'Copy ID': () => generalUtils.copyToClipboard(flow._id),
            'Duplicate': () => duplicateProcessFlow(flow._id, flow.alias),
          }}>
          <PFText margin="top-m">{flow.alias}</PFText>
          <PFText type="secondary" size="s" margin="top-s">
            id: {flow._id}
          </PFText>
        </PFItemCard>
      );
    });
  };

  return (
    <PFContainer type="main">
      <PFContainer >
        <PFContainer margin='bottom-m'>
          <PFText tag="h2" size="l">Process Flows</PFText>
        </PFContainer>
        <PFContainer display="grid"
          gridTemplateColumns="1fr 1fr 1fr" gap="m">
          <PFActionCard onClick={createProcessFlow} loading={createLoading}>
            <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
            <PFText>New Process Flow</PFText>
          </PFActionCard>
          {renderPages()}
        </PFContainer>
      </PFContainer>
      <DeletionDialog />
      <PFAlert
        message={createError}
        open={!!createError}
        onClose={dismissCreateError} />
      <PFAlert
        message={deleteError}
        open={!!deleteError}
        onClose={dismissDeleteError} />
    </PFContainer>
  );
};

export default PFScreenAppFlows;
