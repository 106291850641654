const StyleRules = {
  Background: [
    {
      color: [
        {
          key: 'background-color',
          type: 'editor-control-colorpicker',
          value: ''
        }
      ]
    },
  ],
  Borders: [
    {
      'border-radius': [
        {
          type: 'editor-control-size',
          key: 'border-radius',
          text: '',
          value: '',
          className: ''
        },
        {
          type: 'editor-control-size',
          key: 'border-top-left-radius',
          text: '',
          value: '',
          className: ''
        },
        {
          type: 'editor-control-size',
          key: 'border-top-right-radius',
          text: '',
          value: '',
          className: ''
        },
        {
          type: 'editor-control-size',
          key: 'border-bottom-left-radius',
          text: '',
          value: '',
          className: ''
        },
        {
          type: 'editor-control-size',
          key: 'border-bottom-right-radius',
          text: '',
          value: '',
          className: ''
        },
      ],
    },
    {
      'border': [
        {
          type: 'editor-control-colorpicker',
          key: 'border-top-color',
          text: 'Top',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-size',
          key: 'border-top-width',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BorderStyles',
          key: 'border-top-style',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-colorpicker',
          key: 'border-right-color',
          text: 'Right',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-size',
          key: 'border-right-width',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BorderStyles',
          key: 'border-right-style',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-colorpicker',
          key: 'border-bottom-color',
          text: 'Bottom',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-size',
          key: 'border-bottom-width',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BorderStyles',
          key: 'border-bottom-style',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-colorpicker',
          key: 'border-left-color',
          text: 'Left',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-size',
          key: 'border-left-width',
          text: '',
          value: '',
          className: ' '
        },
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BorderStyles',
          key: 'border-left-style',
          text: '',
          value: '',
          className: ' '
        },
      ],
    }
  ],
  BoxSizing: [
    {
      'box-sizing': [
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BoxSizing',
          key: 'box-sizing',
          value: null,
        },
      ]
    },
  ],
  Dropshadow: [
    {
      'shadow': [
        {
          type: 'editor-control-input',
          alias: '_type',
          key: 'box-shadow',
          label: 'Outside',
          value: null,
        },
      ]
    }
  ],
  Layout: [
    {
      layout: [
        {
          key: 'display',
          label: 'view_day',
          value: 'block'
        },
        {
          key: 'display',
          label: 'view_column_2',
          value: 'flex'
        },
        {
          key: 'display',
          label: 'grid_view',
          value: 'grid'
        },
        {
          key: 'display',
          label: 'view_array',
          value: 'inline'
        },
        {
          key: 'display',
          label: 'format_text_overflow',
          value: 'inline-block'
        },
        {
          key: 'display',
          label: 'visibility_off',
          value: 'none'
        }
      ]
    },
    {
      direction: [
        {
          type: 'editor-control-button-text',
          key: 'flex-direction',
          label: 'Rows',
          value: 'row'
        },
        {
          type: 'editor-control-button-text',
          key: 'flex-direction',
          label: 'Columns',
          value: 'column'
        },
        {
          type: 'editor-control-button-toggle',
          key: 'flex-direction',
          label: 'compare_arrows',
          value: '*-reverse',
        },
      ]
    },
    {
      align: [
        {
          key: 'align-items',
          label: 'align_vertical_top',
          value: 'flex-start'
        },
        {
          key: 'align-items',
          label: 'align_vertical_center',
          value: 'center'
        },
        {
          key: 'align-items',
          label: 'align_vertical_bottom',
          value: 'flex-end'
        },
        {
          key: 'align-items',
          label: 'align_items_stretch',
          value: 'stretch'
        },
        {
          key: 'align-items',
          label: 'discover_tune',
          value: 'baseline'
        }
      ]
    },
    {
      justify: [
        {
          key: 'justify-content',
          label: 'align_justify_flex_start',
          value: 'flex-start'
        },
        {
          key: 'justify-content',
          label: 'align_justify_center',
          value: 'center'
        },
        {
          key: 'justify-content',
          label: 'align_justify_flex_end',
          value: 'flex-end'
        },
        {
          key: 'justify-content',
          label: 'align_justify_space_even',
          value: 'space-evenly'
        },
        {
          key: 'justify-content',
          label: 'align_justify_space_between',
          value: 'space-between'
        },
        {
          key: 'justify-content',
          label: 'align_justify_space_around',
          value: 'space-around'
        }
      ]
    },
    {
      wrap: [
        {
          type: 'editor-control-button-text',
          key: 'flex-wrap',
          label: 'Don\'t Wrap',
          value: 'nowrap'
        },
        {
          type: 'editor-control-button-text',
          key: 'flex-wrap',
          label: 'Wrap',
          value: 'wrap'
        },
        {
          key: 'flex-wrap',
          label: 'compare_arrows',
          value: 'wrap-reverse'
        }
      ]
    },
    {
      gap: [
        {
          type: 'editor-control-size',
          key: 'row-gap',
          label: 'Row',
          value: ''
        },
        {
          type: 'editor-control-size',
          key: 'column-gap',
          label: 'Column',
          value: ''
        },
      ]
    },
  ],
  Position: [
    {
      'position': [
        {
          type: 'editor-control-dropdown',
          typeConfig: 'Position',
          key: 'position',
          value: null,
        },
      ]
    },
    {
      'orientation': [
        {
          type: 'editor-control-size',
          key: 'top',
          text: 'Top',
          value: null,
        },
        {
          type: 'editor-control-size',
          key: 'right',
          text: 'Right',
          value: null,
        },
        {
          type: 'editor-control-size',
          key: 'bottom',
          text: 'Bottom',
          value: null,
        },
        {
          type: 'editor-control-size',
          key: 'left',
          text: 'Left',
          value: null,
        },
      ]
    }
  ],
  Size: [
    {
      width: [
        {
          type: 'editor-control-size',
          key: 'width',
          text: 'Fixed',
          value: '',
          className: 'pf-design-editor__sizing-control--xsmall'
        },
        {
          type: 'editor-control-size',
          key: 'max-width',
          text: 'Max',
          value: '',
          className: 'pf-design-editor__sizing-control--xsmall'
        },
        {
          type: 'editor-control-size',
          key: 'min-width',
          text: 'Min',
          value: '',
          className: 'pf-design-editor__sizing-control--xsmall'
        },
      ],
    },
    {
      height: [
        {
          type: 'editor-control-size',
          key: 'height',
          text: 'Fixed',
          value: '',
          className: 'pf-design-editor__sizing-control--xsmall'
        },
        {
          type: 'editor-control-size',
          key: 'max-height',
          text: 'Max',
          value: '',
          className: 'pf-design-editor__sizing-control--xsmall'
        },
        {
          type: 'editor-control-size',
          key: 'min-height',
          text: 'Min',
          value: '',
          className: 'pf-design-editor__sizing-control--xsmall'
        },
      ],
    },
  ],
  Margin: [
    {
      margin: [
        {
          key: 'margin-top',
          type: 'editor-control-size',
          className: 'spacing-selector section top',
          value: null
        }
      ]
    },
    {
      margin: [
        {
          key: 'margin-right',
          type: 'editor-control-size',
          className: 'spacing-selector section right',
          value: null
        }
      ]
    },
    {
      margin: [
        {
          key: 'margin-bottom',
          type: 'editor-control-size',
          className: 'spacing-selector section bottom',
          value: null
        }
      ]
    },
    {
      margin: [
        {
          key: 'margin-left',
          type: 'editor-control-size',
          className: 'spacing-selector section left',
          value: null
        }
      ]
    }
  ],
  Padding: [
    {
      padding: [
        {
          key: 'padding-top',
          type: 'editor-control-size',
          className: 'spacing-selector inner-section top',
          value: null
        }
      ]
    },
    {
      padding: [
        {
          key: 'padding-right',
          type: 'editor-control-size',
          className: 'spacing-selector inner-section right',
          value: null
        }
      ]
    },
    {
      padding: [
        {
          key: 'padding-bottom',
          type: 'editor-control-size',
          className: 'spacing-selector inner-section bottom',
          value: null
        }
      ]
    },
    {
      padding: [
        {
          key: 'padding-left',
          type: 'editor-control-size',
          className: 'spacing-selector inner-section left',
          value: null
        }
      ]
    }
  ],
  Fonts: [
    {
      font: [
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BaseFontFamilies',
          key: 'font-family',
          value: ''
        }
      ]
    },
    {
      weight: [
        {
          type: 'editor-control-dropdown',
          typeConfig: 'BaseFontWeights',
          key: 'font-weight',
          value: ''
        }
      ]
    },
    {
      size: [
        {
          type: 'editor-control-size',
          typeConfig: '',
          key: 'font-size',
          value: ''
        }
      ]
    },
    {
      height: [
        {
          type: 'editor-control-size',
          typeConfig: '',
          key: 'line-height',
          value: ''
        }
      ]
    },
    {
      color: [
        {
          key: 'color',
          type: 'editor-control-colorpicker',
          value: ''
        }
      ]
    },
    {
      align: [
        {
          key: 'text-align',
          label: 'format_align_left',
          value: 'left'
        },
        {
          key: 'text-align',
          label: 'format_align_center',
          value: 'center'
        },
        {
          key: 'text-align',
          label: 'format_align_right',
          value: 'right'
        },
        {
          key: 'text-align',
          label: 'format_align_justify',
          value: 'justify'
        },

      ]
    },
  ],
};

export default StyleRules;
