import React, { Children, cloneElement, ReactElement, ReactNode } from 'react';
import * as Styled from './accordionheader.styled';

export type AccordionheaderProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * A function to change the current state in the reducer on each click
     */
    handleOnClick?: () => void;

    /**
     * To identify an Accordion Item.
     */
    id?: string;

    /**
     * To know if the accordion is open
     */
    isOpen?: boolean;
};

export function Accordionheader({
    id,
    children,
    className,
    configStyles,
    handleOnClick,
    isOpen = false,
    ...props
}: AccordionheaderProps) {
    return (
        <Styled.Accordionheader
            className={className}
            configStyles={configStyles}
            onClick={handleOnClick}
            id={id}
            {...props}
        >
            {Children.toArray(children as ReactElement).map((child: any, index: number) => {
                if (child.props.isAccordionIcon || child.props.attributes?.isAccordionIcon) {
                    return cloneElement(child, {
                        key: `accordion-icon-${index}`,
                        isOpen: isOpen,
                        ...(child.props || child.props.attributes),
                    });
                } else {
                    return child;
                }
            })}
        </Styled.Accordionheader>
    );
}

export type AccordionIconProps = {
    /**
     * a node to be rendered as an Accordion icon.
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * To identify an Accordion Item.
     */
    id?: string;

    /**
     * This prop helps AccordionHeader recognize this component as its icon and differentiate it from others
     */
    isAccordionIcon?: boolean;

    /**
     * To know if the accordion is open
     */
    isOpen?: boolean;
};

export function Accordionicon({
    id,
    children,
    className,
    configStyles,
    isAccordionIcon = true,
    isOpen,
    ...props
}: AccordionIconProps) {
    return (
        <Styled.AccordionIcon
            id={id}
            className={className}
            configStyles={configStyles}
            isAccordionIcon={isAccordionIcon}
            isOpen={isOpen}
            {...props}
        >
            {children}
        </Styled.AccordionIcon>
    );
}
