import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import './PFModal.css';

/**
 * Standard modal component of the PFComponents library.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFModal properties.
 * @param {React.ReactNode} props.children - Child elements to be
 * rendered inside the modal.
 * @param {boolean} props.active - Determines if the modal is active (visible).
 * @param {func} props.onDismiss - Function that will be called when the modal
 * is dismissed (clicked outside of the content).
 * @param {bool} [props.outsideDismiss=true] - Determines if the modal should
 * be dismissed when clicking outside of it (defaults to true).
 * @param {number} [props.zIndex=1000] - Determines the 'z-index' css property
 * of the modal component (whole screen).
 *
 * @return {React.ReactElement} The modal component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFModal = ({
  children,
  active,
  onDismiss,
  outsideDismiss,
  zIndex,
}) => {
  const { activeTheme } = useSelector((state) => state.theme);

  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [active]);

  const close = () => {
    if (outsideDismiss && !!onDismiss) onDismiss();
  };

  return ReactDOM.createPortal(
    <div
      onClick={close}
      className={`pf pf-modal-screen ${activeTheme}`}
      style={{ zIndex }}
      aria-hidden={active ? 'false' : 'true'}>
      <div
        className="pf pf-modal"
        aria-hidden={active ? 'false' : 'true'}>
        <div onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

PFModal.defaultProps = {
  outsideDismiss: true,
  zIndex: 1000,
};

PFModal.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  outsideDismiss: PropTypes.bool,
  zIndex: PropTypes.number,
};

export default PFModal;
