import {
  PFButton, PFContainer, PFIcon, PFSelect,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


const ProcessFlowSelector = ({ component, setComponent, eventIndex }) => {
  const currentApp = useSelector((state) => state.current_app);
  const processFlows = currentApp.build.process_flows.docs;
  const removeProcessFlow = (eventIndex) => {
    const updatedEvents = [...component.events];
    delete updatedEvents[eventIndex].trigger;
    setComponent({
      ...component,
      events: updatedEvents
    });
  };

  const handleProcessFlowChange = (e, eventIndex) => {
    const updatedComponent = { ...component };
    updatedComponent.events[eventIndex].trigger = e.target.value;
    setComponent(updatedComponent);
  };

  const pfOptions = processFlows.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });

  return (
    <PFContainer
      key={`pf-card-${eventIndex}`}
      display='flex'
      flexDirection='column'
      radius="s"
      padding="m"
      border='1px solid var(--purple-400)'
      background='var(--purple-300)'
    >
      <PFContainer display='flex'
        justifyContent="space-between" alignItems='center'>
        <PFText size='header'>Process Flow</PFText>
        <PFButton onClick={() => removeProcessFlow(eventIndex)}
          type='support'
        >
          <PFIcon
            icon='bx bx-trash'
            size='m'
          />
        </PFButton>
      </PFContainer>
      <PFContainer display="grid" gap="s" alignItems="center"
        gridTemplateColumns="auto 1fr">
        <PFText size='label'>Flow</PFText>
        <PFSelect
          placeholder='Select a flow'
          style={{
            fontSize: 'var(--text-label)',
            backgroundColor: 'var(--purple-400)'
          }}
          optionsStyles={{
            fontSize: '12px'
          }}
          options={pfOptions}
          value={component.events[eventIndex].trigger}
          onChange={
            (e) => handleProcessFlowChange(
              e,
              eventIndex
            )} />
      </PFContainer>
    </PFContainer>
  );
};

ProcessFlowSelector.propTypes = {
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  eventIndex: PropTypes.number.isRequired,
};

export default ProcessFlowSelector;
