import styled from 'styled-components';

export const Readmore = styled.section<{ configStyles: string | undefined }>`
    ${(props) => props.configStyles}
`;

export const Content = styled.article<{ minHeight: string | undefined }>`
    height: ${(props) => props.minHeight};
    overflow: hidden;
`;

export const Button = styled.a.attrs({ role: 'button' })``;
