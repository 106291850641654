import PropTypes from 'prop-types';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLine,
  PFModal,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';

const StepCreationModal = ({
  multistep,
  setMultistep,
  setCurrentStep,
  active,
  onDismiss
}) => {
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);
  const [form, fieldHook] = useForm({
    step_id: {
      default_value: '',
      validation_type: 'text',
      error_message: 'Please provide an ID for your step',
    }
  });

  const onSubmit = ({ step_id }) => {
    asyncCall(async () => {
      if (multistep.steps_order.includes(step_id)) {
        throw Error(`Step ID '${step_id}' already exists`);
      }

      const appId = multistep.associated_app._id;
      const pageId = multistep.associated_app.page_id;
      const multistepId = multistep._id;

      await builderV2.addMultistepStep(
        appId, pageId, multistepId, step_id);
      setMultistep({
        ...multistep,
        steps_order: [...multistep.steps_order, step_id],
        steps: { ...multistep.steps, [step_id]: [] }
      });
      setCurrentStep(step_id);

      form.setConfig({
        step_id: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Please provide an ID for your step',
        }
      });

      onDismiss();
    });
  };

  return (
    <>
      <PFModal active={active} onDismiss={onDismiss}>
        <PFContainer type="form" padding="l" radius="l" width={800}>
          <PFForm form={form} submit={onSubmit}>
            <PFContainer margin="bottom-m"
              display="flex" alignItems="center" justifyContent="space-between">
              <PFText type="support">Create a new step</PFText>
              <PFButton type="support" onClick={onDismiss}
                padding="none" height={28}
                style={{
                  background: 'var(--purple-50)',
                  borderRadius: '50%',
                  aspectRatio: 1,
                }}>
                <PFIcon icon="bx bx-x" size="l" />
              </PFButton>
            </PFContainer>
            <PFContainer display="flex" flexDirection="column" gap="s">
              <PFInput
                name="step_id"
                fieldHook={fieldHook}
                leftContent={(
                  <PFContainer width={100}
                    display="flex" justifyContent="space-between">
                    <PFText padding="horizontal-s" type="secondary">
                      Step ID
                    </PFText>
                    <PFLine orientation="vertical" width={3} />
                  </PFContainer>
                )} />
            </PFContainer>
            <PFContainer margin="top-l" display="flex" justifyContent="end">
              <PFButton padding="horizontal-xl" submit loading={loading}>
                Create step
              </PFButton>
            </PFContainer>
          </PFForm>
        </PFContainer >
      </PFModal >
      <PFAlert message={error} open={!!error} onClose={dismissError} />
    </>
  );
};

StepCreationModal.propTypes = {
  multistep: PropTypes.object.isRequired,
  setMultistep: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default StepCreationModal;
