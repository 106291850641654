import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  PFButton,
  PFContainer,
  PFIcon,
  PFLink
} from 'PFComponents/common';

import './PFItemCard.css';

/**
 * Link card component to display items related to a Pefai configuration. Meant
 * to be used inside a grid.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFItemCard properties.
 * @param {React.ReactNode} props.children - Items to be displayed inside the
 * card component.
 * @param {string} props.path - Path to navigate to once the card has been
 * clicked.
 * @param {string} props.className - Class name to be applied to the component.
 * @param {Object.<string, func>} props.options - List of options to be
 * displayed for the particular item.
 *
 * @return {React.ReactElement} The card component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFItemCard = ({
  children,
  path,
  className,
  options,
}) => {
  const [optsOpen, setOptsOpen] = useState(false);

  const renderOptions = () => {
    return Object.entries(options).map(([name, func]) => {
      const executeFunc = () => {
        func();
        setOptsOpen(false);
      };

      return (
        <PFButton
          key={name}
          onClick={executeFunc}
          type="none"
          padding="vertical-xs horizontal-l">
          {name}
        </PFButton>
      );
    });
  };

  return (
    <PFLink to={path} className={className}>
      <PFContainer className="pfitemcard-container">
        {!!options
          ? <>
            <PFButton
              type="support"
              className="pfitemcard-menu"
              onClick={() => setOptsOpen(!optsOpen)}>
              <PFIcon icon="bx bx-dots-vertical-rounded" size="l" />
            </PFButton>
            <PFContainer
              className={`pfitemcard-menu-options ${optsOpen ? 'active' : ''}`}>
              {renderOptions()}
            </PFContainer>
          </>
          : null
        }
        <PFContainer className="pfitemcard-children-wrapper">
          {children}
        </PFContainer>
        <PFContainer className="pfitemcard-button">
          <PFIcon icon="bx bx-right-arrow-alt" size="l" />
        </PFContainer>
      </PFContainer>
    </PFLink>
  );
};

PFItemCard.defaultProps = {};

PFItemCard.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.object,
};

export default PFItemCard;
