const NODE_TYPES = [
  {
    type: 'ACTION',
    title: 'Action',
    tooltip: `You can setup one of our 2,000 node templates, integrate an API 
    or create custom logic.`
  },
  {
    type: 'CONDITIONAL',
    title: 'Conditional',
    tooltip: `Split your flow into the number of different and complex paths it 
    may present`
  },
  {
    type: 'UI_ACTION',
    title: 'UI Action',
    tooltip: `Return an action for the frontend to execute, such as navigation, 
    redirects or even affect components`
  },
];

export default NODE_TYPES;
