import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

const StandardPrep = ({
  canvas,
  component,
  setComponent,
  addPreviousComponent,
  addNextComponent,
  removeComponent,
}) => {
  const { children } = component;
  const setChildren = (children) => setComponent({ ...component, children });

  const addComponent = {
    BEFORE: addPreviousComponent,
    AFTER: addNextComponent,
    FIRST: (comp) => setChildren([comp, ...(children ?? [])]),
    LAST: (comp) => setChildren([...(children ?? []), comp]),
  };

  return (
    <Wrapper
      canvas={canvas}
      component={component}
      setComponent={setComponent}
      componentChildren={children}
      setComponentChildren={setChildren}
      addComponent={addComponent}
      removeComponent={removeComponent}
    />
  );
};

StandardPrep.propTypes = {
  canvas: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  addPreviousComponent: PropTypes.func.isRequired,
  addNextComponent: PropTypes.func.isRequired,
  removeComponent: PropTypes.func.isRequired,
};

export default StandardPrep;
