import React, {
    Children,
    cloneElement,
    isValidElement,
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import * as Styled from './select.styled';

export type SelectProps = {
    /**
     * An array of elements that make up the list of options.
     */
    children?: ReactNode[] | ReactNode;

    /**
     * to handle class names
     */
    className?: string | undefined;

    /**
     * to handle styles
     */
    configStyles?: string | undefined;

    /**
     * To disable the Select component.
     */
    disabled?: boolean;

    /**
     * Specifies a unique id for Select component.
     */
    id: string;

    /**
     * Specifies the name of Select component.
     */
    name: string;

    /**
     * A function to be triggered when the input element loses focus.
     */
    onBlur?: (e) => any;

    /**
     * A function to be triggered when an option from the list has been chosen.
     */
    onChange?: (e) => any;

    /**
     * The default child of the button element
     */
    placeholder?: string;

    /**
     * Convention [Forms] : This is prop (aux) is used to set this field's value on a form
     * Note: This is handled by a higher component. This means that any application that uses these components should follow this convention on value:state handling.
     */
    setValue?: any;

    /**
     * To determine the Select status
     */
    validationStatus?: boolean;

    /**
     * Set a value for Select component.
     */
    value?: string | { [key: string]: unknown };
};

export function Select({
    children = [],
    className,
    configStyles,
    disabled,
    id,
    name,
    onBlur = () => {},
    onChange = () => {},
    placeholder,
    setValue = () => {},
    validationStatus = undefined,
    value,
}: SelectProps) {
    // Ensure children is always an array - per React's children optimization of object | array when there's a single child or children instead
    const selectNode = useRef<any>(null);
    const [toggleSelect, setToggleSelect] = useState<boolean>(false);

    const [displayValue, setDisplayValue] = useState<any>(
        Children.toArray(children).find((element) => {
            if (element['props']?.['attributes']?.['value'] === value || element['props']?.['value'] === value) {
                return element;
            } else {
                return '';
            }
        }),
    );

    useEffect(() => {
        if (!value) {
            setDisplayValue('');
        } else {
            handleOptionClick(value);
        }
    }, [value]);

    const handleOutsideClick = (event) => {
        if (!selectNode.current.contains(event.target)) {
            setToggleSelect(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleOptionClick = (value: string | { [key: string]: unknown }) => {
        const receivedValue = typeof value === 'string' ? value : value.key;
        const selectedOptionChildren = Children.toArray(children).find((element) => {
            const attributesValue = element['props']?.['attributes']?.['value'];
            const propValue = element['props']?.['value'];
            if (
                (typeof attributesValue === 'string' ? attributesValue : attributesValue?.key) === receivedValue ||
                (typeof propValue === 'string' ? propValue : propValue?.key) === receivedValue
            ) {
                return element;
            } else {
                return '';
            }
        });
        onChange(receivedValue);
        setValue(value);
        setToggleSelect(false);

        if (!selectedOptionChildren) return;

        // Remove on click event if component is <SelectOption/>
        setDisplayValue(
            React.cloneElement(selectedOptionChildren as ReactElement, {
                onClick: () => {},
            }),
        );
    };

    function getSelectIcon() {
        const childrenArray = Children.toArray(children);
        const selectIconIndex: number | undefined = childrenArray.findIndex(
            (child: any) => child.props.isSelectIcon || child.props.attributes?.isSelectIcon,
        );
        if (selectIconIndex !== -1) {
            let selectIcon = childrenArray[selectIconIndex];
            return selectIcon;
        } else {
            return null;
        }
    }

    return (
        <Styled.Select
            ref={selectNode}
            id={id}
            className={className}
            configStyles={configStyles}
            validationStatus={validationStatus}
        >
            <Styled.SelectButton
                name={name}
                type="button"
                value={displayValue}
                disabled={disabled}
                onClick={(event) => {
                    setToggleSelect(!toggleSelect);
                    event.stopPropagation();
                }}
                onBlur={(e) => {
                    onBlur(e);
                }}
            >
                {displayValue || placeholder}
                <Styled.SelectButtonIcon isOpen={toggleSelect}>{getSelectIcon()}</Styled.SelectButtonIcon>
            </Styled.SelectButton>
            <Styled.SelectOptionList isOpen={toggleSelect}>
                {Children.toArray(children).map((option, index) => {
                    if (isValidElement(option)) {
                        const { props } = option;
                        const isSelectIcon = props?.isSelectIcon || props?.attributes?.isSelectIcon;
                        const value = props?.attributes?.value || props?.value;
                        const isOption = !!value;

                        if (isSelectIcon) {
                            return null;
                        } else {
                            return (
                                <Styled.SelectOptionListItem
                                    tabIndex={index}
                                    isOption={isOption}
                                    key={`select-option-${index}`}
                                    role={isOption ? 'option' : undefined}
                                >
                                    {cloneElement(option as ReactElement, {
                                        onClick: (e: any) => {
                                            if (isOption) {
                                                handleOptionClick(e);
                                            }
                                        },
                                    })}
                                </Styled.SelectOptionListItem>
                            );
                        }
                    }
                    return null;
                })}
            </Styled.SelectOptionList>
        </Styled.Select>
    );
}

export type SelectOptionProps = {
    /**
     * An array of elements that make up the list of options.
     */
    children?: ReactNode[] | ReactNode;

    /**
     * OnClick handler
     */
    onClick?: (e) => void;

    /**
     * Set a value for Select component.
     */
    value?: string | { [key: string]: unknown };
};
export function SelectOption({ children, value, onClick = (e) => {} }: SelectOptionProps) {
    return (
        <Styled.SelectOption
            onClick={() => {
                onClick(value);
            }}
        >
            {children}
        </Styled.SelectOption>
    );
}
export type SelectIconProps = {
    /**
     * a node to be rendered as an Select icon.
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * To identify an Select field
     */
    id?: string;

    /**
     * This prop helps Select to recognize this component as its icon and differentiate it from other items
     */
    isSelectIcon?: boolean;
};

export function SelectIcon({ id, children, className, configStyles, isSelectIcon = true, ...props }: SelectIconProps) {
    return (
        <Styled.SelectIcon id={id} className={className} configStyles={configStyles} isSelectIcon={true} {...props}>
            {children}
        </Styled.SelectIcon>
    );
}
