import {
  PFButton, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { getIcon } from './columnicons/getIcons';

const TypeCard = ({ columnType, icon, setColumnType }) => {
  return (
    <PFButton onClick={() => setColumnType(columnType)}
      height='auto' padding='s' type='support'
      style={{
        backgroundColor: 'var(--purple-350)',
        borderRadius: 'var(--size-s)'
      }}>
      <img width={'50px'} src={getIcon(icon).icon} />
      <PFText size='s' color='var(--purple-100)'
        margin='top-s'>
        {columnType}</PFText>
    </PFButton>
  );
};

TypeCard.propTypes = {
  columnType: PropTypes.string,
  icon: PropTypes.string,
  setColumnType: PropTypes.func,
};

export default TypeCard;
