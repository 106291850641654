import { PFTooltip } from 'PFComponents/common';
import { componentUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import './PFContainer.css';


/**
 * Standard container component of the PFComponents library. This container
 * complies with the Pefai style guidelines. The underlying implementation of
 * this container is the React *div* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFContainer properties.
 * @param {React.ReactNode} [props.children] - Child elements to be
 * rendered inside the container.
 * @param {string} [props.className] - Class name to be applied to the
 * container.
 * @param {string} [props.tooltip] - Displays helper text (tooltip) when
 * hovering over the container.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the container.
 * @param {"none"|"main"|"form"|"toolbar"|"editor"} [props.type="none"] -
 * Type of container to be rendered, this changes the appearance of the
 * container and its contents.
 * @param {Object} [props.config={}] - Extra options to be passed as params to
 * the underlying *div*.
 * @param {string} [props.radius] - Indicates the border radius.
 * @param {string|number} [props.width] - Determines the width of the
 * container component.
 * @param {string|number} [props.height] - Determines the height of the
 * container component.
 * @param {string} [props.margin] - Determines the margin config of the
 * container component.
 * @param {string} [props.padding] - Determines the padding config of the
 * container component.
 * @param {string} [props.display] - Determines the display style property of
 * the container component.
 * @param {string|number} [props.flex] - Determines the flex style property of
 * the container component.
 * @param {string} [props.flexDirection] - Determines the flex direction style
 * property of the container component.
 * @param {string} [props.alignItems] - Determines the align items style
 * property of the container component.
 * @param {string} [props.justifyContent] - Determines the justify content style
 * property of the container component.
 * @param {string} [props.alignSelf] - Determines the align self style property
 * of the container component.
 * @param {string} [props.gridTemplateColumns] - Determines the grid template
 * columns style property of the container component.
 * @param {string|number} [props.gap] - Determines the gap style property of
 * the container component.
 * @param {string} [props.background] - Determines the background config of the
 * container component.
 * @param {string} [props.border] - Determines the border config of the
 * container component.
 * @param {boolean} [props.safeArea] - Determines if the area delimited by the
 * container is safe (doesn't extend past a maximum width).
 *
 * @param {string} [props.id] - Component's natrual id attribute
 *
 * @return {React.ReactElement} The container component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFContainer = ({
  children,
  className,
  tooltip,
  style,
  type,
  config,
  radius,
  width,
  height,
  margin,
  padding,
  display,
  flex,
  flexDirection,
  alignItems,
  justifyContent,
  alignSelf,
  gridTemplateColumns,
  gap,
  background,
  border,
  safeArea,
  id,
  onMouseEnter,
  onMouseLeave
}) => {
  const containerClassName
    = `pf pf-container ${type} ${!!safeArea ? 'safe-area' : ''} 
    ${type = 'toolbar' ? 'pf-scroll' : ''} ${className}`;

  const configStyle
    = componentUtils.getStylesFromConfig({ margin, padding, radius });
  const containerStyle = {
    width,
    height,
    display,
    flex,
    flexDirection,
    alignItems,
    justifyContent,
    alignSelf,
    gridTemplateColumns,
    gap: componentUtils.getSizeFromConfig(gap),
    background,
    border,
    ...configStyle,
    ...style
  };

  let container = (
    <div
      id={id}
      className={containerClassName}
      style={containerStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...config}>
      {children}
    </div>
  );

  if (tooltip) {
    container = (
      <PFTooltip title={tooltip}>
        {container}
      </PFTooltip>
    );
  }

  return container;
};

PFContainer.defaultProps = {
  className: '',
  style: {},
  type: 'none',
  config: {}
};

PFContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['none', 'main', 'form', 'toolbar', 'editor']),
  config: PropTypes.object,
  radius: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  padding: PropTypes.string,
  display: PropTypes.string,
  flex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  alignSelf: PropTypes.string,
  gridTemplateColumns: PropTypes.string,
  gap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  background: PropTypes.string,
  border: PropTypes.string,
  safeArea: PropTypes.string,
  id: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default PFContainer;
