import { useSelector } from 'react-redux';

import { PFContainer, PFLoader } from 'PFComponents/common';

const PFScreenLoading = () => {
  const { activeTheme } = useSelector((state) => state.theme);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <PFLoader area color="white" />
      </PFContainer>
    </PFContainer>
  );
};

export default PFScreenLoading;
