import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFContainer,
  PFIcon,
  PFLoader,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useShortcut } from 'PFHooks';
import {
  MultistepJSONEditorHeader,
  MultistepJSONEditorSidebar
} from './components';

const PFScreenMultistepJSONEditor = () => {
  const { app_id, page_id, multistep_id } = useParams();

  const [fetchAsyncCall, fetchLoading, fetchError, dismissFetchError]
    = useAsyncCall(true);
  const [multistep, setMultistep] = useState(null);

  const [currentStep, setCurrentStep] = useState(null);

  const [saveAsyncCall, saveLoading,
    saveError, dismissSaveError] = useAsyncCall(false);
  const [stepsConfig, setStepsConfig] = useState({});
  const [savedSteps, setSavedSteps] = useState({});

  useEffect(() => {
    fetchAsyncCall(async () => {
      const multistep
        = await builderV2.getMultistep(app_id, page_id, multistep_id);
      setMultistep(multistep);
      setCurrentStep(multistep?.steps_order[0] ?? null);
    });
  }, [app_id, page_id, multistep_id]);

  useEffect(() => {
    if (!!multistep) {
      const newStepsConfig = {};
      for (const step_id of multistep.steps_order) {
        if (!!multistep?.steps[step_id]?.components) {
          newStepsConfig[step_id]
            = JSON.stringify(multistep.steps[step_id].components, null, '\t');
        } else {
          newStepsConfig[step_id] = '[]';
        }
      }
      setStepsConfig(newStepsConfig);
    }
  }, [multistep]);

  const saveStepConfig = async () => {
    saveAsyncCall(async () => {
      const newStepConfig = JSON.parse(stepsConfig[currentStep]);
      await builderV2.updateMultistepStep(
        multistep.associated_app._id,
        multistep.associated_app.page_id,
        multistep._id,
        currentStep,
        newStepConfig);
      setSavedSteps({ ...savedSteps, [currentStep]: true });
    });
  };

  useShortcut('ctrl+s', saveStepConfig);

  return (
    <>
      <MultistepJSONEditorHeader
        appId={app_id}
        pageId={page_id}
        stepId={currentStep}
        save={saveStepConfig}
        saveLoading={saveLoading}
        saved={savedSteps[currentStep]} />
      <PFContainer display="flex" flex={1} alignItems='stretch'>
        {fetchLoading || !multistep
          ? <PFLoader area color="var(--primary)" />
          : <>
            <MultistepJSONEditorSidebar
              multistep={multistep}
              setMultistep={setMultistep}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep} />
            <PFContainer flex={1}>
              <PFContainer width="100%" height="100%">
                {!!currentStep
                  ? <Editor
                    height="100%"
                    width="100%"
                    language="json"
                    value={stepsConfig[currentStep]}
                    theme="vs-dark"
                    onChange={(e) => setStepsConfig({
                      ...stepsConfig, [currentStep]: e
                    })}
                  />
                  : <PFContainer width="100%" height="100%"
                    display="flex" alignItems="center" justifyContent="center">
                    <PFContainer width="100%" padding="l" margin="l" radius="l"
                      display="flex" flexDirection="column" alignItems="center"
                      border="1px solid var(--secondary)" gap="s">
                      <PFIcon icon="bx bx-carousel" size="xl"
                        color="var(--secondary)" />
                      <PFText color="var(--secondary)">
                        This multistep component is empty
                      </PFText>
                    </PFContainer>
                  </PFContainer>
                }
              </PFContainer>
            </PFContainer>
          </>
        }
        <PFAlert
          message={fetchError}
          open={!!fetchError}
          onClose={dismissFetchError} />
        <PFAlert
          message={saveError}
          open={!!saveError}
          onClose={dismissSaveError} />
      </PFContainer >
    </>
  );
};

export default PFScreenMultistepJSONEditor;
