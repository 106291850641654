import { images } from 'PFAssets';
import {
  PFButton,
  PFContainer,
  PFForm,
  PFInput,
  PFLink,
  PFText
} from 'PFComponents/common';
import { useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OtpForm = ({ setOtp, username }) => {
  const [verifyForm, fieldHook] = useForm({
    otp: {
      default_value: '',
      validation_type: 'code',
      regex: /^\d{6}$/,
      error_message: 'Please provide a digit code'
    }
  });
  const navigate = useNavigate();

  const verifyEmail = (values) => {
    setOtp(values.otp);
    navigate('/forgot-password/update');
  };

  useEffect(() => {
    if (!username) navigate('/forgot-password');
  }, []);

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="center" margin='top-xl bottom-xl'>
      <PFForm form={verifyForm} submit={verifyEmail} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="center" gap="s">
          <PFLink to='/'>
            <img src={images.pefaiLogo} alt="Pefai" height={50} />
          </PFLink>
          <PFText tag="h2" weight={400} margin="top-xl bottom-l">
            Confirm it's you
          </PFText>
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)' size='s'>
              We've sent you a code, please enter it here</PFText>
          </PFContainer>
          <PFInput placeholder="6-digit-code" name="otp"
            fieldHook={fieldHook}
            width="100%"
            style={{ height: 50, textAlign: 'center' }} />
          <PFButton submit
            width="100%" height={50} margin='bottom-l top-m'
            style={{ borderRadius: 25 }}>
            Verify email
          </PFButton>
          <PFLink type='primary' color='var(--purple-150)' style={{
            fontWeight: '400'
          }} to='/forgot-password'>
            Didn't receive it? Resend here</PFLink>
        </PFContainer>
      </PFForm>
    </PFContainer>
  );
};

OtpForm.propTypes = {
  setOtp: PropTypes.func,
  username: PropTypes.string,
};

export default OtpForm;
