import React, { Children, cloneElement, ReactElement, ReactNode, useEffect, useReducer } from 'react';
import * as Styled from './accordioncontainer.styled';

export type AccordioncontainerProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode | ReactNode[];

    /**
     * to handle class names
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * To identify the Accordion component.
     */
    id?: string;
};

type State = {
    tabId: string;
    isOpen: boolean;
}[];

type Action =
    | {
          type: 'INIT_ACCORDION';
          payload: ReactNode | ReactNode[];
      }
    | {
          type: 'TOGGLE';
          payload: string;
      };

let itemsInitialState: State = [];

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'INIT_ACCORDION':
            return Children.map(action.payload as ReactElement, (payloadItem) => {
                return {
                    tabId: payloadItem?.props?.tabId || payloadItem?.props?.attributes?.tabId,
                    isOpen: payloadItem?.props?.handleIsOpen || payloadItem?.props?.attributes?.handleIsOpen,
                };
            });
        case 'TOGGLE':
            return state.map((accordionItem) => {
                if (accordionItem.tabId === action.payload) {
                    return {
                        ...accordionItem,
                        isOpen: !accordionItem.isOpen,
                    };
                } else {
                    return accordionItem;
                }
            });
        default:
            return state;
    }
};

export function Accordioncontainer({ children, className, configStyles, id, ...props }: AccordioncontainerProps) {
    const [state, dispatch] = useReducer(reducer, itemsInitialState);

    useEffect(() => {
        dispatch({ type: 'INIT_ACCORDION', payload: children });
    }, [children]);

    const onItemClick = (accordionItem: ReactElement) => {
        dispatch({
            type: 'TOGGLE',
            payload: accordionItem?.props?.tabId || accordionItem?.props?.attributes?.tabId,
        });
    };

    const handleIsOpen = (accordionItemReceived: ReactElement) => {
        if (state.length > 0) {
            const currentAccordionItem = state.find(
                (accordionItem) =>
                    accordionItem.tabId ===
                    (accordionItemReceived?.props?.tabId || accordionItemReceived?.props?.attributes?.tabId),
            );
            return currentAccordionItem.isOpen;
        } else {
            return false;
        }
    };

    const accordionItems = Children.map(children as ReactElement, (accordionItemReceived: ReactElement) => {
        return cloneElement(accordionItemReceived, {
            handleIsOpen: handleIsOpen(accordionItemReceived),
            handleOnClick: () => onItemClick(accordionItemReceived),
        });
    });

    return (
        <Styled.Accordioncontainer className={className} configStyles={configStyles} id={id} {...props}>
            {accordionItems}
        </Styled.Accordioncontainer>
    );
}
