import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { twoapp } from 'PFApis';
import { PFActionCard } from 'PFComponents/cards';
import {
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { AppCard, AppCreationModal } from './components';

const PFScreenUserApps = () => {
  const navigate = useNavigate();
  const apps = useSelector((state) => state.user.associated_apps.docs);

  const [creationFormOpen, setCreationFormOpen] = useState(false);

  const renderApps = () => {
    return apps.map((app) => {
      return <AppCard key={app._id} app={app} />;
    });
  };

  const createApp = async (values) => {
    const { InsertedID } = await twoapp.createApp(values);
    navigate(`/apps/${InsertedID}/pages`);
  };

  return (
    <PFContainer>
      <PFContainer display="flex" margin="vertical-m"
        justifyContent="space-between" alignItems="flex-end">
        <PFText tag="h1" size="l" weight={400}>Your apps</PFText>
        <PFText type="secondary">{apps.length} Total</PFText>
      </PFContainer>
      <PFContainer display="grid"
        gridTemplateColumns="1fr 1fr" gap="m">
        <PFActionCard onClick={() => setCreationFormOpen(true)}>
          <PFIcon icon="bx bx-plus-circle" color="white" size="l" />
          <PFText>New Project</PFText>
        </PFActionCard>
        {renderApps()}
      </PFContainer>
      <AppCreationModal active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)}
        onSubmit={createApp} />
    </PFContainer>
  );
};

export default PFScreenUserApps;
