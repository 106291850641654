import {
  PFButton,
  PFCodeEditor,
  PFContainer, PFIcon, PFInput, PFLine, PFText
} from 'PFComponents/common';
import { generalUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Parameters from './Parameters';

const AIOutput = ({ var_name, settings, setArgs, execute, setVarName, setTarget,
  setScript, toggleConfig, setToggleConfig }) => {
  const [copiedAlert, setCopiedAlert] = useState(false);
  return (
    <PFContainer>
      {
        toggleConfig
          ? <PFContainer>
            <PFButton type='none' height='auto'
              style={{
                display: 'flex',
                gap: 'var(--size-xs)',
                alignItems: 'center'
              }}
              padding='0'
              margin='bottom-m'
              onClick={() => {
                setToggleConfig(false);
              }}>
              <PFIcon color='var(--purple-200)' icon='bx bx-chevron-left' />
              <PFText color='var(--purple-200)' size='s'>
                Return to description
              </PFText>
            </PFButton>

            <PFContainer display="flex" alignItems="center" gap="m">
              <PFText type="secondary" style={{ width: 120 }}>
                Target variable
              </PFText>
              <PFContainer flex={1}>
                <PFInput value={var_name}
                  onChange={(e) => setVarName(e.target.value)}
                  placeholder
                  ="This is where the function result will be stored" />
              </PFContainer>
            </PFContainer>
            <PFLine margin='bottom-m top-m' />
            <PFContainer display="flex" flexDirection="column" gap="l">
              <PFContainer display="grid" gridTemplateColumns="120px auto"
                alignItems="center" gap="m">
                <PFText type="secondary">Value</PFText>

                <PFInput value={settings?.target}
                  onChange={(e) => setTarget(e.target.value)}
                  placeholder
                  ="The name of the function in the script to be executed"
                />
              </PFContainer>
              <PFContainer>
                <PFText>Parameters</PFText>
                <Parameters
                  args={settings?.args}
                  setArgs={setArgs} />
              </PFContainer>
              <PFContainer>
                <PFText margin="bottom-s">Script</PFText>
                <PFCodeEditor
                  language="js"
                  value={execute?.script}
                  onChange={(e) => setScript(e.target.value)} />
              </PFContainer>
            </PFContainer>

          </PFContainer>
          : <>
            <PFContainer border='2px solid #e1d7ff'
              padding='top-m bottom-m left-l right-l'
              display='flex' justifyContent='center'
              radius='s'>
              <PFText color='var(--purple-200)' style={{
                textAlign: 'center'
              }}>
                {execute.description}
              </PFText>
            </PFContainer>
            <PFContainer display='flex' justifyContent='space-between'
              margin='top-m'>
              <PFButton type='none' height='auto' padding='0'
                style={{
                  display: 'flex',
                  gap: 'var(--size-xs)',
                  alignItems: 'center'
                }} onClick={() => {
                  setCopiedAlert(true);
                  setTimeout(() => {
                    setCopiedAlert(false);
                  }, 2000);
                  generalUtils.copyToClipboard('${' + var_name + '}');
                }}>
                <PFIcon color='var(--purple-200)' icon='bx bx-copy' />
                <PFText size='s' color='var(--purple-200)'>
                  {copiedAlert
                    ? 'Copied!'
                    : 'Copy variable'}
                </PFText>
              </PFButton>
              <PFButton height='auto' style={{
                display: 'flex',
                gap: 'var(--size-xs)',
                backgroundColor: 'transparent',
                alignItems: 'center'
              }} padding='0'
                onClick={() => {
                  setToggleConfig(true);
                }}>
                <PFIcon icon='bx bxs-cog' color='var(--purple-200)' />
                <PFText color='var(--purple-200)' size='s'>
                  Custom configuration
                </PFText>
              </PFButton>
            </PFContainer>
          </>
      }

    </PFContainer>
  );
};

AIOutput.propTypes = {
  var_name: PropTypes.string,
  settings: PropTypes.object,
  setArgs: PropTypes.func,
  execute: PropTypes.object,
  setVarName: PropTypes.func,
  setTarget: PropTypes.func,
  setScript: PropTypes.func,
  toggleConfig: PropTypes.bool,
  setToggleConfig: PropTypes.func,
};

export default AIOutput;
