import { PFContainer } from 'PFComponents/common';
import PropTypes from 'prop-types';
import {
  ErrorEditor,
  InstructionsEditor,
  ResponseEditor,
  VariablesSelection
} from './components';

const PFFunctionOperationEditor = ({ operation, updateOperation,
  variables }) => {
  return (
    <PFContainer display='flex' flex='1' gap='l'>
      <PFContainer style={{
        maxWidth: 700,
      }} flex='1'
        display="flex" flexDirection="column" gap="m"
      >
        <VariablesSelection
          operation={operation}
          updateOperation={updateOperation} />
        <InstructionsEditor
          operation={operation}
          updateOperation={updateOperation} />
        <ResponseEditor
          operation={operation}
          updateOperation={updateOperation}
          variables={variables} />
        <ErrorEditor
          operation={operation}
          updateOperation={updateOperation} />
      </PFContainer>
    </PFContainer>

  );
};

PFFunctionOperationEditor.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
  variables: PropTypes.array,
};

export default PFFunctionOperationEditor;
