import { authPefai } from 'PFApis';
import { images } from 'PFAssets';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFInput,
  PFLink,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import store, { authActions } from 'PFStore';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const VerifyEmailForm = ({ currentUser }) => {
  const [verifyForm, fieldHook] = useForm({
    otp: {
      default_value: '',
      validation_type: 'code',
      regex: /^\d{6}$/,
      error_message: 'Please provide a digit code'
    }
  });
  const [asyncCall, verifyLoading, verifyError, errorDismiss] = useAsyncCall();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser === null) {
      navigate('/signup');
    }
  }, []);

  const verifyEmail = (values) => {
    asyncCall(async () => {
      await authPefai.verifyEmail({
        username: currentUser.username,
        otp: values.otp
      });
      const authResponse = await authPefai.login({
        username: currentUser.username,
        password: currentUser.password
      });
      if (authResponse !== null) {
        const accessToken = authResponse.access_token;
        store.dispatch(authActions.setAccessToken(accessToken));
        navigate('/');
      }
    });
  };

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="center" margin='top-xl bottom-xl'>
      <PFForm form={verifyForm} submit={verifyEmail} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="center" gap="s">
          <PFLink to='/'>
            <img src={images.pefaiLogo} alt="Pefai" height={50} />
          </PFLink>
          <PFText tag="h2" weight={400} margin="top-xl bottom-l">
            Verify your email
          </PFText>
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)' size='s'>
              We've sent you a code, please enter it here</PFText>
          </PFContainer>
          <PFInput placeholder="6-digit-code" name="otp"
            fieldHook={fieldHook}
            width="100%"
            style={{ height: 50, textAlign: 'center' }} />
          <PFButton submit loading={verifyLoading}
            width="100%" height={50} margin='bottom-l top-m'
            style={{ borderRadius: 25 }}>
            Verify email
          </PFButton>
          <PFLink type='primary' color='var(--purple-150)' style={{
            fontWeight: '400'
          }} to='/signup'>
            Didn't receive it? Resend here</PFLink>
        </PFContainer>
      </PFForm>
      <PFAlert message={verifyError}
        open={!!verifyError} onClose={errorDismiss} />
    </PFContainer>
  );
};

VerifyEmailForm.propTypes = {
  currentUser: PropTypes.object,
};

export default VerifyEmailForm;
