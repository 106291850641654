/* eslint-disable max-len */

const PFIconComponentContainer = () => {
  return (
    <svg width="57" height="46" viewBox="0 0 57 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" x="0.823242" y="0.519531" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="1.65455" y="7.08619" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="24" y="11.8555" width="26" height="28" rx="3" fill="#AD91FF" />
      <rect opacity="0.3" x="7.83033" y="12.6858" width="13.3393" height="26.3393" rx="2.16967" stroke="#AD91FF" strokeWidth="1.66066" strokeLinecap="round" strokeDasharray="6 6" />
    </svg>
  );
};

export default PFIconComponentContainer;
