import { PFContainer } from 'PFComponents/common';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SignupForm, VerifyEmailForm } from './components';


const PFScreenBuilderV2Signup = () => {
  const { activeTheme } = useSelector((state) => state.theme);
  const [currentUser, setCurrentUser] = useState(null);

  return (
    <PFContainer className={activeTheme} display="flex"
      flexDirection="column" flex={1}>
      <PFContainer className="app-background" />
      <PFContainer display="flex" flexDirection="column" flex={1}>
        <Routes>
          <Route path="/" element={<SignupForm
            setCurrentUser={setCurrentUser} />} />
          <Route path="/verify-email"
            element={
              <VerifyEmailForm
                currentUser={currentUser} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </PFContainer>
    </PFContainer>
  );
};

export default PFScreenBuilderV2Signup;
