import forge from 'node-forge';
import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer, PFIcon, PFLoader, PFModal, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { apiPublic, privateApiKey } from 'src/config/env';
import CopyPanel from './components/CopyPanel';

const OperationPublicEditor = ({ operation,
  updateOperation, isModalOpen, setIsModalOpen,
  functionId, saved, save }) => {
  const [publicAsyncCall, publicLoading,
    publicError, publicDismissError] = useAsyncCall(false);
  const [publicApiData, setPublicApiData] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  useEffect(() => {
    publicAsyncCall(async () => {
      const data
        = await builderV2.getPublicApis(functionId);
      setPublicApiData(data);
    });
  }, [saved]);
  const decryptMessage = (encryptedMessage) => {
    try {
      const privateKeyPem = privateApiKey.replace(/\\n/g, '\n');

      const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);

      const decodedMessage = forge.util.decode64(encryptedMessage);

      const decryptedMessage = privateKey.decrypt(decodedMessage, 'RSA-OAEP', {
        md: forge.md.sha256.create()
      });

      return decryptedMessage;
    } catch (error) {
      console.log(error);
    }
  };

  const decryptedApiKey = publicApiData?.api_key
    ? decryptMessage(publicApiData?.api_key)
    : null;
  /* eslint-disable max-len */
  const apiEndpoint = `${apiPublic}/public-api/execute-function/${functionId}/operation/${operation?._id}`;

  return (
    <PFModal active={isModalOpen} onDismiss={() => {
      setIsModalOpen(false);
    }}>
      <PFContainer padding='top-s bottom-m right-m left-m'
        background='var(--purple-500)'
        width='600px' radius='s'>
        <PFContainer display='flex' justifyContent='flex-end'>
          <PFButton type="none" onClick={() => {
            setIsModalOpen(false);
          }}
            padding="none" height='auto'>
            <PFIcon icon="bx bx-x" size="l" color='var(--purple-100)' />
          </PFButton>
        </PFContainer>
        {
          publicLoading
            ? <PFLoader color='var(--purple-200)' />
            : <>
              <CopyPanel label='ENDPOINT' stringToClipboard={apiEndpoint}>
                <PFText style={{
                  wordBreak: 'break-all'
                }}>{apiEndpoint}</PFText>
              </CopyPanel>

              <CopyPanel label='HEADERS' stringToClipboard={decryptedApiKey}>
                <PFText style={{
                  wordBreak: 'break-all'
                }}>X-API-KEY: {decryptedApiKey}</PFText>
              </CopyPanel>

              <CopyPanel stringToClipboard={'*'}>
                <PFText style={{
                  wordBreak: 'break-all'
                }}>domain: *</PFText>
              </CopyPanel>
            </>
        }

        {
          updateOperation
            ? <PFContainer display='flex' gap='s' justifyContent='flex-end'
              margin='top-m'>
              <PFButton type='none' height='auto' padding='0' style={{
                display: 'flex',
                gap: 'var(--size-s)',
                alignItems: 'center'
              }} onClick={() => {
                setConfirmationModal(true);
              }}>
                <PFText color='var(--failure)'>Unpublish endpoint</PFText>
                <PFIcon color='var(--failure)' icon='bx bx-cloud'
                  size='l' />
              </PFButton>
            </PFContainer>
            : null
        }
      </PFContainer>
      <PFModal active={confirmationModal} onDismiss={() => {
        setConfirmationModal(false);
      }} zIndex={10000}>
        <PFContainer padding="l" radius="l" width={800}
          background='var(--purple-500)'>
          <PFContainer margin="bottom-m"
            display="flex" alignItems="center" justifyContent="space-between">
            <PFText type="secondary">
              Are you sure you want to unpublish this enpoint?
            </PFText>
          </PFContainer>
          <PFContainer margin="top-l"
            display="flex" justifyContent="end" gap="m">
            <PFButton type="secondary"
              width={100}
              style={{
                backgroundColor: 'var(--purple-500)',
                borderRadius: 'var(--radius-s)',
                border: '1px solid var(--purple-200)'
              }}
              onClick={() => {
                setConfirmationModal(false);
              }}>
              <PFText>Cancel</PFText>

            </PFButton>
            <PFButton width={100}
              style={{
                backgroundColor: 'var(--purple-200)',
                borderRadius: 'var(--radius-s)'
              }}
              onClick={() => {
                updateOperation({
                  ...operation,
                  is_public_endpoint: false
                });
                save({
                  is_public_endpoint: false
                });
                setConfirmationModal(false);
                setIsModalOpen(false);
              }}>
              <PFText>Yes</PFText>
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal>
      <PFAlert
        message={publicError}
        open={!!publicError}
        onClose={publicDismissError} />
    </PFModal>
  );
};

OperationPublicEditor.propTypes = {
  operation: PropTypes.object.isRequired,
  updateOperation: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  functionId: PropTypes.string,
  saved: PropTypes.bool,
  save: PropTypes.func,
};

export default OperationPublicEditor;
