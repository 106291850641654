import React from 'react';
import * as Styled from './checkbox.styled';

export type CheckboxProps = {
    /**
     * a node to be rendered in the special component.
     */
    children?: any;

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * styling configuration overrides
     */
    configStyles?: string;

    /**
     * to disable the checkbox element
     */
    disabled?: boolean;

    /**
     * display text value
     */
    fieldValue?: string;

    /**
     * Identifies the input[checkbox] semantic element
     */
    id: string;

    /**
     * Specifies the name of the checkbox element
     */
    name: string;

    /**
     * onChange event
     */
    onChange?: (e) => void;

    /**
     * Convention [Forms] : This prop is used to determine if a user input field has been validated or not
     */
    validationStatus?: boolean | undefined;

    /**
     * field value
     */
    value?: string[] | string;

    /**
     * this flag is used to hide the HTML control and be replaced by a child component like a pill, card, etc
     */
    isMasked?: boolean;
};

export function Checkbox({
    children = [],
    className,
    configStyles,
    disabled,
    fieldValue = '',
    id,
    isMasked = false,
    name,
    onChange,
    validationStatus,
    value,
    ...props
}: CheckboxProps) {
    const isChecked = (value: CheckboxProps['value'], fieldValue: CheckboxProps['fieldValue']) => {
        if (typeof value === 'boolean') {
            return value;
        }

        if (typeof value === 'string') {
            return fieldValue !== undefined && value === fieldValue;
        }

        if (Array.isArray(value)) {
            return fieldValue !== undefined && value.includes(fieldValue);
        }

        return false;
    };

    return (
        <Styled.Checkbox id={id} className={className} configStyles={configStyles} {...props}>
            <Styled.CheckboxInput
                checked={isChecked(value, fieldValue)}
                disabled={disabled}
                hideInput={isMasked}
                id={`${name}-${fieldValue}`}
                name={name}
                onChange={onChange}
                type="checkbox"
                value={fieldValue}
            ></Styled.CheckboxInput>
            <Styled.CheckboxLabel htmlFor={`${name}-${fieldValue}`}>
                {React.Children.toArray(children).map((child) => {
                    return React.cloneElement(child as React.ReactElement, {
                        checked: isChecked(value, fieldValue),
                        validationStatus,
                    });
                })}
            </Styled.CheckboxLabel>
        </Styled.Checkbox>
    );
}
