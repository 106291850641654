import styled from 'styled-components';

export const Password = styled.input<{
    configStyles: string | undefined;
}>`
    width: 100%;
    border: none;
    outline: 0;
    background-color: unset;

    ${(props) => props.configStyles};
`;

export const IconBox = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    width: auto;
    height: 100%;
    background-color: unset;
`;
