/* eslint-disable indent */

/**
 * Obtains the style object to be applied based on the configuration strings
 * provided.
 *
 * @param {Object} config Configuration to be analized.
 * @param {string} config.margin Configuration string used to set up a css
 * margin.
 * @param {string} config.padding Configuration string used to set up a
 * css padding.
 * @param {string} config.font Configuration string used to set up font
 * sizes.
 * @param {string} config.radius Configuration string used to set up a css
 * border radius.
 *
 * @return {Object} Style object.
 */
export const getStylesFromConfig = ({
  margin,
  padding,
  font,
  radius,
}) => {
  return {
    margin: getSpacingStyleFromConfig(margin),
    padding: getSpacingStyleFromConfig(padding),
    borderRadius: getRadiusStyleFromConfig(radius),
    fontSize: getFontSizeFromConfig(font)
  };
};

const cssFontSizeVars = {
  'xs': 'var(--text-size-xs)',
  's': 'var(--text-size-s)',
  'm': 'var(--text-size-m)',
  'l': 'var(--text-size-l)',
  'xl': 'var(--text-size-xl)',
  'label': 'var(--text-label)',
  'header': 'var(--text-header)'
};

const cssSizeVars = {
  'xs': 'var(--size-xs)',
  's': 'var(--size-s)',
  'm': 'var(--size-m)',
  'l': 'var(--size-l)',
  'xl': 'var(--size-xl)',
};

const cssRadiusVars = {
  's': 'var(--radius-s)',
  'm': 'var(--radius-m)',
  'l': 'var(--radius-l)',
};

/**
 * Obtains the configuration string that will be used to set up the size of a
 * text element font.
 *
 * @param {string} configString Configuration string used to set up font size.
 *
 * @return {string} One-line font size configuration.
 */
const getFontSizeFromConfig = (configString) => {
  return cssFontSizeVars[configString];
};


/**
 * Obtains the configuration string that will be used to set up a css spacing
 * (margin or padding).
 *
 * @param {string} configString Configuration string used to set up a css
 * margin or padding.
 *
 * @return {string} One-line margin/padding configuration,
 */
const getSpacingStyleFromConfig = (configString) => {
  if (!configString) return null;

  let spacings = { top: '0px', right: '0px', bottom: '0px', left: '0px' };
  const marginOptions = configString.split(' ');

  marginOptions.forEach((opt) => {
    const [type, size] = opt.split('-');
    let cssSize = cssSizeVars[size] ?? '0px';

    switch (type) {
      case 'vertical':
        spacings = { ...spacings, top: cssSize, bottom: cssSize };
        break;

      case 'horizontal':
        spacings = { ...spacings, right: cssSize, left: cssSize };
        break;

      case 'top':
        spacings = { ...spacings, top: cssSize };
        break;

      case 'right':
        spacings = { ...spacings, right: cssSize };
        break;

      case 'bottom':
        spacings = { ...spacings, bottom: cssSize };
        break;

      case 'left':
        spacings = { ...spacings, left: cssSize };
        break;

      default:
        // Whole margin
        if (cssSizeVars[type]) {
          cssSize = cssSizeVars[type];
          spacings = {
            ...spacings,
            top: cssSize, right: cssSize, bottom: cssSize, left: cssSize
          };
        } else {
          return null;
        }
        break;
    }
  });

  const { top, right, bottom, left } = spacings;
  return `${top} ${right} ${bottom} ${left}`;
};

/**
 * Obtains the configuration string that will be used to set up a css border
 * radius.
 *
 * @param {string} configString Configuration string used to set up a css
 * radius.
 *
 * @return {string} One-line radius configuration,
 */
const getRadiusStyleFromConfig = (configString) => {
  return cssRadiusVars[configString];
};

/**
 * Obtains the configuration string for the size CSS variable.
 *
 * @param {string} configString Configuration string used to set up a CSS space.
 *
 * @return {string} One-line space configuration (CSS var).
 */
const getSizeFromConfig = (configString) => {
  return cssSizeVars[configString] ?? null;
};

export default {
  getStylesFromConfig,
  getSizeFromConfig,
};
