/* eslint-disable max-len */

const PFIconComponentVideo = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="7.4834" y="13.373" width="40" height="24" rx="2" fill="#493D6B" />
      <path d="M33.4834 24.873C33.4838 25.0428 33.4403 25.2098 33.357 25.3577C33.2738 25.5057 33.1537 25.6296 33.0084 25.7174L24.0034 31.2259C23.8516 31.3188 23.6777 31.3696 23.4997 31.3729C23.3217 31.3762 23.1461 31.3319 22.9909 31.2446C22.8372 31.1587 22.7092 31.0334 22.62 30.8816C22.5308 30.7298 22.4837 30.557 22.4834 30.3809V19.3652C22.4837 19.1891 22.5308 19.0163 22.62 18.8645C22.7092 18.7127 22.8372 18.5874 22.9909 18.5015C23.1461 18.4142 23.3217 18.3699 23.4997 18.3732C23.6777 18.3765 23.8516 18.4273 24.0034 18.5202L33.0084 24.0287C33.1537 24.1165 33.2738 24.2404 33.357 24.3884C33.4403 24.5363 33.4838 24.7033 33.4834 24.873Z" fill="#BEA7FF" />
    </svg>
  );
};

export default PFIconComponentVideo;
