import { useGridApiContext } from '@mui/x-data-grid';
import {
  PFAlert,
  PFAlertUser,
  PFButton,
  PFContainer, PFIcon, PFModal, PFText
} from 'PFComponents/common';
import { DB } from 'PFConstants';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAddTypes } from 'src/constants/DB/getForm';
import ColumnForm from './ColumnForm';
import TypeCard from './TypeCard';


const { DATA_TYPES } = DB;


const AddColumn = ({ tableName, setUpdateTable, setExitModeWarning }) => {
  const currentDB = useSelector((state) => state.current_db);
  const apiRef = useGridApiContext();
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isDefaultRequired, setIsDefaultRequired] = useState(false);


  const [defaultValue, setDefaultValue] = useState('');
  const [nullable, setNullable] = useState('true');
  const [unique, setUnique] = useState('false');
  const [decimalLength, setDecimalLength] = useState('16');
  const [decimalScale, setDecimalScale] = useState('4');
  const [stringLength, setStringLength] = useState('100');
  const [formError, setFormError] = useState(null);


  const [columnType, setColumnType] = useState(null);
  const [active, setActive] = useState(false);
  const [asyncCall, loading, error, dismissError] = useAsyncCall(false);


  const resetStep = () => {
    setColumnType(null);
    setSelectedColumn(null);
    setSelectedTable(null);
    setDefaultValue('');
    setNullable('true');
    setUnique('false');
    setDecimalLength('16');
    setDecimalScale('4');
    setStringLength('100');
    setIsDefaultRequired(false);
  };

  return (
    <>
      <PFButton type="support" onClick={() => {
        if (Object.keys(apiRef.current.state.editRows).length > 0) {
          setExitModeWarning(true);
        } else {
          setActive(true);
        }
      }}>
        Add column
      </PFButton>
      <PFModal active={active} onDismiss={() => {
        setActive(false);
        resetStep();
      }}>
        <PFContainer type="toolbar" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m" gap="m"
            display="flex" justifyContent="space-between">

            <PFContainer display='flex' alignItems='center' gap='m'>
              {
                columnType
                  ? <PFButton type="support" onClick={resetStep}
                    padding="none" height={28}
                    style={{
                      background: 'var(--purple-300)',
                      borderRadius: '50%',
                      aspectRatio: 1,
                    }}>
                    <PFIcon icon="bx bx-chevron-left" size="l" />
                  </PFButton>
                  : <></>
              }
              <PFContainer display='flex' gap='xs'>
                <PFText>Add column: </PFText>
                <PFText color='var(--purple-100)'>
                  {DATA_TYPES[columnType]?.type_name}
                </PFText>
              </PFContainer>

            </PFContainer>
            <PFButton type="support" onClick={() => {
              setActive(false);
              resetStep();
            }}
              padding="none" height={28}
              style={{
                background: 'var(--purple-400)',
                borderRadius: '50%',
                aspectRatio: 1,
              }}>
              <PFIcon icon="bx bx-x" size="l" />
            </PFButton>

          </PFContainer>
          {
            columnType === null
              ? <PFContainer display="grid" gap="m"
                gridTemplateColumns="repeat(4, 1fr)" >
                {
                  getAddTypes(currentDB.tables.length)
                    .map((item, index) => {
                      return (
                        <TypeCard key={`${item.type_name}-${index}`}
                          columnType={item.type_name}
                          icon={item.icon}
                          setColumnType={setColumnType} />
                      );
                    })
                }

              </PFContainer>
              : <ColumnForm columnType={columnType} loading={loading}
                asyncCall={asyncCall} tableName={tableName}
                setActive={setActive} selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
                setColumnType={setColumnType}
                defaultValue={defaultValue}
                setDefaultValue={setDefaultValue}
                isDefaultRequired={isDefaultRequired}
                setIsDefaultRequired={setIsDefaultRequired}
                setUpdateTable={setUpdateTable} nullable={nullable}
                setNullable={setNullable} resetStep={resetStep}
                setUnique={setUnique} unique={unique}
                decimalScale={decimalScale}
                setDecimalScale={setDecimalScale}
                decimalLength={decimalLength}
                setDecimalLength={setDecimalLength}
                stringLength={stringLength}
                setStringLength={setStringLength}
                setFormError={setFormError} apiRef={apiRef} />
          }

        </PFContainer >
        <PFAlertUser message={formError} open={!!formError} close={() => {
          setFormError(null);
        }} />
        <PFAlert message={error} open={!!error}
          onClose={dismissError} />
      </PFModal>
    </>
  );
};

AddColumn.propTypes = {
  tableName: PropTypes.string.isRequired,
  setUpdateTable: PropTypes.func,
  setExitModeWarning: PropTypes.func
};

export default AddColumn;
