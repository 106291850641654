import {
  PFButton,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFText
} from 'PFComponents/common';
import { PROCESS_FLOW } from 'PFConstants';
import { useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';


const UI_ACTION_NODES = PROCESS_FLOW.NODE_TYPE_UI_ACTION_OPTIONS;

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit an action type node
 *
 * @param {Object} props - NodeBasicUIAction props.
 * @param {ProcessFlowNode} props.node - ACTION type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Navigate node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeBasicUIAction = ({
  node,
  updateNode,
  goBack,
}) => {
  const [nodeName, setNodeName] = useState(node?.alias ?? '');

  const nodeTypeInfo = UI_ACTION_NODES.find(
    (n) => n.name.toLowerCase() === node.action);
  const defaultName = `${nodeTypeInfo.name} node`;
  const { prompt, error_message } = nodeTypeInfo.config;

  const [form, fieldHook] = useForm({
    params: {
      default_value: node.params,
      validation_type: 'text',
      error_message
    }
  });

  const saveNode = (values) => {
    const updatedNode = { ...node, ...values };
    updatedNode.alias = nodeName ?? defaultName;

    updateNode(updatedNode);
    goBack();
  };

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={saveNode}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)}
              style={{
                color: 'var(--purple-100)'
              }} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={nodeTypeInfo.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{nodeTypeInfo.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {nodeTypeInfo.description}
              </PFText>
            </PFContainer>
          </PFContainer>

          <PFContainer display="flex" gap="s">
            <PFText type="secondary" size="s" margin="top-s">{prompt}</PFText>
            <PFInput
              name="params"
              fieldHook={fieldHook}
              width="100%"
              style={{ fontSize: 'var(--text-size-s)' }} />
          </PFContainer>

          <PFButton submit>Save</PFButton>
        </PFContainer>
      </PFForm>
    </PFContainer>
  );
};

NodeBasicUIAction.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeBasicUIAction;
