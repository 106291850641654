import PropTypes from 'prop-types';

import Wrapper from './Wrapper';

const MultistepPrep = ({
  canvas,
  component,
  setComponent,
  addPreviousComponent,
  addNextComponent,
  removeComponent
}) => {
  const currentStepIndex = canvas.getStep(component.id) ?? 0;
  const currentStep = component.children[currentStepIndex];
  const setCurrentStep = (newCurrentStep) => {
    const children = [...component.children];
    children.splice(currentStepIndex, 1, newCurrentStep);
    setComponent({ ...component, children });
  };

  const children = currentStep?.children;
  const setChildren = (children) => {
    setCurrentStep({ ...currentStep, children });
  };

  const addComponent = {
    BEFORE: addPreviousComponent,
    AFTER: addNextComponent,
    FIRST: (comp) => setChildren([comp, ...(children ?? [])]),
    LAST: (comp) => setChildren([...(children ?? []), comp]),
  };

  return (
    <Wrapper
      canvas={canvas}
      component={component}
      setComponent={setComponent}
      tag={currentStep?.build?.alias}
      componentChildren={children}
      setComponentChildren={setChildren}
      addComponent={addComponent}
      removeComponent={removeComponent}
    />
  );
};

MultistepPrep.propTypes = {
  canvas: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  setComponent: PropTypes.func.isRequired,
  addPreviousComponent: PropTypes.func.isRequired,
  addNextComponent: PropTypes.func.isRequired,
  removeComponent: PropTypes.func.isRequired,
};

export default MultistepPrep;
