import { authPefai } from 'PFApis';
import { images } from 'PFAssets';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFInput, PFLink, PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const EmailForm = ({ setUsername }) => {
  const [resetPasswordRequestForm, fieldHook] = useForm({
    email: {
      default_value: '',
      validation_type: 'email',
      error_message: 'Please provide an email'
    }
  });
  const navigate = useNavigate();
  const [asyncCall, resetPasswordRequestLoading,
    resetPasswordRequestError, errorDismiss] = useAsyncCall();

  const resetPasswordRequest = (values) => {
    asyncCall(async () => {
      await authPefai.resetPasswordRequest({
        username: values.email
      });
      setUsername(values.email);
      navigate('/forgot-password/confirm');
    });
  };

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="center" margin='top-xl bottom-xl'>
      <PFForm form={resetPasswordRequestForm} submit={resetPasswordRequest} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="center" gap="s">
          <PFLink to='/'>
            <img src={images.pefaiLogo} alt="Pefai" height={50} />
          </PFLink>
          <PFText tag="h2" weight={400} margin="top-xl bottom-l">
            Recover Password
          </PFText>
          <PFInput placeholder="Email" name="email"
            fieldHook={fieldHook}
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFButton submit loading={resetPasswordRequestLoading}
            width="100%" height={50}
            style={{ borderRadius: 25 }}>
            Recover
          </PFButton>
        </PFContainer>
      </PFForm>
      <PFAlert message={resetPasswordRequestError}
        open={!!resetPasswordRequestError} onClose={errorDismiss} />
    </PFContainer>
  );
};

EmailForm.propTypes = {
  setUsername: PropTypes.func,
};

export default EmailForm;
