import { twoapp } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer, PFIcon, PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { InputParameters } from '.';
import NodeResults from './NodeResults';

const FlowEditorTester = ({ closeTester,
  flowId, inputParameters,
  loading, error, dismissError, formObject, setFormObject }) => {
  const [asyncTestCall, testLoading, testError,
    dismissTestError] = useAsyncCall(false);
  const currentUserId = useSelector((state) => state.user._id);
  const currentAppId = useSelector((state) => state.current_app._id);
  const processflows = useSelector((state) => state.
    current_app.build.process_flows.docs);
  const processflowName = processflows
    .find((processflow) => processflow._id === flowId)?.alias;

  const [nodeResults, setNodeResults] = useState([]);


  const onSubmit = () => {
    const flowData = inputParameters.map((parameter) => {
      return {
        ...parameter,
        replace_value: formObject[parameter.field_name]
      };
    });
    asyncTestCall(async () => {
      const testResult = await twoapp.testFlow(flowId, flowData
        , currentAppId, currentUserId);
      setNodeResults(testResult.logs);
    });
  };

  return (
    <PFContainer>
      <PFContainer padding="l" radius="m"
        background='var(--white)' width='1200px'
      >
        <PFContainer display='flex' justifyContent='space-between'
          gap='s'>
          <PFContainer display='flex' gap='s'
            alignItems='center'>
            <PFButton height={'auto'}
              onClick={closeTester}
              padding='left-xs right-xs top-xs'
              style={{
                backgroundColor: 'var(--purple-50)',
                borderRadius: '100%'
              }}>
              <PFIcon icon="bx bx-x" size='l' color='var(--purple-100)' />
            </PFButton>
            <PFText color='var(--black)'>Test: </PFText>
            <PFText color='var(--purple-200)'>{processflowName}</PFText>
          </PFContainer>
          <PFButton style={{
            backgroundColor: 'var(--purple-200)',
            color: 'var(--white)',
            borderRadius: 'var(--radius-s)'
          }}
            onClick={onSubmit}>Run</PFButton>
        </PFContainer>

        <PFContainer margin='top-m' display='flex' gap='m'>
          <InputParameters formObject={formObject} setFormObject={setFormObject}
            inputParameters={inputParameters} loading={loading} />

          <NodeResults nodeResults={nodeResults} loading={testLoading} />
        </PFContainer>
      </PFContainer>
      <PFAlert message={error} open={!!error} onClose={dismissError} />
      <PFAlert message={testError} open={!!testError}
        onClose={dismissTestError} />
    </PFContainer>
  );
};

FlowEditorTester.propTypes = {
  closeTester: PropTypes.func,
  inputParameters: PropTypes.array,
  nodeResults: PropTypes.array,
  flowId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  dismissError: PropTypes.func,
  formObject: PropTypes.object,
  setFormObject: PropTypes.func
};

export default FlowEditorTester;
