import {
  PFButton,
  PFContainer,
  PFIcon, PFModal, PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import { useState } from 'react';

const DomainRow = ({ date, dateOptions,
  timeOptions, deployId, index, author,
  returnToVersion, loading }) => {
  const [currentVersionId, setCurrentVersionId] = useState(null);

  const tableColumns = '.8fr .8fr 1.2fr 1fr .8fr';
  return (
    <PFContainer padding="m"
      display="grid" gridTemplateColumns={tableColumns}
      style={{ borderTop: '1px solid var(--table-strokes)' }}>
      <PFContainer display="flex" alignItems="center" gap="s">
        <PFIcon color={index === 0 ? 'var(--secondary)' : 'white'} size="l"
          icon={index === 0 ? 'bx bxs-star' : 'bx bxs-arrow-from-right'} />
        <PFText color={index === 0 ? 'var(--secondary)' : 'white'}>
          {index === 0 ? 'Current' : 'Displaced'}
        </PFText>
      </PFContainer>
      <PFContainer>
        <PFText>{date.toLocaleDateString('en-US', dateOptions)}</PFText>
        <PFText>{date.toLocaleTimeString('en-US', timeOptions)}</PFText>
      </PFContainer>
      <PFContainer display="flex" gap="m" alignItems="center">
        <PFButton type="primary" width={40} height={40}>
          {author.full_name.charAt(0)}
        </PFButton>
        <PFContainer>
          <PFText>{author.full_name}</PFText>
          <PFText type="secondary" size="s">{author.email}</PFText>
        </PFContainer>
      </PFContainer>
      <PFContainer>
        <PFText size="s">{deployId}</PFText>
      </PFContainer>
      {index === 0
        ? null
        : <PFContainer display='flex' justifyContent='center'>
          <PFButton padding='s'
            type='none'
            style={{
              backgroundColor: 'var(--purple-200)',
              alignSelf: 'start',
              borderRadius: 'var(--radius-s)'
            }} onClick={() => {
              setCurrentVersionId(deployId);
            }}>
            <PFText size='s'>
              Return to version
            </PFText>
          </PFButton>
        </PFContainer>}
      <PFModal active={!!currentVersionId} onDismiss={() => {
        setCurrentVersionId(null);
      }}>
        <PFContainer type="toolbar" padding="l" radius="l" width={800}>
          <PFContainer margin="bottom-m"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <PFText type="secondary">
              Are you sure you want to roll back to this version?
            </PFText>
          </PFContainer>
          <PFContainer margin="top-l"
            display="flex" justifyContent="end" gap="m">
            <PFButton type="secondary" width={100}
              onClick={() => setCurrentVersionId(null)}>
              Cancel
            </PFButton>
            <PFButton width={100}
              onClick={() => {
                returnToVersion(deployId);
              }}
              loading={loading}>
              Yes
            </PFButton>
          </PFContainer>
        </PFContainer >
      </PFModal>
    </PFContainer>
  );
};

DomainRow.propTypes = {
  date: PropTypes.any,
  dateOptions: PropTypes.object,
  timeOptions: PropTypes.object,
  deployId: PropTypes.string,
  index: PropTypes.number,
  author: PropTypes.object,
  returnToVersion: PropTypes.func,
  loading: PropTypes.bool,
};

export default DomainRow;
