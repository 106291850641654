import { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFIconButton,
  PFModal,
  PFText
} from 'PFComponents/common';
import { ROLES } from 'PFConstants';
import { useAsyncCalls } from 'PFHooks';
import { AddPermission, PermissionManagement } from './components';

const SharingPanel = () => {
  const currentUserId = useSelector((state) => state.user._id);
  const currentDB = useSelector((state) => state.current_db);
  const accountPermissions = currentDB.permissions.accounts.docs;

  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [success, setSuccess] = useState(null);
  const [updateAsyncCall, deleteAsyncCall, alerts]
    = useAsyncCalls(false, false);

  const openSettings = () => setOpen(true);
  const dismissSettings = () => setOpen(false);

  const userRole = currentDB.current_user_role;
  const roleOptions = userRole === 'OWNER'
    ? ROLES.OPTIONS
    : ROLES.OPTIONS.slice(1);

  const owners = accountPermissions.filter((acc) => acc.role === 'OWNER');

  const renderAccounts = () => {
    return accountPermissions.map((account) => {
      return (
        <PermissionManagement
          key={account._id}
          dbId={currentDB._id}
          roleOptions={roleOptions}
          account={account}
          currentUserId={currentUserId}
          isUnique={accountPermissions.length === 1}
          singleOwner={owners.length < 2}
          updateAsyncCall={updateAsyncCall}
          deleteAsyncCall={deleteAsyncCall}
          setSuccess={setSuccess} />
      );
    });
  };

  return (
    <>
      <PFButton onClick={openSettings}
        type="secondary"
        height={50}
        padding="horizontal-l"
        style={{ borderRadius: 25 }}>
        <PFContainer
          display="flex" alignItems="center" gap="s">
          <PFIcon icon="bx bx-user-plus" size="l" />
          Share
        </PFContainer>
      </PFButton>
      <PFModal active={open} onDismiss={dismissSettings}>
        <PFContainer type="form" padding="l" radius="l" width={800}
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center"
            justifyContent="space-between">
            <PFText type="support">Share settings</PFText>
            <PFIconButton icon="bx bx-x" onClick={dismissSettings} />
          </PFContainer>
          <PFText>Accounts with access</PFText>
          <PFContainer margin="bottom-m"
            display="flex" flexDirection="column" gap="s">
            {renderAccounts()}
          </PFContainer>
          <PFButton type="secondary"
            onClick={() => setAddOpen(true)}
            width="100%">
            + add account
          </PFButton>
        </PFContainer >
      </PFModal >
      <AddPermission
        dbId={currentDB._id}
        roleOptions={roleOptions}
        open={addOpen}
        handleDismiss={() => setAddOpen(false)} />
      <PFAlert severity="success" message={success}
        open={success !== null} onClose={() => setSuccess(null)} />
      {alerts()}
    </>
  );
};
export default SharingPanel;
