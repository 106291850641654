import Rating from '@mui/material/Rating';
import { format } from 'PFUtils';

export default {
  'String': {
    type: 'string',
    width: 300
  },
  'Integer': {
    type: 'number',
    width: 100
  },
  'Decimal': {
    type: 'number',
    width: 100
  },
  'Auto Increment': {
    type: 'number',
    width: 100
  },
  'Boolean': {
    type: 'string',
    width: 100
  },
  'Date & Time': {
    type: 'dateTime',
    valueGetter: ({ value }) => {
      if (!value) return value;
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    },
    width: 200
  },
  'Select': {
    type: 'singleSelect',
    width: 200
  },
  'Currency': {
    type: 'number',
    valueFormatter: ({ value }) => format.currency(value),
    width: 200
  },
  'Email': {
    type: 'string',
    width: 200
  },
  'Phone': {
    type: 'string',
    valueFormatter: ({ value }) => format.phoneNumber(value),
    width: 150
  },
  'Rating': {
    type: 'number',
    width: 140,
    renderCell: (params) => <Rating readOnly value={params.value} />
  },
  'Big Integer': {
    type: 'number',
    width: 250
  },
  'Short String': {
    type: 'string',
    width: 200
  },
  'Long String': {
    type: 'string',
    width: 400
  },
  'Date': {
    type: 'string',
    width: 200
  },
  'Float': {
    type: 'number',
    width: 100
  },
  'Money': {
    type: 'number',
    width: 200
  }
};

