import { GridRowModes } from '@mui/x-data-grid';
import { DB } from 'PFConstants';


const { getInitialValues } = DB;

export const getEmptyRowModel = (fields, ctrl_index, isNew) => {
  const keyValueRow = fields.map((dbField) => {
    return [dbField.column_name, getInitialValues()[dbField.type_name]];
  });

  keyValueRow.push(['ctrl_index', ctrl_index]);
  keyValueRow.push(['isNew', isNew]);

  return Object.fromEntries(keyValueRow);
};

export const addRowLocally = (table, rows) => {
  const ctrl_index = rows.values.length;
  rows.setValues([
    ...rows.values,
    getEmptyRowModel(table.fields, ctrl_index, true)
  ]);
  rows.setModels((oldModels) => ({
    ...oldModels,
    [ctrl_index]: {
      mode: GridRowModes.Edit,
      fieldToFocus: table.fields[1] ? table.fields[1].column_name : null
    },
  }));
};
