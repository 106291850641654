/* eslint-disable max-len */

const PFIconComponentPassword = () => {
  return (
    <svg width="56" height="45" viewBox="0 0 56 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.3" width="55.2949" height="19.7869" rx="6" fill="#AD91FF" />
      <rect x="0.831307" y="6.56666" width="53.6328" height="37.4429" rx="5.16967" fill="#1E192B" stroke="#342C49" strokeWidth="1.66066" />
      <rect x="5.4834" y="17.373" width="44" height="16" rx="3" fill="#332851" />
      <path d="M27.1201 23.7985L27.5701 24.5985L26.2001 25.1085L27.5701 25.6085L27.1101 26.4385L25.9701 25.5285L26.1901 26.9885H25.2601L25.4601 25.5285L24.3101 26.4585L23.8301 25.6085L25.1901 25.0985L23.8401 24.5985L24.2901 23.7885L25.4601 24.6885L25.2501 23.2285H26.1901L25.9701 24.6885L27.1201 23.7985Z" fill="#AD90FF" />
      <path d="M22.208 23.7985L22.658 24.5985L21.288 25.1085L22.658 25.6085L22.198 26.4385L21.058 25.5285L21.278 26.9885H20.348L20.548 25.5285L19.398 26.4585L18.918 25.6085L20.278 25.0985L18.928 24.5985L19.378 23.7885L20.548 24.6885L20.338 23.2285H21.278L21.058 24.6885L22.208 23.7985Z" fill="#AD90FF" />
      <path d="M17.2959 23.7985L17.7459 24.5985L16.3759 25.1085L17.7459 25.6085L17.2859 26.4385L16.1459 25.5285L16.3659 26.9885H15.4359L15.6359 25.5285L14.4859 26.4585L14.0059 25.6085L15.3659 25.0985L14.0159 24.5985L14.4659 23.7885L15.6359 24.6885L15.4259 23.2285H16.3659L16.1459 24.6885L17.2959 23.7985Z" fill="#AD90FF" />
      <path d="M12.3837 23.7985L12.8338 24.5985L11.4637 25.1085L12.8338 25.6085L12.3738 26.4385L11.2337 25.5285L11.4538 26.9885H10.5238L10.7237 25.5285L9.57375 26.4585L9.09375 25.6085L10.4538 25.0985L9.10375 24.5985L9.55375 23.7885L10.7237 24.6885L10.5138 23.2285H11.4538L11.2337 24.6885L12.3837 23.7985Z" fill="#AD90FF" />
      <line x1="30.6334" y1="18.373" x2="30.6334" y2="32.373" stroke="#BEA7FF" strokeWidth="0.3" />
    </svg>
  );
};

export default PFIconComponentPassword;
