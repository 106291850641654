import PropTypes from 'prop-types';

import { componentUtils } from 'PFUtils';

/**
 * Standard form component of the PFComponents library. This form complies
 * with the Pefai style guidelines. The underlying implementation of this
 * form is the React *form* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFForm properties.
 * @param {React.ReactNode} props.children - Child elements to be
 * rendered inside the form.
 * @param {Object} [props.form] - Object provided by the 'useForm' hook to
 * manage the content of a form.
 * @param {func} [props.submit] - Function to be called when the form is
 * submitted.
 * @param {string} [props.className] - Class name to be applied to the form.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the form.
 * @param {string} [props.margin] - Determines the margin config of the form
 * component.
 * @param {string} [props.padding] - Determines the padding config of the form
 * component.
 *
 * @return {React.ReactElement} The form component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFForm = ({
  children,
  form,
  submit,
  className,
  style,
  margin,
  padding,
}) => {
  const formClassName = `pf form ${className}`;

  const configStyle = componentUtils.getStylesFromConfig({ margin, padding });
  const formStyle = { ...configStyle, ...style };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.validate()) {
      submit(form.values);
    }
  };

  return (
    <form className={formClassName} style={formStyle} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

PFForm.defaultProps = {
  className: '',
  submit: () => { },
  style: {},
};

PFForm.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.object,
  submit: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  margin: PropTypes.string,
  padding: PropTypes.string,
};

export default PFForm;
