import {
  PFButton,
  PFContainer, PFText
} from 'PFComponents/common';
import { PFTableCreationModal } from 'PFComponents/editors';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TableEditorFooter.css';

const TableEditorFooter = ({ dbId, tables, currentTable,
  apiRef, setExitModeWarning }) => {
  const [creationFormOpen, setCreationFormOpen] = useState(false);
  const navigate = useNavigate();
  const baseURL = `/editor/databases/${dbId}/tables`;

  const renderTableTabs = () => {
    return tables.map((table, i) => {
      const selected = table.table_name === currentTable;

      return (
        <div key={table.table_name} onClick={() => {
          if (Object.keys(apiRef.current.state.editRows).length > 0) {
            setExitModeWarning(true);
          } else {
            navigate(`${baseURL}/${table.table_name}`);
          }
        }
        }
          style={{
            cursor: 'pointer'
          }}
          className='pf link'>
          <PFContainer height="100%" display="flex" alignItems="center"
            padding={`horizontal-${selected ? 'm' : 's'}`}
            background={selected ? 'var(--primary)' : 'none'}
            style={{
              borderTopRightRadius: selected ? 10 : 0,
              borderBottomLeftRadius: i !== 0 && selected ? 10 : 0,
              transition: 'all 200ms ease'
            }}>
            <PFText>{table.alias}</PFText>
          </PFContainer>
        </div>
      );
    });
  };

  return (
    <>
      <PFContainer style={{ marginBottom: 40 }}>
        <PFContainer className="table-editor-footer-container">
          <PFContainer height="100%" display="flex" type="editor">
            {renderTableTabs()}
            <PFButton type="support" onClick={() => {
              if (Object.keys(apiRef.current.state.editRows).length > 0) {
                setExitModeWarning(true);
              } else {
                setCreationFormOpen(true);
              }
            }}>
              + Add
            </PFButton>
          </PFContainer>
        </PFContainer>
      </PFContainer>
      <PFTableCreationModal
        dbId={dbId}
        baseURL={baseURL}
        updateState
        active={creationFormOpen}
        onDismiss={() => setCreationFormOpen(false)} />
    </>
  );
};

TableEditorFooter.propTypes = {
  dbId: PropTypes.string.isRequired,
  tables: PropTypes.array.isRequired,
  currentTable: PropTypes.string.isRequired,
  apiRef: PropTypes.object,
  setExitModeWarning: PropTypes.func,
};

export default TableEditorFooter;
