import { authPefai } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFForm,
  PFInput,
  PFText
} from 'PFComponents/common';
import { useAsyncCall, useForm } from 'PFHooks';
import { useNavigate } from 'react-router-dom';

const UserUpdatePassword = () => {
  const [updatePasswordForm, fieldHook] = useForm({
    old_password: {
      default_value: '',
      validation_type: 'password',
      error_message: 'Please provide a password'
    },
    password: {
      default_value: '',
      validation_type: 'password',
      error_message: 'Please provide a password'
    },
    confirm_password: {
      default_value: '',
      validation_type: 'confirmation',
      params: {
        referenceValue: 'password',
        originValue: 'confirm_password'
      },
      error_message: 'Please confirm your password'
    }
  });
  const [asyncCall, updatePasswordLoading, updatePasswordError,
    errorDismiss] = useAsyncCall();
  const navigate = useNavigate();
  const updatePassword = (values) => {
    asyncCall(async () => {
      await authPefai.updatePassword({
        old_password: values.old_password,
        password: values.password
      });
      navigate('/profile');
    });
  };

  return (
    <PFContainer display="flex" flex={1}
      justifyContent="start" margin='top-m bottom-xl'>
      <PFForm form={updatePasswordForm} submit={updatePassword} >
        <PFContainer type="main" width={350} display="flex"
          flexDirection="column" alignItems="start" gap="s">
          <PFText tag="h2" weight={400} margin="top-xl bottom-l">
            Update password
          </PFText>
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)'>
              First, you'll need to authenticate using your
              old password</PFText>
          </PFContainer>
          <PFInput placeholder="Password" name="old_password"
            fieldHook={fieldHook} type='password'
            width="100%"
            style={{ height: 50 }} />
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)'>
              Now, let's create your new password</PFText>
          </PFContainer>

          <PFInput placeholder="Create your password" name="password"
            fieldHook={fieldHook} type='password'
            width="100%" margin="bottom-xs"
            style={{ height: 50 }} />
          <PFContainer width='100%' style={{ textAlign: 'start' }}>
            <PFText color='var(--purple-100)' size='s'>1 uppercase</PFText>
            <PFText color='var(--purple-100)' size='s'>1 lowercase</PFText>
            <PFText color='var(--purple-100)' size='s'>1 symbol</PFText>
            <PFText color='var(--purple-100)' size='s'>1 number</PFText>
            <PFText color='var(--purple-100)' size='s'>
              8 characters at least</PFText>
          </PFContainer>
          <PFInput placeholder="Confirm your password" name="confirm_password"
            fieldHook={fieldHook} type='password'
            width="100%" margin="bottom-m"
            style={{ height: 50 }} />
          <PFButton submit loading={updatePasswordLoading}
            width="100%" height={50} margin='bottom-l top-m'
            style={{ borderRadius: 25 }}>
            Update password
          </PFButton>
        </PFContainer>
      </PFForm>
      <PFAlert message={updatePasswordError}
        open={!!updatePasswordError} onClose={errorDismiss} />
    </PFContainer>
  );
};

export default UserUpdatePassword;
