import { twoapp } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFContainer,
  PFIcon,
  PFText
} from 'PFComponents/common';
import { useAsyncCall } from 'PFHooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import InputParameters from './InputParameters';
import NodeResults from './NodeResults';

const IntegrationTester = ({
  closeTester,
  integrationId,
  inputParameters
}) => {
  const [formObject, setFormObject] = useState(inputParameters
    .reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {}));

  const [asyncTestCall, testLoading
    , testError, dismissTestError] = useAsyncCall(false);

  const currentUserId = useSelector((state) => state.user._id);
  const currentAppId = useSelector((state) => state.current_app._id);
  const [nodeResults, setNodeResults] = useState({});
  const apiDeclarations = useSelector((state) => state
    .current_app.build.api_declarations?.docs);
  const integrationName = apiDeclarations
    .find((api) => api._id === integrationId)?.alias;

  const onSubmit = () => {
    asyncTestCall(async () => {
      const flowData = formObject;
      const testResult = await twoapp.testIntegration(
        currentAppId,
        integrationId,
        flowData,
        currentAppId,
        currentUserId
      );
      setNodeResults(testResult);
    });
  };

  return (
    <PFContainer>
      <PFContainer
        padding='l'
        radius='m'
        background='var(--white)'
        width='1200px'
      >
        <PFContainer display='flex' justifyContent='space-between' gap='s'>
          <PFContainer display='flex' gap='s' alignItems='center'>
            <PFButton
              height={'auto'}
              onClick={closeTester}
              padding='left-xs right-xs top-xs'
              style={{
                backgroundColor: 'var(--purple-50)',
                borderRadius: '100%',
              }}
            >
              <PFIcon icon='bx bx-x' size='l' color='var(--purple-100)' />
            </PFButton>
            <PFText color='var(--black)'>Test: </PFText>
            <PFText color='var(--purple-200)'>{integrationName}</PFText>
          </PFContainer>
          <PFButton
            style={{
              backgroundColor: 'var(--purple-200)',
              color: 'var(--white)',
              borderRadius: 'var(--radius-s)',
            }}
            onClick={onSubmit}
          >
            Run
          </PFButton>
        </PFContainer>

        <PFContainer margin='top-m' display='flex' gap='m'>
          <InputParameters
            inputParameters={inputParameters}
            formObject={formObject}
            setFormObject={setFormObject}
          />

          <NodeResults nodeResults={nodeResults} loading={testLoading} />
        </PFContainer>
      </PFContainer>
      <PFAlert
        message={testError}
        open={!!testError}
        onClose={dismissTestError}
      />
    </PFContainer>
  );
};

IntegrationTester.propTypes = {
  closeTester: PropTypes.func,
  inputParameters: PropTypes.array,
  nodeResults: PropTypes.object,
  integrationId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  dismissError: PropTypes.func,
  formObject: PropTypes.object,
  setFormObject: PropTypes.func,
};

export default IntegrationTester;
