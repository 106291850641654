import styled from 'styled-components';

const ContentSlider = styled.div<{ configStyles?: string | undefined }>`
  position: relative;
  gap: 16px;
  display: flex;
  overflow: hidden;

  ${(props) => props.configStyles}
`;

const ContentSliderNavigationButton = styled.div<{ configStyles?: string | undefined }>`
  ${(props) => props.configStyles}
`;

export { ContentSlider, ContentSliderNavigationButton };
