import { PFContainer, PFIcon, PFText } from 'PFComponents/common';
import PropTypes from 'prop-types';
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 6 });

const EmptyCanvas = ({ canvas, addComponent }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const builderComponentID = 'Container-' + uid();
    const componentToAdd = canvas.dragging;
    canvas.setLastElementAdded(componentToAdd.build.id);
    addComponent({
      attributes: {
        configStyles: {
          'box-sizing': 'border-box',
          'background-color': '#ffffff',
          'width': '100%',
          'height': '100%'
        }
      },
      build: {
        alias: 'Container',
        id: builderComponentID
      },
      children: [
        componentToAdd
      ],
      component: 'Container',
      id: 'Container-' + uid(),
      namespace: '',
      settings: null,
      viewport: null
    });
  };

  return (
    <PFContainer width="100%" height="100%" display="flex"
      flexDirection="column" alignItems="center" justifyContent="center"
      config={{
        onDragOver: handleDragOver,
        onDrop: handleDrop
      }}>
      <PFText size="l" weight={200}>What are you gonna</PFText>
      <PFText size="l" weight={200}>build today?</PFText>
      <PFContainer padding="l" margin="vertical-l horizontal-xl" radius="l"
        display="flex" flexDirection="column" alignItems="center"
        border="1px solid var(--secondary)" gap="s" style={{
          alignSelf: 'stretch'
        }}>
        <PFIcon icon="bx bx-plus-circle" size="xl"
          color="var(--secondary)" />
        <PFText color="var(--secondary)">
          Drag your components here
        </PFText>
      </PFContainer>
    </PFContainer>
  );
};

EmptyCanvas.propTypes = {
  canvas: PropTypes.object.isRequired,
  addComponent: PropTypes.func.isRequired,
};

export default EmptyCanvas;

