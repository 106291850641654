import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { PFButton, PFContainer, PFIcon, PFText } from 'PFComponents/common';
import { componentUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import './PFSelect.css';

/**
 * Standard select component of the PFComponents library. This select complies
 * with the Pefai style guidelines. The underlying implementation of this
 * select is the Material UI *Select* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFSelect properties.
 * @param {string} [props.label] - Text to be displayed above the select input.
 * @param {string} [props.name] - Name of the select input (required if
 * associated to a 'useForm' hook).
 * @param {string} [props.multiple] - To handle multiple selections
 * @param {string} [props.prefix] - Text to place before a the value displayed
 * on the select component.
 * @param {Array.<{label: string, value: any}>} [props.options] - Options to be
 * used inside the select.
 * @param {string} [props.placeholder] - Placeholder to be displayed inside the
 * select input.
 * @param {func} [props.fieldHook] - Function provided by the 'useForm' hook to
 * manage the content of a field.
 * @param {string} [props.className] - Class name to be applied to the select.
 * @param {any} [props.value] - Current value of the select.
 * @param {string} [props.error] - Current error of the select value.
 * @param {func} [props.onChange] - Function to be called when the value of the
 * select changes (not necessary if fieldHook is present).
 * @param {boolean} [props.disabled=false] - Determines if the select is
 * currently disabled.
 * @param {string} [props.margin] - Determines the margin config of the select
 * component.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the select component.
 * @param {number} [props.zIndex=1300] - Determines the 'z-index' css property
 * to be applied to component.
 * @param {Object} [props.optionsStyles] - Style override to be applied to
 * options.
 * @param {Object} [props.listStyles] - Style override to be applied to
 * list container.
 * @param {Object} [props.iconColor] - Color applied to the icon
 *
 * @return {React.ReactElement} The select component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFSelect = ({
  label,
  name,
  prefix,
  options,
  placeholder,
  fieldHook,
  className,
  value,
  error,
  onChange,
  disabled,
  margin,
  style,
  zIndex,
  optionsStyles,
  listStyles,
  multiple = false,
  iconColor
}) => {
  const hook = fieldHook ? fieldHook(name) : {};

  const selectValue = hook.value ?? value;
  const selectError = hook.error ?? error;
  const selectUpdate = hook.updateAndValidate
    ? (e) => hook.updateAndValidate(e.target.value)
    : null;

  const [open, setOpen] = useState(false);
  const handleOpen = (e) => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectClassName
    = `pf select ${className} 
    ${open ? 'focus' : ''} ${selectError ? 'error' : ''}`;
  const configStyle = componentUtils.getStylesFromConfig({ margin });

  const valueDisplay = !!selectValue
    ? options?.find((o) => o.value === selectValue)?.label
    : null;

  const renderOptions = () => {
    return options.map((option, i) => {
      const { label, value } = option;
      return (<MenuItem key={i} value={value}
        sx={{
          fontFamily: 'Poppins',
          fontSize: '12px',
          ...optionsStyles
        }} >
        {label}</MenuItem>);
    });
  };


  return (
    <div>
      {label
        ? <label className="pf select-label">{label}</label>
        : null
      }
      <div className="pf select-container" style={configStyle}>
        <FormControl fullWidth size='small' style={{ opacity: 0 }}>
          <Select
            multiple={multiple}
            MenuProps={{
              style: { zIndex },
              PaperProps: {
                sx: {
                  borderRadius: '8px',
                  ...listStyles
                },
              }
            }}
            value={selectValue}
            onChange={onChange ?? selectUpdate}
            open={open}
            onClose={handleClose}
          >
            {renderOptions()}
          </Select>
        </FormControl>
        <PFButton
          type="support" className={selectClassName}
          onClick={handleOpen} disabled={disabled} style={style}>
          <PFContainer display="flex"
            justifyContent="space-between" alignItems="center">
            <PFContainer display="flex" gap="xs">
              {prefix ? <PFText type="secondary">{prefix}</PFText> : null}
              <PFText type={!!valueDisplay ? 'primary' : 'secondary'}
                clip style={{ maxWidth: 200 }}>
                {valueDisplay ?? placeholder}
              </PFText>
            </PFContainer>
            <PFIcon color={iconColor} icon="bx bxs-chevron-down" />
          </PFContainer>
        </PFButton>
      </div>
      {selectError
        ? <label className="pf select-error">{selectError}</label>
        : null
      }
    </div>
  );
};

PFSelect.defaultProps = {
  options: [],
  placeholder: 'Select',
  className: '',
  style: {},
  zIndex: 1300,
};

PFSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  prefix: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  fieldHook: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  style: PropTypes.object,
  zIndex: PropTypes.number,
  optionsStyles: PropTypes.object,
  listStyles: PropTypes.object,
  multiple: PropTypes.bool,
  iconColor: PropTypes.string
};

export default PFSelect;
