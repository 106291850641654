import { PFContainer, PFIcon, PFText } from 'PFComponents/common';

const EmptyTableComponent = () => {
  return (
    <PFContainer width="100%" height="100%"
      display="flex" alignItems="center" justifyContent="center">
      <PFContainer width="100%" padding="l" margin="xl" radius="l"
        display="flex" flexDirection="column" alignItems="center"
        border="1px solid var(--secondary)" gap="s">
        <PFIcon icon="bx bx-data" size="xl" color='var(--secondary)' />
        <PFText type="secondary" style={{
          fontFamily: 'var(--theme-font)'
        }}>
          This table is empty
        </PFText>
      </PFContainer>
    </PFContainer>
  );
};

export default EmptyTableComponent;
