import ShortUniqueId from 'short-unique-id';
import {
  NESTING_RESTRICTIONS,
  SEMANTIC_RESTRICTIONS
} from 'src/constants/COMPONENTS';
const uid = new ShortUniqueId({ length: 6 });

/**
 * Basic function to iterate over component's json schema children
 * to identify attribute build.id and filter from set
 *
 * @param {*} blob - blob of raw json components
 * @param {*} _id - blob raw build.id to delete
 * @return {array} - a filtered array of components without _id matched element
 */
export function filterBlobByBuildId(blob, _id) {
  return blob.map((blobElement) => {
    if (blobElement.children) {
      blobElement.children = filterBlobByBuildId(blobElement.children, _id);
    }
    return blobElement;
  }).filter((blobElement) => {
    return blobElement.build.id !== _id;
  });
}

/**
 * It is a function that iterates over the blob until it finds the selected
 * component. Returns the selected component with its descendants and also its
 * parent.
 * @param {object} composition  - blob of raw json
 * @param {string} id - selected component id
 * @param {object} parent - parent of selected component as row json
 * @returns {object} - an objectx with two properties, component and parentId
 */

export function getComposition(composition, id, parent) {
  if (composition.build.id === id) {
    return { component: composition, parentId: parent.build.id };
  }
  if (composition.children && Array.isArray(composition.children)) {
    for (const child of composition.children) {
      const compositionSlice = getComposition(child, id, composition);
      if (compositionSlice.component) {
        return {
          component: compositionSlice.component,
          parentId: compositionSlice.parentId
        };
      }
    }
  }
  return { component: null, parentId: null };
};

/**
 * It is a function that searches and locates the parent component based on its
 * id.
 * @param {object} component - Blob of raw json copy
 * @param {string} targetId - Known parent ID (build id)
 * @returns {object} - A slice of the raw object corresponding to the parent of
 * the copied element
 */

export function findComponentById(component, targetId) {
  if (component.build.id === targetId) {
    return component;
  }

  if (component.children && component.children.length > 0) {
    for (const child of component.children) {
      const foundComponent = findComponentById(child, targetId);
      if (foundComponent) {
        return foundComponent;
      }
    }
  }

  return null;
}

/**
 *
 * @param {object} componentBlob - Blob of raw json copy
 * @param {string} name - Known component name
 * @return {boolean}
 */
export function findComponentByName(componentBlob, name) {
  if (componentBlob.component === name) {
    return true;
  }

  if (componentBlob.children && componentBlob.children.length > 0) {
    for (const child of componentBlob.children) {
      const foundComponent = findComponentByName(child, name);
      if (foundComponent) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Its a function to change component id and build id
 * @param {object} component - raw json blob corresponding to the composition
 * to paste into the Canvas
 * @returns {object} - A composition slice with all modified IDs
 */

export function updateIdsRecursive(component) {
  if (component) {
    const componentName = component.component;
    if (component.id) {
      component.id = `${componentName}-${uid()}`;
    }
    if (component.build.id) {
      component.build.id = `${componentName}-${uid()}`;
      component.build.ref = `${componentName}-${uid()}`;
    }
    if (Array.isArray(component.children)) {
      component.children.forEach((child) => updateIdsRecursive(child));
    }
  }
  return component;
};

/**
 * It is a function that calculates the level of nesting of an element relative
 * to its parent. In this way, we can condition, for example, whether we allow
 * a TableBody component to have a TableCell component as its direct child
 * @param {object} blob - Blob of raw json components
 * @param {string} targetId - It refers to the unique ID of the component that
 * is intended to be the recipient of the component, either by dropping it or by
 * pasting it
 * @param {number} depth - It's the level of nesting to calculate. If the
 * component is found in the first iteration, then the default value is 0
 * @returns {number} - The nesting level
 */

export function findComponentLevel(blob, targetId, depth = 0) {
  if (blob.id === targetId) {
    return 0;
  }

  if (blob.children && blob.children.length > 0) {
    for (let i = 0; i < blob.children.length; i++) {
      const child = blob.children[i];
      const foundNestedLevel = findComponentLevel(
        child,
        targetId,
        (depth = depth + 1)
      );
      if (foundNestedLevel !== null) {
        return foundNestedLevel + 1;
      }
    }
  }
  return null;
};

/**
 *
 * @param {object} target -It refers to the raw json of the component that
 * is intended to be the recipient of the component, either by dropping it or by
 * pasting it
 * @param {object} blob - Blob of raw json components
 * @param {string} parentName - It's the name that the parent component must
 * have
 * @returns {number} - The ancestry level
 */

export function findValidAncestry(target, blob, parentName) {
  if (!parentName) return 0;
  if (blob.component === parentName) {
    const foundComponent = findComponentLevel(
      blob,
      target.id
    );
    if (foundComponent !== null) {
      return foundComponent;
    }
    return null;
  }
  if (blob.children && blob.children.length > 0) {
    for (const child of blob.children) {
      const foundComponent = findValidAncestry(target, child, parentName);
      if (foundComponent !== null) {
        return foundComponent;
      }
    }
  }
  return null;
};

/**
 *
 * @param {object} target -It refers to the raw json of the component that
 * is intended to be the recipient of the component, either by dropping it or by
 * pasting it
 * @param {object} blob - Blob of raw json components
 * @param {string} ancestryName - It's the name that the ancestry component must
 * have
 * @returns {object} - A raw json of the component ancestry
 */

export function getAncestryByName(target, blob, ancestryName) {
  if (!ancestryName) return null;
  if (blob.component === ancestryName) {
    const foundComponent = findComponentLevel(
      blob,
      target.id
    );
    if (foundComponent !== null && foundComponent !== 0) {
      return blob;
    }
    return null;
  }
  if (blob.children && blob.children.length > 0) {
    for (const child of blob.children) {
      const foundComponent = getAncestryByName(target, child, ancestryName);
      if (foundComponent !== null) {
        return foundComponent;
      }
    }
  }
  return null;
};

/**
 * It is a function to validate that a copied component can be pasted as a
 * child of the currently selected component in the Canvas
 * @param {object} targetComponent - It is the object corresponding to the
 * selected component in the Canvas.
 * @param {object} componentToPaste - Raw json blob corresponding to the
 * composition to be pasted into the Canvas.
 * @param {object} updatedBlob - Blob of raw json components
 * @returns {object} - A validation status for paste with error included
 */

export function validateAllowedPaste(
  targetComponent,
  componentToPaste,
  updatedBlob
) {
  const nestingRules = NESTING_RESTRICTIONS.find(
    (rule) => findComponentByName(componentToPaste, rule.component)
  );
  const restrictionRules = SEMANTIC_RESTRICTIONS.find(
    (rule) => rule.parent === targetComponent.dataset.alias
  );
  if (restrictionRules) {
    const acceptChildren = restrictionRules.allowChildren;
    if (acceptChildren) {
      const notAllowedComponent = restrictionRules.notAllowedChildren.some(
        (child) => child === componentToPaste.component
      );
      const notAllowedAttribute = restrictionRules.notAllowedAttributes.some(
        (attribute) =>
          attribute
          === componentToPaste.attributes.configStyles?.display
      );
      if (notAllowedAttribute || notAllowedComponent) {
        return {
          isAllowedToPaste: false,
          error: null,
        };
      } else {
        return {
          isAllowedToPaste: true,
          error: null,
        };
      }
    } else {
      return {
        isAllowedToPaste: false,
        error: null,
      };
    }
  } else if (nestingRules) {
    const ancestryLevel = findValidAncestry(
      targetComponent,
      updatedBlob[0],
      nestingRules.requiredParent
    );
    if (ancestryLevel !== null) {
      if (
        nestingRules.level !== null
        && nestingRules.level !== ancestryLevel
      ) {
        return {
          isAllowedToPaste: false,
          // eslint-disable-next-line max-len
          error: `${componentToPaste.component} cannot be a direct descendant of ${targetComponent.dataset.alias}`,
        };
      } else {
        const restrictionRules = SEMANTIC_RESTRICTIONS.find(
          (rule) => rule.parent === targetComponent.dataset.alias
        );
        if (restrictionRules) {
          const acceptChildren = restrictionRules.allowChildren;
          return {
            isAllowedToPaste: acceptChildren,
            error: null,
          };
        } else {
          return {
            isAllowedToPaste: true,
            error: null,
          };
        }
      }
    }
    return {
      isAllowedToPaste: false,
      // eslint-disable-next-line max-len
      error: `${nestingRules.component} can only be placed in a ${nestingRules.requiredParent}`,
    };
  } else {
    return {
      isAllowedToPaste: true,
      error: null,
    };
  }
};

/**
 * This function calculates the top position of the label containing the
 * component name. This label is positioned taking into account the component
 * or target, the visible area of the canvas, as well as the type of label, as
 * it could be the one that appears on hover actions, selection, or drop zone
 * @param {object} target - This is the object corresponding to the selected
 * component or the one under the cursor, and even the drop zone.
 * @param {string} type - Defines the tag name type
 * @param {number} viewportHeight - It's the visible or working area on the
 * Canvas
 * @returns {number} - The top position of the tag name
 */

export function calcTagBoundaries(target, type, viewportHeight) {
  const iframeScrollPos = getIframeRelativeScrollPos('responsive-frame');
  const topOffset = target.getBoundingClientRect().top + iframeScrollPos.y;
  const height = target.getBoundingClientRect().height;

  if (topOffset <= 20) {
    if (type === 'info') {
      return topOffset;
    } else if (type === 'dropzone') {
      if (height >= viewportHeight) {
        return 0;
      } else {
        return height - 4;
      }
    }
  } else {
    if (type === 'info') {
      return topOffset - 19;
    } else if (type === 'dropzone') {
      return -19;
    }
  }
};

export function calcRadiusByBoundary(target) {
  if (target.offsetTop <= 20) {
    return '0px 0px 4px 4px';
  } else {
    return '4px 4px 0px 0px';
  }
}

export function getIframeRelativeScrollPos(iframeId) {
  const responsiveIframeHelper = document.getElementById(iframeId);
  if (!responsiveIframeHelper) return;

  // eslint-disable-next-line prefer-const
  let scrollPos = { x: 0, y: 0 };
  if (responsiveIframeHelper) {
    const responsiveIframeWindow = responsiveIframeHelper.contentWindow;
    if (responsiveIframeWindow) {
      scrollPos.x = responsiveIframeWindow.scrollX
        || responsiveIframeWindow.pageXOffset;
      scrollPos.y = responsiveIframeWindow.scrollY
        || responsiveIframeWindow.pageYOffset;
    }
  }
  return scrollPos;
}
