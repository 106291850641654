export const formConfigFromApi = (api, avoidEmptyParams, currentTab) => {
  const setParams = (property) => {
    if (!!property && property.length > 0) {
      return property;
    } else {
      return [];
    }
  };

  return {
    application_type: {
      default_value: !!api?.application_type
        ? api?.application_type : 'application/json',
      validation_type: 'text',
      error_message: 'Please provide an API application type'
    },
    response_type: {
      default_value: !!api?.response_type
        ? api?.response_type : 'application/json',
      validation_type: 'text',
      error_message: 'Please provide an API response type'
    },
    info: {
      alias: {
        default_value: api?.info?.alias ?? '',
        validation_type: 'text',
        error_message: 'Please provide a name for the API'
      },
      description: api?.info?.description ?? '',
    },
    method: {
      default_value: !!api?.method ? api?.method : 'GET',
      validation_type: 'text',
      error_message: 'Missing'
    },
    url: {
      default_value: api?.url ?? '',
      validation_type: 'text',
      error_message: 'Please provide an API endpoint'
    },
    headers: setParams(api?.headers),
    query: setParams(api?.query),
    params: setParams(api?.params),
    response_formatter: setParams(api?.response_formatter),
    body: {
      default_value: api?.body ?? '',
      validation_type: currentTab === 'soap'
        ? 'xml'
        : 'json',
      error_message: 'Please provide valid request body'
    }
  };
};

