import React, { ReactNode } from 'react';
import CurrencyInput from 'react-currency-input-field';
import * as Styled from './currency.styled';

export type CurrencyProps = {
    /**
     * Allows the use of decimals (onBlur)
     */
    allowDecimals?: boolean;

    /**
     * allows the browser to predict the value
     */
    autoComplete?: string;

    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;

    /**
     * for accessibility roles and attributes
     */
    className?: string;

    /**
     * for component-level styling override (Design System)
     */
    configStyles?: string;

    /**
     * to set the currency code (ISO 4217)
     */
    currency?: string;

    /**
     * Defines the amount of decimal places (onBlur)
     */
    decimalsLimit?: number;

    /**
     * to disable or enable the Currency element
     */
    disabled?: boolean;

    /**
     * an identifier for the Currency element
     */
    id: string;

    /**
     * to set the currency format (Language tags BCP 47)
     */
    locale?: string;

    /**
     * specifies the name of the Currency element
     */
    name: string;

    /**
     * a function to be triggered when the Currency element loses focus
     */
    onBlur?: () => void;

    /**
     *
     * Used to handle onChange event object
     * Either by user or by form handlers (Formik) configurations
     *
     * @param event
     * @returns void
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

    /**
     * a function to be triggered when the Currency element gets focus
     */
    onFocus?: () => void;

    /**
     * a placeholder for the Currency element
     */
    placeholder?: string;

    /**
     * Value's currency prefix
     */
    prefix?: string;

    /**
     * specifies that the Currency is read-only
     */
    readOnly?: boolean;

    /**
     * specifies that the Currency must be filled
     */
    required?: boolean;

    /**
     * a function to be triggered when the Currency element value change
     */
    setValue?: any;

    /**
     * Value's suffix
     */
    suffix?: string;

    /**
     * Althought his component self cleans special characters
     * to set to value, onChange gets event.target.value which contains
     * special characters as prefixes, dots, commas, suffixes.
     *
     * Given: $123.45 USD
     * True will return 123.45 - mostly used for calculations
     * False will return $123.45 USD - used for labels
     *
     *
     * Note: Currently only works with Formik's implementation
     */
    useCleanValue?: boolean;

    /**
     * Field state value. Commonly used with a Form (Formik)
     */
    value?: string;
};

export function Currency({
    decimalsLimit = 2,
    allowDecimals = true,
    autoComplete = 'off',
    className = '',
    configStyles = '',
    currency,
    disabled,
    id,
    locale = '',
    name,
    onBlur,
    onFocus,
    placeholder,
    readOnly,
    required,
    setValue,
    suffix = '',
    value,
    children,
    onChange,
    prefix = '$',
    useCleanValue = true,
    ...props
}: CurrencyProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange) return false;

        // Note: Although react-currency-field strips "value" of special characters,
        // a onChange might be hijacked by another library that handles this components
        // state. Formik, for example, uses onChange to handle component's state.

        // If required, we'll have to manually strip the event's target value
        // from the special characters
        if (useCleanValue) {
            let modifiedEvent = event;
            modifiedEvent.target.value = sanitizedValue(event.target.value);
            onChange(modifiedEvent);
        } else {
            onChange(event);
        }
    };

    const sanitizedValue = (value: string | undefined) => {
        if (!value) return '';
        const regex = /^[0-9]+(\.[0-9]*)?$/;
        const replacedValue = value.toString().replace(/[^0-9.]/g, '');
        if (!regex.test(replacedValue)) return '';
        return replacedValue;
    };

    return (
        <Styled.CurrencyField className={className} configStyles={configStyles}>
            <CurrencyInput
                allowDecimals={allowDecimals}
                autoComplete={autoComplete}
                decimalsLimit={decimalsLimit}
                disabled={disabled}
                id={id}
                intlConfig={{ locale: locale, currency: currency }}
                name={name}
                onBlur={onBlur}
                onChange={handleChange}
                onFocus={onFocus}
                onValueChange={(e) => setValue(e)}
                placeholder={placeholder}
                prefix={prefix ? `${prefix} ` : ` `}
                readOnly={readOnly}
                required={required}
                suffix={suffix ? ` ${suffix}` : ``}
                value={value}
                {...props}
            />
        </Styled.CurrencyField>
    );
}
