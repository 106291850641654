import {
  PFButton,
  PFContainer,
  PFText
} from 'PFComponents/common';
import PropTypes from 'prop-types';
import '../../DesignEditor.css';
import { EditorControl } from '../../components';
import { validateSizeInput } from '../../utils';

function composeSize({ composedValue }) {
  const [[styleKey, styleValue]] = Object.entries(composedValue);
  const configStyles = {
    [styleKey]: styleValue
  };

  return {
    configStyles
  };
}

const Size = ({
  activeSection,
  componentBreakpointStyles,
  composeStyles,
  handleActiveSection,
  ruleSet,
}) => {
  return (
    <PFContainer padding="right-s left-s">
      <PFButton onClick={handleActiveSection}
        type='support' padding='0' height='auto'
        style={{ position: 'relative', zIndex: '2' }}>
        <PFText className='pf-design-editor__section-title'>
          <i className={!activeSection
            ? 'pf icon bx bxs-chevron-up text'
            : 'pf icon bx bxs-chevron-down text'} />
          Size
        </PFText>
      </PFButton>
      <PFContainer className={!activeSection
        ? 'pf-design-editor__section--up' : 'pf-design-editor__section--down'}>
        <PFContainer display='flex' style={{ gap: '8px' }}>
          {
            ruleSet.map((sizeRule, index) => {
              const ruleKey = Object.keys(sizeRule)[0];
              const ruleValueControls = sizeRule[ruleKey];
              return (
                <PFContainer
                  key={`designeditor-
                  ${ruleKey}${index}`}
                  className="pf-designer-editor__rule
                  pf-designer-editor__rule--vertical">
                  <PFText className='pf-design-editor__rule-title'
                    color='var(--purple-100)'
                    padding='bottom-s'
                    style={{ alignSelf: 'flex-start' }} >
                    {ruleKey}
                  </PFText>
                  <PFContainer
                    className="pf-design-editor__control"
                    gap='s'
                    display='flex'
                    style={{ flexDirection: 'column', width: 'auto' }}>
                    {
                      ruleValueControls.map((rule, index) => {
                        return (
                          <PFContainer
                            key={index}
                            display="grid"
                            gridTemplateColumns='32px 1fr'
                            gap="m"
                            alignItems='center'>
                            <PFText
                              className='pf-design-editor__rule-title'>
                              {rule.text}</PFText>
                            <EditorControl
                              key={`design-editor-rule-${rule.key}-${index}}`}
                              componentBreakpointStyles=
                              {componentBreakpointStyles}
                              onChange={(event) => {
                                // eslint-disable-next-line max-len
                                const normalizedInputValue = validateSizeInput(componentBreakpointStyles[rule.key], event.target.value);


                                const composedValue
                                  = composeSize({
                                    composedValue:
                                    {
                                      [rule.key]:
                                        normalizedInputValue
                                    }
                                  });

                                composeStyles(
                                  {
                                    composedAttributes:
                                      composedValue,
                                  });
                              }}
                              className={rule.className}
                              styleConfig={{ key: rule.key, value: rule.value }}
                              type={typeof rule.type === 'undefined'
                                ? 'editor-control-button' : rule.type}>
                              {rule.label}
                            </EditorControl>
                          </PFContainer>
                        );
                      })
                    }
                  </PFContainer>
                </PFContainer>);
            })
          }
        </PFContainer>
        <PFContainer
          display="grid"
          gridTemplateColumns='32px 1fr'
          gap="m" alignItems='center' padding
          ="top-xs" >
          <PFText className='pf-design-editor__rule-title'>
            Fit
          </PFText>
          <EditorControl
            componentBreakpointStyles={componentBreakpointStyles}
            onChange={(event) => {
              event.preventDefault();
              const composedValue = composeSize({
                composedValue: { 'object-fit': event.target.value },
                componentBreakpointStyles
              });

              composeStyles({
                composedAttributes: composedValue,
              });
            }}
            styleConfig={{ key: 'object-fit', value: '' }}
            type='editor-control-dropdown'
            typeConfig='Fit'
          >
          </EditorControl>
        </PFContainer>
      </PFContainer>
    </PFContainer >
  );
};

export default Size;

Size.propTypes = {
  activeSection: PropTypes.bool,
  composeStyles: PropTypes.func.isRequired,
  handleActiveSection: PropTypes.func,
  ruleSet: PropTypes.array.isRequired,
  componentBreakpointStyles: PropTypes.object
};
