import React, { ReactNode } from 'react';
import * as Styled from './image.styled';
export type ImageProps = {
    /**
     * address of the source
     */
    src: string;
    /**
     * text description for accesibility and semantics purposes
     */
    alt: string;
    /**
     * define the height of the image
     */
    height?: string;
    /**
     * define the width of the image
     */
    width?: string;
    /**
     * a set of classes separated by one space applied to the button
     */
    className?: string;
    /**
     * for component level styling override (Design System)
     */
    configStyles?: string;
    /**
     * To identify the component
     */
    id?: string;
    /**
     * loading modes for images
     * lazy : defer loading offscreen
     * eager: prioritize loading
     * auto: ...well, auto
     */
    loading?: 'lazy' | 'eager' | 'auto';
    /**
     * For configured image source fallback in case of no image found
     */
    srcFallback?: string | undefined;
    /**
     * *** Do not use ***
     * Used to remove property from ...props spread.
     * Must refactor builder to avoid this
     */
    children?: ReactNode;
};
export function Image({
    alt,
    className,
    children,
    configStyles,
    height,
    loading = 'auto',
    src,
    width,
    id,
    srcFallback,
    ...props
}: ImageProps) {
    return (
        <Styled.Image
            id={id}
            src={src || srcFallback}
            alt={alt}
            width={width}
            height={height}
            className={className}
            configStyles={configStyles}
            loading={loading}
            {...props}
        />
    );
}
