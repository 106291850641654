import PropTypes from 'prop-types';

import { PFLoader, PFTooltip } from 'PFComponents/common';
import { componentUtils } from 'PFUtils';

import './PFButton.css';

/**
 * Standard button component of the PFComponents library. This button complies
 * with the Pefai style guidelines. The underlying implementation of this
 * button is the React *button* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFButton properties.
 * @param {React.ReactNode} props.children - Child elements to be
 * rendered inside the button.
 * @param {string} [props.className] - Class name to be applied to the button.
 * @param {string} [props.tooltip] - Displays helper text (tooltip) when
 * hovering over the button.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the button.
 * @param {"primary"|"secondary"|"support"|"none"} [props.type="primary"] -
 * Type of button to be rendered, this changes the appearance of the button.
 * @param {function} [props.onClick] - Function to be performed on a click
 * action.
 * @param {boolean} [props.disabled=false] - Disables the button when *true*.
 * @param {boolean} [props.loading=false] - Sets the button to disabled and runs
 * an animation while this persists.
 * @param {boolean} [props.submit=false] - Allows the button to submit a form
 * (needs to be inside a form component).
 * @param {Object} [props.config={}] - Extra options to be passed as params to
 * the underlying *button*.
 * @param {string|number} [props.width] - Determines the width of the
 * button component.
 * @param {string|number} [props.height] - Determines the height of the button
 * component.
 * @param {string} [props.margin] - Determines the margin config of the button
 * component.
 * @param {string} [props.padding] - Determines the padding config of the button
 * component.
 *
 * @return {React.ReactElement} Button component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFButton = ({
  children,
  className,
  tooltip,
  style,
  type,
  onClick,
  disabled,
  loading,
  submit,
  config,
  width,
  height,
  margin,
  padding,
}) => {
  const buttonClassName
    = `pf button ${type} ${disabled ? 'disabled' : ''} ${className}`;

  const configStyle = componentUtils.getStylesFromConfig({ margin, padding });
  const buttonStyle = {
    width,
    height,
    ...configStyle,
    ...style,
    padding: configStyle.padding
  };

  const handleOnClick = (e) => {
    if (!submit) e.preventDefault();
    onClick();
  };

  const loaderColor = {};
  if (type === 'none') {
    loaderColor.color = 'white';
  }

  let button = (
    <button
      {...config}
      className={buttonClassName}
      style={buttonStyle}
      onClick={handleOnClick}
      disabled={disabled || loading}
    >
      {loading
        ? <PFLoader size="s" {...loaderColor} />
        : children}
    </button>
  );

  if (!!tooltip) {
    button = (
      <PFTooltip title={tooltip}>
        {button}
      </PFTooltip>
    );
  }

  return button;
};

PFButton.defaultProps = {
  className: '',
  style: {},
  type: 'primary',
  onClick: () => { },
  disabled: false,
  loading: false,
  submit: false,
  config: {},
};

PFButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary', 'support', 'none']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  submit: PropTypes.bool,
  config: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  padding: PropTypes.string,
};

export default PFButton;
