import { useEffect } from 'react';

/**
 * Custom hook to detect keyboard combinations and perform custom actions with
 * them,
 *
 * @param {string} keysCombination - Key combination to detect (use '+' to use
 * more than one).
 * @param {func} callback - Function to call when the combination is detected.
 */
const useShortcut = (keysCombination, callback) => {
  const keys = keysCombination.split('+');

  useEffect(() => {
    const handleKeyDown = (event) => {
      const code = event.which || event.keyCode;
      const charCode = String.fromCharCode(code).toLowerCase();

      if (keys.includes('ctrl') && (event.ctrlKey || event.metaKey)) {
        if (keys.includes(charCode)) {
          event.preventDefault();
          callback();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [keys, callback]);
};

export default useShortcut;
