import { builderV2 } from 'PFApis';
import {
  PFAlert,
  PFButton,
  PFCheckbox,
  PFContainer,
  PFForm,
  PFIcon,
  PFInput,
  PFLoader,
  PFSelect,
  PFText
} from 'PFComponents/common';
import { PFAPIDeclarationModalEditor } from 'PFComponents/editors';
import { PROCESS_FLOW } from 'PFConstants';
import { useAsyncCall, useForm } from 'PFHooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const CUSTOM_API_TYPE = PROCESS_FLOW.NODE_TYPE_ACTION_OPTIONS
  .find((n) => n.type === 'API_CALL');

/**
 * @typedef {import('../../../../../utils/flows').ProcessFlowNode
 * } ProcessFlowNode
*/

/**
 * Component to edit a custom API node
 *
 * @param {Object} props - NodeCustomAPI props.
 * @param {ProcessFlowNode} props.node - API_CALL type node.
 * @param {func} props.updateNode - Function to update the node.
 * @param {func} props.goBack - Function to go back on previous editor tab.
 *
 * @return {React.ReactElement} - Custom API node editor component
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const NodeCustomAPI = ({ node, updateNode, goBack }) => {
  const currentApp = useSelector((state) => state.current_app);
  const apiDeclarations = currentApp.build.api_declarations.docs;

  const [api, setApi] = useState(null);
  const [apiEditOpen, setApiEditOpen] = useState(false);
  const [nodeName, setNodeName] = useState(node?.alias ?? '');
  const [asyncNode, setAsyncNode] = useState(node?.async ?? false);

  const [asyncCall, loading, error, dismissError] = useAsyncCall(true);
  const [form, fieldHook] = useForm(buildFormConfig(node, api));

  const selectedApi = form.values.api_declaration;

  useEffect(() => {
    if (selectedApi) {
      asyncCall(async () => {
        const api = await builderV2.getAPI(currentApp._id, selectedApi);
        form.setConfig(buildFormConfig(node, api));
        setNodeName(api?.info?.alias);
        setApi(api);
      });
    }
  }, [selectedApi]);

  const apiOptions = apiDeclarations.map(({ _id, alias }) => {
    return { label: alias, value: _id };
  });

  const finishedEditingApi = (newApi) => {
    if (newApi) {
      form.setConfig(buildFormConfig(node, newApi));
      setApi(newApi);
    }
    setApiEditOpen(false);
  };

  const saveNode = (values) => {
    const updatedNode = { ...node };
    updatedNode.alias = nodeName ?? 'API Call node';
    updatedNode.api_declaration = values.api_declaration;
    updatedNode.params = values.params;
    updatedNode.async = asyncNode;

    updateNode(updatedNode);
    goBack();
  };

  if ((loading && selectedApi) || error) {
    return (
      <PFContainer className="node-custom-api-editor">
        <PFContainer type="form" width={400} height={80}
          padding="m" radius="m"
          display="flex" alignItems="center" justifyContent="center">
          <PFLoader color="var(--primary)" />
        </PFContainer>
        <PFAlert message={error} open={!!error} onClose={dismissError} />
      </PFContainer>
    );
  }

  return (
    <PFContainer className="node-custom-api-editor">
      <PFForm form={form} submit={saveNode}>
        <PFContainer type="form" padding="m" width={400} radius="m"
          display="flex" flexDirection="column" gap="m">
          <PFContainer display="flex" alignItems="center" gap="s">
            <PFButton
              type="support"
              className="flow-dismiss-button"
              onClick={goBack}>
              <PFIcon icon="bx bx-chevron-left" size="l" />
            </PFButton>
            <PFInput className="alt" width="100%"
              value={nodeName} placeholder="Node name"
              onChange={(e) => setNodeName(e.target.value)} />
          </PFContainer>

          <PFContainer display="flex" gap="m" alignItems="flex-start">
            <PFContainer background="var(--purple-25)" padding="s" radius="s">
              <PFIcon icon={CUSTOM_API_TYPE.icon}
                size="l" color="var(--primary)" />
            </PFContainer>
            <PFContainer>
              <PFText>{CUSTOM_API_TYPE.name}</PFText>
              <PFText type="secondary" size="s" weight={300}>
                {CUSTOM_API_TYPE.description}
              </PFText>
            </PFContainer>
          </PFContainer>

          <PFContainer display="flex" flexDirection="column" gap="s"
            style={{
              marginTop: '-10px'
            }}>
            <PFContainer display='flex' justifyContent='flex-end'>
              <PFContainer display='flex' gap='s' alignItems='center'>
                <PFText color='var(--purple-400)' size='s'>
                  ASYNC
                </PFText>
                <PFCheckbox checked={asyncNode}
                  onChange={() => {
                    setAsyncNode((prev) => !prev);
                  }} />
              </PFContainer>
            </PFContainer>

            <PFSelect
              name="api_declaration"
              fieldHook={fieldHook}
              options={apiOptions}
              placeholder="Select an API" />
            <PFButton type="none" onClick={() => setApiEditOpen(true)}
              padding="0" style={{ alignSelf: 'flex-end' }}>
              <PFText type="secondary" size="s">
                {!!api ? 'Edit integration' : '+ Create integration'}
              </PFText>
            </PFButton>

          </PFContainer>


          {!!api && api.params?.length > 0
            ? <PFContainer margin="bottom-m">
              <PFText weight={300} margin="bottom-s">Parameters</PFText>
              <PFContainer
                display="grid"
                gridTemplateColumns="auto 1fr"
                gap="s">
                <APIParamFields params={api.params} fieldHook={fieldHook} />
              </PFContainer>
            </PFContainer>
            : null
          }

          <PFButton submit>Save</PFButton>
        </PFContainer>
      </PFForm>
      <PFAPIDeclarationModalEditor
        api={api}
        open={apiEditOpen}
        onClose={finishedEditingApi} />
    </PFContainer>
  );
};

const APIParamFields = ({ params, fieldHook }) => {
  return params.map(({ key }) => {
    return (
      <React.Fragment key={key}>
        <PFContainer
          background="var(--input-form-background)"
          padding="horizontal-s" radius="s"
          display="flex" alignItems="center" justifyContent="flex-end">
          <PFText type="secondary" size="s">{key}</PFText>
        </PFContainer>
        <PFInput
          style={{ fontSize: 'var(--text-size-s)' }}
          name={`params.${key}`}
          fieldHook={fieldHook}
          placeholder={`${key} value`} />
      </React.Fragment>
    );
  });
};

const buildFormConfig = (node, api) => {
  const formConfig = {
    api_declaration: {
      default_value: api?._id ?? node.api_declaration ?? '',
      validation_type: 'text',
      error_message: 'Please select an API declaration'
    },
    params: {}
  };

  if (!!api) {
    for (const { key } of api.params) {
      formConfig.params[key] = node.params[key] ?? '';
    }
  }

  return formConfig;
};

NodeCustomAPI.propTypes = {
  node: PropTypes.object.isRequired,
  updateNode: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default NodeCustomAPI;
