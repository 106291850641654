import _ from 'lodash';
import { validationUtils } from 'PFUtils';
import { useState } from 'react';


const validatorFuncs = {
  content: validationUtils.validateContent,
  text: validationUtils.validateText,
  email: validationUtils.validateEmail,
  json: validationUtils.validateJSON,
  password: validationUtils.validatePassword,
  code: validationUtils.validateSixDigitCode,
  confirmation: validationUtils.validateConfirmation,
  xml: validationUtils.validateXML
};

/**
 * Form hook object which contains the information and handler functions for
 * the form to be managed.
 * @typedef  {Object} HookForm
 * @property {Object<string, *>} values
 * @property {Object<string, string>} errors
 * @property {()} validate
 * @property {()} setConfig
 */

/**
 * Field hook object which contains the information of a particular field
 * inside a form, and handler for the field to be managed.
 * @typedef  {Object} HookField
 * @property {*} value
 * @property {string?} error
 * @property {()} update
 * @property {()} validate
 * @property {()} updateAndValidate
 */


/**
 * Function to get hook field given it's name.
 * @callback FieldHook
 * @param {string} name
 * @return {HookField}
 */

/**
 * Hook to handle a complete form, including  values, validations and errors.
 *
 * @param {Object.<string, {
 * default_value: any,
 * validation_type: ("text"|"email"|"content"|"json"|null),
 * error_message: (string|null)
 * }>} formConfig - Initial
 * configuration for the form.
 *
 * @return {[HookForm, FieldHook]} Form necessary functions
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const useForm = (formConfig) => {
  const [config, setConfig] = useState(flattenObject(formConfig));
  const [form, setForm] = useState(getFormFromConfig(config));

  const updateField = (name, value) => {
    if (form.values.hasOwnProperty(name)) {
      setForm({
        ...form, values: {
          ...form.values,
          [name]: value
        }
      });
    }
  };

  const validateField = (name) => {
    const fieldConfig = config[name];
    if (!!fieldConfig) {
      const { validation_type, error_message } = fieldConfig;
      const validatorFunc = validatorFuncs[validation_type];
      if (!!validatorFunc) {
        const fieldError
          = validatorFunc(form.values[name], error_message);

        setForm({
          ...form,
          errors: {
            ...form.errors,
            [name]: fieldError
          }
        });
      }
    }
  };

  const updateAndValidateField = (name, value) => {
    const fieldConfig = config[name];

    if (form.values.hasOwnProperty(name) && !!fieldConfig) {
      let fieldError = null;
      const { validation_type, error_message } = fieldConfig;
      const validatorFunc = validatorFuncs[validation_type];
      if (!!validatorFunc) {
        fieldError = validatorFunc(value, error_message);
      }

      setForm({
        values: {
          ...form.values,
          [name]: value
        },
        errors: {
          ...form.errors,
          [name]: fieldError
        }
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let foundError = false;

    for (const fieldName in config) {
      const { validation_type, error_message, params } = config[fieldName];
      const validatorFunc = validatorFuncs[validation_type];

      if (!!validatorFunc) {
        const fieldError = validatorFunc(form.values[fieldName],
          error_message, { ...params, form: form, submit: true });
        newErrors[fieldName] = fieldError;

        if (!!fieldError) foundError = true;
      }
    }

    setForm({ ...form, errors: newErrors });

    return !foundError;
  };

  const setFormConfig = (formConfig) => {
    const newConfig = flattenObject(formConfig);
    setConfig(newConfig);
    setForm(getFormFromConfig(newConfig));
  };

  const addFieldValidation = (fieldName, validationType,
    errorMessage, params) => {
    if (config.hasOwnProperty(fieldName)) {
      const fieldConfig = config[fieldName];
      fieldConfig.validation_type = validationType;
      fieldConfig.error_message = errorMessage;
      fieldConfig.params = params;

      setConfig({ ...config, [fieldName]: fieldConfig });
    }
  };

  const formHook = {
    values: unflattenObject(form.values),
    errors: unflattenObject(form.errors),
    validate: validateForm,
    setConfig: setFormConfig,
    addFieldValidation,
  };

  const fieldHook = (fieldName) => {
    return {
      value: form.values[fieldName],
      error: form.errors[fieldName],
      update: (value) => updateField(fieldName, value),
      validate: () => validateField(fieldName),
      updateAndValidate: (value) => updateAndValidateField(fieldName, value),
    };
  };

  return [formHook, fieldHook];
};

const getFormFromConfig = (config) => {
  const values = {};
  const errors = {};

  for (const name in config) {
    const { default_value } = config[name];
    values[name] = default_value ?? '';
    errors[name] = null;
  }

  return { values, errors };
};

const flattenObject = (obj, parentKey = '', result = {}) => {
  for (const key in obj) {
    const newPath = parentKey ? `${parentKey}.${key}` : key;

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      if (!!obj[key] && obj[key].hasOwnProperty('default_value')) {
        result[newPath] = obj[key];
      } else {
        flattenObject(obj[key], newPath, result);
      }
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        const arrayPath = `${newPath}[${index}]`;
        if (typeof item === 'object') {
          if (!!item && item.hasOwnProperty('default_value')) {
            result[arrayPath] = item;
          } else {
            flattenObject(item, arrayPath, result);
          }
        } else {
          result[arrayPath] = { default_value: item };
        }
      });
    } else {
      result[newPath] = { default_value: obj[key] };
    }
  }

  return result;
};

const unflattenObject = (flattenedObj) => {
  const unflattenedObj = {};

  for (const key in flattenedObj) {
    if (flattenedObj.hasOwnProperty(key)) {
      const value = flattenedObj[key];
      _.set(unflattenedObj, key, value);
    }
  }

  return unflattenedObj;
};

export default useForm;
