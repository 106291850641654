import ADD_DATA_TYPES from './ADD_DATA_TYPES';
import DATA_TYPES_FIELDS from './DATA_TYPES_FIELDS';
import FIELDS_NAMES from './FIELDS_NAMES';

const {
  NULLABLE, UNIQUE, DEFAULT_VALUE,
  LENGTH, SCALE, INDEX,
  TABLE, COLUMN
} = FIELDS_NAMES;
export const getFormTypes = (action = 'add', typeName) => {
  const FIELD_NAME = action === 'add' ? 'alias' : 'column_name';
  const FORM_TYPES = {
    'String': {
      type_name: 'String',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 100,
          validation_type: 'text',
          error_message: 'Field max length missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Integer': {
      type_name: 'Integer',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Big Integer': {
      type_name: 'Big Integer',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Decimal': {
      type_name: 'Decimal',
      distribution: [4, 2, 2, 2, 2, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 16,
          validation_type: 'text',
          error_message: 'Field length missing',
        },
        [SCALE]: {
          default_value: 4,
          validation_type: 'text',
          error_message: 'Field scale missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Float': {
      type_name: 'Float',
      distribution: [4, 2, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Date': {
      type_name: 'Date',
      distribution: [4, 2, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Date & Time': {
      type_name: 'Date & Time',
      distribution: [4, 2, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Boolean': {
      type_name: 'Boolean',
      distribution: [4, 2, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Money': {
      type_name: 'Money',
      distribution: [4, 2, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Foreign Key': {
      type_name: 'Foreign Key',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [TABLE]: {
          default_value: ''
        },
        [COLUMN]: {
          default_value: ''
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        }
      }
    },
    'Short String': {
      type_name: 'Short String',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 100,
          validation_type: 'text',
          error_message: 'Field max length missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Long String': {
      type_name: 'Long String',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 400,
          validation_type: 'text',
          error_message: 'Field max length missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Email': {
      type_name: 'Email',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 50,
          validation_type: 'text',
          error_message: 'Field max length missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Phone': {
      type_name: 'Email',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 20,
          validation_type: 'text',
          error_message: 'Field max length missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Select': {
      type_name: 'Select',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 35,
          validation_type: 'text',
          error_message: 'Field max length missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Currency': {
      type_name: 'Currency',
      distribution: [4, 2, 2, 2, 2, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 16,
          validation_type: 'text',
          error_message: 'Field length missing',
        },
        [SCALE]: {
          default_value: 2,
          validation_type: 'text',
          error_message: 'Field scale missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Rating': {
      type_name: 'Rating',
      distribution: [4, 2, 2, 2, 2, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [LENGTH]: {
          default_value: 2,
          validation_type: 'text',
          error_message: 'Field length missing',
        },
        [SCALE]: {
          default_value: 1,
          validation_type: 'text',
          error_message: 'Field scale missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    },
    'Auto Increment': {
      type_name: 'Auto Increment',
      distribution: [4, 2, 3, 3, 3, 3],
      form: {
        [FIELD_NAME]: {
          default_value: '',
          validation_type: 'text',
          error_message: 'Field name missing',
        },
        [NULLABLE]: {
          default_value: 'true',
          validation_type: 'text',
          error_message: 'Field nullable missing',
        },
        [UNIQUE]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field unique missing',
        },
        'auto_increment': {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field auto increment missing',
        },
        [INDEX]: {
          default_value: 'false',
          validation_type: 'text',
          error_message: 'Field index missing',
        },
        [DEFAULT_VALUE]: {
          default_value: ''
        }
      }
    }
  };
  return { ...FORM_TYPES[typeName] };
};

export const getForm = (action = 'add', typeName, initialValues) => {
  const FORM_TYPES = getFormTypes(action, typeName);
  const formTypes = { ...FORM_TYPES };
  const form = formTypes.form;

  Object.keys(form).forEach((key) => {
    if (initialValues.hasOwnProperty(key)) {
      form[key].default_value = initialValues[key];
    }
  });

  return form;
};

export const getFormSettings = (action = 'add', typeName, initialValues) => {
  const FORM_TYPES = getFormTypes(action, typeName);
  const formTypes = { ...FORM_TYPES };
  const form = formTypes.form;
  if (typeName === initialValues.type_name) {
    Object.keys(form).forEach((key) => {
      if (initialValues.hasOwnProperty(key)) {
        form[key].default_value = initialValues[key];
      }
    });
  } else {
    Object.keys(form).forEach((key) => {
      if (initialValues.hasOwnProperty('alias')) {
        form['alias'].default_value = initialValues['alias'];
      }
    });
  }
  return form;
};

export const getAddTypes = (numberOfTables) => {
  if (numberOfTables > 1) {
    return Object.values(ADD_DATA_TYPES);
  } else {
    return Object.values(ADD_DATA_TYPES).filter((el) => el.type_name
      !== 'Foreign Key');
  }
};

export const getFields = (form) => {
  const matches = [];

  Object.keys(form.form).forEach((key) => {
    if (DATA_TYPES_FIELDS.hasOwnProperty(key)) {
      matches.push(DATA_TYPES_FIELDS[key]);
    }
  });
  return matches;
};

export default getForm;
