import styled from 'styled-components';

const Heading = styled.div<{ weight: number; level: number; configStyles: string }>`
    font-size: ${(props) => props.theme.typography[`heading${props.level}`]};
    font-weight: ${(props) => props.weight};

    ${(props) => props.configStyles};
`;

export { Heading };
