import { componentUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import './PFLink.css';

/**
 * Standard link component of the PFComponents library. This link complies
 * with the Pefai style guidelines. The underlying implementation of this
 * link is the react-router-hash-link *HashLink* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFLink properties.
 * @param {React.ReactNode} props.children - Child elements to be
 * rendered inside the link.
 * @param {"primary"|"secondary"} [props.type="primary"] - Type of link
 * to be rendered, this changes the appearance of the link.
 * @param {string} [props.to] - Link or path to follow when clicking the link.
 * @param {boolean} [props.openInNewTab] - Determines if the link should be
 * opened in a new tab when clicked.
 * @param {string} [props.className] - Class name to be applied to the link.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the link.
 * @param {string} [props.color] - Color to be applied to the link.
 * @param {boolean} [props.block] - Determines if 'display: block' should be
 * applied to the link.
 * @param {string} [props.margin] - Determines the margin config of the link
 * component.
 * @param {string} [props.target="_self"] - Determines the target of the link
 *
 * @return {React.ReactElement} The link component.
 *
 * @author Andres Barragan <andres@pefai.com>
 */
const PFLink = ({
  children,
  type,
  to,
  openInNewTab,
  className,
  style,
  color,
  block,
  margin,
  target
}) => {
  const linkClassName = `pf link ${type} ${block ? 'block' : ''} ${className}`;

  const configStyle = componentUtils.getStylesFromConfig({ margin });
  const linkStyle = {
    color,
    ...configStyle,
    ...style
  };

  const config = {};
  if (openInNewTab) {
    config.target = '_blank';
    config.rel = 'noopener noreferrer';
  }

  return (
    <Link to={to} className={linkClassName} target={target}
      style={linkStyle} {...config}>
      {children}
    </Link>
  );
};

PFLink.defaultProps = {
  type: 'primary',
  className: '',
  style: {},
};

PFLink.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary']),
  to: PropTypes.string,
  openInNewTab: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  block: PropTypes.bool,
  margin: PropTypes.string,
  target: PropTypes.string,
};

export default PFLink;
