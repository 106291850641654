import { PFAlert, PFContainer } from 'PFComponents/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import './Canvas.css';
import {
  Breadcrumbs,
  EmptyCanvas,
  Inspector,
  ListWrapper,
  ResponsiveFrame,
  Sidebar
} from './components';
import { useCanvas, useIframeShortcuts } from './utils';


const Canvas = ({
  blob,
  setBlob,
  theme,
  customComponentUpdate,
  saveBlob,
  setTogglePageSettings,
  toggleDictionary
}) => {
  const canvas = useCanvas({ customComponentUpdate });
  const [openDrawer, setOpenDrawer] = useState(null);
  const [error, setError] = useState(null);

  const handleHover = (e) => {
    if (!!e.target.id && e.target.id !== 'pefai-canvas') {
      if (e.target.tagName === 'SPAN') {
        canvas.setHover(e.target.parentElement);
      } else {
        canvas.setHover(e.target);
      }
    }
  };

  const handleMouseLeave = () => {
    canvas.setHover(null);
    canvas.setDropZone(null);
  };

  const handleSelection = (e) => {
    e.preventDefault();
    const { target } = e;
    canvas.setSelectedCanvasComponent({ target, e });
    setOpenDrawer(null);
    setTogglePageSettings(false);
  };

  const handleDelete = useCallback((event) => {
    canvas.deleteComponent({ event, blob, setBlob });
  }, [canvas.selected]);

  const handleCopy = useCallback((event) => {
    canvas.copyComponent({ canvas, blob });
  }, [canvas]);

  const handlePaste = useCallback((event) => {
    const selectedComponent = canvas.selected;
    canvas.pasteComponent({ selectedComponent, blob, setBlob, setError });
  }, [blob, canvas]);

  const handleUndo = useCallback((event) => {
    canvas.undo({ setBlob });
  });

  const handleRedo = useCallback((event) => {
    canvas.redo({ setBlob });
  });

  const updateHistory = (blob, currentVersion) => {
    canvas.setHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      if (!canvas.isPrevVersion) {
        newHistory.push(blob);
        if (newHistory.length > 15) {
          newHistory.splice(0, newHistory.length - 15);
        }
      } else {
        newHistory[currentVersion + 1] = blob;
        newHistory.length = currentVersion + 2;
        canvas.setIsPrevVersion(false);
      }
      return newHistory;
    });
  };

  useEffect(() => {
    const isDraggingOverCanvas
      = canvas.hover && canvas.dragging && canvas.dropZone;
    const isWithinHistoryBounds
      = canvas.currentVersionIndex <= canvas.history.length - 1;
    if (!canvas.jumpedBack.current
      && !canvas.jumpedFront.current
      && !isDraggingOverCanvas
      && isWithinHistoryBounds) {
      const clonedBlob = cloneDeep(blob);
      updateHistory(clonedBlob, canvas.currentVersionIndex);
      canvas.setCurrentVersionIndex((prevIndex) =>
        (prevIndex === 14 ? 14 : prevIndex + 1));
    } else if (canvas.jumpedBack.current) {
      canvas.jumpedBack.current = false;
    } else if (canvas.jumpedFront.current) {
      canvas.jumpedFront.current = false;
    }
  }, [blob]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();

        toggleDictionary(false);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useIframeShortcuts('Delete', handleDelete, 'responsive-frame');
  useIframeShortcuts('Backspace', handleDelete, 'responsive-frame');
  useIframeShortcuts('ctrl+s', saveBlob, 'responsive-frame');
  useIframeShortcuts('ctrl+c', handleCopy, 'responsive-frame');
  useIframeShortcuts('ctrl+v', handlePaste, 'responsive-frame');
  useIframeShortcuts('ctrl+z', handleUndo, 'responsive-frame');
  useIframeShortcuts('ctrl+y', handleRedo, 'responsive-frame');
  useIframeShortcuts('ctrl+k', () => {
    toggleDictionary(true);
  }, 'responsive-frame');
  useIframeShortcuts('Escape', () => {
    toggleDictionary(false);
  }, 'responsive-frame');

  return (
    <div
      id='pefai-canvas-container'
      tabIndex='0'
      className="pefai-canvas-container">
      <Sidebar
        canvas={canvas}
        blob={blob}
        setBlob={setBlob}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setTogglePageSettings={setTogglePageSettings}
      />
      {!blob || blob.length === 0
        ? <EmptyCanvas
          canvas={canvas}
          addComponent={(component) => setBlob([component])} />
        : <>
          <PFContainer
            id='pefai-canvas'
            className="pefai-canvas"
            config={{
              onMouseMove: handleHover,
              onMouseLeave: handleMouseLeave,
              onClick: handleSelection,
            }}>
            <ResponsiveFrame
              id='responsive-frame'
              canvas={canvas}
            >
              <ThemeProvider theme={theme}>
                <ListWrapper
                  canvas={canvas}
                  components={blob}
                  setComponents={setBlob} />
                <Inspector blob={blob} canvas={canvas} />
              </ThemeProvider>
            </ResponsiveFrame>
            <PFAlert
              message={error}
              open={!!error}
              onClose={() => setError(null)}
            />
            <Breadcrumbs canvas={canvas} />
          </PFContainer>
        </>}
      <PFContainer className="pefai-component-menu">
        <PFContainer id="pefai-component-menu" />
      </PFContainer>
    </div>

  );
};

Canvas.propTypes = {
  blob: PropTypes.array,
  saveBlob: PropTypes.func.isRequired,
  setBlob: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  customComponentUpdate: PropTypes.func.isRequired,
  setTogglePageSettings: PropTypes.func,
  toggleDictionary: PropTypes.func
};

export default Canvas;
