import CodeEditor from '@uiw/react-textarea-code-editor';
import { componentUtils } from 'PFUtils';
import PropTypes from 'prop-types';
import rehypePrism from 'rehype-prism-plus';
import rehypeRewrite from 'rehype-rewrite';
import './PFCodeEditor.scss';

const value_properties = ['number', 'boolean'];
const string_properties = ['string'];
const text_properties = ['property', 'punctuation', 'operator'];

/**
 * Basi code editor component of the PFComponents library. This editor complies
 * with the Pefai style guidelines. The underlying implementation of this editor
 * is the React *input* component.
 *
 * @memberof module:PFComponents
 *
 * @param {Object} props - PFCodeEditor properties.
 * @param {string} [props.language="json"] - Language being used in the editor.
 * @param {string} [props.label] - Text to be displayed above the editor.
 * @param {string} [props.name] - Name of the editor (required if associated to
 * a 'useForm' hook).
 * @param {string} [props.placeholder] - Placeholder to be displayed inside the
 * editor.
 * @param {func} [props.fieldHook] - Function provided by the 'useForm' hook to
 * manage the content of a field.
 * @param {string} [props.className] - Class name to be applied to the file
 * editor.
 * @param {React.CSSProperties} [props.style] - Style override to be applied to
 * the editor.
 * @param {any} [props.value] - Current value of the editor.
 * @param {string} [props.error] - Current error of the editor value.
 * @param {func} [props.onChange] - Function to be called when the value of the
 * editor changes (not necessary if fieldHook is present).
 * @param {boolean} [props.disabled=false] - Determines if the editor can be
 * edited or not.
 * @param {string|number} [props.width] - Determines the width of the editor
 * container.
 * @param {string} [props.margin] - Determines the margin config of the file
 * editor component.
 * @param {Object} [props.labelStyles] - Style override to be applied to label.
 * @return {React.ReactElement} The editor component.
 *
 * @author Andres Barragan  <andres@pefai.com>
 */
const PFCodeEditor = ({
  language,
  label,
  name,
  placeholder,
  fieldHook,
  className,
  style,
  value,
  error,
  onChange,
  disabled,
  width,
  minHeight,
  margin,
  labelStyles
}) => {
  const hook = fieldHook ? fieldHook(name) : {};

  const editorValue = hook.value ?? value;
  const editorError = hook.error ?? error;
  const editorUpdate = hook.updateAndValidate
    ? (e) => hook.updateAndValidate(e.target.value)
    : null;

  const configStyle
    = componentUtils.getStylesFromConfig({ margin });
  const containerStyle = { width, ...configStyle };

  const nodeHasProperty = (node, properties) => {
    return node?.properties?.className.some((className) => {
      return properties.includes(className);
    });
  };

  return (
    <div
      className={`pf input-container ${editorError ? 'error' : ''}`}
      style={containerStyle}
    >
      {label
        ? <label className={`pf input-label`} style={labelStyles}>
          {label}</label>
        : null
      }
      <div
        className={`pf input-wrapper multiline 
        ${className} ${editorError ? 'error' : ''}`}
        data-color-mode="light">
        <CodeEditor
          className={`pf input multiline ${className}`}
          value={editorValue}
          onChange={onChange ?? editorUpdate}
          placeholder={placeholder}
          language={language}
          disabled={disabled}
          padding={20}
          minHeight={minHeight}
          style={{
            ...style,
            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,monospace',
          }}
          rehypePlugins={[
            [rehypePrism, { ignoreMissing: true }],
            [
              rehypeRewrite,
              {
                rewrite: (node, _, parent) => {
                  if (node.type === 'text') {
                    if (nodeHasProperty(parent, string_properties)) {
                      parent.properties.className.push('pf code-editor string');
                    } else if (nodeHasProperty(parent, value_properties)) {
                      parent.properties.className.push('pf code-editor value');
                    } else if (nodeHasProperty(parent, text_properties)) {
                      parent.properties.className.push('pf code-editor text');
                    }
                  }
                }
              }
            ]
          ]}
        />
      </div>
      {editorError
        ? <label className="pf input-error">{editorError}</label>
        : null
      }
    </div>
  );
};

PFCodeEditor.defaultProps = {
  language: 'json',
  label: null,
  placeholder: '',
  style: {},
  error: null,
  disabled: false,
  minHeight: 200,
};

PFCodeEditor.propTypes = {
  language: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fieldHook: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  minHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.string,
  labelStyles: PropTypes.object
};

export default PFCodeEditor;
